/* eslint-disable @typescript-eslint/no-var-requires */
var _dom_adapter = _interopRequireDefault(require("devextreme/core/dom_adapter"));
var _pointer = _interopRequireDefault(require("devextreme/events/pointer"));
var _events_engine = _interopRequireDefault(require("devextreme/events/core/events_engine"));
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxOverlay = require('devextreme/ui/overlay');

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        "default": obj
    }
}

var OVERLAY_STACK = [];
var _dxOverlay = DevExpress.ui.dxOverlay.redefine({
    _setDocumentDownEventHandler: function () {
        var document = _dom_adapter.default.getDocument();
        _events_engine.default.subscribeGlobal(document, _pointer.default.down, function (e) {
            for (var i = OVERLAY_STACK.length - 1; i >= 0; i--) {
                var handleOthers = OVERLAY_STACK[i]._proxiedDocumentDownHandler(e);
                if (!handleOthers) return;
            }
        });
    },
    __initCloseOnOutsideClickHandler: function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var that = this;
        this._proxiedDocumentDownHandler = function () {
            return that._documentDownHandler.apply(that, arguments);
        };
    },
    _overlayStack: function () {
        return OVERLAY_STACK
    },
    _initCloseOnOutsideClickHandler: function () {
        this._setDocumentDownEventHandler();
        this.__initCloseOnOutsideClickHandler()
    },
});

DevExpress.registerComponent("dxOverlay", _dxOverlay);
