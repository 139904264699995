import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { AppsEntity } from '../state/apps.models';
import { from, Observable, of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import { CommandListCollectionService } from './command-list-collection.service';
import { TranslatableEffectCommand } from './translatable-effect.command';
import { EventsService } from '../services/events.service';
import { ApplyFilterCommand } from './apply-filter.command';
import { CacheManagerService } from '../services/cachemanager.service';
import { TranslateFacadeService } from '../services/translate-facade.service';
import { ComponentService } from '../services/component.service';
import { ApiFieldMapperFactory, DefaultFieldMapperFactory } from '../services/field-mappers/field-mapper.factory';
import { ApplicationInformation } from '../services/application-information.service';

@Injectable()
export class ModalPopUpCommand extends TranslatableEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        protected translateFacade: TranslateFacadeService,
        private defaultFieldMapperFactory: DefaultFieldMapperFactory,
        private apiFieldMapperFactory: ApiFieldMapperFactory,
        private componentService: ComponentService,
        private eventsService: EventsService,
        private applyFilterCommand: ApplyFilterCommand,
        private applicationInformation: ApplicationInformation,) {
        super();
    }

    //  open modal popup command effect
    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.openModalPopupCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.openModalPopupCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => this.openModalPopup(action, appsStore),
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    closeModalPopupCommand$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.closeModalPopupCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    const Hide = true;
                    const event = {
                        id: "ThemeCustomAlert.Event",
                        state: { Hide }
                    };
                    const hideCustomAlertAction = CommandActions.publishEvent({ event });
                    return from(this.hideModalPopup(commandConfig))
                        .pipe(
                            switchMap(() => this.appendNextActions([hideCustomAlertAction], commandConfig))
                        )
                })
            )
    );

    showModalPopup$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.showModalPopup),
                tap((action) =>
                    this.showModalPopup(action.commandConfig, action.fieldMap)
                )
            ),
        { dispatch: false }
    );

    private openModalPopup(action, appsStore): Observable<any> {
        const commandConfig = this.getCommandConfig(action);
        const appsState = this.getAppsState(appsStore);
        const startExecution = this.startOpenModalPopupExecution(commandConfig, appsState);
        return startExecution.pipe(
            switchMap(([commandState, fieldMap]) => {
                const useApplyMapper = this.shouldApplyFilterOnShowOpenModalPopUp(commandConfig, fieldMap);
                if (useApplyMapper) {
                    const fieldMapper = this.defaultFieldMapperFactory.create(appsState, this);
                    return this.applyFilterCommand.applyFilter(commandConfig, fieldMapper, fieldMap, appsState)
                        .pipe(
                            switchMap(() => this.getShowModalPopupActions(commandState, commandConfig, fieldMap))
                        );
                }
                return this.getShowModalPopupActions(commandState, commandConfig, fieldMap);
            })
        )
    }

    private getShowModalPopupActions(commandState: AppsEntity[], commandConfig: CommandConfig, fieldMap: FieldMap): Observable<any> {
        const timeout = 50;
        const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
        const showModalPopupAction = CommandActions.showModalPopup({ commandConfig, fieldMap });
        const delayTimeoutAction = CommandActions.delayAndContinueWithNextCommand({ timeout, commandConfig, prevValue: fieldMap });
        thisCommandActions.push(showModalPopupAction);
        thisCommandActions.push(delayTimeoutAction);
        return from(thisCommandActions)
    }

    private hideModalPopup(commandConfig: CommandConfig): Observable<boolean> {
        const appName = _.first(commandConfig.parameters);
        const popupName = this.getModalPopUpName(appName);
        const dxOptions = this.getHidePopupOptions();
        return this.triggerEvent(popupName, dxOptions);
    }

    private showModalPopup(commandConfig: CommandConfig, fieldMap: FieldMap): void {
        const popupName = this.getModalPopUpName(fieldMap.targetApp);
        const dxOptions = this.getShowPopupOptions(fieldMap.targetApp, commandConfig);
        this.componentService.createPopupComponent(commandConfig.appName, popupName, dxOptions);
        const eventEntity = this.createAppEntity(popupName, dxOptions);
        this.eventsService.publishEvent(eventEntity);
    }

    private triggerEvent(popupName: string, dxOptions: any): Observable<boolean> {
        const eventEntity = this.createAppEntity(popupName, dxOptions);
        return of(true)
            .pipe(
                tap(() => this.eventsService.publishEvent(eventEntity)),
                delay(50)
            );
    }

    private shouldApplyFilterOnShowOpenModalPopUp(commandConfig: CommandConfig, fieldMap: FieldMap): boolean {
        let applyFilter = commandConfig.parameters[3];
        if (applyFilter)
            applyFilter = this.applicationInformation.shouldApplyFilterOnShowOpenModalPopUp(fieldMap.targetApp);
        return applyFilter;
    }

    private startOpenModalPopupExecution(commandConfig: CommandConfig, appsState: any): Observable<any> {
        let useApiFieldMapper = commandConfig.parameters[2];
        useApiFieldMapper = this.isTruthy(useApiFieldMapper) || (_.isBoolean(useApiFieldMapper) && useApiFieldMapper);
        const useDefaultFieldMapper = _.isPlainObject(commandConfig.parameters[0])
            || (_.isString(commandConfig.parameters[0])
                && commandConfig.parameters[0].indexOf("=") >= 0);
        let mapResult: Observable<any>;
        if (useApiFieldMapper) {
            const apiMapper = this.apiFieldMapperFactory.create(appsState, this, commandConfig);
            const commandPromise = apiMapper.execute();
            mapResult = from(commandPromise)
                .pipe(
                    switchMap(() => {
                        const commandState = Object.values(apiMapper.State);
                        return of([commandState, apiMapper.fieldMap]);
                    })
                );
        } else if (useDefaultFieldMapper) {
            const fieldMapper = this.defaultFieldMapperFactory.create(appsState, this);
            const forceSetValue = true;
            const fieldMap = fieldMapper.execute(commandConfig, null, forceSetValue);
            const commandState = Object.values(fieldMapper.State);
            mapResult = of([commandState, fieldMap]);
        } else {
            const targetApp = _.first(commandConfig.parameters);
            const fakeFieldMap = { targetApp };
            const commandState = [];
            mapResult = of([commandState, fakeFieldMap]);
        }
        return mapResult;
    }

    private getModalPopUpName(appName: string): string {
        return "popupCLpc" + appName?.toPopUpName();
    }

    private getHidePopupOptions(): any {
        const dxOptions = {};
        dxOptions["visible"] = false;
        return dxOptions;
    }

    private getShowPopupOptions(targetApp: string, commandConfig: CommandConfig): any {

        const dxOptions = {};

        //#region setting variables from command parameters

        let width = null;
        let height = null;
        let cssClass = null;
        let closeOnOutsideClick = false;
        let resetFieldsPristineOnOpen = false;
        let appsToIgnoreOnPristineReset = null;

        if (!_.isEmpty(commandConfig.parameters[4]))
            height = commandConfig.parameters[4];

        if (!_.isEmpty(commandConfig.parameters[5]))
            width = commandConfig.parameters[5];

        if (!_.isEmpty(commandConfig.parameters[6]))
            cssClass = commandConfig.parameters[6];

        if (this.isTruthy(commandConfig.parameters[7]))
            resetFieldsPristineOnOpen = true;

        if (this.isTruthy(commandConfig.parameters[8]))
            closeOnOutsideClick = true;

        if (commandConfig.parameters[9])
            appsToIgnoreOnPristineReset = commandConfig.parameters[9];

        //#endregion

        //#region Update dx pop up options

        if (height != null && height != 'auto') {
            dxOptions['maxHeight'] = height;
            dxOptions['height'] = height;
        } else {
            dxOptions['maxHeight'] = null;
            dxOptions['height'] = 'auto';
        }

        if (width != null && width != 'auto') {
            dxOptions['maxWidth'] = width;
            dxOptions['width'] = width;
        } else {
            dxOptions['maxWidth'] = null;
            dxOptions['width'] = 'auto';
        }

        if (closeOnOutsideClick) {
            dxOptions['closeOnOutsideClick'] = true;
        }

        dxOptions["showTitle"] = null;
        dxOptions["showCloseButton"] = null;
        dxOptions["ic.targetApp"] = targetApp;
        dxOptions["ic.cssClass"] = cssClass;
        dxOptions["ic.resetFieldsPristineOnOpen"] = resetFieldsPristineOnOpen;
        dxOptions["ic.appsToIgnoreOnPristineReset"] = appsToIgnoreOnPristineReset;
        dxOptions["ic.popUpTriggeringElement"] = commandConfig.options.e?.event?.originalEvent?.currentTarget ?? commandConfig.options.e?.event?.currentTarget;

        if (!closeOnOutsideClick) {
            dxOptions["showTitle"] = true;
            dxOptions["showCloseButton"] = true;
        }

        //#endregion

        dxOptions["visible"] = true;

        return dxOptions;
    }

}
