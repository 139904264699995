// TODO: upgrade canvas components
// import './components/canvas/print/canvas-print';
// import './components/canvas/interactive/canvas-interactive';
// import './components/canvas/interactive/PositionTracker';
// import './components/cms/cms';
// import './applets/cms/cms';

const makeServerReplacementRequest = (oldRequest) => {
    let applicationName;
    const uniqueReplacementMap = {};
    for (const appName in oldRequest) {
        applicationName = appName;
        const appObject = oldRequest[appName];
        for (const fieldName in appObject) {
            const replacement = appObject[fieldName];
            uniqueReplacementMap[replacement] = true;
        }
    }
    const serverSideFieldMasks = [];
    const dynamicReplacements = Object.keys(uniqueReplacementMap);
    const adaptedRequest = {
        applicationName,
        dynamicReplacements,
        serverSideFieldMasks
    };
    return window.IC.API.makeServerReplacementRequest(adaptedRequest);
};

// Return the IC.API object for any call to angular injector - requires matching function to be available on the API object
window.angular = {
    deprecated: true,
    element: () => {
        return {
            injector: () => {
                return {
                    get: (name: string) => {
                        console.error("Using legacy AngularJS injector. Please update custom browser code to use IC.API functions", name);
                        if (name == "stateService") {
                            const { getAppState, resetAppState, updateAppState } = window.IC.API;
                            const errorMessage = "Custom browser code should not modify app store";
                            return {
                                getAppState,
                                getContext: () => ({}),
                                resetAppState: (...args: any[]) => {
                                    resetAppState(...args);
                                    console.error(errorMessage);
                                },
                                updateAppState: (...args: any[]) => {
                                    updateAppState(...args);
                                    console.error(errorMessage);
                                }
                            };
                        }
                        if (name == "apiClientService") {
                            return {
                                makeServerReplacementRequest
                            };
                        }
                        return window.IC.API;
                    }
                }
            }
        };
    }
}