/* eslint-disable */
import _ from 'lodash';
import numeral from 'numeral';
import 'numeral/locales';

if (_.isNil(numeral.formats.uppercasea)) {
    numeral.register('format', 'uppercasea', {
        regexps: {
            format: /U/,
            unformat: /U/
        },
        format: function (value, format, roundingFunction) {
            return numeral(value).format(format.replace(/U/, 'a')).toUpperCase();
        },
        unformat: function (string) {
            return numeral._.stringToNumber(string);
        }
    });
}
if (_.isNil(numeral.formats.truncate)) {
    numeral.register('format', 'truncate', {
        regexps: {
            format: /#/,
            unformat: /#/
        },
        format: function (value, format) {
            const roundingFunction = function (str) {
                const _dotIndex = str.indexOf(".");
                let _plusIndex = str.indexOf("+");
                _plusIndex = +str.substr(_plusIndex + 1, str.length);
                let _eIndex;
                if (_dotIndex != -1) {
                    str = str.substr(0, _dotIndex + _plusIndex + 1).replace(".", "");
                    _eIndex = str.indexOf("e");
                    if (_eIndex != -1) {
                        _plusIndex = _plusIndex - (_eIndex - _dotIndex - 1);
                        str = str.substr(0, _eIndex) + Array(_plusIndex).join("0");
                    }
                } else {
                    str = Math.round(str);
                }
                return +str;
            };
            // removing any space and # characters
            format = format.replace(/\s?\#/, '');
            const output = numeral._.numberToFormat(value, format, roundingFunction);
            return output;
        },
        unformat: function (string) {
            return numeral._.stringToNumber(string);
        }
    });
}
