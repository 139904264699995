import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { AppsEntity } from '../state/apps.models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap, delay } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { ValidationEngineService } from '../services/validation-engine.service';
import { ValidationGroupService } from '../services/validation-group.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';

@Injectable()
export class ValidateFormCommand extends BaseEffectCommand {

    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private validationEngineService: ValidationEngineService,
        private validationGroupService: ValidationGroupService,
        private domComponentRetrievalService: DomComponentRetrievalService,
        protected dynamicReplacementService: DynamicReplacementService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.validateFormCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    return this.validateForm(commandConfig)
                        .pipe(
                            switchMap(() => this.getNextActions(commandConfig))
                        )
                })
            )
    );

    resetValidationGroup$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.resetValidationGroupCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    const groupName = _.first(commandConfig.parameters);
                    const groupConfig = this.validationEngineService.getGroupConfig(groupName);
                    const maintainValidValues: boolean = this.isTruthy(commandConfig.parameters[1]);
                    const useDefaultFieldValue: boolean = this.isTruthy(commandConfig.parameters[2]);
                    if (_.isNil(groupConfig)) {
                        return throwError("Validation Group '" + groupName + "' not found.");
                    }
                    const appFieldValueMap = groupConfig.resetAndGetFieldMap(maintainValidValues);
                    const commandStatePromise = this.getCommandStateFromResetAppFieldValueMap(appFieldValueMap, useDefaultFieldValue);
                    return from(commandStatePromise)
                        .pipe(
                            switchMap(commandState => {
                                const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                                return this.appendNextActions(thisCommandActions, commandConfig);
                            })
                        );
                })
            )
    );

    private async getCommandStateFromResetAppFieldValueMap(appFieldValueMap: Record<string, any>,
        useDefaultFieldValue: boolean): Promise<AppsEntity[]> {
        const commandState = [];
        for (const appName in appFieldValueMap) {
            const appComponent = this.domComponentRetrievalService.getAppComponent(appName);
            const appState = { ...appFieldValueMap[appName] };
            if (appComponent && useDefaultFieldValue) {
                for (const fieldName in appState) {
                    let defaultValue = appComponent.modelDefaultValues[fieldName];
                    defaultValue = _.isEmpty(defaultValue?.value) ? appState[fieldName] : defaultValue.value;
                    if (this.dynamicReplacementService.hasReplacementValue(defaultValue)) {
                        const options = { appName, force: false };
                        defaultValue = await this.dynamicReplacementService.getDynamicValue(defaultValue, options);
                    }
                    appState[fieldName] = defaultValue;
                }
            }
            const appEntity = this.createAppEntity(appName, appState);
            commandState.push(appEntity);
        }
        return commandState;
    }

    private validateForm(commandConfig: CommandConfig): Observable<any> {

        let groupName = _.first(commandConfig.parameters);
        if (_.isNil(groupName))
            return throwError("Validation group is not valid. Group name undefined");

        groupName = groupName.toLowerCase();
        const isValid = this.validationGroupService.isValidationGroupValid(groupName)
        if (!isValid)
            return of(true);

        const appComponent = this.domComponentRetrievalService.getAppComponent(commandConfig.appName);
        const delayTime = 100;
        return of(groupName)
            .pipe(
                delay(delayTime),
                switchMap((groupName) => from(this.validationEngineService.validateGroup(appComponent, groupName))),
                switchMap((result: any) => {
                    if (result.isValid) {
                        return of(true);
                    }
                    const attributes = {
                        "tabindex": "0",
                        "role": "alert"
                    };
                    $(".dx-invalid-message.dx-overlay-wrapper").find("div").first().attr(attributes);
                    return throwError("Form Validation Failed for " + groupName);
                })
            );
    }

}
