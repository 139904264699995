// tslint:disable: forin
// tslint:disable: no-bitwise
import { Injectable } from '@angular/core';
import UtilityFunctions from '../../utility.functions';
import { ApplicationInformation } from '../application-information.service';
import { QryAggregateProfitAndLossLiteService } from './qryaggregateprofitandlosslite.service';

@Injectable()
export class EcdUtilService {
  constructor(private applicationInformation: ApplicationInformation,
    private qryAggregateProfitAndLossLiteService: QryAggregateProfitAndLossLiteService) { }

  getOverrideService(request) {
    request = request || {};
    let service = null;
    let overrideService = null;
    const appInfo = this.applicationInformation.getAppInfo(request.ApplicationName);
    let serviceName = 'im.qryaggregateprofitandlosslite';
    const themeProperty = UtilityFunctions.getThemeProperty('QryAggregateProfitAndLossLiteServiceName');
    if (themeProperty && themeProperty.Value1) {
      serviceName = themeProperty.Value1.toLowerCase();
    }

    if (appInfo && appInfo.hasServer) {
      service = appInfo.server.toLowerCase();
      switch (service) {
        case serviceName:
          overrideService = this.qryAggregateProfitAndLossLiteService;
          break;
        default:
          overrideService = null;
          break;
      }
    }
    return overrideService;
  };
}
