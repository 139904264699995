import { Injectable } from "@angular/core";
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import * as AppsActions from '../state/apps.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { ApplicationInformation } from '../services/application-information.service';
import { DomFocusService } from "../services/dom-focus.service";
import { DomComponentRetrievalService } from "../services/dom-component-retrieval.service";
import { DynamicReplacementService } from "../services/dynamic-replacement.service";
import { BaseEffectCommand } from "./base-effect.command";
import { CacheManagerService } from "../services/cachemanager.service";
import { AppsEntity } from "../state/apps.models";

@Injectable()
export class ApplyClientSideFilterCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private dynamicReplacementService: DynamicReplacementService,
        private domFocusService: DomFocusService,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.applyClientSideFilterCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.applyClientSideFilterCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);
                this.applyClientSideFilter(commandConfig, appsState);
                return this.getNextActions(commandConfig);
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    private applyClientSideFilter(commandConfig: CommandConfig, appsState: Record<string, AppsEntity>) {

        const appComponent = this.domComponentRetrievalService.getAppComponent(commandConfig.appName);
        const _dataSources = appComponent.context._dataSources;
        const applet = appComponent.applet;
        const fieldMap = this.getFieldMap(commandConfig);
        let filter = this.getFilterListConfig(fieldMap, appsState);
        filter = filter.length == 0 ? null : filter;

        if (!this.applicationInformation.isV4App(fieldMap.targetApp))
            return;

        const componentName = this.getComponentName(fieldMap.targetApp);
        if (this.applicationInformation.isComponent(fieldMap.targetApp)) {
            if (_.isNil(_dataSources[componentName]))
                return;
            _dataSources[componentName].filter(filter);
            _dataSources[componentName].load();
        } else if (this.applicationInformation.isListApp(fieldMap.targetApp)) {
            const component = this.domComponentRetrievalService.getDxComponentInstance(applet, fieldMap.targetApp);
            if (_.isNil(component)) {
                if (_.isNil(_dataSources[componentName]))
                    return;
                _dataSources[componentName].filter(filter);
                _dataSources[componentName].load();
            } else {
                if (_.isFunction(component.pageIndex))
                    component.pageIndex(0);
                const dataSource = component.getDataSource();
                dataSource.filter(filter);
                dataSource.load();
            }
            this.domFocusService.setFocusOnLoadingPanel();
        }
    }

    private getFilterListConfig(fieldMap: FieldMap, appsState: Record<string, AppsEntity>, addFilterType?: any) {
        addFilterType = _.isNil(addFilterType) ? true : addFilterType;
        const filters = [];
        for (let fieldIndex = 0; fieldIndex < fieldMap.sourceFields.length; fieldIndex++) {

            const fieldMapInfo = this.getFieldMapInfo(fieldMap, fieldIndex);
            const appName = fieldMapInfo.source.appName;

            // if (!this.isAppInitialized(appName))
            //     continue;

            let filterValue = this.getAppFieldValue(appName, fieldMapInfo.source.fieldName, appsState);

            //to avoid creating a filter if the filterValue (value to be filtered) is undefined
            if (filterValue == undefined)
                continue;

            //to check if the current value from the source mapping app has dynamic value
            //and evaluate the field value for the dynamic string if true
            if (this.dynamicReplacementService.hasReplacementValue(filterValue)) {
              filterValue = this.dynamicReplacementService.getDynamicFieldValue(appsState[fieldMap.sourceApp]?.state || {}, filterValue);
            }

            const filterName = fieldMapInfo.target.fieldName;
            const filter = [filterName];
            if (addFilterType)
                filter.push('contains');
            filter.push(filterValue);

            filters.push(filter);
        }
        return filters;
    }

}
