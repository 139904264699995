/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, tap, throttleTime } from 'rxjs/operators';
import { AppsFacade } from '../state/apps.facade';
import { TranslateFacadeService } from '../services/translate-facade.service';
import { SessionTimeout } from '../services/session-timeout.service';
import { ModalService } from './../services/modal.service';
import { ApiClientService } from '../services/api-client.service';
import UtilityFunctions from '../utility.functions';
import { AppEvent } from '../state/app-events.enum';
import { DeviceService } from './../services/device-information.service';

@Component({
    selector: 'ic-enhanced-timeout',
    template: `
    <ng-container *ngIf="visible; then enhancedAlert;"></ng-container>
    <ng-template #enhancedAlert>
        <div class='IX_enhanceTimeoutWindow'>
            <div tabindex='0' id='timeoutWarningDialog' role='dialog' aria-labelledby='timeoutWarningDialogTitle' class='IX_enhanceTimeout'>
                <h3 id='timeoutWarningDialogTitle' class='IX_enhanceTimeoutTitle'>{{timeoutWarning}}</h3>
                <div class='IX_enhanceTimeoutText'>{{sessionText}}</div>
                <div class='IX_enhanceTimeoutTimer'>
                    <span id='session-timer' aria-live='polite' tabindex='0'></span>
                </div>
                <div class='IX_enhanceTimeoutOkButtonWrap'>
                    <div attr.aria-label='{{ariaLabel}}' tabindex='0' role='button' (click)="onOkClick($event)"
                        class='IX_enhanceTimeoutOkButton IX_enhanceTimeoutOkButton_Button ic-ada-focusonshown'>
                        {{buttonText}}
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnhancedTimeoutComponent implements OnInit, OnDestroy {

    private windowResizeSubscription: Subscription;
    private onEventSubscription: Subscription;
    private translateSubscription: Subscription;
    private lastElement: any;
    private eventName = "keydown .IX_enhanceTimeoutWindow";

    visible: boolean;
    timeoutWarning: string;
    sessionText: string;
    ariaLabel: string;
    buttonText: string;

    get $element(): any {
        return $(this.elementRef.nativeElement).find(".IX_enhanceTimeout");
    }

    constructor(private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        private appsFacade: AppsFacade,
        private translateFacade: TranslateFacadeService,
        private modalService: ModalService,
        private apiClientService: ApiClientService,
        private sessionTimeout: SessionTimeout,
        private deviceService: DeviceService,) {
        this.visible = false;
        this.onEventSubscription = this.appsFacade
            .events$
            .pipe(
                filter(event => AppEvent.EnhancedTimeout.EqualsIgnoreCase(event?.id)),
                tap((event) => this.handleEvent(event.state))
            ).subscribe();
    }

    ngOnInit(): void {
        this.translateTexts();
        this.translateSubscription = this.translateFacade.onLangChange.subscribe(() => this.translateTexts());
        this.windowResizeSubscription = fromEvent(window, 'resize')
            .pipe(throttleTime(500, undefined, { trailing: true }))
            .subscribe(() => UtilityFunctions.centerElement(this.$element));
    }

    ngOnDestroy(): void {
        this.onEventSubscription?.unsubscribe();
        this.translateSubscription?.unsubscribe();
        this.windowResizeSubscription?.unsubscribe();
    }

    onOkClick(e) {
        if (e.which == 1 || e.which == 32 || e.which == 13) {
            this.onButtonClick(true);
        }
    }

    private getTranslation(message: string): string {
        return this.translateFacade.getTranslation(message);
    }

    private onButtonClick(keepAlive: boolean): void {
        this.sessionTimeout.TimerVisible = false;
        clearInterval(this.sessionTimeout.IntervalShowAlert);
        clearInterval(iXing.sessionTimeoutStatus);
        setTimeout(() => {
            if (keepAlive) {
                this.initiateServerSideCall("ExtendSession"); 
                this.sessionTimeout.init();
            }
            this.visible = false;
            this.modalService.registerModalClose(this.$element, this.lastElement);
            this.invokeCloseMobileAction();
            this.detectChanges();
        }, 0);
    }

    private invokeOpenMobileAction() {
        this.invokeMobileAction("showing");
    }

    private invokeCloseMobileAction() {
        this.invokeMobileAction("hidden");
    }

    private invokeMobileAction(parameters: string): any {
        if (_.isEmpty(parameters)) return;
        //send message to xamarin to show when modal is open
        if (!this.deviceService.IX_isMobile()) return;
        if (!_.isFunction(window.invokeCSharpAction)) return;
        const command = {
            command: "modal_alert",
            parameters
        };
        window.invokeCSharpAction(JSON.stringify(command));
    }

    private translateTexts() {
        this.sessionText = this.getTranslation("SESSION-1");
        this.buttonText = this.getTranslation("KEEP-SIGNED-IN");
        this.ariaLabel = this.getTranslation("SESSION-ABOUT-EXPIRE");
        this.timeoutWarning = this.getTranslation("TIMEOUT-WARNING");
    }

    private handleEvent(eventState: any) {
        if (_.isNil(eventState)) return;
        $(document).off(this.eventName, this.setKeyBoardHandler);
        this.lastElement = $(document.activeElement);
        this.translateTexts();
        if (this.visible) this.modalService.registerModalClose(this.$element, this.lastElement);
        this.visible = eventState.visible;
        if (this.visible) {
            this.detectChanges();
            this.modalService.registerModalOpen(this.$element);
            this.startCountdownTimer();
            this.invokeOpenMobileAction();
        }
        $(document).on(this.eventName, this.setKeyBoardHandler.bind(this));
        this.detectChanges();
        UtilityFunctions.centerElement(this.$element);
    }

    private detectChanges() {
        this.changeDetectorRef.detectChanges();
    }

    private setKeyBoardHandler(e) {

        const TAB_KEY = 9;
        const ESC_KEY = 27;
        const tabbableSelector = ":ix-tabbable";

        if (e.keyCode !== TAB_KEY && e.keyCode !== ESC_KEY) {
            return;
        }

        if (e.keyCode === ESC_KEY) {
            this.onButtonClick(false);
            $(document).off(this.eventName, this.setKeyBoardHandler);
            return;
        }

        const $wrapper = $(this.elementRef.nativeElement).find(".IX_enhanceTimeoutWindow");
        const tabbableElements = $wrapper.find(tabbableSelector);
        const $firstTabbable = tabbableElements.first();
        const $lastTabbable = tabbableElements.last();
        const isTabOnLast = !e.shiftKey && e.target === $lastTabbable.get(0);
        const isShiftTabOnFirst = e.shiftKey && e.target === $firstTabbable.get(0);
        const isEmptyTabList = 0 === tabbableElements.length;
        const isOutsideTarget = $.inArray(e.target, tabbableElements) === -1;
        if (isTabOnLast || isShiftTabOnFirst || isEmptyTabList || isOutsideTarget) {
            e.preventDefault();
            (e.shiftKey ? $lastTabbable : $firstTabbable).focusin().focus();
        }
    }

    private startCountdownTimer() {
        if (this.checkTimeLeftAndPerformLogoutIfNecessary())
            return;
        iXing.sessionTimeoutStatus = setInterval(() => this.checkTimeLeftAndPerformLogoutIfNecessary(), 1000);
    }

    // returns true if logged out
    private checkTimeLeftAndPerformLogoutIfNecessary() {
        const now: any = new Date();
        const lastTime: any = this.sessionTimeout.getLastActivityTimeDate();
        const millisecondsFromLastAction = (now - lastTime);
        if (millisecondsFromLastAction > IX_TimeoutStart) {
            this.redirectUserForLogout("&IX_TMD=Y");
            return true;
        }
        const timerText = this.setTimerFromSeconds((IX_TimeoutStart - millisecondsFromLastAction) / 1000);
        this.$element.find('#session-timer').text(timerText);
        return false;
    }

    private setTimerFromSeconds(secondsLeft) {
        const minutesToTimeout = Math.floor(secondsLeft / 60);
        const secondsToTimeout = Math.floor(secondsLeft % 60);
        const textToDisplay = minutesToTimeout + ":" + this.padNumber(secondsToTimeout, 2);
        return textToDisplay;
    }

    private padNumber(num, size) {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    private redirectUserForLogout(param?: string) {
        this.appsFacade.redirectUserForLogout(param);
    }

    private initiateServerSideCall(actionFromUser, actionFromUserValue1?, actionFromUserValue2?, recordUserNavEventWithNoThrottleWait?) {
        if (actionFromUser === "ExtendSession") iXing.pendingSessionExtensionRequests = true;
        const serverCaller = this.createThrottledServerCall(actionFromUser, actionFromUserValue1, actionFromUserValue2, recordUserNavEventWithNoThrottleWait);
        serverCaller();
    }

    private createThrottledServerCall(actionFromUser, actionFromUserValue1, actionFromUserValue2, recordUserNavEventWithNoThrottleWait) {
        if (recordUserNavEventWithNoThrottleWait) {
            this.makeServerSideCall(actionFromUser, actionFromUserValue1, actionFromUserValue2);
            return _.noop;
        }
        const throttleWait = 60000;
        const options = { leading: true, trailing: false };
        const optionsReset = { leading: false, trailing: true };
        const serverCall = _.throttle(() => this.makeServerSideCall(actionFromUser, actionFromUserValue1, actionFromUserValue2), throttleWait, options);
        const resetSession = _.throttle(() => this.sessionTimeout.init(), throttleWait, optionsReset);
        return () => {
            serverCall();
            resetSession();
        };
    }

    private makeServerSideCall(actionFromUser, actionFromUserValue1, actionFromUserValue2) {
        actionFromUser = _.isNil(actionFromUser) ? "ExtendSession" : actionFromUser;

        // Updating the "IXLastActivityTime" cookie with the current timestamp of user action
        if (actionFromUser == "IXLastActivityTime") {
            this.sessionTimeout.LastActivityTime = new Date();
            IX_SetCookieValue("IXLastActivityTime", this.sessionTimeout.LastActivityTime);
        }

        this.apiClientService.extendSessionRequest(actionFromUser, actionFromUserValue1, actionFromUserValue2)
            .then(() => delete iXing.pendingSessionExtensionRequests);

        if (_.isFunction(iXing.IX_ExtendSessionTimeoutScript))
            iXing.IX_ExtendSessionTimeoutScript();
    }

}
