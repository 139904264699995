/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { UtilService } from '../../services/util.service';
import { FieldFormatService } from '../../services/field-format.service';

@Component({
    selector: 'ic-label',
    styleUrls: ['./label.component.scss'],
    template: `<ng-container *ngIf="property === 'innerHtml'; then htmlContent else textContent"></ng-container>
        <ng-template #htmlContent><div [innerHtml]='text'></div></ng-template>
        <ng-template #textContent><div></div></ng-template>`
})
export class LabelComponent implements OnInit, OnDestroy, OnChanges {

    @Input() fieldName: any;
    @Input() model: { value: string, isHtml?: boolean };
    @Input() parentModel: any;
    @Input() config: { icLabelModel: { icClassName: string } };
    @Input() applet: Applet;
    @Input() context: any;
    @Input() conditionalFormats: any;
    @Input() cssClass: string;
    @Input() checkSize: any;

    translateSubscription: Subscription;

    // Default approach to create actual content. Using empty string does not create the space
    property = 'innerText';
    text = "&nbsp;";

    constructor(
        private domSanitizer: DomSanitizer,
        private elementRef: ElementRef,
        private translate: TranslateFacadeService,
        private fieldFormatService: FieldFormatService,
        private utilService: UtilService) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.displayInfo();
    }

    ngOnInit(): void {
        this.displayInfo();
        this.translateSubscription = this.translate.onLangChange.subscribe(() => this.displayInfo());
    }

    ngOnDestroy(): void {
        this.translateSubscription?.unsubscribe();
    }

    private displayInfo(updatedData?: any): void {
        let format = this.applet.config.formats[this.fieldName];

        if (!_.isNil(this.model.value)) {
            const data = updatedData ? updatedData : this.parentModel;
            const fieldName = this.config.icLabelModel.icClassName;

            format = this.utilService.getConditionalFormatRulesFieldMask(format, this.conditionalFormats, data, fieldName);
            this.text = this.fieldFormatService.format(format, this.model);

            if (this.model.isHtml) {
                this.property = 'innerHtml';
                this.text = this.domSanitizer.sanitize(SecurityContext.HTML, this.text);
            }
        }

        //we would never want to show '&nbsp;' as text
        this.property = (this.text === "&nbsp;") ? 'innerHtml' : this.property;
        if (!_.isNil(this.text) && this.property === 'innerText') this.elementRef.nativeElement[this.property] = this.text;
        this.elementRef.nativeElement.className = this.cssClass;
    }

}
