import { AppsConstants } from "../state/apps.constants";
import { AppsEntity } from "../state/apps.models";

export abstract class BaseCommand {

    getConditionalRules(commandConfig: CommandConfig): any {
        let conditions = commandConfig.options.path.Path;
        if (!_.isNil(commandConfig.options.path.ConditionalRedirect))
            conditions = commandConfig.options.path.ConditionalRedirect;
        else if (!_.isNil(commandConfig.options.path.ConditionalFieldMappings))
            conditions = commandConfig.options.path.ConditionalFieldMappings;
        return conditions;
    }

    callWindowFunction(workflowName: string, themeName: string, fnName: string, context: any): boolean | any {
        const windowFunction = this.getWindowFunction(workflowName, themeName, fnName);
        if (_.isFunction(windowFunction))
            return windowFunction.call(context);
        return false;
    }

    protected getWindowFunction(workflowName: string, themeName: string, fnName: string): (...args: any[]) => any {
        let windowFunction;
        const functions = window.IC.functions ?? {};
        const workflowScope = workflowName.normalize();
        if (functions[workflowScope] && functions[workflowScope][fnName])
            windowFunction = functions[workflowScope][fnName];
        const themeScope = themeName.normalize();
        if (functions[themeScope] && functions[themeScope][fnName])
            windowFunction = functions[themeScope][fnName];
        return windowFunction;
    }

    setLocationHref(url: string): void {
        if (_.isNil(url)) return;
        //Typescript is inferring WorkerLocation and calling url readonly in unit tests
        //TODO: figure out why
        setTimeout(() => (window as Window).location.href = url, 0);
    }

    isCommandTypeOf(typeOf: string, action: string): boolean {
        if (_.isNil(action)) return false;
        const index = _.findIndex([typeOf], (prefix) => action.toLowerCase().indexOf(prefix) === 0);
        return index > -1;
    }

    isTupCommand(action: string): boolean {
        return this.isCommandTypeOf('tup-', action);
    }

    isMupCommand(action: string): boolean {
        return this.isCommandTypeOf('mup-', action);
    }

    isTupOrMup(action: string): boolean {
        return this.isTupCommand(action) || this.isMupCommand(action);
    }

    parseATierMessage(aTierMessage: string, pTierConfig: string): string {
        // Expected format of aTierMessage from A-Tier: |SOME_ERROR_CATEGORY|1234|Message from the A-Tier logic
        const aTierMessageTokens = aTierMessage.split('|');
        // Expected format of pTierConfig content from Transaction Editor config: |SOME_ERROR_CATEGORY|Custom message that will override the A-Tier message (optional)
        const pTierConfigTokens = (pTierConfig && pTierConfig.split('|')) || [];
        const aTierErrorNameIndex = _.findIndex(aTierMessageTokens, (amt) => !_.isEmpty(amt));
        if (aTierErrorNameIndex < 0) {
            return aTierMessage;
        }
        const errorName = aTierMessageTokens[aTierErrorNameIndex].trim();
        const pTierErrorNameIndex = _.indexOf(pTierConfigTokens, errorName);
        const hasErrorMatch = pTierErrorNameIndex > -1;
        const hasOverride = pTierConfigTokens.length > 2;
        if (hasErrorMatch && hasOverride) {
            return pTierConfigTokens[pTierErrorNameIndex + 1];
        }
        return aTierMessageTokens[aTierErrorNameIndex + 2] || aTierMessageTokens[aTierErrorNameIndex];
    }

    getCommandParameter(commandConfig: CommandConfig, index: number): any {
        return _.isArray(commandConfig.parameters) && commandConfig.parameters.length > index
            ? commandConfig.parameters[index] : null;
    }

    getComponentName(appName: string): string {
        return "CL" + appName.replace(/\./g, '').replace(/_/g, '');
    }

    getFieldClassName(fieldName: string): string {
        return ".CL_" + fieldName.replace(/\./g, '').replace(/_/g, '');
    }

    isUrl(url: string): boolean {
        return RegExp("((http[s]?|ftp)://):?").test(url);
    }

    isTruthy(value: any): boolean {
        if (_.isNil(value)) return false;
        return _.includes(["true", "yes", "y"], value.toString().toLowerCase().trim());
    }

    getIcUrl(url: string): any {
        const options: any = {};
        const returnUrlRegex = new RegExp("\\?(ReturnURL\\=.+)\\#", "i");
        options.url = url;
        options.prefix = '?#!';
        url = url.replace(returnUrlRegex, '?#');
        url = url.replace(options.prefix, '#');
        const parts = url.split('?');
        if (parts.length == 2 || parts.length == 3) {
            let segments = parts[0].split('/');
            options.folder = segments[1];
            options.page = segments[2] && segments[2].replace('#', '').replace('!', ''); // remove trailing "!" if present
            if (segments.length > 3) {
                options.wf = segments[4];
                const search = parts[1].split('s=');
                options.step = search[1];
            }
            if (parts.length == 3) {
                segments = parts[1].split('/');
                options.wf = segments[segments.length - 1];
                const search = parts[2].split('s=');
                options.step = search[1];
            }
        }
        return options;
    }

    getModelDefaultValueOrDefault(appComponent: any, fieldName: string, fieldValue: any): any {
        if (_.isNil(appComponent?.modelDefaultValues)) return fieldValue;
        return appComponent.modelDefaultValues[fieldName]?.value || fieldValue;
    }

    getActivateTabEventEntity(commandConfig: CommandConfig): AppsEntity {
        const mapIndex = commandConfig.options.i;
        const targetApp = (commandConfig.parameters.length > 1) ? commandConfig.parameters[1] : commandConfig.options.path
        const eventName = targetApp + ":ActivateTab";
        const tabName = commandConfig.parameters[mapIndex];
        const eventEntity = {
            id: eventName,
            state: { tabName },
            replayableEvent: commandConfig.isSubCommand ? true : false
        };
        return eventEntity;
    }

    getFilterFields(commandConfig: CommandConfig): any {
        return commandConfig.options.path;
    }

    getAppReferenced(fieldName: string): any {
        const aux = fieldName.split(".");
        return {
            appName: aux[0].replace(/_/g, '.'),
            fieldName: aux[1]
        };
    }

    isFieldAppReferenced(fieldName: string): boolean {
        return fieldName.indexOf('.') != -1;
    }

    getFieldMapInfo(fieldMap: FieldMap, fieldIndex: number): { source: { appName: string, fieldName: string }, target: { appName: string, fieldName: string } } {

        const sourceFieldName = fieldMap.sourceFields[fieldIndex];
        const targetFieldName = fieldMap.targetFields[fieldIndex];

        const isSourceFieldAppReference = this.isFieldAppReferenced(sourceFieldName);
        const isTargetFieldAppReference = this.isFieldAppReferenced(targetFieldName);
        let targetInfo;
        let sourceInfo;
        if (isTargetFieldAppReference && isSourceFieldAppReference) {
            targetInfo = this.getAppReferenced(targetFieldName);
            sourceInfo = this.getAppReferenced(sourceFieldName);
        } else if (isTargetFieldAppReference && !isSourceFieldAppReference) {
            targetInfo = this.getAppReferenced(targetFieldName);
            sourceInfo = {
                appName: fieldMap.sourceApp,
                fieldName: sourceFieldName
            };
        } else if (!isTargetFieldAppReference && isSourceFieldAppReference) {
            sourceInfo = this.getAppReferenced(sourceFieldName);
            targetInfo = {
                appName: fieldMap.targetApp,
                fieldName: targetFieldName
            };
        } else {
            sourceInfo = {
                appName: fieldMap.sourceApp,
                fieldName: sourceFieldName
            };
            targetInfo = {
                appName: fieldMap.targetApp,
                fieldName: targetFieldName
            };
        }
        return {
            source: sourceInfo,
            target: targetInfo
        }
    }

    getTargetAppDataSource(commandConfig: CommandConfig): string {
        const path = commandConfig.options.path;
        return path ? path.Path : null;
    }

    getFieldMap(commandConfig: CommandConfig, i?: number | any): FieldMap {
        const mapIndex = _.isNumber(i) ? i : commandConfig.options.i;
        let fieldMap = commandConfig.parameters[mapIndex];
        if (!this.shouldDefaultMapBeUsed(commandConfig, fieldMap)) return null;
        fieldMap = this.getFieldMapOrDefault(commandConfig, fieldMap);
        return this.getFieldMapBase(fieldMap, commandConfig.appName);
    }

    private shouldDefaultMapBeUsed(commandConfig: CommandConfig, fieldMap: string | FieldMap): boolean {
        const action = this.getActionName(commandConfig).toLowerCase();
        if (action.indexOf('mup') != -1 && fieldMap === "") { // If no fieldMap specified, there is an implicit one
            return true;
        }
        return fieldMap != "";
    }

    protected getActionName(commandConfig: CommandConfig, i?: number | any): string {
        const mapIndex = _.isNumber(i) ? i : commandConfig.options.i;
        if (commandConfig.parameters[mapIndex].directive == AppsConstants.triggerDirective.none)
            return commandConfig.action;
        return this.getActionFromTriggerDirective(commandConfig, mapIndex);
    }

    protected getActionFromTriggerDirective(commandConfig: CommandConfig, i: number | any): string {
        let action = "";
        const mapIndex = _.isNumber(i) ? i : commandConfig.options.i;
        switch (commandConfig.parameters[mapIndex]?.directive) {
            case AppsConstants.triggerDirective.apf:
                action = "APF";
                break;
            case AppsConstants.triggerDirective.keyFirst:
                action = "Key-First";
                break;
            case AppsConstants.triggerDirective.keyRead:
                action = "Key-Read";
                break;
            case AppsConstants.triggerDirective.keyCreate:
                action = "Key-Create";
                break;
            default:
                action = commandConfig.action;
                break;
        }
        return action;
    }

    private getFieldMapOrDefault(commandConfig: CommandConfig, fieldMap: string | any): FieldMap {
        const action = this.getActionName(commandConfig).toLowerCase();
        if (action.indexOf('mup') != -1 && fieldMap === "") {
            return {
                targetApp: commandConfig.appName,
                sourceFields: [],
                targetFields: [],
                targetFieldsToClear: [],
                sourceApp: commandConfig.appName,
                directive: AppsConstants.triggerDirective.none
            };
        }
        if (fieldMap.sourceFields === null) fieldMap.sourceFields = [];
        if (fieldMap.targetFields === null) fieldMap.targetFields = [];
        return fieldMap;
    }

    private getFieldMapBase(fieldMap: any, appName: string): FieldMap {

        if (_.isString(fieldMap) && fieldMap.trim().length > 0) {
            const aux = {
                sourceApp: appName,
                sourceFields: [],
                targetFields: [],
                targetFieldsToClear: [],
                targetApp: "",
                directive: AppsConstants.triggerDirective.none
            };
            const parts = fieldMap.split(",");
            aux.targetApp = parts[0];

            if (parts.length == 1) return aux;

            const fields = parts[1].split("|");
            fields.forEach(field => {
                const mapItem = field.split("=");
                aux.sourceFields.push(mapItem[1]);
                aux.targetFields.push(mapItem[0]);
            });
            fieldMap = aux;
        }

        if (_.isNil(fieldMap.sourceApp) || !fieldMap.sourceApp)
            fieldMap.sourceApp = appName;

        return fieldMap;
    }

    convertSelectedRowsToDSFormat(selectedRows: any[]): any[] {
        const dataFormatted = [];
        if (selectedRows.length > 0) {
            const headerSet = Object.keys(selectedRows[0]).map((key) => key);
            dataFormatted.push(headerSet);
            selectedRows.forEach((selectedRow) => {
                const rowSet = Object.keys(selectedRow).map((key) => selectedRow[key]);
                dataFormatted.push(rowSet);
            });
        }
        return dataFormatted;
    }

    shouldWaitForApplyFilterData(parameters: unknown[]): boolean {
        return parameters?.length > 2 ? this.isTruthy(parameters[2]) : false;
    }
}
