import { Injectable } from '@angular/core';
import { AppsConstants } from '../state/apps.constants';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { CommandParser } from './command-parser.service';

@Injectable()
export class CommandListFactory {

    private supportedCommands: Record<string, Command>;

    constructor(private commandParser: CommandParser,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        this.supportedCommands = this.getSupportedCommands();
    }

    createCommandList(commandList, config): any[] {
        if (_.isNil(commandList)) return [];
        return this.parseCommandList(commandList, config);
    }

    private getSupportedCommands(): Record<string, Command> {

        const fieldMapIndex = AppsConstants.fieldMapIndex;
        const fieldMapCmds = {
            "map fields": { command: 'setValues' },
            "setvalues": { command: 'setValues' },
            "triggerdirectivecalls": { command: 'triggerDirectiveCalls' },
            "conditionalRedirect": { command: 'conditionalRedirectResolver' },
            "conditionalFieldMappings": { command: 'conditionalRedirectResolver' },
            "redirect": { command: 'redirectMapper' },
            "redirect without state refresh": { command: 'redirectMapper' },
            "dynamicreplacement": { command: 'dynamicReplacement' },
            "openpopup": { command: 'openWindow' },//same as open new window
            "open new window": { command: 'openWindow' },//same as openpopup
            "clear step events": { command: 'clearStepEvents' },
        };
        const setLocaleCmds = {
            "dynamicreplacement": { command: 'dynamicReplacement' },
            "set locale": { command: 'setLocale' }
        };
        const commands = {};

        commands["redirect to document"] = { parse: 'defaultParser', command: 'redirectToDocumentResolver', i: fieldMapIndex.none };
        commands["event - publish"] = { parse: 'defaultParser', command: 'eventPublish', i: fieldMapIndex.event };
        commands["event - set values from event"] = { parse: 'defaultParser', command: 'eventSetValues', i: fieldMapIndex.event };
        commands["setvalues"] = { parse: 'defaultParser', command: 'setValues', i: fieldMapIndex.none };
        commands["applyfilter"] = { parse: 'defaultParser', command: 'applyFilterMapper', i: fieldMapIndex.none };
        commands["applyclientsidefilter"] = { parse: 'defaultParser', command: 'applyClientSideFilter', i: fieldMapIndex.none };
        commands["redirect"] = { parse: 'multipleCommandParser', command: 'redirectMapper', i: fieldMapIndex.none, cmds: fieldMapCmds, addClearOnRedirect: true };
        commands["redirect without state refresh"] = { parse: 'multipleCommandParser', command: 'redirectMapper', i: fieldMapIndex.none, cmds: fieldMapCmds, addClearOnRedirect: false };
        commands["map fields"] = { parse: 'multipleCommandParser', command: 'setValues', i: fieldMapIndex.none, cmds: fieldMapCmds };
        commands["open modal popup"] = { parse: 'openModalParser', command: 'openModalPopup', i: fieldMapIndex.none };
        commands["openpopup"] = { parse: 'multipleCommandParser', command: 'openWindow', i: fieldMapIndex.none, cmds: fieldMapCmds };
        commands["open new window"] = { parse: 'multipleCommandParser', command: 'openWindow', i: fieldMapIndex.none, cmds: fieldMapCmds };
        commands["component - send command"] = { parse: 'defaultParser', command: 'sendCommandMapper', i: fieldMapIndex.api };
        commands["set values from list to single field"] = { parse: 'defaultParser', command: 'setListSelectedValues', i: fieldMapIndex.none };
        commands["select items in list"] = { parse: 'defaultParser', command: 'selectItemsInList', i: fieldMapIndex.none };
        commands["setvalues for report params"] = { parse: 'defaultParser', command: 'setValuesForReportParams', i: fieldMapIndex.event };
        commands["set values for columns in list"] = { parse: 'noCommandParser', command: 'setValuesForColumnInList', i: fieldMapIndex.event };
        commands["accept interstitial page message"] = { parse: 'defaultParser', command: 'interstitialPageMapper', i: fieldMapIndex.none };
        commands["set remember user"] = { parse: 'noCommandParser', command: 'setRememberUser', i: fieldMapIndex.none };
        commands["stop execution if"] = { parse: 'noCommandParser', command: 'stopExecutionIf', i: fieldMapIndex.none };
        commands["enable disable"] = { parse: 'noCommandParser', command: 'enableDisable', i: fieldMapIndex.none };
        commands["change menu items overlay"] = { parse: 'noCommandParser', command: 'changeMenuItemsOverlay', i: fieldMapIndex.none };
        commands["post message to iframe parent"] = { parse: 'defaultParser', command: 'postMessageToIFrameParent', i: fieldMapIndex.none };
        commands["set grouping set"] = { parse: 'defaultParser', command: 'setGroupingSet', i: fieldMapIndex.event };
        commands["enable / disable group blocking"] = { parse: 'defaultParser', command: 'enableDisableGroupBlocking', i: fieldMapIndex.event };
        commands["open / close groups"] = { parse: 'defaultParser', command: 'openCloseGroups', i: fieldMapIndex.event };
        commands["trigger webhook"] = { parse: 'defaultParser', command: 'triggerWebHook', i: fieldMapIndex.none };
        commands["conditionally show / hide tab"] = { parse: 'defaultParser', command: 'conditionallyShowHideTab', i: fieldMapIndex.none };

        const clientCmds = [
            "export to excel",
            "export to excel and archive",
            "print page to pdf and archive"
        ];
        for (const cmd in clientCmds) {
            commands[clientCmds[cmd]] = { parse: 'defaultParser', command: 'setValues', i: fieldMapIndex.none };
        }

        const apiCmds = ["apf", "fva", "key-create", "key-first", "key-read",
            "mup-create", "mup-delete", "mup-merge", "mup-update",
            "tup-create", "tup-delete", "tup-update", "tup-merge"];
        for (const cmd in apiCmds) {
            commands[apiCmds[cmd]] = { parse: 'defaultParser', command: 'apiMapper', i: fieldMapIndex.api };
        }

        // Non Map Commands
        commands["set locale"] = { parse: 'setLocaleParser', command: 'setLocale', i: fieldMapIndex.none, cmds: setLocaleCmds };
        commands["close modal popup"] = { parse: 'noCommandParser', command: 'closeModalPopup', i: fieldMapIndex.none };
        commands["logout"] = { parse: 'noCommandParser', command: 'logout', i: fieldMapIndex.none };
        commands["show message"] = { parse: 'noCommandParser', command: 'showMessage', i: fieldMapIndex.none };
        commands["confirm dialog"] = { parse: 'noCommandParser', command: 'confirmDialog', i: fieldMapIndex.none };
        commands["custombrowsercode"] = { parse: 'noCommandParser', command: 'customBrowserCode', i: fieldMapIndex.none };
        commands["show loading panel"] = { parse: 'noCommandParser', command: 'showLoadingPanel', i: fieldMapIndex.none };
        commands["hide loading panel"] = { parse: 'noCommandParser', command: 'hideLoadingPanel', i: fieldMapIndex.none };
        commands["show loading field indicator"] = { parse: 'noCommandParser', command: 'showLoadingFieldIndicator', i: fieldMapIndex.none };
        commands["hide loading field indicator"] = { parse: 'noCommandParser', command: 'hideLoadingFieldIndicator', i: fieldMapIndex.none };
        commands["validate form"] = { parse: 'noCommandParser', command: 'validateForm', i: fieldMapIndex.none };
        commands["clear form values"] = { parse: 'noCommandParser', command: 'clearFormValues', i: fieldMapIndex.none };
        commands["clear field value"] = { parse: 'noCommandParser', command: 'clearFieldValue', i: fieldMapIndex.none };
        commands["search for address details"] = { parse: 'noCommandParser', command: 'searchForAddressDetails', i: fieldMapIndex.none };
        commands["print page to pdf"] = { parse: 'noCommandParser', command: 'printToPDF', i: fieldMapIndex.none };
        commands["refresh list"] = { parse: 'noCommandParser', command: 'refreshList', i: fieldMapIndex.none };
        commands["admin becomes user"] = { parse: 'noCommandParser', command: 'setAdminBecomesUser', i: fieldMapIndex.none };
        commands["login"] = { parse: 'noCommandParser', command: 'loginUser', i: fieldMapIndex.none };
        commands["loginusingoauth"] = { parse: 'noCommandParser', command: 'loginUsingOAuth', i: fieldMapIndex.none };
        commands["clear filter"] = { parse: 'noCommandParser', command: 'clearFilter', i: fieldMapIndex.none };
        commands["ada - set live region"] = { parse: 'noCommandParser', command: 'adaSetLiveRegion', i: fieldMapIndex.none };
        commands["set focus"] = { parse: 'noCommandParser', command: 'setFocus', i: fieldMapIndex.none };
        commands["canvas - load"] = { parse: 'noCommandParser', command: 'canvasLoad', i: fieldMapIndex.none };
        commands["canvas - tidy up"] = { parse: 'noCommandParser', command: 'canvasTidyUp', i: fieldMapIndex.none };
        commands["canvas - print"] = { parse: 'noCommandParser', command: 'canvasPrint', i: fieldMapIndex.none };
        commands["canvas - delete"] = { parse: 'noCommandParser', command: 'canvasDelete', i: fieldMapIndex.none };
        commands["canvas - clone"] = { parse: 'noCommandParser', command: 'canvasClone', i: fieldMapIndex.none };
        commands["treelist - add new row"] = { parse: 'noCommandParser', command: 'treeListNotifyAddRow', i: fieldMapIndex.none };
        commands["treelist - cancel edit"] = { parse: 'noCommandParser', command: 'treeListNotifyCancel', i: fieldMapIndex.none };
        commands["treelist - save"] = { parse: 'noCommandParser', command: 'treeListNotifySave', i: fieldMapIndex.none };
        commands["redirecttoexternalurl"] = { parse: 'noCommandParser', command: 'redirectToExternalURL', i: fieldMapIndex.none };
        commands["loginusingsaml"] = { parse: 'noCommandParser', command: 'loginUsingSAML', i: fieldMapIndex.none };
        commands["button - set visually selected"] = { parse: 'defaultParser', command: 'setButtonVisuallySelected', i: fieldMapIndex.none };
        commands["announce text"] = { parse: 'defaultParser', command: 'announceText', i: fieldMapIndex.none };
        commands["retrieve persisted objects in zip"] = { parse: 'noCommandParser', command: 'retrievePersistedReportInZipMapper', i: fieldMapIndex.none };
        commands["run dynamic replacement"] = { parse: 'noCommandParser', command: 'runDynamicReplacement', i: fieldMapIndex.none };
        commands["set first dropdown item"] = { parse: 'noCommandParser', command: 'setFirstDropdownItem', i: fieldMapIndex.none };
        commands["save list editing changes"] = { parse: 'noCommandParser', command: 'saveListEditingChanges', i: fieldMapIndex.none };
        commands["discard list editing changes"] = { parse: 'noCommandParser', command: 'discardListEditingChanges', i: fieldMapIndex.none };
        commands["clear local data source"] = { parse: 'noCommandParser', command: 'clearLocalDataSource', i: fieldMapIndex.none };
        commands["trigger special effect"] = { parse: 'defaultParser', command: 'triggerSpecialEffect', i: fieldMapIndex.event };
        commands["tab to activate"] = { parse: 'defaultParser', command: 'tabToActivateConditionally', i: fieldMapIndex.none };
        commands["reset all fields to pristine"] = { parse: 'noCommandParser', command: 'resetFieldsPristine', i: fieldMapIndex.none };
        commands["mark all fields pristine"] = { parse: 'noCommandParser', command: 'markFieldsPristine', i: fieldMapIndex.none };
        commands["reload role"] = { parse: 'defaultParser', command: 'reloadRole', i: fieldMapIndex.none };
        commands["reload current page"] = { parse: 'defaultParser', command: 'reloadCurrentPage', i: fieldMapIndex.none };
        commands["evict cache"] = { parse: 'defaultParser', command: 'evictCache', i: fieldMapIndex.event };
        commands["execute xamarin command"] = { parse: 'noCommandParser', command: 'executeXamarinCommand', i: fieldMapIndex.none };
        commands["refresh dropdownlist"] = { parse: 'noCommandParser', command: 'refreshDropdownList', i: fieldMapIndex.none };
        commands["refresh template app"] = { parse: 'noCommandParser', command: 'refreshTemplateApp', i: fieldMapIndex.none };
        commands["refresh component"] = { parse: 'noCommandParser', command: 'refreshComponent', i: fieldMapIndex.none };
        commands["report - request"] = { parse: 'noCommandParser', command: 'reportRequest', i: fieldMapIndex.none };
        commands["reset validation group"] = { parse: 'noCommandParser', command: 'resetValidationGroup', i: fieldMapIndex.none };
        commands["transfer user-agent metrics information"] = { parse: 'noCommandParser', command: 'transferUserAgentMetricsInformation', i: fieldMapIndex.none };
        commands["clear context"] = { parse: 'noCommandParser', command: 'clearContext', i: fieldMapIndex.none };
        commands["set class"] = { parse: 'noCommandParser', command: 'setClass', i: fieldMapIndex.none };
        commands["toggle side navigation"] = { parse: 'defaultParser', command: 'toggleSideNavigation', i: fieldMapIndex.none };
        commands["export from list app"] = { parse: 'noCommandParser', command: 'exportFromListApp', i: fieldMapIndex.none };
        commands["expand group"] = { parse: 'noCommandParser', command: 'expandGroup', i: fieldMapIndex.none };
        commands["set values for row in list"] = { parse: 'noCommandParser', command: 'setValuesForRowInList', i: fieldMapIndex.none };
        commands["set cookie consent state"] = { parse: 'noCommandParser', command: 'setCookieConsentState', i: fieldMapIndex.none };
        commands["clear google tag datalayer"] = { parse: 'noCommandParser', command: 'clearGoogleTagDataLayer', i: fieldMapIndex.none };
        commands["push to google tag datalayer"] = { parse: 'noCommandParser', command: 'pushToGoogleTagDataLayer', i: fieldMapIndex.none };
        commands["save personalize menu"] = { parse: 'noCommandParser', command: 'savePersonalizeMenu', i: fieldMapIndex.none };
        commands["clear mutually exclusive fields"] = { parse: 'noCommandParser', command: 'clearMutuallyExclusiveFields', i: fieldMapIndex.none };
        commands["content editor - insert image"] = { parse: 'noCommandParser', command: 'cmsInsertImage', i: fieldMapIndex.none };
        commands["clear client services cache"] = { parse: 'noCommandParser', command: 'clearClientServicesCache', i: fieldMapIndex.none };
        commands["scroll into view"] = { parse: 'noCommandParser', command: 'scrollIntoView', i: fieldMapIndex.none };
        commands["push to tag managers"] = { parse: 'noCommandParser', command: 'pushToTagManagers', i: fieldMapIndex.none };
        commands["datagrid pagination buttons"] = { parse: 'noCommandParser', command: 'datagridPaginationButtons', i: fieldMapIndex.none };
        commands["clear all multi file uploads"] = { parse: 'noCommandParser', command: 'clearAllFileMultiUploadFields', i: fieldMapIndex.none };
        commands["update personalization group item"] = { parse: 'noCommandParser', command: 'updatePersonalizationGroupItem', i: fieldMapIndex.none };
        commands["map personalization group item to field"] = { parse: 'noCommandParser', command: 'mapPersonalizationGroupItemToField', i: fieldMapIndex.none };

        return commands;
    }

    private getExtraConfig(action, cmdIndex, config) {
        const pathToken = action + "|" + cmdIndex,
            conditionalRulesClearTargetFieldToken = action + "|" + cmdIndex + "|ConditionalRulesForTargetFieldToClear",
            mutuallyExclusiveSettingsToken = action + "|" + cmdIndex + "|MutuallyExclusiveSettings",
            conditionalFieldMappingsToken = action + "|" + cmdIndex + "|ConditionalFieldMappings",
            conditionalRedirectToken = action + "|" + cmdIndex + "|ConditionalRedirect",
            auxConfig = {};
        if (config[pathToken]) auxConfig["Path"] = config[pathToken];
        if (config[conditionalRulesClearTargetFieldToken]) auxConfig["ConditionalRulesForTargetFieldToClear"] = config[conditionalRulesClearTargetFieldToken];
        if (config[mutuallyExclusiveSettingsToken]) auxConfig["MutuallyExclusiveSettings"] = config[mutuallyExclusiveSettingsToken];
        if (config[conditionalFieldMappingsToken]) auxConfig["ConditionalFieldMappings"] = config[conditionalFieldMappingsToken];
        if (config[conditionalRedirectToken]) auxConfig["ConditionalRedirect"] = config[conditionalRedirectToken];
        return _.isEmpty(auxConfig) ? null : auxConfig;
    }

    private parseSubCommandList(subCommand, appName, config) {
        const subCommands = {};


        const action = subCommand.action.toLowerCase() || "";
        subCommands["tab to activate"] = { parse: 'noCommandParser', command: 'activateTab', i: AppsConstants.fieldMapIndex.none };
        subCommands["clear context on redirect"] = { parse: 'noCommandParser', command: 'clearContextOnRedirect', i: AppsConstants.fieldMapIndex.none };

        const cmdConfig = subCommands[action];
        if (cmdConfig) {
            const options: CommandOptions = {
                path: config['targetApp'] || null,
                i: cmdConfig.i,
            };
            const _config: CommandConfig = {
                ...subCommand,
                action: subCommand.action,
                module: cmdConfig.module,
                command: cmdConfig.command,
                appName: appName,
                parameters: this.commandParser[cmdConfig.parse](subCommand.parameters, cmdConfig.i),
                options: options,
                isSubCommand: true,
            };

            return _config;

        } else {
            IX_Log("cmdLst", "unknown sub command", subCommand.action);
        }
        return null;
    }

    private parseCommandList(commandList, extras) {
        const parsedCmds = [];
        for (let cmdIndex = 0; cmdIndex < commandList.length; cmdIndex++) {
            const action = commandList[cmdIndex].action.toLowerCase();
            const cmdConfig = this.supportedCommands[action];
            if (cmdConfig) {

                const options: any = {};

                let config = this.commandParser[cmdConfig.parse](commandList[cmdIndex].parameters, cmdConfig.i, action, cmdConfig.addClearOnRedirect);

                const cmdBreakdown = config.cmdBrkdwn || [];
                const parameters = config.parameters || config;

                config = {};

                options.path = this.getExtraConfig(commandList[cmdIndex].action, cmdIndex, extras);
                options.i = cmdConfig.i;
                options.e = extras.e;
                config.action = commandList[cmdIndex].action;
                config.command = cmdConfig.command;
                config.appName = extras.appName;
                config.parameters = parameters;
                config.appThatOverrides = extras.appThatOverrides;
                config.isSubCommand = false;

                if (extras.contextMutuallyExclusiveConfig) {
                    config.contextMutuallyExclusiveConfig = extras.contextMutuallyExclusiveConfig;
                }

                if (cmdBreakdown.length === 0) {
                    config.options = options;
                    parsedCmds.push(config);
                } else {
                    cmdBreakdown.forEach((cmd) => {
                        const newConf = _.cloneDeep(config)
                        newConf.action = commandList[cmdIndex].action + "-" + cmd;
                        newConf.module = cmdConfig.cmds[cmd].module;
                        newConf.command = cmdConfig.cmds[cmd].command;
                        newConf.options = options;
                        parsedCmds.push(newConf);
                    });
                }

                if (!Array.isArray(parameters)) continue;

                const appObject = this.getAppState(config.appName);
                for (let z = 0; z < parameters.length; z++) {
                    if (!Array.isArray(parameters[z].commands)) continue;
                    if ("Redirect" === commandList[cmdIndex].action
                        && "isConditionalRedirect" === commandList[cmdIndex].parameters[0]) {
                        const conditions = options.path.ConditionalRedirect;
                        for (const field in conditions.fieldToRuleSet) {
                            const rule = conditions.ruleSets[field][z];
                            const ruleFunction = rule.condition || window[rule.functionName];
                            const result = ruleFunction.call(appObject);
                            if (result) {
                                for (let t = 0; t < parameters[z].commands.length; t++) {
                                    const command = parameters[z].commands[t];
                                    const subCmd = this.parseSubCommandList(command, config.appName, parameters[z]);
                                    if (subCmd) {
                                        parsedCmds.push(subCmd);
                                    }
                                }
                            }
                        }
                    } else {
                        for (let t = 0; t < parameters[z].commands.length; t++) {
                            const command = parameters[z].commands[t];
                            const subCmd = this.parseSubCommandList(command, config.appName, parameters[z]);
                            if (subCmd) {
                                parsedCmds.push(subCmd);
                            }
                        }
                    }
                }

            } else {
                IX_Log("cmdLst", "unknown command", commandList[cmdIndex].action);
            }
        }
        return parsedCmds;
    }

    private getAppState(appName: string): AppState {
        // TODO: figure out an alternative for this
        const appComponent = this.domComponentRetrievalService.getAppComponent(appName)
        return appComponent?.getStateFromModel();
    }

}
