/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import Sugar from 'sugar/date';
import { TranslateFacadeService } from './translate-facade.service';
import { DynamicReplacementService } from './dynamic-replacement.service';
import { FieldFormatService } from './field-format.service';
import { DataSourceService } from './datasource.service';
import { UtilService } from './util.service';
import { DeviceService } from './device-information.service';
import { ListApplication } from '../components/list-application';
import { HelpersService } from './helpers.service';
import { ThemeService } from './theme.service';

@Injectable()
export class CsvGenerator {

    constructor(private deviceService: DeviceService,
        private translateFacade: TranslateFacadeService,
        private utilService: UtilService,
        private fieldFormatService: FieldFormatService,
        private dynamicReplacementService: DynamicReplacementService,
        private dataSourceService: DataSourceService,
        private helpersService: HelpersService,
        private themeService: ThemeService,
        ) { }

    exportToCsv(listAppComponent: ListApplication | any, exportType: string): Promise<any> {

        const dxDataGrid = listAppComponent?.dxDataGrid;

        if (_.isNil(dxDataGrid)) return;

        const csv = { value: '' };
        const isFlatFile = "CSVFlat".EqualsIgnoreCase(exportType);
        const headers = this.getColumnHeaders(dxDataGrid, isFlatFile);
        const conditionalFormats = listAppComponent.conditionalFormats;
        return this.getDataSourceItems(listAppComponent)
            .then(items => this.buildCsvFile(csv, headers, items, dxDataGrid, isFlatFile, conditionalFormats));
    }

    getColumnHeaders(dxDataGrid: any, isFlatFile: boolean) {
        const visibleColumns = dxDataGrid.getVisibleColumns();
        return isFlatFile ? _.filter(visibleColumns, (column) => !_.isNil(column.dataField))
            : _.filter(visibleColumns, (column) => !_.isNil(column.dataField) && _.isUndefined(column.groupIndex));
    }

    getDataSourceItems(listAppComponent: ListApplication): Promise<any> {
        const dxDataGrid = listAppComponent.dxDataGrid;
        const dataSource = dxDataGrid.getDataSource();
        const items = dataSource.items();
        const totalCount = dataSource.totalCount();
        if (items.length < totalCount) {
            // Paging preventing availability of all items in grouped format
            // We need to create (1) a custom DataSource and (2) an instance of DataGrid to group the returned items
            const ecdRequest = listAppComponent.applet.config.ecdRequest;
            const customSortColumns = this.createCustomSortColumnMaps(listAppComponent.gridProperties.columns);
            // TODO: update to use store approach
            const dataSource = this.dataSourceService.createCustomDataSource(ecdRequest, customSortColumns, true);
            const filter = dxDataGrid.getCombinedFilter();
            if (_.isArray(filter) && filter.length > 0)
                dataSource.filter(filter);
            return dataSource.load();
        }
        return Promise.resolve(items);
    }

    getCustomSortThemeOverride(customSort) {
        const hasReplacement = this.dynamicReplacementService.hasReplacementValue(customSort);
        if (hasReplacement) {
            customSort = customSort.replace("{ThemeProperty:", "").replace("}", "");
            customSort = this.dynamicReplacementService.getThemePropertyReplacement(customSort);
        }
        return customSort;
    }

    createColumnCustomSortMap(customSort) {
        const map = {};
        customSort = this.getCustomSortThemeOverride(customSort);
        if (_.isString(customSort) && customSort.length > 0) {
            const elements = customSort.trim().split('|');
            elements.forEach((it, i) => {
                if (_.isString(it) && it.length > 0) {
                    map[it] = i;
                }
            });
        }
        return map;
    }

    createCustomSortColumnMaps(columns) {
        const sortMap = {};
        if (_.isArray(columns)) {
            columns.forEach((col) => {
                if (_.isNil(col._customSort)) return;
                sortMap[col.dataField] = this.createColumnCustomSortMap(col._customSort);
                if (_.isEmpty(sortMap[col.dataField])) return;
                col.calculateSortValue = "_customSortFieldName" + col.dataField;
                col.sortOrder = "asc"; // Without sortOrder property, calculateSortValue callback method is not called.
            });
        }
        return sortMap;
    }

    processRow(obj, headers, csv, dxDataGrid, isFlatFile, conditionalFormats) {
        if (!_.isNil(obj.items)) {
            if (obj.key && !isFlatFile) {
                const summary = dxDataGrid.option('summary');
                let aggregates = [];

                if (obj.aggregates && summary.groupItems) {
                    aggregates = this.mapAggregatesToSummaryItems(obj.aggregates, summary.groupItems);
                }

                const headerRow = this.createAggregatesRow(headers, aggregates, obj.key);
                const line = this.buildCsvLine(headerRow, headers, isFlatFile, conditionalFormats);
                this.appendCsvLine(line, csv);
            }

            _.forEach(obj.items, (row) => this.processRow(row, headers, csv, dxDataGrid, isFlatFile, conditionalFormats));
        } else {
            const line = this.buildCsvLine(obj, headers, isFlatFile, conditionalFormats);
            this.appendCsvLine(line, csv);
        }
    }

    buildCsvFile(csv, headers, data, dxDataGrid, isFlatFile, conditionalFormats) {
        // Build headers
        const headersLine = _.map(headers, (header) => `"${header.caption}"`).join();

        this.appendCsvLine(headersLine, csv);

        _.forEach(data, (row) => this.processRow(row, headers, csv, dxDataGrid, isFlatFile, conditionalFormats));

        if (!isFlatFile) {
            const summary = dxDataGrid.option('summary');
            const totalItems = summary?.totalItems;
            if (totalItems) {
                // Append a totals row
                let aggregates = _.map(totalItems, (totalItem) => dxDataGrid.getTotalSummaryValue(totalItem.column));
                aggregates = this.mapAggregatesToSummaryItems(aggregates, totalItems);
                const totalRow = this.createAggregatesRow(headers, aggregates, this.translateFacade.getTranslation('Total'));
                const line = this.buildCsvLine(totalRow, headers, isFlatFile, conditionalFormats);
                this.appendCsvLine(line, csv);
            }
        }

        let fileName = 'Export';
        const exportOption = dxDataGrid.option('export');
        if (!_.isNil(exportOption?.fileName)) {
            fileName = this.generateFileNameForExport(exportOption.fileName);
        }

        fileName += '.csv';

        this.downloadCsv(csv, fileName);
    }

    mapAggregatesToSummaryItems(aggregates, summaryItems) {
        return _.map(aggregates, (aggregate, index) => ({
            column: summaryItems[index].column,
            format: summaryItems[index].format,
            value: aggregate
        }));
    }

    createAggregatesRow(headers, aggregates, title) {
        const ret = {};
        _.forEach(headers, (o, index) => {
            const headerDataField = headers[index].dataField;
            const matchingAggregate = aggregates.length ? _.find(aggregates, (aggregate) => aggregate.column === headerDataField) : null;
            if (index == '0') {
                ret[headerDataField] = title || null;
            } else if (matchingAggregate) {
                ret[headerDataField] = matchingAggregate.value;
            } else {
                ret[headerDataField] = null;
            }
        });
        return ret;
    }

    buildCsvLine(obj, headers, isFlatFile, conditionalFormats) {
        let ret = '';

        _.forEach(headers, (header) => {
            let value = obj[header.dataField] || '';

            if (!isFlatFile && !!value) {
                const fieldMask = this.utilService.getConditionalFormatRulesFieldMask(null, conditionalFormats, obj, header.dataField, null);
                if (fieldMask) {
                    value = this.fieldFormatService.format(fieldMask, value);
                } else if (header.format) {
                    value = DevExpress.formatHelper.format(value, header.format);
                }
            }

            ret += '"' + value + '",';
        });

        return ret.slice(0, -1);
    }

    appendCsvLine(line, csv) {
        csv.value += line + '\r\n';
    }

    generateFileNameForExport(fileName: string): string {
        const date = new Date();
        const sugarDate = new Sugar.Date(date);
        const dateFormat = sugarDate.format('{yyyy}{MM}{dd}{HH}{mm}{ss}');
        return fileName + '_' + dateFormat.raw;
    }

    downloadCsv(csv, fileName) {
        if (iXing.IX_PlatformMobile && this.themeService.getThemeProperty('DownloadCsvAsBase64WebHook')) {
            // Mobile app needs different handling
            this.helpersService.publishOnBase64DownloadEvent(csv.value, fileName);
            return;
        }

        const downloadLink = document.createElement("a");
        const blob = new Blob(["\ufeff", csv.value]);
        const isChrome = /CriOS/.test(navigator.userAgent);

        //TODO: Remove Navigator.msSaveOrOpenBlob
        // Deprecated https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveOrOpenBlob
        if (window?.navigator && window.navigator['msSaveOrOpenBlob']) {
            $(downloadLink)
                .click((event) => {
                    event.stopPropagation(); // Needed as it causes to button click to propagate and causes the file to download twice
                    window.navigator['msSaveOrOpenBlob'](blob, fileName);
                });
        } else if (this.deviceService.IX_isIOS() && isChrome) {
            // Chrome in iOS has issues with javascript-triggered file download
            // This opens as preview in another tab, which is the best we've found.
            // DevExtreme's native Excel export has the same problem, same reason.
            const blobWithMime = new Blob(["\ufeff", csv.value], { type: 'text/csv' });
            const link = URL.createObjectURL(blobWithMime);
            window.open(link, '_blank');
            setTimeout(() => URL.revokeObjectURL(link), 1000);
        } else {
            // Needed as it causes to button click to propagate and causes the file to download twice
            $(downloadLink).click((event) => event.stopPropagation());
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = fileName;
        }

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.helpersService.publishOnDocumentDownloadEvent(URL.createObjectURL(blob), fileName);
    }
}
