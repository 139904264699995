/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { DxPopupComponent } from 'devextreme-angular';
import DxPopup from 'devextreme/ui/popup';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { EventsService } from '../../services/events.service';
import { ApplicationInformation } from '../../services/application-information.service';
import { ModalService } from './../../services/modal.service';
import { AppEvent } from '../../state/app-events.enum';
import { AppsFacade } from '../../state/apps.facade';
import { DeviceService } from './../../services/device-information.service';

type PopupConfig = {
    id: string,
    canvas?: any,
    canvasConfig?: {},
    popup: {
        cssClass?: string,
        screenName?: string,
        showTitle?: boolean,
        title?: string,
        width?: string,
        minWidth?: string,
        height?: string,
        minHeight?: string,
        resizeEnabled?: boolean,
        showCloseButton?: boolean,
        closeOnOutsideClick?: boolean,
        closeAriaLabel?: string
    },
    visible?: boolean,
};

@Component({
    selector: 'ic-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() config: PopupConfig;
    @Input() context;

    @ViewChild("targetPopup", { static: false })
    dxComponent: DxPopupComponent;

    translateSubscription: Subscription;
    onCommandSubscription: Subscription;

    dxScrollView: any;
    observer: MutationObserver;
    destroyed: boolean;

    get dxPopup(): DxPopup {
        return this.dxComponent.instance;
    }

    constructor(private eventsService: EventsService,
        private translate: TranslateFacadeService,
        private applicationInformation: ApplicationInformation,
        private modalService: ModalService,
        private appsFacade: AppsFacade,
        private deviceService: DeviceService) {
        this.destroyed = false;
    }

    ngOnInit(): void {
        this.translateSubscription = this.translate.onLangChange.pipe(
            tap(() => {
                this.updateCloseButtonTexts();
                this.updatePopupTranslations();
            })
        ).subscribe();
        this.onCommandSubscription = this.eventsService.events$
            .pipe(
                filter(event => this.config.id.EqualsIgnoreCase(event?.id)),
                tap(event => this.dxPopup.option(event.state))
            ).subscribe();
    }

    ngAfterViewInit(): void {
        this.updateCloseButtonTexts();
        this.updatePopupTranslations();
    }

    ngOnDestroy(): void {
        this.observer?.disconnect();
        this.translateSubscription?.unsubscribe();
        this.onCommandSubscription?.unsubscribe();
        // This will allow to resume body scrolling
        this.modalService.registerModalClose(this.dxPopup.$element(), this.dxPopup.option("ic.popUpTriggeringElement"));
        this.dxPopup.dispose();
        this.destroyed = true;
    }

    scrollViewOnInitialized(e): void {
        this.dxScrollView = e.component;
        //To remove the empty tab between the two close buttons.
        $(".dx-overlay-content").attr("tabindex", "-1");
        setTimeout(() => this.dxScrollView.container().removeAttr("tabindex"), 10);
    }

    onInitialized(e): void {
        this.onShownAddClass(e);
    }

    onShown(e): void {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.updateCloseButtonTexts();
        this.modalService.registerModalOpen($(e.element), e.component);

        function checkPosition() {
            if (that.destroyed) return;
            const $overlayContent = e.component.$overlayContent();
            const maxWidth = $overlayContent.css('maxWidth');
            const isPercent = _.endsWith(maxWidth, "%");
            let maxWidthValue = parseFloat(maxWidth);

            if (isPercent) {
                maxWidthValue = Math.round((maxWidthValue * window.innerWidth) / 100);
            }

            if (window.innerHeight < $overlayContent.height()
                || !e.component.option('maxHeight')) {
                e.component.option('maxHeight', window.innerHeight * 0.95);
            }
            if (window.innerWidth <= $overlayContent.width()
                || (maxWidth !== 'none' && window.innerWidth <= maxWidthValue)
                || !e.component.option('maxWidth')) {
                e.component.option('maxWidth', window.innerWidth * 0.95);
            }

            // Re-center popup due to the delays of content load.
            e.component.repaint();

            //to check if the modal is inside an iFrame
            //if so to re-center it vertically with the viewport of the parent window
            if ('parentIFrame' in window) {
                window['parentIFrame'].getPageInfo(function (args) {
                    const offset = $overlayContent.position();
                    const scrollPosOfParentWindow = args[0].scrollTop;
                    const topThresholdForModal = Math.round((args[0].windowHeight - $overlayContent.height()) / 2);
                    const top = scrollPosOfParentWindow + topThresholdForModal - args[0].offsetTop;
                    const offsetConfig = { top, left: offset.left };
                    $overlayContent.offset(offsetConfig);
                });
            }
        }

        const debouncedCheckPosition = _.debounce(checkPosition, 100, { leading: false, trailing: true });
        this.observer = new MutationObserver(debouncedCheckPosition);
        // target element that we will observe
        const $content = $(e.component.content());
        const canvasNode = $content.find('.natural-canvas').get(0);
        const observerConfig = {
            childList: true,
            subtree: true
        };
        this.observer.observe(canvasNode, observerConfig);
        debouncedCheckPosition();

        const $componentContainerElement = $content.parent();
        // Don't announce "toolbar" on screen reader
        $componentContainerElement.find('.dx-toolbar').attr('role', 'presentation');
        // Also change title to heading type
        const selector = $componentContainerElement.find('.dx-toolbar-before .dx-toolbar-label .dx-item-content > div');
        if (selector.length > 0) {
            selector.attr('role', 'heading');
            selector.attr('aria-level', '1');
        }

        e.component.option("closeAriaLabel", this.config.popup.closeAriaLabel);
        this.modalService.toolbarCloseButtonADAFixup(e.component, () => e.component.toggle(false));
        $componentContainerElement.find('.dx-closebutton').first().focus();
        this.applyADAFixes($(e.element));

        setTimeout(() => this.dxScrollView?.update(), 50);
    }

    private applyADAFixes($element) {
        if (this.deviceService.IX_isAndroid() || this.deviceService.IX_isIOS()) {
            this.rebindButtonKeyPressEventHandler($element);
        }
    }

    onShowing(e): void {

        // Doing this in onShown would be too late,
        // we'd see a flash of required-validation error
        const targetApp = e.component.option("ic.targetApp");
        const resetFieldsPristineOnOpen = e.component.option("ic.resetFieldsPristineOnOpen");
        if (targetApp && resetFieldsPristineOnOpen) {
            const config = { parameters: [targetApp, ''] };
            const commandConfig = this.createCommandConfigByAction('reset all fields to pristine', config);
            this.appsFacade.resetFieldsPristine(commandConfig)
        }

        // to add the custom css class to the popup
        const cssClass = e.component.option("ic.cssClass");
        if (cssClass) {
            const popupContent = e.component.$overlayContent();
            popupContent?.addClass(cssClass);
        }

        this.modalService.stopBodyScrolling();
    }

    onHidden(e): void {
        const element = e.component.option("ic.popUpTriggeringElement");
        const targetApp = e.component.option("ic.targetApp");
        const resetFieldsPristineOnOpen = e.component.option("ic.resetFieldsPristineOnOpen");
        const appsToIgnoreOnPristineReset = e.component.option("ic.appsToIgnoreOnPristineReset");

        this.modalService.registerModalClose($(e.element), element);

        // Pause the video if present in DOM when modal popup closed
        const videoElement = $('div[data-app="' + targetApp + '"] video');
        if (videoElement && videoElement.length > 0) {
            videoElement.trigger('pause');
        }

        this.observer?.disconnect();

        const clearFormConfigs = [];
        if (resetFieldsPristineOnOpen) {
            //for the cases where the target app in the openModal is a holder app
            if (this.applicationInformation.isHolderApp(targetApp)) {
                const appsInHolder = this.applicationInformation.getAppInfo(targetApp).appsInCanvas;
                const appsToExclude = appsToIgnoreOnPristineReset ? appsToIgnoreOnPristineReset.split(",") : [];
                appsInHolder.forEach(appName => {
                    if (appsToExclude.indexOf(appName) !== -1) return;
                    clearFormConfigs.push({ parameters: [appName] });
                });
            } else {
                clearFormConfigs.push({ parameters: [targetApp] });
            }
        }

        // Required for tablet.
        // Code would set tabIndex to document since element is undefined
        // if (this.deviceService.IX_isMobile()) {
        //     setTimeout(() => {
        //         if (_.isEmpty(element) || _.isEmpty(element[0]))
        //             element = $(document.body); // To set the focus to the body
        //         this.rebindButtonKeyPressEventHandler(element);
        //         element?.focus();
        //     }, 0);
        // }

        // To check if the modal contains a grid,
        // and if so, hide the column chooser (if opened)
        // Fix for column chooser not closing when modal with data grid is closed
        const event = {
            id: AppEvent.HideDataGridOverlays,
            state: { appName: targetApp }
        };
        this.eventsService.publishEvent(event);

        clearFormConfigs.forEach(config => {
            const commandConfig = this.createCommandConfigByAction('clear form values', config);
            this.appsFacade.clearFormValues(commandConfig);
        });
        this.appsFacade.destroyPopup(this.config.id);
    }

    onTitleRendered(e): void {
        this.updateCloseButtonTexts(e);
    }

    onOptionChanged(e): void {
        this.updateCloseButtonTexts(e);
    }

    /**
     * This function rebinds the click event on a button. This is required on Tablet since
     * DevExtreme arbitrarily removes the click event handler and tab index when rendered on
     * Samsung table and iOS.
     */
    private rebindButtonKeyPressEventHandler($buttonElements) {
        const onKeypressHandler = (e) => {
            if (e.keyCode !== 13) return;
            e.srcElement.dispatchEvent(new MouseEvent("click"));
            this.rebindButtonKeyPressEventHandler($buttonElements);
        };
        $(".dx-closebutton").keypress(onKeypressHandler);
        // In the absence of a tabindex the following rebinds the click event handler.
        $buttonElements.each(function () {
            const $button = $(this);
            if ($button.attr("tabindex") != "0") {
                $button.keypress(onKeypressHandler);
                $button.attr("tabindex", "0");
            }
        });
    }

    // Sets a CSS class on the main overlay element of the popup dialog.
    private onShownAddClass(e) {
        // Adds the class set in the TE to the overlay element on the dialog.
        const popup = this.config.popup;
        const cssClass = popup['ic.cssClass'] || popup.cssClass || popup.screenName.toPopUpName() || "";
        e.component.$overlayContent().addClass(cssClass);
        // Fixes ADA bug where H navigation fails on headers.
        IX_SetAdaHeaderMarkup(e.component.$content());
    }

    private updateCloseButtonTexts(e?: any) {
        const instance: any = e?.component || this.dxPopup;
        if (_.isNil(instance._$title)) return;
        const closeTranslated = this.translate.getTranslation("CLOSE");
        instance._$title
            .find("[role=button].dx-closebutton")
            .attr("aria-label", closeTranslated);
    }

    private getText(property: string) {
        const map = { hint: "title" };
        let text = this.dxPopup.option(property);
        if (map[property]) {
            property = map[property];
            text = this.dxPopup.option(property);
        }
        return text;
    }

    private updatePopupTranslations() {
        if (!this.config.popup.showTitle) return;
        const props = ["title", "hint"];
        const options = {};
        props.forEach((property) => {
            const text = this.getText(property);
            if (_.isNil(text) || text.toString().length <= 0) return;
            options[property] = this.translate.getTranslation(text);
        });
        this.dxPopup.option(options);
    }

    private createCommandConfigByAction(action, config): CommandConfig {
        const targetApp = config.parameters[0];
        const commandConfig = {
            action: action,
            parameters: config.parameters,
            options: {
                i: 0,
                e: null,
                path: null
            },
            commandListName: targetApp,
            appName: targetApp,
            rid: targetApp,
            currentIndex: -1,
            command: null,
            module: null,
            executionId: '',
        };
        return commandConfig;
    }
}
