/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxPopup = require('devextreme/ui/popup');

var POPUP_TITLE_CLOSEBUTTON_CLASS = "dx-closebutton";

var _dxPopup = DevExpress.ui.dxPopup.inherit({
    _getCloseButtonRenderer: function () {
        return (function (_, __, container) {
            var $button = $("<button>").addClass(POPUP_TITLE_CLOSEBUTTON_CLASS);
            this._createComponent($button, DevExpress.ui.dxButton, {
                icon: 'close',
                onClick: this._createToolbarItemAction(undefined),
                integrationOptions: {}
            });
            $button.attr('role', null);
            $(container).append($button);
        }).bind(this);
    },
    _createToolbarItemAction: function (clickAction) {
        return this._createAction(clickAction, {
            afterExecute: (e) => e.component.hide()
        });
    },
});

DevExpress.registerComponent("dxPopup", _dxPopup);
