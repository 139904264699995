// tslint:disable: forin
// tslint:disable: no-bitwise
import { Injectable } from '@angular/core';
import UtilityFunctions from '../../utility.functions';
import { ApiClientService } from './../api-client.service';
import { EcdUtilService } from './ecdutil.service';

@Injectable()
export class EcdService {
    constructor(
        private ecdUtilService: EcdUtilService,
        private apiClientService: ApiClientService
    ) { }

    request(params) {

        const methodName = params.methodName;

        if (!_.isString(params.ApplicationName)) {
            return Promise.reject('Method/ApplicationName name is required and must be a string.');
        }

        const useLocalDataSource = UtilityFunctions.getThemeProperty('UseLocalDataSource');

        if (useLocalDataSource && window.location.href.indexOf('IX_DEBUG_DISABLE_LOCAL_DATASOURCE') === -1) {

            const overrideService = this.ecdUtilService.getOverrideService(params);

            if (overrideService) {

                if (_.isFunction(overrideService[methodName])) {
                    return overrideService[methodName](params);
                }

                if (_.isFunction(overrideService.request)) {
                    return overrideService.request(params);
                }
            }
        }

        return this.apiClientService.makeEcdRequest(params);
    }

}
