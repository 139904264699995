import { EventEmitter, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { getCurrentLocale, getLocaleKey } from '../translate/locale.functions';

@Injectable()
export class TranslateFacadeService {

    get onLangChange(): EventEmitter<LangChangeEvent> {
        return this.translate.onLangChange;
    }

    constructor(private translate: TranslateService) { }

    get(key: string | Array<string>, interpolateParams?: any): Observable<string | any> {
        return this.translate.get(key, interpolateParams);
    }

    resetLang(lang: string): void {
        this.translate.resetLang(lang);
    }

    useLang(lang: string): Observable<any> {
        return this.translate.use(lang);
    }

    // to support all calls
    use(lang?: string): string {
        if (_.isNil(lang))
            return getCurrentLocale();
        if (!_.isNil(window.$translateLocaleKey)) {
            this.translate.resetLang(window.$translateLocaleKey);
        }
        const localeKey = getLocaleKey();
        this.translate.resetLang(localeKey);
        this.translate.use(localeKey);
        return lang;
    }

    instant(translationId: string): string {
        if (_.isNil(translationId) || _.isEmpty(translationId)) {
            console.warn("Missing translationId..");
            return '';
        }
        return this.translate.instant(translationId).toString();
    }

    getTranslation(translationId: string, defaultTranslation?: string): string {
        if (_.isNil(defaultTranslation)) return this.instant(translationId);
        return this.translateOrDefault(translationId, defaultTranslation);
    }

    translateOrDefault(translateId: string, defaultStr: string): string {
        if (_.isNil(translateId)) return defaultStr;
        let translation = this.instant(translateId);
        if (translation == translateId) {
            translation = defaultStr;
        }
        return translation;
    }
}
