import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { BaseApplication } from '../components/base-application';
import { CacheManagerService } from '../services/cachemanager.service';


@Injectable()
export class TriggerSpecialEffectCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.triggerSpecialEffectCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    this.triggerSpecialEffect(commandConfig);
                    return this.getNextActions(commandConfig);
                })
            )
    );

    triggerSpecialEffect(commandConfig: CommandConfig): void {
        const parameters = commandConfig.parameters;
        const specialEffectSequence = parameters[0];
        const targetAppName = commandConfig.parameters[1];
        const appInfo = this.applicationInformation.getAppInfo(targetAppName);
        if (_.isNil(appInfo) || !_.isArray(appInfo.specialEffects))
            return;
        const appComponent = this.domComponentRetrievalService.getAppComponent(targetAppName);
        if (_.isNil(appComponent) || !_.isArray(appComponent.applet?.specialEffects))
            return;
        const specialEffects = appComponent.applet.specialEffects;
        let specialEffect = null;
        for (let i = 0; i < specialEffects.length; i++) {
            const specialEffectItems = specialEffects[i].specialEffectItems;
            for (let j = 0; j < specialEffectItems.length; j++) {
                if (specialEffectItems[j].sequence == specialEffectSequence) {
                    specialEffect = specialEffectItems[j];
                    break;
                }
            }
            if (specialEffect) {
                break;
            }
        }
        // TODO: Investigate why `specialEffect.effect` is undefined?
        if (_.isNil(specialEffect) || _.isNil(specialEffect.effect))
            return;
        const specialEffectType = specialEffect.effectType.ToLower();
        specialEffect.effect[specialEffectType]();
    }
}
