/* eslint-disable */
var Big = require('big.js');
var _ = require('lodash');
var DevExpress = require('devextreme/bundles/modules/core');
require('devextreme/bundles/modules/data');
require('devextreme/integration/jquery');

var _dom_adapter = _interopRequireDefault(require("devextreme/core/dom_adapter"));
var _position = require("devextreme/core/utils/position");
var ui = require('devextreme/bundles/modules/ui');
require('devextreme/bundles/modules/ic');

ui.themes = require('devextreme/ui/themes');
ui.dxDataGrid = require('devextreme/ui/data_grid');
ui.dxPager = require('devextreme/ui/pager');
ui.dxTreeList = require('devextreme/ui/tree_list');
ui.dxContextMenu = require('devextreme/ui/context_menu');
ui.dxDropDownBox = require('devextreme/ui/drop_down_box');

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        "default": obj
    }
}

function mergeArraysByMaxValue(values1, values2) {
    const result = [];
    if (values1 && values2 && values1.length && values1.length === values2.length) {
        for (var i = 0; i < values1.length; i++) {
            result.push(values1[i] > values2[i] ? values1[i] : values2[i])
        }
    } else {
        if (values1 && values1.length) {
            result = values1
        } else {
            if (values2) {
                result = values2
            }
        }
    }
    return result
};

function restoreFocus(focusedElement, selectionRange) {
    focusedElement.focus();
    DevExpress.ic.gridCore.setSelectionRange(focusedElement, selectionRange)
};


// Taken from 17_2_4/js/ui/grid_core/ui.grid_core.column_chooser.js. Used when redefining the
// column chooser as a drop down.
var ADD_ROW_BUTTON_CLASS = "addrow-button",
    COLUMN_CHOOSER_CLASS = "column-chooser",
    COLUMN_CHOOSER_BUTTON_CLASS = "column-chooser-button",
    NOTOUCH_ACTION_CLASS = "notouch-action",
    COLUMN_CHOOSER_LIST_CLASS = "column-chooser-list",
    COLUMN_CHOOSER_PLAIN_CLASS = "column-chooser-plain",
    COLUMN_CHOOSER_DRAG_CLASS = "column-chooser-mode-drag",
    COLUMN_CHOOSER_SELECT_CLASS = "column-chooser-mode-select",
    COLUMN_CHOOSER_ICON_NAME = "column-chooser",
    COLUMN_CHOOSER_ITEM_CLASS = "dx-column-chooser-item",
    CLICK_TIMEOUT = 300,
    EDIT_MODE_BATCH = "batch",
    EDIT_MODE_ROW = "row",
    EDIT_MODE_CELL = "cell",
    EDIT_MODE_FORM = "form",
    EDIT_MODE_POPUP = "popup",
    EDIT_MODES = [EDIT_MODE_BATCH, EDIT_MODE_ROW, EDIT_MODE_CELL, EDIT_MODE_FORM, EDIT_MODE_POPUP],
    EDITOR_CELL_CLASS = "dx-editor-cell",
    FOCUS_OVERLAY_CLASS = "focus-overlay",
    ROW_BASED_MODES = [EDIT_MODE_ROW, EDIT_MODE_FORM, EDIT_MODE_POPUP],
    HIDDEN_COLUMN_CLASS = "hidden-column",
    HIDDEN_COLUMNS_WIDTH = "adaptiveHidden",
    ADAPTIVE_COLUMN_NAME = "adaptive",
    PAGER_NAVIGATE_BUTTON = 'dx-navigate-button',
    PAGER_PREV_BUTTON_CLASS = "dx-prev-button",
    PAGER_NEXT_BUTTON_CLASS = "dx-next-button",
    PAGER_PAGE_CLASS = 'dx-page',
    PAGER_PAGE_SEPARATOR_CLASS = 'dx-separator',
    PAGER_PAGE_SIZES_CLASS = "dx-page-sizes",
    DATAGRID_EXPORT_BUTTON_CLASS = "dx-datagrid-export-button",
    TOOLBAR_ITEM_AUTO_HIDE_CLASS = "dx-toolbar-item-auto-hide",
    PAGER_BUTTON_DISABLE_CLASS = "dx-button-disable",
    ROW_SELECTION_CLASS = "dx-selection",
    SELECT_CHECKBOX_CLASS = "dx-select-checkbox",
    CONTEXT_MENU = "dx-context-menu",

    FREESPACE_ROW_CLASS = "dx-freespace-row",
    VIRTUAL_ROW_CLASS = "dx-virtual-row",
    GROUP_ROW_CLASS = "dx-group-row",
    MASTER_DETAIL_CELL_CLASS = "dx-master-detail-cell",
    CELL_FOCUS_DISABLED_CLASS = "dx-cell-focus-disabled",
    COMMAND_EXPAND_CLASS = "dx-command-expand",
    EDIT_ICON_CLASS = {
        save: "save",
        cancel: "revert",
        edit: "edit",
        undelete: "revert",
        delete: "trash",
        add: "add"
    },
    LINK_CLASS = "dx-link",
    COMMAND_EDIT_CLASS = "dx-command-edit",
    COMMAND_EDIT_WITH_ICONS_CLASS = COMMAND_EDIT_CLASS + "-with-icons",
    EDITING_NAMESPACE = "dxDataGridEditing";

let _uniqueId = 0;
const getUniqueId = () => _uniqueId++;

const getActiveElement = () => window.document?.activeElement;

const handleMonthFieldMasks = (format) => {
    // Devexpress localization date format does not accept Mon/Month as Month value
    if (format?.toLocaleLowerCase().contains("mon")) {
        if (format.toLocaleLowerCase().contains("month")) {
            format = format.replace(/Month/gi, "MMMM");
        } else {
            format = format.replace(/Mon/gi, "MMM");
        }
    }
    return format;
};


const getDxDataGrid = (element) => {
    const $icGrid = $(element).closest(".icGrid");
    return $icGrid.length > 0 ? $icGrid.dxDataGrid("instance") : null;
};

var addAriaLabelsToPrevNextBtn = function (button, element) {
    if (button.hasClass(PAGER_BUTTON_DISABLE_CLASS)) {
        button.removeAttr("tabindex");
        button.attr('aria-disabled', true);

        const dataGridInstance = getDxDataGrid(element);
        const $utilLstSvc = dataGridInstance?.option('ic.$utilLstSvc');
        const gridScope = dataGridInstance?.option('gridScope');
        if (!gridScope) return;
        $utilLstSvc?.recalcHorizontalScrollHandles(gridScope);
    }
    else {
        button.attr("tabindex");
    }
},
    isColumnChooserModeExtended = function (that) {
        return that.option("columnChooser.mode") !== "select" && that.option("columnChooser.mode") !== "dragAndDrop";
    },
    processItems = function (that, chooserColumns) {
        const items = [];
        if (_.isEmpty(chooserColumns)) return items;
        const isSelectMode = isColumnChooserModeExtended(that) || that.option("columnChooser.mode") === "select";
        chooserColumns.forEach((column, index) => {
            const item = {
                dataField: column.dataField,
                text: column.caption,
                cssClass: column.cssClass,
                allowHiding: column.allowHiding,
                expanded: true,
                id: column.dataField,
                parentId: !_.isUndefined(column.ownerBand) ? column.ownerBand : null,
            };
            if (isSelectMode) {
                item.selected = column.visible;
            }
            if (column.fixed) {
                item.disabled = true;
                items.unshift(item);
            } else {
                items.push(item);
            }
        });

        return items;
    },
    setDropDownPlaceholder = function (that, items, placeholder) {
        var selected = 0,
            total = 0;

        if (!Array.isArray(items)) {
            var chooserColumns = that._columnsController.getChooserColumns(true);
            items = processItems(that, chooserColumns);
        }

        selected = items.filter((item) => item.selected).length;
        total = items.length;

        if (placeholder) {
            placeholder.text = placeholder.text.replace(placeholder.select, selected.toString());
            placeholder.text = placeholder.text.replace(placeholder.total, total.toString());
            return placeholder.text;

        } else {
            placeholder = that.option("placeholder");
            var columnCounts = placeholder.match(/\d+/g);
            if (columnCounts.length > 1) {
                placeholder = placeholder.replace(columnCounts[0], selected.toString());
                placeholder = placeholder.replace(columnCounts[1], total.toString());
                that.option("placeholder", placeholder);
            }
        }
    },
    getEditMode = function (that) {
        var editMode = that.option("editing.mode");
        if (EDIT_MODES.indexOf(editMode) !== -1) {
            return editMode
        }
        return EDIT_MODE_ROW
    },
    isRowEditMode = function (that) {
        var editMode = getEditMode(that);
        return ROW_BASED_MODES.indexOf(editMode) !== -1
    },
    getDefaultEditorTemplate = function (that) {
        return function (container, options) {
            var $editor = $("<div>").appendTo(container);

            // Begin override to only show the value if it implies that the field is disabled/read-only
            if (!!options.column && _.isArray(options.column.valuesThatDisable) && options.column.valuesThatDisable.indexOf(options.value) > -1) {
                $editor.append('<span class="dx-read-only-value">' + options.value + '</span>');
            } else {
                that.getController("editorFactory").createEditor($editor, _.extend({}, options.column, {
                    value: options.value,
                    setValue: options.setValue,
                    row: options.row,
                    parentType: "dataRow",
                    width: null,
                    readOnly: !options.setValue,
                    isOnForm: options.isOnForm,
                    id: options.id,
                    updateValueImmediately: isRowEditMode(that)
                }));
            }
        };
    };

// Shared redefines
var columnChooserView = {
    // Initialize with input drop down component if extended column chooser mode
    _initializePopupContainer: function (e) {
        var that = this;
        var $element = that.element();
        $element?.addClass(that.addWidgetPrefix(COLUMN_CHOOSER_CLASS));
        var columnChooserOptions = that.option("columnChooser"),
            theme = DevExpress.ui.themes.current(),
            isGenericTheme = theme?.indexOf("generic") > -1,
            isAndroid5Theme = theme?.indexOf("android5") > -1,
            dxPopupOptions = {
                visible: false,
                shading: false,
                showCloseButton: false,
                dragEnabled: true,
                resizeEnabled: true,
                toolbarItems: [{
                    text: columnChooserOptions.title,
                    toolbar: "top",
                    location: "center"
                }],
                position: that.getController("columnChooser").getPosition(),
                width: columnChooserOptions.width,
                height: columnChooserOptions.height,
                rtlEnabled: that.option("rtlEnabled"),
                onHidden: function () {
                    if (that._isWinDevice()) {
                        $("body").removeClass(that.addWidgetPrefix(NOTOUCH_ACTION_CLASS));
                    }
                },
                container: columnChooserOptions.container
            };

        if (!isColumnChooserModeExtended(that)) {
            if (isGenericTheme) {
                _.extend(dxPopupOptions, {
                    showCloseButton: true
                });
            } else {
                dxPopupOptions.toolbarItems[dxPopupOptions.toolbarItems.length] = {
                    shortcut: "cancel"
                };
            }
        }

        if (_.isUndefined(this._popupContainer)) {
            if (isColumnChooserModeExtended(that)) {
                that._popupContainer = e.component;
            } else {
                that._popupContainer = that._createComponent($element, DevExpress.ui.dxPopup, dxPopupOptions);
            }

            that._popupContainer.on("optionChanged", function (args) {
                if (args.name === "opened" && args.value === false) {
                    // If the columnChooser is closed, it is a calmColumnChooser, and autosave on close is
                    // enabled, we need to save the changes here.
                    const gridComponent = getDxDataGrid(args.element);
                    const $utilLstSvc = gridComponent.option('ic.$utilLstSvc');
                    const $themeSvc = gridComponent?.option('ic.$themeSvc');
                    const calmColumnChooser = $themeSvc?.getThemeProperty('CalmColumnChooser');
                    if ("true".EqualsIgnoreCase(calmColumnChooser?.Value1) && gridComponent?.option('icAutoSaveOnColumnChooserClose')) {

                        gridComponent.beginUpdate();

                        var outerContainer = _.get(gridComponent.getView('columnChooserView'), '_popupContainer._popup._$wrapper');
                        var dxTreeViewNodes = !!outerContainer ? outerContainer.find('.dx-treeview-node') : null;
                        var columns = gridComponent.option('columns').filter((column) => column.showInColumnChooser);
                        if (dxTreeViewNodes && dxTreeViewNodes.length && columns && columns.length) {
                            dxTreeViewNodes.each((index, columnNameDomEle) => {
                                if (_.isUndefined(columns[index])) return;
                                const column = columns[index];
                                
                                // Checked state does not auto-sync when the column order is manually adjusted
                                const visible = columnNameDomEle.classList.contains('dx-state-selected');
                                gridComponent.columnOption(column.dataField, 'visible', visible);
                            });
                        }

                        gridComponent.endUpdate();

                        // Sort the order in column chooser
                        $utilLstSvc.updateColumnChooserSequence(gridComponent);
                        $utilLstSvc.recalculateHidingPriorities(gridComponent);

                        // Recalculate horizontal scrollbars if columnchooser is saved / reset
                        setTimeout(() => {
                            const gridScope = that.option('gridScope');
                            if (_.isFunction(gridScope?.generateGroupingActionButtons))
                                gridScope.generateGroupingActionButtons();
                            $utilLstSvc?.recalcHorizontalScrollHandles(gridScope);
                        }, 0);
                    }
                }
                if (args.name === "visible") {
                    that.renderCompleted.fire();
                }
            });
        } else {
            this._popupContainer.option(dxPopupOptions);
        }
    },

    // Override updating the list, building the tree View for either the drop down or the default popup
    _updateList: function (allowUpdate) {
        var items, $popupContent = isColumnChooserModeExtended(this) ? this._popupContainer.element() : this._popupContainer.content(),
            isSelectMode = isColumnChooserModeExtended(this) || this.option("columnChooser.mode") === "select",
            chooserColumns = this._columnsController.getChooserColumns(isSelectMode);

        if (!isSelectMode || !this._columnChooserList || allowUpdate) {
            if (!isColumnChooserModeExtended(this)) {
                this._popupContainer.$wrapper().toggleClass(this.addWidgetPrefix(COLUMN_CHOOSER_DRAG_CLASS), !isSelectMode).toggleClass(this.addWidgetPrefix(COLUMN_CHOOSER_SELECT_CLASS), isSelectMode);
            }

            items = processItems(this, chooserColumns);
            this._renderTreeView($popupContent, items);
        }

        var dataAttributes = this.component.option('columnChooser.dataAttributes');
        if (dataAttributes) {
            var pairs = dataAttributes.split(",");
            var popupContainer = this._popupContainer;
            pairs.forEach(function (pair) {
                if (pair.indexOf("|") !== -1) {
                    var keyValue = pair.split("|");
                    var key = "data-" + keyValue[0];
                    var value = keyValue[1];

                    popupContainer._$container.attr(key, value);
                }
            });
        }
    },

    // Override buidling the tree view
    _renderTreeView: function ($container, items) {
        var that = this,
            scrollTop,
            scrollableInstance,
            columnChooser = this.option("columnChooser"),
            isSelectMode = isColumnChooserModeExtended(that) || columnChooser.mode === "select",
            itemsCopy = _.map(items, (item) => ({ ...item })),
            treeViewConfig = {
                items: columnChooser.mode === "CalmDropDown" ? itemsCopy : items,
                dataStructure: "plain",
                activeStateEnabled: true,
                focusStateEnabled: true,
                hoverStateEnabled: true,
                itemTemplate: "item",
                showCheckBoxesMode: "none",
                rootValue: null,
                searchEnabled: columnChooser.allowSearch
            };

        if (isSelectMode) {
            scrollableInstance = $container.find(".dx-scrollable").data("dxScrollable");
            scrollTop = scrollableInstance && scrollableInstance.scrollTop();
            !this._columnsController.isBandColumnsUsed() && $container.addClass(this.addWidgetPrefix(COLUMN_CHOOSER_PLAIN_CLASS));
        }

        treeViewConfig.onContentReady = function (e) {
            var scrollable = $(e.element).find(".dx-scrollable").data("dxScrollable");
            var $scrollableElement = scrollable.element();
            if ($scrollableElement && $scrollableElement.length > 0) {
                // This class was present in devextreme 18.2 and Ptiers were using it to add custom styles
                $scrollableElement.addClass("dx-scrollable-native");
            }
            if (scrollTop) {
                scrollable && scrollable.scrollTo({
                    y: scrollTop
                });
            }

            IX_ForceShowScrollbars(e.element);

            that.renderCompleted.fire();
        };

        if (this._isWinDevice()) {
            treeViewConfig.useNativeScrolling = false;
        }

        _.extend(treeViewConfig, isSelectMode ? this._prepareSelectModeConfig() : this._prepareDragModeConfig());
        const calmColumnChooser = this.option('ic.$themeSvc')?.getThemeProperty('CalmColumnChooser');
        if ("true".EqualsIgnoreCase(calmColumnChooser?.Value1) && this.option('icAutoSaveOnColumnChooserClose')) {
            treeViewConfig.onItemSelectionChanged = _.noop;
        }

        if (isColumnChooserModeExtended(that)) {
            var that = this;

            // Need to click the dropDownBox to start the mutation observer that will add the Reset and Save buttons
            // Angular version needs setTimeout for the element to be available for click
            setTimeout(() => this._popupContainer.element().click(), 0);

            // Update the dropDownBox placeholder
            var placeholderText = this.option("columnChooser.dropDownPlaceholder");
            if (placeholderText && !placeholderText.contains('{')) {
                // Already translated the strings and interpolated the number placeholders;
                // now can simply replace the counts
                this._popupContainer.option("placeholder", placeholderText);
            }

            setDropDownPlaceholder(this._popupContainer, items, null);

            // Add the tree view to the drop down as a content template
            this._popupContainer.option("contentTemplate", function (e) {
                var $ddToolbar = $("<div>").dxToolbar();
                var $treeView = $("<div>").dxTreeView(treeViewConfig);

                this._popup._$wrapper.addClass(that.addWidgetPrefix(COLUMN_CHOOSER_CLASS));
                this._popup._$wrapper.addClass(that.addWidgetPrefix(COLUMN_CHOOSER_SELECT_CLASS));
                $treeView.addClass(that.addWidgetPrefix(COLUMN_CHOOSER_PLAIN_CLASS));
                $treeView.addClass(that.addWidgetPrefix(COLUMN_CHOOSER_LIST_CLASS));
                $treeView.prepend($ddToolbar);

                that.component.option("ic")?.$icAccessibilitySvc?.addAccessibilityToDxDataGridColumnChooser($treeView);

                return $treeView;
            });
        } else {
            if (this._columnChooserList) {
                if (!treeViewConfig.searchEnabled) {
                    treeViewConfig.searchValue = "";
                }

                this._columnChooserList.option(treeViewConfig);
            } else {
                this._columnChooserList = this._createComponent($container, DevExpress.ui.dxTreeView, treeViewConfig);
                $container.addClass(this.addWidgetPrefix(COLUMN_CHOOSER_LIST_CLASS));
            }
        }
    },

    // Override get column fields for the tree view
    getColumnElements: function () {
        var result = [],
            $node, item, isSelectMode = isColumnChooserModeExtended(this) || this.option("columnChooser.mode") === "select",
            chooserColumns = this._columnsController.getChooserColumns(isSelectMode),
            $content = this._popupContainer && this._popupContainer.content(),
            $nodes = $content && $content.find(".dx-treeview-node");

        if ($nodes) {
            chooserColumns.forEach(function (column) {
                $node = $nodes.filter("[data-item-id = '" + column.index + "']");
                item = $node.length ? $node.children("." + COLUMN_CHOOSER_ITEM_CLASS).get(0) : null;
                result.push(item);
            });
        }

        return $(result);
    },

    // Override the building and rendering the column chooser
    showColumnChooser: function (e) {
        // If the column chooser container has been rendered, return
        if (isColumnChooserModeExtended(this) && this._isPopupContainerShown) {

            // region IC reapply search text
            var treeView = $('.dx-treeview-with-search').dxTreeView("instance");
            if (treeView && treeView._searchEditor && _.get(treeView, '_dataAdapter.options.searchValue')) {
                var existingValue = treeView._dataAdapter.options.searchValue;
                treeView._searchEditor.option('value', '');
                treeView._searchEditor.option('value', existingValue);
            }
            // endregion IC reapply search text

            return;
        }

        this._isPopupContainerShown = true;

        this._initializePopupContainer(e);
        this.render();

        if (!isColumnChooserModeExtended(this)) {
            this._popupContainer.show();
        }

        if (this._isWinDevice()) {
            $("body").addClass(this.addWidgetPrefix(NOTOUCH_ACTION_CLASS));
        }
    },

    hideColumnChooser: function () {
        if (this._popupContainer) {
            if (!isColumnChooserModeExtended(this)) {
                this._popupContainer.hide();
                this._isPopupContainerShown = false;
            } else {
                this._popupContainer.close();
            }
        }
    },
    getBoundingRect: function () {
        const that = this;
        const container =
            that._popupContainer &&
            typeof that._popupContainer.$overlayContent === 'function' &&
            that._popupContainer.$overlayContent();

        if (container && container.is(':visible')) {
            const offset = container.offset();

            return {
                left: offset.left,
                top: offset.top,
                right: offset.left + container.outerWidth(),
                bottom: offset.top + container.outerHeight()
            };
        }

        return null;
    },
};

var handler_handleColumnsChanged = function (e) {
    function checkChanges(changes, changeNames) {
        var i, changesWithChangeNamesCount = 0;
        for (i = 0; i < changeNames.length; i++) {
            if (changes[changeNames[i]]) {
                changesWithChangeNamesCount++
            }
        }
        return changes.length && changes.length === changesWithChangeNamesCount
    }
    var filterValue, filterValues, filterApplied, that = this,
        changeTypes = e.changeTypes,
        optionNames = e.optionNames;
    var _type2 = _interopRequireDefault(DevExpress.ic.typeUtils);
    var updateItemsHandler = function updateItemsHandler() {
        that._columnsController.columnsChanged.remove(updateItemsHandler);
        that.updateItems()
    };
    if (changeTypes.sorting || changeTypes.grouping) {
        if (that._dataSource && !that._columnsUpdating) {
            that._dataSource.group(that._columnsController.getGroupDataSourceParameters());
            that._dataSource.sort(that._columnsController.getSortDataSourceParameters());
            that.reload()
        }
    } else {
        if (changeTypes.columns) {
            if (optionNames.filterValues || optionNames.filterValue || optionNames.selectedFilterOperation) {
                filterValue = that._columnsController.columnOption(e.columnIndex, "filterValue");
                filterValues = that._columnsController.columnOption(e.columnIndex, "filterValues");
                if (Array.isArray(filterValues) || void 0 === e.columnIndex || _type2.default.isDefined(filterValue) || !optionNames.selectedFilterOperation || optionNames.filterValue) {
                    that._applyFilter();
                    filterApplied = true
                }
            }

            //edited dxCode to remove the grouping if they are removed from the column chooser
            //approach is to find if there are any groupings and if there is
            //remove it if its the same column as the one that is being removed from the column chooser

            //for calm column chooser
            if (optionNames.all == true) {
                if (that.component._options.icAllowColumnChooserToUndoGrouping == "true") {
                    var groupedColumns = that.component.getDataSource().group();
                    if (groupedColumns && groupedColumns.length > 0) {
                        _.each(groupedColumns, function (grpdCol) {
                            var groupedColInd = _.find(that._columnsController._columns, function (colItm) {
                                return grpdCol.selector == colItm.dataField && colItm.visible == false;
                            });
                            if (!_.isUndefined(groupedColInd)) {
                                setTimeout(function () {
                                    that.component.columnOption(groupedColInd.index, "groupIndex", -1);
                                }, 0);

                            }
                        });
                    };
                    that._columnsController.columnsChanged.add(updateItemsHandler);
                }
            }
            //for regular column chooser
            else if (!that._needApplyFilter && !checkChanges(optionNames, ["width", "visibleWidth", "filterValue", "bufferedFilterValue", "selectedFilterOperation", "filterValues", "filterType"])) {
                if (that.component._options.icAllowColumnChooserToUndoGrouping == "true") {
                    var groupedColumns = that.component.getDataSource().group();
                    if (groupedColumns && groupedColumns.length > 0) {
                        var changedColumnName = that.component.columnOption(e.columnIndex).dataField;
                        var changedGroupedCols = _.find(groupedColumns, function (colItm) {
                            return colItm.selector == changedColumnName;
                        });
                        if (!_.isUndefined(changedGroupedCols)) {
                            that.component.columnOption(e.columnIndex, "groupIndex", -1);
                        }
                    };
                }
                that._columnsController.columnsChanged.add(updateItemsHandler);
            }

            if (_type2.default.isDefined(optionNames.visible)) {
                var column = that._columnsController.columnOption(e.columnIndex);
                if (column && (_type2.default.isDefined(column.filterValue) || _type2.default.isDefined(column.filterValues))) {
                    that._applyFilter();
                    filterApplied = true
                }
            }
        }
    }
    if (!filterApplied && changeTypes.filtering) {
        that.reload()
    }
};

var columnsController = {
    getChooserColumns: function (getAllColumns) {
        var columns = getAllColumns ? this.getColumns() : this.getInvisibleColumns();
        if (!getAllColumns) {
            columns.sort(function (a, b) {
                if (a.caption < b.caption) {
                    return -1;
                }
                if (a.caption > b.caption) {
                    return 1;
                }
                return 0;
            })
        }
        return columns.filter(function (column) {
            return column.showInColumnChooser;
        });
    },

    changeSortOrder: function (visibleColumn, sortOrder) {
            var that = this,
                column = that._columns.find(x => x.dataField == visibleColumn.dataField);

            // ic custom code changes
            var useAutoGroupingSummary = false;
            var $utilLstSvc = that.component.option('ic.$utilLstSvc');
            var icCustomState = {};
            // from refactored customGroupSorting
            useAutoGroupingSummary = $utilLstSvc.useAutoGroupingSummary(that, column);
            if (useAutoGroupingSummary || column.forceAutoGroupingSummarySort) {
                icCustomState = $utilLstSvc.autoGroupingSummaryPreSort(that, column, false);
            } else {
                icCustomState = $utilLstSvc.customGroupPreSorting(that, column, false);
                if (_.has(icCustomState, 'icCustomSort.sortedColumnIndex')) {
                    columnIndex = icCustomState.icCustomSort.sortedColumnIndex;
                    column = that._columns[columnIndex];
                }
            }
            // ic end

        var isSortOrderValid = function (sortOrder) {
            return "asc" === sortOrder || "desc" === sortOrder
        };
        var _type2 = _interopRequireDefault(DevExpress.ic.typeUtils);
        var _iterator2 = _interopRequireDefault(DevExpress.utils.iterator);
            var options = {},
            sortingOptions = that.option("sorting"),
            sortingMode = sortingOptions && sortingOptions.mode,
            needResetSorting = "single" === sortingMode || !sortOrder,
            allowSorting = "single" === sortingMode || "multiple" === sortingMode,
            nextSortOrder = function (column) {
                if ("ctrl" === sortOrder) {
                    if (!("sortOrder" in column && "sortIndex" in column)) {
                        return false
                    }
                    options.sortOrder = void 0;
                    options.sortIndex = void 0
                } else {
                    if (_type2.default.isDefined(column.groupIndex) || _type2.default.isDefined(column.sortIndex)) {
                        options.sortOrder = "desc" === column.sortOrder ? "asc" : "desc"
                    } else {
                        options.sortOrder = "asc"
                    }
                }
                return true
            };
        if (allowSorting && column && column.allowSorting) {
            if (needResetSorting && !_type2.default.isDefined(column.groupIndex)) {
                _iterator2.default.each(that._columns, function (index) {
                    if (index !== visibleColumn.index && this.sortOrder && !_type2.default.isDefined(this.groupIndex)) {
                        delete this.sortOrder;
                        delete this.sortIndex
                    }
                })
            }
            if (isSortOrderValid(sortOrder)) {
                if (column.sortOrder !== sortOrder) {
                    options.sortOrder = sortOrder
                }
            } else {
                if ("none" === sortOrder) {
                    if (column.sortOrder) {
                        options.sortIndex = void 0;
                        options.sortOrder = void 0
                    }
                } else {
                    nextSortOrder(column)
                }
            }
        }

        // ic custom code changes
        // record sort order in case we need to override sort indicators
        var sorting = that.component.option('sorting')
        if (sorting) {
            _.set(sorting, 'icCustomSort.sortOrder', options.sortOrder);
        }
            // ic end

        that.columnOption(column.index, options)

            // ic custom code changes
            // from refactored customGroupSorting
            if (useAutoGroupingSummary || column.forceAutoGroupingSummarySort) {
                $utilLstSvc.autoGroupingSummaryPostSort(that, column, false, icCustomState);
            } else {
                $utilLstSvc.customGroupPostSorting(that);
            }
            // ic end
    },

    getGroupDataSourceParameters: function (useLocalSelector) {
        const group = [];

        //begin IC change: discover group's expanded/collapsed initial state
        const customGroupExpand = this.option('icCustomGroupExpand');
        let expand;
        let level;
        if (customGroupExpand) {
            const customGroupArgs = customGroupExpand.split(':');
            expand = customGroupArgs[0] === 'Open';
            level = Number.parseInt(customGroupArgs[1]);
        }
        //end IC change

        $.each(this.getGroupColumns(), function () {
            const selector = this.calculateGroupValue || this.displayField || this.calculateDisplayValue || (useLocalSelector && this.selector) || this.dataField || this.calculateCellValue;

            //begin IC change: assign either by custom prop or default (this.autoExpandGroup)
            const isExpanded = customGroupExpand && this.groupIndex === level ? expand : !!this.autoExpandGroup;
            //end IC change

            if (selector) {
                const desc = (this.sortOrder === 'desc');
                const groupItem = { selector, desc, isExpanded };

                if (this.sortingMethod) {
                    groupItem.compare = this.sortingMethod.bind(this);
                }

                group.push(groupItem);
            }
        });
        return group.length > 0 ? group : null;
    },

};

// Taken from 17_2_4/js/ui/grid_core/ui.grid_core.header_panel.js
// Override the header panel's column chooser item to create a drop down
var headerPanel_appendColumnChooserItem = function (items) {
    const that = this;
    const columnChooserOptions = this.option("columnChooser");
    const $utilSvc = this.option('ic.$utilSvc');

    if (columnChooserOptions.enabled) {
        var toolbarItem = null;

        const $element = that.component.$element();
        const columnChooserId = $element.parents('[data-app]').attr('id') || $element.attr('id');
        var columnChooserLabelId = columnChooserId + '_Column_Chooser_Label' + getUniqueId();

        if (isColumnChooserModeExtended(that)) {
            var dropDownPlaceholder = {
                text: columnChooserOptions.dropDownPlaceholder,
                select: columnChooserOptions.placeholderSelect,
                total: columnChooserOptions.placeholderTotal
            };
            var textItem = {
                template: function () {
                    return '<div class="dx-widget" style="border: none"><span class="dx-toolbar-widget-label" id="' + columnChooserLabelId + '">' + columnChooserOptions.dropDownLabel + '</span></div>';
                },
                location: "after",
                name: "columnChooserLabel",
                locateInMenu: "auto",
                sortIndex: 40
            };
            items.push(textItem);
            var initialized = false;
            toolbarItem = {
                widget: "dxDropDownBox",
                options: {
                    width: "100%",
                    placeholder: setDropDownPlaceholder(this, null, dropDownPlaceholder),
                    onOpened: function (e) {
                        that.component.getView("columnChooserView").showColumnChooser(e);

                        var columnChooserInputFocus = _.find(IX_Theme.properties, function (x) { return x.PropertyName.toLowerCase() == "disablemobilecolumnchooserinputfocus"; });
                        if (columnChooserInputFocus && columnChooserInputFocus.Value1.toLowerCase() === "true" && IX_isMobile()) {
                            setTimeout(function () {
                                var $dropdownInput = document.activeElement;
                                $dropdownInput.blur();
                            }, 0);
                        }
                    },
                    onContentReady: function (e) {
                        var ariaLabel = $utilSvc ? $utilSvc.getTranslation('Choose columns') : 'Choose columns';
                        var ariaRole = that.option("icColumnChooserOpenerElementRole");
                        e.element.find('.dx-dropdowneditor-button').addClass('dx-column-chooser-button')
                        e.element.find('.dx-dropdowneditor-button').attr('aria-label', ariaLabel);
                        e.element.find('.dx-texteditor-input').attr('role', !(ariaRole) ? 'combobox' : ariaRole);
                        if (ariaRole && ariaRole.toLowerCase() == "button") {
                            e.element.find('.dx-texteditor-input').removeAttr("aria-autocomplete");
                        }

                        var $dropdownInput = e.element.find('.dx-texteditor-input');
                        var columnChooserLabel = that.option("columnChooserLabel");
                        if (!!columnChooserLabel && columnChooserLabel.length > 0) {
                            var chooserAriaLabelId = ".fields.columnChooserLabel.ariaLabel";
                            var appName = that.component.appName;
                            chooserAriaLabelId = !!appName ? appName + chooserAriaLabelId : "";
                            columnChooserLabel = $utilSvc.translateOrDefault(chooserAriaLabelId, columnChooserLabel);
                            columnChooserLabel = $utilSvc.getAriaLabelWithPlaceholderText($dropdownInput, columnChooserLabel, null);
                            $dropdownInput.attr('aria-label', columnChooserLabel);
                        } else {
                            e.element.find('.dx-texteditor-input').attr('aria-labelledby', columnChooserLabelId);
                        }

                        // Optimization: without a flag check, this will produce as many as six timeouts
                        if (!initialized || !that.option('ic.$themeSvc').isThemePropertyEnabled('OptimizeColumnChooserInitialization')) {
                            window.setTimeout(function () {
                                that.component.getView("columnChooserView").showColumnChooser(e);
                            }, 0);
                            initialized = true;
                        }
                        that.option("ic.$icAccessibilitySvc").handleEnterKeyForAccessibility($dropdownInput, true);
                    },
                    onOptionChanged: function (e) {
                        // Focus should remain on column chooser button when popup closes
                        setTimeout(function () {
                            if (e.name == "opened") {
                                e.element.find('.dx-texteditor-input[aria-haspopup]').focus();
                            }
                        });
                    }
                },
                location: "after",
                name: "columnChooserDropDown",
                locateInMenu: "auto",
                sortIndex: 49
            };
        } else {
            var onClickHandler = function () {
                that.component.getView("columnChooserView").showColumnChooser();
            },
                onInitialized = function (e) {
                    $(e.element).addClass(that._getToolbarButtonClass(that.addWidgetPrefix(COLUMN_CHOOSER_BUTTON_CLASS)));
                    $(e.element).addClass('dx-column-chooser-button');
                },
                hintText = that.option("columnChooser.title");
            toolbarItem = {
                widget: "dxButton",
                options: {
                    icon: COLUMN_CHOOSER_ICON_NAME,
                    onClick: onClickHandler,
                    hint: hintText,
                    text: hintText,
                    onInitialized: onInitialized,
                    elementAttr: {
                        'aria-label': $utilSvc ? $utilSvc.getTranslation('Choose columns') : 'Choose columns'
                    }
                },
                showText: "inMenu",
                location: "after",
                name: "columnChooserButton",
                locateInMenu: "auto",
                sortIndex: 40
            };
            var columnChooserLabelGridProperty = that.component.option("columnChooserLabel");
            var columnChooserImage = that.component.option("columnChooserImage");
            if (!columnChooserLabelGridProperty && columnChooserImage) {
                toolbarItem.options.template = function (data, $container) {
                    var image = document.createElement('img');
                    image.src = columnChooserImage;
                    image.role = 'presentation';
                    $container.get(0).appendChild(image);
                    return image;
                };
            }
        }
        items.push(toolbarItem);
    }

    return items;
};

function setCustomSummaryTotal(that) {
    var summaryTotalItems = that.option("summary.totalItems");

    if (!Array.isArray(summaryTotalItems)) return;

    var _updateCustomTotalColumnPlace = function (totalItems, firstVisibleColumn) {
        var customTotalColumnName = "customSummaryForTotalText",
            updated = false,
            hasFirstColumnTotalText = false,
            totalColumnIndex;
        if (Array.isArray(totalItems)) {
            for (var i = 0; i < totalItems.length; i++) {
                if (totalItems[i].column === firstVisibleColumn) {
                    hasFirstColumnTotalText = true;
                }
                if (totalItems[i].name === customTotalColumnName) {
                    totalColumnIndex = i;
                }
            }
            customTotalColumnName = hasFirstColumnTotalText ? customTotalColumnName : firstVisibleColumn;

            if (!_.isUndefined(totalColumnIndex)) {
                updated = totalItems[totalColumnIndex].showInColumn !== customTotalColumnName;
                totalItems[totalColumnIndex].showInColumn = customTotalColumnName;
            }
        }
        return updated;
    };
    var _getFirstVisibleColumnName = function (columns) {
        var firstColumn = null;
        for (var i = 0; i < columns.length; i++) {
            firstColumn = columns[i];
            if (_.isUndefined(firstColumn.groupIndex) && !_.isUndefined(firstColumn.dataField)) {
                firstColumn = firstColumn.dataField;
                break;
            }
        }
        return firstColumn;
    };
    var visibleColumns = that.getVisibleColumns();
    var firstVisibleColumnName = _getFirstVisibleColumnName(visibleColumns);
    var updated = _updateCustomTotalColumnPlace(summaryTotalItems, firstVisibleColumnName);
    if (updated) {
        var footerView = that.getView('footerView');
        if (footerView) {
            var dataController = footerView.getController('data');
            dataController._updateItemsCore({
                changeType: 'refresh'
            });
            footerView.render();
        }
    }
}

function replaceDefaultEditRowIndex(obj) {
    var oldDefaultValue = -1;
    var newDefaultValue = 0;
    Object.defineProperty(obj, '_editRowIndex', {
        get: function () {
            return !_.isUndefined(this._value) ? this._value : newDefaultValue;
        },
        set: function (newValue) {
            if (newValue === oldDefaultValue) {
                newValue = newDefaultValue;
            }

            this._value = newValue;
        },
        configurable: false,
        enumerable: true,
        writeable: true,
        _value: undefined,
    });
}

var _dxDataGrid = DevExpress.ui.dxDataGrid.inherit({
    _init: function () {
        this.callBase();

        if (this.option('icHandleTabKey')) {
            replaceDefaultEditRowIndex(this.getController('editing'));
        }

        this.getController('editing').constructor.redefine({
            _createButton: function ($container, button, options) {
                var that = this,
                    iconType,
                    icon = EDIT_ICON_CLASS[button.name],
                    useIcons = that.option("editing.useIcons"),
                    $button = $("<a>")
                        .attr("href", "#")
                        // Begin InvestCloud a11y improvement
                        .attr("role", "button")
                        // End InvestCloud change
                        .addClass(LINK_CLASS)
                        .addClass(button.cssClass);

                if (button.template) {
                    that._rowsView.renderTemplate($container, button.template, options, true);
                } else {
                    if (useIcons && icon || button.icon) {
                        icon = button.icon || icon;
                        //TODO: Bring this over from library
                        iconType = DevExpress.ic.core.utils.icon.getImageSourceType(icon);

                        if (iconType === "image") {
                            $button = DevExpress.ic.core.utils.icon.getImageContainer(icon);
                        } else {
                            $button.addClass("dx-icon" + (iconType === "dxIcon" ? "-" : " ") + icon).attr("title", button.text);
                        }

                        $container.addClass(COMMAND_EDIT_WITH_ICONS_CLASS);
                    } else {
                        $button.text(button.text);
                    }

                    if (DevExpress.ic.typeUtils.isDefined(button.hint)) {
                        $button.attr("title", button.hint);
                    }

                    DevExpress.events.on($button, DevExpress.events.utils.addNamespace(DevExpress.events.click.name, EDITING_NAMESPACE), that.createAction(function (e) {
                        // Begin InvestCloud change to update grid with value change on Save click

                        // This fixes a DevExtreme 18 bug in which the input just edited would not have
                        // its new value saved. This happens because the input's value change is handled in
                        // an event on the input "change onfocusout etc" which is *prevented* by this button's
                        // click handler. (By the way, we need this e.preventDefault()).
                        // Reportedly this is fixed in the DevExtreme framework v19 and v20 in latest.

                        // Some buttons make a value change irrelevant
                        var unaffected = ["delete", "cancel", "edit"];

                        if (that.option("icValueChangeBeforeRowSave") && unaffected.indexOf(button.name) === -1) {
                            var icEditableGridRowService = that.option("ic.icEditableGridRowService");
                            if (icEditableGridRowService) {
                                icEditableGridRowService.triggerValueChange();
                            }
                        }
                        // End InvestCloudChange to update grid with value change on Save click
                        button.onClick.call(button, DevExpress.ic.core.utils.extend.extend({}, e, { row: options.row, column: options.column }));
                        e.event.preventDefault();
                    }));
                    options.rtlEnabled ? $container.prepend($button, "&nbsp;") : $container.append($button, "&nbsp;");
                }
            },
            _createEditingLinks: function (container, options, editingOptions, isRowMode) {
                var editingTexts = editingOptions.texts || {};

                // Check whether any of the cells in the row are actually editable
                var hasEditableCells = _.some(options.row.cells, function (cell) {
                    return cell.column.allowEditing && (!!cell.column.valuesThatDisable ?
                        cell.column.valuesThatDisable.indexOf(cell.data[cell.column.dataField]) === -1 :
                        true);
                })

                if (editingOptions.allowUpdating && isRowMode && hasEditableCells) {
                    this._createLink(container, editingTexts.editRow, "editRow", options, "dx-link-edit")
                }
                if (editingOptions.allowDeleting) {
                    if (options.row.removed) {
                        this._createLink(container, editingTexts.undeleteRow, "undeleteRow", options, "dx-link-undelete")
                    } else {
                        this._createLink(container, editingTexts.deleteRow, "deleteRow", options, "dx-link-delete")
                    }
                }
            },
            _delayedInputFocus: function ($cell, beforeFocusCallback, callBeforeFocusCallbackAlways) {
                var eventsEngine = DevExpress.events;
                var that = this;
                var EDITORS_INPUT_SELECTOR = "input:not([type='hidden'])";
                var FOCUSABLE_ELEMENT_SELECTOR = "[tabindex], " + EDITORS_INPUT_SELECTOR;

                function inputFocus() {
                    if (beforeFocusCallback) {
                        beforeFocusCallback();
                    }

                    if ($cell) {
                        eventsEngine.trigger($cell.find(FOCUSABLE_ELEMENT_SELECTOR).first(), "focus");
                    }

                    //Next if-block added to allow for setting focus to a different cell
                    if (that.option("icRestoreFocusToClickedCell")) {
                        var $focusSvc = that.option("ic.$focusSvc");
                        $focusSvc.restore();
                    }

                    that._beforeFocusCallback = null;
                }

                if (DevExpress.devices.real().ios || DevExpress.devices.real().android) {
                    inputFocus();
                } else {
                    if (that._beforeFocusCallback) that._beforeFocusCallback();

                    clearTimeout(that._inputFocusTimeoutID);

                    if (callBeforeFocusCallbackAlways) {
                        that._beforeFocusCallback = beforeFocusCallback;
                    }

                    that._inputFocusTimeoutID = setTimeout(inputFocus);
                }
            },
            // Override to allow immediate feedback for an invalid field
            updateFieldValue: function (e) {
                var that = this,
                    editMode = that.getEditMode();
                that.callBase.apply(that, arguments);
                if (editMode === EDIT_MODE_ROW || editMode === EDIT_MODE_BATCH || e.column.showEditorAlways || e.column.showValidationOnUpdate) {
                    //var currentValidator = that.getController("validating").getValidator();
                    var currentValidator = that.getController("validating")._currentCellValidator;
                    var validationResult = currentValidator && currentValidator.validate();
                    var isValid = !!validationResult && !!validationResult.isValid;

                    if (isValid && e.column.showValidationOnUpdate && e.cellElement) {
                        e.cellElement.attr('aria-live', 'assertive'); // Make sure the validation popup is announced on time
                        e.component.focus(e.cellElement); // Refocus so user is aware of failed validation before form submission and without focusing manually.

                    }

                    if (!!e.column.validationGroupName && e.model && e.model.applet && e.model.context) {
                        var helpersService = that.option('ic.helpersService');
                        helpersService.setAppStateDirty(e.model.applet.Name);
                        helpersService.refreshValidationGroupButtons(e.model.context._events, e.column.validationGroupName, isValid);
                    }
                }
            },
            _getFormEditItemTemplate: function (cellOptions, column) {
                // Original getColumnTemplate implementation, does not allow override getDefaultEditorTemplate
                return column.editCellTemplate || getDefaultEditorTemplate(this);
            },
            getColumnTemplate: function (options) {
                // Original getColumnTemplate implementation, does not allow override getDefaultEditorTemplate
                var that = this;
                var column = options.column;
                var rowIndex = options.row && options.row.rowIndex;
                var template;
                var isRowMode = isRowEditMode(that);
                var isRowEditing = that.isEditRow(rowIndex);
                var isCellEditing = that.isEditCell(rowIndex, options.columnIndex);
                var editingStartOptions;
                if ((column.showEditorAlways || column.setCellValue && (isRowEditing && column.allowEditing || isCellEditing))
                    && ("data" === options.rowType || "detailAdaptive" === options.rowType) && !column.command) {
                    var allowUpdating = that.allowUpdating(options);
                    if (((allowUpdating || isRowEditing) && column.allowEditing || isCellEditing) && (isRowMode && isRowEditing || !isRowMode)) {
                        if (column.showEditorAlways && !isRowMode) {
                            editingStartOptions = {
                                cancel: false,
                                key: options.row.isNewRow ? void 0 : options.row.key,
                                data: options.row.data,
                                column: column
                            };
                            that._isEditingStart(editingStartOptions)
                        }
                        if (!editingStartOptions || !editingStartOptions.cancel) {
                            options.setValue = function (value, text) {
                                that.updateFieldValue(options, value, text)
                            }
                        }
                    }
                    // InvestCloud: call to override getDefaultEditorTemplate
                    template = column.editCellTemplate || getDefaultEditorTemplate(that);
                    // InvestCloud
                } else {
                    if ("detail" === column.command && "detail" === options.rowType && isRowEditing) {
                        template = that.getEditFormTemplate(options)
                    }
                }
                return template
            },
        });

        var COLUMN_HEADERS_VIEW = "columnHeadersView",
            ROWS_VIEW = "rowsView",
            FOOTER_VIEW = "footerView",
            COLUMN_VIEWS = [COLUMN_HEADERS_VIEW, ROWS_VIEW, FOOTER_VIEW],
            ADAPTIVE_NAMESPACE = "dxDataGridAdaptivity",
            HIDDEN_COLUMNS_WIDTH = "adaptiveHidden",
            ADAPTIVE_ROW_TYPE = "detailAdaptive",
            FORM_ITEM_CONTENT_CLASS = "dx-field-item-content",
            FORM_ITEM_MODIFIED = "dx-item-modified",
            HIDDEN_COLUMN_CLASS = "hidden-column",
            ADAPTIVE_COLUMN_BUTTON_CLASS = "adaptive-more",
            ADAPTIVE_COLUMN_NAME_CLASS = "dx-command-adaptive",
            COMMAND_ADAPTIVE_HIDDEN_CLASS = "dx-command-adaptive-hidden",
            ADAPTIVE_DETAIL_ROW_CLASS = "dx-adaptive-detail-row",
            ADAPTIVE_ITEM_TEXT_CLASS = "dx-adaptive-item-text",
            MASTER_DETAIL_CELL_CLASS = "dx-master-detail-cell",
            LAST_DATA_CELL_CLASS = "dx-last-data-cell",
            ADAPTIVE_COLUMN_NAME = "adaptive",
            EDIT_MODE_BATCH = "batch",
            EDIT_MODE_ROW = "row",
            EDIT_MODE_FORM = "form",
            EDIT_MODE_POPUP = "popup",
            REVERT_TOOLTIP_CLASS = "revert-tooltip",
            CELL_HIGHLIGHT_OUTLINE = "dx-highlight-outline",
            ERROR_MESSAGE_CLASS = "dx-error-message",
            REVERT_BUTTON_CLASS = "dx-revert-button";

        function getColumnId(column) {
            return column.command ? "command:" + column.command : column.index
        }

        function addAdaptiveRowToArray(item, processedItems, collapseItem) {
            if (collapseItem && collapseItem.key) {
                var collapseRowIndex = DevExpress.ic.gridCore.getIndexByKey(collapseItem.key, processedItems);
                var removePosIdx = collapseRowIndex + 1;
                processedItems.splice(removePosIdx, 1);
            } else {
                var expandRowIndex = DevExpress.ic.gridCore.getIndexByKey(item.key, processedItems);
                var insertPosIdx = expandRowIndex + 1;
                if (expandRowIndex >= 0) {
                    if (insertPosIdx == processedItems.length || processedItems[insertPosIdx].rowType != ADAPTIVE_ROW_TYPE) {
                        processedItems.splice(insertPosIdx, 0, {
                            visible: true,
                            rowType: ADAPTIVE_ROW_TYPE,
                            key: item.key,
                            data: item.data,
                            modifiedValues: item.modifiedValues,
                            inserted: item.inserted,
                            values: item.values
                        })
                    }
                }
            }
        }

        function getItemFromOriginalItems(key, items) {
            var index = DevExpress.ic.gridCore.getIndexByKey(key, items);
            return items[index];
        }

        function setARIARolesForGridElements() {
            // Ensures elements with an ARIA role that require child roles contains them.
            var $dxDataGridFilterRow = $('.dx-datagrid-filter-row');
            if ($dxDataGridFilterRow && $dxDataGridFilterRow.length > 0) {

                var divMenuGridElements = $dxDataGridFilterRow.find('div.dx-menu.dx-menu-base');
                if (divMenuGridElements && divMenuGridElements.length > 0) {
                    divMenuGridElements.attr('role', 'menubar');
                }

                var ulGridElements = $dxDataGridFilterRow.find('ul.dx-menu-items-container');
                if (ulGridElements && ulGridElements.length > 0) {
                    ulGridElements.attr('role', 'menu');
                }

                var listGridElement = $dxDataGridFilterRow.find('li.dx-menu-item-wrapper');
                if (listGridElement && listGridElement.length > 0) {
                    listGridElement.attr('role', 'menuitem');
                }

                var divContentGridElement = $dxDataGridFilterRow.find('div.dx-menu-item-content');
                if (divContentGridElement && divContentGridElement.length > 0) {
                    divContentGridElement.attr('role', 'menuitem');
                }

                var divItemGridElement = $dxDataGridFilterRow.find('div.dx-menu-item');
                if (divItemGridElement && divItemGridElement.length > 0) {
                    divItemGridElement.attr('role', 'menu');
                }

                var divSubItemGridElement = $dxDataGridFilterRow.find('div.dx-menu-item-has-submenu');
                if (divSubItemGridElement && divSubItemGridElement.length > 0) {
                    divSubItemGridElement.attr('role', 'menu');
                }

                var divHorizontalGridElement = $dxDataGridFilterRow.find('div.dx-menu-horizontal');
                if (divHorizontalGridElement && divContentGridElement.length > 0) {
                    divHorizontalGridElement.attr('role', 'menuitem');
                }
            }
        }

        this.getController('adaptiveColumns').constructor.redefine({

            // Need to overwite the init to set  cellTemplate: adaptiveCellTemplateWithGroupingHeaders
            // allowing group headers to have adaptive rows
            init: function () {

                function adaptiveCellTemplateWithGroupingHeaders(container, options) {
                    var eventsEngine = DevExpress.events,
                        eventUtils = DevExpress.events.utils,
                        clickEvent = DevExpress.events.click;

                    var $adaptiveColumnButton,
                        $container = $(container),
                        adaptiveColumnsController = options.component.getController("adaptiveColumns");

                    var showGroupHeaderAdaptiveRow = false;
                    var headerLevels = options.component.option('adaptiveGroupHeaders');
                    if (headerLevels) {
                        if (headerLevels == 'all') {
                            showGroupHeaderAdaptiveRow = true;
                        } else {
                            var levels = headerLevels ? headerLevels.split(',').map(Number) : [];
                            if (levels.indexOf(options.row.groupIndex + 1) > -1) {
                                showGroupHeaderAdaptiveRow = true;
                            }
                        }
                    }

                    if ("data" === options.rowType || ("group" === options.rowType && showGroupHeaderAdaptiveRow)) {
                        $adaptiveColumnButton = $("<span>").addClass(adaptiveColumnsController.addWidgetPrefix(ADAPTIVE_COLUMN_BUTTON_CLASS));
                        eventsEngine.on($adaptiveColumnButton, eventUtils.addNamespace(clickEvent.name, ADAPTIVE_NAMESPACE), adaptiveColumnsController.createAction(function () {
                            adaptiveColumnsController.toggleExpandAdaptiveDetailRow(options.key);
                        }));

                        $adaptiveColumnButton.appendTo($container);
                    } else {
                        //gridCoreUtils.setEmptyText($container);
                        $container.get(0).textContent = "\u00A0";
                    }
                }

                var that = this;
                that._columnsController = that.getController("columns");
                that._dataController = that.getController("data");
                that._rowsView = that.getView("rowsView");

                that._columnsController.addCommandColumn({
                    type: ADAPTIVE_COLUMN_NAME,
                    command: ADAPTIVE_COLUMN_NAME,
                    visible: true,
                    adaptiveHidden: true,
                    cssClass: ADAPTIVE_COLUMN_NAME_CLASS,
                    width: "auto",
                    cellTemplate: adaptiveCellTemplateWithGroupingHeaders,
                    fixedPosition: "right"
                });

                that._columnsController.columnsChanged.add(function () {
                    var isAdaptiveVisible = !!that.updateHidingQueue(that._columnsController.getColumns()).length;
                    that._columnsController.columnOption("command:adaptive", "adaptiveHidden", !isAdaptiveVisible, true);
                });
                that._editingController = that.getController("editing");
                that._hidingColumnsQueue = [];
                that._hiddenColumns = [];
                that.createAction("onAdaptiveDetailRowPreparing");

                that.callBase();
            },


            _getNotTruncatedColumnWidth: function (column, containerWidth, contentColumns, columnsCanFit) {
                var colWidth, columnId = getColumnId(column),
                    widthOption = this._columnsController.columnOption(columnId, "width"),
                    bestFitWidth = this._columnsController.columnOption(columnId, "bestFitWidth"),
                    columnsCount = contentColumns.length;
                if (widthOption && "auto" !== widthOption) {
                    if (this._isPercentWidth(widthOption)) {
                        colWidth = this._calculatePercentWidth({
                            visibleIndex: column.visibleIndex,
                            columnsCount: columnsCount,
                            columnsCanFit: columnsCanFit,
                            bestFitWidth: bestFitWidth,
                            columnWidth: widthOption,
                            containerWidth: containerWidth
                        })
                    } else {
                        return widthOption
                    }
                } else {
                    var columnAutoWidth = this.option("columnAutoWidth");
                    colWidth = columnAutoWidth || !!column.command ? bestFitWidth : this._getAverageColumnsWidth(containerWidth, contentColumns, columnsCanFit)
                }

                var isTruncated = colWidth < bestFitWidth && !this.option("showAllDefaultColumns"); // Overriding this line to support a list option
                return isTruncated ? null : colWidth
            },
            _addCssClassToViewColumn: function (view, cssClassName, visibleIndex) {
                var rowsCount, rowIndex, $cellElement, currentVisibleIndex, viewName = view.name,
                    column = this._columnsController.getVisibleColumns()[visibleIndex],
                    editFormRowIndex = this._editingController && this._editingController.getEditFormRowIndex();
                if (view && view.isVisible() && column) {
                    rowsCount = view.getRowsCount();
                    var $rowElements = view._getRowElements();
                    for (rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
                        if (rowIndex !== editFormRowIndex || viewName !== ROWS_VIEW) {
                            currentVisibleIndex = viewName === COLUMN_HEADERS_VIEW ? this._columnsController.getVisibleIndex(column.index, rowIndex) : visibleIndex;
                            // exceptions to the rule
                            var skipRow = $rowElements.eq(rowIndex).hasClass('dx-group-row') && // it's a group
                                cssClassName === 'dx-datagrid-hidden-column' && // we are applying hidden columns
                                this.option("preventGridGroupCellHiding"); // theme property is set
                            if (currentVisibleIndex >= 0 && !skipRow) {
                                $cellElement = $rowElements.eq(rowIndex).children().eq(currentVisibleIndex);
                                if (this._isCellValid($cellElement)) {
                                    $cellElement.addClass(cssClassName);

                                    // region IC
                                    if (cssClassName === COMMAND_ADAPTIVE_HIDDEN_CLASS) {
                                        var buttons = $cellElement.find('span.dx-datagrid-adaptive-more');
                                        if (buttons.length) {
                                            buttons.removeAttr('tabindex');
                                        }
                                    }
                                    // endregion IC
                                }
                            }
                        }
                    }
                }
                setARIARolesForGridElements();
            },
            hideRedundantColumns: function (resultWidths, visibleColumns, hiddenQueue) {
                var that = this,
                    visibleColumn;

                this._hiddenColumns = [];

                if (that._isVisibleColumnsValid(visibleColumns) && hiddenQueue.length) {
                    var totalWidth = 0,
                        percentWidths,
                        $rootElement = that.component.$element(),
                        rootElementWidth = $rootElement.width() - that._getCommandColumnsWidth(),
                        contentColumns = visibleColumns.filter(function (item) {
                            return !item.command;
                        }),
                        columnsCanFit,
                        i,
                        contentColumnCount = contentColumns.length,
                        needHideColumn;

                    do {
                        needHideColumn = false;
                        totalWidth = 0;

                        percentWidths = that._calculatePercentWidths(resultWidths, visibleColumns);
                        columnsCanFit = percentWidths < 100 && percentWidths !== 0;
                        for (i = 0; i < visibleColumns.length; i++) {
                            visibleColumn = visibleColumns[i];

                            var columnWidth = that._getNotTruncatedColumnWidth(visibleColumn, rootElementWidth, contentColumns, columnsCanFit),
                                columnId = getColumnId(visibleColumn),
                                widthOption = that._columnsController.columnOption(columnId, "width"),
                                columnBestFitWidth = that._columnsController.columnOption(columnId, "bestFitWidth");

                            if (that.option("icRecalcRightmostColumnWidth")) {
                                columnWidth = that._icGetWidthRecalculatedForRightmostColumn(columnWidth, i, visibleColumns.length, widthOption, rootElementWidth);
                            }

                            if (resultWidths[i] === HIDDEN_COLUMNS_WIDTH) {
                                continue;
                            }
                            if (!columnWidth && !visibleColumn.command && !visibleColumn.fixed) {
                                needHideColumn = true;
                                break;
                            }

                            if (widthOption && widthOption !== "auto" && this._icIsNumericWidth(columnWidth)) {
                                totalWidth = Number(new Big(totalWidth).add(parseFloat(columnWidth)));
                            } else {
                                totalWidth += columnBestFitWidth || 0;
                            }
                        }
                        needHideColumn = needHideColumn || totalWidth > $rootElement.width();
                        if (needHideColumn) {
                            var column = hiddenQueue.pop(),
                                visibleIndex = that._columnsController.getVisibleIndex(column.index);

                            that._hideVisibleColumn({ visibleIndex });
                            resultWidths[visibleIndex] = HIDDEN_COLUMNS_WIDTH;
                            contentColumnCount--;
                            this._hiddenColumns.push(column);
                        }
                    }
                    while (needHideColumn && contentColumnCount > 1 && hiddenQueue.length);

                    if (contentColumnCount === contentColumns.length) {
                        that._hideAdaptiveColumn(resultWidths, visibleColumns);
                    }
                } else {
                    that._hideAdaptiveColumn(resultWidths, visibleColumns);
                }
            },
            /**
             * Because DevExtreme measures the content's width on the DOM using getBoundingClientRect
             * and the last column is allowed to fill out any extra grid width, the value can be unnecessarily
             * high. This can result in the last column being unnecessarily hidden.
             * @param {number} columnWidth
             * @param {number} visibleColumnIndex
             * @param {number} numOfVisibleColumns
             * @param {string} widthOption
             * @param {number} rootElementWidth
             */
            _icGetWidthRecalculatedForRightmostColumn: function (columnWidth, visibleColumnIndex, numOfVisibleColumns, widthOption, rootElementWidth) {
                var isRightmostVisibleColumn = visibleColumnIndex === numOfVisibleColumns - 2;
                if (!isRightmostVisibleColumn || typeof widthOption !== 'string') {
                    return columnWidth;
                }

                var isPercentageWidth = widthOption.slice(-1) === '%' && this._icIsNumericWidth(widthOption);
                if (!isPercentageWidth) {
                    return columnWidth;
                }

                var measuredWidth = Number(new Big(rootElementWidth).times(parseFloat(widthOption)).div(100));
                return measuredWidth;
            },
            _icIsNumericWidth: function (value) {
                return !_.isUndefined(value) && !isNaN(parseFloat(value));
            },
        });

        this.getController('adaptiveColumns').getController('data').constructor.redefine({
            _processItems: function (items, changeType) {
                var that = this;
                items = that.callBase.apply(that, arguments);
                if ("loadingAll" === changeType) {
                    return items
                }

                // Investcloud change
                if (this.option('retainExpandedAdaptiveRows')) {
                    if (that._icRetainExpandedRows && that._adaptiveExpandedKey) {
                        var item = getItemFromOriginalItems(that._adaptiveExpandedKey, items);
                        addAdaptiveRowToArray(item, that._icRetainExpandedRows, that._icCollapseItem);

                        if (!_.isUndefined(that._icAllHiddenColumnsExpanded)) {
                            that._icAdaptiveRowsItems = {
                                visible: true,
                                rowType: ADAPTIVE_ROW_TYPE,
                                key: item.key,
                                data: item.data,
                                modifiedValues: item.modifiedValues,
                                inserted: item.inserted,
                                values: item.values
                            }
                        }
                    } else {
                        that._icRetainExpandedRows = _.cloneDeep(items);
                    }

                    return that._icRetainExpandedRows;
                }
                // End of Investcloud change

                return items;
            },
            toggleExpandAdaptiveDetailRow: function (key, alwaysExpanded) {
                var that = this;
                that._icCollapseItem = void 0;
                var oldExpandRowIndex = DevExpress.ic.gridCore.getIndexByKey(that._adaptiveExpandedKey, that._items);
                var newExpandRowIndex = DevExpress.ic.gridCore.getIndexByKey(key, that._items);

                // InvestCloud change
                var activeAdaptiveRowBtn = that.component._$element.find(getActiveElement());
                var prevActiveAdaptiveBtn = that.component._$element.find(".dx-datagrid-adaptive-more[aria-expanded=true]");
                var isActiveAdaptiveRowBtnExpanded = activeAdaptiveRowBtn.attr("aria-expanded");
                var isAdaptiveRowAriaExpanded;

                if (isActiveAdaptiveRowBtnExpanded == "true") {
                    isAdaptiveRowAriaExpanded = false;
                } else {
                    isAdaptiveRowAriaExpanded = true;
                }

                prevActiveAdaptiveBtn.attr("aria-expanded", false);
                activeAdaptiveRowBtn.attr("aria-expanded", isAdaptiveRowAriaExpanded);

                if (this.option('retainExpandedAdaptiveRows') || alwaysExpanded) {
                    var item = that._items[newExpandRowIndex + 1];
                    that._isAlreadyExpanded = item && item.rowType === ADAPTIVE_ROW_TYPE;
                    that._icCollapseItem = {};

                    if ((that._icAllHiddenColumnsExpanded == true && that._isAlreadyExpanded) ||
                        (that._icAllHiddenColumnsExpanded == false && !that._isAlreadyExpanded)) {
                        that._icCollapseItem = {};
                    }
                    else if (that._isAlreadyExpanded) {
                        that._icCollapseItem = item;
                    }
                }
                // End of investcloud change
                else if (oldExpandRowIndex >= 0 && oldExpandRowIndex === newExpandRowIndex && !alwaysExpanded) {
                    key = void 0;
                    newExpandRowIndex = -1;
                }

                that._adaptiveExpandedKey = key;
                if (oldExpandRowIndex >= 0) {
                    oldExpandRowIndex++
                }
                if (newExpandRowIndex >= 0) {
                    newExpandRowIndex++
                }

                that.updateItems({
                    changeType: "update",
                    rowIndices: [oldExpandRowIndex, newExpandRowIndex]
                })
            },
            updateItems: function (change, isDataChanged) {
                change = change || {};
                var that = this;
                if (void 0 !== that._repaintChangesOnly) {
                    change.repaintChangesOnly = that._repaintChangesOnly
                } else {
                    if (change.changes) {
                        change.repaintChangesOnly = that.option("repaintChangesOnly")
                    } else {
                        if (isDataChanged) {
                            var operationTypes = that.dataSource().operationTypes();
                            change.repaintChangesOnly = operationTypes && !operationTypes.grouping && !operationTypes.filtering && that.option("repaintChangesOnly");
                            change.isDataChanged = true;
                            if (operationTypes && (operationTypes.reload || operationTypes.paging || operationTypes.groupExpanding)) {
                                change.needUpdateDimensions = true
                            }
                        }
                    }
                }
                if (that._updateLockCount) {
                    that._changes.push(change);
                    return
                }
                that._updateItemsCore(change);
                if (change.cancel) {
                    return
                }

                // Investcloud change batch update
                if (!_.isUndefined(that._icAllHiddenColumnsExpanded)) {
                    if (that._icExpandCollapseAdaptiveRows.items.length != that._dataSource.totalCount()) {
                        that._items = that._icRetainExpandedRows
                        return;
                    }

                    // Reset and trigger changes
                    that._icAllHiddenColumnsExpanded = void 0;
                    that._items = that._icRetainExpandedRows.slice();

                    that._fireChanged(that._icExpandCollapseAdaptiveRows)
                } else {
                    that._fireChanged(change)
                }

                var component = this.component;

                if (change && change.changeType === "refresh"
                    && this.component.option('masterDetail.template') && change.items
                    && change.items.filter(function (el) { return el.rowType === "detail"; })[0]) {
                    setTimeout(function () { component.updateDimensions(); }, 0);
                }
                // End of Investcloud change
            },
        });

        // Override DataProvider._getGroupValue in export module
        // to control how the group value appears
        this.getController('export').getDataProvider().constructor.redefine({
            '_getGroupValue': function (item) {
                var $formatSvc = this._exportController.component.option('ic.$formatSvc');
                var groupColumn = this._options.groupColumns[item.groupIndex];
                var nonObjVal = _.isUndefined(item.key[item.groupIndex]) ?
                    item.values[0] :
                    item.key[item.groupIndex];
                var value;
                if (groupColumn.dataType === "date") {
                    value = $formatSvc.format(groupColumn.format.name, nonObjVal);
                } else {
                    value = !_.isUndefined(groupColumn.customizeText) ?
                        groupColumn.customizeText({
                            valueText: nonObjVal
                        }) :
                        nonObjVal;
                }
                var result = this._exportController.component._options.omitGroupingNamesOnExport ?
                    value :
                    groupColumn.caption + ": " + value;
                return result;
            }
        });

        // Override column chooser positioning information
        this.getController("columnChooser").constructor.redefine({
            getPosition: function () {
                var rowsView = this.getView("rowsView");

                return {
                    my: "top left",
                    at: "right top",
                    of: rowsView && rowsView.element(),
                    collision: "fit",
                    offset: "25 -2",
                    boundaryOffset: "2 2"
                };
            }
        });

        this.getView("headerPanel").constructor.redefine({
            _renderButton: function (data, $container, withText) {
                var that = this,
                    buttonOptions = that._getButtonOptions(data.allowExportSelected),
                    $buttonContainer = that._getButtonContainer().addClass(DATAGRID_EXPORT_BUTTON_CLASS).appendTo($container);
                if (withText) {
                    var wrapperNode = $("<div>").addClass(TOOLBAR_ITEM_AUTO_HIDE_CLASS);
                    $container.wrapInner(wrapperNode).parent().addClass("dx-toolbar-menu-action dx-toolbar-menu-button " + TOOLBAR_HIDDEN_BUTTON_CLASS);
                    buttonOptions.text = buttonOptions.hint;
                }
                that._createComponent($buttonContainer, DevExpress.ui.dxButton, buttonOptions);

                var exportBtnImage = that.component.option("excelExportImage");
                if (exportBtnImage) {
                    var exportIcon = $buttonContainer.find('i');
                    if (exportIcon)
                        exportIcon.hide();

                    var buttonContentDiv = $buttonContainer.find('.dx-button-content');

                    if (buttonContentDiv.length > 0) {
                        $('<img />').attr({
                            'src': exportBtnImage,
                            'role': 'presentation'
                        }).appendTo(buttonContentDiv[0]);
                    }
                }
            },
            _appendColumnChooserItem: headerPanel_appendColumnChooserItem,
        });

        this.getController('data').constructor.redefine({
            _handleColumnsChanged: handler_handleColumnsChanged
        });

        // Override the column chooser view functions
        this.getView("columnChooserView").constructor.redefine(columnChooserView);

        // Override column controller methods
        this.getController("columns").constructor.redefine(columnsController);

        // Override columnHeadersView for keyboard accessibility
        var A11Y_DIV_CLASSNAME = "ic-a11y-column-header";
        var A11Y_DIV_ID_SUFFIX = "_IcA11yColumnHeader";

        function decorateColumnHeaderTd(that, $parent, column) {
            if (!$parent) return;

            var uniqueId = $parent.data("unique-id");
            if (uniqueId == null) {
                uniqueId = getUniqueId();
                $parent.data("unique-id", uniqueId);
            }

            var divId = $parent.eq(0).attr("id") + A11Y_DIV_ID_SUFFIX + "_" + uniqueId;
            var $div = $parent.find("#" + divId);

            if ($div.length) return $div;

            $div = $("<div></div>").appendTo($parent);
            $div.attr("id", divId);

            var titleAttr = "";
            if (column?.allowSorting) {
                var message = "Press enter/space bar key to toggle the sort, Press Shift+F10 to get the sorting options for " + column.caption + " column";
                var utilSvc = that.option("ic.$utilSvc");
                var translatedHint = utilSvc?.getTranslation(message);
                titleAttr = [titleAttr, translatedHint].join(" ");
            }
            $div.attr("role", "button");
            $div.addClass(A11Y_DIV_CLASSNAME);

            var icAccessibilitySvc = that.option("ic.$icAccessibilitySvc");
            icAccessibilitySvc?.handleEnterKeyForAccessibility($div, false);

            // By Deque recommendation, setting aria-label = column name & title = instructions.
            // with a reader it reads the column name and the instructions for the header.
            $div.attr('title', titleAttr);
            $div.attr('aria-label', column.caption);

            return $div;
        }

        this.getView("columnHeadersView").constructor.redefine({

            _columnOptionChanged: function (e) {
                var that = this;

                // This override is for when grid filter field is using a range selector for a date
                // When the format is passed for date to be a sugar date format e.g. dd Mon yyyy => that translates to 15 Feb 2019
                // But the above sugar format is not compatible with DevExtreme and therefore instead of 15 Feb 2019 => 15 Fon 2019 is shown
                // The below helpers function is used to transform Sugar Date format to dd MMM yyyy
                if (e.columnIndex > -1) {
                    var column = that._columnsController.columnOption(e.columnIndex);
                    if (column.dataType == "date" && column.format && column.format.type) {
                        var format = column.format.type;
                        if (format) {
                            var formattedValue = handleMonthFieldMasks(format);
                            column.format.type = formattedValue;
                        }
                    }
                }
                that.callBase(e);
            },
            _applyColumnState: function (options) {
                var that = this;

                // override the sort indicators
                // sometimes we need icCustomSort from state because it hasn't been loaded to dataGrid yet
                var customSort = that.component.option('sorting').icCustomSort || this.getController("stateStoring").state().icCustomSort;
                if (options.column && options.name === "sort" &&
                    customSort && !_.isUndefined(customSort.clickedColumnDataField)
                ) {
                    if (customSort.clickedColumnDataField === options.column.dataField) {
                        options.column.sortOrder = customSort.sortOrder;
                    } else {
                        delete options.column.sortOrder;
                    }
                }

                that.callBase.call(that, options);

                if (options.column && options.column.allowSorting) {
                    var $accessibleDiv = decorateColumnHeaderTd(that, options.rootElement, options.column);
                    if ($accessibleDiv) {
                        $accessibleDiv.attr('aria-haspopup', 'menu');
                        const $columnIndicators = options.rootElement.find('.dx-column-indicators').detach();
                        if ($columnIndicators.length)
                            $columnIndicators.appendTo($accessibleDiv);
                    }
                }
            },
            _renderCellContent: function ($cell, options) {
                var that = this;
                that.callBase.call(that, $cell, options);
                var column = options.column;
                if (!column.command && "header" === options.rowType) {
                    var $accessibleDiv = decorateColumnHeaderTd(that, $cell, column);
                    if ($accessibleDiv) {
                        $cell.children().not($accessibleDiv).detach().appendTo($accessibleDiv);
                    }
                    that._applyColumnState({
                        name: "sort",
                        rootElement: $cell,
                        column: column,
                        showColumnLines: that.option("showColumnLines")
                    });

                    if (column.allowSorting == false) {
                        $cell.find("[role]").removeAttr("role");
                        $cell.find("[tabindex]").removeAttr("tabindex");
                    }
                }
            },
            _renderCell: function ($row, options) {
                var $cell = this.callBase.apply(this, arguments);
                var accessibilitySvc = this.option("ic.$icAccessibilitySvc");
                if (accessibilitySvc == null) {
                    return $cell;
                }
                var view = "columnheaders";
                return accessibilitySvc.addAccessibilityToDxDataGridRenderCell(this, $row, $cell, options, view);
            },
            _renderCells: function ($row, options) {
                var accessibilitySvc = this.option("ic.$icAccessibilitySvc")
                if (accessibilitySvc == null) {
                    this.callBase.apply(this, arguments);
                    return;
                }
                var view = "columnheaders";
                accessibilitySvc.addAccessibilityToDxDataGridRenderCells(this, $row, options, view, getUniqueId);
            },
            _renderSelectAllCheckBox: function ($container, column) {
                var SELECT_CHECKBOX_CLASS = "dx-select-checkbox";
                if (!this.option('selection.disableCheckboxes')) {
                    var selectAllCheckBox = this.callBase.apply(this, arguments);
                    selectAllCheckBox.attr("tabindex", 0);

                    return selectAllCheckBox;
                }

                // Intent is to handle selection.disableCheckboxes case by adding disabled: true to editorOptions
                // (needed for BecomeUser)
                var groupElement, that = this,
                    selectionController = that.getController("selection");
                groupElement = $("<div>").appendTo($container).addClass(SELECT_CHECKBOX_CLASS);
                that.setAria("label", DevExpress.localization.message.format("dxDataGrid-ariaSelectAll"), $container);
                that.getController("editorFactory").createEditor(groupElement, _.extend({}, column, {
                    parentType: "headerRow",
                    dataType: "boolean",
                    value: selectionController.isSelectAll(),
                    editorOptions: {
                        disabled: true,
                        visible: that.option("selection.allowSelectAll") || false !== selectionController.isSelectAll()
                    },
                    tabIndex: -1,
                    setValue: function (value, e) {
                        var allowSelectAll = that.option("selection.allowSelectAll");
                        e.component.option("visible", allowSelectAll || false !== e.component.option("value"));
                        if (!e.event || selectionController.isSelectAll() === value) {
                            return;
                        }
                        if (e.value && !allowSelectAll) {
                            e.component.option("value", false)
                        } else {
                            e.value ? selectionController.selectAll() : selectionController.deselectAll()
                        }
                        e.event.preventDefault()
                    }
                }));
                return groupElement;
            },
            _setCellAriaAttributes: function ($cell, cellOptions) {
                if ("header" === cellOptions.rowType) {
                    var fixed = this._isFixedTableRendering;
                    this.setAria("role", "columnheader", $cell);
                    if (cellOptions.column && !cellOptions.column.command && !cellOptions.column.isBand) {
                        cellOptions.column.headerId = cellOptions.column.colId ? cellOptions.column.colId :
                            cellOptions.column.headerId;
                        var fieldName = 'columnHeader' + (fixed ? 'Fixed' : 'Scrollable');
                        const gridScope = this.option('gridScope');
                        if (gridScope) {
                            if (!gridScope[fieldName]) {
                                gridScope[fieldName] = {};
                            }
                            gridScope[fieldName][cellOptions.column.index] = cellOptions.column.headerId;
                        }
                        $cell.attr("id", cellOptions.column.headerId);
                    }
                }

                if (cellOptions.rowType == "filter") {
                    $cell.attr("data-caption", cellOptions.column.caption);
                }

                // Overriding this function to avoid aria-label on column headers
                // (we use visible text instead)
                $cell.attr("aria-label", null);

                // ...and to avoid aria-selected=false on column headers, which
                // are not selectable in our implementation of dxDataGrid and
                // doesn't help accessibility
                $cell.attr("aria-selected", null);
            },
            _updateFilterOperationChooser: function ($menu, column, $editorContainer) {
                var _menu2 = _interopRequireDefault(DevExpress.ui.dxMenu);
                var that = this;
                that.callBase.call(that, $menu, column, $editorContainer);
                var menu = _menu2.default.getInstance($menu);

                if (that._getDxDateboxInstance($editorContainer) instanceof DevExpress.ui.dxDateBox) {
                    // For date filters, replace the logic as below
                    menu._options.onItemClick = function (properties) {
                        that._selectedFilterOperationChange(that, column, $editorContainer, properties);
                    }
                }
            },
            _isOnClickApplyFilterMode: function (that) {
                return "onClick" === that.option("filterRow.applyFilter")
            },
            _getColumnSelectedFilterOperation: function (that, column) {
                if (column) {
                    return that._isOnClickApplyFilterMode(that) && void 0 !== column.bufferedSelectedFilterOperation ? column.bufferedSelectedFilterOperation : column.selectedFilterOperation
                }
            },
            _getDxDateboxInstance: function ($editorContainer) {
                var $editor = $editorContainer && $editorContainer.children(),
                    componentNames = $editor && $editor.data("dxComponents"),
                    editor = componentNames && componentNames.length && $editor.data(componentNames[0]);
                if (editor instanceof DevExpress.ui.dxDateBox) {
                    return editor
                }
            },
            _selectedFilterOperationChange: function (that, column, $editorContainer, properties) {
                var selectedFilterOperation = properties.itemData.name,
                    columnSelectedFilterOperation = that._getColumnSelectedFilterOperation(that, column),
                    notFocusEditor = false,
                    isOnClickMode = that._isOnClickApplyFilterMode(that),
                    options = {};

                if (properties.itemData.items || selectedFilterOperation && selectedFilterOperation === columnSelectedFilterOperation) {
                    return
                }
                if (selectedFilterOperation) {
                    options[isOnClickMode ? "bufferedSelectedFilterOperation" : "selectedFilterOperation"] = selectedFilterOperation;
                    if ("between" === selectedFilterOperation || "between" === columnSelectedFilterOperation) {
                        notFocusEditor = "between" === selectedFilterOperation;
                        options[isOnClickMode ? "bufferedFilterValue" : "filterValue"] = null
                    }
                } else {
                    options[isOnClickMode ? "bufferedFilterValue" : "filterValue"] = null;
                    options[isOnClickMode ? "bufferedSelectedFilterOperation" : "selectedFilterOperation"] = column.defaultSelectedFilterOperation || null
                }
                that._columnsController.columnOption(column.index, options);
                that._applyFilterViewController.setHighLight($editorContainer, true);
                if (!selectedFilterOperation) {
                    var editor = that._getDxDateboxInstance($editorContainer);
                    if (editor && "dxDateBox" === editor.NAME && !editor.option("isValid")) {
                        editor.reset();
                        editor.option("isValid", true)
                    }
                }
                if (!notFocusEditor) {
                    // BEGIN Custom code
                    if (!IX_isIOS()) {
                        that._focusEditor($editorContainer);
                    } else {
                        // IOS devices trigger the date input's calendar dropdown on focus, unlike everything else
                        // So only do the part that doesn't break the Reset behavior
                        that.getController("editorFactory").focus($editorContainer);
                    }
                    // END Custom code
                } else {
                    that._showFilterRange($editorContainer.closest("." + EDITOR_CELL_CLASS), column)
                }
            },
        });

        this.getView("footerView").constructor.redefine({

            _renderCell: function ($row, options) {
                var that = this;
                var $cell = that.callBase.apply(that, arguments);

                var accessibilitySvc = this.option("ic.$icAccessibilitySvc")
                if (accessibilitySvc == null) {
                    return $cell;
                }

                var view = "footer";
                return accessibilitySvc.addAccessibilityToDxDataGridRenderCell(this, $row, $cell, options, view);
            },
            _renderCells: function ($row, options) {
                var accessibilitySvc = this.option("ic.$icAccessibilitySvc")
                if (accessibilitySvc == null) {
                    this.callBase.apply(this, arguments);
                    return;
                }

                var view = "footer";
                accessibilitySvc.addAccessibilityToDxDataGridRenderCells(this, $row, options, view, getUniqueId);
            },
        });

        function isAlternatingRowImpartial(that) {
            var themeSvc = that.option('ic.$themeSvc');
            var themeProp = (!themeSvc || !themeSvc.getThemeProperty) ? null : themeSvc.getThemeProperty("#List.AlternatingRowStyle")
            return !!themeProp && themeProp.Value1.toLowerCase() === "impartialtorowtype";
        }

        this.getView("rowsView").constructor.redefine({
            contentWidth: function () {
                var contentWidth = this.element().width() - this.getScrollbarWidth();
                if (contentWidth == 0) {
                    // This means grid has not rendered yet, and is possibly at loading stage. 
                    // This is the right time to fix the column width
                    const appName = this.component.appName;
                    if (!appName) return contentWidth;
                    const selector = "." + appName.replaceAll(".", "_") + "Ctrl";
                    let $appElement = $(selector);
                    const options = this.option();
                    if (options?.columnFixing?.enabled)
                        $appElement = $($appElement.get(0)).parent();
                    contentWidth = $appElement.width() - this.getScrollbarWidth();
                }
                return contentWidth;
            },
            _getNoDataText: function () {
                var noDataText = this.option("noDataText"),
                    $themeSvc = this.option('ic.$themeSvc'),
                    listNoDataTextId = ".htmls.noDataToDisplayText",
                    appName = this.component.appName;
                listNoDataTextId = appName ? (appName + listNoDataTextId) : "";
                return $themeSvc.textToShowOnNoData(listNoDataTextId, noDataText);
            },
            _createRow: function (row) {
                // Overriding to add class .dx-datagrid-edit-form to element of class
                // .dx-master-detail-row
                var $row = this.callBase(row);

                if (row && this._isDetailRow(row)) {
                    $row.addClass("dx-datagrid-edit-form");
                }

                // Make group headers keyboard-accessible
                if (row && row.rowType === "group") {
                    $row.attr("tabindex", 0);
                }

                // Setting aria-selected on table rows which cannot be selected
                // is invalid; overriding to avoid this. May want to do so conditionally
                // if in future icGrid is augmented to provide selectable rows.
                $row.attr("aria-selected", null);

                return $row;
            },
            _rowPrepared: function ($row, rowOptions, row) {
                var ROW_ALTERNATION_CLASS = "dx-row-alt";
                var _this = this;
                if (isAlternatingRowImpartial(_this)) {
                    // IC change
                    if (this.option("rowAlternationEnabled")) {
                        const indexMap = _this.option('icVisibleRowsIndexed') || new Map();
                        const mapKey = JSON.stringify(rowOptions.key);
                        var getRowAlt = function () {
                            const index = indexMap.has(mapKey) ? indexMap.get(mapKey) : rowOptions.rowIndex;
                            return index % 2 === 0;
                        };
                        if (getRowAlt()) {
                            $row.addClass(ROW_ALTERNATION_CLASS);
                        } else {
                            $row.removeClass(ROW_ALTERNATION_CLASS);
                        }
                        rowOptions.watch && rowOptions.watch(function () {
                            return getRowAlt();
                        }, function (value) {
                            $row.toggleClass(ROW_ALTERNATION_CLASS, value);
                        })
                    }
                    this._setAriaRowIndex(rowOptions, $row);
                    rowOptions.watch && rowOptions.watch(function () {
                        return rowOptions.rowIndex;
                    }, function () {
                        return _this._setAriaRowIndex(rowOptions, $row);
                    })

                    // replaces the callBase to ColumnsView _rowPrepared
                    $($row.get(0)).data("options", rowOptions);
                    rowOptions.rowElement = $row; // $row.get(0);
                    this.executeAction("onRowPrepared", rowOptions);
                } else {
                    // use the usual dx _rowPrepared
                    this.callBase.apply(this, arguments);
                }
            },
            _renderRows: function ($table, options) {
                const gridScope = this.option('gridScope');
                if (gridScope) {
                    // When rendering rows, throw away cached table column count, since it may have changed
                    gridScope.colCount = undefined;
                    if (gridScope.allowGroupBlocking
                        && isAlternatingRowImpartial(this)
                        && !_.isEmpty(options.change?.items)) {
                        var idx = 0;
                        const visibleRows = new Map();
                        _.each(options.change.items, (item) => {
                            visibleRows.set(JSON.stringify(item.key), item.rowType !== 'data' ? idx++ : -1);
                        });
                        this.option('icVisibleRowsIndexed', visibleRows);
                    }
                }
                this.callBase.call(this, $table, options);
            },
            _renderCell: function ($row, options) {
                var that = this;
                var $cell = that.callBase.apply(that, arguments);
                var accessibilitySvc = this.option("ic.$icAccessibilitySvc");
                if (accessibilitySvc == null) {
                    return $cell;
                }
                var view = "rows";
                return accessibilitySvc.addAccessibilityToDxDataGridRenderCell(this, $row, $cell, options, view);
            },
            _renderCells: function ($row, options) {
                // Override headerId before rendering any rows, and link rows from fixed/scrollable tables for aria navigation
                var accessibilitySvc = this.option("ic.$icAccessibilitySvc");
                if (accessibilitySvc == null) {
                    this.callBase.apply(this, arguments);
                    return;
                }

                var view = "rows";
                accessibilitySvc.addAccessibilityToDxDataGridRenderCells(this, $row, options, view, getUniqueId);
            },
            _update: function (change) {
                var that = this,
                    tableElements = that.getTableElements();
                // Setting aria-selected on table rows which cannot be selected
                // is invalid; overriding to avoid this. May want to do so conditionally
                // if in future icGrid is augmented to provide selectable rows.

                // Update: Extending to omit aria-selected entirely if specified via theme property
                var $themeSvc = that.option('ic.$themeSvc');
                var omitRowSelectedAria = $themeSvc.getThemeProperty("omitRowSelectedAria");
                var skipAriaSelected = (omitRowSelectedAria && omitRowSelectedAria.Value1.toLowerCase() == "true");

                if (change.changeType !== "updateSelection" || this.component.option("selection")) {
                    if (change.changeType === "updateSelection") {
                        if (tableElements.length > 0) {
                            for (var i = 0; i < tableElements.length; i++) {
                                var tableElement = tableElements[i];
                                $.each((change.itemIndexes || []), function (_, index) {
                                    var $row,
                                        isSelected;

                                    // T108078
                                    if (change.items[index]) {
                                        $row = that._getRowElements($(tableElement)).eq(index);
                                        isSelected = change.items[index].isSelected;
                                        $row
                                            .toggleClass(ROW_SELECTION_CLASS, isSelected === undefined ? false : isSelected)
                                            .find("." + SELECT_CHECKBOX_CLASS).dxCheckBox("option", "value", isSelected);
                                        if (!skipAriaSelected) {
                                            that.setAria("selected", isSelected, $row);
                                        }
                                    }
                                });
                            }

                            that._updateCheckboxesClass();
                        }
                    } else {
                        that.callBase(change);
                    }

                }
            },
            _updateFocusedCellTabIndex: function () {
                // Accessibilty is actually hindered by adding tabindex=0 to non-interactive <td> elements.
                // When we want inline edit on the grid, we will need to add this back in conditionally.
            },
            renderSelectCheckBoxContainer: function ($container, options) {
                var selection = options.component.initialOption().selection.disableCheckboxes;
                if (!selection) {
                    if (options.rowType === "data" && !options.row.inserted) {
                        $container.addClass(EDITOR_CELL_CLASS);
                        this._attachCheckBoxClickEvent($container);

                        // Attach Aria Label to the select checkbox instead of the <td> parent
                        var selectCheckbox = this._renderSelectCheckBox($container, options);
                        var formattedMessage = DevExpress.localization.formatMessage("dxDataGrid-ariaSelectRow");

                        var ariaDescriptionFieldForCheckBox = options.component.option('ariaDescriptionFieldForCheckBox');
                        if (_.isObject(options.data) &&
                            !_.isEmpty(ariaDescriptionFieldForCheckBox)) {
                            var ariaFieldValue = options.data[ariaDescriptionFieldForCheckBox];
                            if (ariaFieldValue != null) {
                                formattedMessage = formattedMessage + " " + ariaFieldValue;
                            }
                        }

                        this.setAria("label", formattedMessage, selectCheckbox);
                        selectCheckbox.attr("tabindex", 0);
                    } else {
                        setEmptyText($container);
                    }
                    return;
                }

                if (options.rowType === "data" && !options.row.inserted) {
                    this._renderDisabledSelectCheckbox($container, options);
                } else {
                    setEmptyText($container);
                }
            },
            _renderDisabledSelectCheckbox: function ($container, options) {
                // Intent of this change is to add disabled option to checkbox editor config
                // (needed for BecomeUser)
                var SELECT_CHECKBOX_CLASS = 'dx-select-checkbox';
                var groupElement = $("<div>")
                    .addClass(SELECT_CHECKBOX_CLASS)
                    .appendTo($container);

                this.getController("editorFactory").createEditor(groupElement, _.extend({}, options.column, {
                    parentType: "dataRow",
                    dataType: "boolean",
                    editorOptions: {
                        disabled: true,
                    },
                    value: options.value,
                    tabIndex: -1,
                    row: options.row
                }));

                return groupElement;
            },
        });

        this.getView("gridView").constructor.redefine({
            _getTableRoleName: function () {
                // Overriding to set table's role to "presentation" instead of "grid"
                return "presentation";
            },
            render: function ($rootElement) {
                // Overriding to set $rootElement's role to "table" instead of "presentation"
                var GRIDBASE_CONTAINER_CLASS = "dx-gridbase-container",
                    BORDERS_CLASS = "borders";

                var that = this,
                    isFirstRender = !that._groupElement,
                    $groupElement = that._groupElement || $("<div>").addClass(that.getWidgetContainerClass());
                $groupElement.addClass(GRIDBASE_CONTAINER_CLASS);
                $groupElement.toggleClass(that.addWidgetPrefix(BORDERS_CLASS), !!that.option("showBorders"));
                that.component.setAria({
                    role: this._getTableRoleName(),
                    label: DevExpress.localization.message.format(that._getWidgetAriaLabel()),
                }, $groupElement);
                that._rootElement = $rootElement || that._rootElement;
                if (isFirstRender) {
                    that._groupElement = $groupElement;
                    typeof window !== "undefined" && that.getController("resizing").updateSize($rootElement);
                    $groupElement.appendTo($rootElement);
                }
                that._renderViews($groupElement);
            },
        });

        function getLastResizableColumnIndex(columns, resultWidths) {
            var hasResizableColumns = columns.some(function (column) {
                return column && !column.command && !column.fixed && column.allowResizing !== false;
            });
            for (var lastColumnIndex = columns.length - 1; columns[lastColumnIndex]; lastColumnIndex--) {
                var column = columns[lastColumnIndex],
                    width = resultWidths && resultWidths[lastColumnIndex],
                    allowResizing = !hasResizableColumns || column.allowResizing !== false;
                if (!column.command && !column.fixed && width !== "adaptiveHidden" && allowResizing) {
                    break;
                }
            }
            return lastColumnIndex;
        }

        this.getController("draggingHeader").constructor.redefine({
            _pointCreated: function (point, columns, location, sourceColumn) {
                // BEGIN Custom code
                if (this.rightColumnDragOverride == undefined) {
                    var themeSvc = this.option('ic.$themeSvc');
                    this.rightColumnDragOverride = themeSvc.isThemePropertyEnabled('ColumnReorderRightColumnDragOverride');
                    if (this.rightColumnDragOverride) {
                        var offsetVal = ((themeSvc.getThemeProperty('ColumnReorderRightColumnDragOverride') || {}).Value2 || "2") + "";
                        this.rightColumnDragOverrideOffset = parseInt(offsetVal) || 2;
                    }
                }
                // END Custom code

                var boundingRect, result = this.callBase.apply(this, arguments),
                    $transparentColumn = this._columnHeadersView.getTransparentColumnElement();
                if (!result && "headers" === location && $transparentColumn && $transparentColumn.length) {
                    boundingRect = $transparentColumn.get(0).getBoundingClientRect();
                    if (sourceColumn && sourceColumn.fixed) {
                        return "right" === sourceColumn.fixedPosition ? point.x < boundingRect.right : point.x > boundingRect.left;
                    } else {
                        // BEGIN Custom code
                        if (this.rightColumnDragOverride) {
                            // For certain config combinations, the bounding rectangles can slightly misalign.
                            return point.x < boundingRect.left || point.x > (boundingRect.right + this.rightColumnDragOverrideOffset);
                        }
                        // END Custom code
                        return point.x < boundingRect.left || point.x > boundingRect.right;
                    }
                }
                return result;
            },
        });

        const isPixelWidth = (width) => DevExpress.ic.typeUtils.isString(width) && width.slice(-2) === 'px';

        this.getController("resizing").constructor.redefine({
            calculateFreeWidth: function (that, widths) {
                var contentWidth = that._rowsView.contentWidth(),
                    totalWidth = that._getTotalWidth(widths, contentWidth);

                return contentWidth - totalWidth;
            },
            calculateFreeWidthWithCurrentMinWidth: function (that, columnIndex, currentMinWidth, widths) {
                return that.calculateFreeWidth(that, widths.map(function (width, index) {
                    return index === columnIndex ? currentMinWidth : width;
                }));
            },
            _setVisibleWidths: function (visibleColumns, widths) {
                const isGridReady = this.option("icFiredContentReady");
                const fixedColumns = this._rowsView.getFixedColumns();
                const columnsController = this._columnsController;
                columnsController.beginUpdate();
                visibleColumns.forEach(function (column, index) {
                    const columnId = columnsController.getColumnId(column);
                    // region InvestCloud - Only run this code if we have fixed columns
                    if (fixedColumns && fixedColumns.length > 0 && !isGridReady) {
                        if (!widths[index] && column.width != "auto") {
                            widths[index] = column.width;
                        }
                    }
                    // endregion InvestCloud
                    columnsController.columnOption(columnId, "visibleWidth", widths[index])
                });
                columnsController.endUpdate()
            },
            _getBestFitWidths: function () {
                var _this$_footerView;
                var rowsView = this._rowsView;
                var columnHeadersView = this._columnHeadersView;
                const fixedColumns = rowsView.getFixedColumns();
                if (!this.option("legacyRendering")) {
                    var _widths;
                    var widths = rowsView.getColumnWidths();
                    if (!(null !== (_widths = widths) && void 0 !== _widths && _widths.length)) {
                        var _rowsView$getTableEle;
                        var headersTableElement = columnHeadersView.getTableElement();
                        columnHeadersView.setTableElement(null === (_rowsView$getTableEle = rowsView.getTableElement()) || void 0 === _rowsView$getTableEle ? void 0 : _rowsView$getTableEle.children(".dx-header"));
                        widths = columnHeadersView.getColumnWidths();
                        columnHeadersView.setTableElement(headersTableElement)
                    }
                    // region InvestCloud
                    else if (!this.option("icFiredContentReady") && fixedColumns && fixedColumns.length > 0) {
                        const visibleColumns = this._columnsController.getVisibleColumns();
                        visibleColumns.forEach(function (column, index) {
                            if (!widths[index] && column.width != "auto") {
                                widths[index] = column.width;
                            }
                        });
                        const isColumnWidthsCorrected = this._correctColumnWidths(widths, visibleColumns, true);
                        if (isColumnWidthsCorrected) this._setVisibleWidths(visibleColumns, widths);
                    }
                    // endregion InvestCloud
                    return widths
                }
                var rowsColumnWidths = rowsView.getColumnWidths();
                var headerColumnWidths = null === columnHeadersView || void 0 === columnHeadersView ? void 0 : columnHeadersView.getColumnWidths();
                var footerColumnWidths = null === (_this$_footerView = this._footerView) || void 0 === _this$_footerView ? void 0 : _this$_footerView.getColumnWidths();
                var resultWidths = mergeArraysByMaxValue(rowsColumnWidths, headerColumnWidths);
                resultWidths = mergeArraysByMaxValue(resultWidths, footerColumnWidths);
                return resultWidths
            },
            _correctColumnWidths: function (resultWidths, visibleColumns, force) {
                var columnAutoWidth = this.option("columnAutoWidth");

                // This check added for optional InvestCloud optimization
                if (!force && !this.option("icFiredContentReady") && this.option("ic.$themeSvc").isThemePropertyEnabled("OptimizeColumnWidthCorrection")) {
                    if (columnAutoWidth) {
                        this._updateScrollableForIE();
                    }
                    return false;
                }

                var adaptiveController = this._adaptiveColumnsController;
                var oldHiddenColumns = adaptiveController.getHiddenColumns();
                var hidingColumnsQueue = adaptiveController.updateHidingQueue(this._columnsController.getColumns());
                var hiddenColumns;


                adaptiveController.hideRedundantColumns(resultWidths, visibleColumns, hidingColumnsQueue);
                hiddenColumns = adaptiveController.getHiddenColumns();
                if (adaptiveController.hasAdaptiveDetailRowExpanded()) {
                    if (oldHiddenColumns.length !== hiddenColumns.length) {
                        adaptiveController.updateForm(hiddenColumns);
                    }
                }

                !hiddenColumns.length && adaptiveController.collapseAdaptiveDetailRow();

                if (columnAutoWidth && hidingColumnsQueue.length && !hiddenColumns.length) {
                    this._updateScrollableForIE();
                }
                var that = this,
                    i,
                    hasPercentWidth = false,
                    hasAutoWidth = false,
                    isColumnWidthsCorrected = false,
                    $element = that.component.$element(),
                    hasWidth = that._hasWidth,
                    averageColumnsWidth,
                    lastColumnIndex;
                var icFixedColumnCount = 0;

                for (i = 0; i < visibleColumns.length; i++) {
                    var index = i,
                        column = visibleColumns[index],
                        isHiddenColumn = resultWidths[index] === HIDDEN_COLUMNS_WIDTH,
                        width = resultWidths[index],
                        minWidth = column.minWidth;

                    if (column.fixed) {
                        icFixedColumnCount++;
                    }

                    if (minWidth) {
                        if (width === undefined && column.width === undefined) {
                            averageColumnsWidth = that._getAverageColumnsWidth(resultWidths);
                            width = averageColumnsWidth;
                        } else if (this.getController('adaptiveColumns')._isPercentWidth(width)) {
                            var freeWidth = that.calculateFreeWidthWithCurrentMinWidth(that, index, minWidth, resultWidths);

                            if (freeWidth < 0) {
                                width = -1;
                            }
                        }
                    }

                    const realColumnWidth = that._getRealColumnWidth(index, resultWidths.map(function (columnWidth, columnIndex) {
                        return index === columnIndex ? width : columnWidth;
                    }));

                    if (minWidth && !isHiddenColumn && realColumnWidth < minWidth) {
                        resultWidths[index] = minWidth;
                        isColumnWidthsCorrected = true;
                        continue;
                    }
                    if (!DevExpress.ic.typeUtils.isDefined(column.width)) {
                        resultWidths[index] = width;
                        isColumnWidthsCorrected = true;
                        hasAutoWidth = true;
                    }
                    if (this.getController('adaptiveColumns')._isPercentWidth(column.width)) {
                        hasPercentWidth = true;
                    }
                }

                if ($element && that._maxWidth) {
                    delete that._maxWidth;
                    // BEGIN Custom code
                    if (this.option("icConstrainGridNegativeSpace")) {
                        $element.find(".dx-datagrid-headers, .dx-datagrid-rowsview, .dx-datagrid-total-footer").css("maxWidth", "");
                    } else {
                        $element.css("maxWidth", "");
                    }
                    // END Custom code
                }

                if (!hasAutoWidth && resultWidths.length) {
                    var contentWidth = that._rowsView.contentWidth(),
                        scrollbarWidth = that._rowsView.getScrollbarWidth(),
                        totalWidth = that._getTotalWidth(resultWidths, contentWidth);

                    if (totalWidth < contentWidth) {
                        lastColumnIndex = getLastResizableColumnIndex(visibleColumns, resultWidths);

                        if (lastColumnIndex >= 0) {
                            // BEGIN Custom code
                            var themePropThreshold = that.option('ic.$themeSvc').getThemeProperty("NegativeSpaceDistribution");
                            var screenPropThreshold = that.option("icNegativeSpaceDistribution");

                            if (!_.isUndefined(themePropThreshold) || screenPropThreshold) {

                                if (!themePropThreshold) {
                                    themePropThreshold = {};
                                }

                                var icGetSettingsFromConfig = function (configs) {
                                    var settings = {};
                                    if (!configs) {
                                        return settings;
                                    }

                                    var config = configs.split('|');

                                    var isTrue = function (input) {
                                        return ['true', 'yes'].indexOf(input.trim().toLowerCase()) !== -1;
                                    };

                                    config.forEach(function (setting) {
                                        var pair = setting.split(':');
                                        if (pair.length > 1) {
                                            switch (pair[0]) {
                                                case 'excludeFixedColumns':
                                                    settings.excludeFixedColumns = isTrue(pair[1])
                                                    break;
                                                case 'columnCountThreshold':
                                                    settings.columnCountThreshold = parseInt(pair[1], 10);
                                                    break;
                                                case 'proportionate':
                                                    settings.proportionate = isTrue(pair[1]);
                                                    break;
                                                case 'skip':
                                                    // from screen prop
                                                    settings.skip = isTrue(pair[1]);
                                            }
                                        }
                                    });

                                    return settings;
                                }

                                var icNegativeSpaceDistribution = _.assign(
                                    {
                                        excludeFixedColumns: true,
                                        columnCountThreshold: icFixedColumnCount,
                                        proportionate: true,
                                        skip: false,
                                    },
                                    icGetSettingsFromConfig(themePropThreshold.Value1),
                                    icGetSettingsFromConfig(screenPropThreshold)
                                );

                                var reallocate = !icNegativeSpaceDistribution.skip && visibleColumns.length >= icNegativeSpaceDistribution.columnCountThreshold;

                                if (reallocate) {
                                    // Rather than reveal empty space, allow each non-fixed column to fill available space
                                    var negativeSpaceInPixels = contentWidth - totalWidth;
                                    var columnsToWiden = icNegativeSpaceDistribution.excludeFixedColumns ? visibleColumns.length - icFixedColumnCount : visibleColumns.length;
                                    if (columnsToWiden) {
                                        var negativeSpacePerColumn = negativeSpaceInPixels / columnsToWiden;

                                        var lastColumn = visibleColumns.length - 1;
                                        var cumulativeFloatWidth = 0.01; // Avoid math.floor being one pixel down based on floating point rounding
                                        var cumulativeIntWidth = 0;
                                        visibleColumns.forEach(function (column, index) {
                                            if (resultWidths[index] === undefined) {
                                                // Do nothing
                                            } else if (icNegativeSpaceDistribution.excludeFixedColumns && column.fixed) {
                                                // do not expand
                                                var pixelWidth = parseFloat(resultWidths[index].toString().replace('px', ''));
                                                cumulativeFloatWidth += pixelWidth;
                                                cumulativeIntWidth += pixelWidth;
                                            } else if (!icNegativeSpaceDistribution.proportionate) {
                                                resultWidths[index] = "auto";
                                            } else if (index !== lastColumn) {
                                                var pixelWidth = parseFloat(resultWidths[index].toString().replace('px', ''));
                                                cumulativeFloatWidth += pixelWidth + negativeSpacePerColumn;
                                                pixelWidth = Math.floor(cumulativeFloatWidth) - cumulativeIntWidth;
                                                cumulativeIntWidth += pixelWidth;
                                                resultWidths[index] = pixelWidth + "px";
                                            } else {
                                                resultWidths[index] = "auto";
                                            }
                                        });

                                        // Don't do any of the other things... we have resolved the negative space.
                                        return true;
                                    }
                                }
                            }

                            if (!that.option("icRecalcRightmostColumnWidth"))
                                resultWidths[lastColumnIndex] = "auto";
                            // END Custom code
                            isColumnWidthsCorrected = true;
                            if (!hasWidth && !hasPercentWidth) {
                                that._maxWidth = totalWidth + scrollbarWidth + (that.option("showBorders") ? 2 : 0);

                                // BEGIN Custom code
                                if (this.option("icConstrainGridNegativeSpace")) {
                                    $element.find(".dx-datagrid-headers, .dx-datagrid-rowsview, .dx-datagrid-total-footer").css("maxWidth", that._maxWidth);
                                } else {
                                    $element.css("maxWidth", that._maxWidth);
                                }
                                // END Custom code
                            }
                        }
                    }
                }
                return isColumnWidthsCorrected;
            },

            _synchronizeColumns: function () {
                const that = this;
                const columnsController = that._columnsController;
                const visibleColumns = columnsController.getVisibleColumns();
                const columnAutoWidth = that.option('columnAutoWidth');
                const legacyRendering = that.option('legacyRendering');
                let needBestFit = that._needBestFit();
                let hasMinWidth = false;
                let resetBestFitMode;
                let isColumnWidthsCorrected = false;
                let resultWidths = [];
                let focusedElement;
                let selectionRange;
                const _iterator2 = _interopRequireDefault(DevExpress.utils.iterator);
                const normalizeWidthsByExpandColumns = function () {
                    let expandColumnWidth;

                    _iterator2.default.each(visibleColumns, function (index, column) {
                        if (column.type === 'groupExpand') {
                            expandColumnWidth = resultWidths[index];
                        }
                    });

                    _iterator2.default.each(visibleColumns, function (index, column) {
                        if (column.type === 'groupExpand' && expandColumnWidth) {
                            resultWidths[index] = expandColumnWidth;
                        }
                    });
                };

                !needBestFit && _iterator2.default.each(visibleColumns, function (index, column) {
                    if (column.width === 'auto' || (legacyRendering && column.fixed)) {
                        needBestFit = true;
                        return false;
                    }
                });

                _iterator2.default.each(visibleColumns, function (index, column) {
                    if (column.minWidth) {
                        hasMinWidth = true;
                        return false;
                    }
                });

                that._setVisibleWidths(visibleColumns, []);

                if (needBestFit) {
                    focusedElement = DevExpress.ic.core.dom_adapter.getActiveElement();
                    selectionRange = DevExpress.ic.gridCore.getSelectionRange(focusedElement);
                    that._toggleBestFitMode(true);
                    resetBestFitMode = true;
                }

                const $element = this.component.$element();
                if ($element && $element[0] && this._maxWidth) {
                    delete this._maxWidth;
                    $element[0].style.maxWidth = '';
                }

                DevExpress.ic.core.common.deferUpdate(() => {
                    if (needBestFit) {
                        resultWidths = that._getBestFitWidths();

                        _iterator2.default.each(visibleColumns, function (index, column) {
                            const columnId = columnsController.getColumnId(column);
                            columnsController.columnOption(columnId, 'bestFitWidth', resultWidths[index], true);
                        });
                    } else if (hasMinWidth) {
                        resultWidths = that._getBestFitWidths();
                    }

                    _iterator2.default.each(visibleColumns, function (index) {
                        const width = this.width;
                        if (width !== 'auto') {
                            if (DevExpress.ic.typeUtils.isDefined(width)) {
                                resultWidths[index] = DevExpress.ic.typeUtils.isNumeric(width) || isPixelWidth(width) ? parseFloat(width) : width;
                            } else if (!columnAutoWidth) {
                                resultWidths[index] = undefined;
                            }
                        }
                    });

                    if (resetBestFitMode) {
                        that._toggleBestFitMode(false);
                        resetBestFitMode = false;
                        if (focusedElement && focusedElement !== DevExpress.ic.core.dom_adapter.getActiveElement()) {
                            const isFocusOutsideWindow = _position.getBoundingRect(focusedElement).bottom < 0;
                            if (!isFocusOutsideWindow) {
                                //region InvestCloud
                                // if(browser.msie) {
                                //     setTimeout(function() { restoreFocus(focusedElement, selectionRange); });
                                // } else {
                                restoreFocus(focusedElement, selectionRange);
                                // }
                                //endRegion InvestCloud
                            }
                        }
                    }

                    //region InvestCloud (force = true)
                    isColumnWidthsCorrected = that._correctColumnWidths(resultWidths, visibleColumns, true);
                    //endRegion InvestCloud

                    if (columnAutoWidth) {
                        normalizeWidthsByExpandColumns();
                        if (that._needStretch()) {
                            that._processStretch(resultWidths, visibleColumns);
                        }
                    }

                    DevExpress.ic.core.common.deferRender(function () {
                        if (needBestFit || isColumnWidthsCorrected) {
                            that._setVisibleWidths(visibleColumns, resultWidths);
                        }
                    });
                });
            },
        });

        function getCorrectedCellPosition(cellPosition) {

            // Handle user focusing on cell [0, 0] by tab key navigation.
            // Component doesn't update this._focusedCellPosition until after
            // a keyboard action is taken *in this cell*, but the initial value
            // of [-1, -1] makes the resulting math return unusable results, which
            // blocks fulfilling the keyboard action.
            var rowIndex = cellPosition.rowIndex === -1 ? 0 : cellPosition.rowIndex;

            return {
                rowIndex: rowIndex,
                columnIndex: cellPosition.columnIndex,
            };
        }

        function isNotFocusedRow($row) {
            return !$row || $row.hasClass(FREESPACE_ROW_CLASS) || $row.hasClass(VIRTUAL_ROW_CLASS)
        }

        function isGroupRow($row) {
            return $row && $row.hasClass(GROUP_ROW_CLASS)
        }

        function isCellElement($element) {
            return $element.length && "TD" === $element[0].tagName
        }

        this.getController("editing").constructor.redefine({
            updateFieldValue: function (e) {
                var that = this,
                    editMode = that.getEditMode();

                that.callBase.apply(that, arguments);

                if (editMode === EDIT_MODE_ROW || (editMode === EDIT_MODE_BATCH && e.column.showEditorAlways)) {
                    var validatingController = that.getController("validating");
                    validatingController && validatingController.validate();
                }

                // Override to ensure revert button is showing on when validation message appears
                if (editMode === EDIT_MODE_CELL && that._editData.length > 0 && that._editData[0].type === "update") {
                    setTimeout(function () {
                        if ($('.' + ERROR_MESSAGE_CLASS).length > 0 && $('.' + REVERT_BUTTON_CLASS).length < 1) {
                            that.getController("editorFactory")._showRevertButton(e.cellElement, e.cellElement.find("." + CELL_HIGHLIGHT_OUTLINE).first());
                        }
                    }, 750);
                }
            },
        });

        this.getController("keyboardNavigation").constructor.redefine({

            _applyTabIndexToElement: function () {
                // Accessibilty is actually hindered by adding tabindex=0 to non-interactive <td> elements.
                // When we want inline edit on the grid, we will need to add this back in conditionally.
            },
            _getCell: function (cellPosition) {
                if (this._focusedView && cellPosition) {
                    return this._focusedView.getCell({
                        rowIndex: getCorrectedCellPosition(cellPosition).rowIndex - this._dataController.getRowIndexOffset(),
                        columnIndex: cellPosition.columnIndex,
                    });
                }
            },
            _tabKeyHandler: function (eventArgs, isEditing) {
                if (!this.option("icHandleTabKey")) {
                    return;
                }

                var ROWS_VIEW_CLASS = "rowsview";
                var editingOptions = this.option("editing"),
                    direction = eventArgs.shift ? "previous" : "next",
                    isLastValidCell = this._isLastValidCell(this._focusedCellPosition),
                    isFirstValidCell = this._isFirstValidCell(this._focusedCellPosition),
                    isOriginalHandlerRequired = !eventArgs.shift && isLastValidCell || (eventArgs.shift && isFirstValidCell),
                    eventTarget = eventArgs.originalEvent.target;

                if (this._handleTabKeyOnMasterDetailCell(eventTarget, direction)) {
                    return;
                }

                if (editingOptions && eventTarget && !isOriginalHandlerRequired) {
                    if ($(eventTarget).hasClass(this.addWidgetPrefix(ROWS_VIEW_CLASS))) {
                        this._resetFocusedCell();
                    }
                    if (isEditing) {
                        if (!this._editingCellTabHandler(eventArgs, direction)) {
                            return;
                        }
                    } else {
                        if (this._targetCellTabHandler(eventArgs, direction)) {
                            isOriginalHandlerRequired = true;
                        }
                    }
                }

                if (isOriginalHandlerRequired) {
                    this.getController("editorFactory").loseFocus();
                    if (this._editingController.isEditing() && !this._isRowEditMode()) {
                        this._resetFocusedCell();
                        this._editingController.closeEditCell();
                    }
                } else {
                    eventArgs.originalEvent.preventDefault();
                }
            },
            _focus: function ($cell, disableFocus, isInteractiveElement) {
                var _events_engine2 = _interopRequireDefault(DevExpress.events);
                var $row = $cell && $cell.is("td") ? $cell.parent() : $cell;
                if ($row && isNotFocusedRow($row)) {
                    return
                }
                var $focusElement, $prevFocusedCell = this._getFocusedCell(),
                    focusedView = this._focusedView,
                    $focusViewElement = focusedView && focusedView.element();
                this._isHiddenFocus = disableFocus;
                if (isGroupRow($row) || this.isRowFocusType()) {
                    $focusElement = $row;
                    if (focusedView) {
                        this.setFocusedRowIndex(this._getRowIndex($row))
                    }
                } else {
                    if (isCellElement($cell)) {
                        $focusElement = $cell;
                        this._updateFocusedCellPosition($cell)
                    }
                }
                if ($focusElement) {
                    if (!isInteractiveElement) {
                        this._applyTabIndexToElement($focusElement);
                        _events_engine2.default.trigger($focusElement, "focus")
                    }
                    if (disableFocus) {
                        $focusViewElement && $focusViewElement.find("." + CELL_FOCUS_DISABLED_CLASS + "[tabIndex]").not($focusElement).removeClass(CELL_FOCUS_DISABLED_CLASS).removeAttr("tabIndex");
                        $focusElement.addClass(CELL_FOCUS_DISABLED_CLASS)
                    } else {
                        $focusViewElement && $focusViewElement.find("." + CELL_FOCUS_DISABLED_CLASS + ":not(." + MASTER_DETAIL_CELL_CLASS + ")").removeClass(CELL_FOCUS_DISABLED_CLASS);
                        this.getController("editorFactory").focus($focusElement)
                    }
                }
            },
            _enterKeyHandler: function (eventArgs, isEditing) {
                var $cell = this._getFocusedCell(),
                    rowIndex = this.getVisibleRowIndex(),
                    $row = this._focusedView && this._focusedView.getRow(rowIndex);
                if (this.option("grouping.allowCollapsing") && isGroupRow($row) || this.option("masterDetail.enabled") && $cell && $cell.hasClass(COMMAND_EXPAND_CLASS)) {
                    if ($(eventArgs.originalEvent.target).hasClass("dx-command-expand")) {
                        var key = this._dataController.getKeyByRowIndex(rowIndex),
                            item = this._dataController.items()[rowIndex];
                        if (void 0 !== key && item && item.data && !item.data.isContinuation) {
                            this._dataController.changeRowExpand(key)
                        }
                    }
                } else {
                    if (isEditing) {
                        $cell = this._getCellElementFromTarget(eventArgs.originalEvent.target);
                        this._updateFocusedCellPosition($cell);
                        if (this._isRowEditMode()) {
                            this._focusEditFormCell($cell);
                            setTimeout(this._editingController.saveEditData.bind(this._editingController))
                        } else {
                            var $target = (0, _renderer2.default)(eventArgs.originalEvent.target);
                            _events_engine2.default.trigger($target, "blur");
                            this._editingController.closeEditCell();
                            eventArgs.originalEvent.preventDefault()
                        }
                    } else {
                        var column = this._columnsController.getVisibleColumns()[this._focusedCellPosition.columnIndex],
                            row = this._dataController.items()[rowIndex];
                        if (this._editingController.allowUpdating({
                            row: row
                        }) && column && column.allowEditing) {
                            if (this._isRowEditMode()) {
                                this._editingController.editRow(rowIndex)
                            } else {
                                this._focusedCellPosition && this._editingController.editCell(rowIndex, this._focusedCellPosition.columnIndex)
                            }
                        }
                    }
                }
            },
            getVisibleRowIndex: function () {
                if (this._focusedCellPosition) {
                    if (!this._focusedCellPosition.rowIndex) {
                        return this._focusedCellPosition.rowIndex;
                    }
                    return getCorrectedCellPosition(this._focusedCellPosition).rowIndex - this._dataController.getRowIndexOffset();
                }
                return null;
            },
        });

        this.getController("columnsResizer").constructor.redefine({
            _endResizing: function (args) {
                var e = args.event,
                    that = e.data;
                if (that.isResizing()) {
                    if (!args.model) args.model = {};
                    args.model.resizeTime = new Date();
                    var stateStoringController = this.getController("stateStoring");
                    var options = stateStoringController.option("stateStoring");
                    if ("custom" === options.type && options.enabled && this.option('icAutoSaveOnColumnWidthChange')) {
                        // Expedite persisting of personalization when resize completes
                        var state = stateStoringController.state();
                        options.customSave && options.customSave(state);
                    }
                }
                that.callBase.apply(that, arguments);
            }
        });

        this.getView("contextMenuView").constructor.redefine({
            _renderCore: function () {
                var that = this,
                    $element = that.element().addClass(CONTEXT_MENU);

                this.setAria("role", "presentation", $element);

                this._createComponent($element,
                    DevExpress.ui.dxContextMenu, {
                    onShowing: function (e) {
                        var returnFocusElement = $(e.jQEvent.target).closest("[tabindex='0']");
                        e.component.option("previousFocus", returnFocusElement);
                    },
                    onPositioning: function (actionArgs) {
                        var event = actionArgs.event,
                            contextMenuInstance = actionArgs.component,
                            items = that.getController("contextMenu").getContextMenuItems(event);

                        if (items) {
                            contextMenuInstance.option("items", items);
                            event.stopPropagation();
                        } else {
                            actionArgs.cancel = true;
                        }
                    },
                    onItemClick: function (params) {
                        params.itemData.onItemClick && params.itemData.onItemClick(params);
                    },
                    onHidden: function (e) {
                        var focusTarget = e.component.option("previousFocus");
                        if (focusTarget.length > 0)
                            focusTarget.first().focus();

                        var overlayContent = e.component._overlay.content();
                        if (overlayContent && overlayContent.length > 0) {
                            overlayContent.attr('aria-expanded', false);
                        }
                    },
                    onShown: function (e) {
                        var overlayContent = e.component._overlay.content();
                        if (overlayContent && overlayContent.length > 0) {
                            overlayContent.attr('aria-expanded', true);

                            if (overlayContent[0].hasAttribute('aria-activedescendant')) {
                                overlayContent.removeAttr('aria-activedescendant');
                            }

                            var ulElements = overlayContent.find('ul.dx-menu-items-container');
                            ulElements.attr('role', 'menubar');

                            var listElements = overlayContent.find('li.dx-menu-item-wrapper');
                            listElements.attr('role', 'none');
                        }
                    },
                    cssClass: that.getWidgetContainerClass(),
                    target: that.component.$element()
                });
            }
        });

        this.getView("draggingHeaderView").constructor.redefine({
            dropHeader: function dropHeader(args) {
                var e = args.event;
                var that = e.data.that;
                var controller = that._controller;
                that.element().hide();
            
                if (controller && that._isDragging) {
                  controller.drop(that._dropOptions);
                }
            
                that.element().appendTo(that._parentElement());
                that._dragOptions = null;
                that._dropOptions = null;
                that._isDragging = false;
                _dom_adapter.default.getDocument().onselectstart = that._onSelectStart || null;

                // IC custom code
                setTimeout(() => {
                    const gridScope = that.option('gridScope');
                    if (_.isFunction(gridScope?.generateGroupingActionButtons))
                        gridScope.generateGroupingActionButtons();

                    const $utilLstSvc = that.option('ic.$utilLstSvc');
                    $utilLstSvc.updateColumnChooserSequence(gridScope.gridInstance);
                    $utilLstSvc.recalculateHidingPriorities(gridScope.gridInstance);
                }, 0)
                // End IC custom code
              }
        });
    },

    getAllItems: function () {
        var dataCtrl = this.getController("data"),
            request = {},
            isReload = false,
            operationTypes = [],
            items = dataCtrl.getDataSource().items();
        request.storeLoadOptions = {};
        request.remoteOperations = this.option("remoteOperations");
        if (request.remoteOperations === true || (request.remoteOperations || {}).paging === true) {
            return items;
        }
        dataCtrl._dataSource._customizeRemoteOperations(request, isReload, operationTypes);
        return request.cachedStoreData || items;
    },

    getPager: function () {
        return this.element().find(".dx-pager").dxPager("instance");
    },

    updatePager: function (itemCount) {
        var dataSource = this.getDataSource();
        var pager = this.getPager();
        if (!_.isUndefined(pager) && pager.option("totalCount") !== itemCount) {
            dataSource._totalCount = Math.max(dataSource._totalCount, itemCount);

            pager.option("totalCount", dataSource._totalCount);

            var newPageCount = Math.ceil(pager.option("totalCount") / pager.option("pageSize"));
            pager.option("pageCount", Math.max(1, newPageCount));
        }
    },

    setCustomSummaryTotalText: function () {

        var that = this,
            summaryTotalItems = that.option("summary.totalItems");

        if (!Array.isArray(summaryTotalItems)) return;

        var _updateCustomTotalColumnPlace = function (totalItems, firstVisibleColumn) {
            var customTotalColumnName = "customSummaryForTotalText",
                updated = false,
                hasFirstColumnTotalText = false,
                totalColumnIndex;
            if (Array.isArray(totalItems)) {
                for (var i = 0; i < totalItems.length; i++) {
                    if (totalItems[i].column === firstVisibleColumn) {
                        hasFirstColumnTotalText = true;
                    }
                    if (totalItems[i].name === customTotalColumnName) {
                        totalColumnIndex = i;
                    }
                }
                customTotalColumnName = hasFirstColumnTotalText ? customTotalColumnName : firstVisibleColumn;
                updated = totalItems[totalColumnIndex].showInColumn !== customTotalColumnName;
                totalItems[totalColumnIndex].showInColumn = customTotalColumnName;
            }
            return updated;
        };
        var _getFirstVisibleColumnName = function (columns) {
            var firstColumn = null;
            for (var i = 0; i < columns.length; i++) {
                firstColumn = columns[i];
                if (_.isUndefined(firstColumn.groupIndex) && !_.isUndefined(firstColumn.dataField)) {
                    firstColumn = firstColumn.dataField;
                    break;
                }
            }
            return firstColumn;
        };
        var visibleColumns = that.getVisibleColumns();
        var firstVisibleColumnName = _getFirstVisibleColumnName(visibleColumns);
        var updated = _updateCustomTotalColumnPlace(summaryTotalItems, firstVisibleColumnName);
        if (updated) {
            var footerView = that.getView('footerView');
            var dataController = footerView.getController('data');
            dataController._updateItemsCore({
                changeType: 'refresh'
            });
            footerView.render();
        }

    }
});
DevExpress.registerComponent("dxDataGrid", _dxDataGrid);

var gridCoreColumnHeaders = DevExpress.ic.gridCore.modules.find(function (module) { return module.name === 'columnHeaders'; });
gridCoreColumnHeaders.views.columnHeadersView.redefine({
    _renderCore: function () {
        var HEADERS_CLASS = 'headers';
        var NOWRAP_CLASS = 'nowrap';
        var IC_POSITION_STICKY_CLASS = 'ic-position-sticky';
        var that = this;
        var $container = that.element();

        if (that._tableElement && !that._dataController.isLoaded() && !that._hasRowElements) {
            return;
        }

        //IC override for sticky column headers / header-buttons
        if (that.component.option('icFreezeHeadersOnScroll')) {
            var $pageHeader = $('header:visible').eq(0);
            headerInnerHeight = $pageHeader.innerHeight() || 0;

            // Note: eventually, stickiness in the *page* header might also be discovered via position = 'sticky'
            // but that is out of scope for sticky grid column headers.
            var pageHeaderIsSticky = $pageHeader.length ? $pageHeader.css('position').indexOf('fixed') > -1 : false;

            var pageHeaderZIndex = $pageHeader.css('z-index') ;
            var newZIndex = pageHeaderZIndex > 10 ? (pageHeaderZIndex - 10) : 1;
            
            var $headerButtons = $container.closest("[applet]").find(".ic-headerbuttons").eq(0);
            var headerButtonsHeight = $headerButtons.innerHeight() || 0;
            $headerButtons
                .addClass(IC_POSITION_STICKY_CLASS)
                .css({
                    position: 'sticky',
                    top: 0, //TODO: Should this instead be headerInnerHeight? (Build a project using this feature and test manually.)
                    'z-index': pageHeaderIsSticky ? newZIndex : $container.css('z-index'),
                });

            $container
                .addClass(that.addWidgetPrefix(HEADERS_CLASS) + ' ' + IC_POSITION_STICKY_CLASS)
                .toggleClass(that.addWidgetPrefix(NOWRAP_CLASS), !that.option('wordWrapEnabled'))
                .css({
                    position: 'sticky',
                    top: headerInnerHeight + headerButtonsHeight,
                    'z-index': pageHeaderIsSticky ? newZIndex : 101,
                })
                .empty();
            // End of IC override
        } else {
            $container
                .addClass(that.addWidgetPrefix(HEADERS_CLASS))
                .toggleClass(that.addWidgetPrefix(NOWRAP_CLASS), !that.option('wordWrapEnabled'))
                .empty();
        }

        that.setAria('role', 'presentation', $container);

        that._updateContent(that._renderTable());

        if (that.getRowCount() > 1) {
            $container.addClass(MULTI_ROW_HEADER_CLASS);
        }

        that.callBase.apply(that, arguments);
    },
});

function overrideAdaptiveRowUpdateItemsCore(change) {
    var items, oldItems, that = this,
        dataSource = that._dataSource,
        changeType = change.changeType || "refresh";
    change.changeType = changeType;
    if (dataSource) {
        items = change.items || dataSource.items();
        items = that._beforeProcessItems(items);
        items = that._processItems(items, changeType);
        change.items = items;
        oldItems = that._items.length === items.length && that._items;

        // Investcloud change batch update
        if (!_.isUndefined(that._icAllHiddenColumnsExpanded)) {
            var value = that._icAllHiddenColumnsExpanded ? "insert" : "remove";

            if ((that._icAllHiddenColumnsExpanded && that._isAlreadyExpanded) ||
                (!that._icAllHiddenColumnsExpanded && !that._isAlreadyExpanded)) {
                value = "update";
            }
            if (that._icExpandCollapseAdaptiveRows) {
                that._icExpandCollapseAdaptiveRows.changeTypes.push(value);
                that._icExpandCollapseAdaptiveRows.items.push(that._icAdaptiveRowsItems);
                that._icExpandCollapseAdaptiveRows.rowIndices.push(change.rowIndices[1]);
            } else {
                that._icExpandCollapseAdaptiveRows = {
                    changeType: changeType,
                    changeTypes: [value],
                    items: [that._icAdaptiveRowsItems],
                    rowIndices: [change.rowIndices[1]]
                }
            }
            // End of Investcloud change
        } else {
            that._applyChange(change);

            that._items.forEach(function (item, index) {
                item.rowIndex = index;
                if (oldItems) {
                    item.cells = oldItems[index].cells
                }
            })
        }
    } else {
        that._items = []
    }
}
DevExpress.ic.gridCoreAdaptivity.extenders.controllers.data._updateItemsCore = overrideAdaptiveRowUpdateItemsCore;

function overrideCreateRow(row) {
    var that = this;
    var addNamespace = DevExpress.events.utils.addNamespace;
    var clickEvent = DevExpress.events.click;
    var eventsEngine = DevExpress.events;
    var $row = that.callBase(row);

    var COLUMN_HEADERS_VIEW_NAMESPACE = "dxDataGridColumnHeadersView";

    if (row.rowType === "header") {
        eventsEngine.on($row, addNamespace(clickEvent.name, COLUMN_HEADERS_VIEW_NAMESPACE), "td", that.createAction(function (e) {
            if ($(e.event.currentTarget).parent().get(0) !== $row.get(0)) {
                return;
            }
            var keyName = null;
            var event = e.event;
            var $cellElementFromEvent = $(event.currentTarget);
            var rowIndex = $cellElementFromEvent.parent().index();
            var columnIndex = -1;
            [].slice.call(that.getCellElements(rowIndex)).some(($cellElement, index) => {
                if ($cellElement === $cellElementFromEvent.get(0)) {
                    columnIndex = index;
                    return true;
                }
            });
            var visibleColumns = that._columnsController.getVisibleColumns(rowIndex);
            var column = visibleColumns[columnIndex];
            var editingController = that.getController("editing");
            var editingMode = that.option("editing.mode");
            var isCellEditing = editingController && editingController.isEditing() && (editingMode === "batch" || editingMode === "cell");

            if (isCellEditing || !that._isSortableElement($(event.target))) {
                // custom prop check added to support keyboard accessibility while allowing column sorting despite editing
                if (!that.option('icAllowSortWhileEditing')) {
                    return;
                }
            }

            var timeSinceResize = e.model?.resizeTime && (new Date() - e.model.resizeTime);
            if (e.model?.resizeTime && timeSinceResize < 100) {
                // mouse up event for resize is (correctly) followed by click event which (erroneously) triggers sort
                return;
            }

            if (column && _.isUndefined(column.groupIndex) && !column.command) {
                if (event.shiftKey) {
                    keyName = "shift";
                } else if (event.ctrlKey) {
                    keyName = "ctrl";
                }
                setTimeout(() => {
                    that._columnsController.changeSortOrder(column, keyName);
                    // ic change
                    var stateStoringController = that.getController("stateStoring");
                    var options = stateStoringController.option("stateStoring");
                    if ("custom" === options.type && options.enabled && that.option('icAutoSaveOnSort')) {
                        // Expedite persisting of personalization when sort completes
                        var state = stateStoringController.state();
                        options.customSave && options.customSave(state);
                    }
                    // end ic
                });
            }
        }));
    }

    return $row;
}
DevExpress.ic.gridCoreSorting.extenders.views.columnHeadersView._createRow = overrideCreateRow;

function overrideStateStoringProcessLoadState(that) {
    var columnsController = that.getController("columns"),
        selectionController = that.getController("selection"),
        exportController = that.getController("export"),
        dataController = that.getController("data"),
        pagerView = that.getView("pagerView");
    if (columnsController) {
        columnsController.columnsChanged.add(function () {
            var columnsState = columnsController.getUserState(),
                columnsStateHash = DevExpress.utils.common.getKeyHash(columnsState),
                currentColumnsStateHash = DevExpress.utils.common.getKeyHash(that._state.columns);
            if (!DevExpress.utils.common.equalByValue(currentColumnsStateHash, columnsStateHash)) {
                DevExpress.ic.core.utils.extend.extend(that._state, {
                    columns: columnsState
                });
                that.isEnabled() && that.save()
            }
        })
    }
    if (selectionController) {
        selectionController.selectionChanged.add(function (e) {
            DevExpress.ic.core.utils.extend.extend(that._state, {
                selectedRowKeys: e.selectedRowKeys,
                selectionFilter: e.selectionFilter
            });
            that.isEnabled() && that.save()
        })
    }

    // region InvestCloud
    if (dataController) {
        dataController.changed.add(function (e) {
            var sortByGroupSummaryInfo = that.option('sortByGroupSummaryInfo');
            if (sortByGroupSummaryInfo) {
                DevExpress.ic.core.utils.extend.extend(that._state, {
                    sortByGroupSummaryInfo: sortByGroupSummaryInfo
                });
            }
            var sorting = that.option('sorting');
            if (sorting.icCustomSort) {
                DevExpress.ic.core.utils.extend.extend(that._state, {
                    icCustomSort: sorting.icCustomSort
                });
            }
        });
    }

    var $themeSvc = that.option('ic.$themeSvc');
    var skipThemeProp = $themeSvc.getThemeProperty("SkipSavingStateOnRowChange");
    var skipSaving = (skipThemeProp && skipThemeProp.Value1.toLowerCase() == "true") ||
        that.option("stateStoring.icSkipSavingStateOnRowChange");

    if (dataController && !skipSaving) {
        // endregion InvestCloud
        that._initialPageSize = that.option("paging.pageSize");
        dataController.changed.add(function () {
            var userState = dataController.getUserState(),
                focusedRowEnabled = that.option("focusedRowEnabled");
            DevExpress.ic.core.utils.extend.extend(that._state, userState, {
                allowedPageSizes: pagerView ? pagerView.getPageSizes() : void 0,
                filterPanel: {
                    filterEnabled: that.option("filterPanel.filterEnabled")
                },
                filterValue: that.option("filterValue"),
                focusedRowKey: focusedRowEnabled ? that.option("focusedRowKey") : void 0
            });
            that.isEnabled() && that.save()
        })
    }
    if (exportController) {
        exportController.selectionOnlyChanged.add(function () {
            (0, _extend.extend)(that._state, {
                exportSelectionOnly: exportController.selectionOnly()
            });
            that.isEnabled() && that.save()
        })
    }
};
DevExpress.ic.gridCoreStateStoring.extenders.controllers.stateStoring.init = function () {
    this.callBase.apply(this, arguments);
    overrideStateStoringProcessLoadState(this);
};

// region InvestCloud
var origApplyState = DevExpress.ic.gridCoreStateStoring.extenders.controllers.stateStoring.applyState;
DevExpress.ic.gridCoreStateStoring.extenders.controllers.stateStoring.applyState = function (state) {
    this.component.beginUpdate();
    origApplyState.apply(this, arguments);
    if (state.sortByGroupSummaryInfo) {
        this.component.option("sortByGroupSummaryInfo", state.sortByGroupSummaryInfo);
    }
    if (state.icCustomSort) {
        var sorting = this.component.option("sorting");
        sorting.icCustomSort = state.icCustomSort;
    }
    this.component.endUpdate();
};
// endregion InvestCloud

function overrideStateStoringRefreshDataSource() {
    var that = this,
        callBase = that.callBase,
        stateStoringController = that.getController("stateStoring");
    if (stateStoringController.isEnabled() && !stateStoringController.isLoaded()) {
        clearTimeout(that._restoreStateTimeoutID);

        var deferred = new DevExpress.ic.core.deferredUtils.Deferred();
        that._restoreStateTimeoutID = setTimeout(function () {
            stateStoringController.load().always(function () {
                that._restoreStateTimeoutID = null;
                callBase.call(that);
                that.stateLoaded.fire();

                // region InvestCloud
                var $utilLstSvc = that.option('ic.$utilLstSvc');
                $utilLstSvc.updateColumnChooserSequence(that.component);
                $utilLstSvc.recalculateHidingPriorities(that.component);
                // endregion InvestCloud

                deferred.resolve()
            })
        });
        return deferred.promise()
    } else {
        if (!that.isStateLoading()) {
            callBase.call(that)
        }
    }
}
DevExpress.ic.gridCoreStateStoring.extenders.controllers.data._refreshDataSource = overrideStateStoringRefreshDataSource;

var _dxPager = DevExpress.ic.pager.redefine({
    _wrapClickAction: function (action) {
        var tmpThis = this;
        return function (e) {

            if (e.type === "dxpointerup") {
                tmpThis._pointerUpHappened = true;
            } else if (tmpThis._pointerUpHappened) {
                tmpThis._pointerUpHappened = false;
                return;
            }
            action({ event: e });
        };
    },
    _nextPage: function (direction) {
        var that = this,
            pageIndex = that.option("pageIndex"),
            pageCount = that.option("pageCount");

        // From 18.2 library (this code is responsible for the next/previous functionality)
        if (!_.isUndefined(pageIndex)) {
            pageIndex = direction === "next" ? ++pageIndex : --pageIndex;
            if (pageIndex > 0 && pageIndex <= pageCount) {
                that.option("pageIndex", pageIndex);
            }
        }

        // Added to support scroll-to-top
        that._$element.find("div.dx-navigate-button").trigger("ic-paginationbuttonclick");

        // See: iXing.Helpers.checkRowFocusAfterContentReady
        var $dataGrid = that.$element().parents(".icGrid");
        var dataGrid = $dataGrid.dxDataGrid("instance");
        dataGrid.option("setRowFocusIndex", 0);

        var scrollingTopMarginOnPagination = dataGrid.option("icScrollingTopMarginOnPagination");
        if (_.isNumber(scrollingTopMarginOnPagination)) {
            setTimeout(() => window.scrollTo(0, scrollingTopMarginOnPagination), 0);
        }
    },
    _updateButtonsState: function (pageIndex) {
        const $element = this.$element();
        var nextButton = $element.find("." + PAGER_NEXT_BUTTON_CLASS),
            prevButton = $element.find("." + PAGER_PREV_BUTTON_CLASS);
        nextButton.toggleClass(PAGER_BUTTON_DISABLE_CLASS, this._isPageIndexInvalid("next", pageIndex));
        prevButton.toggleClass(PAGER_BUTTON_DISABLE_CLASS, this._isPageIndexInvalid("prev", pageIndex));

        // Add aria disabled if btn has class
        addAriaLabelsToPrevNextBtn(prevButton, $element);
        addAriaLabelsToPrevNextBtn(nextButton, $element);
    },
    _renderNavigateButton: function (direction) {
        var eventsEngine = DevExpress.events,
            eventUtils = DevExpress.events.utils,
            clickEvent = DevExpress.events.click,
            that = this,
            clickAction = that._createAction(function () {
                that._nextPage(direction);
                that._$element.find("div.dx-navigate-button").trigger("ic-paginationbuttonclick");
            }),
            $button;

        if (that.option("showNavigationButtons") || that.option("lightModeEnabled")) {
            $button = $("<div>").addClass(PAGER_NAVIGATE_BUTTON).attr("tabindex", "0");

            eventsEngine.on($button, eventUtils.addNamespace(["dxpointerup", clickEvent.name], that.Name + "Pages"), that._wrapClickAction(clickAction));
            var $utilSvc = this.option("ic.$utilSvc");
            var prevPageTranslation = $utilSvc?.getTranslation('Previous page') || 'Previous page';
            var nextPageTranslation = $utilSvc?.getTranslation('Next page') || 'Next page';

            that.setAria({
                "role": "button",
                "label": direction === "prev" ? prevPageTranslation : " " + nextPageTranslation
            }, $button);

            if (that.option("rtlEnabled")) {
                $button.addClass(direction === "prev" ? PAGER_NEXT_BUTTON_CLASS : PAGER_PREV_BUTTON_CLASS);
                $button.prependTo(this._$pagesChooser);
            } else {
                $button.addClass(direction === "prev" ? PAGER_PREV_BUTTON_CLASS : PAGER_NEXT_BUTTON_CLASS);
                $button.appendTo(this._$pagesChooser);
            }

            //only whole numbers allowed in the pager dxNumberBox
            if (this._pageIndexEditor !== undefined) {
                setTimeout(function () {
                    that._pageIndexEditor.option("mode", "tel");
                    that._pageIndexEditor.option("format", "#0");
                    var numberInput = that._pageIndexEditor
                        .$element()
                        .find("input.dx-texteditor-input"),
                        maxPages = numberInput.attr("max"),
                        ariaLabel = that.option("ic.$utilSvc")?.getTranslation("current page out of") + " " + maxPages;

                    numberInput.attr("aria-label", ariaLabel);
                });
            }
        }
    },
    _renderPages: function (pages) {
        var eventsEngine = DevExpress.events,
            eventUtils = DevExpress.events.utils,
            clickEvent = DevExpress.events.click,
            that = this,
            element = this.$element(),
            $separator,
            pagesLength = pages.length,
            clickPagesIndexAction = that._createAction(function (args) {
                var e = args.event,
                    pageNumber = $(e.target).text(),
                    pageIndex = pageNumber === '>' ? that.option("pageCount") + 1 : Number(pageNumber);

                ///#DEBUG
                that._testPageIndex = pageIndex;
                ///#ENDDEBUG

                that._$element.find("div.dx-page").trigger("ic-paginationbuttonclick");
                that.option("pageIndex", pageIndex);
            }),
            page;

        if (pagesLength > 1) {
            that._pageClickHandler = this._wrapClickAction(clickPagesIndexAction);
            eventsEngine.on(that._$pagesChooser, eventUtils.addNamespace(["dxpointerup", clickEvent.name], that.Name + "Pages"), '.' + PAGER_PAGE_CLASS, that._pageClickHandler);
        }

        if (pagesLength === 1) {
            that._$element[0].style.display = "none";
        }

        const dataGridInstance = getDxDataGrid(element);
        var utilService = dataGridInstance ? dataGridInstance.option('ic.$utilSvc') : null;
        if (_.isNil(utilService)) return;

        pages.forEach(function (page, i) {
            page.render(that._$pagesChooser, that.option('rtlEnabled'));
            var dxPagerAriaLabelTranslated = utilService.getTranslation('Page');
            var value = [dxPagerAriaLabelTranslated, page.value()].join(' ');

            that.setAria({
                "role": "button",
                "label": value
            }, page.element());
            page.element().attr("tabindex", "0");

            if (page.element().hasClass('dx-selection')) {
                page.element().attr("aria-current", "page");
            }

            if (pages[i + 1] && pages[i + 1].value() - page.value() > 1) {
                $separator = $("<div>").text(". . .").addClass(PAGER_PAGE_SEPARATOR_CLASS);
                that.option('rtlEnabled') ? $separator.prependTo(that._$pagesChooser) : $separator.appendTo(that._$pagesChooser);
            }
        })
    },
    _renderPagesSizeChooser: function () {
        var that = this,
            pageSizes = that.option("pageSizes"),
            showPageSizes = that.option("showPageSizes");

        var helpersService = that.option('ic.helpersService');

        if (that.option("lightModeEnabled")) {

            //to remove the "0" page size, which is included as part of show all feature for data grids
            //from the doprdown of pageSizes selectbox generated as part of lightmode
            var indexOfZero = pageSizes.indexOf(0);
            if (indexOfZero > -1) {
                //allowShowAllEnabled = true;
                //pageSizes.splice(indexOfZero, 1);
            }
        };

        var pagesSizesLength = pageSizes && pageSizes.length,
            $element = that.$element();
        that._$pagesSizeChooser && that._$pagesSizeChooser.remove();
        if (!showPageSizes || !pagesSizesLength) {
            return
        }
        that._$pagesSizeChooser = $("<div>").addClass(PAGER_PAGE_SIZES_CLASS).appendTo($element);

        //to account for edgecase where the user loads the page in light-mode pager (small width)
        //and then resizes it to regular mode (thus avoiding reloading the entire grid but changing the pager mode)
        setTimeout(function () {
            if (pageSizes.indexOf(0) > -1 && that.option("pageSize") != 0 && that._$pagesSizeChooser.find("[data-before='View All']").length == 0) {
                helpersService.updatePagerViewAllText({ "element": that._$pagesSizeChooser });
            };
        }, 0);

        if (that.option("lightModeEnabled") && that.option("pageSize") != 0) {
            if (pageSizes.indexOf(0) > -1) {
                that._$pagesSizeChooser.append("<div class='showAllDataButton' data-before='View All'>View All</div>");
                $(".showAllDataButton").click(function () { that.option("pageSize", 0) });
            };
            that._renderLightPageSizes()
        } else {
            that._renderPageSizes()
        }
        that._pagesSizeChooserWidth = that._$pagesSizeChooser.width()
    }

});
DevExpress.registerComponent("dxPager", _dxPager);

var query = query || DevExpress.data.query;
var queryByOptions = queryByOptions || DevExpress.ic.storeHelper.queryByOptions;

var _dxTreeList = DevExpress.ui.dxTreeList.inherit({

    _init: function () {
        this.callBase();

        this.getController('data')._getDataSourceAdapter().create().constructor.redefine({
            _handleDataLoaded: function (options) {
                var data = options.data = this._convertDataToPlainStructure(options.data);
                if (!options.remoteOperations.filtering && options.loadOptions.filter) {
                    var queryByOptions = DevExpress.ic.storeHelper.queryByOptions;
                    var query = DevExpress.ic.data.query;
                    options.fullData = queryByOptions(query(options.data), {
                        sort: options.loadOptions && options.loadOptions.sort
                    }).toArray();
                } else {
                    options.fullData = data;
                }
                this._updateHasItemsMap(options);
                this.callBase(options);

                if (data.isConverted && this._cachedStoreData) {
                    this._cachedStoreData.isConverted = true;
                }
            }
        });

        this.getView("headerPanel").constructor.redefine({
            _appendColumnChooserItem: headerPanel_appendColumnChooserItem,
        });

        this.getView("columnChooserView").constructor.redefine(columnChooserView);

        this.getController("columns").constructor.redefine(columnsController);

        this.getController('data').constructor.redefine({
            _handleColumnsChanged: handler_handleColumnsChanged
        });
    },

    setCustomSummaryTotalText: function () {
        setCustomSummaryTotal(this);
    }
});
DevExpress.registerComponent("dxTreeList", _dxTreeList);
