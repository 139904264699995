import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { switchMap } from 'rxjs/operators';
import { CommandListCollectionService } from "./command-list-collection.service";
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { BaseEffectCommand } from './base-effect.command';
import { ApiClientService } from '../services/api-client.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class RedirectToExternalUrlCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected dynamicReplacementService: DynamicReplacementService,
        protected cacheManagerService: CacheManagerService,
        protected apiClientService: ApiClientService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.redirectToExternalURLCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    const promise = this.redirectToExternalURL(commandConfig);
                    return this.waitAndContinueNextActions(promise, commandConfig);
                })
            )
    );

    redirectToExternalURL(commandConfig: CommandConfig): Promise<any> {
        const externalURL = commandConfig.parameters[0];
        const logoutFlag = commandConfig.parameters[1] || "";
        const openInNewTab = commandConfig.parameters[2] || "";
        const replaceOptions = {
            rid: commandConfig.rid,
            appName: commandConfig.appName,
        };
        return this.dynamicReplacementService
            .getDynamicValue(externalURL, replaceOptions)
            .then((externalUrl: string) => {
                // Prefixing `data` with `https://` if it doesn't already contain http://, https:// or ftp://
                // This is crucial because the intention is to redirect to EXTERNAL url. window.open will treat
                // as a relative url if it does not have a schema prefix.
                const hasProtocol = this.isUrl(externalUrl);
                // AND We let / prefix be treated as a relative redirect to the root.
                // Transaction Editor can specify a redirect url with a proceeding slash to do relative to root.
                const isRelativeToApplication = externalUrl.substring(0, 1) === '/';
                if (!hasProtocol && !isRelativeToApplication) {
                    externalUrl = 'https://' + externalUrl;
                }
                if (this.isTruthy(openInNewTab)) {
                    window.open(externalUrl, '_blank');
                } else {
                    this.setLocationHref(externalUrl);
                }
                if (this.isTruthy(logoutFlag)) {
                    return this.apiClientService.signOut();
                }
                return true;
            });
    }

}
