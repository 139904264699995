import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from "./command-list-collection.service";
import { CacheManagerService } from '../services/cachemanager.service';
import { ApplicationInformation } from '../services/application-information.service';
import { UtilService } from '../services/util.service';

@Injectable()
export class ClearStepEventsCommand extends BaseEffectCommand {

    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected cacheManagerService: CacheManagerService,
        protected commandListCollectionService: CommandListCollectionService,
        protected applicationInformation: ApplicationInformation,
        protected utilService: UtilService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.clearStepEventsCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.clearStepEventsCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const prevCmdMap = {
                    'setValues': true,
                    'Redirect-setvalues': true,
                    'triggerDirectiveCalls': true,
                    'dynamicReplacement': true
                };
                let fieldMap = null;
                let skipApps = {};
                let resetAllContext = commandConfig.options.resetAllContext;
                if (!resetAllContext) {
                    resetAllContext = false;
                    fieldMap = (prevCmdMap[commandConfig.prevCmd] && !_.isNil(commandConfig.fieldMap))
                        ? commandConfig.fieldMap
                        : this.getFieldMap(commandConfig);
                    skipApps = this.utilService.getAppsInFieldMap(fieldMap, fieldMap.sourceApp, fieldMap.targetApp);
                }
                const resetOptions: ResetOptions = {
                    resetAllContext,
                    skipApps,
                    isInput: (appName: string) => this.applicationInformation.isInputApp(appName),
                    isList: (appName: string) => this.applicationInformation.isListApp(appName)
                };
                const resetContext = AppsActions.resetContext({ resetOptions });
                return this.appendNextActions([resetContext], commandConfig, fieldMap);
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route }),
        })
    );
}
