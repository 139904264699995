import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from "./command-list-collection.service";
import { QryAggregateProfitAndLossLiteService } from '../services/clientservices/qryaggregateprofitandlosslite.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class ClearClientServicesCacheCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private qryAggregateProfitAndLossLiteService: QryAggregateProfitAndLossLiteService) {
        super();
    }

    effectCommandName$ = createEffect(
        () => this.actions$.pipe(
            ofType(CommandActions.clearClientServicesCacheCommand),
            switchMap((action) => {
                const commandConfig = this.getCommandConfig(action);
                this.qryAggregateProfitAndLossLiteService.clearCache();
                return this.getNextActions(commandConfig);
            })
        )
    );

}
