/// <reference lib="webworker" />
import { RowSet } from './rowset';

export class DataSet {
  _listData: any[];
  _columnMap: Record<string, any> = {};
  _debugMode = false;

  constructor(listData, debugMode?: boolean) {
    if (!Array.isArray(listData) || !Array.isArray(listData[0])) {
      listData = [[]];
    }

    this._listData = listData;
    this._columnMap = this.calculateColumnMap();
    this._debugMode = debugMode;
  }

  ColumnMap() {
    return this._columnMap;
  }

  calculateColumnMap() {
    const columns = _.first(this._listData) || [];
    let lastIndex = 0;
    const columnMap = columns.reduce(
      (objMap, currentColumn, columnIndex) => {
        objMap[currentColumn] = columnIndex;
        lastIndex = Math.max(lastIndex, columnIndex);
        return objMap;
      },
      { lastIndex }
    );
    columnMap._lastIndex = lastIndex + 1;
    return columnMap;
  }

  setData(listData) {
    this._listData = listData;
    this._columnMap = this.calculateColumnMap();
  }

  data() {
    return this._listData;
  }

  isEmpty() {
    return this._listData[0].length === 0;
  }

  dataLength() {
    return this._listData.length - 1;
  }

  addColumns(cols) {
    if (!Array.isArray(cols) && typeof cols === 'string') {
      cols = [cols];
    }

    cols.forEach((field) => {
      if (this._columnMap[field] === undefined) {
        this._listData[0].push(field);
        this._columnMap[field] = this._columnMap._lastIndex;
        this._columnMap._lastIndex++;
      }
    });
    this._columnMap = this.calculateColumnMap();
  }

  clearColumns(cols) {
    if (!Array.isArray(cols) && typeof cols === 'string') {
      cols = [cols];
    }

    cols.forEach((field) => {
      for (let k = 1; k < this._listData.length; k++) {
        const colIndex = this._listData[0].indexOf(field);
        this._listData[k][colIndex] = null;
      }
    });

    this._columnMap = this.calculateColumnMap();
  }

  headerRow() {
    return this._listData[0];
  }

  row(i): RowSet {
    return new RowSet(this._listData[i + 1], this._columnMap);
  }

  forEachRow(fn) {
    for (let i = 1; i < this._listData.length; i++) {
      fn(new RowSet(this._listData[i], this._columnMap));
    }
  }

  hasColumn(field) {
    return typeof this._columnMap[field] !== 'undefined';
  }

  logToConsole(message) {
    if (typeof window !== 'undefined' && window['IX_DEBUG_SETTINGS']) {
      window['IX_DEBUG_SETTINGS'].clientSvc.logTable(message, this._listData);
    } else {
      this.workerLogToConsole(message, this._listData);
    }
  }

  workerLogToConsole(text, data) {
    if (!this._debugMode) return;

    postMessage({
      logText: text,
      logData: data,
    });
  }
}
