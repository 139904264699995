import { IcValidator } from "./ic-validator";

export class ValidationGroupConfig {

    name: string;
    private validators: IcValidator[] = [];

    constructor(group: string) {
        this.name = group;
    }

    validate(s): Promise<any> {

        const result = {
            isValid: true,
            brokenRules: [],
        }, promises = [];

        $.each(this.validators, (_, validator: IcValidator) => {
            promises.push(validator.validate(s));
        });

        return Promise.all(promises)
            .then((values) => {
                result.isValid = !values.some((el, i) => {
                    let aux = el.isValid;
                    if (!aux) {
                        if (el.brokenRules[0].warning)
                            aux = true;
                        else
                            result.brokenRules.push(el.brokenRules[0]);
                    }
                    return aux === false;
                });
                return Promise.resolve(result);
            });
    }

    resetAndGetFieldMap(maintainValidValues: boolean): Record<string, unknown> {
        const appFieldValueMap = {};
        this.validators.forEach((validator: IcValidator) => {
          if (!this.isMultiRowSelectValidator(validator)) {
            const appMap = validator.resetAndGetFieldMap(maintainValidValues);
            const appName = _.first(Object.keys(appMap));
            const currMap = appFieldValueMap[appName] ? appFieldValueMap[appName] : {};
            appFieldValueMap[appName] = { ...currMap, ...appMap[appName] };
          }
        });
        return appFieldValueMap;
    }

    addValidator(validator: IcValidator): boolean {
        if ($.inArray(validator, this.validators) < 0) {
            this.validators.push(validator);
            return true;
        }
    }

    removeValidator(validator: IcValidator, index: number): boolean {
        if ($.inArray(validator, this.validators) > -1) {
            this.validators.splice(index, 1);
            return true;
        }
    }

    getValidators(): IcValidator[] {
        return $.grep(this.validators, () => true);
    }

    private isMultiRowSelectValidator(validator: IcValidator): boolean {
      return !!validator.adapter.editor.multiRowSelectValidationGroupName;
    }
}
