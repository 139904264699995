import { Injectable } from '@angular/core';
import { GdprService } from './gdpr.service';
import { ThemeService } from './theme.service';

@Injectable()
export class ComplianceService {
  constructor(private themeService: ThemeService,
    private gdprSvc: GdprService
    ) {}

  processOneTrust() {
    if (!!this.themeService.getThemeDetail('ICAnalyticsAccountId')) {
      if (!!this.themeService.getThemeDetail('ICAnalyticsSiteId')) {
        window.OneTrust.InsertHtml(this.getGenericHTML('ICA'), 'body', this.injectICAnalytics, null, 'C0002');
      }
      if (!!this.themeService.getThemeDetail('ICAnalyticsContainerId')) {
        window.OneTrust.InsertHtml(this.getGenericHTML('ICTM'), 'body', this.injectICTagManager, null, 'C0002');
      }
    }

    if (!!this.themeService.getThemeDetail('GoogleAnalyticsAccountId'))
      window.OneTrust.InsertHtml(this.getGenericHTML('GA'), 'body', this.injectGoogleAnalytics, null, 'C0002');

    if (!!this.themeService.getThemeDetail('GoogleTagManagerContainerId'))
      window.OneTrust.InsertHtml(this.getGenericHTML('GTM'), 'body', this.injectGoogleTagManager, null, 'C0002');

    if (!!this.themeService.getThemeDetail('FacebookTrackingId'))
      window.OneTrust.InsertHtml(this.getGenericHTML('FB'), 'body', this.injectFacebookTracker, null, 'C0004');

    if (!!this.themeService.getThemeDetail('ProgrammaticTrackingId')) window.OneTrust.InsertHtml(this.getProgrammaticHTML(), 'body', null, null, 'C0004');

    if (!!this.themeService.getThemeDetail('LinkedInPartnerId')) {
      window.OneTrust.InsertHtml(this.getLinkedInHTML(), 'body', this.injectLinkedInTracker, null, 'C0004');
    }

    if (!!this.themeService.getThemeDetail('TwitterTrackingId'))
      window.OneTrust.InsertHtml(this.getGenericHTML('TW'), 'body', this.injectTwitterTracker, null, 'C0004');
  }

  private getGenericHTML(id) {
    if (!!document.getElementById(id)) {
      IX_Log('icComplianceSvc', 'Analytics for ' + id + ' have already been injected');
      return null;
    }

    return "<div id='" + id + "'></div>";
  }

  private getProgrammaticHTML() {
    const trackId = this.themeService.getThemeDetail('ProgrammaticTrackingId');

    return "<img width='1' height='1' alt='' aria-hidden='true'" + "src='https://one.progmxs.com/seg?add=" + trackId + "&t=2' />";
  }

  private getLinkedInHTML() {
    const trackId = this.themeService.getThemeDetail('LinkedInPartnerId');

    return '<img height="1" width="1" style="display:none;" alt=""' + 'src="https://px.ads.linkedin.com/collect/?pid=' + trackId + '&fmt=gif"/>';
  }

  private injectGoogleAnalytics() {
    this.gdprSvc.googleAnalyticsInjector(
      this.themeService.getThemeDetail('GoogleAnalyticsAccountId'),
      this.themeService.getThemeDetail('AllowGAAnonymization')
    );
  }

  private injectICAnalytics() {
    this.gdprSvc.icAnalyticsInjector(this.themeService.getThemeDetail('ICAnalyticsAccountId'), this.themeService.getThemeDetail('ICAnalyticsSiteId'));
  }

  private injectICTagManager() {
    this.gdprSvc.icTagManagerInjector(this.themeService.getThemeDetail('ICAnalyticsAccountId'), this.themeService.getThemeDetail('ICAnalyticsContainerId'));
  }

  private injectGoogleTagManager() {
    this.gdprSvc.googleTagManagerInjector(this.themeService.getThemeDetail('GoogleTagManagerContainerId'));
  }

  private injectFacebookTracker() {
    this.gdprSvc.facebookInjector(this.themeService.getThemeDetail('FacebookTrackingId'));
  }

  private injectLinkedInTracker() {
    this.gdprSvc.linkedInInjector(this.themeService.getThemeDetail('LinkedInPartnerId'));
  }

  private injectTwitterTracker() {
    this.gdprSvc.twitterInjector(this.themeService.getThemeDetail('TwitterTrackingId'));
  }
}
