import { Injectable } from "@angular/core";
import { ApplicationInformation } from '../services/application-information.service';
import { UtilService } from "../services/util.service";
import { HelpersService } from "../services/helpers.service";
import { AppsConstants } from "../state/apps.constants";

@Injectable()
export class CommandParser {

    constructor(private applicationInformation: ApplicationInformation,
        private utilService: UtilService,
        private helpersService: HelpersService) { }

    defaultParser(parameters, i) {

        if (!_.isString(parameters[i])) return parameters;

        if (this.applicationInformation.isV4App(parameters[i]))
            return parameters;

        if (parameters[i].isJSON())
            parameters[i] = this.helpersService.jsonParse(parameters[i]);

        return parameters;
    }

    multipleCommandParser(parameters, i, action, addClearOnRedirect) {

        const isConditionalRedirect = parameters[0] === "isConditionalRedirect";
        const isConditionalFieldMappings = parameters[0] === "isConditionalFieldMappings";
        const cmdBrkdwn = [];

        if (isConditionalRedirect) {
            cmdBrkdwn.push('conditionalRedirect');
            const tmp = this.parseConditionalMapping(parameters);
            if (tmp.length > 0)
                cmdBrkdwn.push('setvalues');
            parameters = tmp;
            if (addClearOnRedirect)
                cmdBrkdwn.push('clear step events');
            cmdBrkdwn.push(action);
        } else if (isConditionalFieldMappings) {
            cmdBrkdwn.push('conditionalFieldMappings');
            parameters = this.parseConditionalMapping(parameters);
            cmdBrkdwn.push('setvalues');
        } else {
            if (this.helpersService.isFieldMap(parameters[i])) {
                this.checkFieldMapperSettings(parameters[i], cmdBrkdwn);
            } else if (_.isString(parameters[i])) {
                parameters[i] = parameters[i].replace(/,+(None),*/, "");
                if (this.applicationInformation.isV4App(parameters[i])) {
                    parameters;
                } else if (this.hasReplacementValue(parameters[i])) {
                    parameters = this.defaultParser(parameters, i);
                    cmdBrkdwn.push('dynamicreplacement');
                } else if (!parameters[i].startsWith("Condition:")) {
                    const aux = this.helpersService.jsonParse(parameters[i]);
                    if (typeof aux == "object") {
                        parameters[i] = aux;
                        this.checkFieldMapperSettings(parameters[i], cmdBrkdwn);
                    }
                } else {
                    IX_Log("cmdLst", "There is an error trying to parse command:", action, parameters[i]);
                }
            } else {
                IX_Log("cmdLst", "There is an error trying to parse command:", action, parameters[i]);
            }
            if (addClearOnRedirect)
                cmdBrkdwn.push('clear step events');
            cmdBrkdwn.push(action);
        }

        return { parameters: parameters, cmdBrkdwn: cmdBrkdwn };
    }

    noCommandParser(parameters, i) {
        return parameters;
    }

    openModalParser(parameters, i) {
        const trueMap = { "true": true, "yes": true, "false": false, "no": false };
        if (!_.isString(parameters[i])) return parameters;
        parameters[i] = parameters[i].replace(/,+(ClearContextOnRedirect),*/, "");

        if (parameters[i].indexOf(",KeyRead") >= 0) {
            parameters[i] = this.helpersService.getFieldMapBase(parameters[i].replace(/,+(KeyRead),*/, ""));
            parameters[i].directive = AppsConstants.triggerDirective.keyRead;
            parameters[2] = parameters[i].directive != AppsConstants.triggerDirective.none;
        }
        else if (parameters[i].indexOf(",None") >= 0 || (parameters[i].indexOf("{") == -1 && parameters[i].indexOf("{") == -1)) {
            parameters[i] = parameters[i].replace(/,+(None),*/, "");
            parameters[2] = false;
        } else {
            parameters[i] = this.helpersService.jsonParse(parameters[i]);
            parameters[2] = parameters[i].directive != AppsConstants.triggerDirective.none;
        }

        // apply filter flag
        if (parameters.length > 3)
            parameters[3] = trueMap[parameters[3].toString().toLowerCase()] || true;
        else
            parameters.push(true);

        return parameters;
    }

    setLocaleParser(parameters, i, action) {
        const cmdBrkdwn = [];
        if (this.hasReplacementValue(parameters[i])) {
            cmdBrkdwn.push('dynamicreplacement');
        }
        cmdBrkdwn.push(action);
        return { parameters: parameters, cmdBrkdwn: cmdBrkdwn };
    }

    private checkFieldMapperSettings(fieldMap, commands) {
        if (this.hasReplacementValue(fieldMap.targetApp)) {
            commands.push('dynamicreplacement');
        } else {
            const apps = this.utilService.getTriggerDirectiveApplications(fieldMap);
            if (apps.length) commands.push('triggerdirectivecalls');
            else commands.push('setvalues');
        }
    }

    private parseConditionalMapping(parameters) {
        const tmp = [];
        for (let t = 1; t < parameters.length; t++) {
            const aux = this.helpersService.jsonParse(parameters[t]);
            if (!_.isObject(aux) || _.isNil(aux)) continue;
            tmp.push(aux);
        }
        return tmp;
    }

    private isClientSideReplacement(parameter) {

        if (typeof parameter == 'undefined' || typeof parameter == "number" ||
            typeof parameter != 'string' || parameter === null || parameter === "")
            return false;

        parameter = parameter.toLowerCase();
        const dynamic = parameter.indexOf("{field:") > -1 ||
            parameter.indexOf("{date:") > -1 ||
            parameter.indexOf("{list:") > -1 ||
            parameter.indexOf("{event:") > -1 ||
            parameter.indexOf("{themeproperty:") > -1 ||
            parameter.indexOf("{themedetails:") > -1 ||
            parameter.indexOf("{constant:") > -1 ||
            parameter.indexOf("{screencontext:") > -1 ||
            parameter.indexOf("{error:") > -1 ||
            parameter.indexOf("{cookie:") > -1 ||
            parameter.indexOf("{ssoparam:") > -1 ||
            parameter.indexOf("{column:") > -1 ||
            parameter.indexOf("{columnreplaceall:") > -1 ||
            parameter.indexOf("{url:") > -1 ||
            parameter.indexOf("{querystring:") > -1;
        return dynamic;
    }

    private isServerSideReplacement(parameter) {

        if (typeof parameter == 'undefined' || typeof parameter == "number" ||
            typeof parameter != 'string' || parameter === null || parameter === "")
            return false;

        parameter = parameter.toLowerCase();
        const dynamic = parameter.indexOf("{menuredirects:") > -1 ||
            parameter.indexOf("{totalsummaryvalue:") > -1 ||
            parameter.indexOf("{globals:") > -1 ||
            parameter.indexOf("{usercontextdefault:") > -1 ||
            parameter.indexOf("{persisted") > -1 ||
            parameter.indexOf("{securecookie") > -1;
        return dynamic;
    }

    private hasReplacementValue(parameter) {
        return this.isClientSideReplacement(parameter) || this.isServerSideReplacement(parameter);
    }
}