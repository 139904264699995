import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { AppEvent } from '../state/app-events.enum';
import { from, Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { InputApplication } from '../components/input-application';
import { EventsService } from '../services/events.service';
import { CommandListCollectionService } from "./command-list-collection.service";
import { ApplicationInformation } from '../services/application-information.service';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class ClearFormValuesCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private eventsService: EventsService,
        private applicationInformation: ApplicationInformation,
        private dynamicReplacementService: DynamicReplacementService,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(
        () => this.actions$.pipe(
            ofType(CommandActions.clearFormValuesCommand),
            switchMap(action => {
                const commandConfig = this.getCommandConfig(action);
                return this.clearFormValues(commandConfig)
                    .pipe(
                        switchMap((appState) => {
                            const appName = _.first(commandConfig.parameters);
                            const resetActions = this.getResetAppActions(appName);
                            const appEntity = this.createAppEntity(appName, appState);
                            const updateActions = this.getUpdateCommandStateActions([appEntity], commandConfig);
                            const thisActions = resetActions.concat(updateActions);
                            return this.appendNextActions(thisActions, commandConfig);
                        })
                    );
            })
        )
    );

    private clearFormValues(commandConfig: CommandConfig): Observable<AppState> {

        const appName = _.first(commandConfig.parameters);
        const appComponent = this.domComponentRetrievalService.getAppComponent(appName) as InputApplication;
        if (_.isNil(appComponent)) {
            const message = `Validation failed. '${appName}' component is undefined. Make sure the app is part of the page.`;
            return throwError(message);
        }

        this.clearListFilters(appName);
        this.resetAppFieldMultiUploadFields(appName);

        _.mergeWith(appComponent.model, appComponent.modelDefaultValues);
        _.mergeWith(appComponent.model, appComponent.modelDRs);
        const model = _.merge({}, appComponent.model);
        const appState = {};
        return from(this.setContextFromModel(appState, appName, model));
    }

    private resetAppFieldMultiUploadFields(appName: string): void {
        if (!this.applicationInformation.isInputApp(appName)) return;
        const event = this.createAppEntity(AppEvent.ResetFileUploadMulti, { appName });
        this.eventsService.publishEvent(event);
    }

    private setContextFromModel(appState: AppState, appName: string, model: any): Promise<AppState> {
        const fields = [];
        const dynamicReplacements = [];
        Object.keys(model)
            .forEach(fieldName => {
                const field = model[fieldName];
                if (this.dynamicReplacementService.hasReplacementValue(field.value)) {
                    fields.push(fieldName);
                    dynamicReplacements.push(field.value);
                } else {
                    appState[fieldName] = field.value;
                }
            });
        const options = {
            appName,
            force: true, //setting to true to allow empty string "" to be set as dynamic value if resulted from dynamic replacement
            version: 2,
            unformatted: true,
        };
        return (Promise as any).map(dynamicReplacements, (dynamicReplacement: string, index: number) =>
            this.dynamicReplacementService
                .getDynamicValue(dynamicReplacement, options)
                .then((replacementValue: string) => {
                    if (this.isModelReplacementValueValid(replacementValue)) {
                        const field = fields[index];
                        appState[field] = replacementValue;
                    }
                    return replacementValue;
                })
        ).then(() => appState);
    }

    private isModelReplacementValueValid(value: any): boolean {
        return !_.isNil(value);
    }

    private clearListFilters(appName: string) {
        if (!this.applicationInformation.isListApp(appName)) return;
        //to clear the column filter value if filter is enabled
        const dxDataGrid = this.domComponentRetrievalService.getDxComponentInstance({}, appName);
        if (dxDataGrid?.option("filterRow").visible) {
            dxDataGrid.clearFilter();
        }
    }
}
