import { Injectable } from '@angular/core';
import { EventsService } from './events.service';
import { ThemeService } from './theme.service';
import { TranslateFacadeService } from './translate-facade.service';
import { AppEvent } from '../state/app-events.enum';

@Injectable()
export class AlertService {

    constructor(private eventsService: EventsService,
        private themeService: ThemeService,
        private translateFacade: TranslateFacadeService) { }

    showMessage(message: string, callbackFunction?: Function) {
        const waitForUser = () => {
            callbackFunction && callbackFunction();
        };
        if (_.isEmpty(message)) {
            waitForUser();
            return;
        }
        const messageTranslated = this.getTranslation(message);
        this.alert2(messageTranslated, waitForUser);
    }

    icAlert(messageId) {
        let showFallbackMessage = true;
        if (messageId) {
            const alertText = this.getTranslation(messageId);
            if (alertText) {
                showFallbackMessage = false;
                alert(alertText);
            }
        }
        if (showFallbackMessage) {
            const alertText = this.getTranslation("RETRY-OR-CONTACT-SUPPORT");
            alert(alertText);
        }
    }

    alert2(commandTextId, callbackFunction?: Function) {
        const dialogText = commandTextId;
        const showCancelButton = false;
        this.enhanceAlert(dialogText, showCancelButton, (result) => {
            if (result == true && _.isFunction(callbackFunction)) {
                setTimeout(() => callbackFunction(), 0);
            }
        });
    }

    showCustomAlert(callbackFunction) {
        const event = {
            id: AppEvent.ThemeCustomAlert,
            state: {
                Show: true
            }
        };
        this.eventsService.publishEvent(event);
        //  Notifies the calling code that the execution is completed.
        callbackFunction(true);
    }

    enhanceAlert(messageText, showCancelButton?, callbackFunction?, okButtonText?: string, cancelButtonText?: string) {

        const customAlertInfo = this.themeService.getCustomAlertInfo();
        if (customAlertInfo) {
            this.showCustomAlert(callbackFunction);
            return;
        }

        const visible = true;
        const enhanceAlertOptions = {
            visible,
            messageText,
            okButtonText,
            cancelButtonText,
            showCancelButton,
            callbackFunction
        };
        const eventState = {
            id: AppEvent.EnhancedAlert,
            state: enhanceAlertOptions
        };
        this.eventsService.publishEvent(eventState);
    }

    timeoutAlert() {

        const customSessionTimeOutInfo = this.themeService.getCustomSessionTimeOutAlertInfo();
        if (customSessionTimeOutInfo) {
            this.showCustomSessionTimeOutPopup(customSessionTimeOutInfo);
            return;
        }

        const eventState = {
            id: AppEvent.EnhancedTimeout,
            state: { visible: true }
        };
        this.eventsService.publishEvent(eventState);
    }

    private showCustomSessionTimeOutPopup(customSessionTimeOutInfo) {
        // if (_.isEmpty(customSessionTimeOutInfo?.appName)) return;
        // const childScope = {};
        // IX_ShowCustomAlert(customSessionTimeOutInfo, '', false, {}, (success) => {
        //     const remainingMillisec = iXing.IXWarningTimeForCountdown;
        //     if (!success) return;
        //     if (remainingMillisec > 0) {
        //         const timer = $timeout(() => childScope.$emit(logoutEvent), remainingMillisec);
        //         childScope.$on('$destroy', () => $timeout.cancel(timer));
        //         childScope.$on('GlobalExtendSession.Event', () => $timeout.cancel(timer));
        //     } else {
        //         childScope.$emit('GlobalLogOut.Event');
        //     }
        // });
    }

    private getTranslation(translateId: string): string {
        return this.translateFacade.getTranslation(translateId);
    }
}
