import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { AppEvent } from '../state/app-events.enum';
import { RouterService } from '../services/router.service';
import { CommandListCollectionService } from './command-list-collection.service';
import { TranslatableEffectCommand } from './translatable-effect.command';
import { AlertService } from '../services/alert.service';
import { TranslateFacadeService } from '../services/translate-facade.service';
import { UtilService } from '../services/util.service';
import { AppsConstantsFacade } from '../services/apps-constants.facade';
import { CacheManagerService } from '../services/cachemanager.service';
import { ThemeService } from '../services/theme.service';
import UtilityFunctions from '../utility.functions';

@Injectable()
export class LoginCommand extends TranslatableEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected translateFacade: TranslateFacadeService,
        protected cacheManagerService: CacheManagerService,
        private httpClient: HttpClient,
        private router: RouterService,
        private alertService: AlertService,
        private utilService: UtilService,
        private appsConstantsFacade: AppsConstantsFacade,
        private themeService: ThemeService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.loginUserCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.eventPublishCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appState = this.getAppState(commandConfig.appName, appsStore);
                return this.login(appState, commandConfig, appsStore.isPlatformMobile)
                    .pipe(
                        switchMap(() => {
                            // resetting workflow
                            this.cacheManagerService.init({});
                            this.themeService.addWalkMeScripts();
                            commandConfig.options.resetAllContext = true;
                            const thisCommandActions = [CommandActions.clearStepEventsCommand({ commandConfig })];
                            return this.appendNextActions(thisCommandActions, commandConfig);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    private login(appState: AppState, commandConfig: CommandConfig, isPlatformMobile: boolean): Observable<any> {
        const usernameField = commandConfig.parameters[0];
        const passwordField = commandConfig.parameters[1];
        const errorField = {
            aid: commandConfig.rid,
            fcn: commandConfig.parameters[2]
        };
        const loginMessage = this.getTranslation(commandConfig.parameters[3]);
        const targetURL = commandConfig.parameters[4] || "";
        const baseURL = targetURL ? this.utilService.parseUrl(targetURL) : "";
        const username = appState[usernameField] as string;
        const password = appState[passwordField] as string;
        if (_.isNil(username) || _.isNil(password))
            return throwError("Login failed");
        return this.performLogin(username, password, errorField, loginMessage, targetURL, baseURL, isPlatformMobile);
    }

    private getErrorFieldElement(errorField: any) {
        const $appletElement = $("#" + errorField.aid);
        if ($appletElement.length <= 0)
            return null;
        const $errorField = $appletElement.find(".CL_" + errorField.fcn);
        return $errorField.length > 0 ? $errorField : null;
    }

    private hideError(errorField: any): void {
        const $errorField = this.getErrorFieldElement(errorField);
        $errorField?.text("");
        $errorField?.show();
    }

    private displayError(commandTextId: string, errorField: any): void {
        const $errorField = this.getErrorFieldElement(errorField);
        if ($errorField) {
            let errorMsg = this.getTranslation(commandTextId);
            errorMsg = _.isEmpty(errorMsg) ? "Invalid Username or Password" : errorMsg;
            $errorField.text(errorMsg);
            $errorField.show();
        }
        this.hideLoadingPanel();
    }

    private hideLoadingPanel() {
        this.triggerLoadingPanelEvent({ 'visible': false });
    }

    private showLoadingPanel(message: string) {
        const state = {
            message,
            'visible': true,
            'showPane': true
        };
        this.triggerLoadingPanelEvent(state);
    }

    private triggerLoadingPanelEvent(state: any) {
        const event = {
            id: AppEvent.UpdateCommonLoadingPanel,
            state
        };
        this.appsConstantsFacade.publishEvent(event);
    }

    private performLogin(userName: string, password: string, errorField: any,
        commandTextId: string, targetURL: string, baseURL: any, isPlatformMobile: boolean, OAuthRequest?: any): Observable<any> {

        const authMessage = this.getTranslation("AUTHENTICATING") || "";
        this.showLoadingPanel(authMessage);
        this.hideError(errorField);

        let request;
        let isOAuth = false;
        if (_.isPlainObject(OAuthRequest)) {
            request = OAuthRequest;
            isOAuth = true;
        } else {
            const formData = new FormData();
            formData.append('Username', userName);
            formData.append('Password', password);
            formData.append('ResponseAsJSON', 'true');
            const mfaDeviceRef = this.getMFADeviceRef(userName);
            if (mfaDeviceRef && mfaDeviceRef.length > 0) {
                formData.append('MfaToken', mfaDeviceRef);
            }
            let loginUrl = baseURL ? (baseURL.protocol + "//" + baseURL.host) : "";
            loginUrl += "/Membership/ExtPages/Ilg.ashx";
            if (isPlatformMobile) {
                loginUrl += "?platform=mobile";
            }
            request = {
                url: loginUrl,
                data: formData
            };
        }

        return this.httpClient.post(request.url, request.data)
            .pipe(
                tap((data: any) => {

                    if (_.isNil(data)) {
                        this.displayError(commandTextId, errorField);
                        throw new Error("Login failed");
                    }

                    const loginResult = data.authenticationMessage || "";
                    let resultError = data.errorMessage;
                    if (!isOAuth && !_.isEmpty(resultError)) {
                        const newLines = "\\\\n", newLinesR = new RegExp(newLines, "g");
                        resultError = resultError.replace(newLinesR, "\n");
                        alert(resultError);
                    }

                    if (!"AuthenticationSuccess".EqualsIgnoreCase(loginResult)) {
                        this.displayError(commandTextId, errorField);
                        throw new Error("Login failed");
                    }

                    IX_UserAuthenticated = true;

                    if (!_.isEmpty(data.internalSSOFormHTML)) {
                        const SSOTypeContent = data.internalSSOFormHTML.split(';');
                        if (SSOTypeContent.length === 2) {
                            if (SSOTypeContent[0] === "Type=Link") {
                                this.triggerSSO(SSOTypeContent[1], null, true, 1100, 648);
                                return;
                            }
                            else if (SSOTypeContent[0] === "Type=Form") {
                                this.triggerSSO(null, SSOTypeContent[1], true, 1100, 648);
                                return;
                            }
                        }
                    }

                    // Get each item we want to copy.
                    this.performPrimeCacheRequests(data.primingRequests);

                    if (data.requiresMFA) {

                        this.updateAntiForgeryToken();
                        this.deleteMFACookies(data.mfaPrefix); // remove old cookies
                        const mfaSettings = {
                            userName,
                            securityQuestion: data.securityQuestion,
                            disableRememberDevice: data.disableRememberDevice,
                            onCompleteMFA: () => this.redirectToReturnURL(targetURL)
                        };
                        iXing.MfaDialog.show(mfaSettings);
                        this.hideLoadingPanel();

                    } else {

                        if (data.requiresDisclaimer && !_.isNil(data.disclaimerText)) {
                            const callback = (result) => this.updateDisclaimer(targetURL, result);
                            const hasDisclaimerText = !_.isNil(data.disclaimerCancelButtonText) && !_.isEmpty(data.disclaimerCancelButtonText);
                            this.alertService.enhanceAlert(data.disclaimerText, hasDisclaimerText, callback, data.disclaimerAcceptButtonText, data.disclaimerCancelButtonText);
                        } else {
                            targetURL = _.isEmpty(targetURL) ? (_.isNil(data.overrideBaseHostURL) ? null : data.overrideBaseHostURL) : targetURL;
                            this.redirectToReturnURL(targetURL);
                        }
                    }
                 
                }),
                catchError((e) => {
                    this.displayError(commandTextId, errorField);
                    return throwError(e);
                })
            );
    }

    private getMFADeviceRef(userName) {
        userName = userName.toLowerCase();
        const cookieId = "IXMFADEVICEREF-" + (userName).IXhashCode() + '-' + (IX_Theme.themeName).IXhashCode();
        return this.getCookieValue(cookieId);
    }

    private triggerSSO(urlLink, ssoForm, redirectCondition, width, height) {

        const popupName = "IX_SSO_" + UtilityFunctions.generateUUID();
        const newWindow = redirectCondition ? window : window.open("", popupName, "scrollbars=yes,menubar=yes,height=" + height + ",width=" + width + ",resizable=yes,toolbar=yes,status=yes");

        if (_.isNil(newWindow)) {
            this.alertService.icAlert("POPUP-BLOCKED");
            return;
        }

        if (typeof urlLink != "undefined" && urlLink != null && urlLink != "") {
            newWindow.location.assign(urlLink);
        } else {
            //-- RT Commented Code on 04/02/2016 to resolve issues for IE11 browser inconsistencies
            if (ssoForm.startsWith("<form") && !ssoForm.endsWith("/></form>")) {
                ssoForm = ssoForm + "/></form>";
            }
            const actualText = this.getTranslation("ESTABLISHING-SECURE-CONNECTION");
            newWindow.document.writeln("<html><head></head><body style='color: #808080;font-family: Verdana;font-size: 10pt;margin:20px;'><span>" + actualText + '</span>' + ssoForm + '</body></html>');
            newWindow.document.forms[0].submit();
        }
    }

    private performPrimeCacheRequests(primingRequests) {
        if (primingRequests) {
            $(primingRequests)
                .each(function () {
                    $.ajax({ url: $(this).text(), type: "GET" });
                });
        }
        if (_.isArray(primingRequests))
            primingRequests.forEach((request) => $.ajax({ url: request, type: "GET" }));
    }

    private getCookieValue(name: string): string {
        name += "=";
        const cookies = document.cookie.split(";");
        let cookieVal;
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.indexOf(name) == 0)
                cookieVal = cookie.substring(name.length, cookie.length);
        }
        if (cookieVal == null)
            return null;
        return escape(cookieVal);
    }

    private deleteCookie(name: string): void {
        const days = -1;
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + expires + "; path=/";
    }

    private deleteMFACookies(mfaPrefix) {
        const pairs = document.cookie.split(";");
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split("=");
            const cookieName = (pair[0] + '').trim();
            if (cookieName.indexOf('IXMFADEVICEREF-' + mfaPrefix) >= 0)
                this.deleteCookie(cookieName);
        }
    }

    private updateDisclaimer(targetURL: string, result: any): void {
        const accepted = result ? "Y" : "N";
        const url = `/iXingPages/ecd.ashx?IX_LDCL=${accepted}`;
        this.httpClient.post(url, null)
            .pipe(
                tap(() => {
                    if (result)
                        this.redirectToReturnURL(targetURL);
                    else
                        this.appsConstantsFacade.redirectUserForLogout();
                })
            );
    }

    private redirectToReturnURL(targetURL: string) {
        let returnURL = targetURL || this.getQueryStringParameter("ReturnURL");
        // Quick and dirty client-side returnURL sanitization.
        returnURL = !returnURL.match(/(httpClient|\/\/)/g) ? returnURL : "";

        this.router.navigate(returnURL);
    }

    private updateAntiForgeryToken() {
        const newToken = this.getCookieValue('XSRF-TOKEN');
        return $("#ctl00_ix_csrf_token").val(newToken);
    }

    private getQueryStringParameter(name, includeHash?) {
        includeHash = _.isNil(includeHash) ? false : includeHash;
        name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
        const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
        const results = regex.exec(includeHash ? location.href : location.search);
        if (!includeHash && location.hash && !_.isNil(results[1])) {
            results[1] = results[1] + location.hash;
        }
        let parameterValue = results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        parameterValue = filterXSS(parameterValue, {
            whiteList: [],
            stripIgnoreTag: true,
            stripIgnoreTagBody: ["script"] // the script tag is a special case, we need to filter out its content
        });
        return parameterValue;
    }

}
