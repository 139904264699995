import * as common from './PWPCommon';
import * as login from './iXing.Login';
import conditionalFormatContext from './conditionalFormatContext';

export interface CustomWindow extends Window {
    iXing: any;
}
declare let window: CustomWindow;

Object.assign(window, login);
Object.assign(window, common);
Object.assign(window, conditionalFormatContext);

window.iXing = {
    ...window.iXing
};
