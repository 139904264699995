
import { Injectable } from "@angular/core";
import { DataSet } from "./dataset";
import { ArrayHelpers } from "./array-helpers";

@Injectable()
export class QryAggregateProfitAndLossLiteServiceRBC {
 
    _superScriptsMap = {};
    _factSetURLMaps = {};
    _tenantSettings = {};
    arrayHelpers = new ArrayHelpers();

    constructor() {
    }

    getSuperScriptsMap() {
        return this._superScriptsMap;
    }

    initPNLData(_qryProfitAndLossLiteData, objectPreferenceData) {
        const dataSet = new DataSet(_qryProfitAndLossLiteData);
        this.removeHiddenAccounts(dataSet, objectPreferenceData);
        this.addAccountSortOrder(dataSet, objectPreferenceData);
        this.mapSuperScriptFromUserDefinedData(dataSet);
        return dataSet.data();
    }

    mapSuperScriptFromUserDefinedData(dataSet) {

        dataSet.forEachRow((rowSet) => {
            const accountId = rowSet.get('AccountId');
            const enterpriseSymbol = rowSet.get('EnterpriseSymbol');
            const localCCY = rowSet.get('LocalCCY');
            let userDefined1 = rowSet.get('UserDefined1');
            let userDefined2 = rowSet.get('UserDefined2');

            if (userDefined1 === null) {
                userDefined1 = '';
            }
            if (userDefined2 === null) {
                userDefined2 = '';
            }

            const identifier = ['identifier', accountId, enterpriseSymbol].join('|');
            const identifierGrpByLocalCCY = ['identifier', accountId, enterpriseSymbol, localCCY].join('|');

            this._superScriptsMap[identifier] = this._superScriptsMap[identifier] || [];
            this._superScriptsMap[identifierGrpByLocalCCY] = this._superScriptsMap[identifierGrpByLocalCCY] || [];

            if (userDefined1.toUpperCase() === 'SE' || userDefined1.toUpperCase() === 'ME') {
                if (this._superScriptsMap[identifier].indexOf("1") === -1) {
                    this._superScriptsMap[identifier].push("1");
                }
                if (this._superScriptsMap[identifierGrpByLocalCCY].indexOf("1") === -1) {
                    this._superScriptsMap[identifierGrpByLocalCCY].push("1");
                }
            } else if ((userDefined1 === '' || userDefined1.toUpperCase() === 'RE' || userDefined1.toUpperCase() === 'XE') && userDefined2.toUpperCase() === '#') {
                if (this._superScriptsMap[identifier].indexOf("2") === -1) {
                    this._superScriptsMap[identifier].push("2");
                }
                if (this._superScriptsMap[identifierGrpByLocalCCY].indexOf("2") === -1) {
                    this._superScriptsMap[identifierGrpByLocalCCY].push("2");
                }
            }

        });

    }


    mapFactSetTypeToUrls() {
        this.addUrlMaps(this._tenantSettings['{Globals:TenantSetting:RBC.SnapQuoteEquityTypes|[InheritTenancy=true]}'], this._tenantSettings['{Globals:TenantSetting:RBC.SnapQuoteEquityUrl|[InheritTenancy=true]}']);
        this.addUrlMaps(this._tenantSettings['{Globals:TenantSetting:RBC.SnapQuoteETFTypes|[InheritTenancy=true]}'], this._tenantSettings['{Globals:TenantSetting:RBC.SnapQuoteETFUrl|[InheritTenancy=true]}']);
        this.addUrlMaps(this._tenantSettings['{Globals:TenantSetting:RBC.SnapQuoteMutualFundTypes|[InheritTenancy=true]}'], this._tenantSettings['{Globals:TenantSetting:RBC.SnapQuoteMutualFundUrl|[InheritTenancy=true]}']);
        this.addUrlMaps(this._tenantSettings['{Globals:TenantSetting:RBC.SnapQuoteIgnoreTypes|[InheritTenancy=true]}'], null);
        return this._factSetURLMaps;
    }

    private addUrlMaps(values, url) {
        if (values) {
            values.split(',').forEach((val) => {
                this._factSetURLMaps[val] = url;
            });
        }
    }

    setTenantSettings(res) {
        this._tenantSettings = res;
    }

    getTenantSettings() {
        return this._tenantSettings;
    }

    addAccountSortOrder(dataSet, objectPreferenceData) {

        // Get index here instead of using ColumnMap and rowSet for performance
        const accountIdIndex = objectPreferenceData.length ? objectPreferenceData[0].indexOf('AccountId') : -1;
        const sortOrderIndex = objectPreferenceData.length ? objectPreferenceData[0].indexOf('SortOrder') : -1;

        const accountSortOrders = {};
        objectPreferenceData.map((item) => {
            accountSortOrders[item[accountIdIndex]] = item[sortOrderIndex];
        });

        dataSet.forEachRow((rowSet) => {
            rowSet.set('SortOrder', accountSortOrders[rowSet.get('AccountId')]);
        });

        const pnlData = dataSet.data();
        const columnMap = new DataSet(pnlData).ColumnMap();
        const headerRow = pnlData.shift();
        this.arrayHelpers.MultiColumnSort(pnlData, columnMap, ['SortOrder', 'AccountShortName'], ['ASC', 'ASC'], true);
        pnlData.unshift(headerRow);

        dataSet.setData(pnlData);
    }

    removeHiddenAccounts(dataSet, objectPreferenceData) {

        const pnlData = dataSet.data();
        const pnlAccountIdIndex = pnlData.length ? pnlData[0].indexOf('AccountId') : -1;

        const accountIdIndex = objectPreferenceData.length ? objectPreferenceData[0].indexOf('AccountId') : -1;
        const hiddenIndex = objectPreferenceData.length ? objectPreferenceData[0].indexOf('Hidden') : -1;

        const hiddenAccounts = objectPreferenceData
            .filter(item => item[hiddenIndex] === 'Y')
            .map(item => item[accountIdIndex]);

        const filteredData = pnlData.filter((item) => {
            return hiddenAccounts.indexOf(item[pnlAccountIdIndex]) === -1;
        });

        dataSet.setData(filteredData);
    }

}
