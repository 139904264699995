/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import dxDataGrid, { dxDataGridColumn } from 'devextreme/ui/data_grid';
import dxDateBox from 'devextreme/ui/date_box';
import dxPopup from 'devextreme/ui/popup';
import { trim } from 'lodash';
import { TextAnnouncer } from '../commands/text-announcer.service';
import { TranslateFacadeService } from './translate-facade.service';

type cssUnit = 'px' | 'em' | 'rem' | '%' | 'vw';

@Injectable()
export class AccessibilityService {


    constructor(private translate: TranslateFacadeService,
        private textAnnouncer: TextAnnouncer) { }

    ariaIdCount = 0;
    svc = {
        events: {
            ACCESSIBILITY_ADDED_TO_CALENDAR: "accessibilityAddedToCalendar",
            ACCESSIBILITY_ADDED_TO_ROW_FILTER_MENU: "accessibilityAddedToRowFilterMenu"
        }
    }

    addAccessibilityToDxDataGridOnInitialized(e) {
        e.component
            .getController("data")
            .loadingChanged
            .add((isLoading) => {
                if (isLoading) {
                    this.addAccessibilityOnLoadingStart(e.component);
                }
                else {
                    this.addAccessibilityOnLoadingComplete(e.component);
                }
            });
        this.addAccessibilityOnLoadingStart(e.component);
    }

    addAccessibilityOnLoadingStart(instance) {
        this.announceLoadingText(instance.option("loadingStartText"));
    }

    addAccessibilityOnLoadingComplete(instance) {
        this.announceLoadingText(instance.option("loadingEndText"));
    }

    announceLoadingText(text) {
        if (!_.isEmpty(text)) {
            this.textAnnouncer.announce({ text: text });
        }
    }

    columnChooserTreeViewOnContentReady(e) {
        setTimeout(() => this.correctColumnChooserAriaAttributes(e.element));
        this.announceColumnChooserVisibleColumns(e.component);
    }

    correctColumnChooserAriaAttributes(treeViewElement) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        treeViewElement.find("li").first().closest("ul").attr("role", "list");
        treeViewElement.find("li").attr("role", "listitem");
        treeViewElement.find(".dx-scrollable").removeAttr("tabindex");

        // VSTS 106436 add aria-label to search form inside column chooser
        const searchBoxTranslation = this.getTranslation("Search Box");
        treeViewElement.find(".dx-texteditor-input").attr("aria-label", !searchBoxTranslation ? "Search Box" : searchBoxTranslation);

        // VSTS 106429 allow toggling of checkboxes
        const dxCheckboxSelector = treeViewElement.find(".dx-checkbox");
        treeViewElement.find(dxCheckboxSelector).attr("tabindex", "0");
        dxCheckboxSelector.each((_index: number, checkBox: HTMLElement) => {
            const dxCheckboxButton = $(checkBox);
            this.handleEnterKeyForAccessibility(dxCheckboxButton, false);
        });

        treeViewElement.find("li").each(function () {
            that.addColumnChooserCheckboxAria($(this));
        });
    }


    announceColumnChooserVisibleColumns(treeView) {
        const visibleItemsArray = treeView._getVisibleItems(),
            visibleCount = visibleItemsArray.length;
        let checkedCount = 0;

        for (let i = 0; i < visibleItemsArray.length; i++) {
            const current = $(visibleItemsArray[i]);

            checkedCount += current.parent().find("[aria-checked=true]").length;
        }

        const visibleColumnsTranslation = this.getTranslation("visible columns"),
            checkedColumnsTranslation = this.getTranslation("checked.");

        const announceText = visibleCount + " " + visibleColumnsTranslation + " " + checkedCount + " " + checkedColumnsTranslation;

        this.textAnnouncer.announce({
            text: announceText
        });
    }

    addAccessibilityToDxDateBoxOnFocusOut(e) {
        const isValid = e.component.option("isValid");
        if (!isValid) {
            const invalidText = this.getTranslation("IC-INVALID-DATE", "Invalid date");

            setTimeout(() => {
                this.textAnnouncer.announce({
                    text: invalidText
                });
            });
        }
    }

    correctCalendarTableAriaRoles(tableContainer) {
        const allCells = tableContainer.find("td[role=option]");
        allCells.each(function () {
            const current = $(this);
            current.attr("role", "button");
        });

        const allOtherMonthDays = tableContainer.find("td.dx-calendar-other-view");
        allOtherMonthDays.each(function () {
            const current = $(this);
            current.attr("aria-disabled", "true")
        });

        const today = tableContainer.find("td.dx-calendar-today");
        today.removeAttr("aria-disabled");
        today.attr("aria-current", "date");

        const calendarBox = tableContainer.find(".dx-calendar");
        calendarBox.attr("role", "group");
    }

    async addKeyBoardInstructionsToCalendar(popupContent: any, keyboardInstructions: string) {
        if (popupContent.find(".ic-calendar-keyboard-instructions").length > 0 || _.isEmpty(keyboardInstructions))
            return;

        const keyboardInstructionsDiv = $("<div></div>");

        keyboardInstructions = await this.getTranslation("IC-CALENDAR-KEYBOARD-INSTRUCTIONS", keyboardInstructions);
        keyboardInstructionsDiv.text(keyboardInstructions);
        keyboardInstructionsDiv.addClass("ic-calendar-keyboard-instructions");

        const contentContainer = popupContent.find(".dx-popup-content");
        contentContainer.prepend(keyboardInstructionsDiv);
    }

    addAccessibilityToDxDateBoxOnOpened(e: { component: dxDateBox, element: HTMLElement }) {
        if (_.get(e, "component._$popup.dxPopup") == null)
            return;

        const popup: dxPopup = e.component['_$popup'].dxPopup("instance");
        const keyboardInstructions: string = e.component.option("keyboardInstructions");
        const popupContent: any = popup['_$content'];

        this.addKeyBoardInstructionsToCalendar(popupContent, keyboardInstructions);
        this.correctCalendarTableAriaRoles(popupContent);

        $(e.element).trigger(this.svc.events.ACCESSIBILITY_ADDED_TO_CALENDAR);
    }

    addAriaLabelFromOption(instance, optionName, applyAriaToSelector?) {
        const element = instance.$element(),
            input = _.isEmpty(applyAriaToSelector) ? element : element.find(applyAriaToSelector);
        if (input.length == 0)
            throw new Error("Invalid selector to apply the aria label");

        const ariaLabel = instance.option(optionName);
        if (!_.isEmpty(ariaLabel)) {
            input.attr("aria-label", ariaLabel);
            return true;
        }

        return false;
    }

    getOrDefineIdForElement(element) {
        if (element.length != 1)
            throw new Error("Invalid selector, found " + element.length + " elements.");

        const currentId = element.attr("id");

        if (_.isEmpty(currentId)) {
            this.ariaIdCount += 1;
            const newId = "ic_aria_" + this.ariaIdCount;
            element.attr("id", newId);
            return newId;
        } else {
            return currentId;
        }
    }

    warnIfInvalidAriaLabel(instance, selector?) {
        if (instance == null)
            throw new Error("A valid DevExtreme instance is required.");

        let valid = false,
            label = "";
        const element = this.getElementOrSelector(instance.$element(), selector),
            ariaAttributes = ["aria-label", "aria-labelledby", "aria-describedby"];

        for (const attr in ariaAttributes) {
            label = element.attr(attr);
            if (!_.isEmpty(label) && label.replace(/ /g, "").length > 0) {
                valid = true;
                break;
            }
        }

        if (!valid) {
            let translationId = instance.option("translationId"),
                fieldName = instance.option("fieldName"),
                appName = instance.option("appName");

            if (_.isUndefined(translationId))
                translationId = "";
            if (_.isUndefined(fieldName))
                fieldName = "";
            if (_.isUndefined(appName))
                appName = "";

            //console.warn("[P-Tier] AriaLabel is invalid.", translationId, fieldName, appName);
        }
    }

    getTranslation(translationId, defaultIfNoTranslation?): string {
        let translation = this.translate.getTranslation(translationId, defaultIfNoTranslation);
        if (translation === translationId) {
            //console.warn("[P-Tier] Missing translation for ", translationId);
            if (!_.isEmpty(defaultIfNoTranslation))
                translation = defaultIfNoTranslation;
        }
        return translation;
    }

    addAriaLabelledBy(inputElement, containerElement) {
        if (inputElement.length == 0)
            throw new Error("Invalid selector for input element");
        if (containerElement.length == 0)
            return false;

        const parent = containerElement;
        const label = parent.find("label");
        if (label.length > 0) {
            const labelId = this.getOrDefineIdForElement(label);

            inputElement.attr("aria-labelledby", labelId);
            return true;
        }

        return false;
    }

    addAriaLabelFromTranslation(translationId, element, selector, defaultIfNoTranslation?, throwIfNotFound?) {
        if (throwIfNotFound == null)
            throwIfNotFound = false;

        const $element = this.getElementOrSelector(element, selector, throwIfNotFound);
        let ariaLabel = this.getTranslation(translationId);
        if (ariaLabel == translationId) {
            //console.warn("[P-Tier] Missing translation for ", translationId);
            if (!_.isEmpty(defaultIfNoTranslation))
                ariaLabel = defaultIfNoTranslation;
        }
        $element.each(function () {
            const current = $(this);
            current.attr("aria-label", ariaLabel);
        });
    }

    getElementOrSelector(element, selector, throwIfNotFound?) {
        const result = _.isEmpty(selector) ? element : element.find(selector);
        if (result.length == 0 && throwIfNotFound)
            throw new Error("Invalid selector to test");

        return result;
    }

    addAriaLabelToSelector(container, selector, ariaLabel) {
        container.find(selector).each(function () {
            const current = $(this);
            current.attr("aria-label", ariaLabel);
        });
    }

    addAccessibilityToRowFilterMenu(e) {
        this.correctFilterMenuAria(e);
        this.subscribeToFilterMenuEvents(e);
        this.addKeyboardInstructionsToCalendars(e);

        e.element.trigger(this.svc.events.ACCESSIBILITY_ADDED_TO_ROW_FILTER_MENU);
    }

    addKeyboardInstructionsToCalendars(e) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        e.element.find(".dx-datagrid-filter-row .dx-datebox").each(function () {
            const current = $(this),
                fieldName = current.closest("td").attr("data-field"),
                column = this.getColumnConfigurationFromDxDataGridInstance(e.component, fieldName);

            if (column == null)
                return;

            const dateBox = current.data("dxDateBox"),
                keyboardInstructions = column.keyboardInstructions;

            if (_.isEmpty(keyboardInstructions))
                return;

            dateBox.option("keyboardInstructions", keyboardInstructions);
            if (dateBox != null) {
                dateBox.off("opened", that.datePickerOnOpenedEventHandler.bind(this));
                dateBox.on("opened", that.datePickerOnOpenedEventHandler.bind(this));
            }
        });
    }

    getColumnConfigurationFromDxDataGridInstance(instance, fieldName) {
        return _.find(instance.getVisibleColumns(), (column) => {
            return column.dataField == fieldName;
        });
    }

    datePickerOnOpenedEventHandler(e) {
        this.addAccessibilityToDxDateBoxOnOpened(e);
    }

    subscribeToFilterMenuEvents(e) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        e.element.find(".dx-datagrid-filter-row .dx-menu").each(function () {
            const current = $(this);
            const menuInstance = current.dxMenu("instance");

            // Unsubscribe
            menuInstance.off("submenuShown", that.filterMenuOnSubmenuShown.bind(this));
            menuInstance.off("submenuHidden", that.filterMenuOnSubmenuHidden.bind(this));
            menuInstance.off("itemRendered", that.filterMenuOnItemRendered.bind(this));
            menuInstance.off("contentReady", that.filterMenuOnContentReady.bind(this));
            // Subscribe
            menuInstance.on("submenuShown", that.filterMenuOnSubmenuShown.bind(this));
            menuInstance.on("submenuHidden", that.filterMenuOnSubmenuHidden.bind(this));
            menuInstance.on("itemRendered", that.filterMenuOnItemRendered.bind(this));
            menuInstance.on("contentReady", that.filterMenuOnContentReady.bind(this));

            that.addAccessibilityToMenuDefault(menuInstance);
        });
    }

    correctFilterMenuAria(e) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        e.element.find(".dx-datagrid-filter-row .dx-menu").each(function () {
            const current = $(this);
            that.correctFilterMenuOnContentReady(current);
        });
    }

    correctFilterMenuOnContentReady(element) {
        const columnCaption = element.closest("td").attr("data-caption"),
            rowFilterCriteria = this.getTranslation(columnCaption + " filter criteria"),
            criteriaOptions = this.getTranslation("IC_CRITERIA_OPTION", "criteria options");

        element.attr("aria-label", rowFilterCriteria);
        element.find("ul").attr("role", "none");
        element.find("li").attr("role", "none");
        element.attr("role", "menubar");
        element.find("[role=menuitem]").attr("aria-label", criteriaOptions);
    }

    filterMenuOnSubmenuShown(e) {
        const ariaOwnsTarget = e.rootItem.find("[aria-owns]").attr("aria-owns");
        const selectedItem = $("#" + ariaOwnsTarget).find(".dx-menu-item-selected");
        selectedItem.attr("aria-current", "true");
        this.addAccessibilityToMenuOnShown(e);
    }

    filterMenuOnSubmenuHidden(e) {
        this.addAccessibilityToMenuOnHidden(e);
    }

    filterMenuOnItemRendered(e) {
        this.addAccessibilityToMenuOnItemRendered(e);
    }

    filterMenuOnContentReady(e) {
        this.addAccessibilityToMenuDefault(e.component);
        this.correctFilterMenuOnContentReady(e.element);
    }

    closeMenuOnEscapeEventHandler(e) {
        if (e.keyCode == 27) {
            const activeElement = $(window.document.activeElement),
                menuInstance = activeElement.data("menu-instance");

            if (menuInstance != null && menuInstance._visibleSubmenu != null) {
                menuInstance._visibleSubmenu.hide();
            }

            activeElement.off("keyup", this.closeMenuOnEscapeEventHandler.bind(this));
        }
    }

    closeMenuOnEscape(menuInstance) {
        const activeElement = $(window.document.activeElement);

        activeElement.data("menu-instance", menuInstance);
        activeElement.off("keyup", this.closeMenuOnEscapeEventHandler.bind(this));
        activeElement.on("keyup", this.closeMenuOnEscapeEventHandler.bind(this));
    }

    setAriaLabelForFilterRowSearchInputs(element) {
        const searchInputSelector = element.find(".dx-datagrid-filter-row .dx-texteditor-input");
        searchInputSelector.each((index, item) => {
            const current = $(item),
                caption = current.closest("td").attr("data-caption"),
                ariaLabel = this.getTranslation("filter by " + caption);

            current.attr("aria-label", ariaLabel);
        });
    }

    addAccessibilityToDxDataGridRowHeader(e) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        const filterRowIsEnabled = e.element.find(".dx-datagrid-filter-row, .dx-treelist-filter-row").length > 0;
        if (!filterRowIsEnabled)
            throw new Error("The datagrid does not have a filter row.");

        that.setAriaLabelForFilterRowSearchInputs(e.element)

        $(e.element)
            .find(".dx-datebox-calendar")
            .each(function () {
                const current = $(this);

                that.addAriaLabelFromTranslation(
                    "IC_DATAGRID_FILTER_ROW_CALENDAR_BUTTON",
                    current.parent(),
                    "[role='button']",
                    "Open calendar"
                );
            });

        e.element.find("[aria-valuemax='undefined']").removeAttr("aria-valuemax");
        e.element.find("[aria-valuemin='undefined']").removeAttr("aria-valuemin");
    }

    markCurrentItemAsSelected(treeViewElement) {
        // This will be one single element, needed for activedescendant, but will
        // not suffice to set the aria-selected property and show an appropriate focus-indicator.
        const currentItem = treeViewElement.find("li.dx-state-focused");

        if (currentItem.length > 0) {
            const focusedId = this.getOrDefineIdForElement(currentItem);
            treeViewElement.attr("aria-activedescendant", focusedId);
            treeViewElement.find("[role=treeitem]").attr("aria-selected", "false");
            treeViewElement.find("li.dx-treeview-node-is-leaf").removeAttr("aria-expanded");
        }

        // This works for aria-selected, but will select multiple elements when p-tier has two menu
        // items with the same URL.
        const selectedItem = treeViewElement.find("li.ic-treeview-node-selected");

        if (selectedItem.length > 0) {
            selectedItem.attr("aria-selected", "true");
            selectedItem.find("[aria-selected]").removeAttr("aria-selected");
        }
    }

    addAccessibilityToTreeViewOnFocusOut(instance) {
        const element = instance.$element();
        element.removeAttr("aria-activedescendant");
    }

    addAccessibilityToTreeViewOnFocusChange(instance) {
        this.markCurrentItemAsSelected(instance.$element());
    }

    addAccessibilityToMenuOnActiveDescendant(instance) {
        this.fixFocusedElementActiveDescendant(instance);

        this.removeInvalidAriaReferencesRecursively(
            instance.$element(),
            ["aria-activedescendant", "aria-owns"]
        );
    }

    addAccessibilityToMenuDefault(component) {
        const element = component.$element();
        const useTreeView = component.NAME === "dxTreeView";

        this.addAriaLabelFromOption(component, "ariaLabel");
        this.removeInvalidAriaReferencesRecursively(element, ["aria-activedescendant", "aria-owns"]);

        element.removeAttr("role");
        element.find("ul").attr("role", useTreeView ? "tree" : "menubar")
    }

    addAccessibilityToTabsOnSelected(e) {
        setTimeout(
            () => {
                e.element.find(".dx-tab.dx-tab-selected").trigger("dxclick");
                this.correctActiveTabSelectedAria(e.element);
                this.prepareTabIndexesForTabFocusEvents(e);
            }, 500 // Needed by NVDA
        );
    }

    selectNextTab(innerElementSelector, direction) {
        const instance = innerElementSelector.parents("dx-tab-panel").dxTabPanel("instance");
        let selectedIndex = instance.option("selectedIndex");
        switch (direction) {
            case "left":
                if (selectedIndex > 0) {
                    selectedIndex -= 1;
                }
                else {
                    return;
                }

                break;
            case "right": {
                const items = instance.option("items");
                const itemCount = items.length;
                if (selectedIndex < (itemCount - 1)) {
                    selectedIndex += 1;
                }
                else {
                    return;
                }
                break;
            }
        }

        instance.option("selectedIndex", selectedIndex);
        this.correctActiveTabSelectedAria(instance.$element());


        setTimeout(() => {
            this.announceTabAfterSelection(instance.$element());
        }, 500); // Needed by NVDA
    }

    announceTabAfterSelection(element) {
        const tabContainer = $(element.find(".dx-tabs"));
        tabContainer.find("[role='tab'][aria-selected='true']").focus();

        setTimeout(() => {
            tabContainer.focus();
        }, 100); // Needed by NVDA
    }

    tabContainerTabKeyDownEventHandler(ev) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        const element = ev.target;
        ev.preventDefault();
        switch (ev.keyCode) {
            case 9:
                if (ev.shiftKey) {
                    $(element).prev("[tabindex]").focus();
                }
                else {
                    const panelId = $(element).find("[role=tab][aria-selected='true']").attr("aria-controls");
                    const panel = $("#" + panelId);
                    panel.attr("tabindex", "-1");
                    panel.focus();
                }
                break;
            case 37:
                that.selectNextTab($(element), "left");
                break;
            case 39:
                that.selectNextTab($(element), "right");
                break;
        }
    }

    tabFocusEventHandler(ev) {
        const tabsContainer = $(ev.target).closest(".dx-tabs");

        setTimeout(() => {
            tabsContainer.focus();
        }, 500);
    }

    prepareTabIndexesForTabFocusEvents(e) {
        const tabsContainer = e.element.find(".dx-tabs");
        tabsContainer.attr("tabindex", "-1");
        tabsContainer.find("[role='button']").attr("aria-hidden", "true");

        tabsContainer.off("keydown");
        tabsContainer.on("keydown", this.tabContainerTabKeyDownEventHandler.bind(this));

        e.element.find("[role='tab']").off("focus", this.tabFocusEventHandler.bind(this));
        e.element.find(".dx-tab.dx-tab-selected").attr("tabindex", "0");
        e.element.find(".dx-tab.dx-tab-selected").on("focus", this.tabFocusEventHandler.bind(this));
        e.element.find("[role='tab'][aria-selected='false']").attr("tabindex", "-1");
    }

    correctActiveTabSelectedAria(element) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        const tabs = element.find(".dx-tab");

        tabs.each(function () {
            const tab = $(this);
            tab.removeAttr("id");
            that.getOrDefineIdForElement(tab);
        });

        const tabPanel = element.find("[role='tabpanel'].dx-item-selected");
        if (tabPanel.length === 1) {
            tabPanel.removeAttr("id");
            const tabPanelId = that.getOrDefineIdForElement(tabPanel);
            tabs.each(function () {
                const tab = $(this);
                tab.attr("aria-controls", tabPanelId);
            });
        }
        else {
            tabPanel.each(function () {
                const panel = $(this);
                panel.removeAttr("id");
                const tabPanelId = that.getOrDefineIdForElement(panel);
                tabs.each(function () {
                    const tab = $(this);
                    tab.attr("aria-controls", tabPanelId);
                });
            });
        }

        const activeTab = element.find("[role='tab'][aria-selected='true']");
        if (activeTab.length === 1) {
            const activeTabId = that.getOrDefineIdForElement(activeTab);
            tabPanel.attr("aria-labelledby", activeTabId);
        }
        else {
            activeTab.each(function () {
                const currentActiveTab = $(this);
                currentActiveTab.removeAttr("id");
                const activeTabId = that.getOrDefineIdForElement(currentActiveTab);
            })
            tabPanel.removeAttr("aria-labelledby");
        }
    }

    addAccessibilityToTabs(e) {
        this.prepareTabIndexesForTabFocusEvents(e);
        this.correctActiveTabSelectedAria(e.element);
    }

    addColumnChooserCheckboxAria(element) {
        element.find('.dx-treeview-item').attr('aria-hidden', true);
        this.addAriaLabelFromTranslation("ColumnChooser.CheckBoxAriaLabel", element, "[role='checkbox']", "Add Column - " + element[0].innerText);
    }

    addAccessibilityToSimpleList(e, scope) {
        e.element = $(e.element);
        e.itemElement = $(e.itemElement);

        const ariaLabel = _.get(scope, "config.ariaLabel", "");
        if (!_.isEmpty(ariaLabel)) {
            e.element.attr("aria-label", ariaLabel);
        }
        e.element.attr("role", "list");
        e.itemElement.attr("role", "listitem");
    }

    fixMenuElementAriaLabelWithParent(menu) {
        let ariaLabel = menu.closest("[aria-label]").attr("aria-label");
        if (_.isEmpty(ariaLabel)) {
            ariaLabel = "User Options";
        }
        menu.attr("aria-label", ariaLabel);
    }

    addAccessibilityToMenuOnItemClick(e) {
        if (e.component == null)
            return;

        e.component.option("focusedElement", e.itemElement);
        e.element.focus();
        e.element.find("[aria-current]").removeAttr("aria-current");
        e.itemElement.attr("aria-current", "page");
    }

    addAccessibilityToMenuOnShown(e) {
        const menuElement = e.component.$element(),
            items = menuElement.find("[data-has-menu-items]").data("menu-items");

        if (items && items.length > 0) {
            const item = $(items[0]),
                menuContainer = item.closest("[role='menu']");

            const id = this.getOrDefineIdForElement(menuContainer);
            menuElement.find("[aria-owns]").removeAttr("aria-owns");
            menuElement.attr("aria-owns", id);
            this.fixMenuElementAriaLabelWithParent(menuContainer);

            item.closest("ul:not([role='menu'])").attr("role", "none");
        }

        if (e.component._visibleSubmenu != null) {
            const subMenuContainerId = e.component._visibleSubmenu.$element().attr("aria-owns"),
                subMenuContainer = $("#" + subMenuContainerId);
            subMenuContainer.find("ul").attr("role", "menu");
            subMenuContainer.find("li").attr("role", "none");
        }

        this.removeInvalidAriaReferencesRecursively(menuElement);
        this.fixFocusedElementActiveDescendant(e.component);
        this.closeMenuOnEscape(e.component);
    }

    fixFocusedElementActiveDescendant(instance) {
        let focusedElement = instance.option("focusedElement"),
            menuElement = instance.element();
        menuElement = $(menuElement);

        if (focusedElement != null) {
            focusedElement = $(focusedElement);
            const focusedElementId = this.getOrDefineIdForElement(focusedElement);

            menuElement.attr("aria-activedescendant", focusedElementId);
        }
        else {
            menuElement.removeAttr("aria-activedescendant");
        }
        // No other children should have active descendants.
        menuElement.find("[aria-activedescendant]").removeAttr("aria-activedescendant");

        // No aria-activedescendant should be present in the popup that contains the menu options.
        const ariaOwns = menuElement.attr("aria-owns");
        if (ariaOwns != null) {
            const ariaOwnsSelector = $("#" + ariaOwns);

            this.removeInvalidAriaReferences(ariaOwnsSelector, "aria-activedescendant");
        }
    }

    addAccessibilityToMenuOnItemRendered(e) {
        const menuItem = e.itemElement,
            hasSubItems = ((Array.isArray(e.itemData.subMenu) && e.itemData.length) || (Array.isArray(e.itemData.items) && e.itemData.items.length > 0));

        if (menuItem.attr("aria-expanded") == null && hasSubItems) {
            menuItem.attr("aria-expanded", "false");
        }
        const menuItemAriaLabel = e.itemElement.text();
        menuItem.attr("aria-label", menuItemAriaLabel);
    }

    addAccessibilityToMenuOnHidden(e) {
        const menuElement = e.element;

        e.component.repaint();
        const visibleUL = menuElement.find("ul:visible");

        if (visibleUL.length > 0 && visibleUL.parents("[role='menu']").length == 0) {
            visibleUL.attr("role", "menu");
            this.fixMenuElementAriaLabelWithParent(visibleUL);
        }

        setTimeout(() => {
            this.removeInvalidAriaReferencesRecursively($("body"));

            if ($(window.document.activeElement).attr("tabindex") != "0") {
                e.element.focus();
            }
        }, 500); // Wait needed by NVDA.
    }

    removeInvalidAriaReferencesRecursively(element, attributes?) {
        if (attributes == null)
            attributes = ["aria-activedescendant", "aria-owns"];

        for (let i = 0; i < attributes.length; i++) {
            const attribute = attributes[i];

            this.removeInvalidAriaReferences(element, attribute);

            element.find("[" + attribute + "]").each((index, item) => {
                const current = $(item);
                this.removeInvalidAriaReferences(current, attribute);
            });
        }
    }

    removeInvalidAriaReferences(element, attribute) {
        const pointedId = element.attr(attribute);
        if (pointedId == null)
            return;

        const notFoundInPage = $("#" + pointedId).length == 0;
        if (notFoundInPage) {
            element.removeAttr(attribute);
        }
    }

    addAccessibilityToDxDataGridGroupingProperties(gridProperties) {
        function getButtonIndex(e) {
            const currentGroupButton = window.document.activeElement;
            const currentGroupButtonIndex = e.element.find("[role=button]").index(currentGroupButton);
            return currentGroupButtonIndex;
        }

        function focusButtonByIndex(index, e) {
            const currentGroupButton = $(e.element.find("[role=button]")[index]);
            setTimeout(() => {
                currentGroupButton.focus();
            }, 0);
        }

        gridProperties.onRowExpanding = (e) => {
            e.component.option("ic.lastExpandedIndex", getButtonIndex(e));
        }
        gridProperties.onRowCollapsing = (e) => {
            e.component.option("ic.lastCollapsedIndex", getButtonIndex(e));
        }
        gridProperties.onRowExpanded = (e) => {
            focusButtonByIndex(e.component.option("ic.lastExpandedIndex"), e);
        };
        gridProperties.onRowCollapsed = (e) => {
            focusButtonByIndex(e.component.option("ic.lastCollapsedIndex"), e);
        };
        gridProperties.onOptionChanged = (args) => {
            if (args.name === "columns") {
                if (args.fullName.endsWith("filterValue")) {
                    let dataGridRefreshedFromFilterText = "table filtered";
                    dataGridRefreshedFromFilterText = this.getTranslation("DATAGRID-FILTER-ANNOUNCETEXT", dataGridRefreshedFromFilterText);
                    this.textAnnouncer.announce({
                        text: dataGridRefreshedFromFilterText
                    });
                }
            }
        };
    }

    addAccessibilityToDxDataGridRenderCell(view, $row, $cell, options, viewType) {
        // Put calculated column id (if any) in the generated cell
        if (options.column.colId) {
            if (viewType === 'rows') {
                const $existingId = $('#' + options.column.colId, $row);
                if ($existingId.length > 0) {
                    $existingId.attr('id', options.column.colId + '_group');
                }
            }

            $cell.attr('id', options.column.colId);
        }

        // hide the fixed columns that are in the scrollable table from the screen reader
        const fixed = view._isFixedTableRendering;
        if (!fixed && options.column.fixed) {
            $cell.attr('aria-hidden', 'true');
        }

        return $cell;
    }

    addAccessibilityToDxDataGridRenderCells(
        view: Record<string, any>,
        $row: any, //TODO: Fix JQuery typings
        options: Record<string, any>,
        viewType: 'columnheaders' | 'rows' | 'footer',
        getUniqueId: () => unknown
    ): void {
        let ariaChild = '';
        let grid, gridNumber;
        let isFixedTableRendering;
        let fieldName;
        let gridHasFixedColumns = false;
        const gridScope = view.option('gridScope');
        if (gridScope && options.columns && options.columns.length) {
            grid = gridScope.gridInstance;

            gridHasFixedColumns = grid?.option('columnFixing.enabled');
            isFixedTableRendering = gridHasFixedColumns && view._isFixedTableRendering;
            fieldName = 'columnHeader' + (isFixedTableRendering ? 'Fixed' : 'Scrollable');

            if (gridHasFixedColumns) {
                // Get a unique ID for the grid
                gridNumber = grid.option('uniqueGridNumber');
                if (!gridNumber) {
                    gridNumber = getUniqueId();
                    grid.option('uniqueGridNumber', gridNumber);
                }

                let rowId: string;
                if (viewType === 'footer') {
                    rowId = 'ic-cell-g' + gridNumber + 'frc';
                } else if (viewType === 'columnheaders') {
                    rowId = 'ic-cell-g' + gridNumber + 'hr' + options.row.rowIndex + 'c';
                } else { // viewType === 'rows'
                    rowId = 'ic-cell-g' + gridNumber + 'dr' + options.row.rowIndex + 'c';
                }

                const iteratee = (colIdx: number) => {
                    if (isFixedTableRendering) {
                        // Get list of possible cell ids for the aria-owns tag
                        if (viewType === 'rows' && colIdx < 2) {
                            // in case we run into a group
                            ariaChild += rowId + colIdx + '_group ';
                        }
                    }

                    if (viewType === 'rows') {
                        // Configure header ids (for aria-describedby) and column ids for aria
                        const headerId = gridScope[fieldName] && gridScope[fieldName][options.columns[colIdx].index];
                        if (headerId) {
                            options.columns[colIdx].headerId = headerId;
                        }
                    }
                    if (!isFixedTableRendering) {
                        options.columns[colIdx].colId = rowId + colIdx;
                    } else {
                        options.columns[colIdx].colId = undefined;
                    }
                };

                if ((!gridScope.colCount && viewType === 'rows') || viewType !== 'rows') {
                    gridScope.colCount = (options.columns as Record<string, any>[])
                        .map((col, colIdx) => {
                            iteratee(colIdx);
                            return col.colspan;
                        })
                        .reduce((prev, cur) => {
                            // Get table column count
                            // each data column counts as colspan, or 1 if no colspan
                            return (prev || 1) + (cur || 1);
                        });
                } else {
                    (options.columns as Record<string, any>[]).map((col, colIdx) => iteratee(colIdx));
                }

                if (isFixedTableRendering) {
                    // Get list of possible cell ids for the aria-owns tag
                    for (let colIdx = 0; colIdx < gridScope.colCount; colIdx++) {
                        ariaChild += rowId + colIdx + ' ';
                    }
                }
            }
        }

        view.callBase.call(view, $row, options);

        // Overriding to avoid aria-selected=false on cells, which is not appropriate
        // for our implementation of dxDataGrid
        $row.find('td').attr('aria-selected', null);

        if (gridHasFixedColumns) {
            if (viewType === 'rows') {
                // Detail rows are not rendered through the _renderCell above, so we need to configure cell ids the messier way
                if (options.row.rowType === 'detail' && isFixedTableRendering === false) {
                    const $cols = $row.find('td');
                    let colIdx;
                    //TODO: Move this .id assignment inside existing loop
                    for (colIdx = 0; colIdx < $cols.length; colIdx++) {
                        $cols[colIdx].id = 'ic-cell-g' + gridNumber + 'dr' + options.row.rowIndex + 'c' + colIdx;
                    }
                }

                if (options.row.rowType === 'group' && fieldName) {
                    // assign describedby to group columns
                    let tdIdx;
                    let colIdx = 0;
                    const columns = $row.find('td');
                    for (tdIdx = 0; tdIdx < columns.length; tdIdx++) {
                        const headerId = gridScope[fieldName] && options.columns[colIdx] && gridScope[fieldName][options.columns[colIdx].index];
                        //TODO: Move this inside existing loop
                        if (headerId) {
                            $(columns[tdIdx]).attr('aria-describedby', headerId);
                        }
                        colIdx += columns[tdIdx].colSpan ? columns[tdIdx].colSpan : 1;
                    }
                }

                if (options.row.rowType === 'group') {
                    if (!isFixedTableRendering) {
                        // prevent from cycling through hidden button and headings then through table
                        const firstCell = $row.find('td:eq(0).dx-command-expand')
                        if (firstCell) firstCell.attr('aria-hidden', 'true').css('visibility', 'hidden');
                        $row.attr('tabindex', null);
                        $row.find('td').attr('aria-colindex', null);
                        $row.find('div.dx-datagrid-group-opened').attr('tabindex', '-1');
                    }
                }
            }

            if (isFixedTableRendering) {
                // fixed table rows own scrollable table columns for aria navigation purposes
                $row.attr('aria-owns', ariaChild);
                $row.find('div.dx-datagrid-group-opened').attr('tabindex', '-1');
                // prevent from reading blank columns
                const lastCell = $row.find('.dx-last-cell').attr('aria-hidden', 'true');
                if (lastCell.length === 0) {
                    $('<td aria-hidden="true" class="dx-pointer-events-none dx-last-cell">&nbsp;</td>').appendTo($row);
                }
            }
        }
    }

    addAccessibilityToDxDataGridAdaptiveRow(e) {
        const adaptiveRowButtonSelector = e.element.find(".dx-datagrid-adaptive-more");
        let viewMoreAriaLabel = e.component.option("viewMoreAriaLabel");

        if (viewMoreAriaLabel == null) {
            viewMoreAriaLabel = "View more";
        }

        adaptiveRowButtonSelector.attr("aria-label", this.getTranslation(viewMoreAriaLabel));
        adaptiveRowButtonSelector.attr("role", "button");
        adaptiveRowButtonSelector.attr("tabindex", 0);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;
        adaptiveRowButtonSelector.each(function () {
            const adaptiveRowButton = $(this);
            _this.handleEnterKeyForAccessibility(adaptiveRowButton, false);
        });
    }

    addAccessibilityToDxDataGridOnContentReady(e) {
        e.element.find(".dx-group-row").removeAttr("tabindex");
        if (e.component._views != null) {
            e.component._views.rowsView.option("tabIndex", null);
        }

        //vsts 94999- add aria-label to focusable checkboxes
        if ($(e.element).find("[role=row][role=checkbox][tabindex=0]").length > 0) {
            $(e.element).find("[role=row][role=checkbox][tabindex=0]").attr("aria-label", "select row");
        }

        e.element.find("[role=row][role='button']:not([aria-label])").attr("aria-label", "Make a selection");
        e.element.find("[role=row]input[type='text']:not([aria-label])").attr("aria-label", "Enter a value");

        // No nested grid roles.
        e.element.find("[role='grid'] [role='grid']")
            .removeAttr("role")
            .removeAttr("aria-rowcount")
            .removeAttr("aria-colcount");

        // No role='presentation' on tbody
        e.element.find("tbody").removeAttr("role");

        this.addAriaLabelFromTranslation("CURRENT_PAGE", e.element, ".dx-pages [role=spinbutton]", "current page");
        this.addAccessibilityToDxDataGridAdaptiveRow(e);
        this.addAccessibilityToDxGroupPanel(e);
        this.addAccessibilityToDxDataGridPagination(e);
        this.addAccessibilityToDxDataGridHeader(e);
        this.addAccessibilityToRowFilterMenu(e);
    }

    addAccessibilityToDxDataGridHeader(e) {
        const header = e.element.find(".dx-header-row");
        const checkBox = header.find("[role=checkbox]");
        let checkBoxAriaLabel: string | Promise<string> = checkBox.attr("aria-label");

        if (_.isEmpty(checkBoxAriaLabel)) {
            // This next line is suspcicious for not assigning the new value; shouldn't this be awaited or in the then clause?
            checkBoxAriaLabel = this.getTranslation("IC-SELECT-ALL", "Select all rows");
            const checkBoxInstance = checkBox.data("dxCheckBox");
            if (checkBoxInstance != null) {
                checkBoxInstance.option("elementAttr", {
                    "aria-label": checkBoxAriaLabel
                });
            }
        }

        if (!header.find('.ic-resizable').length) {
            //Don't do this more than once per grid
            this.handleAccessibleColumnResizing(e, header.toArray());
        }
    }

    private handleAccessibleColumnResizing(e: { component: dxDataGrid }, headerRows: HTMLTableRowElement[]): void {
        const bounds = this.getCssWidthBounds();
        const widthAriaLabel = this.getTranslation('IC-WIDTH-OF-COLUMN');

        headerRows.forEach(headerRow => {
            const columnHeaders = headerRow.childNodes;
            if (!columnHeaders.length) {
                return;
            }

            const columns: dxDataGridColumn[] = $(headerRow).data().options.columns;
            columnHeaders.forEach((column: HTMLElement, index: number) => {
                if (!columns[index]?.allowResizing || trim(columns[index]?.caption) === '') {
                    return;
                }

                const initialWidth = e.component.columnOption(columns[index].dataField, 'width');
                const unit = this.getCssWidthFromDevExtreme(initialWidth, bounds).unit;

                const input: HTMLInputElement = $(`<input type="range" aria-hidden="true" class="ic-resizable__input" max="${bounds.get(unit).max}" min="${bounds.get(unit).min}" step="${bounds.get(unit).step}" aria-label="${widthAriaLabel} ${columns[index].caption}">`)[0];
                const separator: HTMLDivElement = $('<div class="ic-resizable"></div>')[0];
                separator.appendChild(input);
                column.appendChild(separator);

                input.addEventListener('focus', () => {
                    const td: any = $(input).closest('td[role=columnheader]');
                    td.addClass('ic-resizable__header--focus');
                    $(document).find(`[aria-describedby='${td.attr('id')}']`).addClass('ic-resizable__cell--focus');
                    const currentWidth: string = e.component.columnOption(columns[index].dataField, 'width');
                    const cssWidth = this.getCssWidthFromDevExtreme(currentWidth, bounds);
                    input.value = cssWidth.value.toString();
                });

                input.addEventListener('focusout', () => {
                    const td: any = $(input).closest('td[role=columnheader]');
                    td.removeClass('ic-resizable__header--focus');
                    $(document).find(`[aria-describedby='${td.attr('id')}']`).removeClass('ic-resizable__cell--focus');
                });

                input.addEventListener('change', () => {
                    // If not wrapped in beginUpdate/endUpdate, the UI will not update until the table is clicked :(
                    e.component.beginUpdate();
                    e.component.columnOption(columns[index].dataField, 'width', input.value + unit);
                    e.component.endUpdate();
                });
            });
        });
    }

    private getCssWidthBounds(): Map<cssUnit, Record<'min' | 'max' | 'step', number>> {
        const bounds: Map<cssUnit, Record<'min' | 'max' | 'step', number>> = new Map();
        bounds.set('px', { min: 60, max: 600, step: 10 });
        bounds.set('em', { min: 1, max: 50, step: 1 });
        bounds.set('rem', { min: 1, max: 50, step: 1 });
        bounds.set('%', { min: 1, max: 100, step: 2 });
        bounds.set('vw', { min: 1, max: 100, step: 2 });
        return bounds;
    }

    private getCssWidthFromDevExtreme(width: string, bounds: Map<cssUnit, Record<'min' | 'max' | 'step', number>>): { value: number, unit: cssUnit } {
        // Expecting "51px" or "25.00px" or "15%" or just "15"
        // We don't expect "auto"
        const regex = RegExp(/(^\d+\.?\d*)([\D]*$)/);
        const defaultUnit: cssUnit = 'px';
        const result = regex.exec(width);
        if (!result) {
            // We don't expect leading hyphens/negative numbers
            return { value: bounds.get(defaultUnit).min, unit: defaultUnit };
        }
        const unit = result[2] as cssUnit || defaultUnit;
        return { value: Number(result[1]), unit };
    }

    addAccessibilityToDxDataGridPagination(e) {
        if (e.component == null || e.component.pageCount == null)
            return;

        const pageCount = e.component.pageCount(),
            pagesCountSpan = e.element.find(".dx-pages-count[role=button]"),
            moveToPage = this.getTranslation("Move to page"),
            lastPage = this.getTranslation("Last page"),
            pagesCountAriaLabel = moveToPage + " " + pageCount + " " + lastPage;

        pagesCountSpan.attr("tabindex", "0");
        pagesCountSpan.attr("aria-label", pagesCountAriaLabel);
    }

    addAccessibilityToDxDataGridColumnChooser(treeViewElement): void {
        this.correctColumnChooserAriaAttributes(treeViewElement);
        const treeViewInstance = treeViewElement.data("dxTreeView");
        if (treeViewInstance != null) {
            this.announceColumnChooserVisibleColumns(treeViewInstance);
            treeViewInstance.off("contentReady", this.columnChooserTreeViewOnContentReady.bind(this));
            treeViewInstance.on("contentReady", this.columnChooserTreeViewOnContentReady.bind(this));
        }
    }

    addAccessibilityToBxPager() {
        const pager = $('.bx-pager');
        if (pager.length > 0) {
            const prevPageTranslation = this.getTranslation('Previous page');
            const nextPageTranslation = this.getTranslation('Next page');

            pager.find('.bx-prev').attr('aria-label', !prevPageTranslation ? 'Previous page' : prevPageTranslation);
            pager.find('.bx-next').attr('aria-label', !nextPageTranslation ? 'Next page' : nextPageTranslation);
            pager.find('.bx-next').attr('tabindex', 0);
            pager.find('.bx-prev').attr('tabindex', 0);
            pager.find('.bx-pager-link').attr('tabindex', 0);
            pager.find('.bx-next').attr('role', 'button');
            pager.find('.bx-prev').attr('role', 'button');
            pager.find('.bx-pager-link').attr('role', 'button');
        }
    }

    addAccessibilityToDxGroupPanel(e) {
        e.element.find(".dx-group-panel-item").attr("role", "button");
        e.element.find(".dx-group-panel-item").attr("tabindex", "0");
    }

    addAccessibilityToDropDownsOnInitialized(e) {
        const $element = $(e.element);
        setTimeout(() => {
            $element.find(".dx-texteditor-input").attr("role", "combobox");
        });
    }

    handleEnterKeyForAccessibility(
        element: any,
        onEnterOnly: boolean,
        allowTabKeyPassThrough = false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        keyUpCallBack: (event: any) => void = null
    ) {
        if (element.data('_handleEnterKeyForAccessibility:subscribed')) return;

        // only watch for enter key
        onEnterOnly = !_.isUndefined(onEnterOnly) ? onEnterOnly : true;

        // IE11 won't intercept Enter key in keypress event, but we need to
        // prevent spacebar from scrolling the screen.
        element.on('keypress', (event: any) => {
            event.preventDefault();
        });

        if (allowTabKeyPassThrough) {
            element.on('keydown', (event: any) => {
                // Allow tab-key to pass through
                if (this.isSpaceBarOrEnterKey(event)) {
                    event.preventDefault();
                }
            });
        }

        element.on('keyup', (event: any) => {
            // Artificially trigger a click event on a element
            // to allow for activation via spacebar or enter-key
            // Reference: https://dequeuniversity.com/library/aria/tables/sf-sortable-grid

            const triggerKeyPressed = (onEnterOnly && this.isEnterKey(event)) || (!onEnterOnly && this.isSpaceBarOrEnterKey(event));
            if (!triggerKeyPressed) {
                return;
            }

            if (!!keyUpCallBack && _.isFunction(keyUpCallBack)) {
                keyUpCallBack(event);
            } else {
                event.preventDefault();
                const srcElement = event.srcElement || event.originalEvent.srcElement;
                const clickEvent = this.getNewClickEvent();
                srcElement.dispatchEvent(clickEvent);
            }
        });

        element.data('_handleEnterKeyForAccessibility:subscribed', true);
    }

    private isSpaceBarOrEnterKey(event: any): boolean {
        const spaceBarPressed = event.key === ' ' || event.key === 'Spacebar' || event.keyCode === 32;
        return this.isEnterKey(event) || spaceBarPressed;
    }

    private isEnterKey(event: any): boolean {
        const enterKeyPressed = event.key === 'Enter' || event.keyCode === 13;
        return enterKeyPressed;
    }

    private getNewClickEvent(): MouseEvent {
        return new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
        });
    }
}
