import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { TranslatableEffectCommand } from './translatable-effect.command';
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { TranslateFacadeService } from '../services/translate-facade.service';
import { AlertService } from '../services/alert.service';
import { EcdService } from '../services/clientservices/ecd.service';
import { DefaultApiMapper } from '../services/field-mappers/default-api.mapper';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { UtilService } from '../services/util.service';
import { ApiClientService } from '../services/api-client.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { PersonalizationService } from '../services/personalization.service';
import { HelpersService } from '../services/helpers.service';

@Injectable()
export class RetrievePersistedReportInZipMapperCommand extends TranslatableEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected translateFacade: TranslateFacadeService,
        private ecdService: EcdService,
        private alertService: AlertService,
        private applicationInformation: ApplicationInformation,
        protected dynamicReplacementService: DynamicReplacementService,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private utilService: UtilService,
        private apiClientService: ApiClientService,
        protected cacheManagerService: CacheManagerService,
        protected personalizationService: PersonalizationService,
        protected helpersService: HelpersService,) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.retrievePersistedReportInZipMapperCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.retrievePersistedReportInZipMapperCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);
                return this.retrievePersistedReportInZipMapper(commandConfig, appsState)
                    .pipe(
                        switchMap((result) => {
                            const thisCommandActions = [];
                            
                            if (!_.isBoolean(result)) {
                                const publishEventAction = CommandActions.publishEvent({ event: result });
                                // investigate to confirm if the following actions are executed in sequence?
                                thisCommandActions.push(publishEventAction);
                            }
                            return this.appendNextActions(thisCommandActions, commandConfig);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    retrievePersistedReportInZipMapper(commandConfig: CommandConfig, appsState: any): Observable<any> {

        commandConfig.parameters[1] = commandConfig.parameters[1].trim();

        if (!_.isEmpty(commandConfig.parameters[1]))
            commandConfig.parameters[1] = this.translateFacade.instant("RETRY-OR-CONTACT-SUPPORT");

        const appName = commandConfig.appName;
        if (!this.applicationInformation.isListApp(appName))
            return throwError("Only list apps can execute 'retrieve persisted objects in zip' command");

        const grid = this.domComponentRetrievalService.getDxComponentInstance({}, appName);
        if (_.isNil(grid))
            return throwError("'" + appName + "' is not ready");

        const selectionMode = grid.option("selection").mode;
        const selectedKeys = "multiple".EqualsIgnoreCase(selectionMode) ? grid.getSelectedRowsData() : grid.getDataSource().items();
        if (selectedKeys.length <= 0)
            return throwError("No items selected to perform server request.");

        const tmpDataMup = this.convertSelectedRowsToDSFormat(selectedKeys);

        grid.beginCustomLoading();

        const fieldMapApps = { [appName]: true };
        const action = this.getActionName(commandConfig);
        const path = commandConfig.options.path;

        const apiMapper = new DefaultApiMapper(appsState, this.utilService, this.ecdService, this.cacheManagerService, this.apiClientService, this.personalizationService);
        apiMapper.setComponentData(appName, tmpDataMup);
        const requestPromise = apiMapper.ecdRequest(appName, appName, action, path.Path, fieldMapApps);
        return from(requestPromise)
            .pipe(
                switchMap((data: any) => {

                    if (_.isNil(data?.JSProperties?.cpURL)) return of(true);

                    const targetUrl = data.JSProperties.cpURL;
                    const downloadTag = document.createElement('a');
                    downloadTag.href = targetUrl;
                    // This html5 attribute is required to avoid getting console warning msg
                    downloadTag.setAttribute('download', 'download');
                    downloadTag.click();

                    this.alertService.showMessage(commandConfig.parameters[1]);
                    const documentEvent = this.helpersService.publishOnDocumentDownloadEvent(targetUrl, data.JSProperties.documentName);

                    return of(documentEvent);
                }),
                catchError((errorData) => {
                    if (!_.isEmpty(commandConfig.parameters[1])) {
                        this.dynamicReplacementService
                            .getDynamicValue(commandConfig.parameters[1], { appName })
                            .then((dynamicMessage) => this.alertService.showMessage(dynamicMessage));
                    }
                    const message = this.onFailApiCall(errorData);
                    return throwError(message);
                }),
                tap(() => {
                    // this section is required in order to avoid more server calls  
                    grid.deselectAll();
                    // end doc
                    grid.endCustomLoading();
                })
            );
    }

}
