import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, delay, retry, switchMap, tap } from 'rxjs/operators';
import dxDataGrid from 'devextreme/ui/data_grid';
import * as AppsActions from '../state/apps.actions';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { ListApplication } from '../components/list-application';
import { BaseEffectCommand } from './base-effect.command';
import { UtilService } from '../services/util.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { CommandListCollectionService } from './command-list-collection.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { AppsEntity } from '../state/apps.models';
import { AppEvent } from '../state/app-events.enum';
import { EventsService } from '../services/events.service';

@Injectable()
export class OpenCloseGroupsCommand extends BaseEffectCommand {
    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private utilService: UtilService,
        protected eventsService: EventsService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.openCloseGroupsCommand, {
            id: (action) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.openCloseGroupsCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const { workflow, themeName } = appsStore;
                const appState = this.getAppState(commandConfig.appName, appsStore);
                const promise = this.utilService.updateConditionalFormatRulesContext(commandConfig.appName, appState);
                return from(promise).pipe(
                    switchMap((appStateContext) => this.getOpenCloseGroupCommand(workflow, themeName, commandConfig, appStateContext)),
                    switchMap(([result, action, groupingLevel]) => {
                        if (!result) return of(result);
                        return this.getListComponentAndDxDataGrid(commandConfig.appName)
                            .pipe(
                                tap(([listComponent, gridInstance]) => this.openCloseGroup(listComponent, gridInstance, action, groupingLevel)),
                                catchError((e) => {
                                    if (IX_DEBUG_SETTINGS.cmdLst.debug)
                                        console.log(`Open/Close Group command: could not found '${commandConfig.appName}' application.`, e);
                                    return of(false);
                                })
                            );
                    }),
                    switchMap(() => this.getNextActions(commandConfig))
                );
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route }),
        })
    );

    private getOpenCloseGroupCommand(workflow: string, themeName: string, commandConfig: CommandConfig, appStateContext): Observable<any[]> {

        const ruleConfig = this.getConditionalRules(commandConfig);
        if (_.isEmpty(ruleConfig?.functionName)) {
            return throwError("Unable to determine open/close group set rules.");
        }

        const result = this.callWindowFunction(workflow, themeName, ruleConfig.functionName, appStateContext);
        const action = commandConfig.parameters[1];
        const groupingLevelParam: string = commandConfig.parameters[2];
        let groupingLevel: number;

        const nonNumericLevel = Number.isNaN(parseInt(groupingLevelParam));
        if (nonNumericLevel) {
            if (groupingLevelParam !== "All") {
                return throwError(`Grouping Level ${groupingLevel} is not supported. "All" or specify a number only.`);
            } else {
                groupingLevel = 0;
            }
        } else {
            groupingLevel = Number.parseInt(groupingLevelParam);
        }

        if (action !== 'Open' && action !== 'Close') {
            return throwError(`Action ${action} is not supported. Open / Close only.`);
        }

        return of([result, action, groupingLevel]);
    }

    private openCloseGroup(listComponent: ListApplication, gridInstance: dxDataGrid, action: string, groupingLevel: number): void {
        gridInstance.beginUpdate();
        listComponent.icCustomGroupExpand = action + ':' + groupingLevel;
        gridInstance.option('icCustomGroupExpand', listComponent.icCustomGroupExpand);
        if (action === 'Open') {
            gridInstance.expandAll(groupingLevel);
        } else {
            gridInstance.collapseAll(groupingLevel);
        }
        gridInstance.endUpdate();

        const event: AppsEntity = {
            id: AppEvent.ResetHorizontalScrollHandles,
            state: { appName: listComponent.applet.name },
        };
        this.eventsService.publishEvent(event);
    }

    private getListComponentAndDxDataGrid(appName: string): Observable<any[]> {
        return of(appName)
            .pipe(
                delay(100),
                switchMap((appName) => {
                    const listComponent = this.domComponentRetrievalService.getAppComponent(appName) as ListApplication;
                    const gridInstance = this.domComponentRetrievalService.getDxComponentInstance({}, appName) as dxDataGrid;
                    if (!listComponent || !gridInstance)
                        return throwError("List component dxDataGrid is not ready yet.");
                    return of([listComponent, gridInstance]);
                }),
                retry(10) // TODO: invistigate if theme property AutoTabPreloadDelay/Delay has to be accounted for
            );
    }
}
