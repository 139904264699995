import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DxButtonComponent } from 'devextreme-angular';
import DxButton from 'devextreme/ui/button';
import { Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { AppEvent } from "../../state/app-events.enum";
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { ThemeService } from '../../services/theme.service';
import { UtilService } from "../../services/util.service";
import { EventsService } from "../../services/events.service";
import { AccessibilityService } from "../../services/accessibility.service";

interface ButtonConfig {
    appName?: string;
    fieldName?: string;
    ariaLabel?: string;
    hint?: string;
    icon?: string;
    keepTabstopWhenDisabled?: boolean;
    text?: string;
    translationId?: string;
    icClassName?: string;
    dataAttributes?: any;
    elementAttr?: any;
    hasDynamicAriaLabelValue?: boolean;
};

@Component({
    selector: 'ic-button',
    template: `<dx-button #targetButton [class]="cssClass" [attr.type]="typeAttr" (onInitialized)="this.onComponentInit($event)"></dx-button>`,
    styles: [
        ':host { display: contents; }',
        ':host:first-child { width: auto; }',
        ':host-context(.ExtraSmall) :host:first-child { width: 100%; }',
        ':host-context(.dx-device-phone) :host:first-child { width: 100%; }',
        ':host-context(.dx-device-phone) .dx-overlay-wrapper :host:first-child { width: 100%; }'
    ]
})
export class ButtonComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() config: ButtonConfig = {};
    @Input() cssClass: string;

    @ViewChild("targetButton", { static: false })
    dxComponent: DxButtonComponent;

    translateSubscription: Subscription;
    onCommandSubscription: Subscription;
    toggleDisabledSubscription: Subscription;

    typeAttr: string;

    get dxButton(): DxButton {
        return this.dxComponent.instance;
    }

    constructor(private translate: TranslateFacadeService,
        private eventsService: EventsService,
        private themeService: ThemeService,
        private utilService: UtilService,
        private accessibilityService: AccessibilityService) {
        this.typeAttr = this.themeService.getThemeProperty('NoButtonTypeAttribute') ? undefined : 'button';
    }

    ngOnInit(): void {
        const keepTabstopWhenDisabled = this.themeService.getThemeProperty('ADA.Buttons.TabstopWhenDisabled');
        this.config.keepTabstopWhenDisabled = "true".EqualsIgnoreCase(keepTabstopWhenDisabled?.Value1);
        this.translateSubscription = this.translate.onLangChange.subscribe(() => this.updateTranslations());
        this.createOnCommandSubscription();
        this.createToggleDisabledSubscription();
    }

    ngAfterViewInit(): void {
        this.dxButton.option(this.config);
        const element = this.dxButton.element();
        this.utilService.decorateElementWithDataAttributes(element, this.config);
        this.applyAriaAttributesFromDataAttributes(element);
        this.updateTranslations();
    }

    ngOnDestroy(): void {
        this.onCommandSubscription?.unsubscribe();
        this.translateSubscription?.unsubscribe();
        this.toggleDisabledSubscription?.unsubscribe();
    }

    public onComponentInit(e: { element: any }): void {
        this.arrowKeyFocus(e);
        this.accessibilityService.handleEnterKeyForAccessibility(e.element, false, true);
    }

    private createOnCommandSubscription() {
        this.onCommandSubscription = this.eventsService.events$
            .pipe(
                filter(event => !_.isEmpty(event) && AppEvent.ExecuteButtonClick.EqualsIgnoreCase(event.id)),
                tap(event => {
                    const { appName, buttonName } = event.state;
                    if (this.config.appName.EqualsIgnoreCase(appName as string)
                        && this.config.fieldName.EqualsIgnoreCase(buttonName as string))
                        this.dxButton.element()[0]?.click();
                })
            ).subscribe();
    }

    private createToggleDisabledSubscription() {
        this.toggleDisabledSubscription = this.eventsService.events$
            .pipe(
                filter(event => AppEvent.ToggleDisabledButton.EqualsIgnoreCase(event?.id)
                    && this.config.appName.EqualsIgnoreCase(event?.state?.buttonAppName as string)
                    && this.config.icClassName.EqualsIgnoreCase(event?.state?.className as string)),
                tap((event) => {
                    const disabled = event.state.disabled;
                    if (IX_InBecomeUserMode() && this.dxButton.option('disableOnBecomeUser') === true) {
                        this.dxButton.option('disabled', true);
                        this.addAriaDisabledToButton(true);
                        return;
                    }
                    this.dxButton.option('disabled', disabled);
                    this.addAriaDisabledToButton(disabled);
                })
            ).subscribe();
    }

    private addAriaDisabledToButton(disabled) {
        const $element = $(this.dxButton.element());
        disabled ? $element.attr('aria-disabled', "true") : $element.removeAttr("aria-disabled");
    }

    private updateTranslations(): void {
        const { translationId } = this.config;
        let { ariaLabel, text, icon, hint } = this.config;
        text = this.translateOrDefault(translationId, text);
        hint = this.translateOrDefault(translationId + ".hint", hint);
        ariaLabel = this.translateOrDefault(translationId + ".ariaLabel", ariaLabel);
        icon = this.utilService.translateImageOrDefault(translationId + ".icon", icon);
        this.dxButton.option({ ariaLabel, text, icon, hint });
        this.addAccessibility();
    }

    private translateOrDefault(translationId: string, defaultTranslation: string): string {
        return this.utilService.translateOrDefault(translationId, defaultTranslation);
    }

    private addAccessibility(): void {
        if (this.accessibilityService.addAriaLabelFromOption(this.dxButton, "ariaLabel")) {
            const ariaLabel = this.dxButton.option("ariaLabel");
            $(this.dxButton.element()).find('img').attr('alt', ariaLabel);
        }
        this.addElementAttr();
        this.accessibilityService.warnIfInvalidAriaLabel(this.dxButton);
    }

    private addElementAttr() {
        const elementAttr = this.config.elementAttr;
        if (this.config.hasDynamicAriaLabelValue) {
            const dynamicAriaLabelValue = $(this.dxButton.element()).data('dynamicAriaLabelValue');
            if (dynamicAriaLabelValue) elementAttr['aria-label'] = dynamicAriaLabelValue;
        }
        this.dxButton.option('elementAttr', elementAttr);
    }

    private applyAriaAttributesFromDataAttributes(element: HTMLElement): void {
        _.each(element.attributes, attrib => {
            const name = attrib.name;
            const value = attrib.value;
            if (!name.startsWith("data-aria")) return;
            const ariaName = name.substring("data-".length);
            element.setAttribute(ariaName, value);
        });
    }

    arrowKeyFocus(e: any): void {
        const focusStateEnabled = e.component.option("arrowKeyFocusEnabled");
        if (focusStateEnabled) {
            e.element.keydown(function (ev) {
                let selector = "";
                switch (ev.keyCode) {
                    case 37: // Left
                        selector = e.component.option("arrowKeyFocusLeftSelector");
                        break;
                    case 38: // Up
                        selector = e.component.option("arrowKeyFocusUpSelector");
                        break;
                    case 39: // Right
                        selector = e.component.option("arrowKeyFocusRightSelector");
                        break;
                    case 40: // Down
                        selector = e.component.option("arrowKeyFocusDownSelector");
                        break;
                }
                $(selector).focus();
            });
        }
    }
}
