import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { ApplyFilterCommand } from './apply-filter.command';
import { CommandListCollectionService } from './command-list-collection.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { DefaultFieldMapperFactory } from '../services/field-mappers/field-mapper.factory';

@Injectable()
export class ApplyFilterEffectCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        private applyFilterCommand: ApplyFilterCommand,
        protected cacheManagerService: CacheManagerService,
        private defaultFieldMapperFactory: DefaultFieldMapperFactory) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.applyFilterMapperCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.applyFilterMapperCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);

                const fieldMapper = this.defaultFieldMapperFactory.create(appsState, this);
                const mapIndex = commandConfig.options.i;
                const forceSetValue = true;
                // Step 1: perform field mapping
                let fieldMap = fieldMapper.execute(commandConfig, null, forceSetValue);
                // Step 2: perform data source filter/reload based on target application
                if (!_.isPlainObject(fieldMap))
                    fieldMap = fieldMapper.getFieldMap(commandConfig, mapIndex);


                return this.applyFilterCommand.applyFilter(commandConfig, fieldMapper, fieldMap, appsState)
                    .pipe(
                        switchMap(() => {
                            // TODO setAppFilter(fieldMap.targetApp, filter);
                            const commandState = Object.values(fieldMapper.State);
                            const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                            return this.appendNextActions(thisCommandActions, commandConfig, fieldMap);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );
}
