import { Injectable } from "@angular/core";
import { UtilService } from './util.service';

@Injectable()
export class DomFocusService {


    constructor(private utilService: UtilService) { }

    forceProgrammaticFocusOnElement(element) {
        if (element.length == 0)
            return false;
        element.attr("tabindex", "-1");
        this.setFocusToElement(element);
        return true;
    }

    setFocusToElement(element) {
        let result = false;
        if (_.isFunction(element.setFocus)) {
            element.setFocus();
            result = true;
        } else if (_.isFunction(element.focus)) {
            element.focus();
            result = true;
        }
        return result;
    }

    setFocusOnLoadingPanel() {
        this.utilService.fixOverlappingLoadings();
        setTimeout(() => {
            const loadingPanel = $(".dx-loadpanel-content");
            loadingPanel.attr("aria-busy", "true")
                .attr("aria-live", "polite")
                .attr("tabindex", "-1")
                .attr("aria-label", this.utilService.getTranslation("LOADING"));
        }, 500);
    }
}
