import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { ApiClientService } from '../services/api-client.service';
import { CommandListCollectionService } from "./command-list-collection.service";
import { CacheManagerService } from './../services/cachemanager.service';

interface BrowserInfo {
    appName: string;
    ipAddress: string;
    location: string;
    device: string;
    deviceInfo: string;
    operatingSystem: string;
    timeStamp: string;
}

@Injectable()
export class TransferUserAgentMetricsInformationCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private apiClientService: ApiClientService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.transferUserAgentMetricsInformationCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.transferUserAgentMetricsInformationCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                return this.transferUserAgentMetricsInformation(commandConfig)
                    .pipe(
                        switchMap((responseState) => {
                            const appName = commandConfig.appName;
                            const appState = this.getAppState(appName, appsStore);
                            _.mergeWith(appState, responseState);
                            const appEntity = {
                                id: appName,
                                state: appState
                            };
                            const thisCommandActions = this.getUpdateCommandStateActions([appEntity], commandConfig);
                            return this.appendNextActions(thisCommandActions, commandConfig);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    transferUserAgentMetricsInformation(commandConfig: CommandConfig): Observable<any> {
        const browserInfo = this.getBrowserInfo(commandConfig);
        const params = JSON.stringify(browserInfo);
        const transferInfoPromise = this.apiClientService.transferUserAgentMetricsInformation(params);
        return from(transferInfoPromise);
    }

    private getBrowserInfo(commandConfig: CommandConfig): BrowserInfo {
        const appName = commandConfig.appName;
        return {
            ipAddress: commandConfig.parameters[0],
            location: commandConfig.parameters[1],
            device: commandConfig.parameters[2],
            deviceInfo: commandConfig.parameters[3],
            operatingSystem: commandConfig.parameters[4],
            timeStamp: commandConfig.parameters[5],
            appName: appName
        };
    }

}
