import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateFacadeService } from '../../services/translate-facade.service';

@Component({
  selector: 'ic-modal-email-viewer',
  templateUrl: './modal-incoming-call.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalIncomingCallComponent implements OnInit {

  @Input() options: ModalIncomingCallOptions;
  @Input() acceptCall: Function;
  @Input() declineCall: Function;
  @Input() onHiding: Function;

  caller: string;
  title: string;

  constructor(private translateFacade: TranslateFacadeService) { }

  ngOnInit(): void {
    this.caller = this.options.caller;
    this.title = this.options.title || this.translateFacade.getTranslation('INCOMING-CALL');
  }

  onContentReady(e) {
    e.component.focus();
  }
}
