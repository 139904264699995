import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateFacadeService } from '../../services/translate-facade.service';

@Component({
  selector: 'ic-modal-generic',
  templateUrl: './modal-generic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalGenericComponent implements OnInit {

  @Input() options: ModalGenericOptions;
  @Input() onPrimary?: {};
  @Input() onSecondary?: Function;
  @Input() onTertiary?: Function;
  @Input() onHiding?: Function;
  @Input() onShown?: Function;

  visible = true;
  text: string;
  customCssClass: string;
  title: string;
  //realButtons: any; // not supported by dev-extreme angular buttons at this time
  onContentReady: Function;
  width: number | string;
  height: number | string;

  constructor(private translateFacade: TranslateFacadeService) { }

  ngOnInit(): void {
    this.text = this.options.text;
    this.customCssClass = this.options.customCssClass;
    // Opt-in to use <button> elements instead of <div> for a11y
    //this.realButtons = this.options.realButtons;
    this.title = this.options.title || this.translateFacade.getTranslation('INFORMATION');
    this.width = this.options.width || 350;
    this.height = this.options.height || 'auto';
    this.onContentReady = (e) => {
      e.component.focus();
    };
  }
}
