/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
require('devextreme/bundles/modules/data');

DevExpress.data.CustomStore = DevExpress.data.CustomStore.inherit({
    getRawData: function () {
        var allItems = [];
        if ("raw" === this._loadMode) {
            allItems = this.__rawData;
        }
        return allItems;
    },
    options: {
        "serverCallType": ""
    }
});

DevExpress.data.DataSource.redefine({

    _loadFromStore: function (loadOptions, pendingDeferred) {
        var that = this;

        var extend = DevExpress.ic.core.utils.extend.extend;
        var when = DevExpress.ic.core.deferredUtils.when;
        var Deferred = DevExpress.ic.core.deferredUtils.Deferred;

        function isPending(deferred) {
            return deferred.state() === "pending";
        }

        function handleSuccess(data, extra) {
            function processResult() {
                var loadResult;

                if (data && !Array.isArray(data) && data.data) {
                    extra = data;
                    data = data.data;
                }

                if (!Array.isArray(data)) {
                    data = [data];
                }

                // NOTE: This is the custom IC code, swapping the params of extend so that extra is not overwritten
                loadResult = extend(loadOptions, {
                    data: data,
                    extra: extra
                });

                that._eventsStrategy.fireEvent("customizeLoadResult", [loadResult]);
                when(loadResult.data).done(function (data) {

                    // Add ability to process data once grouping has happened but before rendered
                    if (typeof loadResult.postLoadPreProcess === 'function') {
                        data = loadResult.postLoadPreProcess(data);
                    }

                    loadResult.data = data;
                    that._processStoreLoadResult(loadResult, pendingDeferred);
                }).fail(pendingDeferred.reject);
            }

            if (that._disposed) {
                return;
            }

            if (!isPending(pendingDeferred)) {
                return;
            }

            processResult();
        }

        if (loadOptions.data) {
            return new Deferred().resolve(loadOptions.data).done(handleSuccess);
        }

        return this.store().load(loadOptions.storeLoadOptions)
            .done(handleSuccess)
            .fail(pendingDeferred.reject);
    }

});
