import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from './command-list-collection.service';
import { DefaultFieldMapper } from '../services/field-mappers/default-field.mapper';
import { ThemeService } from '../services/theme.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { ApplicationInformation } from '../services/application-information.service';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { PersonalizationService } from '../services/personalization.service';

@Injectable()
export class EventSetValuesCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected cacheManagerService: CacheManagerService,
        protected commandListCollectionService: CommandListCollectionService,
        private themeService: ThemeService,
        protected applicationInformation: ApplicationInformation,
        protected dynamicReplacementService: DynamicReplacementService,
        private personalizationService: PersonalizationService
    ) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.eventSetValuesCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.eventSetValuesCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);
                const fieldMapper = new DefaultFieldMapper(appsState, this, this.themeService, this.cacheManagerService, this.personalizationService);
                const forceSetValue = true;
                // Step 1: perform field mapping
                fieldMapper.execute(commandConfig, null, forceSetValue);
                const commandState = Object.values(fieldMapper.State);
                // Step 2: update command state
                const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                return this.appendNextActions(thisCommandActions, commandConfig, null);
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route })
        })
    );
}
