export class TextAnnouncer {

    elementSelector: "#IX_AnnounceTextAnnouncedId";
    textQueue: any[];
    activeSpeak: boolean;

    constructor() {
        this.textQueue = [];
        this.activeSpeak = false;
    }

    get $ariaLiveElement(): any {
        return $(this.elementSelector);
    }

    announce(textItem: any, lowTimeout?: number): void {

        if (textItem)
            this.textQueue.push(textItem);

        if (this.textQueue.length <= 0) {
            this.activeSpeak = false;
            return;
        }

        if (!this.activeSpeak) {
            this.activeSpeak = true;

            // to have a local cache of the element
            const $ariaLiveElement = this.$ariaLiveElement;
            $ariaLiveElement.attr('aria-hidden', null);
            // if not next to body or nested in a aria-hidden element move to top
            if ($ariaLiveElement.parent('body').length === 0
                || $ariaLiveElement.closest('[aria-hidden="true"]').length > 0)
                $ariaLiveElement.detach().prependTo('body');

            const delay = lowTimeout ? 50 : 1000;
            setTimeout(() => {
                if (this.textQueue.length > 0) {
                    const { text } = this.textQueue[0];
                    $ariaLiveElement.text("");
                    $ariaLiveElement.text(text);
                    this.textQueue.splice(0, 1);
                    this.activeSpeak = false;
                    this.announce(null, lowTimeout);
                }
            }, delay);
        }
    }
}