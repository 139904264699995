import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { AppsEntity } from '../state/apps.models';
import { BaseEffectCommand } from './base-effect.command';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { ListApplication } from '../components/list-application';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class SetFirstDropdownItemCommand extends BaseEffectCommand {

    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.setFirstDropdownItemCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.setFirstDropdownItemCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appEntity = this.setFirstDropdownItem(commandConfig)
                const appState = this.getAppState(appEntity.id, appsStore);
                _.mergeWith(appState, appEntity.state);
                _.mergeWith(appEntity.state, appState);
                const thisCommandActions = this.getUpdateCommandStateActions([appEntity], commandConfig);
                return this.appendNextActions(thisCommandActions, commandConfig);
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route })
        })
    );

    setFirstDropdownItem(commandConfig: CommandConfig): AppsEntity {
        const appName = commandConfig.parameters[0];
        const fieldName = commandConfig.parameters[1];
        const state = {};
        const appEntity = { id: appName, state };
        const appComponent = this.domComponentRetrievalService.getAppComponent(appName) as ListApplication;
        if (_.isNil(appComponent))
            return appEntity;
        const items = appComponent.dataSources[fieldName]?.items();
        const fieldConfig = appComponent.applet.config.inputFields[fieldName];
        if (items && items.length && fieldConfig) {
            const firstItem = items[0].items ? (items[0].items[0] || {}) : items[0];
            state[fieldName] = firstItem[fieldConfig.valueExpr];
            if (!_.isEmpty(fieldConfig.mappingExpr)) {
                const fieldsMap = fieldConfig.mappingExpr.split(',');
                fieldsMap.forEach((fieldMap) => {
                    if (!fieldMap.contains("=")) return;
                    const fields = fieldMap.split("=");
                    const targetField = fields[0];
                    const sourceField = fields[1];
                    state[targetField] = firstItem[sourceField] || null;
                });
            }
        }
        return appEntity;
    }

}
