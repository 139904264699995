import { Component, OnChanges, OnInit, OnDestroy, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DxListComponent } from 'devextreme-angular';
import { dxSortableOptions } from 'devextreme/ui/sortable';
import { Subscription } from 'rxjs';
import { AppsConstantsFacade } from '../../services/apps-constants.facade';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { AccessibilityService } from '../../services/accessibility.service';
import { UtilService } from '../../services/util.service';
import { UtilListService } from '../../services/util-list.service';
import { ListDataEntity } from '../../state/apps.models';
import UtilityFunctions from '../../utility.functions';

type SimpleListConfig = {
  appName?: string,
  itemField?: string;
  dynamicObjs?: any,
  enableDragDrop?: string,
  allowKeyboardFocus?: string,
  scrollWindowOnDrag?: string,
  allowGaps?: string,
  keyField?: string,
  indexField?: string,
  contextMenuTemplate?: string,
  contextMenu?: {
    componentProperties?: {},
    config: {},
    appScope?: any,
    dxInstance?: { option: Function, show: Function },
    element?: any,
  },
  contextMenuOffsetX?: string,
  contextMenuOffsetY?: string,
  contextMenuWidth?: string,
  onItemClick?: Function,
};

type SimpleListProperties = {
  hasContextMenu?: boolean,
  menuButtonAlign?: string,
};

type SimpleListReorderByKeyboard = {
  focusedElementItemData: Record<string, unknown>,
  focusKeyboardHandlerCallback: (target: any) => void,
}

@Component({
  selector: 'ic-simplelist',
  templateUrl: './simplelist.component.html',
  styleUrls: ['./simplelist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimplelistComponent implements OnChanges, OnInit, OnDestroy {

  @Input() config: SimpleListConfig;
  @Input() context: any;
  @Input() applet: Applet
  @Input() data: any;
  @Input() componentProperties: SimpleListProperties;
  @Input() componentConfig: StaticComponentConfig;
  @Input() checkSize: any;

  @Output() updateStaticComponent = new EventEmitter<StaticComponentStateChange>();
  @Output() staticComponentItemClick = new EventEmitter<StaticComponentItemClick>();

  @ViewChild(DxListComponent)
  dxlist: DxListComponent;

  dxOptions: any;
  translateSubscription: Subscription;
  private focusedElementItemData: Record<string, unknown>;
  private focusKeyboardHandlerCallback: (target: any) => void;

  /**
   * Needed for the DxList component to mutate (in keyboard-reordering case)
   */
  public mutableData: Array<Record<string, unknown>>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private appsConstantsFacade: AppsConstantsFacade,
    private translate: TranslateFacadeService,
    private utilService: UtilService,
    private utilListService: UtilListService,
    private accessibilityService: AccessibilityService
  ) { }

  ngOnInit(): void {
    this.controller();
  }

  ngOnDestroy(): void {
    this.translateSubscription?.unsubscribe();

    // if (this.config.contextMenuTemplate && this.config.contextMenu.appScope) {
    //     this.config.contextMenu.appScope.$destroy();
    // }
  }

  ngOnChanges(changes): void {
    for (const propName in changes) {
      if (Object.prototype.hasOwnProperty.call(changes, propName)) {
        if (propName === 'value') {
          const newVal = changes[propName].currentValue;
          this.context._componentValues[this.componentConfig.GridClientInstanceName] = newVal;
        }
        if (propName === 'data') {
          if (!changes[propName].firstChange) {
            this.data = this.data.data || this.data;
            this.mutableData = _.cloneDeep(this.data);
          }
        }
      }
    }
  }

  controller(): void {
    this.config.dynamicObjs = this.config.dynamicObjs || {};
    this.componentProperties.hasContextMenu = false;
    this.dxOptions = {
      itemDragging: {
        boundary: ".ic-simple-list",
        allowReordering: this.isDragDropEnabled(),
        onReorder: (e: { fromIndex: number, toIndex: number }) => {
          // Handle reordering the list
          this.mutableData.splice(e.fromIndex, 1);
          this.mutableData.splice(e.toIndex, 0, _.cloneDeep(this.data[e.fromIndex]));
          this.updateIndexField();
          this.updateState();
        },
      } as dxSortableOptions,
      focusStateEnabled: this.isDragDropEnabled(),
      icScrollWindowOnDrag: "true".EqualsIgnoreCase(this.config.scrollWindowOnDrag),
      onItemRendered: (e) => this.onItemRendered(e),
      itemTemplate: (data) => {
        const $item = $('<div>').addClass('icsl-item-wrapper');
        $('<span>').addClass('icsl-item-content').text(data[this.config.itemField]).appendTo($item);
        if (this.componentProperties.hasContextMenu) {
          console.log('componentProperties.hasContextMenu');
          const buttonClasses = 'icsl-context-menu-btn icsl-pull-' + this.componentProperties.menuButtonAlign;
          $('<button ng-click="showContextMenu($event, listItem)">').addClass(buttonClasses).appendTo($item);
        }
        return $item;
      },
    };

    // Hard-coded for now. TODO: expose to P-Tier
    this.componentProperties.menuButtonAlign = 'right';

    if (this.config.contextMenuTemplate) {
      this.config.contextMenu = {
        config: {
          appTemplate: this.config.contextMenuTemplate,
          offsetX: this.config.contextMenuOffsetX,
          offsetY: this.config.contextMenuOffsetY,
          componentWidth: this.config.contextMenuWidth,
        },
        componentProperties: {},
      };

      this.componentProperties.hasContextMenu = true;
    }

    this.config.appName = this.componentConfig.GridClientInstanceName;
    this.utilService.triggerDynamicReplacements(this);

    IX_BindAnnounceTextToLoadngState(this);

    this.translateSubscription = this.translate.onLangChange.subscribe(() => this.updateTranslations());

    this.config.onItemClick = (tile) => {
      const fnName = this.applet.name.toPopUpName() + '__CLE_OnComponentClick';
      this.staticComponentItemClick.emit({ componentType: 'simplelist', fnName, data: tile });
    };
  }

  showContextMenu($e): void {
    if (!this.componentProperties.hasContextMenu) return;
    this.config.contextMenu.appScope = this.config.contextMenu.element.isolateScope();
    const dataSource = Object.entries(this.config.contextMenu.appScope.buttons).map((x) => x[1]);
    this.config.contextMenu.dxInstance.option('dataSource', dataSource);
    this.config.contextMenu.dxInstance.option('target', $e.target);
    this.config.contextMenu.dxInstance.show();
  }

  initComponent(e): void {
    this.applet.dxComponent = this.dxlist;
    e.component.option('icScrollWindowOnDrag', this.dxOptions.icScrollWindowOnDrag);

    if (this.isDragDropEnabled()) {
      e.component.option('itemDragging.icOnReorderByKeyboard', this.onReorderByKeyboard);
    }

    // TODO: add support to render application at runtime, hint: CanvasLayoutRenderService
    // Don't generate context menu if none specified
    // if (this.componentProperties.hasContextMenu) {
    //   const directive = 'applet-' + this.config.contextMenuTemplate.toLowerCase().replace(/\./g, '');
    //   const selector = ['CM', this.applet.name, this.config.contextMenuTemplate].join('_').toLowerCase().replace(/\./g, '');
    //   const templateApplet = {
    //     name: selector,
    //     id: selector,
    //   };
    //   const templateElement = '<' + directive + ' id="' + templateApplet.id + '" applet="$parent.templateApplet" context="$parent.context" />';
    //   this.config.contextMenu.element = $compile(templateElement)(this);
    // }
  }

  private isDragDropEnabled(): boolean {
    return 'true'.EqualsIgnoreCase(this.config.enableDragDrop)
  }

  // A callback, so fat-arrow syntax needed to preserve `this`
  private onItemRendered(e: { element: any, itemElement: any, itemData: { _icItemDataGuid?: string } }): void {
    this.accessibilityService.addAccessibilityToSimpleList(e, this);

    if (!(this.isDragDropEnabled() && this.focusedElementItemData
      && typeof this.focusKeyboardHandlerCallback === 'function')) {
      return;
    }

    const compareAllButSortOrder = (a, b) => {
      const keys = Object.keys(a);
      for (const key of keys) {
        // Sort order field has already been changed; ignoring.
        if (key === this.config.indexField) {
          continue;
        }

        if (a[key] !== b[key]) {
          return false;
        }
      }
      return true;
    };

    if (_.isEqualWith(this.focusedElementItemData, e.itemData, compareAllButSortOrder)) {
      // e.itemElement already doesn't exist on the DOM, it has been replaced. We must find it:
      const target = e.element.find('div.dx-list-item-content:contains("' + e.itemElement[0].innerText + '")');
      this.focusedElementItemData = undefined;

      this.appsConstantsFacade.updateListKeyboardFocus(() => this.focusKeyboardHandlerCallback(target));
      this.focusKeyboardHandlerCallback = undefined;
    }
  }

  // A callback, so fat-arrow syntax needed to preserve `this`
  private onReorderByKeyboard = (e: SimpleListReorderByKeyboard): void => {
    this.focusedElementItemData = e.focusedElementItemData;
    this.focusKeyboardHandlerCallback = e.focusKeyboardHandlerCallback;
    this.updateIndexField();
    this.updateState();
  }

  private updateIndexField(): void {
    if (_.isString(this.config.allowGaps) && this.config.allowGaps.toLowerCase() === 'true') {
      const indexes = this.mutableData.map((item: Record<string, unknown>) => item[this.config.indexField]).sort();
      this.mutableData.forEach((item: Record<string, unknown>, index: number) => {
        item[this.config.indexField] = indexes[index];
      });
    } else {
      this.mutableData.forEach((item: Record<string, unknown>, index: number) => {
        item[this.config.indexField] = index;
      });
    }
  }

  private updateState(): void {
    this.data = _.cloneDeep(this.mutableData);
    const listDataEntity: ListDataEntity = {
      id: this.applet.name,
      data: this.data,
      requestId: UtilityFunctions.generateUUID()
    };
    this.updateStaticComponent.emit({ listDataEntity });
  }

  private updateTranslations() {
    this.utilListService.updateListComponentTranslations(this);
    this.changeDetectorRef.detectChanges();
  }
}
