import { Component, ElementRef, AfterViewInit, Input, Output, OnInit, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { TextAnnouncer } from "../../commands/text-announcer.service";
import { UtilService } from '../../services/util.service';
import { DynamicReplacementService } from '../../services/dynamic-replacement.service';
import { IcComponentEditor, IcValidator } from '../../services/validation-engine/ic-validator';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { ValidationGroupService } from '../../services/validation-group.service';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import DxCheckBox from 'devextreme/ui/check_box';
import { DxCheckBoxComponent } from 'devextreme-angular/ui/check-box';
import { NgEventsStrategy } from 'devextreme-angular';

@Component({
    selector: 'ic-check-box-group',
    templateUrl: './checkboxgroup.component.html',
    styleUrls: ['./checkboxgroup.component.scss']
})
export class CheckboxGroupComponent implements OnChanges, OnInit, AfterViewInit {

    @Input() fieldName: string;
    @Input() model: any;
    @Input() config: any;
    @Input() applet: Applet;
    @Input() context: any;
    @Input() conditionalFormats: any;
    @Input() parentModel: any;
    @Input() cssClass: string;
    @Input() checkSize: any;

    @Output() updateState = new EventEmitter<AppStateChange>();


    // TODO: make getter return list of checkbox instances if necessary
    // @ViewChild("dxCheckBox", { static: false })
    // dxCheckBoxComponent: DxCheckBoxComponent;

    // get dxCheckBox(): DxCheckBox {
    //   return this.dxCheckBoxComponent.instance;
    // }

    private textAnnouncer: TextAnnouncer;
    dxOptions: any;
    lookups = {
        in: { "Y": true, "N": false },
        out: { "true": "Y", "false": "N" },
    };
    validatorOptions: {
        validationGroup?: string,
    } = {};
    validator: any;
    originalOnValueChanged: Function;
    options: any;
    isValidationGroupValid: () => void;


    constructor(
        private elementRef: ElementRef,
        private utilService: UtilService,
        private translateFacadeService: TranslateFacadeService,
        private validationEngine: ValidationEngineService,
        private validationGroupService: ValidationGroupService,
        private dynamicReplacementService: DynamicReplacementService) { }

    ngOnInit(): void {
        this.onInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            if (this.isValidationGroupValid) this.isValidationGroupValid();
        }
    }

    ngAfterViewInit() {
        if (!_.isNil(this.config.checkBoxModel)) {
            const $e = $(this.elementRef.nativeElement);
            this.options = {
                element: $e,
                target: $e.find('input:last'),
                appId: this.applet.rid,
                applicationName: this.applet.name,
                useFieldLabelAsAriaLabel: true
            };
        }
        this.updateTranslations();
    }

    private onInit() {
        this.fieldName = this.utilService.getFieldNameFromConfig(this.config.checkBoxModel);


        const config = {
            visible: false,
            onValueChanged: (editor) => {
                const shouldUpdate = this.setCheckBoxModelValue(editor.name, editor.value);
                if (!shouldUpdate) return;
                this.validator?.validate(this);
                this.isValidationGroupValid();
                this.updateState.emit({ field: this.fieldName, value: this.model.value });
                if (!_.isNil(this.originalOnValueChanged)) {
                    setTimeout(() => this.originalOnValueChanged(editor), 0);
                }
            }
        };

        if (!_.isNil(this.config.checkBoxModel.dxValidator)) {
            _.extend(this.validatorOptions, this.config.checkBoxModel.dxValidator);
            delete this.config.checkBoxModel.dxValidator;
        }

        this.isValidationGroupValid = _.debounce(
            () => {
                if (!this.validatorOptions?.validationGroup) {
                    return;
                }
                this.validationGroupService.publishRefreshButtons(this.applet.name, this.validatorOptions.validationGroup);
            }, 150);

        // Creates an object containing the configuration for all checkboxes
        // where the key is the checkbox text eg: 
        // { "cb1": { ...config, name: "cb1", text: "cb1", value: false }, 
        // "cb2": {...}, ... }
        this.dxOptions = this.config.checkboxes.map((x: string) => {
            // Map the checkbox names to an individual object containing config.
            // [ { "cb1": {...} }, { "cb2": {...} }]
            return {
                [x]: {
                    ...config,
                    ...this.config.checkBoxModel,
                    name: x,
                    text: x,
                    value: this.model.value.split(',').includes(x)
                }
            };
        })
        .reduce((x: { [x: string]: never; }, y: { [y: string]: never; }) => {
            // Reduces the array of single objects to a single object
            // [{ 1: {...} }, { 2: {...} } ] -> { 1: {...}, 2: {...} }
            // This just adds the kvp y to x and at the end returns x.
            x[Object.keys(y)[0]] = y[Object.keys(y)[0]];
            return x;
        });

        // Only attach one validator to the last checkbox
        this.dxOptions[this.config.checkboxes[this.config.checkboxes.length-1]].onInitialized = this.initValidator;
                        
        if (this.checkSize) {
            this.checkSize({
                adjustWidth: true,
                adjustHeight: true
            });
        }
    }

    private initValidator(e: IcComponentEditor): void {
        if (!$.isEmptyObject(this.validatorOptions)) {
            this.validator = new IcValidator(this.validationEngine, e, this.validatorOptions);

            // Override applyValidationResults from iXing.GroupConfig.js
            // We have to do this since one checkbox acts as the validation host
            // but it does not display the validation
            this.validator.adapter.applyValidationResults = function (rule): void {
                const errors = [];
                const warnings = [];
                for (let i = 0; i < rule.validationRules.length; i++) {
                    if (!rule.validationRules[i].enabled) continue;
                    if (!_.isNil(rule.validationRules[i].warning) && rule.validationRules[i].warning) {
                        if (!rule.validationRules[i].isValid) {
                            warnings.push(rule.validationRules[i]);
                        }
                    } else {
                        if (!rule.validationRules[i].isValid) {
                            errors.push(rule.validationRules[i]);
                        }
                    }
                }
                for (let j = 0; j < rule.brokenRules.length; j++) {
                    errors.push(rule.brokenRules[j]);
                }

                const isValid = errors.length === 0;
                const error = !isValid ? errors[0] : rule.validationRules[0];
                const announceMessage = !!_.find(rule.validationRules, { 'announceMessage': true });
                if (error && error["message"] && _.isFunction(error["message"])) {
                    error.message = error.message();
                }
    
                this.config.isValid = isValid;
                this.config.validationSummary = error ? error.message : "";
    
                if (!isValid && announceMessage) {
                    this.textAnnouncer.announce({ text: error.message });
                }
            };
        }
    };

    private updateTranslations(): void {
        this.dynamicReplacementService.updateComponentTranslationProperties(this.options, this.config.translationId);
        this.utilService.createADALabelAttributes(this.options);
    }

    private setCheckBoxModelValue(key: string, value: boolean): boolean {
        if (value === null) {
            value = false;
        }

        if (value) {
            return this.tryAddItem(key);
        } else {
            return this.tryRemoveItem(key);
        }
    }

    private tryAddItem(item: string): boolean {
        let modelValue = this.model.value.split(',');

        if (modelValue.includes(item)) {
            return false;
        } else {
            // This has the effect of sorting modelValue by
            // this.config.checkboxes while also inserting item.
            modelValue = this.config.checkboxes.filter((x: string) => {
                return modelValue.includes(x) || x === item;
            });

            this.model.value = modelValue.join(',');
            return true;
        }
    }

    private tryRemoveItem(item: string): boolean {
        let modelValue = this.model.value.split(',');

        if (!modelValue.includes(item)) {
            return false;
        } else {
            // Remove item and sort modelValue by $sC.checkboxes.
            modelValue = this.config.checkboxes.filter((x: string) => {
                return modelValue.includes(x) && x !== item;
            });
            this.model.value = modelValue.join(',');
            return true;
        }
    }

}