import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap, zipAll } from 'rxjs/operators';
import { AppsConstants } from '../state/apps.constants';
import { AppsFacade } from '../state/apps.facade';
import { ApplicationInformation } from './application-information.service';
import { DynamicReplacementService } from './dynamic-replacement.service';
import { WorkflowApplicationTree } from './workflow-application-tree.service';
import { ApiClientService } from './api-client.service';
import { CacheManagerService } from './cachemanager.service';
import { EcdGroupService } from './clientservices/ecdgroup.service';
import { ComponentService } from './component.service';
import { ConfigService } from './config.service';
import { ThemeService } from './theme.service';
import { PersonalizationService } from './personalization.service';

@Injectable()
export class WorkflowStepResolver implements Resolve<boolean> {

    private steps: any;
    private currentWorkflow: string;

    constructor(private configService: ConfigService,
        private appsFacade: AppsFacade,
        private apiClientService: ApiClientService,
        private ecdGroupService: EcdGroupService,
        private themeService: ThemeService,
        private componentService: ComponentService,
        private applicationInformation: ApplicationInformation,
        private workflowApplicationTree: WorkflowApplicationTree,
        private dynamicReplacementService: DynamicReplacementService,
        private personalizationService: PersonalizationService,
        private cacheManagerService: CacheManagerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        const currentStepName = route.data.step;

        return this.configService
            .load(route.data.workflow)
            .pipe(
                switchMap((config: WorkflowConfiguration) => {

                    this.componentService.cleanApplicationPopupRef();

                    if (this.currentWorkflow !== config.constants.workflow) {
                        this.steps = {};
                        _.merge(this.steps, config.canvas._steps);
                    }

                    const themeApps = this.themeService.getThemeApplications();
                    const step = currentStepName.toiXingName();
                    const applets = this.getStepApplets(step, this.steps, themeApps, true);

                    const { workflow, contextName, persistContext, contextFields, stateMode } = config.constants;
                    this.appsFacade.setContextName(contextName);
                    this.appsFacade.setThemeName(IX_Theme.themeName);
                    this.appsFacade.setStateMode(stateMode);

                    const statePromise = this.personalizationService.initPersonalization(workflow, contextName, persistContext, contextFields);
                    const replacementsPromise = this.getAllWFServerReplacements(workflow, applets);

                    const $routeParams = {}; // TODO: get from router.
                    this.setECDGRequest(applets, $routeParams);

                    this.currentWorkflow = config.constants.workflow;

                    return from([statePromise, replacementsPromise, of(contextName)]);
                }),
                zipAll(),
                switchMap((result: any[]) => {
                    const contextName: string = _.last(result);
                    if (!_.isEmpty(contextName)) {
                        const contextState = this.personalizationService.getAppPersonalizationState(contextName);
                        this.appsFacade.updateAppState(contextName, contextState);
                    }
                    return of(true);
                }),
                catchError(error => {
                    console.error(error);
                    return of(true);
                })
            );
    }

    private getStepApplets(step, steps, themeApps, includeDeeplyLinkedApps): Applet[] {
        return this.workflowApplicationTree.getStepApplets(steps[step].name, themeApps, includeDeeplyLinkedApps);
    }

    private isFieldAppReferenced(fieldName: string): boolean {
        return fieldName.indexOf('.') !== -1;
    }

    private getAppReferenced(fieldNameStr: string): any {
        const parts = fieldNameStr.split(".");
        return {
            appName: parts[0].replace(/_/g, '.'),
            fieldName: parts[1]
        };
    }

    private getValuesFromUrl(routeParams) {
        const urlContext = { context: {} };
        for (const param in routeParams) {
            if (!this.isFieldAppReferenced(param)) continue;
            const urlInfo = this.getAppReferenced(param);
            if (_.isNil(urlContext.context[urlInfo.appName])) {
                urlContext.context[urlInfo.appName] = {};
            }
            urlContext.context[urlInfo.appName][urlInfo.fieldName] = routeParams[param];
        }
        return urlContext;
    }

    private getDataFromUrlContext(urlContext, appName) {
        return JSON.stringify(urlContext.context[appName]);
    }

    private getCacheKeyForECDG(request: any): string {
        const hashCode = _.isEmpty(request) ? "" : JSON.stringify(request).IXhashCode();
        return request.ApplicationName + request.ServerCallType + hashCode + "_ecdg";
    }

    private setECDGRequest(applets: Applet[], routeParams) {

        const directive = routeParams["IX_OB"];
        const request = { Requesters: [] };
        const includeTriggerDirectives = _.isNil(directive) ? false : !_.isNil(AppsConstants.triggerDirective[directive]);
        const urlContext = this.getValuesFromUrl(routeParams);

        applets.forEach((app: Applet) => {
            let requests = this.applicationInformation.getPageLoadRequests(app.name);
            if (_.isNil(requests) || _.isEmpty(requests)) return;
            requests.forEach((item) => {
                if (_.isEmpty(item.containerApplication)) {
                    item.containerApplication = app.name;
                }
                request.Requesters.push(item);
            });
            if (!includeTriggerDirectives) return;
            requests = this.applicationInformation.getEcdRequestForTriggerDirective(app.name, app.containerName, directive);
            if (_.isNil(requests) || _.isEmpty(requests)) return;
            if (_.isEmpty(requests.containerApplication)) {
                requests.containerApplication = app.containerName;
            }
            requests.Data = this.getDataFromUrlContext(urlContext, app.name);
            request.Requesters.push(requests);
        });

        let makeCall = false;
        const cacheKeyMap = {};
        request.Requesters.forEach((request) => {
            const key = this.getCacheKeyForECDG(request);
            cacheKeyMap[request.ApplicationName] = key;
            // If the app does NOT support key caching then we always make the call
            if (!this.applicationInformation.supportsKeyCaching(request.ApplicationName)
                || !this.cacheManagerService.has(key)) {
                this.cacheManagerService.delete(key);
                this.cacheManagerService.setPromise(key);
                makeCall = true;
            }
        });

        if (makeCall) {
            return this.ecdGroupService.request(request)
                .then((data) => {
                    for (const response in data.Responses) {
                        const key = cacheKeyMap[response];
                        if (this.cacheManagerService.has(key)) continue;
                        this.cacheManagerService.resolvePromiseWith(response, data.Responses[response]);
                    }
                    return true;
                })
                .catch((error) => IX_Log('sdkSvc', '_setECDGRequest fail', error));
        }
        return Promise.resolve(false);
    }

    private getAllWFServerReplacements(applicationName: string, applets: any[]): Promise<any> {
        const dynamicReplacementMap = {};
        const uniqueReplacementMap = {};
        const uniqueServerSideFieldMasksMap = {};

        applets.forEach(applet => {
            const appInfo = this.applicationInformation.getAppInfo(applet.name);
            if (appInfo.hasServerSideFieldMask)
                uniqueServerSideFieldMasksMap[applet.name] = true;
            const dynamicReplacements = this.applicationInformation.getAppletStringsToReplace(applet.name);
            for (const fieldName in dynamicReplacements) {
                const fieldReplacements = dynamicReplacements[fieldName];
                fieldReplacements.forEach(dynamicReplacement => {
                    if (!this.dynamicReplacementService.isServerSideReplacement(dynamicReplacement)
                        || this.cacheManagerService.has(dynamicReplacement)) return;
                    if (!dynamicReplacementMap[applet.name]) dynamicReplacementMap[applet.name] = [];
                    dynamicReplacementMap[applet.name].push(dynamicReplacement);
                    uniqueReplacementMap[dynamicReplacement] = true;
                });
            }
        });

        let makeCall = false;
        for (const dynamicReplacement in uniqueReplacementMap) {
            if (this.cacheManagerService.has(dynamicReplacement)) continue;
            this.cacheManagerService.setPromise(dynamicReplacement);
            makeCall = true;
        }

        if (makeCall) {
            const dynamicReplacements = Object.keys(uniqueReplacementMap);
            const serverSideFieldMasks = Object.keys(uniqueServerSideFieldMasksMap);
            const requestData = {
                applicationName,
                dynamicReplacements,
                serverSideFieldMasks
            };
            return this.apiClientService.makeServerReplacementRequest(requestData)
                .then((replacementResults) => {
                    const cachePolicy = this.cacheManagerService.dynamicReplacementCachePolicy;
                    for (const dynamicReplacement in replacementResults) {
                        if (this.cacheManagerService.has(dynamicReplacement)) continue;
                        const dynamicReplacementValue = replacementResults[dynamicReplacement];
                        this.cacheManagerService.resolvePromiseWith(dynamicReplacement, dynamicReplacementValue, cachePolicy);
                    }
                    return true;
                })
                .catch((error) => IX_Log('dynamic', 'createServerReplacementPromise fail', error));
        }
        return Promise.resolve("No dynamic replacements found!");
    }

    public isStepMissingFromRoute(stateUrl: string): boolean {
        const currentUrlSplit = stateUrl.split("/");
        return currentUrlSplit.length < 3
            || currentUrlSplit[2].split('?')[0].length === 0
            || /[^a-z0-9]/i.test(currentUrlSplit[2].split('?')[0]);
    }
}
