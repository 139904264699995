/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
var aria = require('./dx.aria');

ui.dxTreeView = require('devextreme/ui/tree_view');

var DISABLED_STATE_CLASS = "dx-state-disabled";
var OPENED_NODE_CONTAINER_CLASS = "dx-treeview-node-container-opened";
var TOGGLE_ITEM_VISIBILITY_CLASS = "dx-treeview-toggle-item-visibility";
var TOGGLE_ITEM_VISIBILITY_OPENED_CLASS = "dx-treeview-toggle-item-visibility-opened";

var TreeView = DevExpress.ui.dxTreeView.redefine({
    setAria: function () {
        aria.setAria.apply(this, arguments);
    },
    filterAria: function (args) {
        switch (args.name) {
            case "expanded":
                /* Do not add aria-expanded=false when the are no children. */
                if (!_.isEmpty(args.target) && args.target.find(".dx-item").length == 0)
                    args.ignore = true;
                break;
        }
    },
    _setFocusedItem : function(element) {
        this.callBase(element);
        
        if (this == null)
            return;

        const component = this;
        const icAccessibilitySvc = component.option("ic.accessibilityService");
        if (icAccessibilitySvc == null)
            return;

        icAccessibilitySvc.addAccessibilityToTreeViewOnFocusChange(component);
    },
    _focusOutHandler : function(event) {
        this.callBase(event);

        if (this == null)
            return;

        const component = this;
        const icAccessibilitySvc = component.option("ic.accessibilityService");
        if (icAccessibilitySvc == null)
            return;

        icAccessibilitySvc.addAccessibilityToTreeViewOnFocusOut(component);
    },
    _refreshActiveDescendant : function() {
        /* Not required. */
    },
    _renderToggleItemVisibilityIcon: function ($node, node) {
        const $icon = $("<div>")
            .addClass(TOGGLE_ITEM_VISIBILITY_CLASS)
            .appendTo($node);

        //This if-block supports adding data-attributes to the toggle-icon
        const $utilSvc = this.option('ic.utilService');
        if (node.dataAttributes && $utilSvc) {
            const config = {
                dataAttributes: node.dataAttributes
            }
            $utilSvc.decorateElementWithDataAttributes($icon, config);
        }

        if (node.internalFields.expanded) {
            $icon.addClass(TOGGLE_ITEM_VISIBILITY_OPENED_CLASS);
            $node.parent().addClass(OPENED_NODE_CONTAINER_CLASS);
        }

        if (node.internalFields.disabled) {
            $icon.addClass(DISABLED_STATE_CLASS);
        }

        this._renderToggleItemVisibilityIconClick($icon, node);
    },
});

DevExpress.registerComponent('dxTreeView', TreeView);
