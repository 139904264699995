/* eslint-disable */
const DevExpress = require('devextreme/bundles/modules/core');
const ui = require('devextreme/bundles/modules/ui');
ui.dxMultiView = require('devextreme/ui/multi_view');
const _renderer = _interopRequireDefault(require("devextreme/core/renderer"));
const _visibility_change = require("devextreme/events/visibility_change");
const _type = require("devextreme/core/utils/type");

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        "default": obj
    }
}

const MULTIVIEW_ITEM_HIDDEN_CLASS = "dx-multiview-item-hidden";

const dxMultiView = DevExpress.ui.dxMultiView.redefine({
    _updateItemsVisibility: function(selectedIndex, newIndex) {

        const $itemElements = this._itemElements();
        $itemElements.each(function(itemIndex, item) {
            // region IC
            const isHidden = _.isUndefined(newIndex) ? (itemIndex !== selectedIndex && itemIndex !== newIndex) : itemIndex !== newIndex;
            // endregion IC
            const $item = (0, _renderer.default)(item);
            if (!isHidden) {                   
                this._renderSpecificItem(itemIndex)
            }
            $item.toggleClass(MULTIVIEW_ITEM_HIDDEN_CLASS, isHidden);
            this.setAria("hidden", isHidden, $item);
        }.bind(this));
    },
    _renderSpecificItem: function(index) {
        const $item = this._itemElements().eq(index);

        // Using find here always returns false resulting in re-rendering pre rendered content
        // var hasItemContent = $item.find(this._itemContentClass()).length > 0;
        // Using getElementsByClassName returns correct value as does not require preceding dot and should perform better anyway
        const hasItemContent =  $item.length > 0 && $item[0].getElementsByClassName(this._itemContentClass()).length > 0;

        if ((0, _type.isDefined)(index) && !hasItemContent) {
            this._deferredItems[index].resolve();
            (0, _visibility_change.triggerResizeEvent)($item)
        }
    },
});

DevExpress.registerComponent('dxMultiView', dxMultiView);
