/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import numeral from 'numeral';
import '../libraries/numeral.extensions';
import Sugar from 'sugar/date';
import '../libraries/sugar.extensions';
import DevExpress from 'devextreme/bundles/modules/core';
import { getCurrentLocale } from '../translate/locale.functions';
import { AppsConstantsFacade } from './apps-constants.facade';
import { TranslateFacadeService } from './translate-facade.service';
import { WorkflowConfigurationService } from './workflow-configuration.service';
import UtilityFunctions from '../utility.functions';

@Injectable()
export class FieldFormatService {

    private currentLocale: string;
    private _localeFieldFormats: Record<string, any>;

    constructor(private appsConstantsFacade: AppsConstantsFacade,
        private translate: TranslateFacadeService,
        private workflowConfigurationService: WorkflowConfigurationService) {
        this.appsConstantsFacade.configuration$
            .pipe(
                filter(action => action?.contains("reload-service-configuration")),
                tap(() => this.reloadConfiguration())
            )
            .subscribe();
        this.translate.onLangChange.subscribe(() => this.setLocale());
        this.setLocale();
    }

    private setLocale() {
        let locale = getCurrentLocale();
        locale = escape(locale);
        this.currentLocale = locale;
        this.setNumeralLocale(locale);
        try {
            // Get language code without full culture and dynamically import locales as needed
            const langCode = locale.substring(0, 2);
            if (langCode == 'en') Sugar.Date.setLocale(locale); // Sugar lib does not have en locale
            else import(`sugar/locales/${langCode}`).then(() => Sugar.Date.setLocale(locale));
            import(`devextreme/dist/js/localization/dx.messages.${langCode}.js`).then(() => DevExpress.localization.locale(langCode));
            // eslint-disable-next-line no-empty
        } catch (e) {
            console.warn("locale missing:", e);
        }
    }

    private setNumeralLocale(newLocale: string): void {
        const normalizeLocale = this.getNormalizeNumeralLocale(newLocale);
        numeral.locale(normalizeLocale);
    }

    private getNormalizeNumeralLocale(newLocale: string): string {
        newLocale = newLocale.toLowerCase();
        if (_.isNil(numeral.locales[newLocale])) {
            newLocale = newLocale.split('-')[0];
            if (_.isNil(numeral.locales[newLocale]) && IX_DEBUG_SETTINGS.fldFrmt.debug) {
                IX_Log('component', 'Locale is not supported by numeral', newLocale);
            }
        }
        return newLocale;
    }

    getFormat(format) {
        const _format = this._localeFieldFormats[this.currentLocale][format];
        const debug = IX_DEBUG_SETTINGS.fldFrmt.debug;
        const verbose = IX_DEBUG_SETTINGS.fldFrmt.verbose;
        if (debug) {
            if (verbose)
                console.log("Getting info for  ", format, " info ", _format);
            else
                console.log("Getting info for  ", format, " found ", !_.isNil(_format));
        }
        return _format;
    }

    format(formatName, input) {

        if (IX_DEBUG_SETTINGS.fldFrmt.debug) {
            console.log("Formatting ", input, " with ", formatName);
        }

        const value = this.getValue(input);
        const format = this.getFormat(formatName);

        if (!_.isPlainObject(format))
            return value == null ? "" : value;

        if (value == null && format.isNFS == "false")
            return "";

        if (format.p === "numeral") {

            this.setNumeralOptions(format);

            let auxValue = numeral(value);

            // Scale is applied whatever so ensure iXing FieldFormats does not scale percentages unless doing other scaling
            if (this.isNumber(format.s) && this.isNumber(auxValue.value())) {
                const floatingPointFix = this.getFloatingPointFixMultiplier(auxValue.value(), format.s);
                const scaledValue = Math.round(auxValue.value() * parseFloat(format.s) * floatingPointFix) / floatingPointFix;
                auxValue = numeral(scaledValue);
            }

            //checking for minimum value of 0.000001 for numeraljs to work properly
            if (this.isNumber(auxValue.value()) && Math.abs(auxValue.value()) < 0.000001) {
                auxValue = numeral(0);
            }

            if (auxValue.value() > -0.5 && auxValue.value() < 0 &&
                format.f.indexOf('$') === 0 && !format.f.match(/(-|\+|\()/)) {
                // Hacky fix taken from https://github.com/adamwdraper/Numeral-js/issues/615
                auxValue = numeral(auxValue.format(format.f.substring(1)));
            }

            const result = auxValue.format(format.f);
            numeral.reset();
            return result;
        }

        if (format.p === "sugar") {
            return this.getDateFormat(value, format);
        }
    }

    private getDateFormat(value, format): string {
        if (value) {
            const auxValue = this.truncateTimeIfNecessary(format, value);
            const localeSugarDate = Sugar.Date.create(value);
            const sugarDate = new Sugar.Date(value);
            let sugarFormat = this.getSugarDateFormat(format.f);
            if (!sugarDate.isValid().raw) {
                IX_Log("fldFrmt", "Invalid Date", auxValue);
                return auxValue;
            }
            if (format.withBrowserTimeZone === 'true') {
                sugarFormat += ' ' + this.getBrowserTimeZone();
            }
            return sugarDate.format(sugarFormat).raw;
        }
        return;
    }

    unformat(formatName, value, originalValue?) {

        const format = _.isString(formatName) ? this.getFormat(formatName) : formatName;

        if (!_.isPlainObject(format))
            return _.isNil(originalValue) ? value : originalValue;

        if (format.p === "numeral") {

            // If null and zero share explicitly set format, return null unless both formats are 0
            if (format.isNFS == "true" && !_.isNil(format.z) && format.n == format.z && format.z == value)
                return (format.z == "0") ? 0 : null;

            this.setNumeralOptions(format);

            let auxValue = numeral(value);
            // Scale is applied whatever so ensure iXing FieldFormats does not scale percentages unless doing other scaling
            if (this.isNumber(format.s) && this.isNumber(auxValue.value())) {
                const floatingPointFix = this.getFloatingPointFixMultiplier(auxValue.value(), format.s);
                const scaledValue = Math.round((auxValue.value() * floatingPointFix)) / parseFloat(format.s) / floatingPointFix;
                auxValue = numeral(scaledValue);
            }

            const result = auxValue.value();
            numeral.reset();
            return result;
        }

        if (format.p === "sugar") {
            let val = _.isNil(originalValue) ? value : originalValue;
            if (format.withBrowserTimeZone === 'true' && 'string' === typeof val) {
                val = val.replace(' ' + this.getBrowserTimeZone(), '');
            }

            const formatted = new Sugar.Date(val);
            if (!formatted.isValid().raw) {
                IX_Log("fldFrmt", "Invalid Date", val);
                return val;
            }
            return formatted.iso().raw;
        }
    }

    setNumeralOptions(format) {
        numeral.reset();
        // Get currency symbol based on locale value
        if (UtilityFunctions.getThemeProperty('CurrencySymbolByLocale')) {
            const currentLocale = getCurrentLocale().toLowerCase();
            if (numeral.locales[currentLocale])
                numeral.locale(currentLocale);
        }
        // numeral js library will apply zero and null formats without honouring locale
        if (!_.isNil(format.z) && format.z.indexOf("$") > -1) {
            format.z = numeral(0).format(format.f);
        }
        if (!_.isNil(format.n) && format.n.indexOf("$") > -1) {
            format.n = numeral(null).format(format.f);
        }

        numeral.zeroFormat(!_.isNil(format.z) ? format.z : "0");
        numeral.nullFormat(!_.isNil(format.n) ? format.n : "");
        numeral.options.scalePercentBy100 = (format.percentnotmultiplied !== "true");

        // Specific to icNumberBox, use library to format value and keep text as-is
        if (format.percentonlyvaluescaled === "true") {
            numeral.options.scalePercentBy100 = false;
        }
    }

    getCurrencySymbol() {
        if (UtilityFunctions.getThemeProperty('CurrencySymbolByLocale')) {
            const currentLocale = getCurrentLocale().toLowerCase();
            if (numeral.locales[currentLocale]) {
                numeral.locale(currentLocale);
                return numeral.locales[currentLocale].currency.symbol;
            }
        }
        return "$";
    }

    getFormattedValue(format, value) {
        if (typeof value === "string"
            && _.startsWith(value, "{Date:")
            && _.endsWith(value, "}")) {
            // Do not format unsubstituted date default,
            // it is not a date, should not be formatted
            return value;
        }

        let formattedValue = this.format(format, value);//Normalize the input
        if (formattedValue) {
            formattedValue = this.unformat(format, formattedValue, value);
        }
        return formattedValue;
    }

    private truncateTimeIfNecessary(format, value) {
        if (format.f.indexOf("hh") < 0 && format.f.indexOf("mm") < 0 && format.f.indexOf("ss") < 0) {
            if (value.length > 10 && new Sugar.Date(value).isValid().raw)
                return value.substr(0, 10);
        }
        return value;
    }

    private reloadConfiguration() {
        this._localeFieldFormats = this.workflowConfigurationService.getFieldFormats();
    }

    private getSugarDateFormat(format: string) {
        const arr = ['{'];
        let lastTokenIsSpecialChar = false;
        for (let i = 0; i < format.length; i++) {
            switch (format[i]) {
                case '/':
                case ':':
                case ' ':
                case ',':
                case '-':
                case 'T':
                case '.':
                    if (!lastTokenIsSpecialChar) {
                        arr.push('}');
                        arr.push(format[i]);
                    } else {
                        arr.push(format[i]);
                    }
                    lastTokenIsSpecialChar = true;
                    continue;
                default:
                    if (lastTokenIsSpecialChar)
                        arr.push('{');
                    lastTokenIsSpecialChar = false;
                    break;
            }
            arr.push(format[i]);
        }
        arr.push('}');
        return arr.join("");
    }

    private getBrowserTimeZone() {
        return new Date().toString().match(/\((.+)\)/)[1];
    }

    private getValue(propValue) {
        if (_.isNil(propValue)) return null;
        if (_.isPlainObject(propValue)) {
            if (_.isDate(propValue))
                return propValue;
            return _.isNil(propValue.value) ? null : propValue.value;
        }
        return propValue;
    }

    private isNumber(input) {
        // Exclude NaN and Infinity
        const value = parseFloat(input);
        return _.isNumber(value) && isFinite(value);
    }

    private getFloatingPointFixMultiplier(value, scale) {
        // multiply as large as possible ensure don't exceed max value
        let multiplyZeros = 15 - Math.floor(value).toString().length - Math.floor(scale).toString().length;
        let fps = "1";
        while (multiplyZeros > 0) {
            fps += "0";
            multiplyZeros--;
        }
        return parseInt(fps);
    }
}
