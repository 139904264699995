<div class="ic-pills-list">
    <div *ngFor="let pill of pills">
        <div class="ic-pills-list-pill">
            <div class="ic-pills-list-pill-file-name" (click)="pillClicked(pill.value)">
                {{pill.display}}
            </div>
            <div class="ic-pills-list-pill-x-button" *ngIf="config.showXButton?.toLowerCase() === 'true'" (click)="xButtonClicked(pill.value)">
                <i class="dx-icon dx-icon-remove"></i>
            </div>
        </div>
    </div>
</div>
