<div class="ic-menu-secondary">
  <ic-menu-default
    [cssClass]="cssClass"
    [config]="config"
    [menu]="menu"
    [items]="items"
    [isSecondary]="true"
    [context]="context"
  ></ic-menu-default>
</div>
