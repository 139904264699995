<dx-popup
  [class]="['ic-modal-generic-popup', customCssClass]"
  [showTitle]="true"
  [title]="options.title"
  [showCloseButton]="false"
  [focusStateEnabled]="false"
  [visible]="true"
  minWidth="350"
  minHeight="164"
  [width]="width"
  [height]="height"
  (onContentReady)="onContentReady"
  (onHiding)="onHiding"
  (onShown)="onShown"
>
  <div *dxTemplate="let data of 'content'">
    <div>
      {{ text }}
    </div>
    <dx-button class="button-primary" *ngIf="onPrimary" [text]="options.actions.onPrimary.text" (onClick)="(onPrimary)"> </dx-button>
    <dx-button class="button-additional" *ngIf="onSecondary" [text]="options.actions.onSecondary.text" (onClick)="(onSecondary)"> </dx-button>
    <dx-button class="button-additional" *ngIf="onTertiary" [text]="options.actions.onTertiary.text" (onClick)="(onTertiary)"> </dx-button>
  </div>
</dx-popup>
