import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommandListCollectionService } from "./command-list-collection.service";
import { BaseEffectCommand } from './base-effect.command';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { InputApplication } from '../components/input-application';
import { UtilService } from '../services/util.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class ConditionalRedirectResolverCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private utilService: UtilService,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.conditionalRedirectResolverCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.conditionalRedirectResolverCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appState = this.getAppState(commandConfig.appName, appsStore);
                const { workflow, themeName } = appsStore;
                const shouldIntercept = this.shouldInterceptRedirectToDocument(commandConfig.appName, appState);
                if (shouldIntercept) return throwError("Missing implementation of redirectToDocumentResolver command");
                return this.conditionalRedirectResolver(appState, commandConfig, workflow, themeName)
                    .pipe(
                        switchMap((mapIndex) => {
                            if (_.isNumber(mapIndex) && mapIndex >= 0)
                                return this.getNextActions(commandConfig, mapIndex);
                            return throwError("Conditional Resolver unable to determine field map. " + mapIndex);
                        })
                    )
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route })
        })
    );

    conditionalRedirectResolver(appContext: AppState, commandConfig: CommandConfig,
        workflowName: string, themeName: string): Observable<number> {
        const conditionRules = this.getConditionalRules(commandConfig);
        const promise = this.utilService.updateConditionalFormatRulesContext(commandConfig.appName, appContext);
        return from(promise)
            .pipe(
                switchMap((contextObj) =>
                    of(this.executeConditionalRedirectRules(conditionRules, contextObj, workflowName, themeName))
                )
            );
    }

    shouldInterceptRedirectToDocument(appName: string, appState: any): boolean {
        const appComponent = this.domComponentRetrievalService.getAppComponent(appName) as InputApplication;
        if (appComponent?.config && appComponent.config["documentViewerApp"] != null
            && appComponent.config["openPDFsInDocumentViewer"] == "true"
            && (appState?.OriginalFileName?.endsWith('.pdf')
                || appState?.SecOriginalFileName?.endsWith('.pdf'))) {
            return true;
        }
        return false;
    }

    private executeConditionalRedirectRules(conditions: any, appContext: Record<string, any>,
        workflowName: string, themeName: string): number {
        for (const field in conditions.fieldToRuleSet) {
            const rules = conditions.ruleSets[field];
            for (let i = 0; i < rules.length; i++) {
                const rule = rules[i];
                const ruleFunction = _.isNil(rule.functionName) ? rule.condition : this.getWindowFunction(workflowName, themeName, rule.functionName);
                const result = ruleFunction.call(appContext);
                if (result) return rule.fieldMapIndex;
            }
        }
        return -1;
    }

}
