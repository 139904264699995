import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { RouterService } from '../services/router.service';
import { CommandListCollectionService } from "./command-list-collection.service";
import { ApplicationInformation } from '../services/application-information.service';
import { HelpersService } from '../services/helpers.service';
import { AppsConstants } from '../state/apps.constants';
import { CacheManagerService } from '../services/cachemanager.service';
import { AppsFacade } from '../state/apps.facade';
import { EventsService } from '../services/events.service';


@Injectable()
export class RedirectCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private router: RouterService,
        private applicationInformation: ApplicationInformation,
        private helpersService: HelpersService,
        private appsFacade: AppsFacade,
        private eventsService: EventsService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.redirectMapperCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    const promise = this.redirect(commandConfig);
                    return this.waitAndContinueNextActions(promise, commandConfig);
                })
            )
    );

    private redirect(commandConfig: CommandConfig): Promise<any> {

        this.appsFacade.resetReplayableEvents();

        const fieldMap = this.getRedirectOrPopUpFieldMap(commandConfig);
        if (!this.applicationInformation.isAppWithinWorkflow(fieldMap.targetApp)
            || this.applicationInformation.isBinaryRelay(fieldMap.targetApp)
            || (!_.isNil(fieldMap.targetWF) && !_.isEmpty(fieldMap.targetWF))) {
            return this.redirectOutsideWorkFlow(commandConfig, fieldMap);
        }
        return this.router.redirectToWorkFlowStep(commandConfig, fieldMap);
    }

    private redirectOutsideWorkFlow(commandConfig: CommandConfig, fieldMap: FieldMap): Promise<string> {
        const parameter = this.getCommandParameter(commandConfig, 4);
        const configParams = {
            documentDownloadEvent: this.isTruthy(parameter || "false"),
        };
        const baseUrl = this.router.url();
        const newUrl = this.getNewUrlOutsideWorkFlow(fieldMap, baseUrl);
        const suppressDocViewer = this.getSuppressDocViewerIfNativeApp();
        if (suppressDocViewer && configParams.documentDownloadEvent) {
            this.helpersService.publishOnDocumentDownloadEvent(newUrl, null);            
        } else {
            if (_.isFunction(window.IX_interceptRedirect))
                window.IX_interceptRedirect(newUrl);
            else
                window.location.href = newUrl;
        }
        return Promise.resolve(newUrl);
    }

    private getSuppressDocViewerIfNativeApp() {
        const themeProperty = this.helpersService.getThemeProperty('SuppressDocViewerIfNativeApp', IX_Theme);
        let suppressDocViewer = false;
        if (themeProperty && themeProperty.Value1 && iXing.IX_PlatformMobile) {
            suppressDocViewer = this.isTruthy(themeProperty.Value1);
        }
        return suppressDocViewer;
    }

    private getRedirectOrPopUpFieldMap(commandConfig: CommandConfig) {
        let fieldMap = _.isNil(commandConfig.fieldMap) ? this.getFieldMap(commandConfig) : commandConfig.fieldMap;
        if (_.isString(fieldMap)) {
            fieldMap = {
                sourceApp: "",
                sourceFields: [],
                targetFields: [],
                targetApp: fieldMap,
                directive: AppsConstants.triggerDirective.none
            };
        }
        return fieldMap;
    }

    private getNewUrlOutsideWorkFlow(fieldMap: FieldMap, baseUrl: string | void): string {
        baseUrl = (baseUrl == null) ? this.router.url() : baseUrl;
        const isTargetAppUrl = this.isUrl(fieldMap.targetApp);
        if (isTargetAppUrl)
            return fieldMap.targetApp;
        const isV4App = this.applicationInformation.isV4App(fieldMap.targetApp);
        const params = this.getParamsFromFieldMap(fieldMap, !isV4App);
        let extension = ".aspx";
        let appName = fieldMap.targetApp.replace(/\./g, '_');
        let suffix = "";
        let folder = "";
        const newWF = !_.isEmpty(fieldMap.targetWF);
        if (isV4App && !newWF) { // TODO: how to identify a v4 angular vs angular js workflow
            extension = this.applicationInformation.isBinaryRelay(fieldMap.targetApp) ? ".ashx" : ".aspx;";
            folder = this.applicationInformation.getFolder(fieldMap.targetApp);
            folder = (!_.isNil(folder) && folder != "") ? ("/" + folder) : "";
        } else if (newWF) {
            suffix = fieldMap.targetWF.normalize();
            appName = fieldMap.targetWF.replace(/\./g, '_');
            const urlInfo = this.getIcUrl(baseUrl as string);
            params.push('s=' + fieldMap.targetApp.normalize());
            suffix = urlInfo.prefix + '/w/' + suffix;
        }
        return folder + "/Apps/" + appName + extension + suffix + "?" + params.join("&");
    }

    private getParamsFromFieldMap(fieldMap: FieldMap, isV1: boolean): string[] {
        const params = [];
        fieldMap.urlParams?.forEach(urlParam => params.push(urlParam));
        if (fieldMap.directive && fieldMap.directive != AppsConstants.triggerDirective.none)
            params.push("IX_OB=" + (isV1 ? AppsConstants.triggerDirective[fieldMap.directive] : fieldMap.directive));

        else
            params.push("IX_OB=" + (isV1 ? "NONE" : AppsConstants.triggerDirective.none));
        return params;
    }

}
