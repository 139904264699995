/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import DevExpress from 'devextreme/bundles/modules/core';

@Injectable()
export class DeviceService {

    triggerWebHook(eventType: string, eventData: any, isIframeData: boolean | string): any {
        const result = { success: false, errorMessage: "" };

        if ((isIframeData === true || 'true'.EqualsIgnoreCase(isIframeData?.toString()))
            && eventData && !_.isEmpty(eventData['ic.webhook'])) {
            eventType = eventData['ic.webhook'];
            delete eventData['ic.webhook'];
        }

        if (!eventType) {
            result.errorMessage = 'EventType must be specified for Trigger WebHook.';
            result.success = false;
            return result;
        }

        eventType = _.upperFirst(eventType);

        // Do not send undefined or null event data
        if (_.isNil(eventData)) {
            eventData = "";
        }
        const eventDataJson = _.isObject(eventData) ? JSON.stringify(eventData) : eventData;
        IX_Log("webhook", "EventType: " + eventType, eventDataJson);

        if (this.IX_isAndroid() && window['WebViewFragment']) {
            const webViewFragment = window['WebViewFragment'];
            switch (eventType) {
                case 'DynamicDownload':
                case 'Upload':
                case 'Feature':
                    webViewFragment?.onFeatureEventWebHook(eventDataJson);
                    break;
                case 'Navigate':
                    webViewFragment?.onNavigateWebHook(eventDataJson);
                    break;
                case 'Error':
                    webViewFragment?.onErrorWebHook(eventDataJson);
                    break;
                case 'Analytics':
                    webViewFragment?.onAnalyticsWebHook(eventDataJson);
                    break;
                default:
                    result.errorMessage = 'EventType must be Feature, Navigate, Error, Upload, DynamicDownload, or Analytics';
                    break;
            }
        }
        else if (this.IX_isIOS() && window['webkit']) {
            const messageHandlers = _.get(window, 'webkit.messageHandlers', null);
            switch (eventType) {
                case 'DynamicDownload':
                case 'Feature':
                    messageHandlers?.featureEvent.postMessage(eventDataJson);
                    break;
                case 'Navigate':
                    messageHandlers?.navigate.postMessage(eventDataJson);
                    break;
                case 'Error':
                    messageHandlers?.error.postMessage(eventDataJson);
                    break;
                case 'Upload':
                    messageHandlers?.upload.postMessage(eventDataJson);
                    break;
                case 'Analytics':
                    messageHandlers?.analytics.postMessage(eventDataJson);
                    break;
                default:
                    result.errorMessage = 'EventType must be Feature, Navigate, Error, Upload, DynamicDownload, or Analytics';
                    break;
            }
        }

        result.success = _.isEmpty(result.errorMessage);
        return result;
    }

    getDevice(): any {
        return DevExpress.devices.current();
    }

    getOrientation(): string {
        return DevExpress.devices.orientation();
    }

    isPhone(): boolean {
        return this.getDevice().deviceType === 'phone';
    }

    isIOS(): boolean {
        return this.getDevice().platform === 'ios';
    }

    isAndroidTablet(): boolean {
        const device = this.getDevice();
        return device.android && device.tablet;
    }

    isLandscapeTablet(): boolean {
        return this.getDevice().tablet && this.getOrientation() === 'landscape';
    }

    getAndroidTabletScreenWidth(): number {
        return window.outerWidth;
    }

    getCurrentDeviceWidth(): number {

        if (this.isAndroidTablet())
            return this.getAndroidTabletScreenWidth();

        if (this.isIOS())
            return this.getIOSScreenWidth();

        let currentWidth = window.outerWidth || window.innerWidth;

        if (IX_Theme && IX_Theme?.properties) {
            const canvasResponsiveStrategy = _.find(IX_Theme.properties, p => p.PropertyName.toLowerCase() === "canvasresponsivestrategy");
            if (canvasResponsiveStrategy && canvasResponsiveStrategy.Value1.toLowerCase() === "zoomresize")
                currentWidth = window.innerWidth;
        }

        return currentWidth;
    }

    getIOSScreenWidth(): number {
        const largest = Math.max(screen.width, screen.height);
        const smallest = Math.min(screen.width, screen.height);
        const orientation = window['orientation'] as any;
        return (Math.abs(orientation) === 90) ? largest : smallest;
    }

    isBrowserEdge(): boolean {
        if (navigator.appName === "Netscape") {
            /// in IE 11 the navigator.appVersion says 'trident'
            /// in Edge the navigator.appVersion does not say trident
            if (navigator.appVersion.indexOf('Trident') === -1) return true;
            else return false;
        }
        return false;
    }

    IX_IsHTTPS(): boolean {
        return location.protocol === 'https:';
    }

    IX_GetSecureFlag(): string {
        return this.IX_IsHTTPS() ? ";secure" : "";
    }

    IX_isMacOS(): boolean {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        return isMac;
    }

    IX_isIOS(): boolean {
        const ua = navigator.userAgent;
        const isiOS = /iPad/i.test(ua) || /iPhone/i.test(ua) || /iPhone/i.test(ua) ||
            (/Mac/i.test(ua) && "ontouchend" in document); // added to detect ipad on ios 13+ 
        // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
        return isiOS;
    }

    IX_isAndroid(): boolean {
        const ua = navigator.userAgent;
        const isAndroid = /android/i.test(ua);
        return isAndroid;
    }

    IX_isMobile(): boolean {
        return (/Android|iPhone|iPad|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera);
    }

    IX_isSafari(): boolean {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        return isSafari;
    }
}
