import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, startWith, switchMap } from "rxjs/operators";
import { AppsEntity } from '../state/apps.models';

interface ConfigurationEvent {
    action: string;
    configuration: WorkflowConfiguration;
}

@Injectable()
export class EventsService {

    events$: BehaviorSubject<AppsEntity>;
    configuration$: BehaviorSubject<string>;
    configEvent$: BehaviorSubject<ConfigurationEvent>;

    replayableEvents$: Observable<AppsEntity>;
    _replayableSubject: Subject<AppsEntity>;
    _reset: any;

    private initEvent = {
        id: "init.event",
        state: {}
    };

    constructor(private router: Router) {
        this.events$ = new BehaviorSubject(this.initEvent);
        this.configuration$ = new BehaviorSubject(null);
        this.configEvent$ = new BehaviorSubject(null);

        const { observable, reset, subject } = this.resettable(() => new ReplaySubject<AppsEntity>());
        this.replayableEvents$ = observable;
        this._replayableSubject = subject;
        this._reset = reset;

        this.router.events
            .pipe(filter((e) => e instanceof NavigationStart))
            .subscribe(() => this.publishEvent(this.initEvent));
    }

    publishEvent(event: AppsEntity): void {
        if (_.isEmpty(event)) return;
        this.events$.next(event);
        if (event.replayableEvent) {
            this._replayableSubject.next(event);
        }
    }

    reloadConfiguration(action: string): void {
        this.configuration$.next(action);
    }

    publishStartLoadConfiguration(): void {
        this.configEvent$.next({
            action:  "start-load-configuration",
            configuration: null
        });
    }

    publishEndLoadConfiguration(configuration: any): void {
        this.configEvent$.next({
            action:  "end-load-configuration",
            configuration
        });
    }

    clearReplayableEventsBuffer(): void {
        this._reset();
    }

    private resettable<T>(factory: () => Subject<T>): {
        observable: Observable<T>,
        subject: Subject<T>
        reset(): void,
    } {
        const resetter = new Subject<void>();
        const source = new Subject<T>();
        let destination = factory();
        let subscription = source.subscribe(destination);
        return {
            observable: resetter.asObservable().pipe(
                startWith(null),
                switchMap(() => destination)
            ),
            reset: () => {
                subscription.unsubscribe();
                destination = factory();
                subscription = source.subscribe(destination);
                resetter.next();
            },
            subject: source
        };
    }
}
