import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import * as AppsActions from '../state/apps.actions';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from "./command-list-collection.service";

import { CacheManagerService } from '../services/cachemanager.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { AppsFacade } from '../state/apps.facade';
import { ListApplication } from '../components/list-application';

@Injectable()
export class DatagridPaginationButtonsCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected domComponentRetrievalService: DomComponentRetrievalService,
        protected cacheManagerService: CacheManagerService,
        protected appsFacade: AppsFacade) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.datagridPaginationButtonsCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.datagridPaginationButtonsCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appName = commandConfig.parameters[0];
                const key = commandConfig.parameters[1];

                const appComponent = this.domComponentRetrievalService.getAppComponent(appName) as ListApplication;
                const items = appComponent.gridInstance.getDataSource().items();
                if (items && items.length == 0) {
                    return this.getNextActions(commandConfig);
                }

                if (key.toLowerCase() == 'next') {
                    const nextPageKey = items[0].NextPageKey;
                    this.appsFacade.updateAppState(commandConfig.appName, { NextPageKey: nextPageKey });
                } else {
                    const prevPageKey = items[0].PreviousPageKey;
                    this.appsFacade.updateAppState(commandConfig.appName, { PreviousPageKey: prevPageKey });
                }

                return this.getNextActions(commandConfig);
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

}
