<dx-tab-panel class="ic-tabs" [items]="dxOptions.items"
    [height]="dxOptions.height"
    [focusStateEnabled]="dxOptions.focusStateEnabled" 
    [swipeEnabled]="dxOptions.swipeEnabled"
    [showNavButtons]="dxOptions.showNavButtons" 
    [(selectedIndex)]="selectedIndex"
    (onInitialized)="dxOptions.onInitialized($event)"
    (onContentReady)="dxOptions.onContentReady($event)" 
    (onItemRendered)="dxOptions.onItemRendered($event)"
    (onSelectionChanged)="dxOptions.onSelectionChanged($event)" 
    (onTitleClick)="dxOptions.onTitleClick($event)" 
    (onAccessibility)="dxOptions.onAccessibility($event)">

    <div *dxTemplate="let item of 'title'">
        <ng-container *ngIf="item.hasDynamicReplacement; then dynamicTitle; else normalTitle"></ng-container>
        <ng-template #dynamicTitle>
            <span [replaceValue]='item.dynamicObj' [replaceValueUpdated]='item.dynamicObj.updated'></span>
        </ng-template>
        <ng-template #normalTitle>
            <span>{{ item.tabName }}</span>
        </ng-template>
    </div>

    <div *dxTemplate="let tab of 'item'">
        <ic-canvas [canvas]="tab.tabCanvas" [context]="context" [config]="canvasConfig"></ic-canvas>
    </div>

</dx-tab-panel>