/* eslint-disable @typescript-eslint/camelcase */
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import UtilityFunctions from '../utility.functions';
import { AppsConstants } from '../state/apps.constants';
import { ApiClientService } from './api-client.service';

@Injectable()
export class RouterService {

    private urlInfo: UrlTree;

    constructor(@Inject(DOCUMENT) private document: Document,
        private router: Router,
        private route: ActivatedRoute,
        private apiClientService: ApiClientService,) {
    }

    performDynamicSignOutActions(url: any) {
        this.apiClientService.performDynamicSignOutActions(url);
    }

    reload() {
        window.location.reload();
    }

    url(url?: string): string | void {
        if (_.isNil(url) || _.isEmpty(url)) {
            this.setUrlInfo();
            return this.urlInfo.toString();
        }
        this.navigate(url).catch((e) => this.logNavigateError(e));
    }

    absUrl(): string {
        return this.document.URL;
    }

    path(path: string): string | void {
        if (_.isNil(path) || _.isEmpty(path)) {
            this.setUrlInfo();
            return this.urlInfo.fragment;
        }
        this.navigate(path).catch((e) => this.logNavigateError(e));
    }

    search(search: string, paramValue: [string]) {
        if (_.isNil(search) || _.isEmpty(search)) {
            this.setUrlInfo();
            return _.merge({}, this.urlInfo.queryParams);
        }
        const extras = {
            queryParams: {
                search: paramValue
            },
            queryParamsHandling: 'merge'
        } as NavigationExtras;
        this.router.navigate(['.'], extras).catch((e) => this.logNavigateError(e));
    }

    navigate(url: string): Promise<boolean> {
        return this.router.navigateByUrl(url);
    }

    navigateExternal(url: string) {
        if (_.isNil(url) || _.isEmpty(url))
            this.logNavigateError("Invalid url.");
        else
            this.document.location.assign(url);
    }

    navigateExternalOnNewTab(url: string) {
        if (_.isNil(url) || _.isEmpty(url))
            this.logNavigateError("Invalid url.");
        else
            window.open(url, "_blank");
    }

    getUrlConfig(url: string, queryString?: string, homeUrl?: string): any {
        let external = true;
        if (_.isNil(url) || _.isEmpty(url) || !url.startsWith('/'))
            return { url, external };
        external = false;
        const parts = url.substr(1).split('/');
        return {
            url,
            external,
            workflow: parts[0],
            step: parts[1],
        };
    }

    redirectToWorkFlowStep(commandConfig: CommandConfig, fieldMap: FieldMap): Promise<boolean> {
        return this.redirectWithinWorkFlow(commandConfig, fieldMap);
    }

    redirectWithinWorkFlow(commandConfig: CommandConfig, fieldMap: FieldMap): Promise<boolean> {
        const stepName = this.getNextStep(fieldMap);
        const shouldSetUrlQueryString = this.shouldSetUrlQueryString();
        const extras = {
            queryParams: {},
            relativeTo: this.route
        } as NavigationExtras;

        if (shouldSetUrlQueryString && _.isArray(fieldMap.urlParams)) {
            fieldMap.urlParams.forEach((urlParam: string) => {
                const [keyParam, valueParam] = urlParam.split('=');
                extras.queryParams[keyParam] = valueParam;
            });
            const enums = AppsConstants;
            extras.queryParams["IX_OB"] = (fieldMap?.directive != enums.triggerDirective.none)
                ? fieldMap.directive : enums.triggerDirective.none;
        }

        const workflowName = _.head(this.router.url.substr(1).split('/'));
        const routePath = "/" + workflowName + "/" + stepName;

        const redirectPromise = this.router.navigate([routePath], extras);
        redirectPromise.then(() => {
            $(window).trigger("resize");
            // Defines the class that will get automatically focused on Redirect. 
            const focusOnCssClass = (commandConfig.parameters.length > 2) ? commandConfig.parameters[2] : null;
            //Required for JPMC
            //To set the focus to the first element with the class (after the redirect) mentioned in the themeProperties for the app
            IX_setFocusToElementByClassName(focusOnCssClass);
        }).catch(e => this.logNavigateError(e));
        return redirectPromise;
    }

    redirectToStepNoMapping(workflowName: string, newStep: string, queryParams: any): Promise<boolean> {

        let routePath = "/";
        if (!_.isNil(workflowName) && !_.isNil(newStep) && workflowName != "" && newStep !+ "") {
            routePath = routePath + workflowName.toiXingName() + "/" + newStep.toiXingName() + "/";
        }
        const extras = {
            queryParams: queryParams
        } as NavigationExtras;
        return this.router.navigate([routePath], extras);
    }

    private shouldSetUrlQueryString(): boolean {
        const disableUrlQueryString = UtilityFunctions.getThemeProperty('DisableRedirectWithQueryStrings');
        return !disableUrlQueryString?.PropertyName.EqualsIgnoreCase('true');
    }

    private setUrlInfo(): void {
        this.urlInfo = this.router.parseUrl(this.document.URL);
    }

    private logNavigateError(reason: any): void {
        console.error(reason);
    }

    private getNextStep(fieldMap: any): string {
        return _.isString(fieldMap) ? fieldMap.normalize() : fieldMap.targetApp.normalize();
    }
}
