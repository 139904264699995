import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class SetValuesForColumnInListCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.setValuesForColumnInListCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    return this.setValuesForColumnInList(commandConfig)
                        .pipe(
                            switchMap(() => this.getNextActions(commandConfig))
                        )
                })
            )
    );

    setValuesForColumnInList(commandConfig: CommandConfig): Observable<any> {

        const appName = commandConfig.parameters[0];

        if (!this.applicationInformation.isListApp(appName))
            return throwError("Target application is not a list application '" + appName + "'");

        const gridInstance = this.domComponentRetrievalService.getDxComponentInstance({}, appName);
        if (_.isNil(gridInstance))
            return throwError("Unable to retrieve grid for '" + appName + "'");

        gridInstance.beginUpdate();

        const targetColumn = commandConfig.parameters[1];
        const targetValue = commandConfig.parameters[2];

        const visibleRows = gridInstance.getVisibleRows().length; // this is necessary to ensure that we iterate through all rows
        for (let i = 1; i <= visibleRows; i++) {
            const cell = gridInstance.getCellElement(i, targetColumn);
            if (_.isNil(cell)) continue;
            if ((cell.find('input').length > 0 || cell.find(".dx-selectbox").length > 0)
                && gridInstance.cellValue(i, targetColumn) != targetValue) {
                gridInstance.cellValue(i, targetColumn, targetValue);
            }
        }

        gridInstance.endUpdate();

        return of(true);
    }

}
