import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import UtilityFunctions from '../utility.functions';

export interface CachePolicy {
  canBeCached(key: string): boolean;
}

interface DeleteCachePolicy {
  canBeDeleted(key: string): boolean;
}

@Injectable()
export class CacheManagerService {

  _cache = {};
  _promises = {};
  _forceCacheValue = false;

  dynamicReplacementCachePolicy: CachePolicy = {
    canBeCached: (str: string): boolean => {
      let res = false;
      const parameter = str.toLowerCase();
      if (
        parameter.indexOf('{globals:') > -1 ||
        parameter.indexOf('{persistedprofile:samlattribute:') > -1 ||
        parameter.indexOf('{usercontextdefault:') > -1
      ) {
        res = true;
      }
      return res;
    },
  };

  constructor(private router: Router) {
    UtilityFunctions.bindPTierAPI('getCachedServerReplacement', this.get, this);
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        tap(() => this.deleteECDRequests())
      ).subscribe();
  }

  private isDefined(it: unknown): boolean {
    return !_.isUndefined(it);
  }

  forceCache(value?: boolean): boolean {
    if (this.isDefined(value)) {
      this._forceCacheValue = value;
    }
    return this._forceCacheValue;
  }

  getAll(): Record<string, unknown> {
    return this._cache;
  }

  init(cache: Record<string, unknown>): void {
    this._cache = { ...cache };
  }

  get(key: string): unknown {
    return this._cache[key];
  }

  set<T>(key: string, value: T): T {
    if (this.isDefined(key)) this._cache[key] = value;
    return value;
  }

  delete(key: string, removePolicy?: DeleteCachePolicy): void {
    if (!this.isDefined(key)) return;
    if (_.isFunction(removePolicy?.canBeDeleted)) {
      const toDelete = [];
      for (const keyIt in this._cache) {
        if (removePolicy.canBeDeleted(keyIt)) {
          toDelete.push(keyIt);
        }
      }
      for (let i = 0; i < toDelete.length; i++) {
        delete this._cache[toDelete[i]];
      }
    } else {
      delete this._cache[key];
    }
  }

  has(key: string): boolean {
    return this.isDefined(this._cache[key]);
  }

  wasRequested(key: string): boolean {
    return this.isDefined(this._promises[key]);
  }

  getPromise(key: string): Promise<any> {
    if (this.wasRequested(key))
      return this._promises[key].promise;
  }

  private deleteECDRequests(): void {
    const ecdgDeletePolicy = {
      canBeDeleted: (key: string): boolean => key.endsWith("_ecdg") || key.endsWith("_LST")
    };
    this.delete("ecdg", ecdgDeletePolicy);
  }

  setPromise<T>(key: string): Promise<T> {
    if (!this.wasRequested(key)) {
      let resolve;
      let reject;
      const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
      });
      this._promises[key] = { resolve, reject, promise };
    }
    return this._promises[key].promise;
  }

  private returnAndCleanUpPromise(key: string): Promise<any> {
    const promise = this._promises[key].promise;
    delete this._promises[key];
    return promise;
  }

  resolvePromiseWith(key: string, value: unknown, cachePolicy?: CachePolicy): Promise<any> {
    if (this.wasRequested(key)) {
      this._promises[key].resolve(value);
      if (!this.isDefined(cachePolicy) || cachePolicy?.canBeCached(key)) {
        this.set(key, value);
      }
      return this.returnAndCleanUpPromise(key);
    }
  }

  rejectPromiseWith(key: string, value: Error): Promise<any> {
    if (this.wasRequested(key)) {
      this._promises[key].reject(value);
      return this.returnAndCleanUpPromise(key);
    }
  }

  getRequestPromise(key: string): Promise<any> {
    if (this.has(key))
      return Promise.resolve(this.get(key));
    if (this.wasRequested(key))
      return this.getPromise(key);
    return this.setPromise(key);
  }

}
