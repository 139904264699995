import { TextAnnouncer } from "../../commands/text-announcer.service";
import { IcComponent, IcValidatorConfig } from "./ic-validator";

const READONLY_STATE_CLASS = "dx-state-readonly",
    WARNING_CLASS = "ic-warning",
    WARNING_MESSAGE = "ic-warning-message",
    INVALID_MESSAGE_AUTO = "dx-invalid-message-auto",
    INVALID_MESSAGE_ALWAYS = "dx-invalid-message-always",
    VALIDATION_TARGET = "dx-validation-target",
    VALIDATION_MESSAGE_MIN_WIDTH = 100;

export class DevExtremeAdapter {

    public editor: IcComponent;
    private validator: IcValidatorConfig;
    private textAnnouncer: TextAnnouncer;

    constructor(editor: IcComponent, validator: IcValidatorConfig) {
        this.editor = editor;
        this.validator = validator;
    }

    dontValidateFieldMaskValue($s) {
        if (_.has($s, 'config.numberProperties.ValidateFieldMaskedValue')) {
            return $s.config.numberProperties.ValidateFieldMaskedValue.toLowerCase() == 'false';
        }
        return false;
    }

    getValue($s) {
        if (!_.isNil($s?.model?.text) && this.dontValidateFieldMaskValue($s)) {
            return $s.model.text;
        }
        if (!_.isNil($s?.model?.value)) {
            return $s.model.value;
        }
        return this.editor.option("value");
    }

    getCurrentValidationError(validatorOptions) {
        if (validatorOptions && validatorOptions.validationGroup) {
            return false;
        }

        return this.editor.option("validationError")
    }

    bypass() {
        if (this.editor.option("respondingToEvent"))
            return false;

        if (this.editor.option("disabled"))
            return true;

        return false;
    }

    applyValidationResults(rule: any): void {
        const errors = [];
        const warnings = [];
        for (let i = 0; i < rule.validationRules.length; i++) {
            if (!rule.validationRules[i].enabled) continue;
            if (!_.isNil(rule.validationRules[i].warning) && rule.validationRules[i].warning) {
                if (!rule.validationRules[i].isValid) {
                    warnings.push(rule.validationRules[i]);
                }
            } else {
                if (!rule.validationRules[i].isValid) {
                    errors.push(rule.validationRules[i]);
                }
            }
        }
        for (let j = 0; j < rule.brokenRules.length; j++) {
            errors.push(rule.brokenRules[j]);
        }
        this.applyErrors(rule, errors);
        this.applyWarnings(rule, warnings);
    }

    applyErrors(rule, errors) {
        const isValid = errors.length == 0;
        const validationError = !isValid ? errors[0] : rule.validationRules[0];
        const announceMessage = !!_.find(rule.validationRules, { 'announceMessage': true });
        if (validationError && _.isFunction(validationError["message"])) {
            validationError.message = validationError.message();
        }
        this.editor.option({
            isValid,
            validationError,
            // validationStatus: isValid ? 'valid' : 'invalid'
        });
        if (!isValid && announceMessage) {
            this.textAnnouncer.announce({ text: validationError.message });
        }
    }

    applyWarnings(_rule, warnings: any[]) {
        const $element = $(this.editor._$element) as any;
        let _$warningMessage = $element._$warningMessage;
        if (_$warningMessage) {
            _$warningMessage._$element.remove();
            _$warningMessage = null;
        }
        $element.removeClass(WARNING_CLASS);
        if (warnings.length > 0) {
            const isValid = this.editor.option("isValid"),
                validationMessageMode = this.editor.option("validationMessageMode");
            if (isValid && !warnings[0].isValid) {
                $element.addClass(WARNING_CLASS);

                if (warnings && warnings[0] && warnings[0]["message"] && typeof (warnings[0]["message"]) === "function") {
                    warnings[0].message = warnings[0].message();
                }

                _$warningMessage = $("<div/>", { "class": WARNING_MESSAGE }).html(warnings[0].message);
                _$warningMessage = this.createComponent(_$warningMessage, "dxOverlay", {
                    templatesRenderAsynchronously: false,
                    target: this.getValidationMessageTarget(),
                    shading: false,
                    width: "auto",
                    height: "auto",
                    container: $element,
                    position: this.getValidationMessagePosition("below"),
                    closeOnOutsideClick: false,
                    closeOnTargetScroll: false,
                    animation: null,
                    visible: true,
                    propagateOutsideClick: true,
                    _checkParentVisibility: false
                });

                $element._$warningMessage = _$warningMessage;

                $element.toggleClass(INVALID_MESSAGE_AUTO, "auto" === validationMessageMode)
                    .toggleClass(INVALID_MESSAGE_ALWAYS, "always" === validationMessageMode);

                this.setValidationMessageMaxWidth();
            }
        }
    }

    getValidationMessageTarget() {
        return this.editor._$element;
    }

    getValidationMessagePosition(positionRequest) {
        const rtlEnabled = this.editor.option("rtlEnabled"),
            messagePositionSide = "center",
            messageOriginalOffset = this.editor.option("validationMessageOffset"),
            messageOffset = {
                h: messageOriginalOffset.h,
                v: messageOriginalOffset.v
            },
            verticalPositions = "below" === positionRequest ? [" top", " bottom"] : [" bottom", " top"];
        if (rtlEnabled) {
            messageOffset.h = -messageOffset.h
        }
        if ("below" !== positionRequest) {
            messageOffset.v = -messageOffset.v
        }
        return {
            offset: messageOffset,
            boundary: this.editor.option("validationBoundary"),
            my: messagePositionSide + verticalPositions[0],
            at: messagePositionSide + verticalPositions[1],
            collision: "none flip"
        }
    }

    setValidationMessageMaxWidth() {
        if (!this.editor._$element._$warningMessage) {
            return
        }
        if (0 === this.getValidationMessageTarget().outerWidth()) {
            this.editor._$element._$warningMessage.option("maxWidth", "100%");
            return
        }
        const validationMessageMaxWidth = Math.max(VALIDATION_MESSAGE_MIN_WIDTH, this.getValidationMessageTarget().outerWidth());
        this.editor._$element._$warningMessage.option("maxWidth", validationMessageMaxWidth);
    }

    createComponent(element, component, config) {
        config = config || {};
        const synchronizableOptions = $.grep(["rtlEnabled", "disabled"], function (value) {
            return !(value in config);
        });
        let instance;
        if (typeof component == "string") {
            const $element = $(element)[component](config);
            instance = $element[component]("instance");
        }
        return instance;
    }

    getFieldValueMap() {
        const fieldName = this.getFieldName();
        const value = this.getValue(null);
        const fieldValueMap = { [fieldName]: value };
        return this.getAppFieldValueMap(fieldValueMap);
    }

    private getAppFieldValueMap(fieldValueMap) {
        const appName = this.getAppName();
        return { [appName]: fieldValueMap };
    }

    private getAppName() {
        return this.editor.option('appName');
    }

    private getFieldName() {
        let fieldName = this.editor.option('icFieldName');
        if (_.isEmpty(fieldName)) {
            fieldName = this.editor.option('icClassName');
            fieldName = fieldName.replace('CL_', '');
        }
        return fieldName;
    }

    resetAndGetFieldMap() {
        const defaultOptions = this.editor['_getDefaultOptions']();
        const fieldName = this.getFieldName();
        const fieldValueMap = { [fieldName]: null };
        return this.getAppFieldValueMap(fieldValueMap);
    }

    focus() {
        this.editor.focus();
    }
}