import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { BaseEffectCommand } from './base-effect.command';
import { ListApplication } from '../components/list-application';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class RefreshComponentCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.refreshComponentCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    const refreshList = this.refreshComponent(commandConfig);
                    return from(refreshList)
                        .pipe(
                            switchMap((appName: string) => {
                                // Keep list data. Refresh Component will be loading new data in so avoid either flash of empty data or deleting new data
                                const resetActions = this.getResetAppActions(appName, true);
                                return this.appendNextActions(resetActions, commandConfig);
                            })
                        );
                })
            )
    );

    refreshComponent(commandConfig: CommandConfig): Observable<string> {
        const appName = _.first(commandConfig.parameters);
        let message = "Command not supported for V1 apps " + appName;
        if (!this.applicationInformation.isV4App(appName))
            return throwError(message);
        message = "Command not supported for non list apps or the list app does not contain a component - " + appName;
        if (this.applicationInformation.isListApp(appName)
            && this.applicationInformation.isComponent(appName)) {
            const component = this.domComponentRetrievalService.getAppComponent(appName) as ListApplication;
            component?.loadData();
            return of(appName);
        }
        return throwError(message);
    }

}
