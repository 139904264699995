import { ApplicationRef, ComponentRef, Injectable } from '@angular/core';
import { InputApplication } from '../components/input-application';
import { ListApplication } from '../components/list-application';

type IcComponentRef = InputApplication | ListApplication;

@Injectable()
export class ApplicationRefFacade {

    private componentRefMap: Map<string, IcComponentRef>;
    private canvasComponentRefMap: Map<string, ComponentRef<IcComponentRef>[]>;

    constructor(private applicationRef: ApplicationRef) {
        this.componentRefMap = new Map<string, IcComponentRef>();
        this.canvasComponentRefMap = new Map<string, ComponentRef<IcComponentRef>[]>();
    }

    updateCanvasComponentRefs(canvasId: string, componentRef: ComponentRef<IcComponentRef> | any): void {
        this.initialize(canvasId);
        const componentRefs = this.canvasComponentRefMap.get(canvasId);
        componentRefs.push(componentRef);
        this.applicationRef.attachView(componentRef.hostView);
        this.updateComponentRefMap(componentRef);
    }

    destroyCanvasComponentRefs(canvasId: string): void {
        if (!this.canvasComponentRefMap.has(canvasId)) return;
        const componentRefs = this.canvasComponentRefMap.get(canvasId);
        this.detachAndDestroyComponentHostViews(componentRefs);
        this.canvasComponentRefMap.delete(canvasId);
    }

    getAppComponent(appName: string): IcComponentRef {
        if (this.componentRefMap.has(appName))
            return this.componentRefMap.get(appName);
        return null;
    }

    getListDxDataGrid(appName: string): any { // return dxDataGrid;
        const appComponent = this.getAppComponent(appName) as ListApplication;
        return appComponent?.dxDataGrid;
    }

    private initialize(canvasId: string): void {
        if (this.canvasComponentRefMap.has(canvasId)) return;
        this.canvasComponentRefMap.set(canvasId, []);
    }

    private updateComponentRefMap(componentRef: ComponentRef<IcComponentRef>): void {
        const appName = componentRef.instance.applet.name;
        this.componentRefMap.set(appName, componentRef.instance);
    }

    private detachAndDestroyComponentHostViews(componentRefs: ComponentRef<IcComponentRef>[]): void {
        componentRefs.forEach(componentRef => {
            this.applicationRef.detachView(componentRef.hostView);
            componentRef.hostView.destroy();
            this.componentRefMap.delete(componentRef.instance.applet.name);
        });
    }
}
