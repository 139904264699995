import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { AppsConstantsFacade } from '../../services/apps-constants.facade';
import { EventsService } from '../../services/events.service';
import { AppEvent } from '../../state/app-events.enum';
import { ListDataEntity } from '../../state/apps.models';

type PillsListComponentConfig = {
  showXButton: 'true'|'false',
  displayField: string,
  valueField: string,
  _dataDisplayBehaviorOnEventOnly: boolean;
};

type Pill = {
  display: string,
  value: string|number,
};

@Component({
  selector: 'ic-pillslist',
  templateUrl: './pillslist.component.html',
  styleUrls: ['./pillslist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillslistComponent implements OnChanges {

  @Input() config: PillsListComponentConfig;
  @Input() context: unknown;
  @Input() applet: Applet;
  @Input() data: ListDataEntity;
  @Input() componentProperties: unknown;
  @Input() componentConfig: StaticComponentConfig;
  @Input() checkSize: unknown;

  @Output() updateStaticComponent = new EventEmitter<StaticComponentStateChange>();
  @Output() staticComponentItemClick = new EventEmitter<StaticComponentItemClick>();

  pills: Pill[] = [];

  constructor(
    private appsConstantsFacade: AppsConstantsFacade,
    private elementRef: ElementRef,
    private eventsService: EventsService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue) {
      this.setPillsFromData(changes.data.currentValue.data ?? []);
    }
  }

  pillClicked(value: string|number): void {
    const eventName = AppEvent.PillListPillClicked;
    const data = {
      Id: value,
    };
    this.appsConstantsFacade.pillListPillClicked(value);
    this.emitEvent(eventName, data);
  }

  xButtonClicked(value: string|number): void {
    const eventName = AppEvent.PillListPillCloseClicked;
    const data = {
      Id: value,
    };
    this.appsConstantsFacade.pillListPillCloseClicked(value);
    this.emitEvent(eventName, data);
  }

  private emitEvent(eventName: string, data: AppState): void {
    this.eventsService.publishEvent({ id: eventName, state: data });
  }

  private setPillsFromData(data: Record<string, unknown>[]): void {
    const newPills: Pill[] = [];
    const newPillsInverted: Record<string, string>[] = [];
    data.forEach((row) => {
      const value = row[this.config.valueField] as string|number;
      const display = row[this.config.displayField] as string;
      newPills.push({ value, display });
      const item = {};
      item[this.config.displayField] = display;
      item[this.config.valueField] = value;
      newPillsInverted.push(item);
    });
    this.pills = newPills;
    $(this.elementRef.nativeElement).data("pillItems", newPillsInverted);
    this.changeDetectorRef.detectChanges();
  }
}
