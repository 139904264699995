import { Injectable } from "@angular/core";

@Injectable()
export class FieldAdapter {
    keys: [];
    fieldMapTargetList: any;
    fieldsConfig: any;
    customParseResult: any;
    config: any;

    constructor(fieldMapTargetList: any, fieldsConfig: any, customParseResult?: any) {
        this.fieldMapTargetList = fieldMapTargetList;
        this.fieldsConfig = fieldsConfig;
        this.customParseResult = customParseResult;

        this.config = {
            parseData: this.parseData
        }
    }

    parseData(data) {
        this.keys = _.first(data);

        return _(data)
            .tail()
            .map((valueSet) => {
                const result = _.zipObject(this.keys, valueSet);

                this.reMapFields(result, this.fieldMapTargetList);

                if (_.isFunction(this.customParseResult)) {
                    this.customParseResult(result);
                }

                return result;
            })
            .value();
    }

    reMapFields(item, fields: any) {
        _.forEach(fields, (field) => {

            if (this.fieldsConfig[field]) {
                item[field] = item[this.fieldsConfig[field]];
                delete item[this.fieldsConfig[field]];
            }
        });
    }
}
