import { Component, OnInit, OnDestroy, AfterContentInit, ChangeDetectionStrategy, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import Highcharts from '../../libraries/highcharts.extensions';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Promise } from 'bluebird';
import numeral from 'numeral';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { FieldFormatService } from '../../services/field-format.service';
import { HighchartsService } from '../../services/highcharts.service';
import { ThemeService } from '../../services/theme.service';
import { DynamicReplacementService } from '../../services/dynamic-replacement.service';
import { UtilService } from '../../services/util.service';
import { CacheManagerService } from '../../services/cachemanager.service';

type ListChartGridProperties = {
  visible: boolean;
  dataSource: { items: () => Array<Record<string, any>>; on: Function, off: Function, reload: Function }
};

type ListChartConfig = {
  chartOverrides: {
    AlwaysShowTooltipOutsideColumn?: 'true' | 'false' | boolean;
    ColumnBackdrop?: string,
    ColumnHighlightMinMax?: any,
    CustomDataPointColors?: string,
    disableDefaultLegendItemClick?: string,
    doNotSplitIntoMultipleSeries?: 'true' | 'false' | boolean;
    donutProgressBar?: 'true' | 'false' | boolean;
    enableColumnStacking?: 'true' | 'false' | boolean;
    enableTotalSumValue?: 'true' | 'false' | boolean;
    grouping?: boolean,
    GroupPadding?: any,
    isMultipleSeriesWithMultipleArguments?: 'true' | 'false' | boolean;
    itemTextMap?: { addSubtitle?: string },
    legendItemMarginBottom?: string,
    legendItemMarginTop?: string,
    makeTooltipCssCustomizable?: 'true' | 'false' | boolean;
    marginLeft?: string,
    marginRight?: string,
    OthersText?: string,
    pieSize?: any,
    pointWidth?: string,
    seriesLabel?: string,
    seriesLabelSeparator?: string,
    ShowSeriesWithNullData?: 'true' | 'false' | boolean;
    ShowXAxisCategories?: 'true' | 'false' | boolean;
    thousandsSeparator?: string,
    Tooltip?: string,
    tooltipBackgroundColor?: string,
    TooltipBackgroundColor?: string,
    tooltipBorderColor?: string,
    TooltipBorderColor?: string,
    tooltipBorderRadius?: string,
    tooltipBorderWidth?: string,
    tooltipHtmlTemplate?: string,
    tooltipShared?: string,
    TopOthers?: string,
    UseChartEditorColors?: 'true' | 'false' | boolean;
    UseHTMLInLegends?: string,
    Width?: string,
    xAxisLabels?: string,
    xAxisLabelWidth?: string,
    xAxisStartOnTick?: string,
    xAxisTickInterval?: string,
    xAxisTickLength?: string,
    xAxisVisible?: 'true' | 'false' | boolean;
    xValueForYaxisLabel?: string,
    yAxisLabels?: string,
    yValueForYaxisLabel?: string,
  },
  responsiveConfig?: {},
  translationId?: string,
  wizzardOptions: {
    chart: {
      series: any[],
      yAxis: { labels: {}[] }[],
    },
    series: any[],
  },
};

@Component({
  selector: 'ic-list-chart',
  template: `<highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions.chart"></highcharts-chart>`,
  styleUrls: ['./listchart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListchartComponent implements OnInit, AfterContentInit, OnDestroy {

  private _showLoading: boolean;

  @Input() config: ListChartConfig;
  @Input() applet: Applet
  @Input() checkSize: any;
  @Input() gridProperties: ListChartGridProperties;

  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart;
  gridInstance: any;
  chartOptions: any;
  wizardSeries: any;
  waitFor: any;
  formatValue: any;
  ready = false;
  shouldForceRender = false;
  translateSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateFacadeService,
    private themeService: ThemeService,
    private fieldFormatService: FieldFormatService,
    private highchartsService: HighchartsService,
    private utilService: UtilService,
    private dynamicReplacementService: DynamicReplacementService,
    private cacheManagerService: CacheManagerService,
  ) { }

  ngOnInit(): void {
    this.configureOptions();
  }

  ngAfterContentInit(): void {
    this.chart = new Highcharts.Chart(this.elementRef.nativeElement, this.chartOptions.chart);
    this.postChartInit();
  }

  ngOnDestroy(): void {
    this.chart = null;
    this.translateSubscription?.unsubscribe();
    this.gridProperties.dataSource.off('changed', this._onDataSourceChangeHandler.bind(this));
    this.gridProperties.dataSource.off('loadingChanged');
    this.gridProperties.dataSource.off('loadError');
  }

  configureOptions(): void {
    this.chartOptions = this.config.wizzardOptions;
    this.chartOptions.formats = this.applet.config.formats;
    this.wizardSeries = _.clone(this.config.wizzardOptions.chart.series);

    // Copy wizard negative values color to plotOptions for general use
    if (this.chartOptions.chart.series[0].negativeColor) {
      this.chartOptions.chart.plotOptions.series.negativeColor = this.chartOptions.chart.series[0].negativeColor;
    }

    // Clean up wizard output
    this.chartOptions.chart.series = [];
    this.chartOptions.chart.credits = false;

    // Merge wizard options with defaults
    this.chartOptions.chart = _.defaultsDeep(this.chartOptions.chart, this._chartDefaults());
    this.chartOptions.appName = this.applet.name;
    this.chartOptions.chart.lang.noData = this._getNoDataText(this.chartOptions.appName, this.chartOptions.chart.lang.noData);
    this.chartOptions.chart.lang.loading = this._getLoadingText(this.chartOptions.appName, this.chartOptions.chart.lang.loading);

    // Binding to scope to make the function testable and less tangled
    this.formatValue = (format, chartType, value, percentage, formatFunc) => {
      formatFunc = formatFunc.bind(this.fieldFormatService); // Bind to svc before calling
      const seriesIsNotPercentFormat = format.toLowerCase().indexOf('percent') < 0;
      const actualValue = chartType === 'column' || seriesIsNotPercentFormat ? value : _.isNil(percentage) ? value : percentage;
      return formatFunc(format, actualValue);
    };

    this.waitFor = this._setDynamicReplacements();
    this._configureAndApplyChartOverrides();
    this._configureTooltip();
    this._configureLegend();

    // Sets the Legend responsive position
    if (this.config.responsiveConfig) {
      this._setLegendResponsivePosition(this.config, this.elementRef);
    }

    //For using the thousands separator if configured by the user
    if (this.config.chartOverrides.thousandsSeparator) {
      Highcharts.setOptions({
        lang: {
          thousandsSep: this.config.chartOverrides.thousandsSeparator,
        },
      });
    }
  }

  
  private hideLoading(): void {
    this.chart.hideLoading()
    this._showLoading = true;
  }

  private showLoading(): void {
    this.chart.showLoading()
    this._showLoading = false;
  }

  postChartInit() {
    this.showLoading();
    this.gridProperties.dataSource.on('changed', this._onDataSourceChangeHandler.bind(this));
    this.gridProperties.dataSource.on('loadingChanged', () => {
      if (this._showLoading) this.showLoading();
      else this.hideLoading();
    });
    this.gridProperties.dataSource.on('loadError', () => this.hideLoading());
    this.waitFor.then(() => {
      this._setSeriesColorsFromThemeProperties(this.chartOptions, this.config.chartOverrides.CustomDataPointColors);
      if (this.shouldForceRender) {
        this.chart.update(this.chartOptions.chart);
        const rawData = this.gridProperties.dataSource.items();
        this._updateChartSeriesData(this.chart, rawData, this.chartOptions, this.config.chartOverrides, null);
        this.shouldForceRender = false;
      }
      this.ready = true;
      this.utilService.triggerWindowResize();
    });
    this.translateSubscription = this.translate.onLangChange.pipe(
      tap(() => this.gridProperties.dataSource.reload())
    ).subscribe();
  }

  private detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  _onDataSourceChangeHandler() {
    if (this.chart == null) return;
    if (this.ready) {
      if (!this.shouldForceRender) {
        this.chart.update(this.chartOptions.chart);
        this.shouldForceRender = true;
      }
      if (this.gridInstance?.columnOption('groupIndex:0') !== undefined) {
        this.config.chartOverrides.grouping = true;
      }
      const seriesFilter = this.config.chartOverrides.grouping ? 'key' : this.chartOptions.series[0].argument;
      const rawData = this.gridProperties.dataSource
        .items()
        .filter(item => {
          if (_.isNil(item)) return false;
          return isNaN(item[seriesFilter]) ? item[seriesFilter].length > 0 : true;
        });
      const $element = $(this.elementRef.nativeElement);
      const options = {
        element: $element,
        target: $element,
        appId: this.applet.rid,
      };
      this.utilService.updateComponentTranslationProperties(options, this.config.translationId);
      this._updateChartSeriesData(this.chart, rawData, this.chartOptions, this.config.chartOverrides, this.wizardSeries);
      if (this.checkSize) {
        this.checkSize({
          adjustWidth: true,
          adjustHeight: true,
        });
      }
      this.detectChanges();
    } else {
      this.shouldForceRender = true;
    }
  }

  _clearChartData(chart) {
    // remove chart series data
    while (chart.series.length) {
      chart.series[0].remove(false);
    }
    chart.redraw();
  }

  _addNewChartCategories(chart, rawData, config) {
    chart.xAxis[0].setCategories(this.highchartsService.chartCategories(rawData, config));
  }

  _getCustomSortOrder(config, custSortField) {
    let temp = {};
    if (
      !_.isNil(config?.CustomSort) &&
      _.isPlainObject(config.CustomSort) &&
      !_.isNil(config.CustomSort[custSortField]) &&
      _.isPlainObject(config.CustomSort[custSortField])
    ) {
      temp = config.CustomSort[custSortField];
    }
    return temp;
  }

  _getCustomSortOrderGap(customOrderMap) {
    if ($.isEmptyObject(customOrderMap)) return 0;
    let max = 0;
    for (const current in customOrderMap) {
      if (customOrderMap[current] > max) {
        max = customOrderMap[current];
      }
    }
    return max + 1;
  }

  _performCustomSort(rawData, config, custSortField) {
    const customOrderMap = this._getCustomSortOrder(config, custSortField);
    if ($.isEmptyObject(customOrderMap)) return rawData; //Remove if it is required to sort, custom sort is optional
    const gap = this._getCustomSortOrderGap(customOrderMap);
    const mapped = rawData.map((el, i) => {
      const customOrder = !_.isNil(customOrderMap[el[custSortField]]) ? customOrderMap[el[custSortField]] : gap + i;
      return {
        index: i,
        name: el[custSortField].toLowerCase(), // this field will be used to perform normal sort
        customOrder: customOrder,
      };
    });
    mapped.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    if (!$.isEmptyObject(customOrderMap)) {
      mapped.sort((a, b) => {
        return a.customOrder - b.customOrder;
      });
    }
    rawData = mapped.map((el) => {
      return rawData[el.index];
    });
    return rawData;
  }

  _copyArgumentToAllSeries(configSeries) {
    const defaultArgument = configSeries[0].argument;
    configSeries.forEach((series, index) => {
      // Add the argument to each series if not set
      if (index > 0 && series.argument == null) {
        series.argument = defaultArgument;
      }
    });
  }

  _updateChartSeriesData(chart, rawData, config, overridesConfig, wizzardSeriesName) {
    this._clearChartData(chart);

    rawData = this._performCustomSort(rawData, overridesConfig, config.series[0].argument);

    this._copyArgumentToAllSeries(config.series);

    if (this.highchartsService.getChartType(config) !== 'scatter') {
      this._addNewChartCategories(chart, rawData, config);
    }

    this.highchartsService.addChartSeries(chart, rawData, config, overridesConfig, wizzardSeriesName);

    // Add plot line to xAxis if width is defined
    if (overridesConfig.xAxisPlotLineWidth)
      chart.update({
        xAxis: {
          plotLines: [
            {
              color: overridesConfig.xAxisPlotLineColor ? overridesConfig.xAxisPlotLineColor : 'black',
              dashStyle: overridesConfig.xAxisPlotLineDashStyle,
              value: overridesConfig.xAxisPlotLineValue !== undefined ? overridesConfig.xAxisPlotLineValue : null,
              width: overridesConfig.xAxisPlotLineWidth,
            },
          ],
        },
      });

    this._updateRXRY(overridesConfig);

    const noDataHideChartIsTrue = overridesConfig.NoDataHideChart && JSON.parse(overridesConfig.NoDataHideChart.ToLower());
    if (noDataHideChartIsTrue && this._chartHasNoData(chart)) {
      chart.container.hidden = true;
    }
  }

  _chartHasNoData(chart) {
    return chart.series && chart.series.length > 0 && chart.series[0].data.length < 1;
  }

  _updateRXRY(overridesConfig) {
    if (typeof overridesConfig.RX !== 'undefined' && typeof overridesConfig.RY !== 'undefined') {
      $('.highcharts-legend-item rect').attr('rx', overridesConfig.RX);
      $('.highcharts-legend-item rect').attr('ry', overridesConfig.RY);
    }
  }

  _setDynamicReplacements(): Promise<any> {
    return new Promise((resolve, reject) => {

      const drm = {};
      const urm = {};
      let makeCall = false;

      const drStrs = [
        this.utilService.createProxyFactory(this.config, 'wizzardOptions.chart.title.text'),
        this.utilService.createProxyFactory(this.config, 'wizzardOptions.chart.subtitle.text'),
      ];

      if (Array.isArray(this.chartOptions.chart.xAxis)) {
        this.chartOptions.chart.xAxis.forEach((it, i) => {
          drStrs.push(this.utilService.createProxyFactory(this.config, 'wizzardOptions.chart.xAxis[' + i + '].title.text'));
        });
      }

      if (Array.isArray(this.chartOptions.chart.yAxis)) {
        this.chartOptions.chart.yAxis.forEach((it, i) => {
          drStrs.push(this.utilService.createProxyFactory(this.config, 'wizzardOptions.chart.yAxis[' + i + '].title.text'));
        });
      }

      this.chartOptions.series.forEach((it, i) => {
        drStrs.push(this.utilService.createProxyFactory(this.config, 'wizzardOptions.series[' + i + '].seriesName'));
      });

      for (let i = 0; i < drStrs.length; i++) {
        const str = drStrs[i]?.getValue();
        if (_.isEmpty(str)) continue;
        const tokens = this.dynamicReplacementService.getTokens(str);
        tokens.tokens.forEach((ti) => {
          const isServerSideReplacement = this.dynamicReplacementService.isServerSideReplacement(ti);
          if (!isServerSideReplacement) return;
          if (this.cacheManagerService.has(ti)) return;
          if (!drm[this.applet.name]) drm[this.applet.name] = {};
          drm[this.applet.name][i] = ti;
          urm[ti] = !0;
        });
      }

      for (const sr in urm) {
        if (this.cacheManagerService.has(sr)) continue;
        this.cacheManagerService.setPromise(sr);
        makeCall = true;
      }

      const _runClientReplacements = (drs: ProxyFactory[]) => {
        return Promise.map(drs, (current) => {
          const currentValue = current?.getValue();
          return !_.isNil(currentValue) ? this.dynamicReplacementService.getDynamicValue(currentValue, this) : void 0;
        });
      };

      let _serverPromise = Promise.resolve({});
      if (makeCall) {
        _serverPromise = iXing.Factories.createServerReplacementPromise(drm, true);
      }

      _serverPromise
        .then((data) => {
          for (const d in data) {
            if (this.cacheManagerService.has(d)) continue;
            this.cacheManagerService.resolvePromiseWith(d, data[d], this.cacheManagerService.dynamicReplacementCachePolicy);
          }
          // It is required to resolve server replacements first, then run client ones
          _runClientReplacements(drStrs).then((results) => {
            results.forEach((it, i) => {
              if (!_.isNil(it)) {
                drStrs[i].setValue(it);
              }
            });
            resolve(true);
          });
        })
        .catch((error) => {
          window['IX_Log'] && IX_Log('component', 'createServerReplacementPromise fail', error);
        });
    });
  }

  _setChartColors(config, colorsSource) {
    if (colorsSource?.indexOf(':') === -1) {
      const colors = colorsSource.split('|');
      if (colors.length > 1) {
        config.chart.colors = colors;
      }
    }
  }

  _setChartColorsFromThemeChartAppearance(config, overridesConfig) {
    if (Array.isArray(IX_Theme.chartAppearance) && !overridesConfig.UseChartEditorColors) {
      const colorsSource = IX_Theme.chartAppearance.filter((it) => !_.isNil(it.Property) && it.Property === 'Colors');
      if (Array.isArray(colorsSource) && colorsSource.length > 0) {
        this._setChartColors(config, colorsSource[0].Value1);
      }
    }
  }

  _trueIfStringTrue(val) {
    if (typeof val === 'boolean') return val;
    return typeof val === 'string' && val.toLowerCase() === 'true';
  }

  _falseIfStringFalse(val) {
    if (typeof val === 'boolean') return val;
    if (typeof val === 'string' && val.toLowerCase() === 'false')
      return false;
    return true;
  }

  /**
   * Defines the responsive property in the chart configuration to allow for legend responsiveness.
   * @param {* Object } config The directive configuration object.
   * @param {* Element } e The container for the chart, as passed by the link function.
   */
  _setLegendResponsivePosition(config, e) {
    const chartConfig = config.wizzardOptions.chart;
    /* The following makes sure that the padding around the chart container is
     * considered when calculating the responsive width. */
    const padding = window.screen.width - e.nativeElement.clientWidth + 1;

    if (_.isNil(config.responsiveConfig.Breakpoint)) {
      const arg = config.chartOverrides.LegendResponsivePosition != null ? config.chartOverrides.LegendResponsivePosition : config.responsiveConfig.SizeName;
      config.responsiveConfig.Breakpoint = this.themeService.getResponsiveBreakpointWidth(arg);
    }

    // https://www.highcharts.com/docs/chart-concepts/responsive
    chartConfig.responsive = {
      rules: [
        {
          chartOptions: {
            legend: _.extend({}, config.wizzardOptions.chart.legend, {
              layout: config.responsiveConfig.Layout,
              align: 'center',
              verticalAlign: config.responsiveConfig.Position,
            }),
          },
          condition: {
            minWidth: 0,
            maxWidth: config.responsiveConfig.Breakpoint - padding,
          },
        },
        {
          chartOptions: {
            legend: config.wizzardOptions.chart.legend,
          },
          condition: {
            minWidth: config.responsiveConfig.Breakpoint - padding + 1,
            maxWidth: 2000,
          },
        },
      ],
    };

    chartConfig.legend = {};
  }

  _chartDefaults() {
    return {
      chart: {
        backgroundColor: 'transparent',
        animation: false,
        spacingTop: 0,
        type: 'line', // highcharts default is line
        marginTop: 10, //adding the buffer margin spacing to avoid the edgecases of Y-axis labels getting cut off depending on the label font size
        tooltip: {
          enabled: true,
        },
      },
      xAxis: [
        {
          visible: false,
        },
      ],
      tooltip: {
        borderWidth: 1,
        enabled: true,
        shared: true,
        borderRadius: 1,
      },
      lang: {
        noData: 'No Data to Display',
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '18px',
          color: '#303030',
        },
      },
    };
  }

  _configureAndApplyChartOverrides() {
    this.config.chartOverrides.ColumnBackdrop = this.config.chartOverrides.ColumnBackdrop ? this.config.chartOverrides.ColumnBackdrop : null;
    this.config.chartOverrides.ColumnHighlightMinMax = this.config.chartOverrides.ColumnHighlightMinMax
      ? this.config.chartOverrides.ColumnHighlightMinMax.split(',')
      : null;
    this.config.chartOverrides.GroupPadding = parseFloat(this.config.chartOverrides.GroupPadding) || 0;
    this.config.chartOverrides.ShowSeriesWithNullData = this._trueIfStringTrue(this.config.chartOverrides.ShowSeriesWithNullData);
    this.config.chartOverrides.ShowXAxisCategories = this._trueIfStringTrue(this.config.chartOverrides.ShowXAxisCategories);
    this.config.chartOverrides.UseChartEditorColors = this._trueIfStringTrue(this.config.chartOverrides.UseChartEditorColors);
    this.config.chartOverrides.donutProgressBar = this._trueIfStringTrue(this.config.chartOverrides.donutProgressBar);
    this.config.chartOverrides.xAxisVisible = this._trueIfStringTrue(this.config.chartOverrides.xAxisVisible);
    this.config.chartOverrides.makeTooltipCssCustomizable = this._trueIfStringTrue(this.config.chartOverrides.makeTooltipCssCustomizable);
    this.config.chartOverrides.enableColumnStacking = this._trueIfStringTrue(this.config.chartOverrides.enableColumnStacking);
    this.config.chartOverrides.enableTotalSumValue = this._trueIfStringTrue(this.config.chartOverrides.enableTotalSumValue);
    this.config.chartOverrides.AlwaysShowTooltipOutsideColumn = this._trueIfStringTrue(this.config.chartOverrides.AlwaysShowTooltipOutsideColumn);
    this.config.chartOverrides.doNotSplitIntoMultipleSeries = this._trueIfStringTrue(this.config.chartOverrides.doNotSplitIntoMultipleSeries);
    this.config.chartOverrides.isMultipleSeriesWithMultipleArguments = this._trueIfStringTrue(this.config.chartOverrides.isMultipleSeriesWithMultipleArguments);
    this.config.chartOverrides.pieSize = this.config.chartOverrides.pieSize
      ? this.fieldFormatService.format('Percentage', this.config.chartOverrides.pieSize / 100)
      : null;
    this.config.chartOverrides.itemTextMap = {
      addSubtitle: 'addSubtitle',
    };

    //Apply Overrides
    this._setChartColorsFromThemeChartAppearance(this.chartOptions, this.config.chartOverrides);

    this.chartOptions.others = this.config.chartOverrides.TopOthers;
    this.chartOptions.othersText = this.config.chartOverrides.OthersText || 'Others';

    if (this.config.chartOverrides.Tooltip) {
      this.chartOptions.chart.tooltip.enabled = this.config.chartOverrides.Tooltip.toLowerCase() === 'disabled' ? false : true;
    }
    if (this.config.chartOverrides.TooltipBorderColor) {
      this.chartOptions.chart.tooltip.borderColor = this.config.chartOverrides.TooltipBorderColor;
    }
    if (this.config.chartOverrides.TooltipBackgroundColor) {
      this.chartOptions.chart.tooltip.backgroundColor = this.config.chartOverrides.TooltipBackgroundColor;
    }

    //chart default overrides for the left and right margin
    if (this.config.chartOverrides.marginLeft) {
      this.chartOptions.chart.chart.marginLeft = Number(this.config.chartOverrides.marginLeft);
    }

    if (this.config.chartOverrides.marginRight) {
      this.chartOptions.chart.chart.marginRight = Number(this.config.chartOverrides.marginRight);
    }

    const auxWidth = this.config.chartOverrides.Width;
    if (!_.isNil(auxWidth) && !_.isNil(numeral(auxWidth).value())) {
      this.chartOptions.chart.width = numeral(auxWidth).value();
    }

    //to not render the chart axis labels based on the user configuration
    //for y-axis
    if (!_.isNil(this.chartOptions.chart.yAxis[0].labels)) {
      this.chartOptions.chart.yAxis[0].labels.enabled = this._falseIfStringFalse(this.config.chartOverrides.yAxisLabels);

      // Positioning yaxis labels and default is 0
      this.chartOptions.chart.yAxis[0].labels.x = this.config.chartOverrides.xValueForYaxisLabel ? Number(this.config.chartOverrides.xValueForYaxisLabel) : 0;

      // Only set yAxis label y if an override exists, otherwise let highcharts decide positioning.
      if (this.config.chartOverrides.yValueForYaxisLabel) this.chartOptions.chart.yAxis[0].labels.y = Number(this.config.chartOverrides.yValueForYaxisLabel);

      const fieldFormatService = this.fieldFormatService;

      const getFormat = () => this.chartOptions.formats[this.chartOptions.series[0].values];
      const getChartType = () => this.chartOptions.chart.chart.type;
      const formatValue = this.formatValue;
      this.chartOptions.chart.yAxis[0].labels.formatter = function () {
        // labelFormatter parameter object bound to `this` by Highcharts
        const format = getFormat();
        const chartType = getChartType();

        return formatValue(format, chartType, this.value, this.percentage, fieldFormatService.format);
      };

    }

    //for x-axis
    if (!_.isNil(this.chartOptions.chart.xAxis[0].labels)) {
      this.chartOptions.chart.xAxis[0].labels.enabled = this._falseIfStringFalse(this.config.chartOverrides.xAxisLabels);
    }
    this.chartOptions.chart.xAxis[0].tickLength = this.config.chartOverrides.xAxisTickLength ? Number(this.config.chartOverrides.xAxisTickLength) : undefined;
    this.chartOptions.chart.xAxis[0].tickInterval = this.config.chartOverrides.xAxisTickInterval
      ? Number(this.config.chartOverrides.xAxisTickInterval)
      : undefined;
    this.chartOptions.chart.xAxis[0].startOnTick = this._trueIfStringTrue(this.config.chartOverrides.xAxisStartOnTick);

    const showXAxisOnLineChartProp = this.themeService.getThemeProperty('ShowXAxisOnLineChart');
    if (this.highchartsService.getChartType(this.chartOptions) === 'line' && showXAxisOnLineChartProp) {
      const showXAxisOnLineChart = showXAxisOnLineChartProp.Value1 && showXAxisOnLineChartProp.Value1.toLowerCase() === 'true';
      this.chartOptions.chart.xAxis[0].visible = showXAxisOnLineChart;
    }

    //Hides the xAxis for column charts.
    if (
      this.highchartsService.getChartType(this.chartOptions) === 'column' &&
      _.isArray(this.chartOptions.chart.xAxis) &&
      this.chartOptions.chart.xAxis.length > 0
    ) {
      if (this.config.chartOverrides.ShowXAxisCategories || this.config.chartOverrides.xAxisVisible) {
        this.chartOptions.chart.xAxis[0].visible = true;
        this.chartOptions.chart.xAxis[0].labels = {
          enabled: true,
        };

        if (this.config.chartOverrides.xAxisLabelWidth) {
          this.chartOptions.chart.xAxis[0].labels.useHTML = true;
          this.chartOptions.chart.xAxis[0].labels.style = {
            width: this.config.chartOverrides.xAxisLabelWidth + 'px',
            'overflow-wrap': 'break-word',
          };
        }
      } else {
        this.chartOptions.chart.xAxis[0].visible = false;
      }
    }

    //to assign a user specified width to the data point in the chart (for bar/column)
    if (this.highchartsService.getChartType(this.chartOptions) === 'bar' || this.highchartsService.getChartType(this.chartOptions) === 'column') {
      if (this.config.chartOverrides.pointWidth) {
        this.chartOptions.chart.plotOptions.series.pointWidth = Number(this.config.chartOverrides.pointWidth);
      }
    }

    //to make the pie size for the pie chart configurable from P-Tier
    if (this.highchartsService.getChartType(this.chartOptions) === 'pie') {
      this.chartOptions.chart.plotOptions.pie.size = this.config.chartOverrides.pieSize;
    }
  }

  _configureTooltip() {
    const localChart = this.chartOptions;
    const localFormat = this.fieldFormatService.format;
    const localConfig = this.config;
    const localFormatValueFn = this.formatValue;

    if (this.chartOptions.chart.tooltip.enabled) {
      this.chartOptions.chart.tooltip.shared = this._trueIfStringTrue(this.config.chartOverrides.tooltipShared);

      if (this.config.chartOverrides.tooltipHtmlTemplate) {
        this.chartOptions.chart.tooltip.useHTML = true;
        this.chartOptions.chart.tooltip.formatter = function () {

          //to find the dynamic field names and get the values from data point
          let templateStr = localConfig.chartOverrides.tooltipHtmlTemplate;
          const dynamicFieldInstances = templateStr.match(/{Field:(.*?)}/g);

          const dataPoint = this._r;

          //to find the dynamically replace values for each dynamic field
          _.forEach(dynamicFieldInstances, (itm) => {
            const fieldName = itm.substring(7, itm.length - 1); // match(/(?<={Field:)(.*?)(?=})/g)[0]];
            const replacedValue = localFormat(this.chartOptions.formats[fieldName], dataPoint[fieldName]);
            templateStr = templateStr.replace(itm, replacedValue);
          });
          return templateStr;
        };
      } else {
        if (this.chartOptions.chart.tooltip.shared) {
          // For multiple points in single tooltip
          this.chartOptions.chart.tooltip.formatter = function () {
            const s = [];
            $.each(this.chart.points, (i, point) => {
              const seriesName = localConfig.chartOverrides.seriesLabel ? this.config.chartOverrides.seriesLabel : this.chart.series.name;
              const tmpHTML =
                seriesName +
                localConfig.chartOverrides.seriesLabelSeparator +
                '<b>' +
                localFormat(localChart.formats[point._yf], point.y) +
                '</b><br/>';

              s.push(tmpHTML);
            });
            return s;
          };
        } else {
          this.chartOptions.chart.tooltip.useHTML = true;
          // For a single point in series
          this.chartOptions.chart.tooltip.pointFormatter = function () {

            const format = localChart.formats[localChart.series[0].values];
            const chartType = localChart.chart.chart.type;

            let displayValue = localFormatValueFn(format, chartType, this.y, this.percentage, localFormat);

            if (chartType === 'column' && localChart.series.length > 1) {
              displayValue = '<span class="tooltip-series-name">' + this.series.name + '</span>: ' + displayValue;
            }
            return '<span class="tooltip-series-value">' + displayValue + '</span>';
          };

          if (this.chartOptions.chart.chart.type === 'column') {
            if (this.config.chartOverrides.AlwaysShowTooltipOutsideColumn) {
              this.chartOptions.chart.tooltip.positioner = (boxWidth, boxHeight, point) => {
                return {
                  x: point.plotX + point.chart.hoverSeries.xAxis.left - boxWidth / 2,
                  y: point.plotY - boxHeight,
                };
              };
            }
            if (this.chartOptions.series.length > 1) {
              this.chartOptions.chart.tooltip.headerFormat = null;
            } else {
              if (!this.config.chartOverrides.ColumnBackdrop) {
                this.chartOptions.chart.tooltip.headerFormat = '<span class="tooltip-series-name">{point.key}</span><br/>';
              } else {
                this.chartOptions.chart.tooltip.headerFormat = '<span class="tooltip-series-header">{point.key}</span><br/>';
              }
            }
          } else {
            this.chartOptions.chart.tooltip.headerFormat = '<span class="tooltip-series-header">{point.key}</span><br/>';
          }
        }

        if (!_.isUndefined(this.config.chartOverrides.makeTooltipCssCustomizable))
          this.chartOptions.chart.tooltip.useHTML = this.config.chartOverrides.makeTooltipCssCustomizable;
      }

      if (!_.isEmpty(this.config.chartOverrides.tooltipBackgroundColor))
        this.chartOptions.chart.tooltip.backgroundColor = this.config.chartOverrides.tooltipBackgroundColor;

      if (!_.isEmpty(this.config.chartOverrides.tooltipBorderColor))
        this.chartOptions.chart.tooltip.borderColor = this.config.chartOverrides.tooltipBorderColor;

      if (!_.isEmpty(this.config.chartOverrides.tooltipBorderWidth))
        this.chartOptions.chart.tooltip.borderWidth = this.config.chartOverrides.tooltipBorderWidth;

      if (!_.isEmpty(this.config.chartOverrides.tooltipBorderRadius))
        this.chartOptions.chart.tooltip.borderRadius = this.config.chartOverrides.tooltipBorderRadius;
    }
  }

  _configureLegend() {
    const self = this;
    if (self.chartOptions.chart.legend) {
      if (_.isNil(self.chartOptions.chart.plotOptions)) self.chartOptions.chart.plotOptions = {};
      if (_.isNil(self.chartOptions.chart.plotOptions.series)) self.chartOptions.chart.plotOptions.series = {};

      if (self.config.chartOverrides.disableDefaultLegendItemClick === 'true') {
        self.chartOptions.chart.plotOptions.series.events = {
          legendItemClick: (e) => {
            e.preventDefault();
            return false;
          },
        };
      }

      self.chartOptions.chart.legend.itemMarginTop = self.config.chartOverrides.legendItemMarginTop
        ? Number(self.config.chartOverrides.legendItemMarginTop)
        : 0;
      self.chartOptions.chart.legend.itemMarginBottom = self.config.chartOverrides.legendItemMarginBottom
        ? Number(self.config.chartOverrides.legendItemMarginBottom)
        : 0;
      self.chartOptions.chart.legend.useHTML = self._trueIfStringTrue(self.config.chartOverrides.UseHTMLInLegends);

      self.chartOptions.chart.legend.labelFormatter = function () {
        if (!self.config.chartOverrides.enableTotalSumValue && self.chartOptions.series.length > 1) {
          if (self.chartOptions.chart.legend.useHTML) return "<span class='ic-chart-legend-item-title'>" + this.name + '</span>';
          else return this.name;
        }

        let value = this.yData ? this.yData[0] : this.y;
        if (self.config.chartOverrides.enableTotalSumValue && this.yData) {
          let total = 0;
          _.forEach(this.yData, (val) => {
            total += val;
          });
          value = total;
        }

        const format = self.chartOptions.formats[this._yf || (this.userOptions.data[0] && this.userOptions.data[0]._yf)];
        if (!format) {
          return;
        }

        const chartType = self.chartOptions.chart.chart.type;
        const displayValue = self.formatValue(format, chartType, value, this.percentage, self.fieldFormatService.format);

        if (self.chartOptions.chart.legend.useHTML)
          return "<span class='ic-chart-legend-item-title'>" + this.name + "</span> <span class='ic-chart-legend-item-value'>" + displayValue + '</span>';
        else return this.name + ' ' + displayValue;
      };
    }
  }

  _setSeriesColorsFromThemeProperties(config, seriesColors) {
    if (_.isNil(seriesColors)) return;

    let str;
    const tks = this.dynamicReplacementService.getTokens(seriesColors);
    if (!tks.tokens.length) return;
    config.seriesColors = {};
    config.series.forEach((it, index) => {
      str = this._getThemePropertyReplacement(seriesColors, it, index);
      if (str?.length) {
        config.seriesColors[it.seriesName] = { type: 'color' };
        if (str.indexOf(':') > -1) {
          config.seriesColors[it.seriesName].type = 'valueColor';
          const colors = str.split('|');
          const colorMap = colors.reduce((obj, col) => {
            const aux = col.split(':');
            return (obj[aux[0]] = aux[1]), obj;
          }, {});
          config.seriesColors[it.seriesName].colors = colorMap;
        } else {
          config.seriesColors[it.seriesName].colors = str.split('|');
        }
      }
    });
  }

  _getThemePropertyReplacement(propertyName, serie, index) {
    propertyName = propertyName.substring(propertyName.indexOf(':') + 1, propertyName.length - 1);
    let str = this._getThemePropertyReplacement_(propertyName, 'Series' + (index + 1));
    if (!str) {
      str = this._getThemePropertyReplacement_(propertyName, serie.argument);
      if (!str) {
        str = this._getThemePropertyReplacement_(propertyName, serie.seriesName);
      }
    }
    return str;
  }

  _getThemePropertyReplacement_(propertyName, value1) {
    let str = propertyName + ':' + value1;
    str = this.dynamicReplacementService.getThemePropertyReplacement(str);
    return str;
  }

  _getNoDataText(appName: string, noDataText: string): string {
    let listNoDataTextId = '.htmls.noDataToDisplayText';
    listNoDataTextId = appName ? appName + listNoDataTextId : '';
    return this.themeService.textToShowOnNoData(listNoDataTextId, noDataText);
  }

  _getLoadingText(appName: string, defaultText: string): string {
    const listLoadingTextId = '.fields.loadingText';
    const lookUpId = appName ? appName + listLoadingTextId : '';
    defaultText = _.isEmpty(defaultText) ? this.themeService.textToShow('LOADING', 'Loading...', 'Loading...') : defaultText;
    const loadingText = this.themeService.textToShow(lookUpId, '', '');
    return _.isEmpty(loadingText) ? defaultText : loadingText;
  }
}
