/* eslint-disable */
'use strict';

window._directives = {};
var iXing = window.iXing || {};
var IX_checkCSRFCookieInterval;
var IX_initCSRFToken;

$(function () {
    IX_SetTimeZone();
    // IX_startCSRFChecks();
    iXing.pageHasLoaded = true;
});

// Allow cross domain authentication requests
$.ajaxSetup({
    xhrFields: {
        withCredentials: true
    }
});

function GetAntiForgeryToken() {
    return $("#ctl00_ix_csrf_token").val();
}

function UpdateAntiForgeryToken() {
    var newToken = IX_GetCookieValue('XSRF-TOKEN');
    return $("#ctl00_ix_csrf_token").val(newToken);
}

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    var csrf_token = GetAntiForgeryToken(),
        icLocale = IX_GetCookieValue("IXCulture");
    jqXHR.setRequestHeader('X-XSRF-TOKEN', csrf_token);
    jqXHR.setRequestHeader('IC-Culture', icLocale);
});

Date.prototype.addMillisecs = function (d) {
    this.setTime(this.getTime() + (d));
    return this;
}

String.prototype.normalize = function () {
    if (this == void (0)) return this;
    return this.replace(/\s/g, '').replace(/\./g, '').replace(/_/g, '').toLowerCase();
}

String.prototype.IXhashCode = function () {
    var hash = 0, i, chr, len;
    if (this.length == 0) return hash;
    for (i = 0, len = this.length; i < len; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

if (!Array.prototype.clear) {
    Object.defineProperty(Array.prototype, "clear", {
        value: function () {
            if (this != null && typeof this.length != "undefined" && this.length)
                while (this.pop());
        },
        writable: false,
        configurable: true,
        enumerable: false, // don't make it appear in a for in loop
    });
}

function btoau(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

function atobu(str) {
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

function isBrowserEdge() {
    if (navigator.appName == "Netscape") {
        /// in IE 11 the navigator.appVersion says 'trident'
        /// in Edge the navigator.appVersion does not say trident
        if (navigator.appVersion.indexOf('Trident') === -1) return true;
        else return false;
    }
}

var IX_isMobile = function () {
    return (/Android|iPhone|iPad|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera);
}

function IX_IsHTTPS() {
    return location.protocol === 'https:';
}

function IX_GetSecureFlag() {
    return IX_IsHTTPS() ? ";secure" : "";
}

function IX_DeleteCookie(name) {
    var days = -1;
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();

    document.cookie = name + "=" + expires + "; path=/";
}

function IX_DeleteMFACookies(mfaPrefix) {
    var pairs = document.cookie.split(";");
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        var cookieName = (pair[0] + '').trim();
        if (cookieName.indexOf('IXMFADEVICEREF-' + mfaPrefix) >= 0)
            IX_DeleteCookie(cookieName);
    }
}

function IX_SetRememberUsernameCookieValue(UsernameValue) {
    var date = new Date();
    if (document.getElementById("RememberUsername") && document.getElementById("RememberUsername").checked) {
        date.setDate(date.getDate() + 365);
        document.cookie = "UserName=" + UsernameValue + ";expires=" + date.toUTCString();
    } else {
        var key = "UserName";
        date.setDate(date.getDate() - 1);
        document.cookie = escape(key) + '=;expires=' + date;
    }
}

function IX_GetUsernameFromCookieValue() {
    var cookieVal,
        name = "UserName=",
        ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0)
            cookieVal = c.substring(name.length, c.length);
    }
    return cookieVal;
}

function IX_SetUsernameFromCookieValue() {
    var username = IX_GetUsernameFromCookieValue();
    if (username != "" && username != null) {
        $(".UsernameInput td.dxic input").val(username);
        $("#RememberUsername").attr("checked", "checked");
    }
}

function generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = d / 16 | 0;
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
    return uuid;
};

function IX_TriggerSSO(urlLink, ssoForm, redirectCondition, width, height) {

    const popupName = "IX_SSO_" + generateUUID();
    const newWindow = redirectCondition ? window : window.open("", popupName, "scrollbars=yes,menubar=yes,height=" + height + ",width=" + width + ",resizable=yes,toolbar=yes,status=yes");

    if (typeof newWindow == "undefined") {
        IC_Alert("POPUP-BLOCKED");
        return;
    }

    if (typeof urlLink != "undefined" && urlLink != null && urlLink != "") {
        newWindow.location.assign(urlLink);
    } else {
        //-- RT Commented Code on 04/02/2016 to resolve issues for IE11 browser inconsistencies
        if (ssoForm.startsWith("<form") && !ssoForm.endsWith("/></form>")) {
            ssoForm = ssoForm + "/></form>";
        }
        var actualText = IX_getMessageText("ESTABLISHING-SECURE-CONNECTION");
        newWindow.document.writeln('<html><head></head><body style=\"color: #808080;font-family: Verdana;font-size: 10pt;margin:20px;\"><span>' + actualText + '</span>' + ssoForm + '</body></html>');
        newWindow.document.forms[0].submit();
    }
}

function IX_getMessageText(staticMessageTextId) {
    if (typeof IX_NonCommandMessageList == "undefined") {
        var $injector = angular.element('[ng-view]').injector();
        if (!_.isNil($injector)) {
            var $utilService = $injector.get('utilService');
            return $utilService.translateOrDefault(staticMessageTextId, staticMessageTextId);
        }
        return staticMessageTextId;
    }

    if (typeof IX_NonCommandMessageList[staticMessageTextId] == "undefined")
        return staticMessageTextId;

    return IX_NonCommandMessageList[staticMessageTextId];
}

function IX_getCommandFromMessageList(commandTextId) {
    if (typeof IX_MessageList == "undefined")
        return commandTextId;

    if (typeof IX_MessageList[commandTextId] == "undefined") {
        if (isNaN(commandTextId))
            return commandTextId;

        return "command text not found: " + commandTextId;
    }

    return IX_MessageList[commandTextId];
}

function IX_ConvertDSListToDXFormat(listData) {
    var dataObject = [], singleObject, i, j;
    for (i = 1; i < listData.length; i++) {
        singleObject = listData[i];
        dataObject[i - 1] = {};
        for (j = 0; j < singleObject.length; j++) {
            dataObject[i - 1][listData[0][j]] = singleObject[j];
        }
    }
    return dataObject;
}

function IX_SetEcdRequestContext(ecdRequest, ecdContext) {
    var tmp, keys, i;
    for (tmp in ecdContext) {
        keys = Object.keys(ecdContext[tmp]);
        if (keys.length > 0) {
            for (i = 0; i < keys.length; i++) {
                if (typeof keys[i] == "string" && keys[i].indexOf("StorageKey") >= 0) {
                    delete ecdContext[tmp][keys[i]];
                }
            }
        }
    }

    ecdRequest.Context = {};
    $.extend(ecdRequest.Context, ecdContext);
}

function IX_GetMFADataAndResolvePromise(ecdRequest, incomingData, deferredPromise, returnAsRawList, ecdContext) {
    IX_GetListDataAndResolvePromise(ecdRequest, incomingData, deferredPromise, returnAsRawList, ecdContext, 'IX_MFARequest')
}

function IX_GetListDataAndResolvePromise(ecdRequest, incomingData, deferredPromise, returnAsRawList, ecdContext, iXCaller) {
    window[iXCaller](ecdRequest, incomingData, ecdContext)
        .done(function (data) {
            if (data == null || data.ListData == null) {
                if (returnAsRawList)
                    deferredPromise.resolve([]);
                else
                    deferredPromise.resolve([], { totalCount: 0 });
            }
            else {
                if (returnAsRawList)
                    deferredPromise.resolve(data.ListData);
                else
                    deferredPromise.resolve(IX_ConvertDSListToDXFormat(data.ListData), { totalCount: data.ListData.length - 1 });
            }
        })
        .fail(function (jqXHR, type, statusMessage) {
            /* Used by "{Error:} Dynamic Replacement Value. "*/
            if (jqXHR) {
                window.lastError = jqXHR.responseJSON;
            }

            if (returnAsRawList)
                deferredPromise.resolve([]);
            else
                deferredPromise.resolve([], { totalCount: 0 });
        });
}

function IX_MFARequest(ecdRequest, incomingData) {
    var ajaxOptions = {
        data: JSON.stringify(incomingData),
        url: '/Membership/ExtPages/mfa.ashx?' + ecdRequest.query,
        type: 'POST',
        contentType: "application/json",
        dataType: 'json'
    };
    return IX_Ajax(ajaxOptions);
}

function IX_PerformPrimeCacheRequests(primingRequests) {
    if (primingRequests) {
        $(primingRequests)
            .each(function (idx) {
                $.ajax({ url: $(this).text(), type: "GET" });
            });
    }
}

function IX_getQueryStringParameter(name, includeHash) {
    if (includeHash == undefined)
        includeHash = false;

    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(includeHash ? location.href : location.search);

    if (!includeHash && location.hash && typeof results != undefined && results != null && typeof results[1] != "undefined") {
        results[1] = results[1] + location.hash;
    }

    var parameterValue = results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));

    parameterValue = filterXSS(parameterValue, {
        whiteList: [], // empty, means filter out all tags
        stripIgnoreTag: true, // filter out all HTML not in the whitelist
        stripIgnoreTagBody: ["script"] // the script tag is a special case, we need to filter out its content
    });
    return parameterValue;
}

function IX_Ajax(options) {
    var d = new $.Deferred();
    $.ajax(options)
        .done(function (data) {
            d.resolve(data);
        }).fail(function (jqXHR) {
            d.reject(jqXHR.responseJSON);
        });
    return d.promise();
}

function IX_centerElement(element) {
    var windowProps = { height: $(window).height(), width: $(window).width() };
    var elementProps = { height: element.height(), width: element.width() };
    var maxWidth = element.css('maxWidth');
    var maxWidthValue = parseFloat(maxWidth);
    var isPercent = _.endsWith(maxWidth, "%");

    if (isPercent) {
        maxWidthValue = Math.round((maxWidthValue * windowProps.width) / 100);
    }

    if (windowProps.width <= elementProps.width || (maxWidth != 'none' && windowProps.width <= maxWidthValue)) {
        maxWidth = windowProps.width * 0.95; // Should be same as dx popup width
    } else {
        maxWidth = (maxWidth != 'none')
            ? maxWidthValue
            : elementProps.width;
    }

    element.css({ "position": "fixed", 'maxWidth': maxWidth });

    $(element).position({
        of: window,
        my: 'center',
        at: 'center'
    });
}

function IX_centerElementVertically(element) {
    var windowProps = { height: $(window).height(), width: $(window).width(), scrollTop: $(window).scrollTop() };
    var elementProps = { height: element.height(), width: element.width() };
    if (windowProps.height > elementProps.height) {
        element.css({ "position": "absolute", "top": (((windowProps.height - elementProps.height) / 2) + windowProps.scrollTop) + "px" });
    } else {
        element.css({ "position": "absolute", "top": windowProps.scrollTop + "px" });
    }
}

function IX_ExtendjQuerySelectors() {

    var focusable = function (element, tabIndex) {
        var nodeName = element.nodeName.toLowerCase(),
            isTabIndexNotNaN = !isNaN(tabIndex),
            isVisible = visible(element),
            isDisabled = element.disabled,
            isDefaultFocus = /^(input|select|textarea|button|object|iframe)$/.test(nodeName),
            isHyperlink = "a" === nodeName,
            isFocusable = true;
        if (isDefaultFocus) {
            isFocusable = !isDisabled
        } else {
            if (isHyperlink) {
                isFocusable = element.href || isTabIndexNotNaN
            } else {
                isFocusable = isTabIndexNotNaN
            }
        }
        return isVisible ? isFocusable : false
    }
    var visible = function (element) {
        var $element = $(element);
        return $element.is(":visible") && "hidden" !== $element.css("visibility") && "hidden" !== $element.parents().css("visibility")
    }
    var icontains = function (elem, text) {
        var result = false;
        $.each($(elem).contents(), function (index, content) {
            if (3 === content.nodeType && (content.textContent || content.nodeValue || "").toLowerCase().indexOf((text || "").toLowerCase()) > -1) {
                result = true;
                return false
            }
        });
        return result
    };

    $.extend($.expr[":"], {
        "ix-tabbable": function (element) {
            var tabIndex = $.attr(element, "tabindex");
            return (isNaN(tabIndex) || tabIndex >= 0) && focusable(element, tabIndex)
        }
    });
}

function IX_getDisplayCustomAlertInfo() {
    /* Gets properties from the current theme properties. */
    var nameThemeProperty = _.find(window.IX_Theme.properties, function (item) {
        return item.PropertyName == "CustomAlertApp.Name";
    });
    var widthThemeProperty = _.find(window.IX_Theme.properties, function (item) {
        return item.PropertyName == "CustomAlertApp.Width";
    });
    var heightThemeProperty = _.find(window.IX_Theme.properties, function (item) {
        return item.PropertyName == "CustomAlertApp.Height";
    });
    var titleThemeProperty = _.find(window.IX_Theme.properties, function (item) {
        return item.PropertyName == "CustomAlertApp.Title";
    });
    var classThemeProperty = _.find(window.IX_Theme.properties, function (item) {
        return item.PropertyName == "CustomAlertApp.Class";
    });


    /* When null is returned no custom alert is displayed. See, IX_enhanceAlert.*/
    if (nameThemeProperty == null || _.isEmpty(nameThemeProperty.Value1))
        return null;
    else
        return {
            appName: nameThemeProperty.Value1,
            width: widthThemeProperty ? widthThemeProperty.Value1 : "500px",
            height: heightThemeProperty ? heightThemeProperty.Value1 : "400px",
            title: titleThemeProperty ? titleThemeProperty.Value1 : "Alert",
            class: classThemeProperty ? classThemeProperty.Value1 : ""
        };
}


//For v1 apps
function IX_GetModalService() {
    var icModalService = null;
    if (iXing && iXing.Helpers && iXing.Helpers.getIcModalService) {
        icModalService = iXing.Helpers.getIcModalService();
    }
    return icModalService;
}

function IX_ShowCustomAlert(dialogInfo, messageText, showCancelButton, scope, callbackFunction) {
    /* Initializes the scope and other variables. */
    var dialogScope = scope.$new(true),
        dialogAppName = dialogInfo.appName.normalize(),
        domTemplate = $("<div dx-popup='popupConfig'><applet-" + dialogAppName + " context='context' applet='applet'></applet-" + dialogAppName + "></div>");

    /* Adds the DOM template to the body (Required by dxPopup) */
    domTemplate.appendTo("body");

    /* Injects the context in the new scope. */
    dialogScope.context = scope.context;

    /* Gets the compile service from the current application scope. */
    var compile = scope.compile;

    /* Only one error custom popup can be visible at a time. */
    if (window.IX_CustomAlertPopup)
        window.IX_CustomAlertPopup.hide();

    var icModalService = IX_GetModalService();

    /* Sets up the popup to open immediately. */
    dialogScope.popupConfig = {
        title: dialogInfo.title,
        minHeight: dialogInfo.height,
        maxHeight: dialogInfo.height,
        minWidth: dialogInfo.width,
        maxWidth: dialogInfo.width,
        elementAttr: { "class": dialogInfo.class },
        showTitle: _.isBoolean(dialogInfo.showTitle) ? dialogInfo.showTitle : true,
        onHidden: function (e) {
            if (window.IX_CustomAlertPopup)
                window.IX_CustomAlertPopup.remove();
        },
        onHiding: function (e) {
            icModalService && icModalService.registerModalClose(e.element);
        },
        onShown: function (e) {
            icModalService && icModalService.registerModalOpen(e.element, e.component);
        },
        bindingOptions: {
            visible: 'visible'
        }
    };
    dialogScope.visible = true;

    /* Compiles the application directive (contained within a dxPopup). */
    var result = compile(domTemplate)(dialogScope);

    /* Only now it's safe to execute a digest. */
    dialogScope.$digest();

    /* Puts an object in the window to enable hiding later by the
     * close modal command. See iXing.Actions|_closeModalPopup() */
    window.IX_CustomAlertPopup = {
        hide: function () {
            dialogScope.visible = false;
            dialogScope.$digest();
        },
        remove: function () {
            dialogScope.$destroy();
            result.remove();
        }
    };

    /* Notifies the calling code that the execution is complete, this is required to trigger the Promise.resolve method. */
    callbackFunction(true);
}

function IX_enhanceAlert(messageText, showCancelButton, scope, callbackFunction, okButtonText, cancelButtonText) {
    /* Makes sure the third parameter can also be used to pass a callbackFunction for backwards compatibility. */
    if (typeof scope == "function") {
        callbackFunction = scope;
        scope = null;
    }

    var customAlertInfo = IX_getDisplayCustomAlertInfo();
    if (customAlertInfo && !scope.customAlertForceOverrideText) {
        IX_ShowCustomAlert(customAlertInfo, messageText, showCancelButton, scope, callbackFunction);
        return;
    }

    var lastElement = $(document.activeElement);

    IX_ExtendjQuerySelectors();

    var _originalResizePopUp,
        _resizePopUp = function () {
            IX_centerElement($(".IX_enhanceAlert"));
        }

    if (typeof _ != 'undefined') {
        _originalResizePopUp = _resizePopUp;
        _resizePopUp = _.debounce(_originalResizePopUp, 150);
    }

    var okHTML = $("<button class='IX_enhanceAlertOkButton_Button displayInlineBlock ic-ada-focusonshown'></button>").text(okButtonText ? okButtonText : IX_getMessageText("OK")),
        cancelHTML = $("<button class='IX_enhanceAlertOkButton_Button displayInlineBlock' ></button>").text(cancelButtonText ? cancelButtonText : IX_getMessageText("CANCEL")),
        messageDivHMTML = "<div tabindex='0' role='dialog' aria-labelledby='IX_enhanceAlert_Message'></div>",
        messageIconHTML = "<span title='Informational alert symbol' role='img'></span>",
        messageHTML = "<div id='IX_enhanceAlert_Message' role='alert'>" + messageText + "</div>",
        messageWindowDiv = $("<div></div>").addClass("IX_enhanceAlertWindow"),
        messageDiv = $(messageDivHMTML).addClass("IX_enhanceAlert").hide()
            .append(
                $(messageIconHTML).addClass("IX_enhanceAlertIcon"),
                $(messageHTML).addClass("IX_enhanceAlertText"),
                $("<div></div>").addClass("IX_enhanceAlertButtons")
                    .append(
                        okHTML.addClass("IX_enhanceAlertOkButton"),
                        cancelHTML.addClass("IX_enhanceAlertCancelButton")
                    )
            );
    messageWindowDiv.append(messageDiv);

    var icModalService = IX_GetModalService();
    var TAB_KEY = 9,
        ESC_KEY = 27,
        wrapperClass = ".IX_enhanceAlertWindow",
        cancelBtnClass = ".IX_enhanceAlertCancelButton",
        selectors = { tabbable: ":ix-tabbable" },
        _proxiedKeyBoardTerminatorHandler = function () {
            _sessionTimeoutWarning_keyBoardHandler.apply(null, arguments)
        },
        _toggleTerminator = function (eventName, enabled, handler) {
            if (enabled) {
                $(document).on(eventName, handler)
            } else {
                $(document).off(eventName, handler)
                $(window).off("resize", _resizePopUp);
            }
        },
        _toggleKeyBoardTerminator = function (enabled) {
            var eventName = "keydown" + wrapperClass;
            _toggleTerminator(eventName, enabled, _proxiedKeyBoardTerminatorHandler)
        },
        _onButtonsAction = function (wasOkClick) {
            _toggleKeyBoardTerminator(false);

            var otherMessageDivs = $(wrapperClass).find('.IX_enhanceAlert').not(messageDiv);

            $(wrapperClass).remove();

            if (typeof callbackFunction != "undefined") {
                callbackFunction(wasOkClick);
            }

            setTimeout(function () {

                otherMessageDivs.each(function (index, element) {
                    icModalService && icModalService.registerModalClose(element, null);
                });

                icModalService && icModalService.registerModalClose(messageDiv, lastElement);

            }, 0);
        },
        _toggleResponse = function (e, response) {
            if (e.which == 1 || e.which == 32 || e.which == 13) {
                _onButtonsAction(response);
            }
        },
        _sessionTimeoutWarning_keyBoardHandler = function (e) {

            if (e.keyCode !== TAB_KEY && e.keyCode !== ESC_KEY) {
                return;
            }

            if (e.keyCode === ESC_KEY) {
                _onButtonsAction(true);
                return;
            }

            var $wrapper = $(wrapperClass);
            if (!!$wrapper.length) {
                var tabbableElements = $wrapper.find(selectors.tabbable),
                    $firstTabbable = tabbableElements.first(),
                    $lastTabbable = tabbableElements.last(),
                    isTabOnLast = !e.shiftKey && e.target === $lastTabbable.get(0),
                    isShiftTabOnFirst = e.shiftKey && e.target === $firstTabbable.get(0),
                    isEmptyTabList = 0 === tabbableElements.length,
                    isOutsideTarget = $.inArray(e.target, tabbableElements) === -1;
                if (isTabOnLast || isShiftTabOnFirst || isEmptyTabList || isOutsideTarget) {
                    e.preventDefault();
                    (e.shiftKey ? $lastTabbable : $firstTabbable).focusin().focus();
                }
            } else {
                _toggleKeyBoardTerminator(false);
            }
        };

    $("body").append(messageWindowDiv);

    $(window).resize(_resizePopUp);

    IX_centerElement($(".IX_enhanceAlert"));

    messageDiv.show("fast", function () {
        icModalService && icModalService.registerModalOpen(messageDiv);
        _toggleKeyBoardTerminator(true);
        $(this).find(".IX_enhanceAlertOkButton").on("click keypress", function (e) {
            _toggleResponse(e, true);
        }).focus();

        if (typeof showCancelButton == 'undefined' || !showCancelButton) {
            $(this).find(cancelBtnClass).remove();
        } else {
            $(this).find(cancelBtnClass).on("click keypress", function (e) {
                _toggleResponse(e, false);
            }).focus();
        }
    });
}

function IX_alert2(commandTextId, nextStep, callbackFunction, getCommandFromMessage, s, e) {
    var dialogText = commandTextId;
    if (getCommandFromMessage)
        dialogText = IX_getCommandFromMessageList(commandTextId);
    IX_enhanceAlert(dialogText, false, s, function (result) {
        if (result == true) {
            if (typeof this != "undefined" && typeof this.IX_FLOW != "undefined") this.IX_FLOW = nextStep;
            if (typeof callbackFunction != "undefined") {
                setTimeout(function () {
                    callbackFunction(s, e);
                }, 1);
            }
        }
    });
}

function IX_replacePropertiesInComponent(value, data) {
    if (typeof value != "undefined") {
        if (value.indexOf("{Property") != -1) {
            if (value.indexOf("Count}") > -1) {
                var rowCount = data.length;
                var valueReplaced = value.replace("{Property:Count}", rowCount);
                return valueReplaced;
            }
        } else {
            return value;
        }
    }
}

function IX_customVerticalScrollBars(jqObject) {
    if (!jqObject.children().first().hasClass("jspContainer")) {
        jqObject.jScrollPane({ mouseWheelSpeed: 25 });
    }
}

function IX_SetTimeZone() {
    var date = new Date();
    date.setDate(date.getDate() + 2);
    var timezone = jstz.determine().name();
    document.cookie = 'IXTimezone=' + timezone + IX_GetSecureFlag() + ';expires=' + date + ';path=/';
}

function redirectUserForLogout(extParam) {

    window.clearInterval(iXing.SessionTimeout.IntervalShowAlert);
    clearInterval(iXing.sessionTimeoutStatus);
    iXing.SessionTimeout.TimerVisible = false;
    //console.log("XYZ 2 - redirectUserForLogout")
    $(".IX_enhanceTimeoutWindow").remove();

    iXing.SessionTimeout.IX_triggerSignOutEvent();

    //console.log("redirectUserForLogout - iXing.IX_urlForLogout=", iXing.IX_urlForLogout)
    if (_.isNil(iXing.IX_urlForLogout)) {
        iXing.IX_urlForLogout = "/Membership/ExtPages/SignOut.aspx";
    }

    //to not throw a timeout error message once the user logs out from regular menu
    if (extParam && extParam == "&IX_TMD=Y") {
        var urlRedirect = iXing.IX_urlForLogout;
    } else {
        var urlRedirect = iXing.IX_urlForLogout.replace("&IX_TMO=true", "");
    }

    if (typeof extParam != "undefined" && extParam != null && extParam != undefined)
        urlRedirect = urlRedirect + extParam;

    if (typeof iXing.IX_SignoutScript != 'undefined') {
        iXing.IX_SignoutScript();
    }
    else if (iXing && iXing.IX_urlForSignout && iXing.IX_appForSignOut) {
        // This is for detecting if the clicked on menu item is the sign out button
        // and the application is configured to perform dynamic sign out. If so then
        // we need to perform the LST call and perform dynamic sign out actions.
        //console.log("redirectUserForLogout - urlRedirect", urlRedirect);
        var $ngView = angular.element("[ng-view]");
        var $injector = _.isNil($ngView) ? null : $ngView.injector();
        if (_.isNil($injector)) {
            window.location.assign(urlRedirect);
            return;
        }

        var $utilSvc = $injector.get("utilService");
        $utilSvc.performDynamicSignOutActions(urlRedirect);
    } else {
        window.location.assign(urlRedirect);
    }
}

function IX_reloadServerSessionWithIframe() {
    if ($("#keepAliveFrame").length == 0)
        $("body").append("<iframe id='keepAliveFrame' src='/Membership/ExtPages/blank.html' width='1' height='1' border='0'></iframe>");

    keepAliveFrame = document.getElementById("keepAliveFrame");
    if (keepAliveFrame) {
        var thisSrc = keepAliveFrame.src.split("?");
        keepAliveFrame.src = thisSrc[0] + "?" + Math.random();
    }
}

var IX_checkCSRFCookieChange = function () {
    if (iXing.IXBecomeUserInProcess)
        return;

    if (!IX_initCSRFToken) {
        IX_initCSRFToken = IX_GetCookieValue("XSRF-TOKEN");
    }

    var current_csrf_token = IX_GetCookieValue("XSRF-TOKEN");
    var SAMLLogin = IX_GetCookieValue("IC-SAML");
    var csrfChanged = current_csrf_token !== IX_initCSRFToken;

    if (csrfChanged && !SAMLLogin) {
        clearTimeout(IX_checkCSRFCookieInterval);

        var reloadURL = window.location.href;
        var notLoggedIn = !isUserAuthenticated();
        if (notLoggedIn) {
            // strip any angular routes to prevent crashing by returning to a route not on the app
            var idx = reloadURL.indexOf('#');
            reloadURL = reloadURL.substr(0, idx > 0 ? idx : reloadURL.length);

            window.location.assign(reloadURL); // only reloads when there is a change in url string
        } else {
            window.location.reload(); // forces browser reload

            // wait 1 min before we start checking again
            IX_checkCSRFCookieInterval = setTimeout(IX_checkCSRFCookieChange, 60000);
        }
        return; // prevent the setTimeout below
    }

    IX_checkCSRFCookieInterval = setTimeout(IX_checkCSRFCookieChange, 250);
};

var IX_startCSRFChecks = function () {
    // this prevents us from reloading if we are in a login page
    var isLoggedIn = isUserAuthenticated();
    if (isLoggedIn) {
        IX_checkCSRFCookieInterval = window.setTimeout(IX_checkCSRFCookieChange, 250);
    }
};

var isUserAuthenticated = function () {
    // this checks for authentication in a tests friendly way
    return typeof IX_UserAuthenticated != "undefined" && !!IX_UserAuthenticated;
}

function IX_IsIPhone() {
    return ((navigator.userAgent.match(/iPhone/i)) != null || (navigator.userAgent.match(/iPod/i) != null));
}

function IX_fallbackImagePath(origPath, fallbackFolder) {
    var determineId = origPath.split("/");
    determineId = parseInt(determineId[determineId.length - 1]);
    var imageId = (isNaN(determineId)) ? 0 : parseInt(determineId % 100);
    var newPath = "/App_Themes/Default/images/FallbackImages/" + fallbackFolder + "/FallbackImage_" + imageId + ".png";
    return newPath;
}

function IX_emailifyAsLink(text) {
    var emailRegex = "/(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim";
    var replacedText = text.replace(emailRegex, '<a href="mailto:$1">$1</a>');
    return replacedText;
}

function IX_maxWidth(group) {
    var widest = 0;
    group.each(function () {
        var thisWidth = $(this).width();
        if (thisWidth > widest) {
            widest = thisWidth;
        }
    });
    return (widest);
}

function IC_Alert(messageId) {
    function _alert(alertText) {
        alert(alertText);
    }
    var showFallbackMessage = true, alertText;
    if (messageId) {
        alertText = IX_getMessageText(messageId);
        if (alertText) {
            showFallbackMessage = false;
            _alert(alertText);
        }
    }
    if (showFallbackMessage) {
        alertText = IX_getMessageText("RETRY-OR-CONTACT-SUPPORT");
        _alert(alertText);
    }
}

function getChromeBrowserVersion() {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return raw ? parseInt(raw[2], 10) : false;
}

if (typeof module != 'undefined') {
    module.exports = {
        iXing: iXing,
        isBrowserEdge: isBrowserEdge,
        IX_IsIPhone: IX_IsIPhone,
        IX_isMobile: IX_isMobile,
        btoau: btoau,
        atobu: atobu,
        generateUUID: generateUUID,
        IX_GetMFADataAndResolvePromise: IX_GetMFADataAndResolvePromise,
        IX_MFARequest: IX_MFARequest,
        IX_TriggerSSO: IX_TriggerSSO,
        IX_DeleteCookie: IX_DeleteCookie,
        IX_alert2: IX_alert2,
        IX_checkCSRFCookieChange: IX_checkCSRFCookieChange,
        IX_startCSRFChecks: IX_startCSRFChecks,
        redirectUserForLogout: redirectUserForLogout,
        IX_getQueryStringParameter: IX_getQueryStringParameter,
        IX_SetRememberUsernameCookieValue: IX_SetRememberUsernameCookieValue,
        IX_Ajax: IX_Ajax,
        IX_enhanceAlert: IX_enhanceAlert,
        IX_ExtendjQuerySelectors: IX_ExtendjQuerySelectors,
        IX_GetModalService: IX_GetModalService,
        IX_centerElement: IX_centerElement,
        IX_centerElementVertically: IX_centerElementVertically,
        IX_GetUsernameFromCookieValue: IX_GetUsernameFromCookieValue,
        IX_SetUsernameFromCookieValue: IX_SetUsernameFromCookieValue
    };
}
