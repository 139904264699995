/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxSlider = require('devextreme/ui/slider');

var _dxSlider = DevExpress.ui.dxSlider.redefine({
    _shouldAlwaysKeepTabindex: function () {
        return this._options && this._options.keepTabstopWhenDisabled;
    },
    _renderFocusState: function () {
        this._attachKeyboardEvents();

        if (!this._isFocusable()) {

            if (this._shouldAlwaysKeepTabindex()) {
                if (this.$element().hasClass("dx-slider")) {
                    // a11y: intent is for sliders to have tabindex when disabled
                    // so that screenreader announces information on tab-navigation
                    // that is available visually
                    //
                    // Sliders are a special case; the tabindex needs to be on a child
                    this.$element().find(".dx-slider-handle").attr("tabindex", 0);
                }
            }

            return;
        }

        this._renderFocusTarget();
        this._attachFocusEvents();
        this._renderAccessKey();
    },
    _cleanFocusState: function () {
        var $element = this._focusTarget();

        this._detachFocusEvents();

        this._toggleFocusClass(false);

        if (!this._shouldAlwaysKeepTabindex()) {
            $element.removeAttr("tabIndex");
        }

        this._disposeKeyboardProcessor();
    },
});

DevExpress.registerComponent("dxSlider", _dxSlider);
