import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import { AppsEntity } from '../state/apps.models';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslatableEffectCommand } from './translatable-effect.command';
import { CommandListCollectionService } from "./command-list-collection.service";
import { EcdService } from '../services/clientservices/ecd.service';
import { TranslateFacadeService } from '../services/translate-facade.service';
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { ApiFieldMapper } from '../services/field-mappers/api-field.mapper';
import { ModalService } from '../services/modal.service';
import { ValidationEngineService } from '../services/validation-engine.service';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { UtilService } from '../services/util.service';
import { ApiClientService } from '../services/api-client.service';
import { HelpersService } from '../services/helpers.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { PersonalizationService } from '../services/personalization.service';

@Injectable()
export class ApiMapperCommand extends TranslatableEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected translateFacade: TranslateFacadeService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private ecdService: EcdService,
        private validationEngineService: ValidationEngineService,
        private dynamicReplacementService: DynamicReplacementService,
        private modalService: ModalService,
        private utilService: UtilService,
        private apiClientService: ApiClientService,
        private helpersService: HelpersService,
        protected personalizationService: PersonalizationService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.apiMapperCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.apiMapperCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);
                const apiMapper = new ApiFieldMapper(appsState, this.utilService, this.ecdService, this.apiClientService,
                    this.cacheManagerService, commandConfig, this, this.applicationInformation, this.domComponentRetrievalService,
                    this.dynamicReplacementService, this.validationEngineService, this.modalService, this.helpersService, this.personalizationService);
                const commandPromise = apiMapper.execute();
                return from(commandPromise)
                    .pipe(
                        switchMap(() => {
                            const commandState: AppsEntity[] = Object.values(apiMapper.State);
                            const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                            return this.appendNextActions(thisCommandActions, commandConfig, null);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );
}
