<dx-popup
  [class]="[customCssClass]"
  [showTitle]="true"
  [title]="options.title"
  [showCloseButton]="false"
  [focusStateEnabled]="true"
  [visible]="true"
  Width="400"
  Height="200"
  (onInitialized)="onInitialized"
  (onHiding)="onHiding"
  (onShown)="onShown"
  ic-buttonize
>
  <div *dxTemplate="let data of 'content'">
    <div dx-popup="dxPopup.options" ng-class="customCssClass">
    <div>
        {{ text }}
    </div>
    <dx-button text="No"></dx-button>
    <dx-button text="Yes"></dx-button>
</div>