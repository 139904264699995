<div>
    <ng-container *ngIf="config.dynamicObjs.tableTitle">
        <h2 class="TableTitle" [replaceValue]='config.dynamicObjs.tableTitle'
            [replaceValueUpdated]='config.dynamicObjs.tableTitle.updated'></h2>
    </ng-container>
    <div class="ic-simple-list">
        <div class="sl-dxl-wrapper">
            <dx-list 
                [items]="mutableData"
                [repaintChangesOnly]="true"
                [activeStateEnabled]="false"
                [focusStateEnabled]="dxOptions.focusStateEnabled"
                [itemDragging]="dxOptions.itemDragging"
                [itemTemplate]="dxOptions.itemTemplate"
                (onInitialized)="initComponent($event)"
                (onItemRendered)="dxOptions.onItemRendered($event)"
            >
                <!-- <div *dxTemplate="let item of 'item'">
                    <div class="icsl-item-wrapper">
                        <span class="icsl-item-content">{{item[config.itemField]}}</span>
                        <button *ngIf="componentProperties.hasContextMenu"
                            [class]="['icsl-context-menu-btn', 'icsl-pull-' + componentProperties.menuButtonAlign]"
                            (click)="showContextMenu($event, item)">
                        </button>
                    </div>
                </div> -->

                <dxo-item-dragging
                    [data]="data"
                    [allowReordering]="true"
                    [onReorder]="dxOptions.itemDragging.onReorder">
                </dxo-item-dragging>
            </dx-list>
        </div>
        <div *ngIf="componentProperties.hasContextMenu" class="sl-cm-wrapper">
            <div>
                <!--
                    <ic-context-menu componentProperties="config.contextMenu.componentProperties" config="config.contextMenu.config" applet="applet" checkSize="checkSize" />
                -->
            </div>
        </div>
    </div>
</div>