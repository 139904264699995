
    export class AdvancedFilterExpressionParser {

        filterStr = null;
        joinChar = '';
        spaceChar = ' ';
        singleQuote = 39;
        leftIndex = 0;
        rigthIndex = 0;
        booleanOperators = {
            'AND': 1,
            'OR': 1
        };
        operatorChars = {
            '!': 1,
            '=': 1,
            '<': 1,
            '>': 1
        };



        parse(inputStr) {
            this.filterStr = inputStr;
            return this._parseFilterString();
        }

        private _isDigit(currentIndex) {
            if (this.filterStr == null || currentIndex == null)
                return false;
            // asccii table
            // [0-9] = [48-57]
            // [a-z] = [65-90]
            // [A-Z] = [97-122]
            return this.filterStr.charCodeAt(currentIndex) >= 48 && this.filterStr.charCodeAt(currentIndex) <= 57
                || this.filterStr.charCodeAt(currentIndex) >= 65 && this.filterStr.charCodeAt(currentIndex) <= 90
                || this.filterStr.charCodeAt(currentIndex) >= 97 && this.filterStr.charCodeAt(currentIndex) <= 122;
        }

        private _skipLeftSpaces() {
            while (this.leftIndex < this.rigthIndex && this.filterStr[this.leftIndex] == this.spaceChar) {
                this.leftIndex++;
            }
        }

        private _skipRigthSpaces() {
            while (this.rigthIndex > this.leftIndex && this.filterStr[this.rigthIndex] == this.spaceChar) {
                this.rigthIndex--;
            }
        }

        private _trimString() {
            this._skipLeftSpaces();
            this._skipRigthSpaces();
        }

        private _normalizeFilter(filterItem) {
            // Operator change
            if (filterItem.length > 1 && filterItem[1] == '<>') {
                filterItem[1] = '!=';
            }
            // convert strings to JS primitive values
            if (filterItem.length > 2) {
                filterItem[2] = eval(filterItem[2]);
            }
            return filterItem;
        }

        private _parseFilterString() {

            var parsedFilters = [];
            var field = [];
            var operator = [];
            var value = [];

            if (this.filterStr == null)
                return parsedFilters;

            this.leftIndex = 0;
            this.rigthIndex = this.filterStr.length - 1;

            var controlIndex = this.leftIndex;

            // Trim string
            this._trimString();

            while (this.leftIndex <= this.rigthIndex) {

                // unknown or unsupported filter expression
                if (controlIndex > this.rigthIndex) {
                    parsedFilters = [];
                    break;
                }

                var currentChar = this.filterStr[this.leftIndex];

                if (currentChar == this.spaceChar && field.length > 0 && operator.length > 0 && value.length > 0) {

                    var filterItem = this._normalizeFilter([field.join(this.joinChar), operator.join(this.joinChar), value.join(this.joinChar)]);
                    parsedFilters.push(filterItem);

                    field = [];
                    operator = [];
                    value = [];

                    this._skipLeftSpaces()

                    for (; this.leftIndex < this.rigthIndex && this.filterStr[this.leftIndex] != this.spaceChar; this.leftIndex++) {
                        if (this._isDigit(this.leftIndex)) {
                            operator.push(this.filterStr[this.leftIndex]);
                        }
                    }

                    this._skipLeftSpaces();

                    if (operator.length > 0) {
                        var operatorStr = operator.join(this.joinChar).toUpperCase();
                        if (this.booleanOperators[operatorStr]) {
                            parsedFilters.push(operatorStr);
                        }
                        operator = [];
                        this.leftIndex--;
                    }
                } else if (this._isDigit(this.leftIndex) && operator.length == 0) {
                    field.push(currentChar);
                } else if (this.operatorChars[currentChar] && value.length == 0) {
                    operator.push(currentChar);
                } else if (operator.length > 0) {
                    var quotes = 0;
                    if (this.filterStr.charCodeAt(this.leftIndex) == this.singleQuote)
                        quotes = 2;
                    for (; this.leftIndex <= this.rigthIndex; this.leftIndex++) {
                        if (quotes == 0 && this.filterStr[this.leftIndex] == this.spaceChar) {
                            this.leftIndex--;
                            break;
                        }
                        value.push(this.filterStr[this.leftIndex]);
                        if (this.filterStr.charCodeAt(this.leftIndex) == this.singleQuote)
                            quotes--;
                    }
                }
                this.leftIndex++;
                controlIndex++;
            }
            if (field.length > 0 && operator.length > 0 && value.length > 0) {
                var filterItem = this._normalizeFilter([field.join(this.joinChar), operator.join(this.joinChar), value.join(this.joinChar)]);
                parsedFilters.push(filterItem);
            }
            return parsedFilters;
        }
    }