import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PTierHtmlPipe } from './ptierhtml.pipe';
import { HighchartsChartModule } from 'highcharts-angular';
import {
  DxButtonModule,
  DxDateBoxModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTextAreaModule,
  DxTabsModule,
  DxTabPanelModule,
  DxNumberBoxModule,
  DxMenuModule,
  DxTreeListModule,
  DxTreeViewModule,
  DxAutocompleteModule,
  DxFileUploaderModule,
  DxCheckBoxModule,
  DxListModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTooltipModule,
  DxLoadPanelModule,
  DxDataGridModule,
} from 'devextreme-angular';
import {
  CanvasComponent,
  CanvasNaturalComponent,
  PopupComponent,
  DropdownComponent,
  ButtonComponent,
  DateboxComponent,
  TextboxComponent,
  TextAreaComponent,
  TabsComponent,
  RadioGroupComponent,
  CheckboxComponent,
  NumberboxComponent,
  MenuDefaultComponent,
  AutocompleteComponent,
  MenuSecondaryComponent,
  ListchartComponent,
  FileDeleteComponent,
  FileUploadMultiComponent,
  JumptotopComponent,
  PillslistComponent,
  SimplelistComponent,
  LabelComponent,
  Barchartv4Component,
  DonutchartV4Component,
  FileUploadComponent,
  Newsfeedv4Component,
  MenuInvestcloudwebsiteComponent,
  EnhancedAlertComponent,
  EnhancedTimeoutComponent,
  ThemeCustomAlertComponent,
  ViewLoadAnnouncerComponent,
  TreeListTotalRowComponent,
  DraggableItemComponent,
  ModalGenericComponent,
  ModalConfirmComponent,
  ModalEmailViewerComponent,
  ModalIncomingCallComponent,
  Repeaterv4Component,
  ToolTipComponent,
  Stockchartv4Component,
  AppTemplateComponent,
  MultitagDropdownComponent,
  FilterBuilderComponent,
  NqeditorComponent,
  PwpCanvasComponent,
  TreeListComponent,
  PlaidlinkComponent,
  GroupedstackedbarchartComponent,
  Assetallocationchartv4Component,
  StackedbarswithlinechartComponent,
  YourgoalsgridComponent,
  Growthchartv4Component,
  DonutprogressComponent,
  MqmatextsummaryComponent,
  TileviewComponent,
  MilestoneprogressbarComponent,
  Mqmaquestionnairev4Component,
  TagBoxComponent,
  ContentEditorComponent,
  Twittertimelinev4Component,
  Documentsliderv4Component,
  Reportschedulerv4Component,
  AnswerprogressComponent,
  GoalspersonaltimelineComponent,
  CoveragecomparisonComponent
} from './components';
import { ReplaceValueDirective } from './directives/replace-value.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    DxButtonModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxTabsModule,
    DxTabPanelModule,
    DxNumberBoxModule,
    DxMenuModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxAutocompleteModule,
    HighchartsChartModule,
    DxFileUploaderModule,
    DxCheckBoxModule,
    DxListModule,
    DxScrollViewModule,
    DxPopupModule,
    DxTooltipModule,
    DxLoadPanelModule,
    DxDataGridModule,
    DxTreeListModule,
  ],
  declarations: [
    PTierHtmlPipe,
    CanvasComponent,
    CanvasNaturalComponent,
    DropdownComponent,
    ButtonComponent,
    DateboxComponent,
    TextboxComponent,
    TextAreaComponent,
    ReplaceValueDirective,
    LabelComponent,
    RadioGroupComponent,
    TabsComponent,
    NumberboxComponent,
    MenuDefaultComponent,
    AutocompleteComponent,
    MenuSecondaryComponent,
    ListchartComponent,
    FileDeleteComponent,
    FileUploadMultiComponent,
    JumptotopComponent,
    PillslistComponent,
    SimplelistComponent,
    DonutchartV4Component,
    CheckboxComponent,
    PopupComponent,
    LabelComponent,
    Barchartv4Component,
    FileUploadComponent,
    Newsfeedv4Component,
    MenuInvestcloudwebsiteComponent,
    EnhancedAlertComponent,
    EnhancedTimeoutComponent,
    ThemeCustomAlertComponent,
    ViewLoadAnnouncerComponent,
    DraggableItemComponent,
    TreeListTotalRowComponent,
    ModalGenericComponent,
    ModalConfirmComponent,
    ModalIncomingCallComponent,
    ModalEmailViewerComponent,
    Repeaterv4Component,
    ToolTipComponent,
    Stockchartv4Component,
    AppTemplateComponent,
    MultitagDropdownComponent,
    FilterBuilderComponent,
    NqeditorComponent,
    PwpCanvasComponent,
    TreeListComponent,
    PlaidlinkComponent,
    GroupedstackedbarchartComponent,
    Assetallocationchartv4Component,
    StackedbarswithlinechartComponent,
    YourgoalsgridComponent,
    Growthchartv4Component,
    DonutprogressComponent,
    MqmatextsummaryComponent,
    TileviewComponent,
    MilestoneprogressbarComponent,
    Mqmaquestionnairev4Component,
    TagBoxComponent,
    ContentEditorComponent,
    Twittertimelinev4Component,
    Documentsliderv4Component,
    Reportschedulerv4Component,
    AnswerprogressComponent,
    GoalspersonaltimelineComponent,
    CoveragecomparisonComponent
  ],
  exports: [
    CommonModule,
    TranslateModule,
    DxButtonModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxTabsModule,
    DxTabPanelModule,
    DxNumberBoxModule,
    DxMenuModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxAutocompleteModule,
    DxListModule,
    DxScrollViewModule,
    DxPopupModule,
    DxTooltipModule,
    DxLoadPanelModule,
    DxDataGridModule,
    DxTreeListModule,
    HighchartsChartModule,
    PTierHtmlPipe,
    CanvasComponent,
    CanvasNaturalComponent,
    DropdownComponent,
    ButtonComponent,
    DateboxComponent,
    TextboxComponent,
    TextAreaComponent,
    TabsComponent,
    ReplaceValueDirective,
    LabelComponent,
    RadioGroupComponent,
    NumberboxComponent,
    MenuDefaultComponent,
    AutocompleteComponent,
    MenuSecondaryComponent,
    ListchartComponent,
    FileDeleteComponent,
    FileUploadMultiComponent,
    JumptotopComponent,
    PillslistComponent,
    SimplelistComponent,
    DonutchartV4Component,
    CheckboxComponent,
    PopupComponent,
    FileUploadComponent,
    Newsfeedv4Component,
    MenuInvestcloudwebsiteComponent,
    EnhancedAlertComponent,
    EnhancedTimeoutComponent,
    ThemeCustomAlertComponent,
    ViewLoadAnnouncerComponent,
    TreeListTotalRowComponent,
    ModalGenericComponent,
    ModalConfirmComponent,
    ModalEmailViewerComponent,
    ModalIncomingCallComponent,
    Repeaterv4Component,
    ToolTipComponent,
    Stockchartv4Component,
    AppTemplateComponent,
    MultitagDropdownComponent,
    FilterBuilderComponent,
    NqeditorComponent,
    PwpCanvasComponent,
    TreeListComponent,
    PlaidlinkComponent,
    GroupedstackedbarchartComponent,
    Assetallocationchartv4Component,
    StackedbarswithlinechartComponent,
    YourgoalsgridComponent,
    Growthchartv4Component,
    DonutprogressComponent,
    MqmatextsummaryComponent,
    TileviewComponent,
    MilestoneprogressbarComponent,
    Mqmaquestionnairev4Component,
    TagBoxComponent,
    ContentEditorComponent,
    Twittertimelinev4Component,
    Documentsliderv4Component,
    Reportschedulerv4Component,
    AnswerprogressComponent,
    GoalspersonaltimelineComponent,
    CoveragecomparisonComponent
  ],
})
export class SharedModule {}
