import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { ListApplication } from '../components/list-application';
import { CommandListCollectionService } from "./command-list-collection.service";
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { DefaultFieldMapper } from '../services/field-mappers/default-field.mapper';
import { PillslistComponent } from '../components';
import { ThemeService } from '../services/theme.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { PersonalizationService } from '../services/personalization.service';
import { AppsEntity } from '../state/apps.models';

@Injectable()
export class SetListSelectedValuesCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private themeService: ThemeService,
        private personalizationService: PersonalizationService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.setListSelectedValuesCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.setListSelectedValuesCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                return this.setListSelectedValues(commandConfig)
                    .pipe(
                        switchMap((appEntity) => {
                            const appsState = this.getAppsState(appsStore);
                            _.mergeWith(appsState[appEntity.id].state, appEntity.state);

                            const commandState = this.executeDefaultMapper(commandConfig, appsState, appEntity);
                            const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                            return this.appendNextActions(thisCommandActions, commandConfig);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    private setListSelectedValues(commandConfig: CommandConfig): Observable<any> {

        const fieldMap = this.getFieldMap(commandConfig);
        const sourceFieldName = _.first(fieldMap.sourceFields);

        if (_.isNil(sourceFieldName))
            return throwError("There are no source fields for the command");

        const { appName, fieldName } = this.getTargetMapInfo(fieldMap.sourceApp, sourceFieldName);

        if (!this.applicationInformation.isV4App(appName))
            return throwError("Executing command on unknown app " + appName);

        if (!this.applicationInformation.isPivotedList(appName)
            && !this.applicationInformation.isPillsList(appName)
            && !this.applicationInformation.isListApp(appName))
            return throwError("Source app is not a valid list app " + appName);

        const separator = commandConfig.parameters[1] || "|";
        let allData = [];
        let selectedData = [];

        // this command assumes that the component is rendered
        if (this.applicationInformation.isPivotedList(appName)) {
            const componentName = this.getAppClassName(appName);
            const $appWrapper = $("." + componentName);
            const auxId = "CL" + $appWrapper.attr("id").replace(/_/g, '');
            const pivotedGrid = $appWrapper.find("#" + auxId + "_Pivoted");
            allData = pivotedGrid.data("IX_PivotedGrid_DataSource").items();
            selectedData = allData;
        } else if (this.applicationInformation.isPillsList(appName)) {
            allData = this.getPillsListItems(appName);
            selectedData = allData;
        } else if (this.applicationInformation.isListApp(appName)) {
            const gridInstance = this.domComponentRetrievalService.getDxComponentInstance({}, appName);
            selectedData = gridInstance.getSelectedRowsData();
            if (this.applicationInformation.isTreeList(appName)) {
                allData = gridInstance.getDataSource().items().map((item) => item.data);
            } else {
                allData = gridInstance.getAllItems();
            }
        }

        // The following sets the selected status in the map. 
        const allDataMap = selectedData.reduce((obj, it) => {
            return obj[it[fieldName]] = 1, obj
        }, {});
        // The following constructs the character-separated list using the same
        // sorting criteria as the data displayed on the grid. 
        const characterSeparatedList = allData.filter((it) => !_.isNil(allDataMap[it[fieldName]]))
            .reduce((acc, it, i) => acc + (i > 0 ? separator : "") + it[fieldName], "");

        const appState = {
            [fieldName]: characterSeparatedList
        };
        return of(this.createAppEntity(appName, appState));
    }

    private getAppClassName(appName: string): string {
        return _.isNil(appName) || _.isEmpty(appName) ? null : (appName.replace(/\./g, '_') + "Ctrl");
    }

    private getPillsListItems(pillListName: string): any[] {
        const pillListAppComponent = this.domComponentRetrievalService.getAppComponent(pillListName) as ListApplication;
        if (_.isNil(pillListAppComponent)) return [];
        const pillItems = $("#" + pillListAppComponent.applet.rid).find('.ic-pills-list').data("pillItems");
        const items = _.clone(pillItems);
        return items;
    }

    private executeDefaultMapper(commandConfig, appsState : Record<string, AppsEntity>, appEntity) {
        const fieldMapper = new DefaultFieldMapper(appsState, this, this.themeService, this.cacheManagerService, this.personalizationService);
        const forceSetValue = true;
        fieldMapper.execute(commandConfig, null, forceSetValue);
        const newState = _.cloneDeep(fieldMapper.State);
        if (newState[appEntity.id])
            _.mergeWith(newState[appEntity.id], appEntity);
        else
            newState[appEntity.id] = appEntity;
        return Object.values(newState);
    }

    private getTargetMapInfo(appName, fieldName: string): any {
        // Gets the app name from the field referenced in the first mapped field.
        if (this.isFieldAppReferenced(fieldName))
            return this.getAppReferenced(fieldName)
        return { appName, fieldName };
    }
}
