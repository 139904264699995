import { Component, ChangeDetectionStrategy, ElementRef, AfterViewInit, Input, Output, OnDestroy, OnChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';
import numeral from 'numeral'; // needs to be default to prevent webpack error
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { FieldFormatService } from '../../services/field-format.service';
import { DynamicReplacementService } from '../../services/dynamic-replacement.service';
import { IcValidator } from '../../services/validation-engine/ic-validator';
import { UtilService } from '../../services/util.service';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { ValidationGroupService } from '../../services/validation-group.service';

@Component({
  selector: 'ic-number-box',
  template: `<dx-text-box
    [(value)]="dxValue"
    [class]="cssClass"
    [disabled]="dxDisabled"
    (onInitialized)="dxOptions.onInitialized($event)"
    (onValueChanged)="dxOptions.onValueChanged($event)"
    (onFocusIn)="dxOptions.onFocusIn($event)"
    (onFocusOut)="dxOptions.onFocusOut($event)"
    (onKeyDown)="dxOptions.onKeyDown($event)"
  ></dx-text-box>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberboxComponent implements OnChanges, AfterViewInit, OnDestroy {

  @Input() fieldName: any;
  @Input() model: any;
  @Input() config: any;
  @Input() applet: Applet
  @Input() context: any;
  @Input() conditionalFormats: any;
  @Input() parentModel: any;
  @Input() cssClass: string;
  @Input() checkSize: any;

  @Output() updateState = new EventEmitter<AppStateChange>();

  dxValue: any;
  dxOptions: any;
  translateSubscription: Subscription;
  validator: any;
  min = 0;
  max = 0;
  format: FieldFormat;
  formatName = 'iXingFieldFormat.Integer';
  validatorOptions: any = {};
  dxDisabled = false;
  defaultValue: any;
  pristine = true;
  fieldInFocus = false;
  rangeValidationEvent = 'icNumberBox.validateGroup';
  previousValidationValue: any;
  originalErrorMessage: string;
  originalOnValueChanged: any;
  validationElementId: string;
  pressedKey: any;

  // Events to be replaced
  $onBeforeSent: any;
  $onFailed: any;
  $onAfterSent: any;

  private resetDirtyFieldsToPristine: Subscription;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateFacadeService,
    private dataService: DataService,
    private fieldFormatService: FieldFormatService,
    private validationEngine: ValidationEngineService,
    private utilService: UtilService,
    private validationGroupService: ValidationGroupService,
    private dynamicReplacementService: DynamicReplacementService) { }

  ngOnDestroy() {
    this.resetDirtyFieldsToPristine?.unsubscribe();
    this.translateSubscription?.unsubscribe();
    //this.rangeValidationListener();

    // TODO: figure out if this is needed
    //this.$onBeforeSent();
    //this.$onFailed();
    //this.$onAfterSent();
  }

  ngOnChanges(changes) {
    // Setup default values, e.g. require formats to be set
    if (this.dxOptions == null) {
      this.controller();
    }

    for (const propName in changes) {
      if (propName === 'model') {
        const newVal = changes[propName].currentValue ? changes[propName].currentValue.value : null;
        const oldVal = changes[propName].previousValue ? changes[propName].previousValue.value : null;

        this._log('ngOnChanges' + propName);

        // Allow null and empty string to be used interchangeably when no null format is specified
        const format = this.fieldFormatService.getFormat(this.formatName);
        if (format && (format.isNFS === 'false' || format.n === '')) {
          if ((newVal == null && oldVal === '') || (newVal === '' && oldVal == null)) {
            return;
          }
        }

        if (newVal !== oldVal) {
          this.updateModel(this.model.value);
        }
      }
    }
  }

  ngAfterViewInit() {
    if (!_.isNil(this.config) && !_.isNil(this.config.numberBoxModel)) {
      const $e = $(this.elementRef.nativeElement);
      const options = {
        element: $e,
        target: $e.find('input'),
        appId: this.applet.rid,
        ariaLabelText: this.config.numberBoxModel.ixLabel,
        ariaDescribedBy: this.config.numberBoxModel.ariaDescribedBy,
      };

      this._updateTranslations(options);

      //to change the format based on the locale
      this.translateSubscription = this.translate.onLangChange.subscribe(() => {
        this.updateModel(this.model.value);
        this._updateTranslations(options);
      });
    }

    if (this.checkSize) {
      this.checkSize({
        adjustWidth: true,
        adjustHeight: true,
      });
    }
  }

  _log(...args: any[]) {
    if (window['IX_DEBUG_SETTINGS']?.icNumberBox) {
      window['IX_Log']?.apply(null, args);
    }
  }

  controller() {
    if (_.isNil(this.config)) return;

    const appletId = this.applet.name.replace(/\./g, '');

    this.fieldName = this.utilService.getFieldNameFromConfig(this.config.numberBoxModel);
    this.defaultValue = this.model.value === '' ? null : this.model.value;
    this.formatName = this._getConditionalFormat();

    const _debounceValueChanged = _.debounce(this._doValueChanged.bind(this), 1000);
    const dxOptions = {
      visible: false,
      valueChangeEvent: 'change input',
      placeholder: '',
      inputAttr: {
        placeholder: '',
        required: '',
      },
      onInitialized: (e) => {
        e.component.option(this.dxOptions);

        if (!_.isEmpty(this.validatorOptions)) {
          const editor = e;
          this.validator = new IcValidator(this.validationEngine, editor, this.validatorOptions);
          setTimeout(() => this.isValidationGroupValid(), 10);
        }

        this.resetDirtyFieldsToPristine = this.dataService.subscribeToResetDirtyFieldsToPristine(this.applet.name, () => {
          e.component.option('isValid', true);
        });

        this.validationElementId = appletId + '_ic_number_box_' + this.elementRef.nativeElement.id;
      },
      onValueChanged: (e) => {
        if (this.fieldInFocus) {
          this.pristine = false;
        }
        _debounceValueChanged(e);
      },
      onFocusIn: () => this.fieldInFocus = true,
      onFocusOut: () => {
        this.fieldInFocus = false;
        _debounceValueChanged.flush();
        this.updateModel(this.model.value);
      },
      onKeyDown: (e) => this.pressedKey = e.event.key
    };

    this._setRequiredValidationRule();

    if (_.isNil(this.config.numberProperties.validation) && !_.isNil(this.config.numberBoxModel.dxValidator)) {
      this.config.numberProperties.validation = _.extend({}, this.config.numberBoxModel.dxValidator);
    }
    delete this.config.numberBoxModel.dxValidator;

    if (!_.isNil(this.config.numberProperties)) {
      this.formatName = this.config.numberProperties.format;

      if (this._setMinMaxValidationRules()) {
        dxOptions.inputAttr.required = 'required';
      } else {
        // Setting default min/max values
        this._setMinMaxRangeValues({});
      }
    }

    if (!_.isNil(this.config.numberBoxModel.onValueChanged)) {
      this.originalOnValueChanged = this.config.numberBoxModel.onValueChanged;
      delete this.config.numberBoxModel.onValueChanged;
    }

    _.merge(dxOptions, this.config.numberBoxModel);

    this.setupOldEventHandlersToBeReplaced();

    if (this.config.numberProperties.EagerValidate === 'Yes') {
      this.updateModel(this.model.value);
    }

    dxOptions.inputAttr.placeholder = dxOptions.placeholder; // placeholder gets replaced by html attribute
    this.dxOptions = dxOptions;
  }

  _doValueChanged(e) {
    this._log('onValueChanged', e.value);

    const formattedValue = this._formatFromText(e.value).value;
    if (this.model.value !== formattedValue) {
      this.updateModel(formattedValue);
    }
  }

  updateModel(inputValue) {
    const result = this._formatFromValue(inputValue);
    const originalValue = this.model.value;

    this._log('updateModel before', this.formatName, inputValue, this.model.value, this.model.text, this.dxValue);

    this.model.value = result.value;
    if (!this.fieldInFocus) {
      this.dxValue = result.dxValue;
      this.model.text = result.text;
      // Initial value does not fire validation unless user has manually edited
      const userHasEdited = !this.pristine || this._nullToEmptyString(this.model.value) !== this._nullToEmptyString(this.defaultValue);
      if (userHasEdited && result.value !== this.previousValidationValue) {
        this.previousValidationValue = result.value;
        this._doValidation();
      }
    }
    this._log('updateModel after', this.formatName, inputValue, this.model.value, this.model.text, this.dxValue);
    
    if (originalValue === result.value) {
      this._log('model and new value match', this.formatName, inputValue, originalValue, this.model.text, this.dxValue);
      return;
    }
    this.updateState.emit({ field: this.fieldName, value: this.model.value });
  }

  _nullToEmptyString(val) {
    return val === null ? '' : val;
  }

  _getConditionalFormat() {
    this.formatName = this.utilService.getConditionalFormatRulesFieldMask(this.formatName, this.conditionalFormats, this.parentModel, this.fieldName, null);
    this.format = this.fieldFormatService.getFormat(this.formatName);
    return this.formatName;
  }

  _formatFromValue(inputValue) {
    const format = this._getConditionalFormat();
    let value = this.model.value;
    let text = this.model.text;
    let dxValue = this.dxValue;
    let forceStopCmdExecution = false;

    if (inputValue === '' || inputValue === null) {
      // Clear nulls
      inputValue = null;
      text = this.fieldFormatService.format(format, inputValue);
      value = this.fieldFormatService.unformat(format, text, inputValue);
      dxValue = text;
    } else if (this.utilService.isNumeral(inputValue)) {
      // Format numbers and transform into required formats
      text = this.fieldFormatService.format(format, inputValue);
      value = this.fieldFormatService.unformat(format, text, value);
      dxValue = text;
    } else {
      text = this.fieldFormatService.format(format, value);
      value = value === inputValue ? null : value;
      dxValue = value === null || value === inputValue ? '' : text;
      forceStopCmdExecution = true;
    }

    return {
      value: value,
      text: text,
      dxValue: dxValue,
      forceStopCmdExecution: forceStopCmdExecution,
    };
  }

  _formatFromText(inputText) {
    const format = this._getConditionalFormat();
    let value = this.model.value;
    let text = this.model.text;
    let dxValue = this.dxValue;

    // Clear nulls
    if (inputText === '' || inputText === null) {
      inputText = null;
    }

    value = this.fieldFormatService.unformat(format, inputText, inputText);
    text = this.fieldFormatService.format(format, value);
    dxValue = text;

    return {
      value: value,
      text: text,
      dxValue: dxValue,
    };
  }

  isValidationGroupValid() {
    if (!this.validatorOptions?.validationGroup) {
      return;
    }
    this.validationGroupService.publishRefreshButtons(this.applet.name, this.validatorOptions.validationGroup);
  }

  _doValidation() {
    this._log('_validateAndExecuteButtonClick');

    const promise = this.validator != null ? this.validator.validate(this) : Promise.resolve(true);

    promise.then(() => {
      this.isValidationGroupValid();
      if (!_.isNil(this.originalOnValueChanged)) {
        setTimeout(() => {
          const $e = {
            value: this.model.value,
            which: 1,
          };
          this.utilService.createOnEventHandlerSettings($e, this.dxOptions);
          this.originalOnValueChanged($e);
        }, 0);
      }
    });

    if (this.validatorOptions && this.validatorOptions.validationGroup) {
      // Old rangeValidationListener code removed
      // inline broadcast call and on event handler prior to moving to RX
      const shouldValidateAgain = !this.pristine && this.validator;

      if (shouldValidateAgain) {
        this.validator.validate(this);
      }

      //this.$rS.$broadcast(this.rangeValidationEvent, source);
    }
  }

  _setMinMaxRangeValues(rule) {
    this.min = rule.min;
    this.max = rule.max;

    if (_.isFunction(rule.minFunc)) {
      this.min = rule.minFunc(this.dynamicReplacementService);
    }
    if (_.isFunction(rule.maxFunc)) {
      this.max = rule.maxFunc(this.dynamicReplacementService);
    }

    this.min = isNaN(this.min) || this.min < Number.MIN_SAFE_INTEGER ? Number.MIN_SAFE_INTEGER : this.min;
    this.max = isNaN(this.max) || this.max > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : this.max;

    if (_.isFunction(rule.minFunc)) {
      rule.min = this.min;
    }
    if (_.isFunction(rule.maxFunc)) {
      rule.max = this.max;
    }
  }

  _getRangeErrorMessage(message, min, max, format) {
    if (typeof message === 'function') {
      message = message();
    }
    return () => message.replace(/{Min}/g, numeral(min).format(format)).replace(/{Max}/g, numeral(max).format(format));
  }

  _setMinMaxValidationRules() {
    let hasRules = false;
    if (this.config.numberProperties.validation) {
      const inValidator = this.config.numberProperties.validation;
      if (inValidator.validationGroup && inValidator.validationRules) {
        hasRules = true;
        this.validatorOptions.validationGroup = inValidator.validationGroup;
        this.validatorOptions.validationRules = [];
        for (let v = 0; v < inValidator.validationRules.length; v++) {
          const rule = inValidator.validationRules[v];
          if (rule.type === 'range' && !rule.warning) {
            this.originalErrorMessage = rule.message;
            this._setMinMaxRangeValues(rule);
            rule.message = this._getRangeErrorMessage(this.originalErrorMessage, this.min, this.max, this.format.f);
          }
          this.validatorOptions.validationRules.push(rule);
        }
      }
    }
    return hasRules;
  }

  _updateMinMaxValidationRules() {
    if (this.validatorOptions.validationGroup && Array.isArray(this.validatorOptions.validationRules)) {
      for (let v = 0; v < this.validatorOptions.validationRules.length; v++) {
        if (this.validatorOptions.validationRules[v].type === 'range' && !this.validatorOptions.validationRules[v].warning) {
          this._setMinMaxRangeValues(this.config.numberProperties.validation.validationRules[v]);
          this.validatorOptions.validationRules[v].message = this._getRangeErrorMessage(this.originalErrorMessage, this.min, this.max, this.format.f);
        }
      }
    }
  }

  getRequiredRule(rules) {
    return _.find(rules, (rule) => rule.type ? rule.type === 'required' : false);
  }

  _hasValidationRules() {
    return this.config.numberBoxModel.dxValidator && this.config.numberBoxModel.dxValidator.validationRules;
  }

  _setRequiredValidationRule() {
    if (!this._hasValidationRules()) {
      return;
    }

    const requiredRule = this.getRequiredRule(this.config.numberBoxModel.dxValidator.validationRules);

    if (!requiredRule) {
      return;
    }

    // Set required if no other rules present
    if (!this.validatorOptions.validationRules) {
      this.validatorOptions.validationGroup = this.config.numberBoxModel.dxValidator.validationGroup;
      this.validatorOptions.validationRules = [requiredRule];
      return;
    }

    // Add required to existing rules if not already set
    const existingRequiredRule = this.getRequiredRule(this.validatorOptions.validationRules);
    if (existingRequiredRule) {
      existingRequiredRule.enabled = requiredRule.enabled;
    } else {
      this.validatorOptions.validationRules.push(requiredRule);
    }
  }

  _updateTranslations(options) {
    this.utilService.updateComponentTranslationProperties(options, this.config.translationId);
    this.utilService.createADALabelAttributes(options);
    this.detectChanges();
  }

  setupOldEventHandlersToBeReplaced() {
    const appletId = this.applet.name.replace(/\./g, '');
    const _shouldPerformTask = (...args: any) => {
      //TODO get rules for this function
      return true;
    };

    // TODO: figure out if this is needed
    // this.$onBeforeSent = this.$events.$on('v4:' + appletId + ':Request:onBeforeSent', (obj, args) => {
    //   if (_shouldPerformTask(args)) {
    //     this.dxDisabled = false;
    //   }
    // });
    // this.$onFailed = this.$events.$on('v4:' + appletId + ':Request:onFailed', (obj, args) => {
    //   if (_shouldPerformTask(args)) {
    //     this.dxDisabled = false;
    //   }
    // });
    // this.$onAfterSent = this.$events.$on('v4:' + appletId + ':Request:onAfterSent', (obj, args) => {
    //   if (_shouldPerformTask(args)) {
    //     this._updateMinMaxValidationRules();
    //     this.dxDisabled = false;
    //   }
    // });
  }

  private detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

}
