import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { TranslateFacadeService } from '../../services/translate-facade.service';

@Component({
  selector: 'ic-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent implements OnInit {

  @Input() options: ModalConfirmOptions;
  @Input() onHiding: Function;
  @Input() onShown: Function;

  @Output() yes = new EventEmitter();
  @Output() no = new EventEmitter();

  @ViewChild(DxPopupComponent)
  dxPopup: DxPopupComponent;

  text: string;
  customCssClass: string;
  userAnswered: boolean;
  title: string;
  visible = true;
  showCloseButton: boolean;

  constructor(private translateFacade: TranslateFacadeService) { }

  onClickNo() {
    this.userAnswered = true;
    this.no.emit();
    this.dxPopup.instance.hide();
  }

  onClickYes() {
    this.userAnswered = true;
    this.yes.emit();
    this.dxPopup.instance.hide();
  }

  ngOnInit(): void {
    this.text = this.options.text;
    this.customCssClass = this.options.customCssClass;
    this.title = this.options.title || this.translateFacade.getTranslation('CONFIRM');
    this.showCloseButton = this.options.showCloseButton || false;
  }

  onHidden(e) {
    e.component.dispose();
    e.element.remove();
  }

  onHidingWrapper(e) {
    //Handle case where user dismisses without clicking either Yes or No
    this.onClickNo();

    this.onHiding();
  }

  onInitialized(e) {
    this.dxPopup.instance = e.component;
  }
}
