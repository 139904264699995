import { AppsEntity } from './apps.models';
import { DataService } from '../services/data.service';

export class AppStateBuilder {

    protected appsState: Record<string, AppsEntity>;
    protected appsMap: Set<string>;

    protected state: Record<string, AppsEntity>;
    get State(): Record<string, AppsEntity> {
        this.setState();
        return { ...this.state };
    }

    constructor(
        appsState: Record<string, AppsEntity>,
        private dataService: DataService,
    ) {
        this.appsState = appsState;
        this.state = {};
        this.appsMap = new Set<string>();
    }

    protected shouldFieldBeSet(appName: string, fieldName: string, value: any, force: boolean): boolean {
        const val = this.getAppFieldValue(appName, fieldName);
        const res = ((!_.isNil(value) && value !== "") || force) && (_.isNil(val) || force);
        return res;
    }

    getAppFieldValue(appName: string, fieldName: string): any {
        return this.appsState[appName] ? this.appsState[appName].state[fieldName] : undefined;
    }

    getAppState(appName: string): AppState {
        return this.appsState[appName] ? this.appsState[appName].state : null;
    }

    isInitialized(appName: string): boolean {
        return !_.isNil(this.appsState[appName]);
    }

    initAppState(appName: string): void {
        if (this.isInitialized(appName)) return;
        this.appsState[appName] = {
            id: appName,
            state: {}
        };
    }

    /**
     * Use only when initializing field states
     */
    initializeAppFieldState(appName: string, fieldName: string, value: string|number|boolean, forceSetValue?: boolean): string|number|boolean {
        return this.updateAppFieldState(appName, fieldName, value, forceSetValue, true)
    }

    updateAppFieldState(
        appName: string,
        fieldName: string,
        value: string|number|boolean,
        forceSetValue?: boolean,
        isInitializingFields = false,
    ): string|number|boolean {
        if (isInitializingFields) {
            this.dataService.setAppPristineFieldValue(appName, fieldName, value);
        }

        let isFieldDirty = false;

        if (value !== null || forceSetValue) {
            this.initAppState(appName);
            this.appsState[appName].state[fieldName] = value;
            this.appsMap.add(appName);
            isFieldDirty = this.dataService.isAppFieldDirty(appName, fieldName, value);
        }

        this.dataService.setAppDirtyField(appName, fieldName, !isInitializingFields && isFieldDirty);
        return value;
    }

    protected log(step: string, sourceAppName: string, targetAppName: string, sourceFieldName: string, targetFieldName: string): void {
        const debug = IX_DEBUG_SETTINGS.fieldMapper.debug;
        const allFields = IX_DEBUG_SETTINGS.fieldMapper.allFields;
        const fieldToWatch = IX_DEBUG_SETTINGS.fieldMapper.fieldToWatch;
        if (debug && (allFields || (sourceFieldName == fieldToWatch || targetFieldName == fieldToWatch))) {
            console.log(step, "Source ['", sourceAppName, "']['", sourceFieldName, "'] =", this.getAppFieldValue(sourceAppName, sourceFieldName),
                " Target ['", targetAppName, "']['", targetFieldName, "'] =", this.getAppFieldValue(targetAppName, targetFieldName));
        }
    }

    protected setState() {
        this.appsMap.forEach(appName => this.state[appName] = { ...this.appsState[appName] });
    }
}
