import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, SecurityContext, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DxSelectBoxComponent, DxValidatorComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from "devextreme/data/array_store";
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';
import { IcValidator } from '../../services/validation-engine/ic-validator';
import { UtilService } from '../../services/util.service';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { ValidationGroupService } from '../../services/validation-group.service';
import { DeviceService } from '../../services/device-information.service';

const IX_ITEMS_POPUP_SELECTOR = '.dx-overlay-wrapper.dx-dropdowneditor-overlay.dx-popup-wrapper.dx-dropdownlist-popup-wrapper.dx-selectbox-popup-wrapper';

type DropDownConfig = {
  bindingOptions?: unknown,
  dataSource?: { _postProcessFunc: (T) => typeof T },
  displayColumnHeaders?: [],
  displayColumns?: [],
  displayExpr?: string | ((string) => typeof string),
  dxValidator?: DxValidatorComponent,
  isMulticolumn?: boolean,
  listHeight?: string,
  mappingExpr?: string,
  onInitialized?: () => void,
  onValueChanged?: () => void,
  placeholder?: string,
  preventDisableEnableOnValueChanged?: boolean,
  separator?: string,
  rememberValue?: boolean,
  translationId?: string,
  valueExpr?: string,
};

type DropDownModel = {
  text: string,
  value: string,
  disableValidation: boolean,
};

@Component({
  selector: 'ic-drop-down',
  template: `<dx-select-box
    [dataSource]="ds"
    [(value)]="dxValue"
    [valueExpr]="config.valueExpr"
    [displayExpr]="config.displayExpr"
    [placeholder]="placeholder"
    [class]="cssClass"
    (onInitialized)="dxOptions.onInitialized($event)"
  >
  </dx-select-box>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  component: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  [x: string]: {};

  @Input() fieldName: any;
  @Input() model: DropDownModel;
  @Input() config: DropDownConfig;
  @Input() applet: Applet
  @Input() context: any;
  @Input() conditionalFormats: any;
  @Input() parentModel: any;
  @Input() cssClass: string;
  @Input() checkSize: any;

  @Output() updateState = new EventEmitter<any>();// new EventEmitter<AppStateChange>();

  @ViewChild(DxSelectBoxComponent)
  selectbox: DxSelectBoxComponent;

  rememberValue = true;
  initialized = false;
  ds: DataSource;
  dxValue: any;
  placeholder: any;
  ariaLabelText: any;
  preventDisableEnableOnValueChanged: any;
  translateSubscription: Subscription;
  isValidationGroupValid: () => void;
  onDataSourceChanged: () => void;
  validator = null;
  originalOnValueChanged: any;
  validatorOptions: {
    validationGroup?: string,
  } = {};
  dxOptions: any;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    protected translate: TranslateFacadeService,
    private domSanitizer: DomSanitizer,
    private utilService: UtilService,
    private validationEngine: ValidationEngineService,
    private accessibilityService: AccessibilityService,
    private validationGroupService: ValidationGroupService,
    private deviceService: DeviceService,) {

  }

  ngOnInit(): void {
    this.controller();
  }

  ngAfterViewInit(): void {
    this.link();
  }

  ngOnDestroy(): void {
    this.translateSubscription?.unsubscribe();
    this.ds?.off('changed');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.isEmpty(changes.model)) return;
    const cur = changes.model.currentValue as DropDownModel;
    const prev = changes.model.previousValue as DropDownModel;
    if (
      this.initialized && (
        cur.value !== prev.value ||
        // We might get here via *ClearFormValues action*
        // so cur.value and prev.value will both be emptystring, but dxValue
        // might retain a value that needs to be cleared...
        cur.value !== this.dxValue
      )
    ) {
      this.updateDxValue();
    } else if (typeof cur !== typeof prev && typeof prev !== "undefined") {
      const fieldName = this.fieldName || '';
      const appName = this.applet.name || '';
      console.warn(
        'The values match but the data type does not match, please check FieldType for the fieldName = ' + fieldName + ' in the application ' + appName
      );
    }
  }

  controller(): void {
    if (this.model.value === '') {
      this.model.value = null;
      this.model.text = '';
    }
    this.fieldName = this.utilService.getFieldNameFromConfig(this.config);
    this.placeholder = this.config.placeholder;
    this.preventDisableEnableOnValueChanged = this.config.preventDisableEnableOnValueChanged;

    this.isValidationGroupValid = () => {
      if (!this.validatorOptions?.validationGroup) {
        return;
      }
      this.validationGroupService.publishRefreshButtons(this.applet.name, this.validatorOptions.validationGroup);
    };

    this.setUpDropdownConfig();
    this.translatePlaceHolder();
  }

  link(): void {
    if (_.isPlainObject(this.config)) {
      const $e = $(this.elementRef.nativeElement);
      const options = {
        element: $e,
        target: $e.find('input:last'),
        appId: this.applet.rid,
      };
      this.updateTranslations(options);
      this.translateSubscription = this.translate.onLangChange.subscribe(() => {
        this.translatePlaceHolder();
        this.updateTranslations(options);
      });
    }
  }

  updateTranslations(options): void {
    const translationId = this.config.translationId;
    this.utilService.updateComponentTranslationProperties(options, translationId);
    this.utilService.createADALabelAttributes(options);
    if (translationId + '.ariaLabel' !== options.ariaLabelText && !!options.ariaLabelText && options.ariaLabelText.trim().length > 0) {
      this.ariaLabelText = options.ariaLabelText;
    }
  }

  translatePlaceHolder(): void {
    const options = {
      placeholder: this.placeholder,
    };
    this.utilService.updateComponentTranslationProperties(options, this.config.translationId);
    if (this.config.translationId + '.placeholder' !== options.placeholder) {
      this.placeholder = options.placeholder;
    }
  }

  log(...args): void {
    args.unshift('icDropDown');
    //IX_Log.apply(null, args);
  }

  private detectChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  private updateDxValue(detectChangesBeforeValidation?: boolean) {
    if (this.dxValue !== this.model.value) {
      if (_.isArray(this.dxOptions.dataSource)) {
        this.dxValue = _.isNil(this.model.value) ? '' : this.model.value.toString();
      }
      //if the dataSource for the dropdown is the linked app list app
      else if (_.isUndefined(this.dxOptions.dataSource) || _.isObject(this.dxOptions.dataSource)) {
        this.dxValue = _.isNil(this.model.value) ? '' : this.model.value;
      }

      if (detectChangesBeforeValidation) {
        this.detectChanges();
      }

      // This gives time for angular digest cycle to complete and 
      // bind dxValue to value of the component
      setTimeout(() => this.isValidationGroupValid());
    }
  }

  private emitUpdateState(mapState: any): void {
    if (_.isEmpty(mapState)) return;
    this.updateState.emit({ partialState: mapState });
  }

  // Performs any additional field mapping on value change.
  //AccountId=ListAccountId,AccountGroupId=ListAccountGroupId
  private updateFieldMaps(fieldMapStr, selectedItem): AppState {
    const mapState = {};
    if (_.isNil(fieldMapStr)) return mapState;
    const fieldsMap = fieldMapStr.split(',');
    fieldsMap.forEach((fieldMap) => {
      if (!fieldMap.contains('=')) return;
      const fields = fieldMap.split('=');
      const targetField = fields[0];
      const sourceField = fields[1];
      mapState[targetField] = (selectedItem && !_.isNil(selectedItem[sourceField])) ? selectedItem[sourceField] : null;
    });
    return mapState;
  }

  _waitForDataSource(ds: DataSource): Promise<boolean> {
    return new Promise((resolve) => {
      if (typeof ds === 'object' && !Array.isArray(ds)) {
        this.ds = ds;
        this.onDataSourceChanged = () => {
          this.ds = new DataSource({ store: new ArrayStore({ data: ds.store().__rawData }) });
          const instance = this.selectbox.instance;
          const closeWhenItemsChange = instance.option().closeWhenItemsChange;
          if (closeWhenItemsChange === true && instance.option().opened) {
            instance.close();
            $(this.elementRef.nativeElement).focus();
          }
          resolve(true);
          this.detectChanges();
        };
        this.ds.on('changed', this.onDataSourceChanged);
      } else if (Array.isArray(ds)) {
        this.ds = new DataSource({ store:  new ArrayStore({ data: ds }) });
        resolve(true);
      } else {
        resolve(true);
      }
    });
  }

  _removeUnnecesaryAriaText(e): void {
    $(e.element).find('.dx-scrollview-pull-down .dx-scrollview-pull-down-text').find('div').attr('aria-hidden', 'true');
  }

  _onFocusIn(e): void {
    this._removeUnnecesaryAriaText(e);
    this._fixADAAttributes(e);
  }

  _setTitleTagsForCombobox(e): void {
    const _options = e.find('.dx-list-item');

    if (_options.length > 0) {
      _options.each(function (index, item) {
        const attr = $(this).attr('hovertitle');

        if (!attr) {
          $(this).attr('hovertitle', item.textContent);
          $(this).hover(
            function () {
              $(this).attr('title', $(this).attr('hovertitle'));
            },
            function () {
              $(this).attr('title', '');
            }
          );
        }
      });
    }
  }

  _fixADAAttributes(e) {
    const $element = $(e.element);

    if (this.deviceService.IX_isIOS()) {
      const buttonAria = e.component.option("opened") ? "Expanded" : "Collapsed";
      const buttonValue = e.component.option('value') || e.component.option('placeholder');
      this.accessibilityService.addAriaLabelFromTranslation(
        "IC_DROPDOWN_EDITOR_BUTTON",
        $element,
        ".dx-texteditor-container .dx-dropdowneditor-button",
        buttonValue + " - Dropdown " + buttonAria
      );
    }

    setTimeout(() => {
      const ariaLabelText = this.ariaLabelText;
      const $ps = this.utilService.getAppContext(this);
      const appId = $ps.applet.rid;
      const $target = $element.find('input:last');
      this.utilService.updateADALabelAttributes($element.parent(), $target, ariaLabelText, appId, false);

      if (this.deviceService.IX_isAndroid() || this.deviceService.IX_isIOS()) {
        // Android Talkback and iOS VoiceOver needs to *not* accessibility-focus on input element,
        // because TB cannot activate it with a double-tap (TB merely sends focus event)
        $element.find('input.dx-texteditor-input').attr('aria-hidden', true);
        // If we are using a keyboard, this restriction should be lifted
        $element.on('keydown', function () {
          $element.find('input.dx-texteditor-input').attr('aria-hidden', null);
        });

        // Android TB and iOS VoiceOver can activate the widget via double-tap to div.dx-texteditor-container,
        // but it *cannot* activate the widget when focused on the input type=text.
        $element.find('.dx-texteditor-container').attr('tabindex', '-1');
      } else {
        // Only Android Talkback and iOS VoiceOver needs to see this button (or else it cannot activate dropdown with double-tap).
        // Hide it from other browsers.
        $element.find(".dx-dropdowneditor-button").attr("role", null);
        $element.find(".dx-dropdowneditor-button").attr("aria-hidden", "true");
      }

    }, 500);
  }

  _getListingContainer(ele) {
    return ele.parents('.ic-dropdown-input-container').siblings('.ic-dropdown-list-container');
  }

  _createListingContainer(ele) {
    // Needed for accessibility / swipe navigation.
    // DevExtreme dropdown popup default behavior is to append to end of DOM.
    // Tablets need the popup to be in DOM order (after the input container).
    const parent = ele.parent();
    const container = $('<div><div class="ic-dropdown-input-container"></div><div class="ic-dropdown-list-container"></div></div>');
    parent.append(container);
    ele.appendTo(container.find('.ic-dropdown-input-container'));
    return container;
  }

  _removeListingContainer(ele) {
    const container = ele.parent('.ic-dropdown-input-container').parent();
    container.parent().append(ele);
    container.remove();
  }

  _populateListingContainer(e, container) {
    e.component._popup.option('container', container);

    const listItemsContainer = $('#' + container.find('.dx-texteditor-input').attr('aria-owns'))
      .parent()
      .parent()
      .parent();

    if (this.deviceService.IX_isAndroid() || this.deviceService.IX_isIOS()) {
      this._removeListboxRole(listItemsContainer);
      this._raiseClickForOptionOnEnterKey(listItemsContainer);
    }
  }

  _removeListboxRole(ele) {
    // Android Talkback will tab-stop on the entire listbox as a focus-target, which is meaningless
    ele.find('[role=listbox]').attr('role', null);
  }

  _raiseClickForOptionOnEnterKey(ele) {
    // Android and iOS with external keyboard need this to activate a non-semantic clickable using Enter key
    ele.find('[role=option]').one('keydown', (event) => {
      if (event.which === KEYS.enter) {
        const srcElement = event.srcElement || event.originalEvent.srcElement;
        this._raiseClickEvent(srcElement);
      }
    });
  }

  _raiseClickEvent(ele) {
    const realClick = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
    });
    ele.dispatchEvent(realClick);
  }

  _clearActiveDescendantId(ele) {
    // <input> shouldn't *always* have an aria-activedescendant.
    // This value should only be set when the list is displayed
    // and a list item has keyboard focus (as when using arrow keys
    // to navigate the dropdown list.) However, DevExtreme 18's
    // default behavior is assigning a unique identifier here
    // regardless of keyboard focus/list state.
    // This is invalid when no item is active, per DeQue axe.
    // So, we must remove it. DevExtreme will replace it when
    // activated.

    const inputEle = ele.find('input.dx-texteditor-input');
    if (inputEle.length) {
      inputEle.attr('aria-activedescendant', null);
    }
  }

  changeDefaultValueToNull(e) {
    e.value = null;
    const selectedItem = e.component.option('selectedItem');
    const mapState = this.updateFieldMaps(this.config.mappingExpr, selectedItem);
    this.emitUpdateState(mapState);
  }

  setUpDropdownConfig() {

    const config = {
      visible: false,
      inputAttr: {},
      onEnterKey: (e) => {
        const srcElement = e.event.srcElement || e.event.originalEvent.srcElement;

        const setFocus = (event, ele) => {
          setTimeout(() => {
            const selected = $(event.element).parent().parent().find('.dx-list-item-selected');
            if (selected.length) {
              selected.focus();
            } else {
              ele.focus();
            }
          }, 0);
        };

        this._raiseClickEvent(srcElement);
        setFocus(e, srcElement);
      },
      onInitialized: (editor) => {
        if (editor.component) {
          this.dxOptions.placeholder = this.placeholder;
          const instance = editor.component;
          instance.option(this.dxOptions);

          if (!_.isEmpty(this.validatorOptions)) {
            this.validator = new IcValidator(this.validationEngine, editor, this.validatorOptions);
          }

          this._waitForDataSource(instance.option('dataSource')).then(() => {
            this.initialized = true;

            // TODO: Remove timeouts and figure out the right approach
            setTimeout(() => {
              this.updateDxValue(true);

              if (!_.isEmpty(this.validatorOptions) && this.dxValue !== undefined) {
                instance.option('isValid', true);
              }
            }, 1000);
          });
        }

        if (this.deviceService.IX_isAndroid() || this.deviceService.IX_isIOS()) {
          setTimeout(() => this._fixADAAttributes(editor), 300);
        }

        //the validity check of the input elements is different in IE compared to other browsers
        //elements are marked as invalid if they have a value of empty string(IE) vs valid in chrome
        //since the placeholder display is dependent on validity in devexpress
        //adding a check for IE and displaying the placeholder after component in done rendering (adding half a second to allow that)
        if (this.deviceService.isBrowserEdge()) {
          if (this.placeholder && this.placeholder !== null && (this.dxValue == null || this.dxValue === '')) {
            setTimeout(() => {
              if ((this.dxValue == null || this.dxValue === ''))
                $(editor.element).find('.dx-placeholder').removeClass('dx-state-invisible');
            }, 300);
          }
        }

        this.accessibilityService.addAccessibilityToDropDownsOnInitialized(editor);
      },
      onOptionChanged: (e) => {
        const element = $(e.element);
        this._setTitleTagsForCombobox(element);
      },
      onValueChanged: (e) => {
        //adding a check to see if the value in the dropdown in empty string
        //this is caused by overrideModel function in iXing Helpers
        //by default dx has text - "" and value - null for empty dropdowns
        if (e.value === '') {
          this.changeDefaultValueToNull(e);
        }
        // remove focus from when value clears on android device if search is enabled so keyboard doesn't open automatically
        if (this.dxOptions.searchEnabled && this.deviceService.IX_isAndroid() && (e.value === '' || e.value == null)) {
          const target = $(e.element).find('[tabindex=-1]');
          target.focus();
        }

        if (_.isPlainObject(this.model)) {

          // Treat null, undefined and empty string as same
          const isDifferent = this.nullOrUndefinedToEmptyString(this.dxValue) !== this.nullOrUndefinedToEmptyString(this.model?.value);

          if (isDifferent && this.rememberValue) {
            this.model.value = e.value;
            this.model.text = e.component.option('text');

            if (!this.model.disableValidation) {
              this.validator?.validate(this);
              this.isValidationGroupValid();
            }
          }

          const selectedItem = e.component.option('selectedItem');
          const mapState = this.updateFieldMaps(this.config.mappingExpr, selectedItem);
          mapState[this.fieldName] = this.model.value;
          this.emitUpdateState(mapState);

          if (this.model.disableValidation) {
            delete this.model.disableValidation;
          }

          if (!this.model.disableValidation && this.originalOnValueChanged && isDifferent) {
            setTimeout(() => this.originalOnValueChanged(e), 0);
          }

        }

        this._fixADAAttributes(e);
      },
      onOpened: (editor) => {
        const popupSelector = $(IX_ITEMS_POPUP_SELECTOR);
        const listBoxOffset = editor.component.option().listBoxOffset;

        if (!_.isEmpty(listBoxOffset)) {
          popupSelector.css('top', Number(popupSelector.css('top')) - Number(listBoxOffset));
        }

        this.accessibilityService.addAriaLabelToSelector(popupSelector, "[role='listbox']", 'Dropdown list contents');

        const prefix = 'ic-dropdown-list-container-field-';
        if (popupSelector.length > 0) {
          const classes = popupSelector[0].className.split(' ');
          for (let i = 0; i < classes.length; i++) {
            const currentClass = classes[i];
            if (currentClass.startsWith(prefix)) {
              $(IX_ITEMS_POPUP_SELECTOR).removeClass(currentClass);
            }
          }
          //prefix + e.model.fieldName.toLowerCase()
          popupSelector.addClass(prefix + this.fieldName.toLowerCase());
          popupSelector.addClass('ic-dropdown-list-container');

          setTimeout(() => {
            popupSelector.find('.dx-scrollable-container').scrollTop(0);

            window['IX_ForceShowScrollbars'] && IX_ForceShowScrollbars(popupSelector);
          }, 0);
          if (editor.component._list != null && this.config.listHeight != null) {
            this.setItemsListHeight(editor.component, this.config.listHeight);
          }
        }

        if (this.deviceService.IX_isAndroid() || this.deviceService.IX_isIOS()) {
          const tabindexItems = (ele) => {
            // Android with external keyboard needs this, else Enter key cannot make selection from dropdown
            const options = ele.find('.dx-list-item');
            options.attr('tabindex', 0);
          };

          const focusItem = (ele) => {
            // TalkBack and iOS VoiceOver needs this, else focus flies to top of DOM when dropdown opens on double-tap
            let target = ele.find('[aria-selected=true]');

            if (!target.length && this.deviceService.IX_isAndroid()) {
              target = ele.find('[tabindex=0]');
            }

            target.focus();

            // dropdowns with search enabled need this so focus stays in the input when we create our own list container
            if (this.dxOptions.searchEnabled) {
              editor.component.focus();
            }
          };

          // We need our own container, not the one DevExtreme appends to the end of the DOM, but one that's right after the <input>
          let theContainer = this._getListingContainer($(editor.element));

          if (theContainer.length === 0) {
            theContainer = this._createListingContainer($(editor.element));
          }

          this._populateListingContainer(editor, theContainer);
          this._fixADAAttributes(editor);
          tabindexItems(theContainer);
          focusItem(theContainer);
        }
      },
      onContentReady: (e) => {
        const $element = $(e.element);
        setTimeout(() => {
          $element.find('[tabindex]').focus(() => {
            this._fixADAAttributes(e);
          });

          this._clearActiveDescendantId($element);
        }, 0);

        if (this.deviceService.IX_isMobile()) {
          $element.find("input[type='text']").removeAttr('autocomplete');
        }

        this._fixADAAttributes(e);
      },
      onKeyDown: (e) => {
        this._fixADAAttributes(e);
      },
      onFocusIn: (e) => {
        this._onFocusIn(e);
      },
      onClick: (e) => {
        this._fixADAAttributes(e);
      },
      onClosed: (e) => {
        const $element = $(e.element);
        const theContainer = this._getListingContainer($element);
        if (theContainer.length) {
          // Don't leave it hanging around, otherwise the onOpened handler breaks tablet screen readers.
          this._removeListingContainer($element);
        }

        this._fixADAAttributes(e);

        if (this.deviceService.IX_isAndroid() || this.deviceService.IX_isIOS()) {
          // Without this, focus will fly out to <body>
          const target = $element.find('[tabindex=0]');
          target.focus();
        }
        // remove focus when container closes so input doesn't automatically open keyboard on android device
        if (this.dxOptions.searchEnabled && this.deviceService.IX_isAndroid()) {
          const target = $element.find('[tabindex=0]');
          target.blur();
        }
        // remove focus when container closes so focus doesn't remain on  when modal closes on iOS
        if ($('.dx-overlay-modal').length > 0 && this.deviceService.IX_isIOS()) {
          // TODO
          //document.activeElement.blur();
        }
        this._clearActiveDescendantId($element);
      },
    };

    this.addtionalConfig(config);
  }

  addtionalConfig(config) {
    if (_.isPlainObject(this.config)) {
      delete this.config.bindingOptions;
      delete this.config.onInitialized;

      if (!_.isNil(this.config.onValueChanged)) {
        this.originalOnValueChanged = this.config.onValueChanged;
        delete this.config.onValueChanged;
      }

      if (!_.isNil(this.config.rememberValue)) {
        this.rememberValue = this.config.rememberValue;
      }

      if (!_.isNil(this.config.dxValidator)) {
        _.extend(this.validatorOptions, this.config.dxValidator);
        delete this.config.dxValidator;
        config.inputAttr['required'] = 'required';
      }

      config.groupTemplate = (itemData, itemIndex, itemElement) => {
        let text = !!itemData && _.has(itemData, 'key') ? itemData.key : '';
        if (text == null) text = '';

        text = this.getTrustedHtml(text);
        itemElement.append(text);
      };

      if (this.config.isMulticolumn) {
        const columns = _.extend([], this.config.displayColumns);
        const columnHeaders = _.extend([], this.config.displayColumnHeaders);
        const separator = this.config.separator;

        if (this.config.dataSource && columnHeaders.length > 0) {
          this.config.dataSource._postProcessFunc = (data) => {
            if (Array.isArray(data) && data.length) {
              const header = {};

              for (let i = 0; i < columns.length; i++) {
                header[columns[i]] = columnHeaders[i];
              }

              header['isHeader'] = true;
              header['disabled'] = true;
              data.unshift(header);
            }

            return data;
          };
        }

        config.itemTemplate = (itemData, itemIndex, itemElement) => {
          let bFirst = true;
          const hasSeparator = !_.isNil(separator);

          for (const c in columns) {
            if (Object.prototype.hasOwnProperty.call(columns, c)) {
              let aux = '';

              if (!bFirst && !hasSeparator) {
                aux = " style='margin-left: 15px;'";
              }

              let text = itemData[columns[c]];
              if (text == null) {
                text = '';
              }

              if (!bFirst && hasSeparator && text !== '') {
                text = separator + text;
              }

              text = this.getTrustedHtml(text);

              const $cell = $('<span' + aux + '>').text(text);

              if (itemData.isHeader) {
                $cell.addClass('ic-dropdown-list-cell ic-dropdowmn-list-header-cell');
              } else {
                $cell.addClass('ic-dropdown-list-cell');
              }
              itemElement.append($cell);

              bFirst = false;
            }
          }
        };

        delete this.config.displayColumns;
        delete this.config.isMulticolumn;
      } else {
        config.itemTemplate = (itemData, itemIndex, itemElement) => {
          const displayExpr = this.config.displayExpr ? this.config.displayExpr : '';
          let text = typeof displayExpr === 'function' ? displayExpr(itemData) : !!itemData && _.has(itemData, displayExpr) ? itemData[displayExpr] : '';
          if (text == null) text = '';

          text = this.getTrustedHtml(text);
          itemElement.append(text);
        };
      }
      _.extend(config, this.config);
    }
    this.dxOptions = config;
    this.dxOptions.onInitialized.bind(this);
  }

  setItemsListHeight(instance, height) {
    instance._list.option('height', height);
  }

  private getTrustedHtml(text: string): string {
    return this.domSanitizer.sanitize(SecurityContext.HTML, text);
  }

  private nullOrUndefinedToEmptyString(value: any) {
    if (value == null || typeof value === "undefined") {
      return "";
    }
    return value.toString();
  }
}
