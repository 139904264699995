/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data/load_options';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppsEntity, ListDataEntity } from './apps.models';
import * as AppsActions from './apps.actions';
import * as AppsSelectors from './apps.selectors';
import * as StateActions from './apps.tokens';
import * as CommandActions from '../state/command.actions';
import { AppsConstantsFacade } from '../services/apps-constants.facade';
import { TextAnnouncer } from '../commands/text-announcer.service';
import { CommandListEngine } from '../commands/command-list-engine.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { SessionTimeout } from '../services/session-timeout.service';
import UtilityFunctions from '../utility.functions';

@Injectable()
export class AppsFacade {

  //#region Streams observables

  get events$(): BehaviorSubject<AppsEntity> {
    return this.appsConstantsFacade.events$;
  }

  get replayableEvents$(): Observable<AppsEntity> {
    return this.appsConstantsFacade.replayableEvents$;
  }

  get configuration$(): BehaviorSubject<string> {
    return this.appsConstantsFacade.configuration$;
  }

  get step$(): Observable<string> {
    return this.appsConstantsFacade.step$;
  }

  get workflow$(): Observable<string> {
    return this.appsConstantsFacade.workflow$;
  }

  get breakPoint$(): Observable<string> {
    return this.appsConstantsFacade.breakPoint$;
  }

  //#endregion

  constructor(private store: Store,
    private appsConstantsFacade: AppsConstantsFacade,
    private sessionTimeout: SessionTimeout,
    private commandListEngine: CommandListEngine,
    private textAnnouncer: TextAnnouncer,
    private cacheManagerService: CacheManagerService) {
    UtilityFunctions.bindPTierAPI('updateAppState', this.updateAppState, this);
    UtilityFunctions.bindPTierAPI('resetAppState', this.resetAppState, this);
  }

  private getCurrentAppsState(): Promise<any> {
    return this.store
      .pipe(select(AppsSelectors.getCurrentAppsState))
      .pipe(first())
      .toPromise();
  }

  triggerSessionKeepAliveEvent(): void {
    this.sessionTimeout.triggerSessionKeepAliveEvent();
  }

  private setCommandListPromise(commandListName: string, executionId: string): Promise<boolean> {
    return this.cacheManagerService.setPromise<boolean>(commandListName + executionId);
  }

  startCommandList(applet: any, commandListName: string, e: any): Promise<boolean> {
    const executionId = UtilityFunctions.generateUUID();
    const promise = this.setCommandListPromise(commandListName, executionId);
    const commandAction = this.commandListEngine.startExecution(applet, commandListName, e, executionId);
    this.store.dispatch(commandAction);
    return promise;
  }

  getCommandWithTargetApp(appName: string, commandListName: string): any {
    return this.commandListEngine.getCommandWithTargetApp(appName, commandListName);
  }

  startCommandListIfContextHasChanged(applet: any, commandListName: string, forceExecution: boolean): Promise<any> {
    if (forceExecution)
      return this.startCommandList(applet, commandListName, undefined);
    return this.getCurrentAppsState()
      .then(appsStore => {
        const getAppState = (appName: string) => appsStore.entities[appName]?.state || {};
        const executionId = UtilityFunctions.generateUUID();
        const promise = this.setCommandListPromise(commandListName, executionId);
        const commandAction = this.commandListEngine.startCommandListIfContextHasChanged(applet, commandListName, executionId, getAppState);
        this.store.dispatch(commandAction);
        return promise;
      });
  }

  clearStepEvents(commandConfig: CommandConfig): Promise<any> {
    this.store.dispatch(CommandActions.clearStepEventsCommand({ commandConfig }));
    return (Promise as any).delay(50);
  }

  clearFormValues(commandConfig: CommandConfig): Promise<any> {
    this.store.dispatch(CommandActions.clearFormValuesCommand({ commandConfig }));
    return (Promise as any).delay(50);
  }

  destroyPopup(popupId: string): void {
    this.store.dispatch(CommandActions.destroyModalPopup({ popupId }));
  }

  resetFieldsPristine(commandConfig: CommandConfig): Promise<any> {
    this.store.dispatch(CommandActions.resetFieldsPristineCommand({ commandConfig }));
    return (Promise as any).delay(50);
  }

  toggleSideNavigation(commandConfig: CommandConfig): Promise<any> {
    this.store.dispatch(CommandActions.toggleSideNavigationCommand({ commandConfig }));
    return Promise.resolve(true);
  }

  publishEvent(event: AppsEntity): void {
    this.appsConstantsFacade.publishEvent(event);
  }

  publishAppEvent(appName: string, appState: AppState): void {
    this.appsConstantsFacade.publishAppEvent(appName, appState);
  }

  resetReplayableEvents(): void {
    this.appsConstantsFacade.resetReplayableEvents();
  }

  announceText(textItem: any): void {
    this.textAnnouncer.announce(textItem);
  }

  redirectUserForLogout(extParam?: string): void {
    this.appsConstantsFacade.redirectUserForLogout(extParam);
  }

  createAppStateStream(appName: string): Observable<AppState> {
    return this.store.pipe(select(AppsSelectors.getCurrentAppState, { appName }));
  }

  getCurrentAppStateSync(appName: string): AppState {
    let appState: AppState;
    const stateSelector = select(AppsSelectors.getCurrentAppState, { appName });
    this.store.pipe(
      stateSelector,
      first()
    ).subscribe(currentAppState => appState = { ...currentAppState });
    return appState;
  }

  createListDataSourceStream(appName: string): Observable<ListDataEntity> {
    return this.store.pipe(select(AppsSelectors.getCurrentListDataSource, { appName }));
  }

  setStep(step: string): void {
    this.store.dispatch(AppsActions.setStep({ step }));
  }

  setWorkflow(workflow: string): void {
    this.store.dispatch(AppsActions.setWorkflow({ workflow }));
  }

  setBreakPoint(breakPoint: string): void {
    this.appsConstantsFacade.setBreakPoint(breakPoint);
  }

  setContextName(contextName: string): void {
    this.store.dispatch(AppsActions.setContextName({ contextName }));
  }

  setThemeName(themeName: string): void {
    this.store.dispatch(AppsActions.setThemeName({ themeName }));
  }

  setStateMode(stateMode: string): void {
    this.store.dispatch(AppsActions.setStateMode({ stateMode }));
  }

  updateCommandState(commandState: AppsEntity[]): void {
    const actionProps = {
      commandState,
      commandName: 'app-facade',
      commandListName: ''
    };
    this.store.dispatch(AppsActions.updateCommandState(actionProps));
  }

  loadApplication(ecdRequest: EcdRequest, dynamicReplacements: Record<string, any>): void {
    // TODO: Old code path stateService.onPageLoadKeyRAction has code to deal with personalization.
    //       Implement that functionality in a different way
    this.store.dispatch(AppsActions.loadApplication({ ecdRequest, dynamicReplacements }));
  }

  loadListData(ecdRequest: EcdRequest, loadOptions: LoadOptions): void {
    this.store.dispatch(AppsActions.loadListData({ ecdRequest }));
  }

  updateServerListData(ecdRequest: EcdRequest, deferrers: any[]): void {
    this.store.dispatch(AppsActions.updateServerListData({ ecdRequest, deferrers }));
  }

  updateAppState(appName: string, appState: AppState): void {
    this.appsConstantsFacade.updateAppState(appName, appState);
  }

  resetAppState(appName: string, forceDelete?: boolean): void {
    this.dispatchStateAction(StateActions.RESET_STATE, appName, { forceDelete });
  }

  replaceListData(appName: string, listDataEntity: ListDataEntity): void {
    this.dispatchStateAction(StateActions.REPLACE_LIST_DATA, appName, { listDataEntity });
  }

  setAppFilter(appName: string, listFilterDetails: DataSourceFilterDetails): void {
    this.dispatchStateAction(StateActions.SET_FILTERS, appName, { listFilterDetails });
  }

  private dispatchStateAction(actionType: string, appName: string, payload: any) {
    this.appsConstantsFacade.dispatchStateAction(actionType, appName, payload);
  }

}
