import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import * as fromAppSelectors from '../state/apps.selectors';
import * as fromAppActions from '../state/apps.actions';
import { AppsPartialState } from '../state/apps.reducer';
import { AppEvent } from '../state/app-events.enum';
import { AppsConstantsFacade } from './apps-constants.facade';
import { ResetDirtyFieldsToPristineEvent } from '../state/app-events.types';

@Injectable()
export class DataService {

  constructor(private store: Store<AppsPartialState>,
    private appsConstantsFacade: AppsConstantsFacade) { }

  public setAppDirtyField(appName: string, fieldName: string, isDirty: boolean): void {
    this.store.dispatch(fromAppActions.setAppDirtyField({ appName, fieldName, isDirty }));
  }

  public isAppFieldDirty(appName: string, fieldName: string, value: string | number | boolean): boolean {
    let pristineValue: string | number | boolean;
    this.store.pipe(select(fromAppSelectors.getPristineFieldValue, { appName, fieldName }))
      .subscribe(value => pristineValue = value);
    return this.getComparableValue(value) !== this.getComparableValue(pristineValue);
  }

  public isAppFieldPristine(appName: string, fieldName: string, value: string | number | boolean): boolean {
    if (this.isAppFieldDirty(appName, fieldName, value)) {
      return false;
    }

    return !this.getDirtyFields(appName).includes(fieldName);
  }

  public setAppPristineFieldValue(appName: string, fieldName: string, value: string | number | boolean): void {
    this.store.dispatch(fromAppActions.setAppPristineFieldValue({ appName, fieldName, value }));
  }

  //TODO: Determine why this needs to return a promise, or return void
  public setCurrentAsPristine(appName: string): Promise<boolean> {
    this.store.dispatch(fromAppActions.setCurrentAsPristine({ appName }));
    return Promise.resolve(true);
  }

  public setAppStateDirty(appName: string): void {
    // Used for when we can't track dirty state per-field, such as in list apps.
    this.store.dispatch(fromAppActions.setAppStateDirty({ appName }));
  }

  public isAppStateDirty(appName: string): boolean {
    if (this.getDirtyFields(appName).length) {
      return true;
    }

    let isDirty = false;
    this.store.pipe(select(fromAppSelectors.getIsAppStateDirty, { appName }))
      .subscribe(value => isDirty = value);
    return isDirty;
  }

  public isDisplayBehaviorOnEventOnlyEnabled(appName: string): boolean {
    let isOnEventOnly = false;
    this.store.pipe(select(fromAppSelectors.getAppIsOnEventOnly, { appName }))
      .subscribe(value => isOnEventOnly = value);
    return isOnEventOnly;
  }

  public setDataDisplayBehaviorOnEventOnly(holderAppName: string, appNames: string[]): void {
    this.store.dispatch(fromAppActions.setDataDisplayBehaviorOnEventOnly({ holderAppName, appNames }));
  }

  public disableDisplayBehaviorOnEventOnly(holderAppName: string, appName: string): void {
    this.store.dispatch(fromAppActions.disableDisplayBehaviorOnEventOnly({ holderAppName, appName }));
  }


  public disableDisplayBehaviorOnEventOnlyAllAppInstances(appName: string): void {
    this.store.dispatch(fromAppActions.disableDisplayBehaviorOnEventOnlyAllAppInstances({ appName }));
  }

  //TODO: Determine why this needs to return a promise, or return void
  public resetDirtyFieldsToPristine(appName: string): Promise<boolean> {
    this.store.dispatch(fromAppActions.resetDirtyFieldsToPristine({ appName }));
    this.publishResetDirtyFieldsToPristine(appName);
    return Promise.resolve(true);
  }

  public subscribeToResetDirtyFieldsToPristine(appName: string, callback: () => void): Subscription {
    return this.appsConstantsFacade.events$.pipe(
      filter((event) => AppEvent.ResetDirtyFieldsToPristine.EqualsIgnoreCase(event?.id)),
      tap((event: ResetDirtyFieldsToPristineEvent) => {
        if (event.appName === appName && _.isFunction(callback)) {
          callback();
        }
      })
    ).subscribe();
  }

  private getDirtyFields(appName: string): string[] {
    let dirtyFields: string[];
    this.store.pipe(select(fromAppSelectors.getDirtyFields, { appName }))
      .subscribe(value => dirtyFields = value || []);
    return dirtyFields;
  }

  private publishResetDirtyFieldsToPristine(appName: string): void {
    const event: ResetDirtyFieldsToPristineEvent = {
      id: AppEvent.ResetDirtyFieldsToPristine,
      state: { appName },
    };
    this.appsConstantsFacade.publishEvent(event);
  }

  private getComparableValue(value: string | number | boolean): string {
    // Coerce value to string; whitespace converted to empty string
    if (_.isNil(value)) {
      return '';
    }
    return value.toString().trim();
  }
}
