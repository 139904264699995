import { CacheManagerService } from '../cachemanager.service';
import { PersonalizationService } from '../personalization.service';
import { AppsConstants } from '../../state/apps.constants';
import { AppsEntity } from '../../state/apps.models';

export interface FieldMapper {
    execute(commandConfig: CommandConfig, mapIndex: number, forceSetValue?: boolean): any;
    getFieldMap: any;
    updateWithGroupResponse?;
    updateWithResponse?;
    updateWithObject?;
}


export abstract class BaseFieldMapper {

    protected appsState: Record<string, AppsEntity>;
    protected componentsData: Record<string, any>;

    protected appsMap: Set<string>;
    protected targetFieldMap: Record<string, any>;

    protected state: Record<string, AppsEntity>;
    get State(): Record<string, AppsEntity> {
        return { ...this.state };
    }

    protected abstract cacheManagerService: CacheManagerService;
    protected abstract personalizationService: PersonalizationService;

    constructor(appsState: Record<string, AppsEntity>) {
        this.appsState = appsState;
        this.state = {};
        this.componentsData = {};
        this.targetFieldMap = {};
        this.appsMap = new Set<string>();
    }

    getFieldMap(commandConfig: CommandConfig, i?: number | any): FieldMap {
        const mapIndex = _.isNumber(i) ? i : commandConfig.options.i;
        let fieldMap: FieldMap = commandConfig.parameters[mapIndex];
        if (!this.shouldDefaultMapBeUsed(commandConfig, fieldMap)) return null;
        fieldMap = this.getFieldMapOrDefault(commandConfig, fieldMap);
        return this.getFieldMapBase(fieldMap, commandConfig.appName);
    }

    updateWithGroupResponse(data: any, appMap: Record<string, any>): void {
        if (_.isNil(data) || _.isNil(data.Responses)) return;
        for (const appName in data.Responses) {
            const key = appMap[appName];
            const response = data.Responses[appName];
            if (this.cacheManagerService.has(key)) continue;
            this.cacheManagerService.resolvePromiseWith(key, response);
            this.updateWithResponse(appName, response, true);
        }
        this.setState();
    }

    updateWithResponse(appName: string, response: any, force?: boolean): void {
        this.updateWithObject(appName, response?.SingletonData, force);
    }

    updateWithObject(appName: string, singletonData: Record<string, any>, force?: boolean): void {
        if (_.isNil(singletonData)) return;
        this.initAppState(appName);
        for (const fieldName in singletonData) {
            const fieldValue = singletonData[fieldName];
            if (!this.shouldFieldBeSet(appName, fieldName, fieldValue, force)) continue;
            this.updateAppFieldState(appName, fieldName, fieldValue, force);
        }
    }

    private shouldDefaultMapBeUsed(commandConfig: CommandConfig, fieldMap: string | FieldMap): boolean {
        const action = this.getActionName(commandConfig).toLowerCase();
        if (action.indexOf('mup') != -1 && fieldMap === "") { // If no fieldMap specified, there is an implicit one
            return true;
        }
        return fieldMap != "";
    }

    private getFieldMapOrDefault(commandConfig: CommandConfig, fieldMap: string | any): FieldMap {
        const action = this.getActionName(commandConfig).toLowerCase();
        if (action.indexOf('mup') != -1 && fieldMap === "") {
            return {
                targetApp: commandConfig.appName,
                sourceFields: [],
                targetFields: [],
                targetFieldsToClear: [],
                sourceApp: commandConfig.appName,
                directive: AppsConstants.triggerDirective.none
            };
        }
        if (fieldMap.sourceFields === null) fieldMap.sourceFields = [];
        if (fieldMap.targetFields === null) fieldMap.targetFields = [];
        return fieldMap as FieldMap;
    }

    private getFieldMapBase(fieldMap: FieldMap|string, appName: string): FieldMap {
        let result: FieldMap;

        if (typeof fieldMap === 'string') {
          if (fieldMap.trim().length > 0) {
            result = {
                sourceApp: appName,
                sourceFields: [],
                targetFields: [],
                targetFieldsToClear: [],
                targetApp: "",
                directive: AppsConstants.triggerDirective.none
            };
            const parts = fieldMap.split(",");
            result.targetApp = parts[0];

            if (parts.length == 1) return result;

            const fields = parts[1].split("|");
            fields.forEach(field => {
                const mapItem = field.split("=");
                result.sourceFields.push(mapItem[1]);
                result.targetFields.push(mapItem[0]);
            });
          }
        } else {
          if (_.isNil(fieldMap.sourceApp) || !fieldMap.sourceApp) {
            fieldMap.sourceApp = appName;
          }
          result = fieldMap;
        }

        return result;
    }

    protected shouldFieldBeSet(appName: string, fieldName: string, value: any, force: boolean): boolean {
        const val = this.getAppFieldValue(appName, fieldName);
        const res = ((!_.isNil(value) && value !== "") || force) && (_.isNil(val) || force);
        return res;
    }

    public getAppFieldValue(appName: string, fieldName: string): string | number | boolean {
        return this.appsState[appName] ? this.appsState[appName].state[fieldName] : undefined;
    }

    protected getAppState(appName: string): AppState {
        const personalizedState = this.updateAppStateFromPersonalization(appName);
        if (!_.isEmpty(personalizedState) && this.appsState[appName]) {
            this.appsState[appName].state = personalizedState;
        }

        return this.appsState[appName] ? this.appsState[appName].state : null;
    }

    protected getComponentData(appName: string): any {
        return _.isNil(this.componentsData[appName]) ? null : this.componentsData[appName];
    }

    protected isInitialized(appName: string): boolean {
        return !_.isNil(this.appsState[appName]);
    }

    protected initAppState(appName: string): void {
        if (this.isInitialized(appName)) return;
        const state = this.personalizationService.getAppPersonalizationState(appName);
        this.appsState[appName] = { id: appName, state };
    }

    protected updateAppStateFromPersonalization(appName: string): AppState {
        const newState = {};
        if (this.personalizationService.personalizeContextApp
            && this.personalizationService.personalizeContextAppFields
            && appName?.EqualsIgnoreCase(this.personalizationService.contextAppName)) {
            let appState = this.appsState[appName] ? this.appsState[appName].state : {};
            if (!_.isEmpty(appState)) return newState;
            appState = this.personalizationService.getPersonalization(this.personalizationService.contextAppKey);
            if (appState) {
                this.personalizationService.personalizeContextAppFields.forEach((field) => newState[field] = appState[field]);
            }
        }
        return newState;
    }

    protected updateFieldValue(targetFieldFullName: string, sourceMap: any, targetMap: any, newValue: any, forceSetValue: boolean): void {
        this.log("BEFORE:", sourceMap.appName, targetMap.appName, sourceMap.fieldName, targetMap.fieldName);
        this.targetFieldMap[targetFieldFullName] = this.updateAppFieldState(targetMap.appName, targetMap.fieldName, newValue, forceSetValue);
        this.log("AFTER :", sourceMap.appName, targetMap.appName, sourceMap.fieldName, targetMap.fieldName);
    }

    protected updateAppFieldState(appName: string, fieldName: string, value: any, forceSetValue: boolean): any {
        if (value !== null || forceSetValue) {
            this.appsState[appName].state[fieldName] = value;
            this.appsMap.add(appName);
            return value;
        }
        return undefined;
    }

    protected clearField(targetAppName: string, fieldName: string, forceSetValue: boolean): void {
        this.log("ClearField BEFORE:", targetAppName, targetAppName, fieldName, fieldName);
        this.updateAppFieldState(targetAppName, fieldName, null, forceSetValue);
        this.log("ClearField AFTER :", targetAppName, targetAppName, fieldName, fieldName);
    }

    protected setComponentData(appName: string, tmpMupData: any): void {
        const state = { $ComponentDataToMUP: tmpMupData };
        this.componentsData[appName] = state;
    }

    protected log(step: string, sourceAppName: string, targetAppName: string, sourceFieldName: string, targetFieldName: string): void {
        const debug = IX_DEBUG_SETTINGS.fieldMapper.debug;
        const allFields = IX_DEBUG_SETTINGS.fieldMapper.allFields;
        const fieldToWatch = IX_DEBUG_SETTINGS.fieldMapper.fieldToWatch;
        if (debug && (allFields || (sourceFieldName == fieldToWatch || targetFieldName == fieldToWatch))) {
            console.log(step, "Source ['", sourceAppName, "']['", sourceFieldName, "'] =", this.getAppFieldValue(sourceAppName, sourceFieldName),
                " Target ['", targetAppName, "']['", targetFieldName, "'] =", this.getAppFieldValue(targetAppName, targetFieldName));
        }
    }

    protected getFieldMapInfo(fieldMap: FieldMap, fieldIndex: number): any {

        const sourceFieldName = fieldMap.sourceFields[fieldIndex];
        const targetFieldName = fieldMap.targetFields[fieldIndex];

        const isSourceFieldAppReference = this.isFieldAppReferenced(sourceFieldName);
        const isTargetFieldAppReference = this.isFieldAppReferenced(targetFieldName);
        let targetInfo;
        let sourceInfo;
        if (isTargetFieldAppReference && isSourceFieldAppReference) {
            targetInfo = this.getAppReferenced(targetFieldName);
            sourceInfo = this.getAppReferenced(sourceFieldName);
        } else if (isTargetFieldAppReference && !isSourceFieldAppReference) {
            targetInfo = this.getAppReferenced(targetFieldName);
            sourceInfo = {
                appName: fieldMap.sourceApp,
                fieldName: sourceFieldName
            };
        } else if (!isTargetFieldAppReference && isSourceFieldAppReference) {
            sourceInfo = this.getAppReferenced(sourceFieldName);
            targetInfo = {
                appName: fieldMap.targetApp,
                fieldName: targetFieldName
            };
        } else {
            sourceInfo = {
                appName: fieldMap.sourceApp,
                fieldName: sourceFieldName
            };
            targetInfo = {
                appName: fieldMap.targetApp,
                fieldName: targetFieldName
            };
        }
        return {
            source: sourceInfo,
            target: targetInfo
        }
    }

    protected getAppsInFieldMap(fieldMap: FieldMap, sourceAppName: string, targetAppName: string): Record<string, boolean> {
        const appsMap = {};
        appsMap[sourceAppName] = true;
        appsMap[targetAppName] = true;
        if (fieldMap && fieldMap.sourceFields && fieldMap.targetFields && fieldMap.sourceFields.length > 0
            && fieldMap.targetFields.length > 0 && fieldMap.sourceFields.length === fieldMap.targetFields.length) {
            for (let i = 0; i < fieldMap.sourceFields.length; i++) {
                if (this.isFieldAppReferenced(fieldMap.targetFields[i])) {
                    const targetAppTmp = this.getAppReferenced(fieldMap.targetFields[i]);
                    appsMap[targetAppTmp.appName] = true;
                }
                if (this.isFieldAppReferenced(fieldMap.sourceFields[i])) {
                    const sourceAppTmp = this.getAppReferenced(fieldMap.sourceFields[i]);
                    appsMap[sourceAppTmp.appName] = true;
                }
            }
            appsMap[fieldMap.sourceApp] = true;
            appsMap[fieldMap.targetApp] = true;
        }
        return appsMap;
    }

    protected isFieldAppReferenced(fieldName: string): boolean {
        return fieldName.indexOf('.') != -1;
    }

    protected getAppReferenced(fieldName: string): any {
        const aux = fieldName.split(".");
        return {
            appName: aux[0].replace(/_/g, '.'),
            fieldName: aux[1]
        };
    }

    protected getActionFromTriggerDirective(commandConfig: CommandConfig, i: number | any): string {
        let action = "";
        const mapIndex = _.isNumber(i) ? i : commandConfig.options.i;
        switch (commandConfig.parameters[mapIndex]?.directive) {
            case AppsConstants.triggerDirective.apf:
                action = "APF";
                break;
            case AppsConstants.triggerDirective.keyFirst:
                action = "Key-First";
                break;
            case AppsConstants.triggerDirective.keyRead:
                action = "Key-Read";
                break;
            case AppsConstants.triggerDirective.keyCreate:
                action = "Key-Create";
                break;
            default:
                action = commandConfig.action;
                break;
        }
        return action;
    }

    protected getContainerApp(commandConfig: CommandConfig) {
        const fieldMap = this.getFieldMap(commandConfig);
        const action = commandConfig.action;
        const config = {
            appId: fieldMap.targetApp,
            containerId: _.isNil(commandConfig.appThatOverrides) ? fieldMap.sourceApp : commandConfig.appThatOverrides
        };
        switch (action.toLowerCase()) {
            case "key-read":
            case "tup-create":
            case "mup-update":
            case "mup-create":
            case "mup-delete":
            case "mup-merge":
                config.containerId = commandConfig.appName;
                if (commandConfig.appName == fieldMap.targetApp) {
                    config.appId = commandConfig.appName;
                } else {
                    config.appId = fieldMap.targetApp;
                }
                break;
        }
        return config;
    }

    protected getActionName(commandConfig: CommandConfig, i?: number | any): string {
        const mapIndex = _.isNumber(i) ? i : commandConfig.options.i;
        if (commandConfig.parameters[mapIndex].directive == AppsConstants.triggerDirective.none)
            return commandConfig.action;
        return this.getActionFromTriggerDirective(commandConfig, mapIndex);
    }

    protected setState() {
        this.appsMap.forEach(appName => this.state[appName] = { ...this.appsState[appName] });
    }

    protected resetState() {
        this.state = {};
        this.targetFieldMap = {};
        this.appsMap.clear();
    }
}
