/* eslint-disable */

function setAria() {
    var DISABLED_STATE_CLASS = "dx-state-disabled",
        LIST_ITEM_SELECTOR = ".dx-list-item",
        SELECT_ALL_SELECTOR = ".dx-list-select-all";

    var _activeStateUnit = [LIST_ITEM_SELECTOR, SELECT_ALL_SELECTOR].join(",");

    var _eventBindingTarget = function () {
        return this.$element()
    },
        _getActiveElement = function () {
            var activeElement = _eventBindingTarget.apply(this);
            var listItemElement;
            if (_activeStateUnit) {
                listItemElement = activeElement.find(_activeStateUnit).not("." + DISABLED_STATE_CLASS);
            }
            if (listItemElement.length) {
                activeElement = listItemElement;
            } else {
                activeElement = activeElement.find('input.dx-texteditor-input').not("." + DISABLED_STATE_CLASS);
            }
            return activeElement
        },
        _focusTarget = function () {
            return _getActiveElement.apply(this);
        },
        _getAriaTarget = function () {
            return _focusTarget.apply(this)
        },
        _toggleAttr = function ($target, attr, value) {
            value ? $target.attr(attr, value) : $target.removeAttr(attr)
        };

    var setAttribute = function (option) {
        var attrName = "role" === option.name || "id" === option.name ? option.name : "aria-" + option.name,
            attrValue = option.value;
        if (null === attrValue || void 0 === attrValue) {
            attrValue = void 0
        } else {
            attrValue = attrValue.toString()
        }
        _toggleAttr.apply(this, [option.target, attrName, attrValue])
    };

    if (!_.isObject(arguments[0])) {
        var args = {
            name: arguments[0],
            value: arguments[1],
            target: arguments[2] || _getAriaTarget.apply(this)
        };
        if (_.isFunction(this.filterAria)) {
            this.filterAria.apply(this, [args]);
            if (args.ignore === true)
                return;
        }
        setAttribute.apply(this, [args])
    } else {
        var $target = arguments[1] || _getAriaTarget.apply(this);
        for (var key in arguments[0]) {
            var value = arguments[0][key];
            var args = {
                name: key,
                value: value,
                target: $target
            };
            if (_.isFunction(this.filterAria)) {
                this.filterAria.apply(this, [args]);
                if (args.ignore === true)
                    continue;
            }

            setAttribute.apply(this, [args])
        }
    }
}

module.exports = {
    setAria: setAria
};
