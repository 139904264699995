import { Component, ElementRef, ViewChild, AfterViewInit, Input, Output, OnInit, OnChanges, EventEmitter, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { DynamicReplacementService } from '../../services/dynamic-replacement.service';
import { IcValidator } from '../../services/validation-engine/ic-validator';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { ValidationGroupService } from '../../services/validation-group.service';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import DxCheckBox from 'devextreme/ui/check_box';
import { DxCheckBoxComponent } from 'devextreme-angular/ui/check-box';

@Component({
  selector: 'ic-check-box',
  template: `<dx-check-box 
    #dxCheckBox 
    [(value)]="dxValue" 
    [class]="cssClass"
    (onInitialized)="dxOptions.onInitialized($event)" 
    (onValueChanged)="dxOptions.onValueChanged($event)"
  ></dx-check-box>`,
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() fieldName: any;
  @Input() model: any;
  @Input() config: any;
  @Input() applet: Applet
  @Input() context: any;
  @Input() conditionalFormats: any;
  @Input() parentModel: any;
  @Input() cssClass: string;
  @Input() checkSize: any;

  @Output() updateState = new EventEmitter<AppStateChange>();

  @ViewChild("dxCheckBox", { static: false })
  dxCheckBoxComponent: DxCheckBoxComponent;

  get dxCheckBox(): DxCheckBox {
    return this.dxCheckBoxComponent.instance;
  }

  dxOptions: any;
  dxValue: boolean;
  threeState = false;
  lookups = {
    in: { "Y": true, "N": false },
    out: { "true": "Y", "false": "N" },
  };
  validatorOptions: {
    validationGroup?: string,
  } = {};
  validator: any;
  originalOnValueChanged: Function;
  options: any;
  isValidationGroupValid: () => void;

  constructor(
    private elementRef: ElementRef,
    private utilService: UtilService,
    private translateFacadeService: TranslateFacadeService,
    private validationEngine: ValidationEngineService,
    private validationGroupService: ValidationGroupService,
    private dynamicReplacementService: DynamicReplacementService) { }

  ngOnInit(): void {
    this.onInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model) {
      this.setDxCheckBoxValue(changes.model.currentValue.value);
      if (this.isValidationGroupValid) this.isValidationGroupValid();
    }
  }

  ngAfterViewInit() {
    if (!_.isNil(this.config.checkBoxModel)) {
      const $e = $(this.elementRef.nativeElement);

      let target = $e.find('input:last');
      if (target.attr('type') === 'hidden') {
        // <input type="hidden"> aria-label or aria-labelledby will not be announced in NVDA
        target = target.parent();
      }

      this.options = {
        element: $e,
        target,
        appId: this.applet.rid,
        applicationName: this.applet.name,
        useFieldLabelAsAriaLabel: true
      };
    }
    this.updateTranslations();
  }

  private onInit() {

    this.fieldName = this.utilService.getFieldNameFromConfig(this.config.checkBoxModel);
    this.threeState = this.config.threeState || false;

    const config = {
      visible: false,
      onInitialized: (editor) => {
        if (!$.isEmptyObject(this.validatorOptions)) {
          this.validator = new IcValidator(this.validationEngine, editor, this.validatorOptions);
        }
      },
      onValueChanged: (editor) => {
        const shouldUpdate = this.setCheckBoxModelValue(editor.value);
        if (!shouldUpdate) return;
        this.validator?.validate(this);
        this.isValidationGroupValid();
        this.updateState.emit({ field: this.fieldName, value: this.model.value });
        if (!_.isNil(this.originalOnValueChanged)) {
          setTimeout(() => this.originalOnValueChanged(editor), 0);
        }
      }
    };

    if (!_.isNil(this.config.checkBoxModel.dxValidator)) {
      _.extend(this.validatorOptions, this.config.checkBoxModel.dxValidator);
      delete this.config.checkBoxModel.dxValidator;
    }

    if (!_.isNil(this.config.checkBoxModel.onValueChanged)) {
      this.originalOnValueChanged = this.config.checkBoxModel.onValueChanged;
      delete this.config.checkBoxModel.onValueChanged;
    }

    this.setUpLookups();
    this.setDxCheckBoxValue(this.model.value);
    this.isValidationGroupValid = _.debounce(
      () => {
        if (!this.validatorOptions?.validationGroup) {
          return;
        }
        this.validationGroupService.publishRefreshButtons(this.applet.name, this.validatorOptions.validationGroup);
      }, 150);

    this.dxOptions = { ...config, ...this.config.checkBoxModel };

    if (this.checkSize) {
      this.checkSize({
        adjustWidth: true,
        adjustHeight: true
      });
    }
  }

  private setUpLookups() {
    _.extend(this.lookups, this.config.lookups);
    // ShortLists Translation values mapping so if the value coming back on load
    // is translated one than the correct state of the checkbox is set
    const lookupKeys = Object.keys(this.lookups.in);
    lookupKeys.forEach((key) => {
      const translatedKey = this.translateFacadeService.translateOrDefault(key, key)
      if (translatedKey === key) return;
      this.lookups.in[translatedKey] = this.lookups.in[key];
    });
    if (this.threeState) {
      this.lookups.in["undefined"] = undefined;
      this.lookups.out["undefined"] = undefined;
    }
  }

  private updateTranslations() {
    this.dynamicReplacementService.updateComponentTranslationProperties(this.options, this.config.translationId);
    this.utilService.createADALabelAttributes(this.options);
  }

  private setCheckBoxModelValue(value: any): boolean {
    if (value === null) {
      if (this.threeState) {
        this.model.value = null;
        return true;
      }
      value = false;
    }
    const valueStr = _.isNil(value) ? 'undefined' : value.toString();
    if (!_.isNil(this.lookups?.out[valueStr])) {
      const valueFromLookup = this.lookups.out[valueStr];
      const translatedValue = this.translateFacadeService.translateOrDefault(valueFromLookup, valueFromLookup);
      this.model.value = translatedValue;
      return true;
    }
    return false;
  }

  private setDxCheckBoxValue(value) {
    if (_.isNil(value)) {
      if (this.threeState) {
        this.dxValue = undefined;
        return true;
      }
      value = "N";
    }
    if (!_.isNil(this.lookups?.in[value.toString()])) {
      this.dxValue = this.lookups.in[value];
      return true;
    }
  }

}
