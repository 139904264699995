import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { tap } from 'rxjs/operators';
import { AppsFacade } from '../state/apps.facade';
import { CommandListCollectionService } from "./command-list-collection.service";
import { BaseEffectCommand } from './base-effect.command';
import { DefaultFieldMapper } from '../services/field-mappers/default-field.mapper';
import { ThemeService } from '../services/theme.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { PersonalizationService } from '../services/personalization.service';

@Injectable()
export class EventPublishCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private themeService: ThemeService,
        private appsFacade: AppsFacade,
        private personalizationService: PersonalizationService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.eventPublishCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.eventPublishCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);
                const fieldMapper = new DefaultFieldMapper(appsState, this, this.themeService, this.cacheManagerService, this.personalizationService);
                const forceSetValue = true;
                // Step 1: perform field mapping
                const fieldMap = fieldMapper.execute(commandConfig, null, forceSetValue);
                const commandState = Object.values(fieldMapper.State);
                // Step 2: update store with new state
                const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                // Step 3: trigger event
                const event = appsState[fieldMap.targetApp];
                const publishEventAction = CommandActions.publishEvent({ event });
                // investigate to confirm if the following actions are executed in sequence?
                thisCommandActions.push(publishEventAction);
                return this.appendNextActions(thisCommandActions, commandConfig, commandState);
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route })
        })
    );

    publishEvent$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.publishEvent),
                tap(action => this.appsFacade.publishEvent(action.event))
            ),
        { dispatch: false }
    );

}
