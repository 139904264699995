import { Injectable } from "@angular/core";
import { ThemeService } from "./theme.service";

@Injectable()
export class EditableRowMupService {

    constructor(private themeService: ThemeService) { }

    initialize(row) {
        this.provide(row);
    }

    isChanged(row) {
        this.provide(row);
        return row.node.mupStatus.isChanged();
    }

    isCreated(row) {
        this.provide(row);
        return row.node.mupStatus.isCreated();
    }

    isDeleted(row) {
        this.provide(row);
        return row.node.mupStatus.isDeleted();
    }

    setToCreated(row) {
        this.provide(row);
        row.node.mupStatus.setToCreated();
    }

    setToDeleted(row) {
        this.provide(row);
        row.node.mupStatus.setToDeleted();
    }

    setToNotChanged(row) {
        this.provide(row);
        row.node.mupStatus.setToNotChanged();
    }

    setToUndeleted(row) {
        this.provide(row);
        row.node.mupStatus.setToUndeleted();
    }

    setToUpdated(row) {
        this.provide(row);
        row.node.mupStatus.setToUpdated();
    }

    /**
     * Implementation details of MUP-M
     * verb (create/update/delete) sent up to
     * row
     */
    provide(row) {
        if (!row) {
            throw new RangeError('Row object is required.');
        }

        if (row.node && row.node.mupStatus) {
            return;
        }

        if (!row.node) {
            row.node = {};
        }

        const fieldName = 'MupVerb';
        const createValue = 'C';
        const updateValue = 'U';
        const deleteValue = 'D';
        let noChangeValue = 'N';

        //TODO: Remove theme property and if-block when a-tier is updated with [N]otChanged verb
        if (this.themeService.getThemeProperty('MupVerbUpdateAll')) {
            noChangeValue = updateValue;
        }

        row.node.mupStatus =  this.mupStatusManagerFactory(row, fieldName, createValue, updateValue, deleteValue, noChangeValue);
    }

    /**
     * Business logic for setting created/updated/deleted state of row
     */

    /**
     *
     * @param {Object} row provided by DxTreeList
     * @param {string} fieldName expected by A-tier
     * @param {string} createValue expected by A-tier
     * @param {string} updateValue expected by A-tier
     * @param {string} deleteValue expected by A-tier
     * @param {string} notChangedValue expected by A-tier
     */
    mupStatusManagerFactory(row, fieldName, createValue, updateValue, deleteValue, notChangedValue) {

        var isNewlyAddedRow = function () {
            return !row.data.Id && !!row.data.__KEY__;
        };

        var setField = function (value) {
            row.data[fieldName] = value;
        };

        const initializeAs = isNewlyAddedRow() ? createValue : notChangedValue;

        setField(initializeAs);

        const mupStateManager = {
            initialValue: initializeAs,
            currentState: initializeAs,
            previousState: initializeAs,

            setToCreated: function () {
                this.previousState = this.currentState;
                this.currentState = createValue;

                setField(this.currentState);
            },

            setToDeleted: function () {
                this.previousState = this.currentState;
                this.currentState = deleteValue;

                setField(this.currentState);
            },

            setToUndeleted: function () {
                this.currentState = this.previousState;
                this.previousState = deleteValue;

                setField(this.currentState);
            },

            setToUpdated: function () {
                if (isNewlyAddedRow()) {
                    return;
                }

                this.previousState = this.currentState;
                this.currentState = updateValue;

                setField(this.currentState);
            },

            setToNotChanged: function () {
                this.previousState = this.initialValue;
                this.currentState = this.initialValue;

                setField(this.currentState);
            },

            isCreated: function () {
                return this.currentState === createValue;
            },

            isChanged: function () {
                return this.currentState === updateValue;
            },

            isDeleted: function () {
                return this.currentState === deleteValue;
            },
        };
        return mupStateManager;
    }
}
