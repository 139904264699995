<div class="ic-repeater-wrapper">
  <div class="ic-repeater">
    <div [ngClass]="{ 'rpt-carousel-viewport': config.enableCarousel }"
      *ngIf="config.repeatCriteria === RepeatCriteria.Numerical && rawData?.length">
      <div class='ic-repeater-items' [ngClass]="{ 'rpt-carousel': config.enableCarousel }">
        <ic-app-template *ngFor="let x of [].constructor(repetitionCount); index as index" [config]="appTemplateConfig"
          [index]="index" class="ic-repeater-item" [rawData]="rawData" [context]="context" [parentApplet]="applet">
        </ic-app-template>
      </div>
    </div>
    <div [ngClass]="{ 'rpt-carousel-viewport': config.enableCarousel }"
      *ngIf="config.repeatCriteria === RepeatCriteria.RowData && rawData?.length">
      <div class='ic-repeater-items' [ngClass]="{ 'rpt-carousel': config.enableCarousel }">
        <ng-container *ngFor="let item of rawData; trackBy: getItemHash; index as index">
          <ic-app-template *ngIf="!config.conditionalTemplateApps; else elseBlock" [config]="appTemplateConfig"
            [index]="index" class="ic-repeater-item" [rawData]="rawData" [context]="context" [parentApplet]="applet">
          </ic-app-template>
          <ng-template #elseBlock>
            <ic-app-template [config]="getConfigWithConditionalTemplate(item)" [index]="index" class="ic-repeater-item"
              [rawData]="rawData" [context]="context" [parentApplet]="applet">
            </ic-app-template>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <div class="carousel-nav" *ngIf="config.enableCarousel && rawData?.length">
      <a class='carousel-btn carousel-nav-prev material-icons noselect' (click)="pagePrev()">chevron_left</a>
      <div class="carousel-dots">
        <a [ngClass]="{ 'active': currentPage === index }" *ngFor="let item of rawData; index as index"
          class="carousel-nav-dot material-icons" (click)="pageTo(index)">circle</a>
      </div>
      <a class='carousel-btn carousel-nav-next material-icons noselect' (click)="pageNext()">chevron_right</a>
    </div>
  </div>
</div>
