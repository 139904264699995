/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');

var VALID_TYPES = {
    // §18.18.11, ST_CellType (Cell Type)
    "boolean": "b",
    "date": "d",
    "number": "n",
    "string": "s"
};

var numeralNumberFormats = {}, utilSvc, formatSvc;

DevExpress.clientExporter.excel.creator.redefine({
    '_prepareStyleData': function () {
        var _this = this;
        var exporter = this,
            styles = exporter._dataProvider.getStyles();
        var counter = 0;
        exporter._dataProvider.getColumns().forEach(function (column) {
            exporter._colsArray.push(exporter._calculateWidth(column.width));
            // Override code for linking column dataField to the styleArray
            styles[counter + 3].dataField = column.dataField;
            counter++;
            // End
        });
        var fonts = [{
            size: 11,
            color: {
                theme: 1
            },
            name: "Calibri",
            family: 2,
            scheme: "minor",
            bold: false
        }, {
            size: 11,
            color: {
                theme: 1
            },
            name: "Calibri",
            family: 2,
            scheme: "minor",
            bold: true
        }];
        this._excelFile.registerFont(fonts[0]);
        this._excelFile.registerFont(fonts[1]);
        styles.forEach(function (style) {
            var numberFormat = exporter._tryConvertToExcelNumberFormat(style.format, style.dataType);
            if (_.isUndefined(numberFormat)) {
                numberFormat = 0
            }
            exporter._styleArray.push({
                font: fonts[Number(!!style.bold)],
                numberFormat: numberFormat,
                dataField: style.dataField,
                alignment: {
                    vertical: "top",
                    wrapText: !!style.wrapText,
                    horizontal: style.alignment || "left"
                }
            })
        });
        exporter._styleArrayIndexToCellStyleIdMap = exporter._styleArray.map(function (item) {
            return _this._excelFile.registerCellFormat(item)
        })
    },
    '_prepareValue': function (rowIndex, cellIndex) {
        var dataProvider = this._dataProvider,
            cellData = dataProvider.getCellData(rowIndex, cellIndex),
            cellType = dataProvider.getCellType(rowIndex, cellIndex),
            type = this._getDataType(cellType),
            value = cellData.value,
            cellSourceData = cellData.cellSourceData,
            sourceValue;

        if (type === VALID_TYPES.date && !DevExpress.ic.typeUtils.isDate(value)) {
            type = VALID_TYPES.string;
        }

        switch (type) {
            case VALID_TYPES.string:
                sourceValue = value;
                value = this._appendString(value);
                break;

            case VALID_TYPES.number:
                var percentNotMultipliedIsTrue = false, appScope, iXingFormatName, iXingFormat;

                if (utilSvc && utilSvc.getApplicationScope) {
                    var appScope = utilSvc.getApplicationScope(dataProvider._exportController.component.option('elementAttr')['data-appName']);
                    var iXingFormatName = appScope.applet.formats[cellSourceData.column.dataField];
                    var iXingFormat = utilSvc.getFormat(iXingFormatName);
                    percentNotMultipliedIsTrue = _.get(iXingFormat, 'percentnotmultiplied') === "true";

                    // Building Numeral number format array for excel export - 
                    // for applying negative paranethesis format same as numeral formats
                    // And applying currency symbol if locale is set to other than default
                    if (!numeralNumberFormats[cellSourceData.column.dataField]) {
                        var currencySymbol = getLocaleCurrencySymbol(iXingFormat, cellSourceData.column.format);
                        numeralNumberFormats[cellSourceData.column.dataField] = {
                            currencySymbol: currencySymbol,
                            format: iXingFormat.f
                        };
                    }

                }
                // If the number is a percent, excel multiplies the value by 100.
                // Since 1.23 is interpreted as 123% and not 1.23% we need to pre-divide the value.                    
                sourceValue = value;
                var styleId = dataProvider.getStyleId(rowIndex, cellIndex);
                var format = dataProvider.getStyles()[styleId].format;
                if (
                    typeof format === "object" &&
                    typeof format.type === "string" &&
                    format.type.toLowerCase() == "percent" &&
                    (format.scale || percentNotMultipliedIsTrue)
                ) {
                    if (percentNotMultipliedIsTrue) {
                        value *= 0.01;
                    }
                    else {
                        var scale = parseFloat(format.scale);
                        if (!isNaN(scale))
                            value *= scale;
                    }
                }

                break;

            case VALID_TYPES.date:
                sourceValue = value;
                value = this._tryGetExcelDateValue(value);
                type = VALID_TYPES.number;
                break;
        }

        return {
            value: value,
            type: type,
            sourceValue: sourceValue,
            cellSourceData: cellSourceData
        };
    },

    '_getDataArray': function () {
        var rowIndex, cellIndex, cellsArray, cellData, cellsLength, that = this,
            result = [],
            dataProvider = that._dataProvider,
            rowsLength = dataProvider.getRowsCount(),
            columns = dataProvider.getColumns();

        utilSvc = dataProvider._exportController.component.option('ic.$utilSvc'),
            formatSvc = dataProvider._exportController.component.option('ic.$formatSvc');

        var setFormattedSummaryValues = function (dataProvider, rowIndex, cellIndex, cellData) {
            var styleArrayIndex = dataProvider.getStyleId(rowIndex, cellIndex);
            var cellStyleId = that._styleArrayIndexToCellStyleIdMap[styleArrayIndex];
            if (dataProvider.hasCustomizeExcelCell && dataProvider.hasCustomizeExcelCell()) {
                var value = cellData.sourceValue || cellData.value;
                var modifiedExcelCell = that._callCustomizeExcelCell({
                    dataProvider: dataProvider,
                    value: value,
                    style: that._styleArray[styleArrayIndex],
                    sourceData: cellData.cellSourceData,
                });

                if (modifiedExcelCell.value !== value) {
                    if (typeof (modifiedExcelCell.value) !== typeof (value) ||
                        typeof (modifiedExcelCell.value) === "number" &&
                        !isFinite(modifiedExcelCell.value)) {

                        var cellDataType = that._tryGetExcelCellDataType(modifiedExcelCell.value);
                        if (DevExpress.ic.typeUtils.isDefined(cellDataType)) {
                            cellData.type = cellDataType;
                        }
                    }
                    // 18.18.11 ST_CellType (Cell Type)
                    switch (cellData.type) {
                        case VALID_TYPES.string:
                            cellData.value = that._appendString(modifiedExcelCell.value);
                            break;
                        case VALID_TYPES.date:
                            cellData.value = modifiedExcelCell.value;
                            break;
                        case VALID_TYPES.number: {
                            var newValue = modifiedExcelCell.value;
                            var excelDateValue = that._tryGetExcelDateValue(newValue);
                            if (DevExpress.ic.typeUtils.isDefined(excelDateValue)) {
                                newValue = excelDateValue;
                            }
                            cellData.value = newValue;
                            break;
                        }
                        default:
                            cellData.value = modifiedExcelCell.value;
                    }
                }
                cellStyleId = that._excelFile.registerCellFormat(modifiedExcelCell.style);
            }

            return {
                style: cellStyleId,
                value: cellData.value,
                type: cellData.type
            };
        };

        var title = dataProvider._exportController.option("title");

        //to find the button columns in the grid
        var buttonColumnIndexes = [];
        _.forEach(columns, function (itm, ind) {
            if (itm.dataField.startsWith("#Button.")) {
                buttonColumnIndexes.push(ind);
            }
        });

        for (rowIndex = 0; rowIndex < rowsLength; rowIndex++) {
            cellsArray = [];
            cellsLength = columns.length;
            for (cellIndex = 0; cellIndex !== cellsLength; cellIndex++) {
                cellData = that._prepareValue(rowIndex, cellIndex);
                cellsArray.push(setFormattedSummaryValues(dataProvider, rowIndex, cellIndex, cellData));
            }
            if (!that._needSheetPr && dataProvider.getGroupLevel(rowIndex) > 0) {
                that._needSheetPr = true;
            }

            //if the grid is grouped and has a button, values are misaligned
            //this part fixes that issue
            if (dataProvider._options.groupColumns.length > 0 && !(cellData.cellSourceData.rowType == "group")) {
                //splicing the cell space reserved for button column
                _.forEach(buttonColumnIndexes, function (item) {
                    cellsArray.splice(item, 1);
                });

                //pushing the while data and header row one column to the right, so that the grouping columns is distinct and appears one column to the left
                //for presentation purposes
                cellsArray.unshift({ style: undefined, value: undefined, type: "s" });
            }
            if (dataProvider._options.groupColumns.length > 0 && cellData.cellSourceData.rowType == "group") {
                cellsArray.splice(1, 0, { style: undefined, value: undefined, type: "s" });
                if (buttonColumnIndexes.length > 0) {
                    _.forEach(buttonColumnIndexes, function (btnInd) {
                        cellsArray.splice(btnInd, 1);
                    })
                }
            }

            //if there is no grouping in the grid
            //just removing the button cell is enough
            if (dataProvider._options.groupColumns.length == 0 && buttonColumnIndexes.length > 0) {
                _.forEach(buttonColumnIndexes, function (item) {
                    cellsArray.splice(item, 1);
                });
            }

            result.push(cellsArray);
        }

        applyNumberFormatting(that, numeralNumberFormats);

        // Unshift title at the end so no strings get misaligned.
        if (typeof title != 'undefined') {
            var titleCell = [];

            titleCell.push({
                style: dataProvider.getStyleId(0, 0),
                value: this._appendString(title),
                type: "s"
            });

            result.unshift(titleCell);

            // Duplicate the header row's properties for the added title row.
            dataProvider._options.columns.unshift(dataProvider._options.columns[0]);
        }

        return result;
    }

});

// Apply Number Formatting to the Excel Exporter Formats
// 1. Apply negative paranthesis from numeral number formats i.e. (0.00) => 0.00;(0.00) 
// 2. Apply locale currency symbol to currency formats i.e. $#.## => £#.##
function applyNumberFormatting(that, numeralNumberFormats) {

    if (that._styleArray && that._excelFile._numberFormatTags && that._excelFile._numberFormatTags.length > 0) {

        var dataProvider = that._dataProvider;
        var columnsWithNumberFormats = getNumberColumns(dataProvider);

        for (var i = 0; i < columnsWithNumberFormats.length; i++) {
            var column = columnsWithNumberFormats[i];

            // Style calculated by Excel Format Converter class (excel_format_converter.js) 
            // For example "fixedPoint" => "#,##0{0}"
            // the above is done for each column for numbers and if there is duplication than only one is kept and referenced
            var columnStyleFromExcelStyleArray = getStyleFromExcelStyleArray(column.dataField, that._styleArray);

            if (columnStyleFromExcelStyleArray) {
                var excelSheetNumFormats = _.find(that._excelFile._numberFormatTags, {
                    formatCode: columnStyleFromExcelStyleArray.numberFormat
                });

                // Numeral Format is the format transformed by numeral library and shown within the Grid Cells
                var numeralFormat = numeralNumberFormats[columnStyleFromExcelStyleArray.dataField];

                if (!excelSheetNumFormats || !numeralFormat)
                    continue;

                // Override Excel exporter default currency format with the numeral Currency Format
                //if(excelSheetNumFormats.formatCode.indexOf("$") > -1) {
                //    var numeralCurrencyFormat = numeralFormat.format.split(';')[0];
                //    excelSheetNumFormats.formatCode = !numeralCurrencyFormat ? 
                //                numeralFormat.f.replaceAll('(', '').replaceAll(')','') : numeralCurrencyFormat.replaceAll('(', '').replaceAll(')','');
                //}

                // The below if statement checks for the numeral format shown in the grid if the negative value is shown within the paraenthesis
                // If so modify the collection of numberFormatTags to be within paraenthesis as well for that particular column
                if (_.startsWith(numeralFormat.format, "(") && _.endsWith(numeralFormat.format, ")")) {

                    // The collection of all shared number formats is saved into that._excelFile._numberFormatTags
                    // By default the numberFormatTags array only holds default values and no negative/zero/null formats
                    // The below code is overwritting the format values with the same positive number format with negative values within paraenthesis 
                    if (excelSheetNumFormats)
                        excelSheetNumFormats.formatCode = excelSheetNumFormats.formatCode + ";(" + excelSheetNumFormats.formatCode + ")";
                }


                // If number format is currency and currency symbol is not default $ symbol than change the currency symbol for excel formats
                // The currency symbol is picked up by numeral zero and null formats where the first character is always a currency symbol. 
                if (numeralFormat && numeralFormat.currencySymbol && excelSheetNumFormats &&
                    excelSheetNumFormats.formatCode.indexOf("$") > -1 &&
                    numeralFormat.currencySymbol !== "$") {
                    excelSheetNumFormats.formatCode = excelSheetNumFormats.formatCode.replace(/\$+/g, numeralFormat.currencySymbol);
                }

            }

        }
    }
}

function getNumberColumns(dataProvider) {
    var columnsWithNumberFormats = _.map(_.filter(dataProvider.getColumns(), { "dataType": "number" }), function (column) { return { format: column.format, dataField: column.dataField }; });
    return columnsWithNumberFormats;
}

function getStyleFromExcelStyleArray(dataField, styleArray) {
    var styleFromStyleArray = _.map(_.filter(styleArray, { "dataField": dataField }), function (stArr) { return { dataField: stArr.dataField, numberFormat: stArr.numberFormat }; });
    return styleFromStyleArray[0];
}

function getLocaleCurrencySymbol(iXingFormat, columnFormat) {
    if (!columnFormat || !iXingFormat || (columnFormat.type !== "currency" || iXingFormat.f.indexOf("$") < 0))
        return;

    var currencySymbol = formatSvc.getCurrencySymbol();
    if (currencySymbol && currencySymbol !== "$") // Locale currency symbol is differnt from dollar sign
        return currencySymbol;

    return;
}
