import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { AppsEntity } from '../state/apps.models';
import { AppEvent } from '../state/app-events.enum';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommandListCollectionService } from "./command-list-collection.service";
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { BaseEffectCommand } from './base-effect.command';
import { CacheManagerService } from '../services/cachemanager.service';
import { ApplicationInformation } from '../services/application-information.service';
import { EventsService } from '../services/events.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { HelpersService } from '../services/helpers.service';

interface ClearFieldSettings {
    appName: string;
    fieldName: string;
    fieldValue: any;
}

@Injectable()
export class ClearFieldValueCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected cacheManagerService: CacheManagerService,
        protected commandListCollectionService: CommandListCollectionService,
        private eventsService: EventsService,
        private applicationInformation: ApplicationInformation,
        protected dynamicReplacementService: DynamicReplacementService,
        protected domComponentRetrievalService: DomComponentRetrievalService,
        private helpersService: HelpersService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.clearFieldValueCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.clearFieldValueCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                return this.clearFieldValue(commandConfig)
                    .pipe(
                        switchMap((appEntity) => {
                            const appState = this.getAppState(appEntity.id, appsStore);
                            _.mergeWith(appState, appEntity.state);
                            _.mergeWith(appEntity.state, appState);
                            const thisCommandActions = this.getUpdateCommandStateActions([appEntity], commandConfig);
                            return this.appendNextActions(thisCommandActions, commandConfig);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    private clearFieldValue(commandConfig: CommandConfig): Observable<AppsEntity> {
        const fieldValuePromise = this.getClearDefaultValue(commandConfig);
        return from(fieldValuePromise)
            .pipe(
                switchMap((settings: ClearFieldSettings) => {
                    this.resetAppAudioRecordingFields(settings);
                    const id = settings.appName;
                    const state = {};
                    state[settings.fieldName] = settings.fieldValue;
                    this.helpersService.setDirtyFields(settings.appName, settings.fieldName, settings.fieldValue);
                    const appEntity = { id, state };
                    return of(appEntity);
                })
            );
    }

    private getClearDefaultValue(commandConfig: CommandConfig): Promise<ClearFieldSettings> {
        const result = {
            appName: commandConfig.parameters[0],
            fieldName: commandConfig.parameters[1],
            fieldValue: null
        };
        const appComponent = this.domComponentRetrievalService.getAppComponent(result.appName);
        result.fieldValue = this.getModelDefaultValueOrDefault(appComponent, result.fieldName, result.fieldValue);
        result.fieldValue = _.isString(result.fieldValue) && _.isEmpty(result.fieldValue) ? null : result.fieldValue;

        if (_.isNil(result.fieldValue))
            return Promise.resolve(result);

        return this.dynamicReplacementService.getDynamicValue(result.fieldValue, result)
            .then((modelValue) => {
                result.fieldValue = modelValue;
                return result;
            });
    }

    // TODO: when audio record component is upgraded. Used this event to resetAudioState
    private resetAppAudioRecordingFields(settings: ClearFieldSettings): void {
        if (!this.applicationInformation.isInputApp(settings.appName)) return;
        const event = this.createAppEntity(AppEvent.ResetAudioRecordState, { ...settings });
        this.eventsService.publishEvent(event);
    }

}
