import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { from, throwError } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { AppEvent } from '../state/app-events.enum';
import { ApplicationInformation } from '../services/application-information.service';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from './command-list-collection.service';
import { DataService } from '../services/data.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { EventsService } from '../services/events.service';

type ResetAllFieldsConfig = {
    appName: string,
    validationGroup: string,
};

@Injectable()
export class ResetAllFieldsToPristineCommand extends BaseEffectCommand {

    constructor(protected actions$: Actions,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        private eventsService: EventsService,
        private applicationInformation: ApplicationInformation,
        private dataService: DataService) {
        super();
    }

    effectCommandName$ = createEffect(
        () => this.actions$.pipe(
            ofType(CommandActions.resetFieldsPristineCommand),
            switchMap(action => {
                const commandConfig = this.getCommandConfig(action);
                const { appName, validationGroup } = this.getConfig(commandConfig);
                if (!this.applicationInformation.isV4App(appName)) {
                    return throwError(`Unknown '${appName}' application.`);
                }
                return from(this.dataService.resetDirtyFieldsToPristine(appName))
                    .pipe(
                        delay(50),// This app could be rendered in a popup, so we need to delay to await its arrival on the DOM
                        switchMap(() => {
                            this.resetAppFieldMultiUploadFields(appName);
                            this.refreshValidationGroupButtons(appName, validationGroup);
                            return this.getNextActions(commandConfig);
                        })
                    )
            })
        )
    );

    private refreshValidationGroupButtons(appName: string, validationGroup: string): void {
        this.publishEvent(AppEvent.RefreshValidationGroupButtons, { appName, validationGroup });
    }

    private resetAppFieldMultiUploadFields(appName: string): void {
        if (!this.applicationInformation.isInputApp(appName)) return;
        this.publishEvent(AppEvent.ResetFileUploadMulti, { appName });
    }

    private publishEvent(eventName: string, eventState: any): void {
        const event = this.createAppEntity(eventName, eventState);
        this.eventsService.publishEvent(event);
    }

    private getConfig(commandConfig: CommandConfig): ResetAllFieldsConfig {
        return {
            appName: commandConfig.parameters[0],
            validationGroup: commandConfig.parameters[1],
        };
    }

}
