import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FieldsInAppEntity, ListDataEntity } from './apps.models';
import { APPS_FEATURE_KEY, State, AppsPartialState } from './apps.reducer';

interface ByAppInfo {
  appName?: string;
  fieldName?: string;
}

// Lookup the 'Apps' feature state managed by NgRx
export const getAppsState = createFeatureSelector<AppsPartialState, State>(APPS_FEATURE_KEY);

export const getCurrentAppsState = createSelector(
  getAppsState,
  (state: State) => state.apps
);

//#region get constants

export const getCurrentStep = createSelector(
  getAppsState,
  (state: State) => state.step
);

export const getCurrentWorkflow = createSelector(
  getAppsState,
  (state: State) => state.workflow
);

export const getCurrentBreakPoint = createSelector(
  getAppsState,
  (state: State) => state.breakPoint
);

export const getCurrentContextName = createSelector(
  getAppsState,
  (state: State) => state.contextName
);

//#endregion

export const getCurrentAppState = createSelector(
  getAppsState,
  (state: State, props: ByAppInfo): AppState => {
    let appState = {};
    if (props?.appName) {
      const appEntity = state.apps.entities[props.appName];
      appState = appEntity?.state || {};
    }
    return appState;
  }
);

export const getCurrentListDataSource = createSelector(
  getAppsState,
  (state: State, props: ByAppInfo): ListDataEntity => {
    let listDataEntity = {
      id: props?.appName,
      data: [],
      requestId: null
    };
    if (props?.appName) {
      const foundDataEntity = state.listData.entities[props.appName];
      if (foundDataEntity)
        listDataEntity = foundDataEntity;
    }
    return listDataEntity;
  }
);

export const getCurrentListFilterSource = createSelector(
  getAppsState,
  (state: State, props: ByAppInfo): DataSourceFilterDetails => {
    let filterDetails: DataSourceFilterDetails;
    if (props?.appName) {
      const foundFilter = state.listFilter.entities[props.appName];
      if (foundFilter)
        filterDetails = foundFilter.listFilterDetails;
    }
    return filterDetails;
  }
);

export const getAppIsOnEventOnly = createSelector(
  getAppsState,
  (state: State, props: ByAppInfo) => {
    if (props?.appName) {
      const app = state.appsInHolder.entities[props.appName];
      return app?.onEventOnly;
    }
    return false;
  }
);

export const getFieldsInAppState = createSelector(
  getAppsState,
  (state: State, props: ByAppInfo) => {
    if (props?.appName) {
      return state.fieldsInApp.entities[props.appName];
    }
  }
);

export const getDirtyFields = createSelector(
  getFieldsInAppState,
  (state: FieldsInAppEntity) => state?.dirtyFields || []
);

export const getPristineFieldValue = createSelector(
  getFieldsInAppState,
  (state: FieldsInAppEntity, props: ByAppInfo) => {
    if (props.fieldName) {
      if (!state?.id) return null;
      const pristineRecord = state.pristineFieldValues.find(pv => pv[props.fieldName]);
      return pristineRecord ? pristineRecord[props.fieldName] : null;
    }
  }
);

export const getIsAppStateDirty = createSelector(
  getAppsState,
  (state: State, props: ByAppInfo) => {
    if (props?.appName) {
      return state.apps.entities[props.appName]?.isDirty;
    }
  }
);

export const getIsPlatformMobile = createSelector(
  getAppsState,
  state => state.isPlatformMobile
);
