import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from "./command-list-collection.service";
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { DefaultFieldMapper } from '../services/field-mappers/default-field.mapper';
import { ThemeService } from '../services/theme.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { PersonalizationService } from '../services/personalization.service';

@Injectable()
export class SelectItemsInListCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private themeService: ThemeService,
        private personalizationService: PersonalizationService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.selectItemsInListCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.selectItemsInListCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                return this.selectItemsInList(appsStore, commandConfig)
                    .pipe(
                        switchMap(() => {
                            const appsState = this.getAppsState(appsStore);
                            const fieldMapper = new DefaultFieldMapper(appsState, this, this.themeService, this.cacheManagerService, this.personalizationService);
                            fieldMapper.execute(commandConfig, null, false);
                            const commandState = Object.values(fieldMapper.State);
                            const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                            return this.appendNextActions(thisCommandActions, commandConfig);
                        })
                    )
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );


    selectItemsInList(appsStore: any, commandConfig: CommandConfig): Observable<any> {

        const fieldMap = this.getFieldMap(commandConfig);
        const sourceFieldName = fieldMap.sourceFields[0];
        const targetFieldName = fieldMap.targetFields[0];
        const sourceAppName = this.isFieldAppReferenced(sourceFieldName) ? this.getAppReferenced(sourceFieldName).appName : fieldMap.sourceApp;
        const targetAppName = this.isFieldAppReferenced(targetFieldName) ? this.getAppReferenced(targetFieldName).appName : fieldMap.targetApp;
        const sourceField = this.isFieldAppReferenced(sourceFieldName) ? this.getAppReferenced(sourceFieldName).fieldName : sourceFieldName;
        const targetField = this.isFieldAppReferenced(targetFieldName) ? this.getAppReferenced(targetFieldName).fieldName : targetFieldName;

        if (!this.applicationInformation.isListApp(targetAppName))
            return throwError("Target application is not a list application '" + targetAppName + "'");

        const appState = this.getAppState(sourceAppName, appsStore);
        const fieldValue = appState[sourceField] as string;
        const sourceItems = this.getSourceItems(sourceField, fieldValue);
        if (sourceItems.length == 0) return of(true);

        return from(this.setListItemsHandler(targetAppName, sourceItems, sourceField, targetField));
    }

    private getSourceItems(sourceField: string, fieldValue: string): any[] {
        if (_.isNil(fieldValue)) return [];
        const sourceItems = [];
        // support | and , as separators
        const sep = fieldValue.indexOf('|') != -1 ? '|' : ',';
        const fieldValues = fieldValue.split(sep);
        for (let i = 0; i < fieldValues.length; i++) {
            if (fieldValues[i] === "") continue;
            const item = {};
            item[sourceField] = fieldValues[i];
            sourceItems.push(item);
        }
        return sourceItems;
    }

    private setListItemsHandler(targetListApp: string, sourceItems: any[], sourceField: string, targetField: string): Promise<boolean> {

        const defaultPromise = Promise.resolve(true);

        const gridInstance = this.domComponentRetrievalService.getDxComponentInstance({}, targetListApp);
        if (_.isNil(gridInstance)) return defaultPromise;

        gridInstance.clearSelection();
        gridInstance.pageIndex(0);

        return this.waitForDataSource(gridInstance)
            .then((dataSourceKey: string) => {
                const targetItems = gridInstance.getAllItems();
                const selectedRows = $.grep(targetItems, (item) => {
                    const value = item[targetField];
                    const exists = _.isObject(_.find(sourceItems, [sourceField, value]))
                        || _.isObject(_.find(sourceItems, [sourceField, value.toString()]));
                    return exists;
                });
                if (_.isEmpty(dataSourceKey)) {
                    gridInstance.selectRows(selectedRows, false);
                } else {
                    const selectedRowKeys = selectedRows.map((row) => row[dataSourceKey]);
                    gridInstance.selectRows(selectedRowKeys, false);
                }
                return true;
            });
    }

    private waitForDataSource(gridInstance: any): Promise<string> {
        const dataSource = gridInstance.option("dataSource");
        const dataSourceKey = dataSource.key();
        if (dataSource.isLoading()) {
            return new Promise((resolve) => {
                const _onChanged = () => {
                    dataSource.off("changed", _onChanged);
                    resolve(dataSourceKey);
                };
                dataSource.on("changed", _onChanged);
            });
        }
        return Promise.resolve(dataSourceKey);
    }
}
