import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        const translationId = params.key;
        const split = translationId.split('.');
        let property;
        let fieldName;
        if (split.length > 2) {
            property = split[split.length - 1];
            fieldName = split[split.length - 3];
        }
        switch (true) {
            case property === 'placeholder':
                // Empty string placeholders are stripped during generation, so assume a missing one is empty string
                return ' ';
            case fieldName === 'fields' && (property === 'ariaLabel' || property === 'ixLabel'):
                // ariaLabel is stripped from JSON if matches fieldName so default to fieldname
                return ' ';
            case translationId.contains('.htmls.noDataToDisplayText'):
                // don't interfere with translateOrDefault; the default in this case comes from DevExtreme
                return undefined;
            case translationId.contains('.htmls.loadingText'):
                return 'Loading...';
            case translationId.contains('.htmls.'):
                return '&nbsp;';
            case translationId.contains('.labels.l'):
                return ' ';
            default:
                // Not included in original JSON or deliberate fallback.
                return translationId;
        }
    }
}
