import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

// This basically just wraps icMenuDefault and sets the isSecondary binding to true.
// A separate component is also needed because component file paths are used as keys
// in code that only allows one of each component.
@Component({
  selector: 'ic-menu-secondary',
  templateUrl: './menu-secondary.component.html',
  styleUrls: ['./menu-secondary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSecondaryComponent implements OnInit {
  @Input()
  public config: any;

  @Input()
  public items: any[];

  @Input()
  public menu: any;

  @Input()
  public isSecondary: boolean;

  @Input()
  public context: any;

  @Input()
  public cssClass: string;

  @Input()
  public parentAppName: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
