<div role="radiogroup"
    [ngClass] = "getRadioGroupCssClasses()"
    [class.dx-state-disabled] = "isDisabled"
    [attr.aria-label]="config.ixLabel"
    [attr.aria-invalid]="isRequired && !isChecked() ? 'true' : 'false'"
    [attr.aria-required]="isRequired">
    <div class="dx-collection">
            <div *ngFor="let item of items"
                role="radio"    
                            
                (click)="onItemClick(item)"
                (keydown)="onKeyDown($event)"
                (keydown.enter)="onItemClick(item)"
                (keydown.space)="onItemClick(item)"

                [ngClass] = "item.cssClass"
                [class.dx-radiobutton-icon-checked] = "item.selected"
                [class.dx-state-disabled] = "!item.enabled"
                [class.correct-ans-choice] = "config.answerReviewed && item.isCorrectAns"
                [class.incorrect-ans-choice] = "item.hasBeenSelected && !item.isCorrectAns"
                          
                [attr.id]="item.elementId"
                [attr.tabindex]="item.enabled && !parentisDisabled ? '0' : ''" 
                
                [attr.aria-disabled]="item.enabled && (!parentisDisabled) ? 'false' : 'true'"
                [attr.aria-checked]="item.selected ? 'true' : 'false'"
                [attr.aria-setsize]="item.setSize"
                [attr.aria-posinset]="item.setPosition"
                >
                <div class="dx-radio-value-container">
                    <div class="dx-radiobutton-icon">
                        <div class="dx-radiobutton-icon-dot"></div>
                    </div>
                </div>
                <div class="dx-item-content ng-binding">
                    <span class="item-content-icon" *ngIf="config.iconExpr">{{ item.icon }}</span>
                    {{ item.text }}
                    <div class="wrong-ans-supplement" *ngIf="showWrongSelectionSupplement(item)" 
                        [innerHTML]="config.wrongSelectionSupplement">
                    </div>
                </div>

                <dx-tooltip *ngIf="item.tooltip" (onInitialized)="item.tooltip.onInitialized($event)">
                    <div *dxTemplate="let data = data of 'content'" class="radio-buttongroup-disabledtooltip"
                        [innerHTML]="this.config.disabledText"></div>
                </dx-tooltip>
            </div>
    </div>
</div>