import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { AsyncSubject, from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { ListApplication } from '../components/list-application';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class ListEditingChangesCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.discardListEditingChangesCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    this.discardListEditingChanges(commandConfig);
                    return this.getNextActions(commandConfig);
                })
            )
    );

    saveListEditingChangesCommand$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.saveListEditingChangesCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    return from(this.saveListEditingChanges(commandConfig))
                      .pipe(
                        switchMap(() => {
                          return this.appendNextActions([], commandConfig)
                        })
                      );
                })
            )
    );

    saveListEditingChanges(commandConfig: CommandConfig): Observable<any> {

        const appName = _.first(commandConfig.parameters);
        const applet = { name: appName };

        const isValidAppType = this.applicationInformation.isV4App(appName)
            && this.applicationInformation.isListApp(appName)
            && !this.applicationInformation.isPivotedList(appName);
        if (!isValidAppType)
            return of(false);

        const instance = this.domComponentRetrievalService.getDxComponentInstance(applet, appName);

        // gets the component for the application executing the command
        const scope = this.domComponentRetrievalService.getAppComponent(commandConfig.appName) as ListApplication;
        //checking if the list rows are draggable and if any items are re-arranged
        if (scope?.rowReorderSequenceField && scope?.reorderedItems?.length > 0) {
            _.each(scope.reorderedItems, (item) => instance.getDataSource()
                .store()
                .update(item, {})
                .then(() => scope.reorderedItems = []) // This would be reset on the first loop?
            );
        }

        if (!instance?.hasEditData())
            return of(false);

        const editController = instance.getController('editing');
        //to pass the value of the server call type to the custom data source
        //based on the user parameters
        DevExpress.data.CustomStore = DevExpress.data.CustomStore.redefine({
            options: {
                "serverCallType": commandConfig.parameters[1]
            }
        });
        const isEditFormValid = _.every(editController._editData, (item) => item.isValid);
        if (isEditFormValid) {
            const deferredToObservable$ = new AsyncSubject();
            const saveDeferred = instance.saveEditData();
            saveDeferred.always(() => {
              this.setupReappearsObserver(instance);
              deferredToObservable$.next(null);
              deferredToObservable$.complete();
            });
            return deferredToObservable$;
        }

        this.setupReappearsObserver(instance);
        return throwError('Form validation failed for list ' + commandConfig.appName);
    }

    discardListEditingChanges(commandConfig: CommandConfig): void {
        const appName = _.first(commandConfig.parameters);
        const isValidAppType = this.applicationInformation.isV4App(appName)
            && this.applicationInformation.isListApp(appName)
            && !this.applicationInformation.isPivotedList(appName);
        if (!isValidAppType)
            return;
        const applet = { name: appName };
        const instance = this.domComponentRetrievalService.getDxComponentInstance(applet, appName);
        if (!instance?.hasEditData())
            return;
        instance.cancelEditData();
        this.setupReappearsObserver(instance);
    }

    private setupReappearsObserver(dxDataGridInstance) {
        const target = dxDataGridInstance.element().get(0);
        const options = {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ['style']
        };
        const observer = new MutationObserver((mutations, observer) => {
            let shouldResize = false;
            mutations.forEach((mutation) => {
                const element = mutation.target as any;
                const oldValue = mutation.oldValue;
                if ((!oldValue || oldValue.contains("display: none;"))
                    && element?.style?.display === "block") {
                    shouldResize = true;
                }
            });
            if (shouldResize)
                dxDataGridInstance?.resize();
            observer.disconnect();
        });
        observer.observe(target, options);
    }
}
