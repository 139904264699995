/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import * as CommandActions from '../state/command.actions';
import { AppsConstantsFacade } from '../services/apps-constants.facade';
import { WorkflowConfigurationService } from '../services/workflow-configuration.service';
import { CommandListFactory } from './command-list-factory.service';
import { CacheManagerService } from '../services/cachemanager.service';

interface MutuallyExclusiveConfig {
    mutuallyExclusiveSettings: any;
    targetApp: any;
    targetAppName: string;
    forceSetValue: boolean;
}

interface CommandConfigSettings {
    e: any;
    rid: string;
    contextMutuallyExclusiveConfig?: MutuallyExclusiveConfig;
}

interface CommandListInfo {
    commandList: any;
    appThatOverrides: any;
    commandsConfig: CommandConfigSettings;
}

@Injectable()
export class CommandListCollectionService {

    private currentStepName: string;
    private allCommandList: Record<string, CommandListInfo>;
    private overrideCommandList: Record<string, CommandListInfo>;
    private contextMutuallyExclusiveConfig: MutuallyExclusiveConfig;

    constructor(private appsConstantsFacade: AppsConstantsFacade,
        private workflowConfigurationService: WorkflowConfigurationService,
        private commandListFactory: CommandListFactory,
        private cacheManagerService: CacheManagerService) {
        this.allCommandList = {};
        this.overrideCommandList = {};
        this.contextMutuallyExclusiveConfig = {
            mutuallyExclusiveSettings: null,
            targetAppName: '',
            targetApp: null,
            forceSetValue: true
        };
        this.appsConstantsFacade.step$.subscribe(stepName => this.currentStepName = stepName);
        this.appsConstantsFacade.configuration$
            .pipe(
                filter(action => action?.contains("reload-service-configuration")),
                tap(() => this.reloadConfiguration())
            )
            .subscribe();
    }

    getCommandList(fnName: string, appName: string): CommandListInfo {
        const stepFnName = fnName + "|" + this.currentStepName;
        appName = appName || "_";
        const themeFnName = fnName + "|" + appName;
        let commandList;
        if (!_.isNil(this.overrideCommandList[stepFnName])) {
            commandList = this.overrideCommandList[stepFnName];
            if (commandList.appThatOverrides === this.currentStepName) {
                commandList.commandsConfig.appThatOverrides = commandList.appThatOverrides;
            }
        } else if (!_.isNil(this.overrideCommandList[themeFnName])) {
            commandList = this.overrideCommandList[themeFnName];
            if (commandList.appThatOverrides === appName) {
                commandList.commandsConfig.appThatOverrides = commandList.appThatOverrides;
            }
        } else {
            commandList = this.allCommandList[fnName];
        }
        commandList = this.assignMutuallyExclusiveConfig(commandList);
        return commandList;
    }

    getMapKeys(): any {
        return Object.keys(this.allCommandList);
    }

    getNextCommand(commandConfig: CommandConfig, prevValue?: any): any {
        const cmdLst = this.getCommandList(commandConfig.commandListName, commandConfig.appName);
        if (_.isNil(cmdLst)) {
            const log = `Command list name '${commandConfig.commandListName}' not found`;
            this.cacheManagerService.rejectPromiseWith(commandConfig.commandListName + commandConfig.executionId, new Error(log));
            commandConfig.commandListName = log;
            this.log(commandConfig);
            return CommandActions.stop();
        }
        const commands = this.commandListFactory.createCommandList(cmdLst.commandList, cmdLst.commandsConfig);
        const nextIndex = commandConfig.currentIndex + 1;
        if (commands[nextIndex]) {
            const nextCommand: CommandConfig = { ...commands[nextIndex] };
            nextCommand.currentIndex = nextIndex;
            nextCommand.prevCmd = commandConfig.action;
            nextCommand.fieldMap = this.isFieldMap(prevValue) ? _.cloneDeep(prevValue) : null;
            nextCommand.rid = commandConfig.rid;
            nextCommand.options.e = commandConfig.options.e;
            nextCommand.executionId = commandConfig.executionId;
            nextCommand.commandListName = commandConfig.commandListName;
            this.log(nextCommand);
            const commandAction = nextCommand.command + 'Command';
            const nextActionCommand = CommandActions[commandAction];
            if (nextActionCommand)
                return nextActionCommand(nextCommand);
        }

        return this.resolveCommandListExecution(commandConfig.commandListName, commandConfig.executionId);
    }

    resolveCommandListExecution(commandListName: string, executionId: string): any {
        this.completeCommandList(commandListName, executionId);
        return CommandActions.stop();
    }

    completeCommandList(commandListName: string, executionId: string): void {
        const cacheKey = commandListName + executionId;
        const cachePolicy = { canBeCached: () => false };
        this.cacheManagerService.resolvePromiseWith(cacheKey, null, cachePolicy);
    }

    private isFieldMap(preValue: any): preValue is FieldMap {
        if ((preValue as FieldMap)) {
            return true;
        }
        return false;
    }

    private assignMutuallyExclusiveConfig(commandList: CommandListInfo): CommandListInfo {
        if (!_.isNil(this.contextMutuallyExclusiveConfig.mutuallyExclusiveSettings)
            && !_.isNil(commandList?.commandsConfig)) {
            commandList.commandsConfig.contextMutuallyExclusiveConfig = _.cloneDeep(this.contextMutuallyExclusiveConfig);
        }
        return commandList;
    }

    private reloadConfiguration() {
        const config = this.workflowConfigurationService.getCommandList();
        this.allCommandList = _.cloneDeep(config.commandList);
        this.overrideCommandList = _.cloneDeep(config.commandListOverrides);
        this.contextMutuallyExclusiveConfig.mutuallyExclusiveSettings = _.cloneDeep(config.mutuallyExclusiveSettings);
        this.contextMutuallyExclusiveConfig.targetAppName = config.contextName;
    }

    private log(commandConfig: CommandConfig): void {
        const debug = IX_DEBUG_SETTINGS.cmdLst.debug;
        const verbose = IX_DEBUG_SETTINGS.cmdLst.verbose;
        if (!debug || commandConfig.action == "DynamicReplacement") return;
        if (verbose) {
            // Serialize to string for chrome iOS debugger chrome://inspect
            // Remove when support for complex object debugging becomes available
            if (navigator.userAgent.match('CriOS')) {
                let cache = [];
                const output = JSON.stringify(commandConfig, (key, value) => {
                    if (_.isObject(value) && !_.isNil(value)) {
                        // Duplicate reference found, discard key
                        if (cache.indexOf(value) !== -1) return;
                        // Very large, ignore by default
                        if (key == "DevExpress") return;
                        // Store value in our collection
                        cache.push(value);
                    }
                    return value;
                }, 4);
                cache = null;
                console.log(output);
            } else {
                console.log(commandConfig);
            }
        }
        else {
            const { action, appName, commandListName, currentIndex } = commandConfig;
            console.log([action, appName, commandListName, currentIndex]);
        }
    }
}
