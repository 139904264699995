import { Injectable } from '@angular/core';
import { AppsFacade } from '../state/apps.facade';

// TODO
/* WORK IN PROGRESS PORTING */

@Injectable()
export class DragDropHelperService {

    constructor(private appsFacade: AppsFacade) { }

    inheritDragBehaviors($scope, messageKey) {

        const dragStartMethod = messageKey + '_onDragStart';
        const dragStopMethod = messageKey + '_onDragStop';

        const dragMessageKey = 'dragdrop:' + messageKey;

        $scope.onDragStart = function () {
            $scope.context._events.publish(dragMessageKey + ':enableDrop');
        };

        $scope.onDragStop = function () {

            // Give success a chance to fire, then disable right away.
            setTimeout(function () {
                $scope.context._events.publish(dragMessageKey + ':disableDrop');
            }, 0);
        };
    }

    private defer() {
        let resolve, reject;
        const promise = new Promise((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });
        return {
            resolve: resolve,
            reject: reject,
            promise: promise
        };
    }

    inheritDropBehaviors($scope, element, messageKey) {
        const dragMessageKey = 'dragdrop:' + messageKey;
        const childScope = $scope.$new();


        childScope.onDrop = (event, obj) => {
            const deferred = this.defer();
            const data = obj.draggable.draggable('option').dragData;

            try {
                this.handleDrop(childScope, data);
                deferred.resolve();
            } catch (e) {
                deferred.reject(e);
            }

            childScope.dropEnabled = false;

            return deferred.promise;
        };

        childScope.dropEnabled = false;

        // TODO  - convert this to component injected into app's html?
        const dropZone = ''; //$compile('<div ng-show="dropEnabled" data-drop="dropEnabled" jqyoui-droppable="{ onDrop: \'onDrop\' }" class="dragdrop-dropzone">')(childScope);
        const dropBackdrop = ''; //$compile('<div ng-show="dropEnabled" class="dragdrop-backdrop">')(childScope);

        //dropZone.append('<div class="dragdrop-dropzone-inner"></div>');

        element.css('position', 'relative');

        element.prepend(dropZone);
        element.prepend(dropBackdrop);

        $scope.context._events.$on(dragMessageKey + ':enableDrop', function () {
            childScope.$apply(function () {
                childScope.dropEnabled = true;
            });
        });

        $scope.context._events.$on(dragMessageKey + ':disableDrop', function () {
            childScope.$apply(function () {
                childScope.dropEnabled = false;
            });
        });
    }

    private handleDrop(childScope, dropData) {
        const droppedEvent = 'ApplicationEvent.DraggableItemDropped.Event';
        const eventData = {
            targetApp: childScope.applet.name,
            value: dropData
        };
        this.appsFacade.publishAppEvent(droppedEvent, eventData);
    }

}
