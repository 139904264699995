import { AppsEntity } from '../../state/apps.models';
import { BaseFieldMapper } from './base-field-mapper';
import { DefaultFieldMapper } from './default-field.mapper';
import { ApplicationInformation } from '../application-information.service';
import { DomComponentRetrievalService } from '../dom-component-retrieval.service';
import { EcdService } from '../clientservices/ecd.service';
import { TranslatableEffectCommand } from '../../commands/translatable-effect.command';
import { DynamicReplacementService } from '../dynamic-replacement.service';
import { ValidationEngineService } from '../validation-engine.service';
import { ModalService } from '../modal.service';
import { UtilService } from '../util.service';
import { ApiClientService } from '../api-client.service';
import { HelpersService } from '../helpers.service';
import { CacheManagerService } from '../cachemanager.service';
import { PersonalizationService } from '../personalization.service';
import { ThemeService } from "../theme.service";
import { Injectable } from '@angular/core';
import { DefaultApiMapper } from './default-api.mapper';
import { ApiFieldMapper } from './api-field.mapper';
import { BaseEffectCommand } from '../../commands/base-effect.command';

abstract class FieldMapperFactory {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public abstract create(appsState: Record<string, AppsEntity>, commandHelpers: BaseEffectCommand, commandConfig?: CommandConfig): BaseFieldMapper;
}

@Injectable()
export class DefaultFieldMapperFactory extends FieldMapperFactory {

    constructor(
        private themeService: ThemeService,
        protected cacheManagerService: CacheManagerService,
        protected personalizationService: PersonalizationService) {
        super();
    }

    public create(appsState: Record<string, AppsEntity>, commandHelpers: BaseEffectCommand): DefaultFieldMapper {
        return new DefaultFieldMapper(appsState,
            commandHelpers,
            this.themeService,
            this.cacheManagerService,
            this.personalizationService);
    }
}

@Injectable()
export class DefaultApiMapperFactory extends FieldMapperFactory {

    constructor(
        private utilService: UtilService,
        private ecdService: EcdService,
        private apiClientService: ApiClientService,
        protected cacheManagerService: CacheManagerService,
        protected personalizationService: PersonalizationService) {
        super();
    }

    public create(appsState: Record<string, AppsEntity>): DefaultApiMapper {
        return new DefaultApiMapper(appsState,
            this.utilService,
            this.ecdService,
            this.cacheManagerService,
            this.apiClientService,
            this.personalizationService);
    }
}

@Injectable()
export class ApiFieldMapperFactory extends FieldMapperFactory {

    constructor(
        private utilService: UtilService,
        private ecdService: EcdService,
        private apiClientService: ApiClientService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private dynamicReplacementService: DynamicReplacementService,
        private validationEngineService: ValidationEngineService,
        private modalService: ModalService,
        private helpersService: HelpersService,
        protected cacheManagerService: CacheManagerService,
        protected personalizationService: PersonalizationService,) {
        super();
    }

    public create(appsState: Record<string, AppsEntity>, commandHelpers: TranslatableEffectCommand, commandConfig: CommandConfig): ApiFieldMapper {
        return new ApiFieldMapper(appsState,
            this.utilService,
            this.ecdService,
            this.apiClientService,
            this.cacheManagerService,
            commandConfig,
            commandHelpers,
            this.applicationInformation,
            this.domComponentRetrievalService,
            this.dynamicReplacementService,
            this.validationEngineService,
            this.modalService,
            this.helpersService,
            this.personalizationService);
    }
}
