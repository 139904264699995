import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap, retry, delay, tap } from 'rxjs/operators';
import { BaseCommand } from './base-command';
import { AppEvent } from '../state/app-events.enum';
import { ListApplication } from '../components/list-application';
import { AppsFacade } from '../state/apps.facade';
import { ApplicationInformation } from '../services/application-information.service';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { DefaultFieldMapper } from '../services/field-mappers/default-field.mapper';
import { DataService } from '../services/data.service';
import { AppsEntity } from '../state/apps.models';

type Filters = (string | number | boolean)[][];

@Injectable()
export class ApplyFilterCommand extends BaseCommand {

    constructor(private appsFacade: AppsFacade,
        private applicationInformation: ApplicationInformation,
        private dynamicReplacementService: DynamicReplacementService,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private dataService: DataService) {
        super();
    }

    applyFilter(commandConfig: CommandConfig, fieldMapper: DefaultFieldMapper, fieldMap: FieldMap, appsState: Record<string, AppsEntity>): Observable<any> {

        if (_.isNil(fieldMap) || !this.applicationInformation.isV4App(fieldMap.targetApp)) {
            const errorMessage = "Applying filter to unknown app " + fieldMap?.targetApp;
            IX_Log("fieldMapper", errorMessage);
            return throwError(errorMessage);
        }

        const wasLastCommandOpenModalPopUp = (prevCmd) => !_.isNil(prevCmd) && "Open Modal Popup".EqualsIgnoreCase(prevCmd);
        const getTimeoutValue = (prevCmd) => wasLastCommandOpenModalPopUp(prevCmd) ? 50 : 0;
        
        // TODO: remove delay for popup commands
        const timeout = getTimeoutValue(commandConfig.prevCmd);
        return from([1])
            .pipe(
                delay(timeout),
                tap(() => {
                    let result = null;
                    const appComponent = this.domComponentRetrievalService.getAppComponent(commandConfig.appName);
                    if (this.applicationInformation.isComponent(fieldMap.targetApp)) {
                        result = this.applyFilterComponent(appComponent, commandConfig, fieldMap, fieldMapper, appsState);
                    } else if (this.doesCommandFieldHaveOwnDataSource(appComponent, fieldMap, commandConfig)) {
                        result = this.applyFilterFieldComponent(appComponent, commandConfig, fieldMap, fieldMapper, appsState);
                    } else if (this.applicationInformation.isListApp(fieldMap.targetApp)) {
                        result = this.applyFilterGrid(commandConfig, fieldMap, fieldMapper, appsState);
                    } else {
                        result = {
                            errorMessage: "Applying filter to unknown app type " + fieldMap.targetApp,
                            errorFlag: true
                        };
                    }

                    // Ensure OnEventOnly data loads get called if a filter has been applied
                    this.dataService.disableDisplayBehaviorOnEventOnlyAllAppInstances(fieldMap.targetApp);

                    const { filterPromise, errorFlag, component, filter } = result;
                    if (errorFlag)
                        return throwError(result.errorMessage);
                    if (this.applicationInformation.isListApp(fieldMap.targetApp) && component != null) {
                        const executeSync = this.shouldWaitForApplyFilterData(commandConfig.parameters);
                        if (executeSync)
                            return of(filter);
                        else
                            return from(filterPromise)
                                .pipe(
                                    switchMap(() => {
                                        if (_.get(component, '_controllers.keyboardNavigation.setupFocusedView'))
                                            component._controllers.keyboardNavigation.setupFocusedView();
                                        if (result.autoSelectAllRows)
                                            component.selectAll();
                                        const wasCommandOpenModalPopUp = wasLastCommandOpenModalPopUp(commandConfig.prevCmd);
                                        if (!wasCommandOpenModalPopUp)
                                            return;
                                        const $element = $(component.$element());
                                        $element.find('table tr.dx-data-row:first td:first').click().focus();
                                        return of(filter);
                                    })
                                );
                    }
                    return of(filter);
                }),
                retry(5)
            );
    }

    doesCommandFieldHaveOwnDataSource(appComponent, fieldMap, config) {
        if (this.applicationInformation.isListApp(fieldMap.targetApp)) {
            const componentName = this.getComponentName(fieldMap.targetApp);
            if (!$("#" + componentName).length) {
                const dataSourceName = this.getTargetAppDataSource(config);
                return !_.isNil(appComponent?.dataSources[dataSourceName]);
            }
        }
        return false;
    }

    private applyFilterComponent(appComponent: any, commandConfig: CommandConfig,
        fieldMap: FieldMap, fieldMapper: DefaultFieldMapper, appsState: Record<string, AppsEntity>): Record<string, string | Filters | boolean | Promise<unknown>> {
        const errorMessage = "Applying filter to non initialized component app " + fieldMap.targetApp;
        let errorFlag = true;
        let filterPromise: Promise<unknown> = null;
        const dataSources = appComponent.context._dataSources;
        const componentName = this.getComponentName(fieldMap.targetApp);
        const filter = this.getFilterListConfig(fieldMapper, fieldMap, appsState);
        const filterOnCompleteButtonName = commandConfig.parameters.length >= 2 ? commandConfig.parameters[2] : "";
        this.appsFacade.setAppFilter(fieldMap.targetApp, { filters: filter, filterOnCompleteButtonName });

        if (dataSources && !_.isNil(dataSources[componentName])) {
            // Binds on the data source to fire the ButtonOnComplete instructions.
            this.bindOnFilterCompleteEvent(appComponent, filterOnCompleteButtonName, dataSources[componentName]);
            dataSources[componentName].filter(filter);
            filterPromise = dataSources[componentName].reload();
            errorFlag = false;
          } else if (appComponent.isCatapultComponent) {
            // Without this, the list call is only fired once (on component initialization)
            filterPromise = appComponent.activeDataRequest.promise.promise();
            appComponent.loadData();
            errorFlag = false;
        }
        return { filterPromise, errorMessage, errorFlag, filter };
    }

    private applyFilterFieldComponent(appComponent: any, commandConfig: CommandConfig,
        fieldMap: FieldMap, fieldMapper: DefaultFieldMapper, appsState: Record<string, AppsEntity>): Record<string, string | Filters | boolean | Promise<unknown>> {
        const errorMessage = "Applying filter to non initialized dropdown app " + fieldMap.targetApp;
        let errorFlag = true;
        let filterPromise: Promise<unknown> = null;

        const addFilterType = false;
        const filter = this.getFilterListConfig(fieldMapper, fieldMap, appsState, addFilterType);

        const filterOnCompleteButtonName = commandConfig.parameters.length >= 2 ? commandConfig.parameters[2] : "";
        this.appsFacade.setAppFilter(fieldMap.targetApp, { filters: filter, filterOnCompleteButtonName });
        const componentName = this.getTargetAppDataSource(commandConfig);
        if (appComponent.dataSources[componentName]) {
            this.bindOnFilterCompleteEvent(appComponent, filterOnCompleteButtonName, appComponent.dataSources[componentName]);
            const forceServerSideFiltering = (appComponent.dataSources[componentName].ic || {}).forceServerSideFiltering === true;
            if (!forceServerSideFiltering)
                appComponent.dataSources[componentName].filter(filter);
            filterPromise = appComponent.dataSources[componentName].reload();
            errorFlag = false;
        }
        return { filterPromise, errorMessage, errorFlag, filter };
    }

    private applyFilterGrid(commandConfig: CommandConfig, fieldMap: FieldMap, fieldMapper: DefaultFieldMapper, appsState: Record<string, AppsEntity>): any {

        let errorFlag = false;
        let errorMessage = "";
        let filterPromise = Promise.resolve();
        const autoSelectAllRows = this.isTruthy(commandConfig.parameters[1]);

        const targetComponent = this.domComponentRetrievalService.getAppComponent(fieldMap.targetApp) as ListApplication;

        const filters = this.getFilterListConfig(fieldMapper, fieldMap, appsState);

         // Set filter in store
        const triggerLoadingShimmer = this.isTruthy(commandConfig.parameters[3]);
        const filterOnCompleteButtonName = commandConfig.parameters.length >= 2 ? commandConfig.parameters[2] : "";
        const filterOnCompleteAppName = commandConfig.appName;
        const filterDetails = {
            filters,
            filterOnCompleteAppName,
            filterOnCompleteButtonName,
            triggerLoadingShimmer
        };
        this.appsFacade.setAppFilter(fieldMap.targetApp, filterDetails);


        //We can get here before DataGrid.onInitialized, and throw.
        //TODO: Investigate
        if(_.isNil(targetComponent?.gridInstance)) {
            errorFlag = true;
            errorMessage = "Applying filter to non initialized grid app " + fieldMap.targetApp;
            return { component: null, filterPromise, errorMessage, errorFlag, autoSelectAllRows, filter: [] };
        }
        const gridComponent = targetComponent.gridInstance;
        filterPromise = gridComponent.refresh();
        this.bindOnFilterCompleteEvent(targetComponent, filterOnCompleteButtonName, gridComponent.getDataSource(), filterOnCompleteAppName);

        return { component: gridComponent, filterPromise, errorMessage, errorFlag, autoSelectAllRows, filter: filters };
    }

    private getFilterListConfig(fieldMapper: DefaultFieldMapper, fieldMap: FieldMap, appsState: Record<string, AppsEntity>, addFilterType = true): Filters {
        const filters: (string | number | boolean)[][] = [];
        for (let fieldIndex = 0; fieldIndex < fieldMap.sourceFields.length; fieldIndex++) {

            const fieldMapInfo = this.getFieldMapInfo(fieldMap, fieldIndex);
            const appName: string = fieldMapInfo.source.appName;

            let filterValue = fieldMapper.getAppFieldValue(appName, fieldMapInfo.source.fieldName);
            //to avoid creating a filter if the filterValue (value to be filtered) is not set (AngularJS code just checks for object - mainitaing this for backwards compat)
            if (!filterValue)
                continue;

            //to check if the current value from the source mapping app has dynamic value
            //and evaluate the field value for the dynamic string if true
            if (this.dynamicReplacementService.hasReplacementValue(filterValue)) {
              filterValue = this.dynamicReplacementService.getDynamicFieldValue(appsState[fieldMap.sourceApp]?.state || {}, filterValue.toString());
            }

            const filterName = fieldMapInfo.target.fieldName;
            const filter: (string | number | boolean)[] = [filterName];
            if (addFilterType) filter.push('contains');
            filter.push(filterValue);
            filters.push(filter);
        }
        return filters;
    }

    // If any button field name is passed to the ButtonOnComplete parameter
    // the following fires the button instructions once the loading is done.
    private bindOnFilterCompleteEvent(appComponent: any, onCompleteButtonName: string, dataSource: any, appName?: string): void {
        if (_.isNil(dataSource) || _.isEmpty(onCompleteButtonName))
            return;
        const onCompleteButtonHandler = () => {
            dataSource.off("changed", onCompleteButtonHandler);
            dataSource.off("loaded", onCompleteButtonHandler);
            const eventState = { appName: appName || appComponent.applet.name, buttonName: onCompleteButtonName };
            this.appsFacade.publishAppEvent(AppEvent.ExecuteButtonClick, eventState);
        };
        dataSource.on("changed", onCompleteButtonHandler);
        dataSource.on("loaded", onCompleteButtonHandler);
    }

}
