import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ic-tree-list-total-row',
  templateUrl: './tree-list-total-row.component.html',
  styleUrls: ['./tree-list-total-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeListTotalRowComponent implements OnInit {

  @Input()
  gridProperties: any;

  @Input()
  sumValues: any;

  constructor() { }

  ngOnInit(): void {
  }

}
