import { AppsEffects } from './state/apps.effects';
import { LoginCommand } from './commands/login.command';
import { LogoutCommand } from './commands/logout.command';
import { RedirectCommand } from './commands/redirect.command';
import { SetClassCommand } from './commands/set-class.command';
import { SetFocusCommand } from './commands/set-focus.command';
import { ApiMapperCommand } from './commands/api-mapper.command';
import { SetValuesCommand } from './commands/set-values.command';
import { OpenWindowCommand } from './commands/open-window.command';
import { PrintToPDFCommand } from './commands/print-to-pdf.command';
import { ModalPopUpCommand } from './commands/modal-pop-up-command';
import { ClearFilterCommand } from './commands/clear-filter.command';
import { ActivateTabCommand } from './commands/activate-tab.command';
import { RefreshListCommand } from './commands/refresh-list.command';
import { ApplyFilterCommand } from "./commands/apply-filter.command";
import { ValidateFormCommand } from './commands/validate-form.command';
import { AnnounceTextCommand } from './commands/announce-text.command';
import { EventPublishCommand } from './commands/event-publish.command';
import { EnableDisableCommand } from './commands/enable-disable.command';
import { EnableDisableGroupBlockingCommand } from './commands/enable-disable-group-blocking.command';
import { GenericEffectsCommand } from './commands/generic-effects.command';
import { TriggerWebHookCommand } from './commands/trigger-web-hook.command';
import { EventSetValuesCommand } from './commands/event-set-values.command';
import { ClearFieldValueCommand } from './commands/clear-field-value.command';
import { StopExecutionIfCommand } from './commands/stop-execution-if.command';
import { OpenCloseGroupsCommand } from './commands/open-close-groups.command';
import { ClearStepEventsCommand } from './commands/clear-step-events.command';
import { ClearFormValuesCommand } from './commands/clear-form-values.command';
import { RefreshComponentCommand } from './commands/refresh-component.command';
import { ApplyFilterEffectCommand } from './commands/apply-filter-effect.command';
import { CustomBrowserCodeCommand } from './commands/custom-browser-code.command';
import { SelectItemsInListCommand } from './commands/select-items-in-list.command';
import { ExportFromListAppCommand } from './commands/export-from-list-app.command';
import { ListEditingChangesCommand } from './commands/list-editing-changes.command';
import { GoogleTagDataLayerCommand } from './commands/google-tag-data-layer.command';
import { SavePersonalizeMenuCommand } from './commands/save-personalize-menu.command';
import { TriggerSpecialEffectCommand } from './commands/trigger-special-effect.command';
import { ToggleSideNavigationCommand } from './commands/toggle-side-navigation.command';
import { ClearLocalDataSourceCommand } from './commands/clear-local-data-source.command';
import { SetFirstDropdownItemCommand } from './commands/set-first-dropdown-item.command';
import { RunDynamicReplacementCommand } from './commands/run-dynamic-replacement.command';
import { SetListSelectedValuesCommand } from './commands/set-list-selected-values.command';
import { SetCookieConsentStateCommand } from './commands/set-cookie-consent-state.command';
import { ApplyClientSideFilterCommand } from './commands/apply-client-side-filter.command';
import { RedirectToExternalUrlCommand } from './commands/redirect-to-external-url.command';
import { ClearClientServicesCacheCommand } from './commands/clear-client-services-cache.command';
import { DatagridPaginationButtonsCommand } from './commands/datagrid-pagination-buttons.command';
import { ClearAllFileMultiUploadFieldsCommand } from './commands/clear-all-file-multi-upload-fields.command';
import { ResetAllFieldsToPristineCommand } from './commands/reset-all-fields-to-pristine.command';
import { SetValuesForColumnInListCommand } from './commands/set-values-for-column-in-list.command';
import { TabToActivateConditionallyCommand } from './commands/tab-to-activate-conditionally.command';
import { ConditionalRedirectResolverCommand } from './commands/conditional-redirect-resolver.command';
import { UpdatePersonalizationGroupItemCommand } from './commands/update-personalization-group-item.command';
import { MapPersonalizationGroupItemToFieldCommand } from './commands/map-personalization-group-item-to-field.command';
import { RetrievePersistedReportInZipMapperCommand } from './commands/retrieve-persisted-report-in-zip-mapper.command';
import { TransferUserAgentMetricsInformationCommand } from './commands/transfer-user-agent-metrics-information.command';

export const ProjectEffects = [
    AppsEffects,
    LoginCommand,
    LogoutCommand,
    RedirectCommand,
    SetClassCommand,
    SetFocusCommand,
    ApiMapperCommand,
    SetValuesCommand,
    ModalPopUpCommand,
    PrintToPDFCommand,
    OpenWindowCommand,
    ClearFilterCommand,
    RefreshListCommand,
    ApplyFilterCommand,
    ActivateTabCommand,
    ValidateFormCommand,
    EventPublishCommand,
    AnnounceTextCommand,
    EnableDisableCommand,
    EnableDisableGroupBlockingCommand,
    GenericEffectsCommand,
    EventSetValuesCommand,
    TriggerWebHookCommand,
    ClearStepEventsCommand,
    ClearFormValuesCommand,
    StopExecutionIfCommand,
    OpenCloseGroupsCommand,
    ClearFieldValueCommand,
    RefreshComponentCommand,
    ApplyFilterEffectCommand,
    ExportFromListAppCommand,
    CustomBrowserCodeCommand,
    SelectItemsInListCommand,
    ListEditingChangesCommand,
    GoogleTagDataLayerCommand,
    SavePersonalizeMenuCommand,
    TriggerSpecialEffectCommand,
    ToggleSideNavigationCommand,
    SetFirstDropdownItemCommand,
    ClearLocalDataSourceCommand,
    SetListSelectedValuesCommand,
    RunDynamicReplacementCommand,
    SetCookieConsentStateCommand,
    ApplyClientSideFilterCommand,
    RedirectToExternalUrlCommand,
    ResetAllFieldsToPristineCommand,
    SetValuesForColumnInListCommand,
    ClearClientServicesCacheCommand,
    DatagridPaginationButtonsCommand,
    ClearAllFileMultiUploadFieldsCommand,
    TabToActivateConditionallyCommand,
    ConditionalRedirectResolverCommand,
    UpdatePersonalizationGroupItemCommand,
    MapPersonalizationGroupItemToFieldCommand,
    RetrievePersistedReportInZipMapperCommand,
    TransferUserAgentMetricsInformationCommand,
];
