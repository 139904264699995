import { TranslateFacadeService } from "../services/translate-facade.service";
import { BaseEffectCommand } from "./base-effect.command";

export abstract class TranslatableEffectCommand extends BaseEffectCommand {

    protected abstract translateFacade: TranslateFacadeService;

    onFailApiCall(errorResponse: any): string {
        return this.getMainErrorOrDefault(errorResponse);
    }

    getMainErrorOrDefault(errorResponse: any): string {
        return errorResponse?.MainMessage || this.getTranslation("RETRY-OR-CONTACT-SUPPORT");
    }

    getTranslation(message: string, defaultTranslation?: string): string {
        return this.translateFacade.getTranslation(message, defaultTranslation);
    }

}
