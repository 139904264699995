import Big from 'big.js';
import _ from 'lodash';
import { RowSet } from './rowset';
import { ArrayHelpers } from './array-helpers';

export class GenericCalculator {
  _options: any;
  _debugMode = false;

  constructor(options, debugMode?: boolean) {
    this._options = this._buildOptions(options);
    this._debugMode = debugMode;
  }

  applyAdvancedFilter(dataSet, advancedFilter) {
    const result = [dataSet.headerRow()];

    dataSet.forEachRow(function (rowSet) {
      if (rowSet.isAdvancedFilteredRow(advancedFilter)) {
        result.push(rowSet.unwrap());
      }
    });

    dataSet.setData(result);
  }

  private _buildOptions(options) {
    options = options || {};
    options.dataSet = options.dataSet || [];
    options.filterBy = options.filterBy || {};
    options.groupBy = options.groupBy || [];
    options.sortBy = options.sortBy || [];
    options.select = options.select || [];
    options.rollups = options.rollups || {};
    options.postProcess = options.postProcess || {};
    options.tenantSettings = options.tenantSettings || {};
    return options;
  }

  private _isLastItem(items, index) {
    return index === items.length - 1;
  }

  private _shouldDefaultToZero(value) {
    return value === undefined;
  }

  private _getAggregatedGroup(currentGroup, rowSet, groupByFields) {
    let groupByColumn, groupByvalue, childGroup;
    for (let g = 0; g < groupByFields.length; g++) {
      groupByColumn = groupByFields[g];
      groupByvalue = rowSet.get(groupByColumn);

      childGroup = currentGroup.get(groupByvalue);
      if (childGroup === undefined) {
        childGroup = new Map();
        currentGroup.set(groupByvalue, childGroup);

        if (this._isLastItem(groupByFields, g)) {
          childGroup.set('aggregates', {});
          childGroup.set('select', {});
          childGroup.set('totals', {});
        }
      }

      currentGroup = childGroup;
    }
    return currentGroup;
  }

  private _updateGroupSelectColumns(currentGroup, rowSet, selectColumns) {
    let selectColumn, columnValue;
    for (let s = 0; s < selectColumns.length; s++) {
      selectColumn = selectColumns[s];
      columnValue = rowSet.get(selectColumn);
      const selectObj = currentGroup.get('select');
      if (selectObj[selectColumn] === undefined) {
        selectObj[selectColumn] = columnValue;
      }
    }
  }

  private _updateGroupAggregateColums(currentGroup, rowSet, rollups) {
    let aggregationType;
    let columnValue;
    for (const aggregationColumn in rollups) {
      aggregationType = rollups[aggregationColumn].type;

      const aggregates = currentGroup.get('aggregates');
      const totals = currentGroup.get('totals');

      if (aggregationType === 'custom') {
        rollups[aggregationColumn].fn(aggregationColumn, aggregates, rowSet);
        continue;
      }

      columnValue = rowSet.get(aggregationColumn);

      if (this._shouldDefaultToZero(columnValue)) {
        columnValue = null;
      }

      if (aggregates[aggregationColumn] === undefined) {
        aggregates[aggregationColumn] = null;
      }

      switch (aggregationType) {
        case 'sum':
          if (columnValue !== null) {
            aggregates[aggregationColumn] = +Big(
              aggregates[aggregationColumn] || 0
            ).plus(columnValue || 0);
          }
          break;
        case 'count':
          aggregates[aggregationColumn] = +Big(
            aggregates[aggregationColumn] || 0
          ).plus(1);
          break;
        case 'min':
          if (
            aggregates[aggregationColumn] == null ||
            columnValue < aggregates[aggregationColumn]
          )
            aggregates[aggregationColumn] = columnValue;
          break;
        case 'max':
          if (
            aggregates[aggregationColumn] == null ||
            columnValue > aggregates[aggregationColumn]
          )
            aggregates[aggregationColumn] = columnValue;
          break;
        case 'avg':
          if (totals[aggregationColumn] === undefined) {
            totals[aggregationColumn] = 0;
          }
          aggregates[aggregationColumn] = +Big(
            aggregates[aggregationColumn] || 0
          ).plus(columnValue || 0);
          totals[aggregationColumn] = +Big(totals[aggregationColumn]).plus(1);
          break;
        case 'percentage':
          if (totals[aggregationColumn] === undefined) {
            totals[aggregationColumn] = 0;
          }
          aggregates[aggregationColumn] = +Big(
            aggregates[aggregationColumn] || 0
          ).plus(columnValue || 0);
          totals[aggregationColumn] = +Big(totals[aggregationColumn]).plus(
            columnValue || 0
          );
          break;
      }
    }
  }

  private _updateAggregatedRowWithRollupValues(rowSet, currentGroup) {
    let runningValue, totalValue;
    const aggregates = currentGroup.get('aggregates');
    const totals = currentGroup.get('totals');
    for (const columnName in aggregates) {
      runningValue = aggregates[columnName];
      totalValue = totals[columnName];
      if (totalValue === undefined) {
        totalValue = runningValue;
      } else {
        Big.RM = 2;
        totalValue = +Big(runningValue || 0).div(totalValue);
      }
      rowSet.set(columnName, totalValue);
    }
  }

  private _updateAggregatedRowWithGroupLevelValues(
    rowSet,
    groupByLevelValues,
    groupByFields
  ) {
    for (let i = 0; i < groupByLevelValues.length; i++) {
      const columnName = groupByFields[i];
      const columnValue = groupByLevelValues[i];
      rowSet.set(columnName, columnValue);
    }
  }

  private _updateAggregatedRowWithSelectColumns(
    rowSet,
    selectColumns,
    groupByFieldMap
  ) {
    for (const columnName in selectColumns) {
      const columnValue = selectColumns[columnName];
      if (!groupByFieldMap[columnName]) {
        rowSet.set(columnName, columnValue);
      }
    }
  }

  private _updateAggregatedRowWithPostProcessValues(
    rowSet,
    postProcess,
    tenantSettings
  ) {
    let customFn;
    for (const columnName in postProcess) {
      customFn = postProcess[columnName];
      if (typeof customFn === 'function') {
        const value = customFn(columnName, rowSet, tenantSettings);
        rowSet.set(columnName, value);
      }
    }
  }

  // Updates aggregated result table - Flatten out the groupping object [Step 2] Recursive
  private _updateAggregatedResultTable(
    aggregatedResult,
    rootGroup,
    columnMap,
    groupByFields,
    postProcess,
    groupFieldMap,
    tenantSettings
  ) {
    const queue = [
      {
        group: rootGroup,
        prevGroupValue: [],
      },
    ];

    while (queue.length) {
      const groupItem = queue.pop();
      const currentGroup = groupItem.group;
      const select = currentGroup.get('select');

      if (select === undefined) {
        currentGroup.forEach(function (childGroup, groupByField) {
          queue.unshift({
            group: childGroup,
            prevGroupValue: groupItem.prevGroupValue
              .slice()
              .concat(groupByField),
          });
        });
      } else {
        const rowSet = new RowSet(
          _.fill(new Array(columnMap._lastIndex), null),
          columnMap
        );

        this._updateAggregatedRowWithSelectColumns(
          rowSet,
          select,
          groupFieldMap
        );
        this._updateAggregatedRowWithRollupValues(rowSet, currentGroup);
        this._updateAggregatedRowWithGroupLevelValues(
          rowSet,
          groupItem.prevGroupValue,
          groupByFields
        );
        this._updateAggregatedRowWithPostProcessValues(
          rowSet,
          postProcess,
          tenantSettings
        );

        aggregatedResult.push(rowSet.unwrap());
      }
    }
  }

  private _createColumn(columnMap, field) {
    if (columnMap[field] === undefined) {
      columnMap[field] = columnMap._lastIndex;
      columnMap._lastIndex++;
    }
  }

  private _updateColumnMapWithObjectProperties(obj, columnMap) {
    for (const field in obj) {
      this._createColumn(columnMap, field);
    }
  }

  private _updateColumnMapWithArrayElements(arr, columnMap) {
    for (let i = 0; i < arr.length; i++) {
      this._createColumn(columnMap, arr[i]);
    }
  }

  // Creates the new header rowSet based on the provided options: groupBy, select, rollups
  private _createAggregatedResultHeaderColumnMap(
    groupBy,
    select,
    rollups,
    postProcess
  ) {
    const columnMap = { _lastIndex: 0 };
    this._updateColumnMapWithArrayElements(groupBy, columnMap);
    this._updateColumnMapWithArrayElements(select, columnMap);
    this._updateColumnMapWithObjectProperties(rollups, columnMap);
    this._updateColumnMapWithObjectProperties(postProcess, columnMap);
    return columnMap;
  }

  // Performs filtering, groupping, sorting, aggregation and select fields [Step 1]
  private _performBaseAggregatedResult(options) {
    const baseAggregatedResult = new Map();
    let currentGroup;

    options.dataSet.forEachRow((rowSet) => {
      if (rowSet.isFilteredRow(options.filterBy)) {
        currentGroup = this._getAggregatedGroup(
          baseAggregatedResult,
          rowSet,
          options.groupBy
        );
        this._updateGroupSelectColumns(currentGroup, rowSet, options.select);
        this._updateGroupAggregateColums(currentGroup, rowSet, options.rollups);
      }
    });
    return baseAggregatedResult;
  }

  private _getHeaderRow(columnMap) {
    const headerRow = Object.keys(columnMap);
    headerRow.shift(); //removing _lastIndex
    return headerRow;
  }

  private _getAtierSortingInfo(groupByFields, otherSortFields) {
    const fieldMap = {};
    const sortingFields = [];
    const orderByFields = [];
    if (Array.isArray(otherSortFields)) {
      for (let s = 0; s < otherSortFields.length; s++) {
        const sortInfo = otherSortFields[s];
        if (sortInfo === undefined) continue;
          sortingFields.unshift(sortInfo.field);
          if (typeof sortInfo.order === 'string') {
            orderByFields.unshift(sortInfo.order.toUpperCase());
          } else {
            sortInfo.unshift('ASC');
          }
      }
    }
    for (let i = groupByFields.length - 1; i >= 0; i--) {
      const field = groupByFields[i];
      if (!fieldMap[field]) {
        sortingFields.unshift(field);
        orderByFields.unshift('ASC');
        fieldMap[field] = 1;
      }
    }
    return {
      sortingFields: sortingFields,
      orderByFields: orderByFields,
      groupFieldMap: fieldMap,
    };
  }

  aggregate() {
    this._logToConsole('Aggregation:BEFORE', this._options.dataSet.data());

    const aggregatedResult = [];

    if (this._options.dataSet.isEmpty()) {
      return aggregatedResult;
    }

    const baseAggregatedResult = this._performBaseAggregatedResult(this._options);
    const columnMap = this._createAggregatedResultHeaderColumnMap(
      this._options.groupBy,
      this._options.select,
      this._options.rollups,
      this._options.postProcess
    );
    const headerRow = this._getHeaderRow(columnMap);
    const sortingInfo = this._getAtierSortingInfo(
      this._options.groupBy,
      this._options.sortBy
    );
    this._updateAggregatedResultTable(
      aggregatedResult,
      baseAggregatedResult,
      columnMap,
      this._options.groupBy,
      this._options.postProcess,
      sortingInfo.groupFieldMap,
      this._options.tenantSettings
    );

    const arrayHelpers = new ArrayHelpers();
    arrayHelpers.MultiColumnSort(
      aggregatedResult,
      columnMap,
      sortingInfo.sortingFields,
      sortingInfo.orderByFields
    );
    aggregatedResult.unshift(headerRow);

    this._logToConsole('Aggregation:AFTER', aggregatedResult);

    this._options.dataSet.setData(aggregatedResult);
  }

  private _logToConsole(message, data) {
    if (typeof window !== "undefined" && window['IX_DEBUG_SETTINGS']) {
        window['IX_DEBUG_SETTINGS'].clientSvc.logTable(message, data);
    } else {
        this.workerLogToConsole(message, data);
    }
  }

  private workerLogToConsole(text, data) {
    if (!this._debugMode) return;

    postMessage({
      logText: text,
      logData: data,
    });
  }
}
