import { ApplicationRef, ComponentFactoryResolver, ElementRef, Injectable, Injector, Renderer2, RendererFactory2, SimpleChange } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AppEvent } from '../state/app-events.enum';
import { PopupComponent } from '../components/popup/popup.component';
import { ButtonComponent } from '../components/button/button.component';
import { DateboxComponent } from '../components/datebox/datebox.component';
import { TextboxComponent } from '../components/textbox/textbox.component';
import { NumberboxComponent } from '../components/numberbox/numberbox.component';
import { FileDeleteComponent } from '../components/file-delete/file-delete.component';
import { AppsConstantsFacade } from './apps-constants.facade';
import { ApplicationInformation } from './application-information.service';
import { ListApplication } from '../components/list-application';
import { appletComponentMap } from '../components/applet-component-map';
import { AppsFacade } from '../state/apps.facade';

@Injectable()
export class ComponentService {

    renderer: Renderer2;
    popupMap: Map<string, any>;
    hostViewMap: Map<string, any[]>;
    currentStepName: string;

    constructor(
        rendererFactory: RendererFactory2,
        private injector: Injector,
        private componentFactory: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private appsConstantsFacade: AppsConstantsFacade,
        private applicationInformation: ApplicationInformation,
        private appsFacade: AppsFacade,
    ) {
        this.popupMap = new Map<string, any>();
        this.hostViewMap = new Map<string, any[]>();
        this.renderer = rendererFactory.createRenderer(null, null);
        this.appsConstantsFacade.step$.subscribe(stepName => this.currentStepName = stepName);
        this.appsConstantsFacade.events$.pipe(
            filter(event => AppEvent.PopupDestroy.EqualsIgnoreCase(event?.id)),
            tap(event => this.destroyPopup(event?.state))
        ).subscribe();
    }

    createPopupComponent(appName: string, popupId: string, dxOptions: any): void {

        const domElement = document.getElementById(popupId);
        if (!_.isNil(domElement)) return;

        const appInfo = this.applicationInformation.getAppInfo(appName);
        const config = appInfo.popups[popupId];
        config.id = popupId;
        _.mergeWith(config.popup, dxOptions);
        config.canvas.applets = this.applicationInformation.getStepApplets(this.currentStepName, [config.canvas.id]);

        const factory = this.componentFactory.resolveComponentFactory(PopupComponent);
        const componentRef = factory.create(this.injector);
        componentRef.instance.config = config;
        componentRef.instance.context = {};

        this.renderer.setAttribute(componentRef.location.nativeElement, "id", popupId);
        this.renderer.appendChild(document.body, componentRef.location.nativeElement);

        componentRef.hostView.detectChanges();
        this.updatePopupHostViews(popupId, componentRef.hostView);
    }

    createFileDeleteComponent(appName: string, fileId: any, onUpdateState: Function): any {

        const factory = this.componentFactory.resolveComponentFactory(FileDeleteComponent);
        const componentRef = factory.create(this.injector);
        componentRef.instance.fileId = fileId;
        componentRef.instance.updateState.subscribe(docId => onUpdateState(docId));
        componentRef.hostView.detectChanges();

        this.updateHostViews(appName, componentRef.hostView);

        return componentRef.location.nativeElement;
    }

    createButtonComponent(appName: string, buttonConfig: any, isHidden: boolean, buttonClass: string): any {

        const factory = this.componentFactory.resolveComponentFactory(ButtonComponent);
        const buttonRef = factory.create(this.injector);

        buttonRef.instance.config = buttonConfig;
        buttonRef.instance.cssClass = buttonClass;

        if (isHidden) {
            this.renderer.setStyle(buttonRef.location.nativeElement, 'display', 'none');
        }

        this.updateHostViews(appName, buttonRef.hostView);

        return buttonRef.location.nativeElement;
    }

    createTextBoxComponent(
        appName: string,
        model: any,
        config: any,
        applet: any,
        context: any,
        conditionalFormats: any,
        parentModel: any,
        cssClass: string,
        updateStateCallback: Function
    ): ElementRef {

        const factory = this.componentFactory.resolveComponentFactory(TextboxComponent);
        const textBoxRef = factory.create(this.injector);

        textBoxRef.instance.model = model;
        textBoxRef.instance.config = config;
        textBoxRef.instance.applet = applet;
        textBoxRef.instance.context = context;
        textBoxRef.instance.conditionalFormats = conditionalFormats;
        textBoxRef.instance.parentModel = parentModel;
        textBoxRef.instance.cssClass = cssClass;

        const sub: Subscription = textBoxRef.instance.updateState.subscribe(event => updateStateCallback(event));
        textBoxRef.onDestroy(() => sub.unsubscribe());

        this.updateHostViews(appName, textBoxRef.hostView);

        const changes = {
            model: new SimpleChange(null, model, true)
        }
        textBoxRef.instance.ngOnChanges(changes);

        return textBoxRef.location;
    }

    createNumberBoxComponent(
        appName: string,
        model: any,
        config: any,
        applet: any,
        context: any,
        conditionalFormats: any,
        parentModel: any,
        cssClass: string,
        updateStateCallback: Function
    ): ElementRef {

        const factory = this.componentFactory.resolveComponentFactory(NumberboxComponent);
        const componentRef = factory.create(this.injector);

        componentRef.instance.model = model;
        componentRef.instance.config = config;
        componentRef.instance.applet = applet;
        componentRef.instance.context = context;
        componentRef.instance.conditionalFormats = conditionalFormats;
        componentRef.instance.parentModel = parentModel;
        componentRef.instance.cssClass = cssClass;

        const sub: Subscription = componentRef.instance.updateState.subscribe(event => updateStateCallback(event));
        componentRef.onDestroy(() => sub.unsubscribe());

        this.updateHostViews(appName, componentRef.hostView);

        const changes = {
            model: new SimpleChange(null, model, true)
        }
        componentRef.instance.ngOnChanges(changes);

        return componentRef.location;
    }

    createDateBoxComponent(
        appName: string,
        model: any,
        config: any,
        applet: any,
        context: any,
        conditionalFormats: any,
        parentModel: any,
        cssClass: string,
        updateStateCallback: Function
    ): ElementRef {

        const factory = this.componentFactory.resolveComponentFactory(DateboxComponent);
        const componentRef = factory.create(this.injector);

        componentRef.instance.model = model;
        componentRef.instance.config = config;
        componentRef.instance.applet = applet;
        componentRef.instance.context = context;
        componentRef.instance.conditionalFormats = conditionalFormats;
        componentRef.instance.parentModel = parentModel;
        componentRef.instance.cssClass = cssClass;

        const sub: Subscription = componentRef.instance.updateState.subscribe(event => updateStateCallback(event));
        componentRef.onDestroy(() => sub.unsubscribe());

        this.updateHostViews(appName, componentRef.hostView);

        const changes = {
            model: new SimpleChange(null, model, true)
        }
        componentRef.instance.ngOnChanges(changes);

        return componentRef.location;
    }

    createDxGridMasterDetailTemplate(parentListApp: ListApplication, container, options, detailApp, masterDetailAriaDescription) {

        const appClassName = this.applicationInformation.getAppClassName(detailApp);
        const tmpId = 'CP_' + detailApp.replace(/\./g, '') + '_' + options.rowIndex;
        const instanceId = detailApp + "[" + options.rowIndex + "]";
        const applet = {
            rid: tmpId,
            name: instanceId,
        };
        const detailAppName = detailApp.normalize();
        const componentTypeName = detailAppName.charAt(0).toUpperCase() + detailAppName.slice(1);
        const componentType = appletComponentMap[componentTypeName];
        const appletFactory = this.componentFactory.resolveComponentFactory(componentType) as any; // Better way than "any"?
        const appletRef = appletFactory.create(this.injector);

        appletRef.instance.applet = applet;
        appletRef.instance.context = parentListApp.context;
        appletRef.instance.cssClass = appClassName;
        appletRef.instance.masterDetailRowIndex = options.rowIndex;

        const appletNode = appletRef.location.nativeElement;
        this.renderer.setAttribute(appletNode, "id", (tmpId + 'wrapper'));

        this.appsFacade.updateAppState(instanceId, options.row.data);

        // Select row
        container.parent().on("mousedown touchstart", () => {
            this.appsFacade.updateAppState(parentListApp.applet.name, options.key);
        });

        container.append(appletNode);
        this.updateHostViews(parentListApp.applet.name, appletRef.hostView);
    }

    cleanApplicationRef(appName: string): void {
        if (!this.hostViewMap.has(appName)) return;
        const hostViews = this.hostViewMap.get(appName);
        this.removeHostViews(hostViews);
        this.hostViewMap.delete(appName);
    }

    cleanApplicationPopupRef(): void {
        this.popupMap.forEach((hostView, popupId) => this.removePopupHostView(popupId, hostView));
        this.popupMap.clear();
    }

    private removeHostViews(hostViews: any[]): void {
        hostViews.forEach(hostView => this.removeHostView(hostView));
    }

    private destroyPopup(eventState: AppState): void {
        if (_.isEmpty(eventState)) return;
        const popupId = eventState.popupId?.toString();
        if (_.isEmpty(popupId)) return;
        const hostView = this.popupMap.get(popupId);
        if (_.isNil(hostView)) return;
        this.removePopupHostView(popupId, hostView);
        this.popupMap.delete(popupId);
    }

    private removePopupHostView(popupId: string, hostView: any) {
        const domElement = document.getElementById(popupId);
        this.renderer.removeChild(document.body, domElement);
        this.removeHostView(hostView)
    }

    private removeHostView(hostView: any): void {
        this.applicationRef.detachView(hostView);
        hostView.destroy();
    }

    private updateHostViews(appName: string, hostView: any): void {
        if (!this.hostViewMap.has(appName)) {
            this.hostViewMap.set(appName, []);
        }
        const hostViews = this.hostViewMap.get(appName);
        hostViews.push(hostView);
        this.applicationRef.attachView(hostView);
    }

    private updatePopupHostViews(popupId: string, hostView: any): void {
        if (this.popupMap.has(popupId)) {
            const auxHostView = this.popupMap.get(popupId);
            this.removePopupHostView(popupId, auxHostView);
        }
        this.popupMap.set(popupId, hostView);
        this.applicationRef.attachView(hostView);
    }

}