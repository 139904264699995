import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  AfterViewInit,
  ViewChild,
  Input,
  Output,
  OnDestroy,
  OnChanges,
  EventEmitter,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import Sugar from 'sugar';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { DataService } from '../../services/data.service';
import { FieldFormatService } from '../../services/field-format.service';
import { AccessibilityService } from '../../services/accessibility.service';
import { DynamicReplacementService } from '../../services/dynamic-replacement.service';
import { IcValidator } from '../../services/validation-engine/ic-validator';
import { ThemeService } from '../../services/theme.service';
import { UtilService } from '../../services/util.service';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { ValidationGroupService } from '../../services/validation-group.service';
import UtilityFunctions from '../../utility.functions';

const _noUTCFormat = '{yyyy}-{MM}-{dd}';
type DateBoxConfig = {
  dateBoxModel: {
    buttonAriaLabel?: string,
    displayFormat?: string,
    dxValidator?: {
      validationGroup: string,
      validationRules: { type: string, warning?: boolean, message: any, min?: number, max?: number }[],
    },
    onValueChanged?: () => void,
    placeholder?: string,
    resetToDefaultIfBlank?: boolean,
    type?: string
  },
  numberProperties: {
    format?: string,
    validation?: {
      validationGroup: string,
      validationRules: { type: string, warning?: boolean, message: any, min?: number, max?: number }[],
    },
  },
  translationId?: string,
};



@Component({
  selector: 'ic-date-box',
  template: `<dx-date-box
    [(value)]="dxValue"
    [class]="cssClass"
    [placeholder]="placeholder"
    [disabled]="dxDisabled"
    (onInitialized)="dxOptions.onInitialized($event)"
    (onValueChanged)="dxOptions.onValueChanged($event)"
    (onFocusOut)="dxOptions.onFocusOut($event)"
  ></dx-date-box>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateboxComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {

  @Input() fieldName: any;
  @Input() model: any;
  @Input() config: DateBoxConfig;
  @Input() applet: Applet;
  @Input() context: any;
  @Input() conditionalFormats: any;
  @Input() parentModel: any;
  @Input() cssClass: string;
  @Input() checkSize: any;

  @Output() updateState = new EventEmitter<AppStateChange>();

  @ViewChild(DxDateBoxComponent)
  datebox: DxDateBoxComponent;

  dxValue: any;
  dxOptions: any;
  dxDisabled = false;
  placeholder: any;
  translateSubscription: Subscription;
  validator: any;
  min = 0;
  max = 0;
  format: any = 'iXingFieldFormat.Integer'; // TODO Find out is this on purpose? Why not iXingFieldFormat.DateTime?
  validatorOptions: {
    validationGroup?: string,
    validationRules?: any[],
  } = {};
  modelValue: string;
  resetToDefaultIfBlank: any;
  defaultValue: any;
  isValidationGroupValid: () => void;
  originalErrorMessage: any;
  originalOnValueChanged: any;

  private resetDirtyFieldsToPristine: Subscription;

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private translate: TranslateFacadeService,
    private dataService: DataService,
    private themeService: ThemeService,
    private fieldFormatService: FieldFormatService,
    private validationEngine: ValidationEngineService,
    private validationGroupService: ValidationGroupService,
    private dynamicReplacementService: DynamicReplacementService,
    private accessibilityService: AccessibilityService,
    private utilService: UtilService) { }

  ngOnInit(): void {
    this.controller();
  }

  ngOnChanges(changes) {
    // Setup default values, e.g. require formats to be set
    if (this.dxOptions == null) {
      this.controller();
    }

    for (const propName in changes) {
      if (propName === 'model') {
        let newVal = null;
        let oldVal = null;
        if (changes[propName].currentValue.value) {
          newVal = changes[propName].currentValue.value;
        }
        if (changes[propName].previousValue && changes[propName].previousValue.value) {
          oldVal = changes[propName].previousValue.value;
        }
        this._getDxValueFromModel(newVal, oldVal);
      }
    }
  }

  ngOnDestroy() {
    this.resetDirtyFieldsToPristine?.unsubscribe();
    this.translateSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    if (!_.isNil(this.config?.dateBoxModel)) {
      const $e = $(this.elementRef.nativeElement);

      const _options = {
        element: $e,
        target: $e.find('input'),
        appId: this.applet.rid,
        placeholder: this.placeholder,
      };

      this._updateTranslations(_options);

      this.translateSubscription = this.translate.onLangChange.subscribe(() => {
        const displayFormat = this._getConditionalFormat();
        const format = this.fieldFormatService.getFormat(displayFormat).f;
        if (format) {
          this.datebox.instance.option('displayFormat', format);
        }
        this._updateTranslations(_options);
      });
    }
  }

  _log(...args: any[]) {
    if (window['IX_DEBUG_SETTINGS']?.icDatebox) {
      args.unshift('icDatebox');
      window['IX_Log']?.apply(null, args);
    }
  }

  controller() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    that.validator = null;
    that.fieldName = that.utilService.getFieldNameFromConfig(that.config.dateBoxModel);
    that.dxValue = null;
    that.modelValue = that.model.value + '';
    that.placeholder = that.config.dateBoxModel.placeholder;
    that.resetToDefaultIfBlank = that.config.dateBoxModel.resetToDefaultIfBlank;
    that.defaultValue = that.model.value;

    that._getDxValueFromModel(that.model.value, null);
    const ixIsValidationGroupValid = _.debounce(
      () => {
        if (!that.validatorOptions?.validationGroup) {
          return;
        }
        that.validationGroupService.publishRefreshButtons(that.applet.name, that.validatorOptions.validationGroup);
      }, 150);

    that.isValidationGroupValid = () => ixIsValidationGroupValid();

    that.dxOptions = {
      dateBoxModel: {
        visible: false,
        inputAttr: {},

        dropDownButtonTemplate: (buttonData, contentElement) => {
          const _config = that.config.dateBoxModel;
          const btnAriaLabel = !!_config.buttonAriaLabel && _config.buttonAriaLabel.length > 0 ? _config.buttonAriaLabel : 'Select';
          const appName = that.applet.name;
          const btnAriaLabelId = !!appName && appName.length > 0 ? appName + '.fields.' + that.fieldName + '.buttonAriaLabel' : '';
          const selectLabel = that.utilService.translateOrDefault(btnAriaLabelId, btnAriaLabel);
          $(contentElement).parent().attr('aria-label', selectLabel);
          return 'dropDownButton';
        },
        onInitialized: (editor) => {

          if (editor.component) {
            const instance = editor.component;
            instance.option(that.dxOptions);
          }

          if (!$.isEmptyObject(that.validatorOptions)) {
            that.validator = new IcValidator(that.validationEngine, editor, that.validatorOptions);

            setTimeout(() => that.isValidationGroupValid(), 10);
          }

          that.resetDirtyFieldsToPristine = that.dataService.subscribeToResetDirtyFieldsToPristine(that.applet.name, () => {
            editor.component.option('isValid', true);
          });
        },
        onFocusOut: (e) => {
          const toGuess = e.component.option('value');
          that._log('onFocusOut dx  text', toGuess);
          if (typeof toGuess === 'string' && toGuess.length > 0) {
            that._log('onFocusOut sg value', toGuess);
            that._getDxValueFromModel(toGuess, null);
          }
          if (that.resetToDefaultIfBlank && !e.component.option('value')) {
            that._getDxValueFromModel(that.defaultValue, null);
          }
          this._runValidation();
          that.accessibilityService.addAccessibilityToDxDateBoxOnFocusOut(e);
        },
        onOpened: (e) => {
          that.accessibilityService.addAccessibilityToDxDateBoxOnOpened(e);
        },
        onValueChanged: (e) => {
          if (that._setValue(e)) {
            that._runValidation();
            if (!that.model.disableValidation) {
              if (that.validator != null) {
                that.validator.validate(that);
              }
              that.isValidationGroupValid();
            }

            if (!that.model.disableValidation) {
              if (typeof that.originalOnValueChanged !== 'undefined') {
                setTimeout(() => that.originalOnValueChanged(e), 0);
              }
            }

            if (that.model.disableValidation) {
              delete that.model.disableValidation;
            }

            that.updateState.emit({ field: that.fieldName, value: that.model.value });
          }
        },
      },
    };

    if (_.isNil(this.config.numberProperties.validation) && !_.isNil(this.config.dateBoxModel.dxValidator)) {
      this.config.numberProperties.validation = _.extend({}, this.config.dateBoxModel.dxValidator);
    }
    delete this.config.dateBoxModel.dxValidator;

    if (!_.isNil(this.config.numberProperties)) {
      if (!this._setMinMaxValidationRules()) {
        this._setMinMaxRangeValues({});
      }

      this.format = this.config.numberProperties.format;
    }

    if (!_.isNil(this.config.dateBoxModel.onValueChanged)) {
      this.originalOnValueChanged = this.config.dateBoxModel.onValueChanged;
      delete this.config.dateBoxModel.onValueChanged;
    }

    _.merge(this.dxOptions.dateBoxModel, this.config.dateBoxModel);

    if (!_.isNil(this.config?.dateBoxModel)) {
      if (!_.isNil(this.config.dateBoxModel.dxValidator)) {
        _.extend(this.validatorOptions, this.config.dateBoxModel.dxValidator);
        delete this.config.dateBoxModel.dxValidator;
        this.dxOptions.dateBoxModel.inputAttr.required = 'required';
      }

      _.extend(this.dxOptions.dateBoxModel, this.config.dateBoxModel);
      this.dxOptions.dateBoxModel.displayFormat = {
        parser: (value) => {
          const format = this._getFormat();
          let d = window.DevExpress.localization.date.parse(value, format);
          if ((d == null || d.getFullYear() < 1900) && format.indexOf('yyyy') > -1) {
            d = window.DevExpress.localization.date.parse(value, format.replace('yyyy', 'yy'));
          }
          return d;
        },
        formatter: (value) => {
          const format = this._getFormat();
          return window.DevExpress.localization.date.format(value, UtilityFunctions.handleMonthFieldMasks(format));
        },
      };

      this.themeService.setEnforceDateFieldMaskInput(this.dxOptions.dateBoxModel);

      this.dxOptions = this.dxOptions.dateBoxModel;
    }
  }

  _getDxValueFromModel(modelStr, oldValue) {
    this.dynamicReplacementService.getDynamicValue(modelStr)
      .then((dynamicMessage) => {
        let defaultValue = null, formatted = null, oldDefaultValue = null;
        const dateBoxType = this.config.dateBoxModel.type
        if (_.isString(dynamicMessage) && dynamicMessage.length > 0) {
          formatted = this.getSugarDate(dynamicMessage);
          if (formatted.raw == "InvalidDate") {
              defaultValue = null;
          } else if (dateBoxType == "datetime") {
              defaultValue = formatted.toISOString().raw;
          } else {
              defaultValue = this.getFormat(dynamicMessage, _noUTCFormat);
          }
        }
        if (oldValue && !oldValue.startsWith('{')) {
          oldDefaultValue = dateBoxType == "datetime" ? oldValue : this.getFormat(oldValue, _noUTCFormat);
        }
        // ngOnChange might send the dxValue as raw date and we need to update dxValue with defaultValue 
        // and fire the onChange one more time
        if (oldDefaultValue != defaultValue || this.dxValue != defaultValue) {
          this.dxValue = defaultValue;
          this.cdr.detectChanges();
        }
      });
  }

  _updateTranslations(options) {
    this.utilService.updateComponentTranslationProperties(options, this.config.translationId);
    this.utilService.createADALabelAttributes(options);

    if (this.config.translationId + '.placeholder' != options.placeholder) {
      this.placeholder = options.placeholder;
    }
  }

  _getConditionalFormat() {
    return this.utilService.getConditionalFormatRulesFieldMask(
      this.config.dateBoxModel.displayFormat,
      this.conditionalFormats,
      this.parentModel,
      this.fieldName,
      null
    );
  }

  _setValue(e) {
    this._log('onValueChanged dx value', e.value, e);
    let res = true;

    // If datebox was cleared from P-tier command, then there is no event associated with it
    // VSTS: 78632 - set res to false, to avoid circular events to occur
    if (e.event === undefined) {
      res = false;
      this._runValidation();
    }

    if (e.value === null) {
      this.model.value = '';
      this.model.text = '';
    } else {
      const tmp = this.getFormat(e.value, _noUTCFormat);
      if (tmp === this.model.value && e.value === e.previousValue) {
        res = false;
      } else {
        this.model.value = tmp;
        this._log('onValueChanged sg value', this.model.value);
        this.model.text = e.component.option('text');
      }
    }
    return res;
  }

  _runValidation() {
    if (!this.model.disableValidation) {
      if (this.validator != null) {
        this.validator.validate(this);
      }
      this.isValidationGroupValid();
    }
  }

  _setMinMaxRangeValues(rule) {
    this.min = rule.min;
    this.max = rule.max;

    if (_.isFunction(rule.minFunc)) {
      this.min = rule.minFunc();
    }
    if (_.isFunction(rule.maxFunc)) {
      this.max = rule.maxFunc();
    }

    this.min = this.getSugarDate(this.min).isValid() ? this.min : null;
    this.max = this.getSugarDate(this.max).isValid() ? this.max : null;

    if (_.isFunction(rule.minFunc)) {
      rule.min = this.min;
    }
    if (_.isFunction(rule.maxFunc)) {
      rule.max = this.max;
    }
  }

  _getRangeErrorMessage(message, min, max, format) {
    const minDate = this.getSugarDate(min).format(format).raw;
    const maxDate = this.getSugarDate(max).format(format).raw;
    if (_.isFunction(message)) {
      message = message();
    }
    return message.replace(/{Min}/g, minDate).replace(/{Max}/g, maxDate);
  }

  _setMinMaxValidationRules() {
    let hasRules = false;
    if (this.config.numberProperties.validation) {
      const inValidator = this.config.numberProperties.validation;
      if (inValidator.validationGroup && inValidator.validationRules) {
        hasRules = true;
        this.validatorOptions.validationGroup = inValidator.validationGroup;
        this.validatorOptions.validationRules = [];
        for (let v = 0; v < inValidator.validationRules.length; v++) {
          const rule = inValidator.validationRules[v];
          if (rule.type === 'dateRange' && !rule.warning) {
            this.originalErrorMessage = rule.message;
            this._setMinMaxRangeValues(rule);
            rule.message = this._getRangeErrorMessage(this.originalErrorMessage, this.min, this.max, this.format.f);
          }
          this.validatorOptions.validationRules.push(rule);
        }
      }
    }
    return hasRules;
  }

  _getFormat() {
    // find a conditional field mask when the parent model has been updated
    const displayFormat = this.dxValue === this.model.value ? this._getConditionalFormat() : this.config.dateBoxModel.displayFormat;
    //to account for the case where the year is entered as 'yy'
    //and make sure the century is taken as current century
    return this.fieldFormatService.getFormat(displayFormat).f;
  }

  private getSugarDate(dateValue): sugarjs.Date.Chainable<Date> {
    return new Sugar.Date(dateValue);
  }

  private getFormat(dateValue, format) {
    const formatted = this.getSugarDate(dateValue);
    return formatted.isValid().raw ? formatted.format(format).raw : null;
  }

}
