/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { TranslateFacadeService } from '../services/translate-facade.service';
import { fromEvent, Subscription } from 'rxjs';
import { filter, tap, throttleTime } from 'rxjs/operators';
import { EventsService } from '../services/events.service';
import { AppEvent } from '../state/app-events.enum';
import UtilityFunctions from '../utility.functions';
import { ModalService } from './../services/modal.service';

interface EnhancedAlertState {
    visible?: boolean;
    messageText?: string;
    okButtonText?: string;
    cancelButtonText?: string;
    showCancelButton?: boolean;
    callbackFunction?: Function;
}

@Component({
    selector: 'ic-enhanced-alert',
    template: `
    <ng-container *ngIf="visible; then enhancedAlert;"></ng-container>
    <ng-template #enhancedAlert>
        <div class='IX_enhanceAlertWindow'>
            <div tabindex='0' role='dialog' aria-labelledby='IX_enhanceAlert_Message' class='IX_enhanceAlert'>
                <span title='Informational alert symbol' role='img' class='IX_enhanceAlertIcon'></span>
                <div id='IX_enhanceAlert_Message' role='alert' class="IX_enhanceAlertText">{{ messageText }}</div>
                <div class='IX_enhanceAlertButtons'>
                    <button (click)="onOkClick($event)" class='IX_enhanceAlertOkButton IX_enhanceAlertOkButton_Button displayInlineBlock ic-ada-focusonshown'>{{ okButtonText }}</button>
                    <button *ngIf='showCancelButton' (click)="onCancelClick($event)" class='IX_enhanceAlertCancelButton IX_enhanceAlertOkButton_Button displayInlineBlock' >{{ cancelButtonText }}</button>
                </div>
            </div>
        </div>
    </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnhancedAlertComponent implements OnInit, OnDestroy {

    private windowResizeSubscription: Subscription;
    private onEventSubscription: Subscription;
    private translateSubscription: Subscription;
    private lastElement: any;
    private eventName = "keydown .IX_enhanceAlertWindow";

    visible: boolean;
    messageText: string;
    okButtonText: string;
    cancelButtonText: string;
    showCancelButton: boolean;
    callbackFunction: Function;

    get $element(): any {
        return $(this.elementRef.nativeElement).find(".IX_enhanceAlert");
    }

    constructor(private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        private translate: TranslateFacadeService,
        private eventsService: EventsService,
        private modalService: ModalService) {
        this.visible = false;
        this.onEventSubscription = this.eventsService
            .events$
            .pipe(
                filter(event => AppEvent.EnhancedAlert.EqualsIgnoreCase(event?.id)),
                tap((event) => this.handleEvent(event.state))
            ).subscribe();
    }

    ngOnInit(): void {
        const currentTexts = {
            okButtonText: this.okButtonText,
            cancelButtonText: this.cancelButtonText
        };
        this.translateTexts(currentTexts);
        this.translateSubscription = this.translate.onLangChange.subscribe(() => this.translateTexts(currentTexts));
        this.windowResizeSubscription = fromEvent(window, 'resize')
            .pipe(throttleTime(500, undefined, { trailing: true }))
            .subscribe(() => UtilityFunctions.centerElement(this.$element));
    }

    ngOnDestroy(): void {
        this.onEventSubscription?.unsubscribe();
        this.translateSubscription?.unsubscribe();
        this.windowResizeSubscription?.unsubscribe();
    }

    onOkClick(e) {
        this.toggleResponse(e, true);
    }

    onCancelClick(e) {
        this.toggleResponse(e, false);
    }

    private translateTexts(eventState: EnhancedAlertState) {
        this.okButtonText = eventState.okButtonText ? eventState.okButtonText : this.getTranslation("OK");
        this.cancelButtonText = eventState.cancelButtonText ? eventState.cancelButtonText : this.getTranslation("CANCEL");
    }

    private getTranslation(message: string): string {
        return this.translate.instant(message);
    }

    private onButtonsAction(wasOkClick: boolean): void {
        this.performCallback(wasOkClick);
        this.visible = false;
        this.callbackFunction = null;
        this.messageText = null;
        this.showCancelButton = true;
        this.modalService.registerModalClose(this.$element, this.lastElement);
        this.detectChanges();
    }

    private performCallback(wasOkClick: boolean): void {
        if (this.callbackFunction)
            this.callbackFunction(wasOkClick);
    }

    private toggleResponse(e, wasOkClick: boolean): void {
        if (e.which == 1 || e.which == 32 || e.which == 13) {
            this.onButtonsAction(wasOkClick);
        }
    }

    private handleEvent(eventState: EnhancedAlertState) {
        if (_.isNil(eventState)) return;
        $(document).off(this.eventName, this.setSessionTimeoutWarningKeyBoardHandler);
        this.performCallback(false);
        this.lastElement = $(document.activeElement);
        this.translateTexts(eventState);
        this.messageText = _.isNil(eventState.messageText) ? "Alert" : eventState.messageText;
        this.showCancelButton = eventState.showCancelButton;
        this.callbackFunction = eventState.callbackFunction;
        if (this.visible) this.modalService.registerModalClose(this.$element, this.lastElement);
        this.visible = eventState.visible;
        if (this.visible) {
            this.detectChanges();
            this.modalService.registerModalOpen(this.$element);
        }
        $(document).on(this.eventName, this.setSessionTimeoutWarningKeyBoardHandler.bind(this));
        this.detectChanges();
        UtilityFunctions.centerElement(this.$element);
    }

    private detectChanges() {
        this.changeDetectorRef.detectChanges();
    }

    private setSessionTimeoutWarningKeyBoardHandler(e) {

        const TAB_KEY = 9;
        const ESC_KEY = 27;
        const tabbableSelector = ":ix-tabbable";

        if (e.keyCode !== TAB_KEY && e.keyCode !== ESC_KEY) {
            return;
        }

        if (e.keyCode === ESC_KEY) {
            this.onButtonsAction(true);
            $(document).off(this.eventName, this.setSessionTimeoutWarningKeyBoardHandler);
            return;
        }

        const $wrapper = $(this.elementRef.nativeElement).find(".IX_enhanceAlertWindow");
        const tabbableElements = $wrapper.find(tabbableSelector);
        const $firstTabbable = tabbableElements.first();
        const $lastTabbable = tabbableElements.last();
        const isTabOnLast = !e.shiftKey && e.target === $lastTabbable.get(0);
        const isShiftTabOnFirst = e.shiftKey && e.target === $firstTabbable.get(0);
        const isEmptyTabList = 0 === tabbableElements.length;
        const isOutsideTarget = $.inArray(e.target, tabbableElements) === -1;
        if (isTabOnLast || isShiftTabOnFirst || isEmptyTabList || isOutsideTarget) {
            e.preventDefault();
            (e.shiftKey ? $lastTabbable : $firstTabbable).focusin().focus();
        }
    }
}