import { Component, HostListener, OnInit } from '@angular/core';
import '../legacy/window.objects';
import '../angularjs/angularjsapp';
import '../libraries/jquery-ui';
import { Promise } from 'bluebird';

// Keep in mind about promises encapsulating setTimeout, 
// cancelling a promise might not fully work as expected
Promise.config({ cancellation: true });

@Component({
    selector: 'ic-root',
    template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {

    ngOnInit(): void {
        this.setGoogleFonts();
    }

    setGoogleFonts(): void {
        const webFontConfig = window['WebFontConfig'];
        if (!webFontConfig) return;
        const scriptFontId = 'pwp-google-font-id';
        const scriptFont = document.getElementById(scriptFontId);
        if (scriptFont) return;
        const script = document.createElement('script');
        script.id = scriptFontId;
        script.async = true;
        script.type = 'text/javascript';
        script.src = document.location.protocol + '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
        document.body.append(script);
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent): void {

        window.customTab = null;

        if (event.key != "Tab" || !window.IX_Theme || !window.IX_Theme.properties)
            return;

        if (window.customTab == null) {
            let navSettings = _.find(window.IX_Theme.properties, (x) => "tabnavigationmapping".EqualsIgnoreCase(x.PropertyName));
            if (!navSettings || !navSettings.Value1)
                return;

            navSettings = JSON.parse(navSettings.Value1);

            window.customTab = {
                navSettings: navSettings,
                nextTabConfigs: _.flatMap(Object.values(navSettings), (entry) => entry.next),
                previousTabConfigs: _.flatMap(Object.values(navSettings), (entry) => entry.previous)
            };
        }

        const navSettings = window.customTab.navSettings;
        const activeElement = $(document.activeElement);
        const getFocusClassName = (classNames) => {
            const startIndex = classNames.indexOf("focus-");
            const endIndex = classNames.indexOf(' ', startIndex);
            return classNames.substring(startIndex + 6, endIndex > 0 ? endIndex : classNames.length);
        }

        const HasCustomTabOrder = (element) => {
            const appName = $(element).closest("[data-app]").attr("data-app");
            const className = getFocusClassName(element.className);
            const elementConfigSelector = appName + ' ' + className;

            const settingsToLookFor = event.shiftKey ? window.customTab.previousTabConfigs : window.customTab.nextTabConfigs;
            return elementConfigSelector && _.includes(settingsToLookFor, elementConfigSelector);
        }

        const IsElementVisible = (element) => {
            return element.is(':visible'); // element.css("display") !== "none" && element.parentsUntil('[data-app]', '[style*="display: none"]').length < 1;
        }

        if (activeElement.length > 0 && navSettings) {
            const classNames = activeElement[0].className;
            const startIndex = classNames.indexOf("focus-");
            const endIndex = classNames.indexOf(' ', startIndex);
            const className = classNames.substring(startIndex + 6, endIndex > 0 ? endIndex : classNames.length);
            const appName = activeElement.closest("[data-app]").attr("data-app");
            const settings = navSettings[appName + ' ' + className];

            if (settings) {
                IX_Log('tabnav', 'settings', settings);
                const targetSettings = event.shiftKey ? (settings.previous || []) : (settings.next || []);
                if (targetSettings) {
                    IX_Log('tabnav', 'targetSettings', targetSettings);
                    for (let i = 0; i < targetSettings.length; i++) {
                        const selectorDetails = targetSettings[i].split(' ');
                        if (selectorDetails.length > 1) {
                            const appIdentifier = selectorDetails[0];
                            const classIdentifier = selectorDetails[1].toLowerCase();
                            const targetElement = $("[data-app='" + appIdentifier + "'] .focus-" + classIdentifier);

                            if (targetElement.length > 0 && IsElementVisible(targetElement)) {
                                event.preventDefault();
                                targetElement.focus();
                                break;
                            }
                        }
                    }
                }
            } else { // Tab order is not custom but avoid circular loop and ignore elements if they already have custom tab order
                const focusable = $(':focusable');
                let activeIndex = focusable.index(activeElement);
                if (activeIndex) {
                    const direction = event.shiftKey ? -1 : 1;
                    activeIndex += direction;
                    for (let i = activeIndex; i < focusable.length && i >= 0; i += direction) {
                        const elementToFocus = focusable[i];
                        const el = $(elementToFocus);
                        if (elementToFocus.tabIndex >= 0) {
                            const visible = IsElementVisible(el);
                            const customTab = HasCustomTabOrder(elementToFocus);
                            IX_Log('tabnav', 'elementToFocus', elementToFocus, 'visible', visible);
                            IX_Log('custom order', customTab);
                            if (visible && !customTab) {
                                event.preventDefault();
                                focusable[i].focus();
                                return;
                            }
                        }
                    }
                }
            }
        }
    }
}
