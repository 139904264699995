/* eslint-disable */
'use strict';

window.customTab = null;

if (!performance || !performance.mark) {
    performance = {
        mark: _.noop,
        getEntries: function () { return []; },
        getEntriesByName: function () { return []; },
        getEntriesByType: function () { return []; },
        measure: _.noop,
        timing: function () { return {}; },
        clearMarks: _.noop,
        clearMeasures: _.noop,
    };
}
 
function IX_PerfStart(appName, operation) {
    if (IX_DEBUG_SETTINGS.performance || ['WORKFLOW_CONFIG'].includes(appName)) {
        var step = $('body').attr('data-step') || '';
        var key = "[" + step + "]-[" + appName + "]-" + operation;
        performance.mark(key);

        var status = $('#icConsole textarea');
        if (status.length > 0) {
            status = status[0];
            var entries = performance.getEntriesByName(key, "mark");
            for (var i=0; i < entries.length; i++) {
                var p = entries[i];
                status.value += p.name + ' started: ' + Math.round(p.startTime) + '\n';
            }
        }
    }   
}
function IX_PerfEnd(appName, operation, inner) {
    if (IX_DEBUG_SETTINGS.performance) {
        var step = $('body').attr('data-step') || '';
        var key = "[" + step + "]-[" + appName + "]-" + operation;

        var entries = performance.getEntriesByName(key, "mark");
        var canMeasure = entries.length != 0;
        if (canMeasure) {
            performance.measure(key, key);
        }

        var status = $('#icConsole textarea');
        if (status.length > 0) {
            status = status[0];

            var entries = performance.getEntriesByName(key, "measure");
            for (var i=0; i < entries.length; i++) {
                var p = entries[i];
                if (canMeasure) {
                    status.value += p.name + ' ended: ' + Math.round(p.startTime + p.duration) + ' took: ' + Math.round(p.duration) + '\n';
                } else {
                    status.value += p.name + ' ended: could not measure.';
                }
                // console.log(p.name + ' started: ' + p.startTime + ' took: ' + p.duration);
            }
            if (inner) {
                status.value += inner;
            }                
        }

        var text = $('#icConsole textarea').val();
        var startregex = /^(?!>>).*started:\s([0-9]+)/m;
        var endregex = /^(?!>>).*ended:\s([0-9]+)/gm
        var startMatch = startregex.exec(text);
        var endMatch = endregex.exec(text);
        var startVal = 0;
        var endVal = 0;
        if (startMatch != null) {
            startVal = parseFloat(startMatch[1]);
        } else {
            startVal = "";
        }
        while (endMatch != null) {
        endVal = parseFloat(endMatch[1]);
        endMatch = endregex.exec(text);
        }
        var totalPageTime = $('#icConsoleTotalPageTime');
        totalPageTime.html('Total: ' + (endVal - startVal)/1000);        
    }
}


document.addEventListener('DOMContentLoaded', function () {
    var profiling = window.localStorage.getItem('IX_profile') == 'true';
    IX_DEBUG_SETTINGS.performance = profiling;
    if (profiling) {
        showIcConsole();
    }
})

function showIcConsole() {
    var profiling = window.localStorage.getItem('IX_profile') == 'true';
    var icConsole = document.getElementById('icConsole');
    if (!icConsole) {
        icConsole = document.createElement('div');
        icConsole.id = 'icConsole';
        icConsole.style.cssText = "padding: 5px; position: fixed; bottom: 0; left: 0; width: 100%; height: 450px; opacity: 0.8; background: white; border: solid 1px black; z-index: 10000;";

        var toolbar = document.createElement('div');
        toolbar.className = 'toolbar';
        toolbar.style.cssText = 'margin-bottom: 5px; display: flex;';
        icConsole.appendChild(toolbar);

        var title = document.createElement('div');
        title.textContent = "IC CONSOLE";
        title.style.cssText = 'font: bold 12px Verdana; line-height: 24px; margin-right: 10px';
        toolbar.appendChild(title);

        var status = document.createElement("textarea");
        status.id = "status";
        status.style.cssText = "width: 100%; height: 400px;"
        icConsole.appendChild(status);

        var buttonCss = 'padding: 0 5px; margin-right: 5px';

        var record = document.createElement('button');
        record.className = 'record';
        record.textContent = profiling ? "Stop Profiling" : "Start Profiling";
        record.onclick = function() {
            profiling = !profiling;
            window.localStorage.setItem('IX_profile', profiling);
            window.location.reload();
        }
        record.style.cssText = buttonCss;
        toolbar.appendChild(record);

        var clearConsole = document.createElement('button');
        clearConsole.className = 'clearIcConsole';
        clearConsole.textContent = "Clear Console";
        clearConsole.onclick = function() {
            status.value = "";
        }
        clearConsole.style.cssText = buttonCss;
        toolbar.appendChild(clearConsole);
        
        var totalPageTime = document.createElement('span');
        totalPageTime.id = 'icConsoleTotalPageTime';
        totalPageTime.style.cssText = "margin-left: auto; font: bold 12px Verdana; line-height: 24px; ";
        totalPageTime.textContent = "0";
        toolbar.appendChild(totalPageTime);
        
        document.body.appendChild(icConsole);
    }

    if (profiling) {
        $('#icConsole').show();
    }
}

document.addEventListener("keydown", function (e) {
    // alt + shift + ~
    if (e.keyCode == 192 && e.shiftKey && e.altKey) {
        showIcConsole();
    }
});

//For compatibility with earlier ECMAScript version and clients expecting this method, an alias .always is provided for .finally.
Promise.prototype.always = Promise.prototype.lastly;

if (!String.prototype.hashCode) {
    String.prototype.hashCode = function () {
        var hash = 0, i, chr;
        if (this.length === 0) return hash;
        for (i = 0; i < this.length; i++) {
            chr = this.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    };
}

function IX_Signout() {

    var d = new $.Deferred();

    $.ajax({
        type: 'GET',
        url: '/Membership/ExtPages/SignOut.aspx',
        contentType: "application/json",
    }).done(function (data) {
        d.resolve(data);
    }).fail(function (jqXHR, type, statusMessage) {
        d.resolve({});
    });

    return d.promise();
}

function IX_ConvertSelectedRowsToDSFormat(selected) {
    var dataFormated = [];
    if (selected.length > 0) {
        dataFormated.push(Object.keys(selected[0]).map(function (key) { return key; }));
        for (var i = 0; i < selected.length; i++) {
            dataFormated.push(Object.keys(selected[i]).map(function (key) { return selected[i][key]; }));
        }
    }
    return dataFormated;
}

// TODO: update reference in util list service
function IX_GetFormattedField(format, value) {

    if (typeof value == 'undefined' ||
        (value == null && typeof format != 'undefined' && format.isNFS == "false") ||
        (value == null && typeof format == 'undefined')) {
        return "";
    }

    if (typeof format == 'undefined') {
        return value;
    }

    if (format.p === "string") {
        if (value) {
            return value;
        }
        return format.n ? format.n : value;
    } else if (format.p === "numeral") {
        numeral.zeroFormat("0");
        numeral.nullFormat("0");

        if (format.z) {
            numeral.zeroFormat(format.z);
        }
        if (format.n) {
            numeral.nullFormat(format.n);
        }
        if (format.pnm100) {
            numeral.options.scalePercentBy100 = false;
        }

        var res, scale;
        if (_.isNumber(value) && typeof format.s != 'undefined' && format.s != null) {
            scale = parseFloat(format.s);
            value = value * scale;
        }

        if (_.isNumber(value) && value != 0 && Math.abs(value) < 0.00000001) {
            value = 0;
        }

        res = numeral(value).format(format.f);

        numeral.reset();

        return res;
    } else {
        if (format.n && !value) {
            return format.n;
        }
    }

    var truncateTimeIfNecessary = function (format, value) {
        if (format.f.indexOf("hh") < 0 && format.f.indexOf("mm") < 0 && format.f.indexOf("ss") < 0) {
            if (value.length > 10 && new Sugar.Date(value).isValid().raw) {
                return value.substr(0, 10);
            }
        }

        return value;
    };

    var getSugarDateFormat = function (format) {
        var arr = ['{'], i, lastTokenIsSpecialChar = false;
        for (i = 0; i < format.length; i++) {
            switch (format[i]) {
                case '/':
                case ':':
                case ' ':
                case ',':
                case '-':
                case 'T':
                case '.':
                    if (!lastTokenIsSpecialChar) {
                        arr.push('}');
                        arr.push(format[i]);
                    } else {
                        arr.push(format[i]);
                    }
                    lastTokenIsSpecialChar = true;
                    continue;
                default:
                    if (lastTokenIsSpecialChar) {
                        arr.push('{');
                    }

                    lastTokenIsSpecialChar = false;
                    break;
            }


            arr.push(format[i]);
        }
        arr.push('}');
        return arr.join("");
    };

    if (format.p === "sugar" && value) {
        value = truncateTimeIfNecessary(format, value);

        var formatted = new Sugar.Date(value);
        var tformat = getSugarDateFormat(format.f);
        return formatted.format(tformat).raw;
    }
}

function IX_ConditionalFormatApplyFormat_PivotedGrid(target, field) {
    var element, targetSelector, prefix,
        appStr = target.appId + field;
    if (field.indexOf("#HTML") > -1) {
        prefix = "#CL_HTML_";
    } else {
        prefix = "#CL";
    }
    targetSelector = prefix + appStr + "_" + target.row + "_" + target.col;
    element = $(targetSelector);
    return element;
}

function IX_ConditionalFormatApplyFormat_jQueryElement(target, field, auxFormat, auxAttrs) {
    var visible = auxFormat["display"] !== "none",
        _isButton = function (field) {
            return field.startsWith("CL_Btn_");
        };
    target.css(auxFormat).attr("aria-hidden", false);
    if (!visible)
        target.attr("aria-hidden", true);
    if (!$.isEmptyObject(auxAttrs))
        target.attr(auxAttrs);
    if (_isButton(field)) {
        if (!_.isUndefined($(target).data("dxButton"))) {
            $(target).data("dxButton").option("visible", visible);
        }
    }
}

function IX_ConditionalFormatApplyFormat(target, field, options, row, rowIndex) {
    
    const auxFormat = _.extend({}, options.format);
    
    if ($.isEmptyObject(auxFormat)) return;

    const auxAttrs = _.extend({}, options.attributes);
    let $element;
    if (!$.isEmptyObject(target) && target.isPivotedGrid) {
        $element = IX_ConditionalFormatApplyFormat_PivotedGrid(target, field);
    } else {
        if (target instanceof jQuery) {
            IX_ConditionalFormatApplyFormat_jQueryElement(target, field, auxFormat, auxAttrs);
            return;
        }
        const _getFieldClassName = (_field) => "." + _field;
        field = _getFieldClassName(field);
        $element = $("#" + target).find(field);
        if ($element.length > 1 && _.isInteger(rowIndex) && rowIndex >= 0)
            $element = $($element.get(rowIndex));
        if (!$element.length) {
            const buttonClass = ".ibtn_ICL" + field.replace(/[#_\.]/g, '').replace(/\|/g, "_");
            $element = $element.find(buttonClass);
        }
    }
    
    if ($element == undefined || !$element.length) return;

    let $container = $element.parents("table:first:not(.dx-datagrid-table):not(.dx-treelist-table)"); // table based layout

    // element in a table
    if (!$container.length && _.isInteger(rowIndex) && rowIndex >= 0) {
        const $cell = $element.parents("td");
        $container = $cell.children().first();
    }

    if (!$container.length) 
        $container = $element.parents("div.ic-field-wrapper").first(); // tableless layout

    if ($container.length) {
        if (!_.isEmpty(auxFormat.display)) {
            $container.css({ "display": auxFormat.display });
            delete auxFormat.display;
        }
        if (!_.isEmpty(auxFormat.visibility)) {
            $container.css({ "visibility": auxFormat.visibility });
            delete auxFormat.visibility;
        }
        if (!_.isEmpty(options.className))
            $container.parent().toggleClass(options.className.split(" "), true);
    }

    if (!$.isEmptyObject(auxFormat))
        $element.css(auxFormat);
    
    if (!$.isEmptyObject(auxAttrs))
        $element.attr(auxAttrs);
    
    // text-decoration in conditional formattting is applied if 
    // we select disable button click checkbox
    const textDecoration = auxFormat['text-decoration'];
    if (field.includes('CL_Btn_') && textDecoration) {
        textDecoration == 'none' ? $element.attr('aria-disabled', true) : $element.removeAttr('aria-disabled');
    }
}

function IX_UnApplyConditionalFormatCssClass(target, field, options) {
    if (options.className === "") {
        return;
    }

    var _getFieldClassName = function (_field) {
        return "." + _field;
    };

    var element, container;
    if (!$.isEmptyObject(target) && target.isPivotedGrid) {
        element = IX_ConditionalFormatApplyFormat_PivotedGrid(target, field);
    } else {
        if (target instanceof jQuery) {
            element = target;
        } else {
            field = _getFieldClassName(field);
            element = $("#" + target).find(field);
            if (!element.length) {
                element = element.find(".ibtn_ICL" + field.replace(/[#_\.]/g, '').replace(/\|/g, "_"));
            }
        }
    }
    if (element != undefined) {
        if (element.length) {
            container = element.parents("table:first:not(.dx-datagrid-table):not(.dx-treelist-table)"); // table based layout
            if (!container.length) {
                container = element.parents("div.ic-field-wrapper:first"); // tableless layout
            }
            if (container.length && options.className) {
                container.parent().toggleClass(options.className.split(" "), false);
            }
        }
    }
}

// Sets a CSS class on the main overlay element of the popup dialog.
function IX_PopUpOnShownAddClass(e) {
    // Adds the class set in the TE to the overlay element on the dialog.
    if (e != null &&
        e.model != null &&
        e.model.config != null &&
        e.model.config.popup != null) {
        var screenName = e.component._options.screenName;
        e.component.$content().parent().addClass(e.model.config.popup.cssClass || screenName || "");
        /* Fixes ADA bug where H navigation fails on headers. */
        IX_SetAdaHeaderMarkup(e.component.$content());
    }
}

function IX_Log() {
    if (_.isUndefined(arguments) || arguments.length <= 1 ||
        _.isUndefined(console) || !_.isFunction(console.log))
        return;

    var args = Array.from(arguments);

    if (args.length > 1 && args[1] === 'showTrace' && console.trace && typeof IX_DEBUG_SETTINGS[args[0]] != 'undefined' && IX_DEBUG_SETTINGS[args[0]].debug) {
        console.trace();
        args.splice(1,1);
    }

    var objStr = arguments[0];
    if (typeof objStr == 'string' && objStr != "") {
        if (typeof IX_DEBUG_SETTINGS[objStr] != 'undefined' &&
            IX_DEBUG_SETTINGS[objStr].debug &&
            console && _.isFunction(console.log)) {
            var css = 'background: lightblue; color: black';
            try {
                console.log('%c%s [', css, objStr);

                if (args.length) {
                    args.splice(0, 1);
                }

                for (var i = 0; i < args.length; i++) {
                    console.log('%c [%s]>', css, i + 1, args[i]);
                }

                console.log('%c%s ];', css, objStr);
            } catch (ex) {
                console.warn && console.warn("exception when logging: " + ex);
            }
        }
    }
}

function IX_InBecomeUserMode() {
    return iXing.IX_BU == true;
}

function IX_SetSocialSharingButtons(windowPass) {
    var socialMediaDiv = $('<div>').addClass('addthis_toolbox addthis_default_style addthis_32x32_style');
    var socialMediaCommaSep = '';
    var socialMedia;
    var divAppend;
    var x = windowPass.IX_Theme.properties.filter(function(e) {
        return e.Value1 === 'Social_Media'
    });

    if (x.length > 0) {
        socialMediaCommaSep = x[0].Value2;
        socialMedia = x[0].Value2.split(',');
        divAppend = windowPass.IX_Theme.properties.filter(function(e) {
            return e.Value1 === 'Social_Media_div'
        });
    }
    return {'div': socialMediaDiv, 'vals': socialMediaCommaSep, 'divAppend': 'Social_Media_div'};
}

(function () {
    if (typeof window.IX_DEBUG_SETTINGS == 'undefined') {
        window.IX_DEBUG_SETTINGS = {};
        window.IX_DEBUG_SETTINGS.fieldMapper = { debug: false, allFields: false, fieldToWatch: "" };
        window.IX_DEBUG_SETTINGS.cmdLst = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.appGrph = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.fldFrmt = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.apiCli = { debug: false, verbose: false, log: false };
        window.IX_DEBUG_SETTINGS.component = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.fxLib = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.clientSvc = { debug: false, verbose: false, logTable: function() { return "logTable no-op"; }, outputData: function() { return "outputData no-op"; }, saveFile: function() { return "saveFile no-op"; } };
        window.IX_DEBUG_SETTINGS.sdkSvc = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.applet = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.canvas = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.dynamic = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.iframeResizer = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.validationGroup = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.webhook = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.gridPrint = { debug: false, verbose: false };
        window.IX_DEBUG_SETTINGS.tabs = { debug: false };

        window.IX_DEBUG_SETTINGS.enableAll = function (verbose) {
            window.IX_DEBUG_SETTINGS.fieldMapper = { debug: true, allFields: true, fieldToWatch: "" };
            window.IX_DEBUG_SETTINGS.cmdLst = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.appGrph = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.fldFrmt = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.apiCli = { debug: true, verbose: verbose, log: false };
            window.IX_DEBUG_SETTINGS.component = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.fxLib = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.clientSvc = { debug: true, verbose: verbose, logTable: function() { return "logTable no-op"; }, outputData: function() { return "outputData no-op"; }, saveFile: function() { return "saveFile no-op"; } };
            window.IX_DEBUG_SETTINGS.sdkSvc = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.applet = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.canvas = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.dynamic = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.iframeResizer = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.validationGroup = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.webhook = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.gridPrint = { debug: true, verbose: verbose };
            window.IX_DEBUG_SETTINGS.tabs = { debug: true }
        };
    }
}());

// Feature detection of scrollbars
// -------------------------------

window.IX_AreNativeScrollbarsVisible = true;

function IX_ForceShowScrollbars(element) {
    var forceShowScrollbars = !IX_IsThemePropertyValue1Falsey("ForceShowScrollbars", false);
    if (forceShowScrollbars) {
        // for IE we we give native presedence otherwise it shows both native and dx scrollbars
        var scrollConfig = {
            useNative: true
        };
        setTimeout(() => {
            var dxScroll = $(element).find('.dx-scrollview');
            if (dxScroll.length) {
                dxScroll.dxScrollView(scrollConfig);
            } else {
                dxScroll = $(element).find('.dx-scrollable');
                dxScroll.dxScrollable(scrollConfig);
            }
        }, 200);
    }
}

function IX_OnShownModalDialogSetUpADA(dialogElement, dxPopupComponent) {
    /* If the popup shows up and there's an element having the ic-ada-focusonshown class that
     * element is automatically focused on shown. */
    var focusOnShown = dialogElement.find(".ic-ada-focusonshown");
    if (focusOnShown.length == 0) {
        /* If no ic-ada-focusonshown is found then try to locate the default devextreme "X" close button. */
        if (dxPopupComponent && dxPopupComponent._$title) {
            focusOnShown = dxPopupComponent._$title.find('.dx-closebutton').first();
        }
    }

    /* If a default focus item is found then the following sets the focus. */
    if (focusOnShown.length > 0) {
        focusOnShown = $(focusOnShown.get(0));
        focusOnShown.attr("tabindex", "0");
        focusOnShown.focus();
    }

    //To remore the empty tab between the two close buttons.
    $('.dx-overlay-content').attr('tabindex', '-1');

    /* Removes the toolbar role as described by
       https://investcloud.visualstudio.com/Portfolio/_workitems/edit/15279 */
    dialogElement.find("[role=toolbar]").removeAttr("role");

    var popupContentElement = !!dxPopupComponent && !!dxPopupComponent.$content() ? dxPopupComponent.$content() : null;
    setTimeout(function () {
        /* Aria scrollable element */
        var ariaScrollableElement = $(".ic-scrollable-popup .dx-scrollable-container").first();
        ariaScrollableElement.attr("tabindex", "0");
        ariaScrollableElement.addClass("ic-focus-indicator");
        ariaScrollableElement.attr("aria-label", "Scrollable content.");
        if (!!popupContentElement) {
            IX_ForceShowScrollbars(popupContentElement);
        }
    }, 300);
}

function IX_OnHiddenModalDialogSetUpADA(modalTriggeringElm) {
    // Convert to jQ object if not already, .find is a jq method. (65260)
    if (!(modalTriggeringElm instanceof $))
        modalTriggeringElm = $(modalTriggeringElm);

    //to set the focus back on the element that triggered the modal pop up launch (For ADA pursposes)
    if (modalTriggeringElm) {
        var tabStopEle = modalTriggeringElm.find("[tabindex='0']");
        if (tabStopEle.length && modalTriggeringElm[0].tagName != "BODY")
            tabStopEle.focus();
        else
            modalTriggeringElm.focus();
    }
}

function IX_UpdateAppWrapperHTMLAttributes(applet, iElement) {
    var appName = applet.name ? applet.name : (applet.rid || "CL_WrongAppletConfig_0").split('_')[1],
        appClass = iXing.Helpers.getAppClassName(appName),
        element = $(iElement);

    element.find(".dx-datagrid div[role='menu']").attr("aria-hidden", "true");
    element.find(".dx-treelist div[role='menu']").attr("aria-hidden", "true");
    element.addClass(appClass);
}

function IX_AnnounceText(message, lowTimeout) {
    if (!window.textQueue) {
        window.textQueue = [];
        window.activeSpeak = false;
    }
    if (message) {
        window.textQueue.push(message);
    }
    if ((window.textQueue.length > 0) && (window.activeSpeak === false)) {
        window.activeSpeak = true;

        var ariaLiveElement = $("#IX_AnnounceTextAnnouncedId");
        ariaLiveElement.attr('aria-hidden', null);
        // if not next to body or nested in a aria-hidden element move to top
        if (
            ariaLiveElement.parent('body').length === 0 ||
            ariaLiveElement.closest('[aria-hidden="true"]').length > 0
        ) {
            ariaLiveElement.detach().prependTo('body');
        }

        var _delay = !!lowTimeout ? 50 : 1000;
        setTimeout(function () {
            if (window.textQueue && window.textQueue[0]) {
                var announce = window.textQueue[0].text;
                ariaLiveElement.text("");
                ariaLiveElement.text(announce);
                window.textQueue.splice(0, 1);
                window.activeSpeak = false;
                IX_AnnounceText(null, lowTimeout);
            }
        }, _delay);
    }
}

function IX_setFocusToElementByClassName(className) {

    var classToFocus, classToFocusObj;
    if (className && typeof className == "string") {
        classToFocus = className;
    } else {

        //To check if the className is being passed on throught the themeProperties to be focused upon redirect
        classToFocusObj = _.find(window.IX_Theme.properties, function (o) {
            return o.PropertyName == 'CssClassToFocusAfterRedirect';
        });
        if (classToFocusObj) {
            classToFocus = classToFocusObj.Value1;
        }

    }
    if (!_.isEmpty(classToFocus)) {

        //Need to wait for the dom element to completely render
        //before setting the focus on it. hence using the timeout.
        //Possible alternate solution using "window.requestAnimationFrame()"

        var timerVar = 0;
        //this code keeps checking for the element to show up every 1 second and gives up after 5 seconds VSTS 9914
        var checkEleExists = setInterval(function () {
            if (timerVar > 3) {
                clearInterval(checkEleExists);
            }
            if ($('.' + classToFocus).length) {
                $('.' + classToFocus).attr('tabindex', '0');
                $('.' + classToFocus).focus();
                clearInterval(checkEleExists);
            }
            timerVar++;
        }, 1000);
    }

}

//To make sure the next tabbed element is visible on the viewport
//Need to make sure this happens for ADA purposes
$(window).bind('load', function () {
    IX_AddBodyAttributesDefinedByMobileApplication();

    $(document.body).on('keyup', function (e) {
        //Loops through all parent nodes of an element to get it's distance from the top of the document
        function getDistanceFromTop(element) {
            var yPos = 0;

            while (element) {
                yPos += (element.offsetTop);
                element = element.offsetParent;
            }
            return yPos;
        }

        var activeElement = window.document.activeElement;

        if ($('body')[0] === activeElement) {
            // when a focus element is removed from the DOM the focus element defaults to body
            return;
        }

        //To make sure the next tabbed element is visible within the viewport and the not hidden under the header/footer
        if (e.keyCode == 9 && e.shiftKey) {
            var scrolledDistanceTop = $(window).scrollTop();
            var distanceToTop = $(activeElement).offset().top;
            var headerHeight = 75;
            if ((distanceToTop - scrolledDistanceTop) < headerHeight) {
                window.scrollTo(0, $(activeElement).offset().top - $(window).height() / 2);
            }
        }

        //To make sure the next tabbed element is visible within the viewport and the not hidden under the header/footer - while tabbing back
        if (e.keyCode == 9 && !e.shiftKey) {

            var pageHeight = Math.max($("body").height(), $("html").height());
            var scrolledDistanceBot = pageHeight - ($(window).scrollTop() + $(window).height());
            var distanceToBottom = pageHeight - activeElement.offsetHeight - getDistanceFromTop(activeElement);
            var footerHeight = 88;
            if ((distanceToBottom - scrolledDistanceBot) < footerHeight) {
                window.scrollTo(0, $(activeElement).offset().top - $(window).height() / 2);

                //to cover for the case of sticky elements getting position changed after scrolling and hence losing focus
                setTimeout(function () {
                    $(activeElement).focus();
                }, 0);
            }
        }
    });
});

function IX_AddBodyAttributesDefinedByMobileApplication() {
    var deviceOS = IX_GetCookieValue("IX_DeviceOS");
    var deviceType = IX_GetCookieValue("IX_DeviceType");
    var specificDevice = IX_GetCookieValue("IX_SpecificDevice");
    var deviceResolution = IX_GetCookieValue("IX_DeviceResolution");
    var deviceBrowser = IX_GetCookieValue("IX_DeviceBrowser");
    var isMobile = IX_isMobile() === true;
    var body = $("body");

    if (!_.isEmpty(deviceOS))
        body.attr("data-device-os", deviceOS);
    if (!_.isEmpty(deviceType))
        body.attr("data-device-type", deviceType);
    if (!_.isEmpty(specificDevice))
        body.attr("data-specific-device", specificDevice);
    if (!_.isEmpty(deviceResolution))
        body.attr("data-device-resolution", deviceResolution);
    if (!_.isEmpty(deviceBrowser))
        body.attr("data-device-browser", deviceBrowser);

    body.attr("data-is-mobile", isMobile)
}

//Helper function to attach the loading announce text to the loadingChanged events of the list app datasource
function IX_BindAnnounceTextToLoadngState(scope) {
    if (scope.gridProperties && scope.gridProperties.dataSource && scope.lodngEndAnnTxt && scope.lodngStrtAnnTxt) {
        scope.gridProperties.dataSource.on({
            'loadingChanged': function () { IX_AnnounceText({ text: scope.lodngStrtAnnTxt }); },
            'changed': function () { IX_AnnounceText({ text: scope.lodngEndAnnTxt }); },
            'loadError': function () { IX_AnnounceText({ text: "error loading" }); }
        });
    }
}

function IX_SetAdaHeaderMarkup(popUpjQueryObject) {
    var tags = ["h1", "h2", "h3"];
    var $tags = [];
    tags.forEach(function (tag) {
        var $tag = popUpjQueryObject.find(tag).first();
        $tag.attr("tabindex", -1);
        $tags.push($tag);
    });
    $('.dx-closebutton').first().keypress(function (e) {
        if (e.charCode === 72 || e.charCode === 104) {
            $tags.forEach(function ($tag) {
                $tag.focus();
            });
        }
    });
}

function IX_isSafari() {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return isSafari;
}

function IX_openOutsideMobileApp(source, event) {
    event?.preventDefault();
    source = _.isString(source) ? source : source.href;
    window.open(source, "_system");
    return false;
}

function IX_isWebView() {
    var userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios && !safari)
        return true; // IOS WebView
    else if (navigator.userAgent.includes('wv'))
        return true; // Android WebView
    else
        return false; // Probably not WebView
}

function IX_ApplyDeviceStyles($scope) {
    if ($scope.deviceStyle == null)
        return;

    var applyStyle = function (button, deviceStyle) {
        if (!_.isEmpty(deviceStyle.visibility) && deviceStyle.visibility.toLowerCase() == "hiddendonotreservespace") {
            button.visible = false;
        }
    };

    for (var buttonSequenceName in $scope.buttons) {
        var button = $scope.buttons[buttonSequenceName];
        var deviceStyle = $scope.deviceStyle[button.fieldName];
        if (deviceStyle == null)
            continue;
        if (_.isEmpty(deviceStyle.device))
            continue;

        switch (deviceStyle.device.toLowerCase()) {
            case "tablet":
                if (IX_isAndroid())
                    applyStyle(button, deviceStyle);

                break;
            case "mobile":
                if (IX_isAndroid() || IX_isIOS())
                    applyStyle(button, deviceStyle);

                break;
            case "ipad":
                if (IX_isIOS())
                    applyStyle(button, deviceStyle);

                break;
        }
    }
}

function IX_GetFieldValueForTest(app, field) {
    if (typeof angular == 'undefined' || typeof $ == 'undefined') return 'NO-NG-$';

    var $a = $("[ng-view]"); if (typeof $a == 'undefined') return 'NO-NG-VIEW';
    var $i = $a.injector(); if (typeof $i == 'undefined') return 'NO-INJECTOR';
    var $t = $i.get('stateService'); if (typeof $i == 'undefined') return 'NO-STATE-SERVICE';
    var c = $t.getContext(); if (typeof c == 'undefined') return 'NO-CONTEXT';

    if (typeof c[app] == 'undefined') return 'NO-APP-' + app + '-IN_CONTEXT';
    if (typeof c[app][field] == 'undefined') return 'No value found for ' + app + '-' + field;
    return c[app][field];
}

function IX_FixAlternatingRows(dataGrid) {
    var dataGridRows = dataGrid.getVisibleRows();
    var parentRowClass = "ic-alt-row";

    for (var i = 0; i < dataGridRows.length; i++) {
        var currentDataGridRow = dataGridRows[i];
        var currentRowElement = dataGrid.getRowElement(currentDataGridRow.rowIndex);

        if (currentDataGridRow.rowType == "data") {
            if (parentRowClass == "ic-alt-row") {
                parentRowClass = "ic-primary-row";
            }
            else {
                parentRowClass = "ic-alt-row";
            }
        }

        $(currentRowElement).addClass(parentRowClass);
    }
}

// Drop-in fix for Highcharts issue #8477 on older Highcharts versions. The
// issue is fixed since Highcharts v6.1.1.
if (typeof Highcharts != 'undefined') {
    Highcharts.wrap(Highcharts.Axis.prototype, 'getPlotLinePath', function (proceed) {
        var path = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        if (path) {
            path.flat = false;
        }
        return path;
    });
}

/**
 * Enables disables history.scrollRestoration and provides a synthetic approach
 * which allows for a period of time to wait for screen to reach a scrollable size
 * before issuing a scroll.
 * 
 * @param {object} $scope holder app $scope
 * @param {object} $window $window object
 * @param {string} mode auto, manual, synthetic
 * @param {string} deviceSpecific optional: ios_safari
 */
function IX_ScrollRestoration($scope, $window, mode, deviceSpecific) {

    if (!!deviceSpecific && deviceSpecific === 'ios_safari' 
        && (!IX_isIOS() || !IX_isSafari())
    ) {
        return;
    }

    function setHistoryScrollRestoration(mode) {
        if (!!history && !!history.scrollRestoration) { // browser support
            history.scrollRestoration = mode;
        } 
    }

    if (mode !== 'synthetic') {
        setHistoryScrollRestoration(mode); 
    } else {
        // this codepath is also a workaround for ios safari back button div clipping issue in safari 13.1
        setHistoryScrollRestoration('manual'); 
        var appName = $scope.applet.name.toLowerCase().replace(/\./g,'');
        function storePosition(appName) {
            var scrollPosition = $(document).scrollTop();
            sessionStorage.setItem("IX_scrollPosition_" + appName, scrollPosition.toString());
        }
        $window.addEventListener('beforeunload', function (e) {
            storePosition(appName);
        });
        // $scope.locationChangeStartOff = $scope.$on('$locationChangeStart', function (event, next, current) {
        //     if (current.indexOf(appName) >= 0 ) {
        //         storePosition(appName);
        //     }
        // });
        if (sessionStorage["IX_scrollPosition_" + appName]) {
            var scrollPosition = sessionStorage.getItem("IX_scrollPosition_" + appName);
            sessionStorage.removeItem("IX_scrollPosition_" + appName);
            var startTime = new Date();
            var timeoutMs = 30000; // 30 secs
            var intervalMs = 1000; // 1 secs
            var wait = setInterval(function() {
                var currentHeight = $(document).height();
                if (currentHeight >= scrollPosition || new Date() - startTime > timeoutMs) {
                    $(document).scrollTop(scrollPosition);
                    clearInterval(wait);
                }                        
            }, intervalMs);
        }
        // $scope.$on('$destroy', function(){ 
        //     if (typeof $scope.locationChangeStartOff != 'undefined'){ 
        //         $scope.locationChangeStartOff(); 
        //     } 
        // });
    }
}

function IX_GetThemeProperty(propName) {
    if (!propName || !window.IX_Theme || !window.IX_Theme.properties) {
        return null;
    }
    return _.find(window.IX_Theme.properties, function (c) {
        return c.PropertyName.toLowerCase() === propName.toLowerCase();
    });
};

function IX_GetThemePropertyValue1(propName, defaultValue) {
    var property = IX_GetThemeProperty(propName);
    return !!property && !!property.Value1 ? property.Value1 : defaultValue;
};

function IX_GetThemePropertyValue2(propName, defaultValue) {
    var property = IX_GetThemeProperty(propName);
    return !!property && !!property.Value2 ? property.Value2 : defaultValue;
};

function IX_IsThemePropertyValue1Falsey(propName, defaultValue) {
    var value1 = IX_GetThemePropertyValue1(propName, defaultValue);
    value1 = typeof value1 !== "string" ? value1.toString() : value1;
    value1 = !!value1 ? value1.toLowerCase() : "";
    return value1.indexOf('false') === 0 ||
        value1.indexOf('no') === 0 ||
        value1.indexOf('disabled') === 0;
};


// PRINT GRID
var icClassPrintStyles           = 'ic-print-custom-styles';
var icClassPrintGridSection      = 'ic-print-grid-section';
var icClassPrintTable            = 'ic-print-table-gr{gridIdx}-sec{secIdx}-tb{tblIdx}';
var icClassPrintTableFixed       = 'ic-print-table-fixed';
var icClassPrintTableScrollable  = 'ic-print-table-scrollable';
var icClassPrintTableScreenInPct = 'ic-print-table-screen-in-pct';
var icClassPrintFreespaceRow     = 'ic-print-freespace-row-gr{gridIdx}-sec{secIdx}-tb{tblIdx}';
var icClassPrintOmitCol          = 'ic-print-col-omit';
var icClassPrintViewCol          = 'ic-print-col-gr{gridIdx}-sec{secIdx}-tb{tblIdx}-col{colIdx}';
var icClassPrintRowHeight        = 'ic-print-row-height-{height}';
var icClassPrintHideCell         = 'ic-print-hide-cell';
var printColOptimization = true;
var printRowOptimization = true;

// logging for debugging, uncomment this and toggle a log
// IX_DEBUG_SETTINGS.gridPrint.debug = true;
var logMediaStyle = false;
var logBeforePrintGrid = false;
var logAfterPrintGrid = false;
var logRowHeights = false;
var logColumCalculation = false;
var logGeneratedStyleToBody = false;
// var logTimers = true;

function setUpMediaStyle() {
    $("#" + icClassPrintStyles).remove();
    var style = document.createElement('style');
    style.media = "print";
    style.id = icClassPrintStyles;
    style.type = 'text/css';
    document.head.appendChild(style);
}

function writeToMediaStyle(printStyles) {
    var style = document.getElementById(icClassPrintStyles);
    if (!style) { // reset or make
        setUpMediaStyle();
        style = document.getElementById(icClassPrintStyles);
    } else if (printStyles.length > 0) {
        // cycle through incoming styles 
        var newStyles = printStyles.split(/\n/);
        var existing = $(style).text();
        if (existing.length > 0) {
            var replacementMade = false;
            // find them in existing and remove them from existing
            for (var i=0;i<newStyles.length;i++) {
                var newStyle = newStyles[i];
                if (newStyle.length > 0) {
                    var match = newStyle.trim().split(' ')[0].replace('.',''); // .match(/\.([^\{]*) \{[^\}]*\}/i);
                    if (!!match && match.length > 0) {
                        // remove existing styles that match
                        var re = new RegExp('\.' + match + ' [^\}]*\}\n',"g");
                        existing = existing.replace(re, '');
                        replacementMade = true;
                    }
                }
            }; 
            if (replacementMade) {
                $(style).text(existing);
            }
        }
    }

    // var css = "@media print {\n" + printStyles + "}";
    var css = printStyles || '';
    style.appendChild(document.createTextNode(css));
    if (logMediaStyle) icPrintGridLogging(css);
}

function icPrintGridMakeClass(selector, style) {
    return "." + selector + " { " + style + " }\n";
}

function icPrintGridGetClasses() {
    var currentStyles = '';
    var style = document.getElementById(icClassPrintStyles);
    if (style) {
        currentStyles = $(style).text();
    }
    return currentStyles;
}

function icPrintGridLogging() {
    var message = '';
    var args = Array.from(arguments);
    if (args.length) {
        message = args[0];
        for (var i = 1; i < args.length; i++) {
            message = message.replace('%s', args[i]);
        }
    }
    IX_Log('gridPrint', message);
    // console.log(message); // displays more condensed
}

function beforePrintGridProcess() {
    $('.' + icClassPrintHideCell).removeClass(icClassPrintHideCell);
    if (getInternetExplorerVersion() > 0) {
        $('.IXResponsiveBody').addClass('ic-internet-explorer');
    }
    if (logBeforePrintGrid) icPrintGridLogging('beforePrintGridProcess()');
    // if (logTimers) console.time('beforePrintGridProcess()');
    // reset
    setUpMediaStyle();

    var printStyles = '';
    // one time classes
    writeToMediaStyle(icPrintGridMakeClass(icClassPrintOmitCol,'width: 0px !important;'));
    if (getInternetExplorerVersion() > 0) {
        writeToMediaStyle(icPrintGridMakeClass(icClassPrintHideCell,'white-space: nowrap !important; overflow: hidden !important; padding-left: 0px !important; padding-right: 0px !important; visibility: hidden; width: 0px; text-overflow: clip !important;'));
    } else {
        writeToMediaStyle(icPrintGridMakeClass(icClassPrintHideCell,'white-space: nowrap !important; overflow: hidden !important; padding: 0px !important; visibility: hidden; width: 0px; text-overflow: clip !important;'));  
    }

    if (logBeforePrintGrid) icPrintGridLogging("Device Pixe Ratio = %s for Screen", window.devicePixelRatio);

    var grids = $('div.dx-datagrid');
    $(grids).each(function eachGridBefore(gridIdx) {
        var thisGrid = this;
        var gridRect = thisGrid.getBoundingClientRect();
        var icWidth = icPrintGetWidth(this);
        var screenGridWidth = icWidth.width;
        var screenGridWidthInPixels = icWidth.isPx;

        // cycle through grid sections (header, body, footer, etc)
        var sections = $(this).children('div');
        $(sections).each(function eachSectionBefore(secIdx) {
            var tables = $('table', this);
            if(tables.length == 0) {
                // exit if this section doesn't have a table
                return true;
            }

            if (logBeforePrintGrid) {
                icPrintGridLogging('screenGridWidth %s bodyWidth %s, in pix = %s, offsetWidth %s, boundingRec{width: %s, left: %s}', 
                    screenGridWidth, $('body').width(), screenGridWidthInPixels, 
                    this.offsetWidth, thisGrid.getBoundingClientRect().width, thisGrid.getBoundingClientRect().left);
            }
            if (logBeforePrintGrid) icPrintGridLogging('this icGrid %s', thisGrid.className);

            // helps us find fixed column table siblings
            icPrintAddClass(this, icClassPrintGridSection)

            // cycle through tables
            $(tables).each(function eachTableBefore(tblIdx) {
                var isTableVisible = $(this).is(":visible");
                if (!isTableVisible) {
                    // don't process invisible tables
                    return true;
                }

                // tag fixed columns table
                var isFixedColumnsTable = $(this).parent().hasClass('dx-datagrid-content-fixed');
                if (isFixedColumnsTable) {
                    icPrintAddClass(this, icClassPrintTableFixed);
                } else {
                    icPrintAddClass(this, icClassPrintTableScrollable);
                }

                var tblClass = icClassPrintTable
                    .replace('{gridIdx}', gridIdx)
                    .replace('{secIdx}', secIdx)
                    .replace('{tblIdx}', tblIdx);
                icPrintAddClass(this, tblClass);
                $(this).attr('ic-print-tbl-idx', tblIdx);
                if (logBeforePrintGrid) icPrintGridLogging('screen table %s', tblClass);

            });

            if (keepScreenWidths()) {
                return true;
            }

            var tblProfile = {
                gridIdx: gridIdx, 
                secIdx: secIdx, 
                tblIdx: null, 
                gridWidth: screenGridWidth, 
                gridRect: gridRect,
                screenGridWidthInPixels: screenGridWidthInPixels,
                omitOffScreenCols: true, 
                visibleColumnCount: 0,
            };
            // try to process scrollable table first
            tables = $('table.' + icClassPrintTableScrollable, this);
            if (tables.length > 0) {
                processColumnRatios(tables[0], thisGrid, tblProfile);
                alleviatePageClipping(tables[0], tblProfile);
            }
            tables = $('table.' + icClassPrintTableFixed, this);
            if (tables.length > 0) {
                processColumnRatios(tables[0], thisGrid, tblProfile);
                alleviatePageClipping(tables[0], tblProfile);
            }
            // else any other tables
            tables = $('table:not(.' + icClassPrintTableFixed + '):not(.' + icClassPrintTableScrollable + ')', this);
            if (tables.length > 0) {
                $(tables).each(function eachNonFixNonScrollTable(tblIdx) {
                    var thisTable = this;
                    var exitLoop = processColumnRatios(thisTable, thisGrid, tblProfile);
                    alleviatePageClipping(thisTable, tblProfile);
                    if (exitLoop) {
                        return true;
                    }
                })
            }

            function alleviatePageClipping (thisTable, tblProfile) {
                // The browser's print engine calculates the number of pages needed before we adjust widths and
                // heights.  This code adds an estimated height to the last row of the grid to prepare the print
                // engine for extra pages.
                if (getInternetExplorerVersion() < 0) {
                    var rows = $('tr', thisTable);
                    // This next calculation attempts to anticipate column scrunching (taking up more height) due to wider screen than page.
                    //   How: find a ratio based on how many columns are visible in "excess" and multiply it by rows.height.
                    //   The pixelsPerRow formula seems to be mitigating most clipping of last grid(s) depending on the size of print job.
                    //   This method gets called after processColumnRatios because it gets a count of visible columns.
                    var standardColCount = 5;
                    var excessCols = Math.max(tblProfile.visibleColumnCount - standardColCount, 0);
                    var pixelsPerRow = 3 + (excessCols * 2);
                    var lastRowHeight = rows.length * pixelsPerRow;
                    var bigRowClass = icClassPrintFreespaceRow
                        .replace('{gridIdx}', tblProfile.gridIdx)
                        .replace('{secIdx}', tblProfile.secIdx)
                        .replace('{tblIdx}', tblProfile.tblIdx);
                    writeToMediaStyle(icPrintGridMakeClass(bigRowClass, 'display: block !important; height: ' + lastRowHeight + 'px !important; page-break-inside: avoid;'));

                    $(thisTable).find('.dx-freespace-row').addClass(bigRowClass);
                }
            };

            function processColumnRatios (thisTable, thisGrid, tblProfile) {
                if (!tblProfile.screenGridWidthInPixels) {
                    icPrintAddClass(thisTable, icClassPrintTableScreenInPct);
                    // no column ratios to calculate because grid is already in %
                    return true;
                }
                tblProfile.tblIdx = $(thisTable).attr('ic-print-tbl-idx');
                var exitLoop = columnWidthsToPercentages(thisTable, thisGrid, tblProfile);
                if (exitLoop) {
                    // always return true to continue parent loop
                    return true;
                }
            };

        });

    });

    writeToMediaStyle(printStyles);
    // if (logTimers) console.timeEnd('beforePrintGridProcess()');
}

function afterPrintGridProcess() {
    // if (logTimers) console.time('afterPrintGridProcess()');
    if (logAfterPrintGrid) icPrintGridLogging("afterPrintGridProcess()");

    var printStyles = '';
    var rowHeightsStyleCatalog = [];
    var bodyWidth = icPrintGetWidth($('body')).width; // print media width

    if (logAfterPrintGrid) icPrintGridLogging("Device Pixel Ratio = %s for Print", window.devicePixelRatio);

    var grids = $('div.dx-datagrid');
    $(grids).each(function eachGridAfter(gridIdx) {
        var thisGrid = this;
        var gridRect = thisGrid.getBoundingClientRect();

        if (!canDetectPrintMediaDimensions()) {
            // Some browsers give us no opportunity to detect the boundingRect of the print media
            var gridX = gridRect.x;
            var gridY = gridRect.y;

            // Redefine width to default
            var gridWidth = printAllVisibleScrollableAndFixedColumnsDefaultWidth();
            var gridHeight = gridRect.height;
            var gridTop = gridRect.top;

            // Redefine right to width of grid
            var gridRight = gridWidth;
            var gridBottom = gridRect.bottom;

            // Redefine left to edge of container
            var gridLeft = 0;

            gridRect = {
                x: gridX,
                y: gridY,
                width: gridWidth,
                height: gridHeight,
                top: gridTop,
                right: gridRight,
                bottom: gridBottom,
                left: gridLeft,
            };
        }

        var icWidth = icPrintGetWidth(thisGrid);
        var printGridWidth = icWidth.width;
        var printGridWidthInPixels = icWidth.isPx;
        var atLeastOneFixedGrid = false;

        var sections = $(thisGrid).children('div');
        $(sections).each(function eachSectionAfter(secIdx) {
            var thisSec = this;
            var tables = $('table', this);
            if(tables.length == 0) {
                // exit if this section doesn't have a table
                return true;
            }

            if (logAfterPrintGrid) icPrintGridLogging('printGridWidth %s bodyWidth %s, in pix = %s, offsetWidth %s, boundingRec{width: %s, left: %s, height: %s}', 
                printGridWidth, bodyWidth, printGridWidthInPixels,
                thisGrid.offsetWidth, thisGrid.getBoundingClientRect().width, thisGrid.getBoundingClientRect().left, thisGrid.getBoundingClientRect().height);
            if (logAfterPrintGrid) icPrintGridLogging('this icGrid %s', thisGrid.className);

            var tableStyles = '';
            // cycle through tables to tag fix column tables
            $(tables).each(function eachTableAfter(tblIdx) {
                var isTableVisible = $(this).is(":visible");
                if (!isTableVisible) {
                    // don't process invisible tables
                    return true;
                }

                var screenGridWasInPercent = $(this).hasClass(icClassPrintTableScreenInPct);

                // tag fixed columns table
                var isFixedColumnsTable = $(this).parent().hasClass('dx-datagrid-content-fixed');
                if (isFixedColumnsTable) {
                    atLeastOneFixedGrid = true;
                }

                // Remove added height after page numbers have been set by browser.
                if (getInternetExplorerVersion() < 0) {
                    var bigRowClass = icClassPrintFreespaceRow
                        .replace('{gridIdx}', gridIdx)
                        .replace('{secIdx}', secIdx)
                        .replace('{tblIdx}', tblIdx);
                    icPrintRemoveClass($(this).find('.dx-freespace-row')[0], bigRowClass);
                }

                if (keepScreenWidths()) {
                    return true;
                }

                // set print media table width
                var tblClass = icClassPrintTable
                        .replace('{gridIdx}', gridIdx)
                        .replace('{secIdx}', secIdx)
                        .replace('{tblIdx}', tblIdx);

                var widthStyle = '';
                if (printGridWidthInPixels && !screenGridWasInPercent) {
                    widthStyle = canDetectPrintMediaDimensions() ? 'width: ' + printGridWidth + 'px !important;' : 'width: 100% !important;';
                    if (logAfterPrintGrid) icPrintGridLogging('print table %s, is fixed column table = %s, new width style: "%s"', tblClass, isFixedColumnsTable, widthStyle);
                    tableStyles += icPrintGridMakeClass(tblClass, widthStyle);
                } else {
                    var targetWidth = screenGridWasInPercent ? bodyWidth : printGridWidth;
                    widthStyle = canDetectPrintMediaDimensions() ? 'width: ' + targetWidth + 'px !important;' : 'width: 100% !important;';
                    tableStyles += icPrintGridMakeClass(tblClass, widthStyle);
                    if (logAfterPrintGrid) icPrintGridLogging('print table %s, is fixed column table = %s, new width style: "%s"', tblClass, isFixedColumnsTable, widthStyle);
                    // possibly calculate if columns are in px but grid is in %
                    var tblProfile = {
                        gridIdx: gridIdx, 
                        secIdx: secIdx, 
                        tblIdx: tblIdx, 
                        gridWidth: targetWidth, 
                        gridRect: gridRect,
                        screenGridWidthInPixels: null,
                        omitOffScreenCols: false, 
                        visibleColumnCount: 0,
                    };
                    var exitLoop = columnWidthsToPercentages(this, thisGrid, tblProfile);
                    if (exitLoop) {
                        // always return true to continue parent loop
                        return true;
                    }
                }
            });

            if (tableStyles !== '') {
                // These must be saved before calculating row heights, since they will change row height
                writeToMediaStyle(tableStyles);
            }

            // cycle through to adjust row heights
            // - this needs a separate loop because they need to be tagged first
            if (atLeastOneFixedGrid == false) { 
                // don't loop unecessarily
                return true;
            }

            var rowDictionary = [];
            $('table', this).each(function eachTableAfter4Heights(tblIdx) {
                if ($(this).hasClass(icClassPrintTableFixed)) {
                    // find matching scroll table by going up to grid section then down to scrollable table
                    var fixedTable = this;
                    var scrollTable = $(this).closest('.' + icClassPrintGridSection).find('table.' + icClassPrintTableScrollable);
                    if (scrollTable.length > 0) {
                        var tblData = {
                            fixedTable: fixedTable,
                            scrollTable: scrollTable[0],
                            rows: [],
                        };

                        var rows = $('tr', fixedTable); // separated to see count
                        var scrollRows = $('tr', scrollTable);

                        // remove previous row heights to get original height readings
                        var currentClasses = icPrintGridGetClasses();
                        if (currentClasses.length > 0 && currentClasses.indexOf(icClassPrintRowHeight.split('{')[0]) >= 0) {
                            // reflow/repaint optimization technique: display none before doing nested element changes
                            var fixedDisplay = fixedTable.style.display;
                            var scrollDisplay = scrollTable[0].style.display;
                            if (fixedDisplay !== 'none') {
                                fixedTable.style.display = 'none';
                            }
                            if (scrollDisplay !== 'none') {
                                scrollTable[0].style.display = 'none';
                            }
                            $(rows).each(function eachRow(rowIdx) { 
                                var scrollRow = scrollRows[rowIdx];
                                icPrintRemoveClass(this, icClassPrintRowHeight);
                                icPrintRemoveClass(scrollRow, icClassPrintRowHeight);
                            });
                            if (fixedDisplay !== 'none') {
                                fixedTable.style.display = fixedDisplay;
                            }
                            if (scrollDisplay !== 'none') {
                                scrollTable[0].style.display = scrollDisplay;
                            }
                        }

                        // cycle through rows and get matching table row height
                        $(rows).each(function eachRow(rowIdx) {
                            var myHeight = icPrintGetHeight(this).height;
                            var scrollRow = scrollRows[rowIdx];
                            // icPrintGridLogging(' row text = %s', $(scrollRow).text());
                            var rowHeight = 0;                         
                            var scrollRowHeight = icPrintGetHeight(scrollRow).height;
                            if (logRowHeights) icPrintGridLogging('row %s, heights this %s, scroll %s', rowIdx, myHeight, scrollRowHeight);

                            // when fixed column is taller
                            if (scrollRowHeight < myHeight) {
                                // use fixed col row height
                                rowHeight = myHeight;
                            } else {
                                rowHeight = scrollRowHeight;
                            }

                            // correct derived float height by rounding to int and add 2px 
                            rowHeight = Number((rowHeight).toFixed(0)) + 2;

                            // add classes to rows
                            var rowClass = icClassPrintRowHeight.replace('{height}', rowHeight);
                            if (printRowOptimization) {
                                tblData.rows.push(rowClass);
                            } else {
                                icPrintAddClass(this, rowClass);
                                icPrintAddClass(scrollRow, rowClass);
                            }

                            if (logRowHeights) icPrintGridLogging('row %s height %s', rowClass, rowHeight);

                            // - add row height override to printStyles (only when necessary)
                            if (_.indexOf(rowHeightsStyleCatalog, rowHeight) < 0) {
                                rowHeightsStyleCatalog.push(rowHeight);
                                // :after content to keep fixed/scrollable rows synced in iOS browsers
                                if (IX_isIOS()) {
                                    var afterContent = "";
                                    afterContent = icPrintGridMakeClass(rowClass + ':after', 'content: " ";');
                                    printStyles += afterContent;
                                }
                                printStyles += icPrintGridMakeClass(rowClass,'height: ' + rowHeight + 'px !important; page-break-inside: avoid !important; page-break-after: auto !important;');
                            }
                        });

                        if (printRowOptimization) {
                            rowDictionary.push(tblData);
                        }
                    }
                }
            });

            // optimize reflow/repaint by adding row classes while hiding tables
            if (printRowOptimization) {
                for (var iTbl=0;iTbl<rowDictionary.length;iTbl++) {
                    var tblData = rowDictionary[iTbl];
                    // reflow/repaint optimization technique: display none before doing nested element changes
                    var fixedDisplay = tblData.fixedTable.style.display;
                    var scrollDisplay = tblData.scrollTable.style.display;
                    if (fixedDisplay !== 'none') {
                        tblData.fixedTable.style.display = 'none';
                    }
                    if (scrollDisplay !== 'none') {
                        tblData.scrollTable.style.display = 'none';
                    }
                    var fixedRows = $('tr', tblData.fixedTable); // separated to see count
                    var scrollRows = $('tr', tblData.scrollTable);
                    for (var iRow=0;iRow<tblData.rows.length;iRow++) {
                        var rowClass = tblData.rows[iRow];
                        var fixedRow = fixedRows[iRow];
                        var scrollRow = scrollRows[iRow];
                        icPrintAddClass(fixedRow, rowClass);
                        icPrintAddClass(scrollRow, rowClass);
                    }
                    if (fixedDisplay !== 'none') {
                        tblData.fixedTable.style.display = fixedDisplay;
                    }
                    if (scrollDisplay !== 'none') {
                        tblData.scrollTable.style.display = scrollDisplay;
                    }
                }
            }

        });

    });

    // for testing: show invisible cells
    // printStyles += icPrintGridMakeClass('dx-hidden-cell','visibility: visible !important;');

    // block out fixed columns backgrounds
    printStyles += icPrintGridMakeClass(icClassPrintTableFixed + ' td:not(.dx-last-cell)','background-color: #fff !important; background: #fff !important;');

    writeToMediaStyle(printStyles);

    outputStylesToBody();
    // if (logTimers) {
    //     console.timeEnd('afterPrintGridProcess()');
    //     console.log('print optimization col %s, row %s', printColOptimization, printRowOptimization);
    // }
}

function canDetectPrintMediaDimensions() {
    return !(IX_isSafari() || isBrowserFirefox() || isBrowserAppleChrome());
}

function keepScreenWidths() {
    if (canDetectPrintMediaDimensions()) {
        return false;
    }

    if (!IX_isIOS()) {
        return false;
    }

    var iPadPortraitPixelWidth = 768;
    // Avoid unnecessary transformation when screen width is already narrower than print width
    // If we transform width here, we risk changing the way content wraps in table cell elements,
    // which we won't be able to compensate for via row height adjustment, due to inability to detect.
    return document.body.clientWidth <= iPadPortraitPixelWidth;
}

/**
 * Only for debugging; especially where DOM is difficult to inspect (mobile)
 */
function outputStylesToBody() {
    if (logGeneratedStyleToBody) {
        $(document.body).append('<pre id="' + icClassPrintStyles + '_copy"></pre>');
        $('#' + icClassPrintStyles + '_copy').text($('#' + icClassPrintStyles).text());
    }
}

function columnWidthsToPercentages(thisTable, thisGrid, tblProfile) {
    var printStyles = '';
    var cols = $('colgroup col', thisTable);
    var tdData = [];
    // var timerName = 'optimization ' + printColOptimization + ' grid ' + gridIdx + ' sec ' + secIdx + ' tbl ' + tblIdx;
    // if (logTimers) console.time(timerName);

    // catalog td to tdData
    if (tblProfile.omitOffScreenCols) {
        tdData = findRowTdOverhangAndColspan(thisGrid, thisTable, tblProfile.gridRect);

        if (cols.length > tdData.length) {
            // we can't adjust for printing because we can't calc visible cols
            return true;
        }
    }
    var isFixedColumnsTable = $(thisTable).parent().hasClass('dx-datagrid-content-fixed');

    // reflow/repaint optimization technique: display none before doing nested element changes
    var tableDisplay = thisTable.style.display;
    if (printColOptimization && tableDisplay !== 'none') {
        thisTable.style.display = 'none';
    }

    var colDictionary = []
    var runningPercent = 0;
    // cycle through columns
    $(cols).each(function eachCol(colIdx) {
        var icWidth = icPrintGetWidth(this);
        var colWidth = icWidth.width;
        var isPx = icWidth.isPx;
        var originalWidthString = icWidth.originalWidth;
        var colModifier = {
            colInstance: this,
            removeClass: '',
            addClass: '',
        };

        if (logColumCalculation) icPrintGridLogging('col width %s, css width pixel: %s, offsetWidth %s, boundingRec{width: %s, left: %s}',
            colWidth, isPx, this.offsetWidth, this.getBoundingClientRect().width, this.getBoundingClientRect().left);

        var colClass = icClassPrintViewCol
            .replace('{gridIdx}', tblProfile.gridIdx)
            .replace('{secIdx}', tblProfile.secIdx)
            .replace('{tblIdx}', tblProfile.tblIdx)
            .replace('{colIdx}', colIdx);

        // if it overhangs by more than threshold omit it
        var overhangThresholdLeft = 0.7; // 70%
        var overhangThresholdRight = 0.4; // 40%
        var overhangPct = tblProfile.omitOffScreenCols ? tdData[colIdx] : { left: 0, right: 0, colspan: 1, colspanIdx: 0 };
        if ((overhangPct.left > overhangThresholdLeft || overhangPct.right > overhangThresholdRight) && !isFixedColumnsTable) {
            // the cols that are out of view = ic-print-col-omit
            if (printColOptimization) {
                colModifier.removeClass = colClass;
                colModifier.addClass = icClassPrintOmitCol;
            } else {
                icPrintRemoveClass(this, colClass);
                icPrintAddClass(this, icClassPrintOmitCol);
            }
            $('td[id$=c' + colIdx + ']', thisTable).addClass(icClassPrintHideCell);
            if (logColumCalculation) icPrintGridLogging('overhang %, column omitted', JSON.stringify(overhangPct));
        } else {
            if (printColOptimization) {
                colModifier.removeClass = icClassPrintOmitCol;
                colModifier.addClass = colClass;
            } else {
                icPrintRemoveClass(this, icClassPrintOmitCol);
                icPrintAddClass(this, colClass);
            }

            var thisStyle = '';
            if (colWidth > 0 && isPx) {
                // find out visible column's/grid ratio
                // only first col in colspan group gets full width
                colWidth = overhangPct.colspanIdx === 0 ? colWidth : 0;
                var colRatio = colWidth > 0 ? (colWidth / tblProfile.gridWidth) * 100 : 0;
                colRatio = parseFloat(colRatio.toFixed(2));
                if ((colRatio === 0 || isFixedColumnsTable) && overhangPct.colspan > 1) {
                    var remainingPercent = 100 - runningPercent;
                    if (isFixedColumnsTable) {
                        // in fixed columns table the first column in the multi colspan gets the rest up to 100%
                        colRatio = overhangPct.colspanIdx === 0 ? remainingPercent : 0;
                    } else {
                        // regular tables the all the multi colspan columns get an equally divided rest of 100%
                        colRatio = remainingPercent / overhangPct.colspan;
                    }
                    colRatio = parseFloat(colRatio.toFixed(2));
                } else {
                    // only increse for non combined columns
                    runningPercent += colRatio;
                }
                // add column override ratio to printStyles
                tblProfile.visibleColumnCount += colRatio > 0 ? 1 : 0;
                thisStyle = 'width: ' + colRatio + '% !important;';
            } else if (colWidth > 0 && !isPx) {
                tblProfile.visibleColumnCount += colWidth > 0 ? 1 : 0;
                thisStyle = ' width: ' + colWidth + '% !important; /* // from percent width */ ';
            } else if (colWidth === 0 && (originalWidthString === 'auto' || ( overhangPct.colspan > 1 && overhangPct.colspanIdx === 0)) ) {
                thisStyle = ' width: auto !important; /* Remainder of percent */';
            } else {
                thisStyle = ' width: 0px !important; /* // no width found */';
            }
            printStyles += icPrintGridMakeClass(colClass, thisStyle);
            if (logColumCalculation) icPrintGridLogging('col class %s, overhang %s, style %s', colClass, JSON.stringify(overhangPct), thisStyle);
        }

        if (printColOptimization) {
            colDictionary.push(colModifier);
        }
    });

    // optimize reflow/repaint by adding col classes while hiding tables
    writeToMediaStyle(printStyles);
    if (printColOptimization) {
        for (var iCol=0;iCol<colDictionary.length;iCol++) {
            var colModifier = colDictionary[iCol];
            icPrintRemoveClass(colModifier.colInstance, colModifier.removeClass);
            icPrintAddClass(colModifier.colInstance, colModifier.addClass);
        }
        if (tableDisplay !== 'none') {
            thisTable.style.display = tableDisplay;
        }
    }
    // if (logTimers) console.timeEnd(timerName);
    return false;
}

function icClassRemover(currentClasses, searchedClass) {
    // finds generated classes
    var newClasses = currentClasses;
    var possibleReplacements = [];
    possibleReplacements[0] = icClassPrintTable.split('{')[0];
    possibleReplacements[1] = icClassPrintViewCol.split('{')[0];
    possibleReplacements[2] = icClassPrintRowHeight.split('{')[0];
    possibleReplacements[3] = icClassPrintFreespaceRow.split('{')[0];

    // replace ic print class type
    var foundPattern = false;
    for (var i=0;i<possibleReplacements.length;i++) {
        if (searchedClass.indexOf(possibleReplacements[i]) >= 0) {
            var re = new RegExp(possibleReplacements[i] + '[^\\s]*', 'g')
            newClasses = (' ' + currentClasses + ' ').replace(re, ' ').trim();
            foundPattern = true;
            break;
        }
    }

    // replace it exactly
    if (!foundPattern) {
        newClasses = (' ' + currentClasses + ' ').replace(' ' + searchedClass + ' ', ' ').trim();
    }

    return newClasses;
}

function icPrintRemoveClass(elem, clss) {
    if (!elem) {
        return;
    }
    // done without jquery for performance and testability
    var currentClasses = elem.className;
    var newClasses = icClassRemover(currentClasses, clss);

    // only update element if replacement was made
    if (newClasses.length !== currentClasses.length) {
        elem.className = newClasses;
    }
}

function icPrintAddClass(elem, clss) {
    icPrintRemoveClass(elem, clss);
    elem.className += ' ' + clss;
}

function icPrintGetWidth(elem) {
    var width, isPx;
    width = $(elem).css('width');
    isPx = width.toLowerCase().indexOf('px') >= 0;
    return {
        width: parseFloat(width), // strips out px or % data
        isPx: isPx,
        originalWidth: (elem && elem.style && elem.style.width) || width
    }
}

function icPrintGetHeight(elem) {
    var height, isPx;
    height = $(elem).css('height');
    isPx = height.toLowerCase().indexOf('px') >= 0;
    return {
        height: parseFloat(height), // strips out px or % data
        isPx: isPx,
    }
}

function percentOverflowingX(el, gridRect) {
    var overhang = { left: 0, right: 0 };
    var rect = el.getBoundingClientRect();
    if (gridRect.width <= 0) {
        return overhang;
    }
    var hangsLeft = rect.left < gridRect.left;
    var hangsRight = rect.right > gridRect.right;
    if (hangsLeft) {
        overhang.left = gridRect.left > rect.right || rect.width < 1 ? 1 :
            (parseFloat(gridRect.left.toFixed(2)) - parseFloat(rect.left.toFixed(2))) / parseFloat(rect.width.toFixed(2));
        overhang.left = parseFloat(overhang.left.toFixed(2));
    }
    if (hangsRight) {
        overhang.right = gridRect.right < rect.left || rect.width < 1 ? 1 :
            (parseFloat(rect.right.toFixed(2)) - parseFloat(gridRect.right.toFixed(2))) / parseFloat(rect.width.toFixed(2));
        overhang.right = parseFloat(overhang.right.toFixed(2));
    }
    return overhang;
}

function findRowTdOverhangAndColspan(thisGrid, thisTable, gridRect) {
    var tdData = [];

    // first try to find a data row
    var rows = $('tr.dx-data-row', thisTable).not('.dx-freespace-row,[style*="display: none"]');
    if (rows.length === 0) {
        // find header row
        var isFixedColumns = $(thisTable).hasClass(icClassPrintTableFixed);
        rows = isFixedColumns ?
            $('table.' + icClassPrintTableFixed + ' tr.dx-header-row', thisGrid) :
            $('table.' + icClassPrintTableScrollable + ' tr.dx-header-row', thisGrid);
    }
    if (rows.length === 0) {
        // grab first available row
        rows = $('tr.dx-row', thisTable);
    }
    if (rows.length === 0) {
        return tdData;
    }

    var tds = $('td', rows[0])
    $(tds).each(function eachTd() {
        var colspan = $(this).attr('colspan') || 1;
        var overhangPct = percentOverflowingX(this, gridRect);
        for (var i = 0; i < parseInt(colspan); i++) {
            overhangPct.colspan = parseInt(colspan);
            overhangPct.colspanIdx = i;
            tdData.push(_.clone(overhangPct));
        }
    });
    if (logColumCalculation) icPrintGridLogging('tdData set %s', JSON.stringify(tdData).split('},').join('},\n'));
    return tdData;
}

function getInternetExplorerVersion() {
    var rv = -1;
    if (navigator.appName == 'Microsoft Internet Explorer') {
        var ua = navigator.userAgent;
        var re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
        if (re.exec(ua) != null) {
            rv = parseFloat(RegExp.$1);
        }
    } else if (navigator.appName == 'Netscape') {
        var ua = navigator.userAgent;
        var re = new RegExp("Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})");
        if (re.exec(ua) != null) {
            rv = parseFloat(RegExp.$1);
        }
    }
    return rv;
}

function isBrowserFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

function isBrowserAppleChrome() {
    var isApple = navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 ||
        navigator.userAgent.toLowerCase().indexOf('ipad') > -1;
    var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    return isApple && isChrome;
}

var hasPrintAllVisibleScrollableAndFixedColumnsThemeProp = function () {
    return IX_GetThemeProperty('PrintAllVisibleScrollableAndFixedColumns') &&
        !IX_IsThemePropertyValue1Falsey('PrintAllVisibleScrollableAndFixedColumns', 'false');
};

var printAllVisibleScrollableAndFixedColumnsDefaultWidth = function () {
    var pixelWidthA4 = 794;
    return parseInt(IX_GetThemePropertyValue2('PrintAllVisibleScrollableAndFixedColumns', pixelWidthA4));
};

var hasBeforePrintEvent = false;

function mediaQueryChangeEvent (changeEvent) {
    if (changeEvent.matches && hasBeforePrintEvent && hasPrintAllVisibleScrollableAndFixedColumnsThemeProp()) {
        afterPrintGridProcess();
    }
}

function windowsOnLoadMediaQueryChange () {

    // disable optimizations that slow down ios & firefox
    if ( IX_isIOS() || isBrowserFirefox() ) {
        printColOptimization = false;
    }

    // For IE11 we need to instead use onafterprint
    if (getInternetExplorerVersion() > 0) {
        return;
    }

    // For Firefox we need to do everything in beforeprint
    if (isBrowserFirefox()) {
        return;
    }

    // media change detection necessary because some browsers fire onAfterPrint after preview is gone
    var mediaQueryList = window.matchMedia('print');

    if (mediaQueryList.addEventListener) {
        mediaQueryList.addEventListener('change', mediaQueryChangeEvent);
    } else {
        mediaQueryList.addListener(mediaQueryChangeEvent);
    }
}

$(window).off("load", windowsOnLoadMediaQueryChange);
$(window).on("load", windowsOnLoadMediaQueryChange);

$(window).on("beforeprint", function (event) {
    // Feature-sniff... Safari 12 did not have this event, and crashes
    // with segmentation fault when we try to print after calculating in media query listener.
    // Degrade more gracefully by not attempting any print transformation where absent.
    hasBeforePrintEvent = true;

    if (!hasPrintAllVisibleScrollableAndFixedColumnsThemeProp()) {
        return;
    }
    beforePrintGridProcess();

    if (isBrowserFirefox()) {
        // In Firefox we will not get a MediaQueryChange event, and what we do in afterprint event cannot affect output.
        // So we must do everything here in the beforeprint event.
        afterPrintGridProcess();
    }
});

//TODO: Replace browser-sniffing with feature-sniffing
if (getInternetExplorerVersion() > 0) {
    $(window).on("afterprint", function (event) {
        if (!hasPrintAllVisibleScrollableAndFixedColumnsThemeProp()) {
            return;
        }
        afterPrintGridProcess();
    });
}

// END PRINT GRID

if (typeof module != 'undefined') {
    module.exports = {
        IX_InBecomeUserMode: IX_InBecomeUserMode,
        IX_GetFormattedField: IX_GetFormattedField,
        IX_Log: IX_Log,
        IX_isWebView: IX_isWebView,
        IX_UpdateAppWrapperHTMLAttributes: IX_UpdateAppWrapperHTMLAttributes,
        IX_BindAnnounceTextToLoadngState: IX_BindAnnounceTextToLoadngState,
        IX_AddBodyAttributesDefinedByMobileApplication: IX_AddBodyAttributesDefinedByMobileApplication,
        IX_OnHiddenModalDialogSetUpADA: IX_OnHiddenModalDialogSetUpADA,
        IX_ConvertSelectedRowsToDSFormat: IX_ConvertSelectedRowsToDSFormat,
        IX_OnShownModalDialogSetUpADA: IX_OnShownModalDialogSetUpADA,
        IX_AnnounceText: IX_AnnounceText,
        IX_ScrollRestoration: IX_ScrollRestoration,
        IX_GetThemeProperty: IX_GetThemeProperty,
        IX_GetThemePropertyValue1: IX_GetThemePropertyValue1,
        IX_GetThemePropertyValue2: IX_GetThemePropertyValue2,
        IX_IsThemePropertyValue1Falsey: IX_IsThemePropertyValue1Falsey,
        IX_SetSocialSharingButtons: IX_SetSocialSharingButtons,
        IX_ConditionalFormatApplyFormat: IX_ConditionalFormatApplyFormat,
        IX_UnApplyConditionalFormatCssClass: IX_UnApplyConditionalFormatCssClass,
        IX_ForceShowScrollbars: IX_ForceShowScrollbars,
        IX_openOutsideMobileApp: IX_openOutsideMobileApp,
        IX_ApplyDeviceStyles: IX_ApplyDeviceStyles,
        IX_isSafari: IX_isSafari,
        IX_setFocusToElementByClassName: IX_setFocusToElementByClassName,
        IX_FixAlternatingRows: IX_FixAlternatingRows,
        IX_PerfStart: IX_PerfStart,
        IX_PerfEnd: IX_PerfEnd,
        IX_PopUpOnShownAddClass: IX_PopUpOnShownAddClass,
        IX_SetAdaHeaderMarkup: IX_SetAdaHeaderMarkup,
        IX_Signout: IX_Signout
    };
}
