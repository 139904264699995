/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import Highcharts from '../libraries/highcharts.extensions';
import { FieldFormatService } from "./field-format.service";
import { UtilListService } from './util-list.service';

@Injectable()
export class HighchartsService {

    constructor(private fieldFormatService: FieldFormatService,
        private utilListService: UtilListService) {
    }

    getChartType(config) {
        return config.chart.chart.type.toLowerCase();
    }

    chartCategories(rawData, config) {
        const cats = [];
        const l = rawData.length;
        let fValue = null;
        const others = !_.isNil(config.others) ? config.others : l;
        const yField = config.series[0].argument;

        for (let i = 0; i < l; i++) {
            if (i >= others) {
                cats.push(config.othersText);
                break;
            } else {
                fValue = this.fieldFormatService.format(config.formats[yField], rawData[i][yField]);
                if (fValue === "") {
                    fValue = this.getDefaultSeriesName(i);
                }
                cats.push(fValue);
            }
        }
        return cats;
    }

    getDefaultSeriesName(i) {
        return "Series " + (i+1);
    }

    seriesHasDataPoints(series) {
        if (!series.data) {
            return false;
        }
        return !series.data.every((x) => _.isNil(x.y));
    }

    addChartSeries(chart, data, config, overridesConfig, wizzardSeries) {
        const chartType = this.getChartType(config);
        const xField = config.series[0].argument;
        switch (chartType) {
            case 'column':
                return this.addNewChartSeriesForBarChart(chart, data, config, overridesConfig);
            case 'scatter':
                return this.addNewChartSeriesForScatterChart(chart, data, config);
            default:
                return this.addNewChartSeries(chart, data, config, xField, overridesConfig, wizzardSeries);
        }
    }

    getMarkerInfoForSeries(overridesConfig, wizzardSeries, seriesName) {
        const overRideEnabled = overridesConfig.enableMarker == 'true';
        let wizardEnabled, markerSymbol;
        _.forEach(wizzardSeries, function(series, ind){
            if(series.name == seriesName) {
                wizardEnabled = series.marker && series.marker.enabled ? true : false;
                markerSymbol = series.marker && series.marker.symbol ? series.marker.symbol : undefined;
            }
        });
        if (wizardEnabled || overRideEnabled){
            return {enabled: true, symbol: markerSymbol}
        } else {
            return {enabled: false}
        }
    };

    addNewChartSeries(chart, rawData, config, field, overridesConfig, wizzardSeries) {
        let currentSerieContract;
        let colorValue;
        let colorPath;
        const calculatedSeries = this.convertDatasetItemsToHighchartsSeries(rawData, config, overridesConfig, field, chart);

        calculatedSeries.forEach((it, idx) => {
            currentSerieContract = {
                name: it.seriesName,
                data: it.seriesData,
                marker: this.getMarkerInfoForSeries(overridesConfig, wizzardSeries, it.seriesName)
            };

            //to show different field value as data labels only when the override is provided
            if(overridesConfig.UseFieldAsDataLabel){
                currentSerieContract.dataLabels = it.dataLabels;
            };

            colorValue = this.getSeriesColorValue(it.seriesName, config);
            colorPath = this.getSeriesColorPath(config);
            if (!_.isNil(colorPath) && !_.isNil(colorValue)) {
                _.set(currentSerieContract, colorPath, colorValue);
                if (this.shouldSetColorByPoint(config)) {
                    chart.update({ plotOptions: { series: { colorByPoint: true } } });
                }
            }
            if (this.getChartType(config) == 'area') {
                const fillColorValue = colorValue ? colorValue[idx] : config.chart.colors[idx];
                const opacity = overridesConfig.linearGradientOpacity ? parseInt(overridesConfig.linearGradientOpacity) : 1;
                currentSerieContract.fillColor = this.fillColorByGradient(fillColorValue, opacity);
            }
            chart.addSeries(currentSerieContract, true);
        });
        //to show the noData text when there is no data in the chart
        chart.hideNoData();
        if (chart.series?.length > 0 
            && chart.series[0].data?.length < 1) {
          chart.showNoData();
        }			
    }

    //to create the chart series for the scatter chart
    addNewChartSeriesForScatterChart(chart, rawData, config) {

        let currentSerieContract;
        let colorValue;
        let colorPath;
        const calculatedSeries = this.convertDatasetItemsToHighchartsSeries(rawData, config);

        calculatedSeries.forEach((it) => {
            currentSerieContract = {
                name: it.seriesName,
                data: it.seriesData
            };
            colorValue = this.getSeriesColorValue(it.seriesName, config);
            colorPath = this.getSeriesColorPath(config);
            if (!_.isNil(colorPath) && !_.isNil(colorValue)) {
                _.set(currentSerieContract, colorPath, colorValue);
                if (this.shouldSetColorByPoint(config)) {
                    chart.update({
                        plotOptions: {
                            series: {
                                colorByPoint: true
                            }
                        }
                    });
                }
            }
            chart.addSeries(currentSerieContract, true);

            //to show the line running through even the null datapoints
            chart.options.plotOptions.series.connectNulls = true;
        });
    }

    
    addNewChartSeriesForBarChart(chart, rawData, config, overridesConfig) {
        const that = this;
        const calculatedSeries = this.convertDatasetItemsToHighchartsSeries(rawData, config, overridesConfig);

        const filteredCalculatedSeries = [];

        //to show the dataLabel inside the dataPoint based on the override
        if (overridesConfig.showCustomDataLabelAs && overridesConfig.showCustomDataLabelAs.toLowerCase() == 'percentage') {
            let totalData = 0;
            _.forEach(rawData, function (k) {
                totalData += k[config.series[0].values];
            });
            chart.options.plotOptions.column.dataLabels.formatter = function () {
                return that.fieldFormatService.format('Percentage', (this.y/totalData));
            };
        }

        //showing the datalabels in the format specified in the field format if configured
        if (overridesConfig.UseFieldFormatForDataLabels && overridesConfig.UseFieldFormatForDataLabels.toLowerCase() == 'true') {
            let totalData = 0;
            _.forEach(rawData, function (k) {
                totalData += k[config.series[0].values];
            });
            if(config.series[0] && config.series[0].values) {
                chart.options.plotOptions.column.dataLabels.formatter = function () {
                    return that.fieldFormatService.format(config.formats[config.series[0].values], (this.y/totalData))
                };
            } else {
                return undefined;
            }                
        }

        // Start series from specific point
        if (overridesConfig.pointStart && !overridesConfig.ShowXAxisCategories) {
            chart.options.plotOptions.series.pointStart = parseInt(overridesConfig.pointStart);
        }
        //To remove the extra space on both sides between the Y-axis and the columns in the chart.
        chart.options.plotOptions.column.groupPadding = overridesConfig.GroupPadding;

        calculatedSeries.forEach(function (it) {
            const currentSerieContract = {
                name: it.seriesName,
                data: it.seriesData,
                color: it.color,
                negativeColor: it.negativeColor,
                colorByPoint: false,
                dataLabels : '',
                enableMouseTracking : false,
                grouping: false,
                index: undefined      
            };

            //to show different field value as data labels only when the override is provided
            if(overridesConfig.UseFieldAsDataLabel || (it.dataLabels && it.dataLabels.enabled==true)){
                currentSerieContract.dataLabels = it.dataLabels;
            }
            
            if (overridesConfig.ColumnBackdrop) {
                currentSerieContract.enableMouseTracking = it.enableMouseTracking;
                currentSerieContract.grouping = it.grouping;
                currentSerieContract.index = it.index;
            }

            if (overridesConfig.ShowSeriesWithNullData || that.seriesHasDataPoints(currentSerieContract)) {
                filteredCalculatedSeries.push(currentSerieContract);
            }
        });

        if (overridesConfig.ColumnHighlightMinMax || overridesConfig.enableColumnStacking) {
            chart.options.plotOptions.column.stacking = 'normal';
            chart.options.plotOptions.series.borderWidth = 0;
        }

        if (overridesConfig.ColumnBackdrop && rawData.length) {
            that.configureYAxis(
                chart,
                that.getDataBoundaries(rawData, overridesConfig.ColumnBackdrop),
                false
            );
        }

        const last = filteredCalculatedSeries.length - 1;
        filteredCalculatedSeries.forEach((it, ind) => chart.addSeries(it, ind == last));

        //to show the noData text when there is no data in the chart
        chart.hideNoData();
        if (chart.series.length < 1) {
            // Reset y-axis to default, so that an empty chart does not appear.
            that.configureYAxis(chart, null, true);
            chart.showNoData();
        }
    }

    convertDatasetItemsToHighchartsSeries(data, config, overridesConfig?, xField?, chart?) {
        const chartType = this.getChartType(config);
        switch (chartType) {
            case 'column':
                return this.getCalculatedSeriesForBarChart(config, data, overridesConfig);
            case 'scatter':
                return this.getCalculatedSeriesForScatterChart(data, config);
            default:
                return this.getCalculatedSeries(data, config, xField, chart, overridesConfig);
        }
    }

    createDonutProgressChart(obj, it, chart, overridesConfig, point, config, newData) {
        const actual = obj[it.values];
        const empty = obj[it.argument] - obj[it.values];
        obj['Remaining'] = obj[it.argument] - obj[it.values];
        let updateSubtitle = chart.options.subtitle.text;
        let units = [];

        // Set Donut progress bar color
        const customColor = overridesConfig.donutCustomColor ? overridesConfig.donutCustomColor.split('|') : [];
        const completedColor = customColor && customColor.length > 0 ? customColor[0] : null; // null puts default colors
        const notCompletedColor = customColor && customColor.length > 1 ? customColor[1] : null;

        // Set donut series name
        const donutSeriesName = overridesConfig.donutSeriesName ? overridesConfig.donutSeriesName.split('|') : [];
        let segmentSize;
        if (donutSeriesName.length < 2) {
            throw new Error("Must provide series name");
        }

        if(overridesConfig.fieldForDonutProgressSegments){
            let previousValue = 0;
            let actualValueEndingSeries;
            const segmentValues = obj[overridesConfig.fieldForDonutProgressSegments].split(",");
            //to create the series in the chart to be shown as segments
            //based on the values in the data
            _.forEach(segmentValues, function(itm, ind){
                segmentSize = itm - previousValue;
                if(parseInt(itm) <= parseInt(actual)){
                    point.push({name: donutSeriesName[0], y: parseInt(segmentSize), color: completedColor});
                } else if(parseInt(actual) > parseInt(previousValue.toString())) {
                    point.push({name: donutSeriesName[0], y: parseInt(actual) - parseInt(previousValue.toString()), color: completedColor},
                            {name: "segmentSeries", y: parseInt(itm) - parseInt(actual), color: notCompletedColor});
                    
                    //to know the actual value ending series number
                    //to exclude putting gap between series in this case
                    actualValueEndingSeries = ind;
                } else {
                    point.push({name: "segmentSeries", y: parseInt(segmentSize), color: notCompletedColor});
                }
                previousValue = itm;
            });
            this.createGapBetweenChartSeries(chart, actualValueEndingSeries, segmentValues);
        } else {
            point.push({ name: donutSeriesName[0], y: parseInt(actual), color: completedColor });
            point.push({ name: donutSeriesName[1], y: parseInt(empty.toString()), color: notCompletedColor});
        }

        // Set donut chart configuration
        if (overridesConfig.donutInnerSize) chart.options.plotOptions.series.innerSize = this.fieldFormatService.format("Percentage", overridesConfig.donutInnerSize / 100);
        if (overridesConfig.donutOuterSize) chart.options.plotOptions.series.size = this.fieldFormatService.format("Percentage", overridesConfig.donutOuterSize / 100);
        if (overridesConfig.donutSeriesUnit) {
            units = overridesConfig.donutSeriesUnit.split('|');
        } 
        chart.options.plotOptions.series.borderWidth = overridesConfig.borderWidth ?  parseInt(overridesConfig.borderWidth) : 0;
        chart.options.plotOptions.series.allowPointSelect = overridesConfig.allowPointSelect ? true : false;

        if (overridesConfig.addSubtitle) {
            this.utilListService.updateComponentPropertiesForTranslations(config.appName, newData, overridesConfig);
            updateSubtitle = obj[overridesConfig.itemTextMap["addSubtitle"]];
        }
        chart.setTitle({useHTML: overridesConfig.useHTML}, { text: updateSubtitle, useHTML: overridesConfig.useHTML });

        // Place text inside donut chart
        const textX = chart.plotLeft + (chart.plotWidth  * 0.5);
        const textY = chart.plotTop  + (chart.plotHeight * 0.5);

        // The width and height need to be calculated properly, so I made it a span.
        let span = '<span class="donutChartInfoText" style="position:absolute; text-align:center;">';
        span += '<span class="actualValue">' + this.fieldFormatService.format(config.formats[it.values], obj[it.values]) + '</span>';
        span += '<span class="units">' + (units.length > 1 ? units[1].toUpperCase() : "") + '</span>';
        span += '<span class="totalValue">of ' + this.fieldFormatService.format(config.formats[it.argument], obj[it.argument])  + '</span>';
        span += '</span>';

        $(".ic-list-chart").children().append(span);
        const spanSelector = $('.donutChartInfoText');
        spanSelector.css('left', textX + (spanSelector.width() * -0.5));
        spanSelector.css('top', textY + (spanSelector.height() * -0.5));
    }

    createGapBetweenChartSeries(chart, actualValueEndingSeries, segmentValues) {
            //to show the gap between the segments in the donut chart
            let seriesIndex = 0;
            const defined = Highcharts.defined,
            pick = Highcharts.pick;
            chart.renderer.symbols.arc = function (x, y, w, h, options) {
            const start = options.start,
                rx = options.r || w,
                ry = options.r || h || w,
                proximity = actualValueEndingSeries != undefined && (seriesIndex == actualValueEndingSeries || seriesIndex == ((segmentValues.length + 1)*4 + 6 + actualValueEndingSeries))? 0 : 0.05,
                fullCircle =
                Math.abs(options.end - options.start - 2 * Math.PI) <
                proximity,
                // Substract a small number to prevent cos and sin of start and
                // end from becoming equal on 360 arcs (related: #1561)
                end = options.end - proximity,
                innerRadius = options.innerR,
                open = pick(options.open, fullCircle),
                cosStart = Math.cos(start),
                sinStart = Math.sin(start),
                cosEnd = Math.cos(end),
                sinEnd = Math.sin(end),
                // Proximity takes care of rounding errors around PI (#6971)
                longArc = options.end - start - Math.PI < proximity ? 0 : 1;
    
            const arc = [
                'M',
                x + rx * cosStart,
                y + ry * sinStart,
                'A', // arcTo
                rx, // x radius
                ry, // y radius
                0, // slanting
                longArc, // long or short arc
                1, // clockwise
                x + rx * cosEnd,
                y + ry * sinEnd
            ];
    
            if (defined(innerRadius)) {
                arc.push(
                    open ? 'M' : 'L',
                    x + innerRadius * cosEnd,
                    y + innerRadius * sinEnd,
                    'A', // arcTo
                    innerRadius, // x radius
                    innerRadius, // y radius
                    0, // slanting
                    longArc, // long or short arc
                    0, // clockwise
                    x + innerRadius * cosStart,
                    y + innerRadius * sinStart
                );
            }
            seriesIndex++;
            arc.push(open ? '' : 'Z'); // close
            return arc;
        }; 
    }

    getCalculatedSeries(newData, config, field, chart, overridesConfig) {
        const that = this;
        let pointStart = !_.isNil(overridesConfig.pointStart) ? parseInt(overridesConfig.pointStart) : 0;
        let point = null;
        const res = [], l = newData.length,
            others = !_.isNil(config.others) ? config.others : l,
            calculatedSeries = config.series.reduce(function (obj, it) {
                obj[it.seriesName] = {};
                obj[it.seriesName].cVal = 0;
                obj[it.seriesName].data = [];
                return obj;
            }, {});
        newData.forEach(function (obj, i) {
            // PointStart works only if x-axis categories are not set, and we want to start series from random point
            if (overridesConfig.pointStart && !overridesConfig.ShowXAxisCategories) {
                i = pointStart;
                pointStart++;
            }
            config.series.forEach(function (it) {
                const mapValuesFromField = overridesConfig && overridesConfig.MapValuesFromField;

                if (i >= others) {
                    calculatedSeries[it.seriesName].cVal += obj[it.values];
                    if (i === l - 1) {
                        point = {
                            x: others,
                            y: calculatedSeries[it.seriesName].cVal,
                            _xf: field,
                            _yf: it.values
                        };
                        point._r = { ...obj };
                        point._r[it.values] = point.y;
                        if (config.chart.chart.type === "pie") {
                            point.name = config.othersText;
                            delete point.x;
                        }
                        that.setPointColor(config, point, it.seriesName, others, mapValuesFromField);
                    }
                } else if (overridesConfig && overridesConfig.donutProgressBar) {
                    point = [];
                    that.createDonutProgressChart(obj, it, chart, overridesConfig, point, config, newData);                        
                } else {
                    point = {
                        x: i,
                        y: obj[it.values],
                        _yf: it.values,
                        _xf: field,
                        _r: obj
                    };
                    if (config.chart.chart.type === "pie") {
                        point.name = obj[field];
                        delete point.x;
                    }
                    that.setPointColor(config, point, it.seriesName, undefined, mapValuesFromField);
                }
                if (_.isArray(point)) 
                    calculatedSeries[it.seriesName].data = point;
                else
                    calculatedSeries[it.seriesName].data.push(point);
                
                //to show particular field value in the dataPoint as dataLabel
                if(overridesConfig.UseFieldAsDataLabel){
                    calculatedSeries[it.seriesName].dataLabels = {
                        formatter: function(){
                            return that.fieldFormatService.format(config.formats[overridesConfig.UseFieldAsDataLabel], newData[this.point.index][overridesConfig.UseFieldAsDataLabel]);
                        }
                    };
                }                                    
            });
        });
        for (const serie in calculatedSeries) {
            res.push({
                seriesName: serie,
                seriesData: calculatedSeries[serie].data,
                dataLabels: calculatedSeries[serie].dataLabels
            });
        }
        return res;
    }
    

    getCalculatedSeriesForScatterChart(newData, config){
        let point = null;
        const res = [], l = newData.length,
            calculatedSeries = config.series.reduce(function (obj, it) {
                obj[it.seriesName] = {};
                obj[it.seriesName].cVal = 0;
                obj[it.seriesName].data = [];
                return obj;
            }, {});
        newData.forEach((obj, i) => {
            config.series.forEach((it) => {
                point = [obj[it.argument], obj[it.values]];
                //TODO: Calling _setPointColor with point being an array will have no effect. Evaluate removing.
                this.setPointColor(config, point, it.seriesName);
                calculatedSeries[it.seriesName].data.push(point);
            });
        });
        for (const serie in calculatedSeries) {
            res.push({
                seriesName: serie,
                seriesData: calculatedSeries[serie].data
            });
        }
        return res;
    }

    createCurrentSeriesAndAddToSeries(config, data, overridesConfig, i, j, currentSeries, color, others, result) {
        const that = this;
        let currentSeriesData;
        let othersSum = null;
        let seriesName = overridesConfig.grouping
            ? data[0].key
            : data[i][currentSeries.argument];
        let addSeries = false;
        const val = data[i][currentSeries.values];
        const formattedName = that.fieldFormatService.format(config.formats[currentSeries.argument], data[i][currentSeries.argument]);

        if (seriesName.toString() === "") {
            seriesName = that.getDefaultSeriesName(j);
        }

        if (i >= others) {
            othersSum += val;
            if (i === data.length - 1) {
            currentSeriesData = [
                {
                name: config.othersText,
                _yf: currentSeries.values,
                y: othersSum,
                _r: data[0]
                }
            ];
            addSeries = true;
            }
        } else {
            currentSeriesData = [
            {
                name: formattedName,
                _yf: currentSeries.values,
                y: val,
                _r: data.length > 1 ? data[i] : data[0]
            }
            ];
            addSeries = true;
        }

        if (
            overridesConfig.ShowXAxisCategories &&
            overridesConfig.xAxisVisible
        ) {
            currentSeriesData[0].x = i; // To convert it into {x,y} point format, so categories can be displayed
        }

        if (addSeries) {
            const series = {
            seriesName: seriesName,
            seriesData: currentSeriesData,
            color:
                i >= others
                ? color
                    ? color
                    : config.chart.colors[others]
                : color
                ? color
                : config.chart.colors[i],
            negativeColor: overridesConfig.DataLabelColorNegative
                ? overridesConfig.DataLabelColorNegative
                : config.chart.plotOptions.series.negativeColor,
            dataLabels: {
                formatter: function() {
                if (
                    data[this.series.index] &&
                    data[this.series.index][
                    overridesConfig.UseFieldAsDataLabel
                    ]
                ) {
                    return that.fieldFormatService.format(
                    config.formats[overridesConfig.UseFieldAsDataLabel],
                    data[this.series.index][
                        overridesConfig.UseFieldAsDataLabel
                    ]
                    );
                }
                }
            }
            };

            result.push(series);
        }
    };

    getCalculatedSeriesForBarChart(config, data, overridesConfig) {
        const that = this;
        const result = [];

        if (
            config.chart.xAxis &&
            config.chart.xAxis.length > 0 &&
            config.chart.xAxis[0].labels &&
            !overridesConfig.ShowXAxisCategories &&
            !overridesConfig.xAxisVisible
        ) {
            config.chart.xAxis[0].visible = false;
            config.chart.xAxis[0].labels.enabled = false;
        }

        const others = !_.isNil(config.others) ? config.others : data.length;

        if (!overridesConfig.ColumnBackdrop) {
            // Convert each row into series so legend can control column
            if (
                !overridesConfig.doNotSplitIntoMultipleSeries &&
                !overridesConfig.isMultipleSeriesWithMultipleArguments
            ) {
                for (let j = 0; j < config.series.length; j++) {
                const currentSeries = config.series[j];
                const color = that.getSeriesColorValue(
                    config.series[j].seriesName,
                    config
                );

                for (let i = 0; i < data.length; i++) {
                    that.createCurrentSeriesAndAddToSeries(
                    config,
                    data,
                    overridesConfig,
                    i,
                    j,
                    currentSeries,
                    color,
                    others,
                    result
                    );
                }
                }
            } else if (
                overridesConfig.isMultipleSeriesWithMultipleArguments
            ) {
                //use case scenario for when there are multiple series with different arguments
                //is only possible when the metadata for the chart object is overwritten in the list app
                //not possible from the chart wizard alone
                for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < config.series.length; j++) {
                    const color = that.getSeriesColorValue(
                    config.series[j].seriesName,
                    config
                    );
                    const currentSeries = config.series[j];
                    that.createCurrentSeriesAndAddToSeries(
                    config,
                    data,
                    overridesConfig,
                    i,
                    j,
                    currentSeries,
                    color,
                    others,
                    result
                    );
                }
                }
            } else {
                // Use series as specified in chart wizard
                for (let j = 0; j < config.series.length; j++) {
                const currentSeries = config.series[j];
                const currentSeriesData = [];
                let othersSum = null;
                let seriesName = currentSeries.seriesName;
                const seriesColor = that.getSeriesColorValue(seriesName, config);

                if (data.length > 0) {
                    seriesName = overridesConfig.grouping
                    ? data[0].key
                    : seriesName;
                }

                for (let i = 0; i < data.length; i++) {
                    const val = data[i][currentSeries.values];
                    let color = seriesColor;
                    const formattedName = that.fieldFormatService.format(config.formats[currentSeries.argument], data[i][currentSeries.argument]);
                    if (i >= others) {
                    othersSum += val;
                    if (i === data.length - 1) {
                        currentSeriesData.push({
                        name: config.othersText,
                        _yf: currentSeries.values,
                        y: othersSum,
                        _r: data[0],
                        color: seriesColor
                            ? seriesColor
                            : config.chart.colors[others]
                        });
                    }
                    } else {
                    if (!seriesColor) {
                        if (config.series.length > 1) {
                        color = config.chart.plotOptions.series
                            .negativeColor
                            ? config.chart.plotOptions.series.negativeColor
                            : config.chart.colors[j];
                        } else {
                        color =
                            val < 0 &&
                            config.chart.plotOptions.series.negativeColor
                            ? config.chart.plotOptions.series.negativeColor
                            : config.chart.colors[i];
                        }
                    }
                    currentSeriesData.push({
                        name: formattedName,
                        _yf: currentSeries.values,
                        y: val,
                        color: color,
                        _r: data[0],
                    });
                    }
                }

                const series = {
                    seriesName: seriesName,
                    seriesData: currentSeriesData,
                    negativeColor: overridesConfig.DataLabelColorNegative
                    ? overridesConfig.DataLabelColorNegative
                    : config.chart.plotOptions.series.negativeColor,
                    dataLabels: {
                    formatter: function() {
                        if (
                        data[this.series.index] &&
                        data[this.series.index][
                            overridesConfig.UseFieldAsDataLabel
                        ]
                        ) {
                        return that.fieldFormatService.format(
                            config.formats[
                            overridesConfig.UseFieldAsDataLabel
                            ],
                            data[this.series.index][
                            overridesConfig.UseFieldAsDataLabel
                            ]
                        );
                        }
                    }
                    }
                };

                result.push(series);
                }
            }
        } else {
            if (data.length) {
                const dataBoundaries = that.getDataBoundaries(data, overridesConfig.ColumnBackdrop);

                config.series.forEach(function (s, i) {
                    // Add lines to highlight each column
                    if (overridesConfig.ColumnHighlightMinMax) {
                        result.push({
                            borderColor: 'transparent',
                            color: overridesConfig.DataLabelColorPositive ? overridesConfig.DataLabelColorPositive : 'auto',
                            dataLabels: {
                                enabled: false
                            },
                            grouping: true,
                            index: 1,
                            enableMouseTracking: false,
                            negativeColor: overridesConfig.DataLabelColorNegative ? overridesConfig.DataLabelColorNegative : 'auto',
                            seriesData: data.map(function(o) {
                                return {
                                    name: o[s.argument],
                                    // Highlighting lines are 5% of the backdrop padding for consistency
                                    y: dataBoundaries.padding * 0.05 * (o[s.values] >= 0 ? 1 : -1), 
                                    _yf: s.values,
                                    _r: data[0],
                                };
                            }),
                            seriesName: null,
                            showInLegend: false
                        });
                    }

                    result.push({
                        color: config.chart.colors[i],
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                const actualValue = (
                                        (config.chart.chart.type === 'column') || (config.formats[s.values].toLowerCase().indexOf('percent') < 0)
                                    ) ? this.y : this.percentage;

                                let displayValue = that.fieldFormatService.format(config.formats[s.values], actualValue);
                                if (overridesConfig.DataLabelColorNegative && this.y < 0) {
                                    displayValue = '<span style="color: ' + overridesConfig.DataLabelColorNegative + '">' + displayValue + '</span>';
                                }

                                if (overridesConfig.DataLabelColorPositive && this.y > 0) {
                                    displayValue = '<span style="color: ' + overridesConfig.DataLabelColorPositive + '">' + displayValue + '</span>';
                                }

                                return displayValue;
                            },
                            inside: false,
                            style: {
                                textOutline: overridesConfig.ColumnHighlightMinMax ? '1px white' : '1px contrast',
                            },
                        },
                        enableMouseTracking: true,
                        grouping: true,
                        index: 1,
                        negativeColor: config.chart.plotOptions.series.negativeColor,
                        seriesData: data.map(function(o) {
                            return {
                                name: o[s.argument],
                                y: o[s.values],
                                _yf: s.values,
                                _r: data[0]
                            };
                        }),
                        seriesName: s.argument,
                        showInLegend: true
                    });
                });

                if (overridesConfig.ColumnBackdrop) {
                    result.push(that.getBackdropColumn(data, dataBoundaries.positive));
                    result.push(that.getBackdropColumn(data, dataBoundaries.negative));
                }

            }
        }

        return result;
    }

    getBackdropColumn(data, boundary) {
        return {
            color: '#eee', // TODO Support custom backdrop color
            dataLabels: {
                enabled: false
            },
            enableMouseTracking: false,
            grouping: true,
            index: 0,
            negativeColor: '#eee',
            seriesData: data.map(function () {
                return {name : "", y : boundary};
            }),
            seriesName: null,
            showInLegend: false
        };
    }

    configureYAxis(chart, dataBoundaries, resetToDefaults) {
        if (resetToDefaults) {
            chart.update({
                yAxis: {
                    endOnTick: true,
                    max: null,
                    min: null,
                    startOnTick: true,
                },
            });
        } else {
            chart.update({
                yAxis: {
                    endOnTick: false,
                    max: dataBoundaries.positive,
                    min: dataBoundaries.negative,
                    startOnTick: dataBoundaries.negative >= 0,
                },
            });
        }
    }

    getDataBoundaries(data, fieldName) {
        const ret = {
            positive: Math.max(_.maxBy(data, function(o) { return o[fieldName]; })[fieldName], 0),
            negative: Math.min(_.minBy(data, function(o) { return o[fieldName]; })[fieldName], 0),
            padding: 0
        };

        // The padding should be consistent whether it is a positive or negative backdrop-
        // -this also results in consistent highlighting
        ret.padding = 0.2 * Math.max(Math.abs(ret.positive), Math.abs(ret.negative));
        ret.positive += ret.padding;
        ret.negative -= ret.negative === 0 ? 0 : ret.padding;

        return ret;
    }

    
    getSeriesColorValue(serieName, config) {
        if (_.isNil(config.seriesColors)
            || _.isNil(config.seriesColors[serieName])
            || config.seriesColors[serieName].type == 'valueColor') return;
        const colors = config.seriesColors[serieName].colors;
        if (colors.length <= 1) return;
        const type = this.getChartType(config);
        let res;
        switch (type) {
            case "line":
                res = colors[0];
                break;
            default:
                res = colors;
                break;
        }
        return res;
    }

    getSeriesColorPath(config) {
        let colorPath;
        const type = this.getChartType(config);
        switch (type) {
            case "line":
                colorPath = "color";
                break;
            default:
                colorPath = "colors";
                break;
        }
        return colorPath;
    }

    shouldSetColorByPoint(config) {
        let res;
        const type = this.getChartType(config);
        switch (type) {
            case "line":
                res = false;
                break;
            default:
                res = true;
                break;
        }
        return res;
    }

    fillColorByGradient(color, linearGradientOpacity) {
        const fillColor = {
            linearGradient: [0,0,0,300],
            stops: [
                [0, color],
                [1, Highcharts.Color(color).setOpacity(linearGradientOpacity).get('rgba')]
            ]
        };
        return fillColor;
    }
    
    getPointColor(config, serieName, value, others) {
        if (_.isNil(config.seriesColors)
            || _.isNil(config.seriesColors[serieName])
            || config.seriesColors[serieName].type == 'color') return;
        const colorsMap = config.seriesColors[serieName].colors;
        if (_.isNil(colorsMap) || $.isEmptyObject(colorsMap)) return;
        if (!_.isNil(others) && colorsMap["Others"]) {
            return colorsMap["Others"];
        }
        return colorsMap[value];
    }

    setPointColor(config, point, serieName, others?, mapValuesFromField?) {
        let color;
        let value;

        if (point._r && mapValuesFromField) {
            value = point._r[mapValuesFromField];
        } else {
            value = point.y;
        }

        color = this.getPointColor(config, serieName, value, others);

        if (_.isNil(color) && !_.isNil(point.name)) {
            color = this.getPointColor(config, serieName, point.name, others);
        }

        if (!_.isNil(color)) {
            point.color = color;
        }
    }

}
