export class RowSet {

        rowSet: any[]
        map: {}

        constructor(rowArr, fieldMap) {
            this.rowSet = rowArr;
            this.map = fieldMap;
        }

        get(name, zeroIfNull?) {
            return zeroIfNull ? this.getZeroIfNull(name) : this.getFieldValue(name);
        }

        getFieldValue(fieldName) {
            const fieldIndex = this.map[fieldName];
            if (fieldIndex === undefined) {
                //console.warn('The field ' + fieldName + ' is missing from this app so cannot read value. Check p-tier');
                return null;
            }
            const valueObj = this.rowSet[fieldIndex];
            if (valueObj === undefined) {
                this.rowSet[fieldIndex] = null;
            }
            return valueObj;
        }

        set(fieldName, valueObj) {
            const fieldIndex = this.map[fieldName];
            if (fieldIndex === undefined) {
                //console.warn('The field ' + fieldName + ' is missing from this app so cannnot set value. Check p-tier');
                return null;
            }
            return this.rowSet[fieldIndex] = valueObj;
        }

        getZeroIfNull(fieldName) {
            const valueObj = this.getFieldValue(fieldName);
            return valueObj === null ? 0 : valueObj;
        }

        setColumn(name) {
            return {
                withValue: (value) => {
                    if (value === undefined) {
                        value = null;
                        //console.warn('Undefined value for ' + name);
                    }
                    return this.set(name, value);
                }
            }
        }

        getColumn(name, zeroIfNull) {
            return {
                value: () => {
                    if (zeroIfNull) {
                        return this.getZeroIfNull(name);
                    }
                    return this.getFieldValue(name);
                },
            }
        }

        isFilteredRow(filterBy) {
            let result = true, columnValue;
            for (const columnName in filterBy) {
                columnValue = this.getFieldValue(columnName);
                result = result && (filterBy[columnName] === columnValue);
                if (!result) break;
            }
            return result;
        }

        isAdvancedFilteredRow(filters) {
            let isFilteredRow = 1, groupOperator = "AND";
            for (let filterIndex = 0; filterIndex < filters.length; filterIndex = filterIndex + 2) {
                const filter = filters[filterIndex];
                if (Array.isArray(filter) && filter.length === 3) {

                    const field = filter[0];
                    const operator = filter[1];
                    const value = filter[2];
                    let filterValue = false;

                    // To match a-tier
                    const fieldValue = this.getZeroIfNull(field);

                    switch (operator) {
                        case '==':
                            // tslint:disable-next-line: triple-equals
                            filterValue = value == fieldValue;
                            break;
                        case '!=':
                            // tslint:disable-next-line: triple-equals
                            filterValue = value != fieldValue;
                            break;
                        case '<':
                            filterValue = fieldValue < value;
                            break;
                        case '>':
                            filterValue = fieldValue > value;
                            break;
                        default:
                            continue;
                    }
                    switch (groupOperator) {
                        case 'OR':
                            // tslint:disable-next-line: no-bitwise
                            isFilteredRow |= filterValue ? 1 : 0;
                            break;
                        default:
                            // tslint:disable-next-line: no-bitwise
                            isFilteredRow &= filterValue ? 1 : 0;
                            break;
                    }
                    groupOperator = filters[filterIndex + 1];
                }
            }
            return isFilteredRow === 1;
        }

        clone(): RowSet {
            return new RowSet(this.rowSet.slice(), this.map);
        }

        unwrap() {
            return this.rowSet;
        }

    };