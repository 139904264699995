/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DxTextAreaComponent } from 'devextreme-angular';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { TextboxShared } from "./text-box.shared";
import { DataService } from '../../services/data.service';
import { FieldFormatService } from '../../services/field-format.service';
import { AccessibilityService } from '../../services/accessibility.service';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { UtilService } from '../../services/util.service';
import { ValidationGroupService } from '../../services/validation-group.service';

@Component({
  selector: 'ic-text-area',
  template: '<dx-text-area [(value)]="dxValue" [class]="cssClass" (onInitialized)="onInitialized($event)"></dx-text-area>'
})
export class TextAreaComponent extends TextboxShared implements AfterViewInit, OnDestroy {

  @ViewChild(DxTextAreaComponent)
  textarea: DxTextAreaComponent;

  constructor(
    elementRef: ElementRef,
    translate: TranslateFacadeService,
    dataService: DataService,
    fieldFormatService: FieldFormatService,
    utilService: UtilService,
    validationEngineService: ValidationEngineService,
    validationGroupService: ValidationGroupService,
    accessibilityService: AccessibilityService) {
    super(elementRef, translate, dataService, fieldFormatService, utilService, validationEngineService, validationGroupService, accessibilityService);
  }

  ngOnDestroy(): void {
    this.baseOnDestroy();
  }

  ngAfterViewInit() {
    this.dxComponent = this.textarea;
    this.baseAfterViewInit();
  }
}
