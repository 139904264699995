/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import dxMenu, { dxMenuOptions } from 'devextreme/ui/menu';
import dxTreeView from 'devextreme/ui/tree_view';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AppEvent } from '../../state/app-events.enum';
import { getLocaleKey } from '../../translate/locale.functions';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { WorkflowStepComponent } from '../workflow-step.component';
import { DeviceService } from '../../services/device-information.service';
import { AccessibilityService } from '../../services/accessibility.service';
import { AppsFacade } from '../../state/apps.facade';
import { UtilService } from '../../services/util.service';
import { DataSourceService } from '../../services/datasource.service';
import { RouterService } from '../../services/router.service';
import { PersonalizationService } from '../../services/personalization.service';
import { DynamicReplacementService } from '../../services/dynamic-replacement.service';

interface IcDxMenuOptions extends dxMenuOptions {
  ariaLabel: string;
  ic: {
    utilService: UtilService;
    accessibilityService: AccessibilityService;
  };
  clickPromise: Promise<any>;
  onItemExpanded: (e) => void;
  onItemCollapsed: (e) => void;
  onContentReady: (e) => void;
  onSubmenuShown: (e) => void;
  onItemClick: (e) => void;
  onItemRendered: (e) => void;
  onSubmenuHidden: (e) => void;
}

type MenuItem = {
  label: string,
  link: string,
  cssClass: string,
  fontAwesomeIcon: string,
  secondary: string,
  subMenu?: MenuItem[],
  linkType: string,
  storageKey: string,
  dataAttributes: string,
  reloadApp: string,
  disabled: boolean,
};

@Component({
  selector: 'ic-menu-default',
  template: `<div class="ic-menu-default" [ngClass]="[cssClass]">
    <dx-menu *ngIf="!useTreeView"
    (onInitialized)="onInitializedMenu($event)"
    [dataSource]="menuOptions.dataSource"
    [displayExpr]="menuOptions.displayExpr"
    [itemsExpr]="menuOptions.itemsExpr"
    [itemTemplate]="menuOptions.itemTemplate"
    (onItemExpanded)="menuOptions.onItemExpanded($event)"
    (onItemCollapsed)="menuOptions.onItemCollapsed($event)"
    (onContentReady)="menuOptions.onContentReady($event)"
    (onSubmenuShown)="menuOptions.onSubmenuShown($event)"
    (onItemClick)="menuOptions.onItemClick($event)"
    (onItemRendered)="menuOptions.onItemRendered($event)"
    (onSubmenuHidden)="menuOptions.onSubmenuHidden($event)"
     ></dx-menu>
    <div class="dx-menu-adaptive-mode" *ngIf="useTreeView && dataAvailable">
      <dx-tree-view
          class="focus-treeview"
          [dataSource]="menuOptions.dataSource"
          [displayExpr]="menuOptions.displayExpr"
          [itemsExpr]="menuOptions.itemsExpr"
          [itemTemplate]="menuOptions.itemTemplate"
          (onInitialized)="onInitializedTreeView($event)"
          (onItemExpanded)="menuOptions.onItemExpanded($event)"
          (onItemCollapsed)="menuOptions.onItemCollapsed($event)"
          (onContentReady)="menuOptions.onContentReady($event)"
          (onSubmenuShown)="menuOptions.onSubmenuShown($event)"
          (onItemClick)="menuOptions.onItemClick($event)"
          (onItemRendered)="menuOptions.onItemRendered($event)"
          (onSubmenuHidden)="menuOptions.onSubmenuHidden($event)"></dx-tree-view>
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDefaultComponent implements OnInit, OnDestroy {
  @Input()
  public config: any;

  @Input()
  public items: any[];

  @Input()
  public menu: any;

  @Input()
  public isSecondary: boolean;

  @Input()
  public context: any;

  @Input()
  public cssClass: string;

  @Input()
  public parentAppName: string;

  public useTreeView: boolean;
  public dataAvailable: boolean;
  public menuItems: MenuItem[] = [];
  public menuOptions: IcDxMenuOptions;

  private translateSubscription: Subscription;
  private breakPointSubscription: Subscription;
  private navigationEndSubscription: Subscription;
  private updateMenuBadges: Subscription;

  private dxMenuComponent: dxMenu;
  private dxTreeViewComponent: dxTreeView;

  private mode: any;
  private badges: any;
  private menuDataSource: any;
  private latestMenuClicked: any;
  private proposedLanguage: string;
  private allowPersonalize: boolean;
  private currentBreakPoint: string;
  private disableLogoutForBecomeUser: boolean;
  private reloadIframeList: any[];

  private get $element() {
    return $(this.elementRef.nativeElement);
  }

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private icRouter: RouterService,
    private appsFacade: AppsFacade,
    private translate: TranslateFacadeService,
    private deviceService: DeviceService,
    private utilService: UtilService,
    private dataSourceService: DataSourceService,
    private accessibilityService: AccessibilityService,
    private personalizationService: PersonalizationService,
    private dynamicReplacementService: DynamicReplacementService,
    router: Router) {

    this.reloadIframeList = [];

    this.navigationEndSubscription = router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.onLocationChangeSuccess());

    this.breakPointSubscription = this.appsFacade.breakPoint$
      .subscribe((breakPoint) => this.currentBreakPoint = breakPoint);

    this.updateMenuBadges = this.appsFacade.events$.pipe(
      filter((event) => AppEvent.MenuBadge.EqualsIgnoreCase(event?.id)),
      tap((event: any) => {
        for (const updateKey in event.state) {
          for (const key in this.badges) {
            if (this.badges.hasOwnProperty(key)) {
              if (this.badges[key]['eventParamName'] == updateKey) {
                this.badges[key]['value'] = event.state[updateKey];
                if (this.dxTreeViewComponent) this.dxTreeViewComponent.repaint();
                if (this.dxMenuComponent) this.dxMenuComponent.repaint();
              }
            }
          }
        }
      })
    ).subscribe();
  }

  private publishAppEvent(eventName: string, eventState: any) {
    this.appsFacade.publishAppEvent(eventName, eventState);
  }

  public onInitializedMenu($event): void {
    this.dxMenuComponent = $event.component;
    $event.component.option(this.menuOptions);
  }

  public onInitializedTreeView($event): void {
    this.dxTreeViewComponent = $event.component;
    $event.component.option('ic', this.menuOptions.ic);
    $event.component.option('ariaLabel', this.menuOptions.ariaLabel);
    $event.component.option('showFirstSubmenuMode', this.menuOptions.showFirstSubmenuMode);
  }

  private getThemeBasedProperties() {
    let navToggle = {} as any;
    let panelLocation = {} as any;
    let state = {} as any;
    IX_Theme.properties.forEach(property => {
      if ('DisableLogoutForBecomeUser'.EqualsIgnoreCase(property.PropertyName)
        && property.Value1 && 'true'.EqualsIgnoreCase(property.Value1)) {
        this.disableLogoutForBecomeUser = true;
      }
      if ('toggleNavigation'.EqualsIgnoreCase(property.PropertyName)) {
        navToggle = property;
      }
      if ('panelLocation'.EqualsIgnoreCase(property.PropertyName)) {
        panelLocation = property;
      }
      if ('state'.EqualsIgnoreCase(property.PropertyName)) {
        state = property;
      }
      if ('ReloadIframeByIdOnClick'.EqualsIgnoreCase(property.PropertyName)
        && property.Value1) {
        this.reloadIframeList = _.map(property.Value1.split('|'), (item) => {
          const [label, iframeId] = item.split(',');
          return {
            label: label?.trim(),
            iframeId: iframeId?.trim()
          };
        });
      }
    });
    return { navToggle, panelLocation, state };
  }

  private controller() {
    this.mode = this.config.Mode;
    this.useTreeView = this.mode == 'TreeView';
    this.proposedLanguage = getLocaleKey();

    // Badges should be in the following form:
    // badges:"[Label1]:[Event Param Name1]|[Label2]:[Event Param Name2]|[repeat as needed per label]"
    // badges:"Messages:MessageCount|Documents:DocumentCount"
    if (this.config.Badges) {
      const badgesDict = {};
      let count = 0;
      const badges = this.config.Badges.split('|');

      for (let i = 0; i < badges.length; i++) {
        const parts = badges[i].split(':');

        if (parts.length == 2) {
          badgesDict[parts[0]] = {
            eventParamName: parts[1],
            value: null,
          };
          count++;
        }
      }

      if (count > 0) {
        this.badges = badgesDict;
      }
    }

    const _home = (WorkflowStepComponent.globalMenu || {}).home;

    let _queryString = this.icRouter.absUrl();
    _queryString = this.utilService.getQueryUrl(_queryString);

    this.allowPersonalize = 'true'.EqualsIgnoreCase(this.config.Personalize);
    this.menuDataSource = this.dataSourceService.createCustomDataSourceForMenu();

    const { navToggle, panelLocation, state } = this.getThemeBasedProperties();

    this.menuItems = this._getPersistedMenuItems(this.isSecondary);
    if (!Array.isArray(this.menuItems)) {
      this.loadMenuDataSource();
    } else {
      _.each(this.menuItems, (item) => this._setDisabledState(item));
      // TODO: figure out an approach to deal with hidePreloadMask
      // setTimeout(() => hidePreloadMask = true, 200);
      this.dataAvailable = true;
    }

    this.menuOptions = {
      ariaLabel: this.config.ariaLabel,
      dataSource: this.menuItems,
      clickPromise: null,
      displayExpr: 'label',
      itemsExpr: 'subMenu',
      adaptivityEnabled: true,
      showFirstSubmenuMode: {
        name: 'onHover',
        delay: { show: 0, hide: 0 },
      },
      ic: {
        utilService: this.utilService,
        accessibilityService: this.accessibilityService,
      },
      itemTemplate: (item) => {
        const menuItemTemplateClass = this._getMenuItemTemplateClass(item);
        const elementText = `<span data-template="true" class="${menuItemTemplateClass}"></span>`;
        const element = $(elementText);
        let resultElement = element;
        element.text(item.label);
        if (!this.useTreeView) {
          element.attr('role', 'link');
        }
        const hasBadge =
          this.badges &&
          this.badges[item.label] &&
          this.badges[item.label]['value'];

        if (this._shouldAddMenuItemSelectedClass(item)) {
          element.attr('aria-current', 'page');
        }

        if (item.fontAwesomeIcon || item.icon || hasBadge) {
          const elementWithIcon = $(
            '<span class="dx-menu-item-text-with-icon-wrapper"></span>'
          );

          elementWithIcon.append(element);

          if (item.fontAwesomeIcon) {
            elementWithIcon.prepend(
              `<i class="dx-menu-item-icon dx-menu-item-icon-fa dx-icon ${item.fontAwesomeIcon}"></i>`
            );
          }

          if (item.icon) {
            elementWithIcon.prepend(
              `<img class="dx-menu-item-icon dx-menu-item-icon-image" src="${item.icon}" />`
            );
          }

          if (hasBadge) {
            const badges = this.badges[item.label]['value'];
            elementWithIcon.append(`<span class='badge'>${badges}</span>`);
          }

          resultElement = elementWithIcon;
        }

        return resultElement;
      },
      onItemExpanded: (e) => {
        if (_.isArray(e.itemData.subMenu)) {
          $(e.itemElement).find("[role='link']").attr('aria-expanded', 'true');
        }
      },
      onItemCollapsed: (e) => {
        if (_.isArray(e.itemData.subMenu)) {
          $(e.itemElement).find("[role='link']").attr('aria-expanded', 'false');
        }
      },
      onContentReady: (e) => {
        e.element = $(e.element);
        setTimeout(() => {
          this.accessibilityService.addAccessibilityToMenuDefault(e.component);
        }, 0);
      },
      onSubmenuShown: (e) => {
        if (!this.useTreeView) {
          e.element = $(e.element);
          setTimeout(() => {
            this.accessibilityService.addAccessibilityToMenuOnShown(e);
          }, 0);
        }
      },
      onItemClick: (data) => {
        this.latestMenuClicked = data;
        const item = data.itemData;
        if (item.cssClass?.contains('IX_CloseMenu')) {
          data.component.repaint();
          return;
        }

        const isSecondaryItem = 'true'.EqualsIgnoreCase(item.secondary);
        const isNavToggleEnabled = this._isNavToggleEnabled(navToggle, this.currentBreakPoint);
        const isContainerItem = !item.link && Array.isArray(item.subMenu) && item.subMenu.length;
        let eventName = '';
        let theData = {};

        if (isContainerItem && data.node) {
          if (data.node.expanded) {
            data.component.collapseItem(data.node.key);
          } else {
            data.component.expandItem(data.node.key);
          }
        }

        if (
          !isContainerItem &&
          !isSecondaryItem &&
          isNavToggleEnabled &&
          !this._isEmpty(panelLocation) &&
          !this._isEmpty(state)
        ) {
          const updatedState = state.Value1.toLowerCase() === 'open' ? 'close' : 'open';
          const command = {
            action: 'toggle side navigation',
            parameters: [
              panelLocation.Value1.toLowerCase(),
              updatedState
            ],
            options: {
              i: 0,
              e: null,
              path: null
            },
          } as CommandConfig;
          this.appsFacade.toggleSideNavigation(command);

          eventName = 'ApplicationEvent.SideNavToggleClosed.Event';
          theData = {
            type: 'toggleClosed',
          };
          this.publishAppEvent(eventName, theData);
        }

        if (item.linkType == 'Logout') {
          this.appsFacade.redirectUserForLogout();
          return;
        }

        if (item.linkType == 'Webhook' && iXing.IX_PlatformMobile) {
          eventName = 'ApplicationEvent.LOBToggling.Event';
          theData = {
            type: 'toggle',
            destination: item.code,
          };
          this.publishAppEvent(eventName, theData);
          return;
        }

        let lnk = this._getFirstLink(item.link);

        if (this.allowPersonalize) {
          const idx = this.personalizationService.getPersonalization(`menu.${item.storageKey}`);
          lnk = idx != undefined ? item.link[idx] : lnk;
        }

        const linkConfig = {
          link: lnk,
          openExternalUrlsInNewTab: "true".EqualsIgnoreCase(item.openExternalUrlsInNewTab),
          reloadApp: item.reloadApp || 'false'
        };

        const promise = Promise.resolve(linkConfig);

        if (_.isEmpty(lnk)) {
          this.menuOptions.clickPromise = promise;
          return;
        }

        this.menuOptions.clickPromise = promise.then((linkConfig) => {

          const link = linkConfig.link;
          const config = this.icRouter.getUrlConfig(link, _queryString, _home);

          if (config.external) {
            // This is for detecting if the clicked on menu item is the sign out button
            // and the application is configured to perform dynamic sign out.
            // If so then we need to perform the LST call and perform dynamic sign out actions.
            if (iXing?.IX_urlForSignout && iXing?.IX_appForSignOut && iXing?.IX_urlForSignout == link) {
              this.icRouter.performDynamicSignOutActions(config.url);
            } else if (linkConfig.openExternalUrlsInNewTab) {
              this.icRouter.navigateExternalOnNewTab(config.url);
            } else {
              this.icRouter.navigateExternal(config.url);
            }
            return config.url;
          }

          return this.getOnRedirectPromise()
            .then(() => {
              const absUrl = this.icRouter.absUrl();
              const queryUrl = this.utilService.getQueryUrl(absUrl);
              const urlMatchCurrent = queryUrl.indexOf(config.url) > -1;
              const reloadApp = 'true'.EqualsIgnoreCase(linkConfig.reloadApp);
              const forceReload = reloadApp && urlMatchCurrent;
              if (forceReload) {
                const frameItem = { label: data.itemData.storageKey };
                const reloadIframe = _.find(this.reloadIframeList, frameItem);
                if (!_.isEmpty(reloadIframe?.iframeId)) {
                  this.utilService.refreshIframe(reloadIframe.iframeId);
                } else {
                  this.icRouter.reload();
                }
              } else {
                return this.icRouter.navigate(config.url);
              }
              return config.url;
            });
        });
      },
      onSubmenuHidden: (e) => {
        e.element = $(e.element);
        this.accessibilityService.addAccessibilityToMenuOnHidden(e);
      },
      onItemRendered: (evt) => {

        const component = evt.component;
        const menuElement = $(evt.component.$element());
        const $itemElement = $(evt.itemElement);
        const $element = $(evt.element);
        const $parentElement = $itemElement.parent();

        evt.element = $(evt.element);
        evt.itemElement = $(evt.itemElement);

        if (this._shouldAddMenuItemSelectedClass(evt.itemData)) {
          this._setAriaCurrentPage($itemElement);
        }

        if (!this.useTreeView) {
          if (menuElement.data('menu-items') == null) {
            menuElement.data('menu-items', []);
          }
          menuElement.data('menu-items').push($itemElement);
          menuElement.attr('data-has-menu-items', 'true');
        }

        let navigationUrls = [];

        if (_.isString(evt.itemData.link)) {
          navigationUrls.push(evt.itemData.link);
        } else if (_.isArray(evt.itemData.link)) {
          navigationUrls = evt.itemData.link;
        }

        if (navigationUrls.length > 0) {
          evt.itemData._index = evt.itemIndex;
          evt.itemData._component = component;
          $itemElement.data('dxMenuItem', evt.itemData);

          navigationUrls.forEach((item) => {
            if (item == null || item === "") {
              return;
            }

            let urlItem;
            try {
              urlItem = new URL(item, 'http://parse.com');
            } catch (er) {
              urlItem = document.createElement('a');
              urlItem.href = item;
            }
            const targetAppInUrl = _.last(urlItem.pathname.split('/'));
            if (targetAppInUrl !== '') {
              $itemElement.addClass('ic-data-menuitem-targetapp-' + targetAppInUrl);
            }
          });
        }

        if (_.isArray(evt.itemData.subMenu)) {
          if ($parentElement.attr('aria-expanded') == undefined) {
            //Submenu-parent nodes are rendered closed, and rendered without an aria-expanded property.
            //Targeting <li> element, in <li aria-expanded="false"><div $itemElement></div></li>
            $parentElement.attr('aria-expanded', 'false');
          }

          const normalizedAppNames = evt.itemData.subMenu.map((item) => {
            return _.isString(item.targetApp) ? item.targetApp.normalize() : '';
          });

          evt.itemData._index = evt.itemIndex;
          evt.itemData._component = component;
          $itemElement.data('dxMenuItem', evt.itemData);

          for (let i = 0; i < normalizedAppNames.length; i++) {
            const normalizedAppName = normalizedAppNames[i];

            if (!_.isEmpty(normalizedAppName)) {
              $itemElement.addClass('ic-dxmenu-parentof-' + normalizedAppName);
            }
          }
        }

        // Missing class causing breaking change after dev extreme upgrade
        $itemElement.addClass('dx-menu-item-has-text');

        if (evt.itemData && evt.itemData.dataAttributes) {
          this.utilService.decorateElementWithDataAttributes($itemElement.get(0), evt.itemData);
        }

        if (_.get(evt, 'itemData.conditionalCss')) {
          const cssClasses = this.getConditionalCssClasses(evt.itemData.conditionalCss);
          evt.itemElement.parent().addClass(cssClasses);
        }

        if (evt.itemData.subMenu) {
          $itemElement.addClass('dx-menu-item-has-submenu');
        }

        if (
          this._shouldAddMenuItemSelectedClass(evt.itemData) &&
          $itemElement.parentsUntil('.ic-menu-default', 'ul').length === 1
        ) {
          // Set focused element for devextreme to correctly set aria-descendant id and
          // then call focus out handler to update focused state and correct classe to be applied
          if (this.useTreeView) {
            const event = { type: 'blur', target: $parentElement, isDefaultPrevented: () => false };
            component.option('focusedElement', $parentElement);
            component._focusOutHandler(event);
          }
          $itemElement.addClass('dx-parent-menu-item-selected');
          $itemElement
            .parent('.dx-treeview-node')
            .addClass('dx-treeview-node-selected')
            .addClass('ic-treeview-node-selected');
          this.accessibilityService.addAccessibilityToTreeViewOnFocusChange(component);
        }

        const listItemParent = $itemElement[0].parentElement;

        if (listItemParent && listItemParent.attributes['aria-level']) {
          const ariaLevel = listItemParent.attributes['aria-level'].value;

          if (ariaLevel == '2') {
            if (listItemParent.hasAttribute('aria-expanded'))
              listItemParent.attributes.removeNamedItem('aria-expanded');

            if (listItemParent.hasAttribute('aria-selected'))
              listItemParent.attributes.removeNamedItem('aria-selected');
          }
        }

        if (!this.useTreeView && $element && $element.length > 0) {
          $element.find('li').attr('role', 'none');
          $element.parents('ul').attr('role', 'menu');
        }

        if (!evt.itemData.subMenu) {
          const ariaCurrent = $itemElement
            .find('[aria-current]:first')
            .attr('aria-current');

          $itemElement.attr('aria-current', ariaCurrent);
        }

        this.accessibilityService.addAccessibilityToMenuOnItemRendered(evt);
      },
    };

    if (this.useTreeView) {
      this.menuOptions = _.extend(this.menuOptions, {
        selectNodesRecursive: false,
        adaptivityEnabled: false,
        expandEvent: 'click',
        hoverStateEnabled: true,
        focusStateEnabled: true,
        noDataText: null,
      });
    }
  }

  private link() {
    this.translateSubscription = this.translate.onLangChange.subscribe(event => {
      if (this.proposedLanguage === event.lang) return;
      this.proposedLanguage = event.lang;
      this.loadMenuDataSource();
    });
  }

  private getConditionalCssClasses(config: string): string {
    const classesToApply = [];
    const pairs = config.split(',');
    pairs.forEach((pair) => {
      if (!pair.contains('|')) return;
      const [cssClass, condition] = pair.split('|');
      const success = this.dynamicReplacementService.evaluateGlobalsConditional(condition);
      if (!success) return;
      classesToApply.push(cssClass);
    });
    return classesToApply.join(' ');
  }

  private loadMenuDataSource(): void {
    this.menuDataSource.load().done((items) => {
      let tempItems = this._getFilteredMenuItems(items, this.isSecondary);
      tempItems = this._coalesceSubMenu(tempItems);
      this.menuItems = tempItems;
      this.menuOptions.dataSource = tempItems;
      this._setPersistedMenuItems(this.isSecondary, this.menuItems);
      // TODO: figure out an approach to deal with hidePreloadMask
      // setTimeout(() => hidePreloadMask = true, 200);
      this.dataAvailable = true;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.controller();
    this.link();
  }

  ngOnDestroy(): void {
    this.translateSubscription?.unsubscribe();
    this.breakPointSubscription?.unsubscribe();
    this.navigationEndSubscription?.unsubscribe();
    this.updateMenuBadges?.unsubscribe();
  }

  private _isMenuItemParent(item) {
    return !_.isNil(item.subMenu) && Array.isArray(item.subMenu);
  }

  private _shouldAddMenuItemSelectedClass(item) {
    let result = false;

    if (this._isMenuItemParent(item)) {
      result = this._isAnySubMenuItemEqualsToCurrentUrl(item.subMenu);
    } else {
      result = this._isMenuItemEqualsToCurrentUrl(item);
    }
    return result;
  }

  private _isAnySubMenuItemEqualsToCurrentUrl(subMenu) {
    let result = false;

    for (let i = 0; i < subMenu.length; i++) {
      result = this._isMenuItemEqualsToCurrentUrl(subMenu[i]);
      if (result) break;
    }
    return result;
  }

  private _isMenuItemEqualsToCurrentUrl(item) {
    const emptyQueryString = '?#';
    const locationHash =
      window.location.hash.indexOf('&') > 0
        ? window.location.hash.substr(0, window.location.hash.indexOf('&'))
        : window.location.hash;

    const questionMarkIndex =
      window.location.href.indexOf(window.location.pathname) +
      window.location.pathname.length;

    const questionMarkToAdd =
      window.location.href[questionMarkIndex] === '?' &&
        window.location.href[questionMarkIndex + 1] === '#' &&
        item.link.indexOf(emptyQueryString) > -1
        ? '?'
        : '';

    const currentUrl =
      window.location.pathname +
      questionMarkToAdd +
      locationHash;

    return this._isUrlInLink(currentUrl, item.link);
  }

  private refreshMenus(menuItem: any): void {
    const menuItemElement = $(menuItem);
    let item = menuItemElement.data('dxMenuItem');
    const resultElement = menuItemElement.find('[data-template]');

    if (
      item &&
      (menuItemElement.text() == this.latestMenuClicked?.itemData?.storageKey
        || menuItemElement.text() == this.latestMenuClicked?.node?.parent?.text)
    ) {
      item = this.latestMenuClicked.itemData;
    }
    if (item && this._shouldAddMenuItemSelectedClass(item)) {
      const $treeView = menuItemElement.closest('.dx-treeview');
      if ($treeView.length === 1 && this._isMenuItemParent(item)) {
        this.dxTreeViewComponent.expandItem(menuItemElement);
      }

      this._setAriaCurrentPage(resultElement);

      resultElement.addClass(this._getMenuItemSelectedClass(item));

      if (menuItemElement.parentsUntil('.ic-menu-default', 'ul').length === 1) {
        menuItemElement.addClass('dx-parent-menu-item-selected');
        menuItemElement
          .parent('.dx-treeview-node')
          .addClass('dx-treeview-node-selected')
          .addClass('ic-treeview-node-selected');
      }
    } else {
      this._removeAriaCurrentPage(resultElement);
      resultElement.removeClass('dx-menu-item-parent-selected');
      resultElement.removeClass('dx-menu-item-selected');

      resultElement
        .find('.dx-menu-item-parent-selected')
        .removeClass('dx-menu-item-parent-selected');
      resultElement
        .find('.dx-menu-item-selected')
        .removeClass('dx-menu-item-selected');

      menuItemElement.removeClass('dx-parent-menu-item-selected');
      menuItemElement
        .parent('.dx-treeview-node')
        .removeClass('dx-treeview-node-selected')
        .removeClass('ic-treeview-node-selected');
    }
  }

  private onLocationChangeSuccess(): void {
    const $adaptiveMenu = this.$element.find('.dx-menu-adaptive-mode');
    const menuIsAdaptive = $adaptiveMenu.length > 0 && $adaptiveMenu[0].attributes.length == 1;
    const itemSelector = menuIsAdaptive ? '.dx-treeview-item' : '.dx-menu-item';
    _.forEach($adaptiveMenu.find(itemSelector), item => this.refreshMenus(item));
  }

  private _getFilteredMenuItems(items: any[], isSecondary: boolean): any[] {
    const filteredItems = _.map(
      _.filter(items, (item) => {
        const itemIsSecondary =
          !_.isNil(item.secondary) &&
          item.secondary.toLowerCase() === 'true';
        return isSecondary ? itemIsSecondary : !itemIsSecondary;
      }),
      (item) => item
    );
    return filteredItems;
  }

  private _getPersistedMenuItemProperty(isSecondary: boolean): string {
    return isSecondary ? 'icMenuSecondaryItems' : 'icMenuDefaultItems';
  }

  private _getPersistedMenuItems(isSecondary: boolean): any[] {
    return window[this._getPersistedMenuItemProperty(isSecondary)] as any[];
  }

  private _setPersistedMenuItems(isSecondary: boolean, val: any[]): void {
    window[this._getPersistedMenuItemProperty(isSecondary)] = val;
  }

  private _isNavToggleEnabled(navToggleProp, currentBreakPoint): boolean {
    if (_.isEmpty(navToggleProp)) {
      return false;
    }

    if (navToggleProp.Value1.toLowerCase() !== 'true') {
      return false;
    }

    if (navToggleProp.Value2) {
      const responsiveMax = _.parseInt(navToggleProp.Value2);

      if (!isNaN(responsiveMax)) {
        const currentWidth = this.deviceService.getCurrentDeviceWidth();

        // Only enabled if the screen is smaller than the configured size.
        if (currentWidth > responsiveMax) {
          return false;
        }
      } else {
        const breakPoints = navToggleProp.Value2.split(',');

        if (breakPoints.indexOf(currentBreakPoint) === -1) {
          return false;
        }
      }
    }

    return true;
  }

  private _setAriaCurrentPage(element: any) {
    element.closest('[role=treeitem]').attr('aria-current', 'page');
  }

  private _removeAriaCurrentPage(element: any) {
    const treeItem = element.closest('[role=treeitem]');
    treeItem.removeAttr('aria-current');
  }

  private _getClearStepEventsConfig(): CommandConfig {
    const commandConfig = {
      action: 'clear step events',
      parameters: [
        {
          directive: 2,
          sourceApp: this.parentAppName,
          targetApp: this.parentAppName,
          sourceFields: [],
          targetFields: [],
        },
      ],
      options: {
        i: 0,
        e: null,
        path: null
      },
      commandListName: this.parentAppName,
      appName: this.parentAppName,
      rid: this.parentAppName,
      currentIndex: -1,
      command: null,
      module: null,
      executionId: '',
    };
    return commandConfig;
  }

  private getOnRedirectPromise(): Promise<any> {
    const commandConfig = this._getClearStepEventsConfig();
    return this.appsFacade.clearStepEvents(commandConfig);
  }

  private _getMenuItemSelectedClass(item) {
    return this._isMenuItemParent(item)
      ? 'dx-menu-item-parent-selected'
      : 'dx-menu-item-selected';
  }

  private _getTargetAppClassName(item) {
    let targetAppClass = '';
    if (!_.isNil(item.targetApp)) {
      targetAppClass = item.targetApp.replace(/\./g, '_') + 'Menu';
    }
    return targetAppClass;
  }

  private _getMenuItemTemplateClass(item: MenuItem) {
    const itemCssClass =
      typeof item.cssClass != 'undefined' ? ' ' + item.cssClass : '';
    let menuItemTemplateClass =
      'dx-menu-item-text ' + this._getTargetAppClassName(item) + itemCssClass;

    if (this._shouldAddMenuItemSelectedClass(item)) {
      menuItemTemplateClass += ' ' + this._getMenuItemSelectedClass(item);
    }
    return menuItemTemplateClass;
  }

  private _isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  private _coalesceSubMenu(menuItems: MenuItem[]) {
    menuItems.forEach((item) => {
      this._setDisabledState(item);

      if (item.subMenu) {
        const labels = {};

        for (let i = 0; i < item.subMenu.length; i++) {
          this._setDisabledState(item.subMenu[i]);
          if (!labels[item.subMenu[i].label]) {
            labels[item.subMenu[i].label] = item.subMenu[i];
          } else {
            if (Array.isArray(labels[item.subMenu[i].label].link)) {
              labels[item.subMenu[i].label].link.push(item.subMenu[i].link);
            } else {
              labels[item.subMenu[i].label].link = [
                labels[item.subMenu[i].label].link,
                item.subMenu[i].link,
              ];
            }
          }
        }

        item.subMenu = Object.keys(labels).map((key) => {
          return labels[key];
        });
      }
    });
    return menuItems;
  }

  private _isUrlInLink(currentUrl, itemLink) {
    let link = false;

    if (itemLink) {
      if (_.isArray(itemLink)) {
        itemLink = itemLink.map(x => '/ui' + x);
        link = _.indexOf(itemLink, currentUrl) >= 0;
      } else {
        itemLink = "/ui" + itemLink;
        link = currentUrl === itemLink;
      }
    }
    return link;
  }

  private _getFirstLink(itemLink) {
    let link = '';
    if (itemLink) {
      if (_.isArray(itemLink)) {
        link = itemLink.length > 0 ? itemLink[0] : '';
      } else {
        link = itemLink;
      }
    }
    return link;
  }

  private _setDisabledState(item) {
    const result = IX_InBecomeUserMode()
      && this.disableLogoutForBecomeUser
      && item.linkType === 'Logout';
    item.disabled = result;
  }

}
