import { Injectable } from '@angular/core';
import { AppEvent } from '../state/app-events.enum';
import { AlertService } from './alert.service';
import { DeviceService } from './device-information.service';
import { AppsConstantsFacade } from './apps-constants.facade';

@Injectable()
export class SessionTimeout {

    public LastActivityTime: Date;
    TimerVisible: boolean;
    IntervalShowAlert: ReturnType<typeof setTimeout>;

    constructor(private appsConstantsFacade: AppsConstantsFacade,
        private deviceService: DeviceService,
        private alertService: AlertService) {
        this.LastActivityTime = new Date();
        this.TimerVisible = false;
    }

    init(): void {

        if (this.TimerVisible && this.deviceService.isIOS()) {
            if (this.shouldWeCheckTimeout()) {
                // If we are still logged in keep session alive until we ok the alert or it logs us out
                this.triggerSessionKeepAliveEvent();
            }
            return;
        }

        this.LastActivityTime = new Date();
        this.TimerVisible = false;
        clearInterval(this.IntervalShowAlert);

        if (!this.shouldWeCheckTimeout())
            return;

        // Start from *now*
        this.triggerSessionKeepAliveEvent();

        // Check and then setup interval to check if we need to show the alert window
        this.showAlertWhenRequired();

        const checkInterval = 2000;
        this.IntervalShowAlert = setInterval(() => this.showAlertWhenRequired(), checkInterval);
    }

    getLastActivityTimeDate(): Date {
        if (!_.isDate(this.LastActivityTime) || isNaN(this.LastActivityTime.getTime()))
            this.LastActivityTime = new Date();
        return new Date(this.LastActivityTime);
    }

    redirectUserForLogout(param?: string): void {
        this.appsConstantsFacade.redirectUserForLogout(param);
    }

    handleOnLogout(): void {
        clearInterval(this.IntervalShowAlert);
        clearInterval(iXing.sessionTimeoutStatus);
        this.TimerVisible = false;
        this.triggerSessionTimeoutEvent();
    }

    triggerSignOutEvent(): void {
        this.triggerEvent(AppEvent.SignOut, "signOut");
    }

    triggerSessionTimeoutEvent(): void {
        this.triggerEvent(AppEvent.SessionTimeout, "signOut");
    }

    triggerSessionKeepAliveEvent(): void {
        const triggerWebHook = this.getThemeProperty("SessionKeepAliveWebHook")
        if (!triggerWebHook) return;
        this.triggerEvent(AppEvent.SessionKeepAlive, "extendsession");
    }

    private triggerEvent(eventName: string, type: string): void {
        // const eventState = {
        //     type: 'dynamicEvent',
        //     action
        // };
        const eventState = { type };
        this.appsConstantsFacade.publishAppEvent(eventName, eventState);
    }

    private shouldWeCheckTimeout(): boolean {
        return IX_UserAuthenticated && !iXing.IX_userInDevelopmentMode;
    }

    private showAlertWhenRequired() {

        if (iXing.pendingSessionExtensionRequests) return;

        //  Determine when warning window should be shown
        const clientSideTimeoutSafetyNet = 5000; // Buffer as time to roundtrip to server might have timed out already if kept alive in last few seconds of client time 
        iXing.IXWarningTimeForCountdown = iXing.IXWarningTimeForCountdown || 300000;
        const showAlertAfterElapsedMilliseconds = IX_TimeoutStart - iXing.IXWarningTimeForCountdown - clientSideTimeoutSafetyNet;
        iXing.showAlertAfterDate = this.addMilliseconds(this.getLastActivityTimeDate(), showAlertAfterElapsedMilliseconds);

        const now = new Date();
        if (now < iXing.showAlertAfterDate) return;

        // Stop checking once warning shown (until reset)
        clearInterval(this.IntervalShowAlert);

        // If no warning required, immediately timeout
        if (iXing.IXWarningTimeForCountdown == 0) {
            this.redirectUserForLogout();
            return;
        }

        if (this.shouldShowTimeoutWarning())
            this.alertUserOfImpendingTimeout();
    }

    private addMilliseconds(date, milliSeconds) {
        date.setTime(date.getTime() + milliSeconds);
        return date;
    }

    private shouldShowTimeoutWarning() {
        return $(".IX_enhanceTimeoutWindow").length === 0;
    }

    private alertUserOfImpendingTimeout() {
        this.alertService.timeoutAlert();
        this.TimerVisible = true;
    }

    private getThemeProperty(propertyName: string): ThemeProperty {
        return _.find(IX_Theme.properties, (item) => item.PropertyName === propertyName);
    }

}
