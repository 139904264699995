import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommandListCollectionService } from "./command-list-collection.service";

import { BaseEffectCommand } from './base-effect.command';
import { GdprService } from '../services/gdpr.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class SetCookieConsentStateCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        protected gdprSvc: GdprService
        ) { 
        super();
    }

    effectCommandName$ = createEffect(
        () => this.actions$.pipe(
            ofType(CommandActions.setCookieConsentStateCommand),
            switchMap((action) => {
                const commandConfig = this.getCommandConfig(action);
                const rule = _.first(commandConfig.parameters) || "";
                const matches = rule.match(/allow|deny/i);
                if (!_.isNil(matches) && matches.length > 0) {
                    this.gdprSvc.reload(rule.toLowerCase() === "allow");
                    return this.getNextActions(commandConfig);
                }
                return throwError("Value '" + commandConfig.parameters[0] + "' not supported. Supported values are: allow/deny.");
            })
        )
    );

}
