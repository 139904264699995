<div class="ic-file-upload-multi" [ngClass]="cssClasses">
  <dx-file-uploader
    [accept]="fileUploadOptions?.accept"
    [allowCanceling]="fileUploadOptions?.allowCanceling"
    [allowedFileExtensions]="fileUploadOptions?.allowedFileExtensions"
    [multiple]="fileUploadOptions?.multiple"
    [maxFileSize]="fileUploadOptions?.maxFileSize"
    [uploadMode]="fileUploadOptions?.uploadMode"
    [uploadUrl]="fileUploadOptions?.uploadUrl"
    [invalidFileExtensionMessage]="localizedInvalidFileExtensionMessage"
    [invalidMaxFileSizeMessage]="localizedInvalidMaxFileSizeMessage"
    [labelText]="localizedDragDropText"
    [selectButtonText]="localizedSelectButtonText"
    [uploadedMessage]="localizedUploadSuccessMessage"
    [uploadFailedMessage]="localizedUploadFailedMessage"
    [value]="value"
    (onInitialized)="fileUploadOptions?.onInitialized($event)"
    (onContentReady)="fileUploadOptions?.onContentReady($event)"
    (onProgress)="fileUploadOptions?.onProgress($event)"
    (onUploaded)="fileUploadOptions?.onUploaded($event)"
    (onUploadAborted)="fileUploadOptions?.onUploadAborted($event)"
    (onUploadError)="fileUploadOptions?.onUploadError($event)"
    (onValueChanged)="fileUploadOptions?.onValueChanged($event)"
  ></dx-file-uploader>

  <div *ngIf="showAllowedExtensions" class="ic-file-upload-multi__note">
    <span>{{ localizedAllowedAttachmentsMessage }}:</span>
    <ul class="ic-file-upload-multi__accepted-files-list">
      {{ allowedFileExtensions.join(', ') }}
    </ul>
  </div>
</div>
