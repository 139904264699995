<div class="popup">
    <dx-popup #targetPopup
        [(visible)]="config.visible"
        [title]="config.popup.title"
        [width]="config.popup.width" [minWidth]="config.popup.minWidth"
        [height]="config.popup.height" [minHeight]="config.popup.minHeight"
        [resizeEnabled]="config.popup.resizeEnabled"
        [showTitle]="config.popup.showTitle"
        [showCloseButton]="config.popup.showCloseButton"
        [closeOnOutsideClick]="config.popup.closeOnOutsideClick"
        [animation]="{ hide: null }"
        (onInitialized)="onInitialized($event)"
        (onShown)="onShown($event)"
        (onShowing)="onShowing($event)"
        (onHidden)="onHidden($event)"
        (onTitleRendered)="onTitleRendered($event)"
        (onOptionChanged)="onOptionChanged($event)">
        <div *dxTemplate="let data of 'content'">
            <dx-scroll-view [direction]="'both'" [height]="'98%'" (onInitialized)="scrollViewOnInitialized($event)">
                <ic-canvas [canvas]="config.canvas" [context]="context" [config]="config.canvasConfig"></ic-canvas>
            </dx-scroll-view>
        </div>
    </dx-popup>
</div>
