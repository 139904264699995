import { Injectable } from '@angular/core';
import { ApplicationInformation } from './application-information.service';
import { HelpersService } from './helpers.service';
import '../libraries/sticky-kit.jquery';
import { DeviceService } from './device-information.service';

@Injectable()
export class SpecialEffectsService {
    
    constructor(private applicationInformation: ApplicationInformation,
        private helpersService: HelpersService,
        private deviceService: DeviceService) { }

    fxEventUnbinder(effectInfo) {
        const initiator = '.' + effectInfo.initiator;
        $('body').off(effectInfo.eventType, initiator);
    }

    keyUpEventSetter(initiator, effectInfo, effects) {
        $('body').off(effectInfo.eventType, initiator)
            .on(effectInfo.eventType, initiator, (e) => {
                if (e.keyCode == 9 || e.keyCode == 13) {
                    e.preventDefault();
                    effects.forEach((it) => {
                        it.effect[it.effectType.toLowerCase()]();
                    });
                }
            });
    }

    clickEventSetter(initiator, effectInfo, effects) {
        $('body').off(effectInfo.eventType, initiator)
            .on(effectInfo.eventType, initiator, (e) => {
                e.preventDefault();
                effects.forEach((it) => {
                    it.effect[it.effectType.toLowerCase()]();
                });
            }).on('keydown', initiator, (e) => {
                if (e.keyCode == 13 || e.keyCode == 32) {
                    e.preventDefault();
                    effects.forEach((it) => {
                        it.effect[it.effectType.toLowerCase()]();
                    });
                }
            });
    }

    readyEventSetter(initiator, effectInfo, effects) {
        const dispSlideShow = setInterval(() => {
            if ($(initiator).length) {
                effects.forEach((it) => {
                    it.effect[it.effectType.toLowerCase()]();
                });
                clearInterval(dispSlideShow);
            }
        }, 100);
    }

    getEffects(effectInfo, containerId) {
        const effects = effectInfo.specialEffectItems || [];
        effects.forEach((effect) => {
            effect.containerId = containerId;
            effect.effect = new this[effect.effectType](effect);
        });
        return effects;
    }

    getInitiator(initiator, containerId, isHolder) {
        containerId = "#" + containerId;
        if (isHolder) {
            initiator = '.' + initiator;
        } else {
            initiator = containerId + '.' + initiator;
        }
        return initiator;
    }

    bindEvents(initiator, effectInfo, containerId) {
        this.fxBindInitiatorToEvents(initiator, effectInfo, containerId);
        if (this.deviceService.IX_isIOS() || this.deviceService.IX_isSafari()) {
            /* Required by Safari on iOS.
               See the following article for more information:
               https://www.quirksmode.org/blog/archives/2010/09/click_event_del.html */
            $(initiator).click(function () { });
        }
    };

    fxEventBinder(effectInfo, isHolder, containerId) {
        const initiator = this.getInitiator(effectInfo.initiator, containerId, isHolder);
        if ($(initiator).length == 0) {
            // If the initiator is not available yet then wait until it gets added to the DOM. 
            // This is a requirement in some mobile browsers including the Native WebView component for iOS.
            var containerNode = document.getElementById(containerId);
            this.helpersService.whenAddedToDom("." + effectInfo.initiator, containerNode).then(() => {
                this.bindEvents(initiator, effectInfo, containerId);
            });
        }
        else {
            this.bindEvents(initiator, effectInfo, containerId);
        }
    }

    fxBindInitiatorToEvents(initiator, effectInfo, containerId) {
        const effects = this.getEffects(effectInfo, containerId);
        switch (effectInfo.eventType.toLowerCase()) {
            case 'click':
                this.clickEventSetter(initiator, effectInfo, effects);
                break;
            case 'keyup':
                this.keyUpEventSetter(initiator, effectInfo, effects);
                break;
            case 'ready':
                this.readyEventSetter(initiator, effectInfo, effects);
                break;
            default:
                IX_Log("component", "Unknown event type", effectInfo.eventType);
                break;
        }
    }

    tearDownEffects(applets) {
        if (Array.isArray(applets)) {
            applets.forEach((applet) => {
                if (Array.isArray(applet.specialEffects) && applet.specialEffects.length > 0) {
                    applet.specialEffects.forEach((effectInfo) => {
                        this.fxEventUnbinder(effectInfo);
                    });
                }
            });
        }
    }

    setUpEffects(applets) {
        const holderMap = {};
        if (Array.isArray(applets)) {
            applets.forEach((applet) => {
                if (Array.isArray(applet.specialEffects) && applet.specialEffects.length > 0) {
                    if (_.isNil(holderMap[applet.name])) {
                        holderMap[applet.name] = this.applicationInformation.isHolderApp(applet.name);
                    }
                    applet.specialEffects.forEach((effectInfo) => {
                        this.fxEventBinder(effectInfo, holderMap[applet.name], applet.rid);
                    });
                }
            });
        }
    }


    //#region FxLibarryV4 effects

    Flip = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;

        this.logState = function () {
            // IX_Log("fxLib", "configurationArray.elementOne: " + configurationArray.elementOne + " visible:" + $("#" + configurationArray.elementOne).is(":visible"));
            // IX_Log("fxLib", "configurationArray.elementTwo: " + configurationArray.elementTwo + " visible:" + $("#" + configurationArray.elementTwo).is(":visible"));
            // IX_Log("fxLib", "configurationArray.elementState:" + configurationArray.elementState);
        };

        this.flip = function () {

            // IX_Log("fxLib", "Before flipping");
            self.logState(configurationArray);
            if (configurationArray.elementState == "Transition") {
                return;
            }

            // IX_Log("fxLib", "flipping");
            configurationArray.elementState == "Transition";
            self.logState(configurationArray);
            configurationArray.subscriber = $("." + configurationArray.elementOne).parent();
            if (configurationArray.subscriber.children("." + configurationArray.elementTwo).length < 1) {
                $("." + configurationArray.elementTwo).appendTo(configurationArray.subscriber).css("display", "none");
            }

            const isFirstElementVisible = $("." + configurationArray.elementOne).is(":visible"); //(typeof configurationArray.elementState == "undefined" || configurationArray.elementState == "FirstShown")
            const direction = isFirstElementVisible ? "lr" : "rl";
            const elementToShow = isFirstElementVisible ? configurationArray.elementTwo : configurationArray.elementOne;
            const elementToHide = isFirstElementVisible ? configurationArray.elementOne : configurationArray.elementTwo;
            const nextState = isFirstElementVisible ? "SecondShown" : "FirstShown";

            configurationArray.subscriber.flip({
                direction: direction,
                speed: configurationArray.duration,
                color: "transparent",
                onBefore: function () {
                    configurationArray.subscriber.wrapInner("<div class='tempWrap' style='width:" + configurationArray.subscriber.width() + "px; height:" + configurationArray.subscriber.height() + "px;'></div>");
                    $("." + elementToHide).hide();
                },
                onEnd: function () {
                    if ($("." + elementToShow).is("span")) {
                        $("." + elementToShow).css("display", "block").show();
                    } else {
                        $("." + elementToShow).show();
                    }
                    configurationArray.subscriber.find(".tempWrap").contents().unwrap();
                    configurationArray.elementState = nextState;
                    self.logState(configurationArray);
                }
            });

            self.logState(configurationArray);
            // IX_Log("fxLib", "END fliiping");
        };

        this.testFlip = function () {

            const self = this;
            const elementOneVisible = $("#" + configurationArray.elementOne).is(":visible");
            const elementTwoVisible = $("#" + configurationArray.elementTwo).is(":visible");

            //IX_Log("fxLib", configurationArray.elementOne + " - " + configurationArray.elementTwo);

            // check if flip was applied
            if ((elementOneVisible && !elementTwoVisible) || (!elementOneVisible && elementTwoVisible)) {
                return "Ok"
            } else if (elementOneVisible && elementTwoVisible) {
                return configurationArray.elementOne + " " + configurationArray.elementTwo + " should not both be visible"
            } else if (!elementOneVisible && !elementTwoVisible) {
                return "No Elements are Visible, either " + configurationArray.elementOne + " or " + configurationArray.elementTwo + " should be"
            } else {
                return "Error: unknown state"
            }
        }
    };


    Appear = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;

        const elementTopOffset = configurationArray.top;
        const elementLeftOffset = configurationArray.left;
        let classOrIdSelector = ".";

        self.subscribeElement = function() {
            if (configurationArray.element.contains('IXCOL_')) {
                classOrIdSelector = "#";
                configurationArray.subscriber = $("#" + configurationArray.element);
            } else {
                configurationArray.subscriber = $("." + configurationArray.element).parent();
            }
        }
        self.subscribeElement();

        self.appear = function () {
            if (configurationArray.subscriber && configurationArray.subscriber.length == 0) {
                self.subscribeElement();
            }

            const effectElement = $(classOrIdSelector + configurationArray.element);

            var afterEffectHandler = function () {
                setTimeout(function () {
                    effectElement
                        .find("[dx-data-grid]")
                        .each(function () {
                            const dxDataGridInstance = $(this).dxDataGrid("instance");

                            if (dxDataGridInstance != null) {
                                dxDataGridInstance.repaint();
                            }
                        });
                }, 0);
            }

            if (typeof window.stickyElems != "undefined" && window.stickyElems.hasOwnProperty(configurationArray.element)) {
                if (effectElement.css("display") == "none")
                    effectElement.css("display", "");

                const options = window.stickyElems[configurationArray.element];
                effectElement.stick_in_parent(options);
            }

            if (configurationArray.subscriber.is("span")) {
                configurationArray.subscriber.css("display", "block");
            }

            if (configurationArray.triggeringElement != void (0)) {
                const parent = $(configurationArray.triggeringElement).parent();
                const inner = parent.find("[data-aria-expanded]");
                if (inner.length > 0) $(configurationArray.triggeringElement).attr("aria-expanded", "true");
            }

            if (elementLeftOffset != void (0) && elementLeftOffset != "auto" && elementTopOffset != "auto") {
                configurationArray.subscriber.show(null, afterEffectHandler);
                effectElement.delay(configurationArray.delay).css({ "position": "absolute", "top": elementTopOffset, "left": elementLeftOffset }).show(null, afterEffectHandler);
            } else {
                configurationArray.subscriber.show(null, afterEffectHandler);
                effectElement.delay(configurationArray.delay).show(configurationArray.duration, afterEffectHandler);
            }

        };

        self.testAppear = function () {
            const self = this;
            const elementVisible = $(classOrIdSelector + configurationArray.element).is(":visible");

            // check if appear was applied
            if (elementVisible) {
                return "Ok"
            } else {
                return "Error: The element is not visible and should be."
            }
        };
    };

    Hide = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        let classOrIdSelector = ".";

        if (configurationArray.element.contains('IXCOL_')) {
            classOrIdSelector = "#";
            configurationArray.subscriber = $("#" + configurationArray.element);
        } else {
            configurationArray.subscriber = $("." + configurationArray.element).parent();
        };

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.hide = function () {
            $(classOrIdSelector + configurationArray.element).delay(configurationArray.delay).hide(configurationArray.duration)

            if (typeof window.stickyElems != "undefined" && window.stickyElems.hasOwnProperty(configurationArray.element)) {
                $(classOrIdSelector + configurationArray.element).trigger("sticky_kit:detach");
            }

            if (configurationArray.triggeringElement != void (0)) {
                const parent = $(configurationArray.triggeringElement).parent();
                const inner = parent.find("[data-aria-expanded]");
                if (inner.length > 0) $(configurationArray.triggeringElement).attr("aria-expanded", "false");
            }
        };

        self.testHide = function () {
            const self = this;
            const elementVisible = $(classOrIdSelector + configurationArray.element).is(":visible");
            // check if hide was applied
            if (!elementVisible) {
                return "Ok";
            } else {
                return "Error: The element is visible and should not be.";
            }
        };
    };

    HideOrAppear = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        var showAriaExpand = function (expandedValue) {
            const parent = $(configurationArray.triggeringElement).parent();
            const inner = parent.find("[data-aria-expanded]");
            if (inner.length > 0) {
                inner.attr("aria-expanded", expandedValue);
            }
            else {
                $(configurationArray.triggeringElement).attr("aria-expanded", expandedValue);
            }
        }

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        setTimeout(function () {
            if ($("." + configurationArray.element).is(":visible")) {
                showAriaExpand("true");
            }
            else {
                showAriaExpand("false");
            }
        }, 0);

        self.hideorappear = function () {
            self.originalVisibility = $("." + configurationArray.element).is(":visible");
            let expandedValue;

            if (self.originalVisibility) {
                $("." + configurationArray.element).delay(configurationArray.delay).hide(configurationArray.duration);

                expandedValue = "false";
            }
            else {
                $("." + configurationArray.element).delay(configurationArray.delay).show(configurationArray.duration);

                expandedValue = "true";
            }

            showAriaExpand(expandedValue);
        };
    }

    AddCssClass = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.addcssclass = function () {
            $("." + configurationArray.element).addClass(configurationArray.additionalParameters.cssClass);
        };
    };

    RemoveCssClass = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.removecssclass = function () {
            $("." + configurationArray.element).removeClass(configurationArray.additionalParameters.cssClass);
        };
    };

    ToggleCssClass = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.togglecssclass = function () {
            $("." + configurationArray.element).toggleClass(configurationArray.additionalParameters.cssClass);
        };
    };

    Grow = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.grow = function () {

            if (configurationArray.width != null && configurationArray.height < 0) {
                if ($("." + configurationArray.element).width() != configurationArray.width) {
                    if ($("." + configurationArray.element).is(":hidden") && configurationArray.width > 0) {
                        $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).css({
                            "display": "block",
                            "overflow": "hidden"
                        }).width(0);
                    }
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).animate({
                        'width': configurationArray.width
                    }, configurationArray.duration);
                }
            } else if (configurationArray.height != null && configurationArray.width < 0) {
                if ($("." + configurationArray.element).is(":hidden") && configurationArray.height > 0) {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).css({
                        "display": "block",
                        "overflow": "hidden"
                    }).width(0);
                }
                $("." + configurationArray.element).delay(configurationArray.delay).animate({
                    'height': configurationArray.height
                }, configurationArray.duration);
            } else {
                if ($("." + configurationArray.element).is(":hidden") && configurationArray.height > 0 && configurationArray.width > 0) {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).css({
                        "display": "block",
                        "overflow": "hidden"
                    }).width(0);
                }
                $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).animate({
                    'height': configurationArray.height,
                    'width': configurationArray.width
                }, configurationArray.duration);
            }
        };
    };

    Parallax = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }
        if (!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
            $(window).load(function () {
                setTimeout(function () {
                    window.skrollr.init({
                        forceHeight: false
                    });
                    /*$(window).resize(function() {
                        skrollrInit.refresh($(".skrollable"));
                    });*/
                }, 300);
            });

            $(document).ready(function () {
                if ($("#skrollr-body").length == 0) {
                    $("body").wrapInner("<div id='skrollr-body'></div>");
                    $("#skrollr-body").css({
                        "position": "absolute",
                        "width": "100%",
                        "height": "100%"
                    });
                };

            });
            window.hasParallax = true;
            window.isMobile = false;
        } else {
            window.isMobile = true;
        }
        window.SlideShowElementArray = [];

        $(window).load(function () {
            setTimeout(function () {

                if (window[configurationArray.exParent] == undefined) {
                    window[configurationArray.exParent] = $(configurationArray.backgroundWrapAsSelector).parent("div");
                };
                if (window[configurationArray.exParent].length > 0) {
                    $(configurationArray.backgroundWrapAsSelector).css({
                        "top": window[configurationArray.exParent].offset().top
                    }).prependTo("body");
                    if (window.isMobile) {
                        $(document).hammer().bind("dragup", "#skrollr-body", function (ev) {
                            ev.preventDefault();
                            ev.stopPropagation();
                            refreshParallaxElementsHeightAndPosition(configurationArray.backgroundWrapAsSelector, window[configurationArray.exParent]);
                        });
                        $(document).hammer().bind("dragdown", "#skrollr-body", function (ev) {
                            ev.preventDefault();
                            ev.stopPropagation();
                            refreshParallaxElementsHeightAndPosition(configurationArray.backgroundWrapAsSelector, window[configurationArray.exParent]);
                        });
                        ;
                    }
                    refreshParallaxElementsHeightAndPosition(configurationArray.backgroundWrapAsSelector, window[configurationArray.exParent]);
                    $(window).scroll(function () {
                        refreshParallaxElementsHeightAndPosition(configurationArray.backgroundWrapAsSelector, window[configurationArray.exParent]);
                    });
                    setInterval(function () {
                        refreshParallaxElementsHeightAndPosition(configurationArray.backgroundWrapAsSelector, window[configurationArray.exParent])
                    }, 300);
                };
            }, 300);
        });

        function refreshParallaxElementsHeightAndPosition(backgroundWrapAsSelector, exParent) {
            var newTop = exParent.offset().top;
            if (window.mainAppHolderDiv == null) {
                window.mainAppHolderDiv = exParent;
            }
            if (window.SlideShowElementArray.length > 0) {
                if (window.SlideShowElementArray.indexOf(exParent[0].id) >= 0)
                    newTop = window.mainAppHolderDiv.position().top;
            }
            if (exParent.is(":visible")) {
                $(backgroundWrapAsSelector).css({
                    "top": newTop
                }).height(exParent.height());
            }
        };

        self.parallax = function () {
            //refreshParallaxElementsHeightAndPosition(configurationArray.backgroundWrapAsSelector, window[configurationArray.exParent]);
        };
    };

    FadeIn = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        const elementTopOffset = configurationArray.top;
        const elementLeftOffset = configurationArray.left;

        self.fadein = function () {

            const fxElement = $("." + configurationArray.element);
            if (fxElement.is(":visible"))
                fxElement.hide();

            if (elementLeftOffset != void (0) && elementLeftOffset != "auto" && elementTopOffset != "auto") {
                fxElement.stop(true, true);
                fxElement.delay(configurationArray.delay).css({
                    "position": "absolute",
                    "top": elementTopOffset,
                    "left": elementLeftOffset
                });
                fxElement.fadeIn(configurationArray.duration);
            } else {
                fxElement.stop(true, true);
                fxElement.delay(configurationArray.delay);
                fxElement.fadeIn(configurationArray.duration);
            }

        };

        self.testFadeIn = function () {
            const elementVisible = $("." + configurationArray.element).is(":visible");
            // check if fadeIn was applied
            if (elementVisible) {
                return "Ok"
            } else {
                return "Error: The element is not visible and should be."
            }
        };
    };

    FadeOut = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.fadeout = function () {
            const fxElement = $("." + configurationArray.element);
            if (fxElement.is(":hidden"))
                return

            fxElement.stop(true, true);
            fxElement.delay(configurationArray.delay);
            fxElement.fadeOut(configurationArray.duration);

        };

        self.testFadeOut = function () {
            const elementVisible = $("." + configurationArray.element).is(":visible");
            if (!elementVisible) {
                return "Ok"
            } else {
                return "Error: The element is not visible and should be."
            }
        };
    };

    ReplaceAndResize = function (configurationArray) {

        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.elementOne).parent();

        $(window).load(function () {
            if ($("." + configurationArray.elementOne).parents(".imageExpandWrap").length === 0) {
                $("." + configurationArray.elementOne).wrap("<div class='imageExpandTop'><div class='imageExpandWrap'></div></div>");
            }

            $("." + configurationArray.elementOne).parents(".imageExpandWrap");
            $("." + configurationArray.elementOne).parents(".imageExpandTop");
        });

        self.replaceandresize = function () {

            let rightPosition = "auto";
            if (configurationArray.effectDirection == "Right") {
                rightPosition = ($("form").width() - (configurationArray.subscriber.position().left + configurationArray.subscriber.width())).toString();
            };

            $("." + configurationArray.elementTwo).css({
                "position": "absolute"
            }).show();
            $("." + configurationArray.elementOne).hide();
            $("." + configurationArray.elementOne).parents(".imageExpandWrap").css({
                "position": "absolute",
                "z-index": "999",
                "right": rightPosition
            }).prepend($("." + configurationArray.elementTwo));

            if (configurationArray.width != null && configurationArray.height < 0) {
                $("." + configurationArray.elementOne).parents(".imageExpandWrap").animate({
                    'width': configurationArray.width
                }, configurationArray.duration);
            } else if (configurationArray.height != null && configurationArray.width < 0) {
                $("." + configurationArray.elementOne).parents(".imageExpandWrap").animate({
                    'height': configurationArray.height
                }, configurationArray.duration);
            } else {
                $("." + configurationArray.elementOne).parents(".imageExpandWrap").animate({
                    'height': configurationArray.height,
                    'width': configurationArray.width
                }, configurationArray.duration);
            }
        }
    };

    ReverseReplaceAndResize = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.elementOne).parent();

        $(window).load(function () {
            if ($("." + configurationArray.elementOne).parents(".imageExpandWrap").length === 0) {
                $("." + configurationArray.elementOne).wrap("<div class='imageExpandTop'><div class='imageExpandWrap'></div></div>");
            }

            $("." + configurationArray.elementOne).parents(".imageExpandWrap");
            $("." + configurationArray.elementOne).parents(".imageExpandTop");
        });

        self.reversereplaceandresize = function () {

            let rightPosition = "auto";
            if (configurationArray.effectDirection == "Right") {
                rightPosition = ($("form").width() - (configurationArray.subscriber.position().left + configurationArray.subscriber.width())).toString();
            };

            $("." + configurationArray.elementOne).css({
                "position": "absolute"
            }).show();
            $("." + configurationArray.elementTwo).hide();
            $("." + configurationArray.elementOne).parents(".imageExpandWrap").css({
                "position": "absolute",
                "z-index": "999",
                "right": rightPosition
            }).prepend($("." + configurationArray.elementOne));

            if (configurationArray.width != null && configurationArray.height < 0) {
                $("." + configurationArray.elementOne).parents(".imageExpandWrap").animate({
                    'width': configurationArray.width
                }, configurationArray.duration, function () {
                    $("#" + configurationArray.elementTwo).hide();
                    $("#" + configurationArray.elementOne).parents(".imageExpandWrap").css({
                        "z-index": ""
                    });
                });
            } else if (configurationArray.height != null && configurationArray.width < 0) {
                $("." + configurationArray.elementOne).parents(".imageExpandWrap").animate({
                    'height': configurationArray.height
                }, configurationArray.duration, function () {
                    $("#" + configurationArray.elementTwo).hide();
                    $("#" + configurationArray.elementOne).parents(".imageExpandWrap").css({
                        "z-index": ""
                    });
                });
            } else {
                $("." + configurationArray.elementOne).parents(".imageExpandWrap").animate({
                    'height': configurationArray.height,
                    'width': configurationArray.width
                }, configurationArray.duration, function () {
                    $("#" + configurationArray.elementTwo).hide();
                    $("#" + configurationArray.elementOne).parents(".imageExpandWrap").css({
                        "z-index": ""
                    });
                });
            }
        };
    };

    SlideOffScreen = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        if (configurationArray.isApplication)
            configurationArray.subscriber = $("." + configurationArray.element);
        else
            configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.slideoffscreen = function () {
            if ($("." + configurationArray.element).is(":hidden")) {
                if ($("." + configurationArray.element).css("display") == "none")
                    return
            }
            if (configurationArray.subscriber.length == 0)
                configurationArray.subscriber = $("." + configurationArray.element);
            let slideDirection = self.configurationArray.effectDirection;
            let subscriberProps;

            const elementProps = {
                offset: $("." + configurationArray.element).position(),
                right: $("." + configurationArray.element).position().left + $("." + configurationArray.element).width(),
                width: $("." + configurationArray.element).width(),
                height: $("." + configurationArray.element).height()
            };
            if (configurationArray.isApplication)
                subscriberProps = configurationArray.subscriber.position();
            else
                subscriberProps = configurationArray.subscriber.offset();
            subscriberProps.position = configurationArray.subscriber.css("position");
            const windowProps = {
                width: $(window).width(),
                height: $(window).height()
            };
            slideDirection = configurationArray.effectDirection;

            if (slideDirection == "Left" || slideDirection == "left") {
                $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).css({
                    "position": "absolute",
                    "top": elementProps.offset.top,
                    "left": elementProps.offset.left
                }).animate({
                    "left": -windowProps.width
                }, configurationArray.duration, function () {
                    $(this).hide().css({
                        "left": elementProps.offset.left,
                        "top": elementProps.offset.top
                    })
                });
            } else if (slideDirection == "Right" || slideDirection == "right") {
                $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).css({
                    "position": "absolute",
                    "top": elementProps.offset.top,
                    "left": elementProps.offset.left
                }).animate({
                    "left": windowProps.width
                }, configurationArray.duration, function () {
                    $(this).hide().css({
                        "left": elementProps.offset.left,
                        "top": elementProps.offset.top
                    })
                });
            } else if (slideDirection == "Down" || slideDirection == "down") {
                if (subscriberProps.position == "absolute") {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).css({
                        "position": "absolute",
                        "top": elementProps.offset.top,
                        "left": elementProps.offset.left
                    }).animate({
                        "top": windowProps.height
                    }, configurationArray.duration, function () {
                        $(this).hide().css({
                            "position": "absolute",
                            "top": subscriberProps.top,
                            "left": subscriberProps.left
                        })
                    });
                } else {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).css({
                        "position": "absolute",
                        "top": elementProps.offset.top,
                        "left": elementProps.offset.left
                    }).animate({
                        "top": windowProps.height
                    }, configurationArray.duration, function () {
                        $(this).hide().css({
                            "position": "",
                            "top": "",
                            "left": ""
                        })
                    });
                }
            } else if (slideDirection == "Up" || slideDirection == "up") {
                if (subscriberProps.position == "absolute") {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).css({
                        "position": "absolute",
                        "top": subscriberProps.top,
                        "left": elementProps.offset.left
                    }).animate({
                        "top": -(elementProps.height + subscriberProps.top)
                    }, configurationArray.duration, function () {
                        $(this).hide().css({
                            "position": "absolute",
                            "top": subscriberProps.top,
                            "left": subscriberProps.left
                        })
                    });
                } else {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).css({
                        "position": "absolute",
                        "top": subscriberProps.top,
                        "left": elementProps.offset.left
                    }).animate({
                        "top": -(elementProps.height + subscriberProps.top)
                    }, configurationArray.duration, function () {
                        $(this).hide().css({
                            "position": "",
                            "top": "",
                            "left": ""
                        })
                    });
                }
            };

        };

        self.testSlideOffScreen = function () {
            const self = this;
            const elementVisible = $("." + configurationArray.element).is(":visible");
            if (!elementVisible) {
                return "Ok"
            } else {
                return "Error: The element is visible and should not be."
            }
        };
    };

    SlideFromOffScreen = function (configurationArray, isApplication) {
        const self = this;
        self.configurationArray = configurationArray;
        if (configurationArray.isApplication)
            configurationArray.subscriber = $("." + configurationArray.element);
        else
            configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.slidefromoffscreen = function () {
            if ($("." + configurationArray.element).is(":visible"))
                return

            if (configurationArray.subscriber.length == 0)
                configurationArray.subscriber = $("." + configurationArray.element);

            let slideDirection = self.configurationArray.effectDirection;
            let subscriberProps;

            const elementProps = {
                offset: $("#" + configurationArray.element).position(),
                right: $("." + configurationArray.element).position().left + $("." + configurationArray.element).width(),
                width: $("." + configurationArray.element).width(),
                height: $("." + configurationArray.element).height()
            };
            const windowProps = {
                width: $(window).width(),
                height: $(window).height()
            };
            if (configurationArray.isApplication) {
                configurationArray.subscriber.show();
                subscriberProps = configurationArray.subscriber.position();
                configurationArray.subscriber.hide();
            } else
                subscriberProps = configurationArray.subscriber.offset();
            subscriberProps.position = configurationArray.subscriber.css("position");
            const initialTop = (configurationArray.isApplication) ? subscriberProps.top : 0;
            slideDirection = configurationArray.effectDirection;
            if (slideDirection == "Left" || slideDirection == "left") {
                if (typeof self.configurationArray.centerVertically != "undefined" && self.configurationArray.centerVertically) {
                    IX_centerElementVertically($("." + configurationArray.element));
                    $(window).resize(function () {
                        IX_centerElementVertically($("#" + configurationArray.element));
                    });
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).show().css({
                        "visibility": "visible",
                        "position": "absolute",
                        "left": -windowProps.width
                    }).animate({
                        "left": subscriberProps.left
                    }, configurationArray.duration, function () {
                        $(this).css({
                            "position": "",
                            "left": subscriberProps.left
                        })
                    });
                } else {
                    if (subscriberProps.position == "absolute") {
                        $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).show().css({
                            "visibility": "visible",
                            "position": "absolute",
                            "top": initialTop,
                            "left": -windowProps.width
                        }).animate({
                            "left": subscriberProps.left
                        }, configurationArray.duration, function () {
                            $(this).css({
                                "position": "absolute",
                                "top": subscriberProps.top,
                                "left": subscriberProps.left
                            })
                        });
                    } else {
                        $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).show().css({
                            "visibility": "visible",
                            "position": "absolute",
                            "top": subscriberProps.top,
                            "left": -windowProps.width
                        }).animate({
                            "left": subscriberProps.left
                        }, configurationArray.duration, function () {
                            $(this).css({
                                "position": "",
                                "top": "",
                                "left": ""
                            })
                        });
                    }
                }
            } else if (slideDirection == "Right" || slideDirection == "right") {
                if (subscriberProps.position == "absolute") {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).show().css({
                        "visibility": "visible",
                        "position": "absolute",
                        "top": initialTop,
                        "left": windowProps.width
                    }).animate({
                        "left": 0
                    }, configurationArray.duration, function () {
                        $(this).css({
                            "position": "",
                            "top": "",
                            "left": ""
                        })
                    });
                } else {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).show().css({
                        "visibility": "visible",
                        "position": "absolute",
                        "top": subscriberProps.top,
                        "left": windowProps.width
                    }).animate({
                        "left": 0
                    }, configurationArray.duration, function () {
                        $(this).css({
                            "position": "",
                            "top": "",
                            "left": ""
                        })
                    });
                }
            } else if (slideDirection == "Down" || slideDirection == "down") {
                if (subscriberProps.position == "absolute") {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).show().css({
                        "visibility": "visible",
                        "position": "absolute",
                        "top": -(elementProps.height + subscriberProps.top),
                        "left": elementProps.offset.left
                    }).animate({
                        "top": subscriberProps.top
                    }, configurationArray.duration, function () {
                        $(this).css({
                            "position": "absolute",
                            "top": subscriberProps.top,
                            "left": subscriberProps.left
                        })
                    });
                } else {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).show().css({
                        "visibility": "visible",
                        "position": "absolute",
                        "top": -(elementProps.height + subscriberProps.top),
                        "left": elementProps.offset.left
                    }).animate({
                        "top": subscriberProps.top
                    }, configurationArray.duration, function () {
                        $(this).css({
                            "position": "",
                            "top": "",
                            "left": ""
                        })
                    });
                }
            } else if (slideDirection == "Up" || slideDirection == "up") {
                if (subscriberProps.position == "absolute") {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).show().css({
                        "visibility": "visible",
                        "position": "absolute",
                        "top": windowProps.height,
                        "left": elementProps.offset.left
                    }).animate({
                        "top": subscriberProps.top
                    }, configurationArray.duration, function () {
                        $(this).css({
                            "position": "absolute",
                            "top": subscriberProps.top,
                            "left": subscriberProps.left
                        })
                    });
                } else {
                    $("." + configurationArray.element).stop(true, true).delay(configurationArray.delay).show().css({
                        "visibility": "visible",
                        "position": "absolute",
                        "top": windowProps.height,
                        "left": elementProps.offset.left
                    }).animate({
                        "top": subscriberProps.top
                    }, configurationArray.duration, function () {
                        $(this).css({
                            "position": "",
                            "top": "",
                            "left": ""
                        })
                    });
                }
            };

        };

        self.testSlideFromOffScreen = function () {
            const self = this;
            const elementVisible = $("." + configurationArray.element).is(":visible");
            if (!elementVisible) {
                return "Ok"
            } else {
                return "Error: The element is not visible and should be."
            }
        };
    };

    Rotate = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.rotate = function () {
            // From: https://stackoverflow.com/a/14016604
            $("." + configurationArray.element).delay(configurationArray.delay).animate(
                {
                    rotation: configurationArray.rotationDegrees
                },
                {
                    duration: configurationArray.duration,
                    step: function (now, fx) {
                        $(this).css({
                            "transform": "rotate(" + now + "deg)"
                        });
                    }
                });
        };

        self.testRotate = function () {
            const elementVisible = $("." + configurationArray.element).is(":visible");

            // check if rotate was applied
            if (elementVisible) {
                return "Ok"
            } else {
                return "Error: The element is not visible and should be."
            }
            //return "Ok" if success, "Error message" if failure
        };
    };

    OpenDown = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();
        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.opendown = function () {
            if (window.hasParallax && !window.isMobile && configurationArray.repositionParallax) {
                $("." + configurationArray.element).show();
                const thisHeight = $("." + configurationArray.element).height();
                const thisPosition = $("." + configurationArray.element).offset();
                configurationArray.animHeight = thisHeight;
                configurationArray.positioning = thisPosition;
                $("." + configurationArray.element).hide();
            }
            if (!configurationArray.subscriber.is(".hiddenDiv")) {
                $("." + configurationArray.element).wrap("<div class='hiddenDiv' style='display: none;'></div>");
                $("." + configurationArray.element).show();
            }
            $("." + configurationArray.element).parent(".hiddenDiv").stop(true, true).delay(configurationArray.delay).slideDown(configurationArray.duration, function () {
                $("." + configurationArray.element).unwrap()
            });
            if (window.hasParallax && !window.isMobile && configurationArray.repositionParallax) {
                $("div[class$='prlx_BGWrap']").each(function () {
                    const prlxThis = $(this);
                    const thisOffset = prlxThis.offset();
                    if (thisOffset.top > configurationArray.positioning.top) {
                        prlxThis.delay(configurationArray.delay).animate({
                            top: "+=" + configurationArray.animHeight
                        }, configurationArray.duration);
                    }
                });
            }
        };

        self.testOpenDown = function () {
            const self = this;
            const elementVisible = $("." + configurationArray.element).is(":visible");

            // check if openDown was applied
            if (elementVisible) {
                return "Ok"
            } else {
                return "Error: The element is not visible and should be."
            }
            //return "Ok" if success, "Error message" if failure
        };
    };

    CloseUp = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        configurationArray.subscriber = $("." + configurationArray.element).parent();

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        self.closeup = function () {

            if ($("." + configurationArray.element).parent().is(":hidden"))
                $("." + configurationArray.element).parent().show();

            if (window.hasParallax && !window.isMobile && configurationArray.repositionParallax) {
                const thisHeight = $("." + configurationArray.element).height();
                const thisPosition = $("." + configurationArray.element).offset();
                configurationArray.animHeight = thisHeight;
                configurationArray.positioning = thisPosition;
            }

            if (!$("." + configurationArray.element).parent().is(".hiddenDiv")) {
                $("." + configurationArray.element).wrap("<div class='hiddenDiv'></div>");
                $("." + configurationArray.element).show();
            }

            $("." + configurationArray.element).parent(".hiddenDiv").stop(true, true).delay(configurationArray.delay).slideUp(configurationArray.duration, function () {
                $("." + configurationArray.element).unwrap().hide()
            });
            if (window.hasParallax && !window.isMobile && configurationArray.repositionParallax) {
                $("div[class$='prlx_BGWrap']").each(function () {
                    const prlxThis = $(this);
                    const thisOffset = prlxThis.offset();
                    if (thisOffset.top > configurationArray.positioning.top) {
                        prlxThis.delay(configurationArray.delay).animate({
                            top: "-=" + configurationArray.animHeight
                        }, configurationArray.duration);
                    }
                });
            }
        };

        self.testCloseUp = function () {
            const self = this;
            const elementVisible = $("." + configurationArray.element).is(":visible");

            // check if closeUp was applied
            if (!elementVisible) {
                return "Ok"
            } else {
                return "Error: The element is visible and should not be."
            }
            //return "Ok" if success, "Error message" if failure
        };
    };

    SlideShow = function (config) {
        'use strict';

        const self = this;
        self.config = config;
        self.config.prlxDivs = [];
        function _hideParallaxDivs(arrayOfDivs, currentIndex) {
            for (var i = 0; i < arrayOfDivs.length; i++) {
                $(arrayOfDivs[i]).hide();
            }
            $(arrayOfDivs[currentIndex]).show();
        }
        function _transitionParallaxDivs(arrayOfDivs, currentIndex, newIndex, duration) {
            for (var i = 0; i < arrayOfDivs.length; i++) {
                if ($(arrayOfDivs[i]).is(":visible"))
                    $(arrayOfDivs[i]).fadeOut(duration);
            }
            $(arrayOfDivs[newIndex]).fadeIn(duration);
        }
        function _updateDefaults(additionalParameters, slideShowDefaults) {
            for (var key in additionalParameters) {
                if (additionalParameters.hasOwnProperty(key)) {
                    var keyVal = additionalParameters[key];
                    if (keyVal.toLowerCase() == "true" || keyVal.toLowerCase() == "false") {
                        slideShowDefaults[key] = (keyVal.toLowerCase() == "true");
                    }
                    else if (!isNaN(keyVal)) {
                        slideShowDefaults[key] = Number(keyVal);
                    }
                    else {
                        slideShowDefaults[key] = keyVal;
                    }
                }
            }
            slideShowDefaults.minSlides = parseInt(slideShowDefaults.minSlides);
            slideShowDefaults.slideMargin = parseInt(slideShowDefaults.slideMargin);
        }
        function _delayStartAuto(slideObj) {
            setTimeout(function () {
                slideObj.startAuto();
            }, 500);
        }
        self.slideshow = function () {

            let multipleSlidesPerPage = false,
                stillFirstPage = true,
                runSlideshow = true;
            const slideShowDefaults = {
                mode: 'fade',
                speed: self.config.duration,
                slideMargin: 10,
                startSlide: 0,
                randomStart: false,
                infiniteLoop: true,
                hideControlOnEnd: true,
                ticker: false,
                tickerHover: false,
                adaptiveHeight: false,
                adaptiveHeightSpeed: 500,
                responsive: false,
                useCSS: true,
                preloadImages: 'visible',
                touchEnabled: false,
                swipeThreshold: true,
                oneToOneTouch: true,
                preventDefaultSwipeX: true,
                preventDefaultSwipeY: false,
                pager: false,
                pagerType: 'full',
                pagerShortSeparator: '/',
                controls: false,
                nextText: 'Next',
                nextprev: 'Prev',
                autoControls: false,
                startText: 'Start',
                stopText: 'Stop',
                autoControlsCombine: false,
                auto: true,
                pause: self.config.delay,
                autoStart: true,
                autoDirection: 'next',
                autoHover: false,
                autoDelay: 0,
                minSlides: 1,
                maxSlides: 1,
                moveSlides: 1,
                slideWidth: self.config.width,
                onSliderLoad: _.noop,
                onSlideBefore: _.noop
            };

            _updateDefaults(self.config.additionalParameters, slideShowDefaults);

            if (slideShowDefaults.minSlides > 1)
                multipleSlidesPerPage = true;

            if (self.config.elementArray.length > 0 && self.config.elementArray[0] == "")
                self.config.elementArray.shift();

            const slideShowSelector = ".slideShow" + self.config.containerId;
            const slideShowSlideSelector = "slideShow" + self.config.containerId + "slide";
            let innerHeight;

            for (var i = 0; i < self.config.elementArray.length; i++) {

                const $currentElement = $("." + self.config.elementArray[i]);

                if ($currentElement.is("div")) {
                    innerHeight = $currentElement.height();
                }
                if (i == 0) {
                    $currentElement.addClass(slideShowSlideSelector).show();
                    var firstSlideWidth = $currentElement.width(),
                        firstSlideHeight = $currentElement.height(),
                        slideShowProperties = {
                            width: (self.config.width > 0) ? self.config.width : firstSlideWidth,
                            height: (self.config.height > 0) ? self.config.height : firstSlideHeight
                        };
                    $currentElement.parent()
                        .addClass("slideShow" + self.config.containerId)
                        .css({
                            width: slideShowProperties.width,
                            height: slideShowProperties.height
                        });
                }
                else if (multipleSlidesPerPage && i > 0 && stillFirstPage) {
                    $currentElement.addClass(slideShowSlideSelector).show();
                    $(slideShowSelector).append($currentElement);
                    if (((i + 1) % slideShowDefaults.minSlides) == 0)
                        stillFirstPage = false;
                }
                else {
                    $currentElement.addClass(slideShowSlideSelector).hide();
                    $(slideShowSelector).append($currentElement);
                }
            }

            if (innerHeight == 0)
                runSlideshow = false;

            if (multipleSlidesPerPage) {
                slideShowDefaults.slideWidth = ($(slideShowSelector).width() / slideShowDefaults.minSlides) - slideShowDefaults.slideMargin;
                $("." + slideShowSlideSelector).css("display", "inline-block");
            } else {
                slideShowDefaults.slideWidth = $(slideShowSelector).width();
            }

            if (runSlideshow) {
                slideShowDefaults.onSliderLoad = function (currentIndex) {
                    _hideParallaxDivs(self.config.prlxDivs, currentIndex);
                };
                slideShowDefaults.onSlideBefore = function ($slideElement, oldIndex, newIndex) {
                    _transitionParallaxDivs(self.config.prlxDivs, oldIndex, newIndex, self.config.duration);
                }

                const $sliderInstance = $(slideShowSelector).bxSlider(slideShowDefaults);

                $($sliderInstance).each(function () {
                    const thisNow = $(this);
                    thisNow.find("div[class$='prlx_BGWrap']").each(function () {
                        self.config.prlxDivs.push("." + $(this).attr("class"));
                    });
                });
                _hideParallaxDivs(self.config.prlxDivs, 0);
                $(slideShowSelector).parents(".bx-wrapper").addClass("slideShow" + self.config.containerId + "wrap");
                $sliderInstance.parent().addClass("forceHeightAuto");
                //Two Helper functions for restarting the auto slideshow when next, prev, or pager is clicked
                if (slideShowDefaults.pager) {
                    $(slideShowSelector).parents(".bx-wrapper").find(".bx-pager-item a").click(function (e) {
                        var i = $(this).attr("data-slide-index");
                        $sliderInstance.goToSlide(i);
                        $sliderInstance.stopAuto();
                        if (slideShowDefaults.auto) {
                            _delayStartAuto($sliderInstance);
                        }
                        return false;
                    });
                }
                if (slideShowDefaults.controls) {
                    $(slideShowSelector).parents(".bx-wrapper").find(".bx-prev, .bx-next").click(function (e) {
                        $sliderInstance.stopAuto();
                        if (slideShowDefaults.auto) {
                            _delayStartAuto($sliderInstance);
                        }
                        return false;
                    });
                    const $objId = $(".slideShow" + self.config.containerId + " .slideShow" + self.config.containerId + "slide");
                    $objId.hammer().on("tap", function (ev) {
                        ev.preventDefault();
                        ev.stopPropagation();
                    });
                    $objId.hammer().on("dragright", function (ev) {
                        ev.preventDefault();
                        ev.stopPropagation();
                        $sliderInstance.goToNextSlide();
                        $sliderInstance.stopAuto();
                        if (slideShowDefaults.auto) {
                            _delayStartAuto($sliderInstance);
                        };
                        return false;
                    });
                    $objId.hammer().on("dragleft", function (ev) {
                        ev.preventDefault();
                        ev.stopPropagation();
                        $sliderInstance.goToPrevSlide();
                        $sliderInstance.startAuto();
                        $sliderInstance.stopAuto();
                        if (slideShowDefaults.auto) {
                            _delayStartAuto($sliderInstance);
                        };
                        return false;
                    });
                }
            }
        };

        self.testSlideShow = function () {

            const self = this;
            const elementVisible = $("." + self.config.element).is(":visible");
            if (!elementVisible) {
                return "Ok"
            } else {
                return "Error: The element is visible and should not be."
            }

        };

    };

    Overlay = function (configurationArray) {

        const self = this;
        //For v4 effects
        self.overlay = () => {
            this.overlayHelpFunc(configurationArray);
        };

        //For v1 effects
        self.appear = () => {
            this.overlayHelpFunc(configurationArray);
        };
    };

    overlayHelpFunc = function (configurationArray) {
        const self = this;
        self.configurationArray = configurationArray;
        self.configurationArray.subscriber = $("." + configurationArray.elementOne).parent();
        if (configurationArray.subscriber.children("." + configurationArray.elementTwo).length < 1) {
            $("." + configurationArray.elementTwo).prependTo(configurationArray.subscriber).css("display", "none");
        }

        if (configurationArray.subscriber.is("span")) {
            configurationArray.subscriber.css("display", "block");
        }

        const parentOffset = configurationArray.subscriber.offset();
        let elementTopOffset, elementLeftOffset;

        if (configurationArray.top != "auto") {
            elementTopOffset = parentOffset.top + configurationArray.top;
        } else {
            elementTopOffset = "auto";
        }
        if (configurationArray.left != "auto") {
            elementLeftOffset = parentOffset.left + configurationArray.left;
        } else {
            elementLeftOffset = "auto";
        }
        $("." + configurationArray.elementTwo).css({ "position": "absolute", "top": elementTopOffset, "left": elementLeftOffset, "z-index": "998" }).show(configurationArray.duration);

    };

    /* Used to force a tabstop anywhere on the page. */
    TabStop = function (configuration) {
        const cssSelector = configuration.additionalParameters["selector"];
        const ariaLabel = configuration.additionalParameters["aria-label"];
        const delayInSecs = configuration.additionalParameters["effect-delay-seconds"];

        let time = 200;
        if (!_.isEmpty(delayInSecs))
            time = parseFloat(delayInSecs) * 1000;

        setTimeout(
            function () {
                const element = $(cssSelector)

                element.attr("aria-label", ariaLabel);
                element.attr("tabindex", "0");
            }, time
        );

    }

    //#endregion
}
