import { createAction, props } from '@ngrx/store';
import { AppsEntity, ListDataEntity } from './apps.models';

export const updateCommandState = createAction(
  '@ic/command/update-state',
  props<{ commandState: AppsEntity[], commandName: string, commandListName: string }>()
);

export const setStep = createAction(
  '@ic/apps/set-step',
  props<{ step: string }>()
);

export const setWorkflow = createAction(
  '@ic/apps/set-workflow',
  props<{ workflow: string }>()
);

export const setBreakPoint = createAction(
  '@ic/apps/set-break-point',
  props<{ breakPoint: string }>()
);

export const setContextName = createAction(
  '@ic/apps/set-context-name',
  props<{ contextName: string }>()
);

export const setThemeName = createAction(
  '@ic/apps/set-theme-name',
  props<{ themeName: string }>()
);

export const setStateMode = createAction(
  '@ic/apps/set-state-mode',
  props<{ stateMode: string }>()
);

export const updateAppState = createAction(
  '@ic/apps/update-app-state',
  props<{ appName: string, appState: AppState }>()
);

export const resetAppState = createAction(
  '@ic/apps/reset-app-State',
  props<{ appName: string, forceDelete: boolean }>()
);

export const loadApplicationSuccess = createAction(
  '@ic/apps/load-application-success',
  props<{ appName: string, appState: AppState }>()
);

export const loadApplication = createAction(
  '@ic/apps/load-application',
  props<{ ecdRequest: EcdRequest, dynamicReplacements: Record<string, any> }>()
);

export const loadApplicationFailure = createAction(
  '@ic/apps/load-application-failure',
  props<{ error: string }>()
);

export const onError = createAction(
  '@ic/apps/on-error',
  props<{ error: string, info: any }>()
);

export const onCommandError = createAction(
  '@ic/apps/on-command-error',
  props<{ error: string, route: any }>()
);

export const loadListData = createAction(
  '@ic/apps/load-list-data',
  props<{ ecdRequest: EcdRequest }>()
);

export const loadListDataSuccess = createAction(
  '@ic/apps/load-list-data-success',
  props<{ appName: string, listData: any[] }>()
);

export const replaceListData = createAction(
  '@ic/apps/replace-list-data',
  props<{ appName: string, listDataEntity: ListDataEntity }>()
);

export const updateServerListData = createAction(
  '@ic/apps/mup-list-data',
  props<{ ecdRequest: EcdRequest, deferrers: any[] }>()
);

export const mupListDataSuccess = createAction(
  '@ic/apps/mup-list-data-success',
  props<{ appName: string }>()
);

export const mupApplicationFailure = createAction(
  '@ic/apps/mup-application-failure',
  props<{ error: string }>()
);

export const setIsPlatformMobile = createAction(
  '@ic/apps/set-is-platform-mobile'
);

export const setAppDirtyField = createAction(
  '@ic/apps/set-app-dirty-field',
  props<{ appName: string, fieldName: string, isDirty: boolean }>(),
);

export const setAppPristineFieldValue = createAction(
  '@ic/apps-set-app-pristine-field-value',
  props<{ appName: string, fieldName: string, value: string | number | boolean }>()
);

export const setCurrentAsPristine = createAction(
  '@ic/apps/set-current-as-pristine',
  props<{ appName: string }>()
);

export const resetDirtyFieldsToPristine = createAction(
  '@ic/apps/reset-dirty-fields-to-pristine',
  props<{ appName: string }>()
);

export const clearDirtyFields = createAction(
  '@ic/apps/clear-dirty-fields',
  props<{ appName: string }>()
);

export const setAppStateDirty = createAction(
  '@ic/apps/set-app-state-dirty',
  props<{ appName: string }>()
);

export const refreshValidationGroupButtons = createAction(
  '@ic/apps/refresh-validation-group-buttons',
  props<{ validationGroup: string, isValid?: boolean }>()
);

export const setDataDisplayBehaviorOnEventOnly = createAction(
  '@ic/apps/set-data-display-behavior-on-event-only',
  props<{ holderAppName: string, appNames: string[] }>()
);

export const disableDisplayBehaviorOnEventOnly = createAction(
  '@ic/apps/disable-display-behavior-on-event-only',
  props<{ holderAppName: string, appName: string }>()
);

export const disableDisplayBehaviorOnEventOnlyAllAppInstances = createAction(
  '@ic/apps/disable-display-behavior-on-event-only-all-app-instances',
  props<{ appName: string }>()
);

export const resetContext = createAction(
  '@ic/apps/reset-apps-state',
  props<{ resetOptions: ResetOptions }>()
);

export const redirectUserForLogout = createAction(
  '@ic/apps/redirect-user-logout',
  props<{ extParam: string }>()
);

/**
 * DevExtreme handles list-edit reordering by keyboard, using internal scripting.
 * Its implementation will lose keyboard focus when ngRx state is updated.
 * Call this action after a user reorders a list item via keyboard.
 * Send in a callback that will handle restoring keyboard focus
 * to the element that just moved.
 */
export const updateListKeyboardFocus = createAction(
  '@ic/apps/update-list-keyboard-focus',
  props<{ callback: () => void }>(),
);

export const pillListPillClicked = createAction(
  '@ic/apps/pill-list-pill-clicked',
  props<{ id: string | number }>()
);

export const pillListPillCloseClicked = createAction(
  '@ic/apps/pill-list-pill-close-clicked',
  props<{ id: string | number }>()
);
