/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { CustomRule } from 'devextreme/ui/validation_rules';
import { DxFileUploaderComponent } from 'devextreme-angular';
import ProgressBar from 'devextreme/ui/progress_bar';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { AccessibilityService } from '../../services/accessibility.service';
import { IcValidator } from '../../services/validation-engine/ic-validator';
import { ComponentService } from '../../services/component.service';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { ValidationGroupService } from '../../services/validation-group.service';
import { AppsFacade } from '../../state/apps.facade';
import { AppEvent } from '../../state/app-events.enum';
import UtilityFunctions from '../../utility.functions';

export type FileUploadMultiConfig = {
  handlerUrl: string,
  sequence?: number;
  appName?: string,
  allowCanceling?: boolean,
  ariaLabel?: string,
  icAllowInvalidFilenameCharacters?: boolean;
  maxFileSize?: number,
  showStatus?: boolean,
  validationGroup?: string,
  fields?: {
    id: string,
    originalFileName: string,
  },
  allowedFileExtensions?: string,
};

@Component({
  selector: 'ic-file-upload-multi',
  templateUrl: './file-upload-multi.component.html',
  styleUrls: ['./file-upload-multi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadMultiComponent implements OnInit, OnChanges, OnDestroy {

  @Input() fieldName: any;
  @Input() model: any;
  @Input() config: FileUploadMultiConfig;
  @Input() applet: Applet
  @Input() context: any;
  @Input() conditionalFormats: any;
  @Input() parentModel: any;
  @Input() cssClass: string;
  @Input() checkSize: any;

  @Output() updateState = new EventEmitter<AppStateChange>();

  @ViewChild(DxFileUploaderComponent)
  fileUploader: DxFileUploaderComponent;

  cssClasses: string[];

  fileOptions = {} as FileOptions;
  uploadInProgress = false;
  fileUploadOptions: any;
  fileDetails: any = [];
  value: any = [];
  allowedFileExtensions: any = [
    '.jpg',
    '.jpeg',
    '.heic',
    '.png',
    '.gif',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.pdf',
    '.zip',
  ];
  localizedDragDropText: any;
  localizedInvalidFileExtensionMessage: any;
  localizedAllowedAttachmentsMessage: any;
  localizedInvalidFilenameCharacterMessage: any;
  localizedInvalidMaxFileSizeMessage: any;
  localizedSelectButtonText: any;
  localizedUploadFailedMessage: any;
  localizedUploadSuccessMessage: any;

  onSubscription: Subscription;

  get showAllowedExtensions(): boolean {
    return this.localizedAllowedAttachmentsMessage
      && this.allowedFileExtensions?.length > 0;
  }

  constructor(private http: HttpClient,
    private translate: TranslateFacadeService,
    private changeDetectorRef: ChangeDetectorRef,
    private appsFacade: AppsFacade,
    private componentService: ComponentService,
    private validationEngine: ValidationEngineService,
    private validationGroupService: ValidationGroupService,
    private accessibilityService: AccessibilityService) {
    this.onSubscription = new Subscription();
    const onResetSubscription = this.appsFacade.events$
      .pipe(
        filter(event => AppEvent.ResetFileUploadMulti.EqualsIgnoreCase(event?.id)
          && this.applet?.name.EqualsIgnoreCase(event?.state?.appName.toString())),
        tap(() => this.resetDxComponent())
      ).subscribe();
    this.onSubscription.add(onResetSubscription);
  }

  ngOnInit(): void {
    this.controller();
    this.updateTranslations();
  }

  ngOnDestroy(): void {
    this.onSubscription?.unsubscribe();
    this.componentService.cleanApplicationRef(this.applet.name);
  }

  ngOnChanges(changes): void {
    this.updateComponent();
  }

  ngAfterViewInit(): void {
    this.addAccessibility();
    const translateSubscription = this.translate.onLangChange.subscribe(() => {
      this.updateComponent();
      this.changeDetectorRef.detectChanges();
    });
    this.onSubscription.add(translateSubscription);
    UtilityFunctions.bindPTierComponentFunction(this.applet.name, this.config.fields.originalFileName, 'resetDxComponent', this.resetDxComponent, this);
  }

  private publishAppEvent(eventName, data) {
    this.appsFacade.publishAppEvent(eventName, data);
  }

  private updateComponent() {
    this.updateTranslations();
    this.addAccessibility();
  }

  setFileDetails(name, lastModified, id, file, url, deleteUrl) {
    this.fileOptions.name = name;
    this.fileOptions.lastModified = lastModified;
    this.fileOptions.id = id;
    this.fileOptions.file = file;
    this.fileOptions.url = url;
    this.fileOptions.deleteUrl = deleteUrl;
    return this.fileOptions;
  }

  getFileDetailsByFileId(id): any {
    return _.first(this.fileDetails.filter((o) => id === o.id));
  }

  updateFieldValues() {
    this.updateState.emit({
      field: this.config.fields.originalFileName,
      value: this.getValue('name')
    });
    this.updateState.emit({
      field: this.config.fields.id,
      value: this.getValue('id')
    });
  }

  getValue(propertyName) {
    return this.fileDetails.map((o) => o[propertyName]).join();
  }

  isValid(element) {
    const badUploadExists = !!element.find('.dx-fileuploader-invalid').length;
    IX_Log('component', 'Validating icFileUploadMulti; bad upload exists: ' + badUploadExists + '; upload in progress: ' + this.uploadInProgress);
    return !badUploadExists && !this.uploadInProgress;
  }

  updateValidation() {
    if (this.config.validationGroup)
      this.validationGroupService.publishRefreshButtons(this.applet.name, this.config.validationGroup);
  }

  deleteFile(fileId) {
    const fileDetails = this.getFileDetailsByFileId(fileId);
    const qryChar = this.config.handlerUrl.indexOf('?') != -1 ? '&' : '?';
    const deleteUrl = this.config.handlerUrl + qryChar + 'IX_DEL=' + fileId;
    if (fileDetails) this.value.splice(this.value.indexOf(fileDetails.file), 1);
    const deleteSubscription = this.http.post(deleteUrl, {})
      .subscribe(() => {
        this.fileDetails.splice(this.fileDetails.indexOf(fileDetails), 1);
        this.updateFieldValues();
      });
    this.onSubscription.add(deleteSubscription);
  }

  resetDxComponent(): void {
    this.value = [];
    this.fileDetails = [];
    this.fileUploader.instance.reset();
    this.updateFieldValues();
  }

  private addAccessibility() {
    if (!this.fileUploader) return;
    const $element = this.fileUploader.instance.$element();
    this.accessibilityService.addAriaLabelFromTranslation('MULTIFILEUPLOAD_UPLOAD_ARIA_LABEL', $element, "[type='file']");
  }

  private updateTranslations() {
    this.localizedDragDropText = this.translate.instant('MULTIFILEUPLOAD_DRAGDROPTEXT');
    this.localizedSelectButtonText = this.translate.instant('MULTIFILEUPLOAD_SELECTBUTTONTEXT');
    this.localizedUploadFailedMessage = this.translate.instant('MULTIFILEUPLOAD_UPLOAD_FAILED_MESSAGE');
    this.localizedAllowedAttachmentsMessage = this.translate.instant('MULTIFILEUPLOAD_ATTACHMENTMESSAGE');
    this.localizedUploadSuccessMessage = this.translate.instant('MULTIFILEUPLOAD_UPLOAD_SUCCESS_MESSAGE');
    this.localizedInvalidMaxFileSizeMessage = this.translate.instant('MULTIFILEUPLOAD_INVALID_MAX_FILE_SIZE_MESSAGE');
    this.localizedInvalidFileExtensionMessage = this.translate.instant('MULTIFILEUPLOAD_INVALID_FILE_EXTENSION_MESSAGE');
    this.localizedInvalidFilenameCharacterMessage = this.translate.instant('MULTIFILEUPLOAD_INVALID_FILENAME_CHARACTER_MESSAGE');
  }

  private setCssClasses(): void {
    this.cssClasses = [];
    if (this.cssClass)
      this.cssClasses.push(this.cssClass);
    if (this.config?.showStatus)
      this.cssClasses.push('ic-show-status-messages');
  }

  private controller(): void {
    this.setCssClasses();
    if (this.config.allowedFileExtensions && this.config.allowedFileExtensions.trim().length > 0) {
      const allowedFileExtensions = this.config.allowedFileExtensions.split(',');
      this.allowedFileExtensions = _.map(allowedFileExtensions, (extension) => extension.trim());
    }
    this.fileUploadOptions = {
      accept: this.allowedFileExtensions.join(),
      allowCanceling: this.config.allowCanceling,
      allowedFileExtensions: this.allowedFileExtensions,
      ariaLabel: this.config.ariaLabel,
      appName: this.config.appName,
      maxFileSize: this.config.maxFileSize,
      multiple: true,
      icAllowInvalidFilenameCharacters: this.config.icAllowInvalidFilenameCharacters,
      uploadMode: 'instantly',
      uploadUrl: this.config.handlerUrl,
      onContentReady: (e) => {
        $(e.element)
          .find('.dx-fileuploader-button')
          .removeClass('dx-button')
          .addClass('custom-dx-fileuploader-button');
      },
      onInitialized: (e) => {
        if (this.config.validationGroup) {
          const customRule = {
            enabled: true,
            type: 'custom',
            validationCallback: () => {
              const $element = $(e.element);
              return this.isValid($element);
            },
          } as CustomRule;
          const validatorOptions = {
            validationGroup: this.config.validationGroup,
            validationRules: [customRule],
          };
          new IcValidator(this.validationEngine, e, validatorOptions);
        }
      },
      onProgress: (e) => {
        this.uploadInProgress = true;
        this.updateValidation();
        const index = this.value.indexOf(e.file);
        const fileContainerEl = $($(e.element).find('.dx-fileuploader-file-container').get(index));
        const progressBarEl = $(fileContainerEl.find('.dx-progressbar'));
        if (!progressBarEl) {
          return;
        }
        const instance = ProgressBar.getInstance(progressBarEl);
        instance.option({
          showStatus: false,
          value: false,
        });
      },
      onUploaded: (e) => {
        this.uploadInProgress = false;
        this.updateValidation();
        const resp = JSON.parse(e.request.response)['Value'];
        if (resp && !this.getFileDetailsByFileId(resp.fileId)) {
          const uploadedFile = this.setFileDetails(
            e.file.name,
            e.file.lastModified,
            resp.fileId,
            e.file,
            resp.url,
            resp.delete_url
          );
          this.fileDetails.push(uploadedFile);
        }

        // Add delete buttons since this is not supported by DX FileUploader w/ uploadMode === 'instantly'
        const $fileContainers = $(e.element).find('.dx-fileuploader-file-container');
        _.forEach($fileContainers, (fileContainer, index) => {
          const fileContainerEl = $(fileContainer);
          // Element index matches uploaded file index in this.value
          const fileIndex = this.value.indexOf(e.file);
          // Does not already have delete button
          const hasDeleteButton = !fileContainerEl.find('.dx-fileuploader-delete-button').length;
          if (hasDeleteButton && fileIndex === index) {
            const onDeleteHandler = (docId: string) => this.deleteFile(docId);
            const deleteElement = this.componentService.createFileDeleteComponent(this.applet.name, resp.fileId, onDeleteHandler);
            fileContainerEl.append(deleteElement);
          }
        });

        this.updateFieldValues();
        const data = {
          type: 'uploadDocument',
          from: e.file.name,
          fileType: e.file.type,
          success: !resp.errorMessage ? 'true' : 'false',
        };
        this.publishAppEvent(AppEvent.DocumentUpload, data);
      },
      onUploadAborted: (e) => {
        this.uploadInProgress = false;
        this.updateValidation();
      },
      onUploadError: (e) => {
        this.uploadInProgress = false;
        this.updateValidation();
        const data = {
          type: 'uploadDocument',
          from: e.file.name,
          fileType: e.file.type,
          success: 'false',
        };
        this.publishAppEvent(AppEvent.DocumentUpload, data);
        this.updateValidation();
      },
      onValueChanged: (e) => {
        this.updateValidation();
        this.value = e.value;
        if (
          !_.every(e.value, (o) => {
            const parts = o.name.split('.');
            return !parts.length
              ? true
              : this.allowedFileExtensions.indexOf('.' + parts[parts.length - 1].toLowerCase()) > -1;
          })
        ) {
          e.component.option('value', e.previousValue);
        }
      },
    };
  }
}
