import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from "./command-list-collection.service";

import { CacheManagerService } from '../services/cachemanager.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { AppsFacade } from '../state/apps.facade';
import { AppEvent } from '../state/app-events.enum';
import { switchMap } from 'rxjs/operators';
import { ApplicationInformation } from '../services/application-information.service';
import { EventsService } from '../services/events.service';

@Injectable()
export class ClearAllFileMultiUploadFieldsCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected domComponentRetrievalService: DomComponentRetrievalService,
        protected cacheManagerService: CacheManagerService,
        protected appsFacade: AppsFacade,
        private applicationInformation: ApplicationInformation,
        private eventsService: EventsService) {
        super();
    }

    effectCommandName$ = createEffect(
        () => this.actions$.pipe(
            ofType(CommandActions.clearAllFileMultiUploadFieldsCommand),
            switchMap(action => {
                const commandConfig = this.getCommandConfig(action);
                const appName = _.first(commandConfig.parameters);
                this.resetAppFieldMultiUploadFields(appName);
                return this.getNextActions(commandConfig);
            })
        )
    )

    private resetAppFieldMultiUploadFields(appName: string): void {
        if (!this.applicationInformation.isInputApp(appName)) return;
        const event = this.createAppEntity(AppEvent.ResetFileUploadMulti, { appName });
        this.eventsService.publishEvent(event);
    }
}
