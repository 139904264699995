import { Injectable } from "@angular/core";

@Injectable()
export class MetaTagsService {

		defaultTags = {};
		tagElements = [];

		setDefaultTags(tags) {
			this.defaultTags = { ...tags };
			this.setTags({});
		}
		addTags(tags) {
			_.forEach(tags, (content, name) => {
				const tagElement = this.getTagElement(content, name);
				document.head.appendChild(tagElement);
				this.tagElements.push(tagElement);
			});
		}
		setTags(tags) {
			this.clearTags();
			this.mergeDefaultTags(tags);
			this.addTags(tags);
		}
		resetTags() {
			this.clearTags();
			this.mergeDefaultTags({});
		}
		mergeDefaultTags(tags) {
			_.forEach(this.defaultTags, (defaultTagContent, defaultTagName) => {
				if (!tags[defaultTagName]) {
					tags[defaultTagName] = defaultTagContent;
				} else if (defaultTagName === 'title') {
					tags['title'] += ' - ' + defaultTagContent;
				}
			});
			return tags;
		}
		getTagElement(content, name) {
			if (name == 'title') {
				let title = $('title')[0];

				if (_.isNil(title))
					title = document.createElement('title');

				title.textContent = content;
				return title;
			} else {
				// Opengraph uses [property], but everything else uses [name]
				const nameAttr = (name.indexOf('og:') === 0) ? 'property' : 'name';
				let meta = $('meta[' + nameAttr + '="' + name + '"]')[0];
				if (_.isNil(meta)) {
					meta = document.createElement('meta');
					meta.setAttribute(nameAttr, name);
				}

				if (name.indexOf('image') > 0 && !content.startsWith("http")) {
					const full = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

					if (content[0] == '/')
						content = full + content;
					else
						content = full + '/' + content;
				}

				meta.setAttribute('content', content);
				return meta;
			}
		}
		clearTags() {
			_.forEach(this.tagElements, (tagElement) => {
				if (document.head.contains(tagElement))
					document.head.removeChild(tagElement);
			});
			this.tagElements.length = 0;
		}
		setMetaTagsForViewport() {
			const customContent = 'width=device-width, initial-scale=1.0';
			$('meta[name=viewport]').attr('content', customContent);
		}
}