import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as AppsSelectors from '../state/apps.selectors';
import UtilityFunctions from '../utility.functions';

@Injectable()
export class StoreService {

  constructor(private store: Store) {
    UtilityFunctions.bindPTierAPI('getAppState', this.getCurrentAppStateSync, this);
  }

  getCurrentAppsState(): Observable<any> {
    return this.store.pipe(select(AppsSelectors.getCurrentAppsState));
  }

  getCurrentAppState(appName: string): Observable<AppState> {
    return this.store.pipe(select(AppsSelectors.getCurrentAppState, { appName }));
  }

  getCurrentAppStateSync(appName: string): AppState {
    let appState: AppState;
    this.store.pipe(select(AppsSelectors.getCurrentAppState, { appName })).subscribe(s => appState = s);
    return { ...appState };
  }

  getCurrentListFilters(appName: string): Observable<DataSourceFilterDetails> {
    return this.store.pipe(select(AppsSelectors.getCurrentListFilterSource, { appName }));
  }

}
