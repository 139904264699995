import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { switchMap } from 'rxjs/operators';
import { CommandListCollectionService } from "./command-list-collection.service";

import { BaseEffectCommand } from './base-effect.command';
import { HelpersService } from '../services/helpers.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class GoogleTagDataLayerCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private helpersService: HelpersService
        ) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.clearGoogleTagDataLayerCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    this.clearGoogleTagDataLayer();
                    return this.getNextActions(commandConfig);
                })
            )
    );

    pushToGoogleTagDataLayerCommand$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.pushToGoogleTagDataLayerCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.pushToGoogleTagDataLayerCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appState = this.getAppState(commandConfig.appName, appsStore);
                this.pushToGoogleTagDataLayer(appState, commandConfig);
                return this.getNextActions(commandConfig);
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    pushToGoogleTagDataLayer(appState: AppState, commandConfig: CommandConfig): void {

        window.dataLayer = window.dataLayer || [];

        if (!commandConfig.parameters[0]) return;

        const dataOverride = {};
        const fieldNames = commandConfig.parameters[0].split(',');
        const fieldAliases = commandConfig.parameters[1]?.split(',') || [];

        const override = this.helpersService.getThemeProperty('PushToGoogleTagLayerOverride', IX_Theme);
        if (override && override.Value1) {
            const overrideKeyValuePairs = override.Value1.split('|');
            overrideKeyValuePairs.forEach((kvp) => {
                const pair = kvp.split(':');
                const overrideKey = pair[0];
                const overrideValue = pair[1];
                dataOverride[overrideKey] = overrideValue;
            });
        }

        const data = {};
        fieldNames.forEach((fieldName, index) => {
            const key = fieldAliases[index] || fieldName;
            data[key] = dataOverride[fieldName] || appState[fieldName];
        });

        window.dataLayer.push(data);
    }

    clearGoogleTagDataLayer(): void {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.splice(0, window.dataLayer.length);
    }

}
