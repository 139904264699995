import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { Observable, of, throwError } from "rxjs";
import { delay, switchMap } from "rxjs/operators";
import { BaseEffectCommand } from './base-effect.command';
import { DomFocusService } from '../services/dom-focus.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { ApplicationInformation } from '../services/application-information.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { AppsConstantsFacade } from '../services/apps-constants.facade';

@Injectable()
export class SetFocusCommand extends BaseEffectCommand {

    currentStepName: string;
    currentBreakPoint: string;

    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private appsConstantsFacade: AppsConstantsFacade,
        private domFocusService: DomFocusService,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private applicationInformation: ApplicationInformation) {
        super();
        this.appsConstantsFacade.step$.subscribe((step) => this.currentStepName = step);
        this.appsConstantsFacade.breakPoint$.subscribe((breakPoint) => this.currentBreakPoint = breakPoint);
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.setFocusCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.setFocusCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const focusConfig = this.getSetFocusConfig(commandConfig);
                const appName = this.getSetFocusAppName(commandConfig, focusConfig);
                const appState = this.getAppState(appName, appsStore);
                return this.setFocus(appState, focusConfig)
                    .pipe(
                        switchMap(() => this.getNextActions(commandConfig))
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    getSetFocusAppName(commandConfig: CommandConfig, focusConfig: any): string {
        let appName = commandConfig.appName;
        if (this.isSetFocusForMainApplication(focusConfig.targetElementType)) {
            const focusCurrentStep = "CurrentStep".EqualsIgnoreCase(focusConfig.targetElement);
            focusConfig.targetElement = this.getMainApplicationForSetFocus(commandConfig.appName, focusCurrentStep);
            focusConfig.targetElementType = "Application";
            appName = focusConfig.targetElement;
        }
        return appName;
    }

    getSetFocusConfig(commandConfig: CommandConfig) {
        const config = {
            targetElementType: commandConfig.parameters[0],
            targetElement: commandConfig.parameters[1],
            focusOnCssClass: commandConfig.parameters[3],
            timeoutPeriod: (commandConfig.parameters.length < 3 || isNaN(parseInt(commandConfig.parameters[2])))
                ? 0 : parseInt(commandConfig.parameters[2])
        };
        return config;
    }

    setFocus(appState: AppState, focusConfig: any): Observable<boolean> {

        if (_.isEmpty(appState)) return throwError(false);

        return of(appState)
            .pipe(
                delay(focusConfig.timeoutPeriod),
                switchMap(() => {
                    // Whenever the "Focus on Class" parameter is present the focus goes to the specified class within the app. 
                    if (!_.isEmpty(focusConfig.focusOnCssClass)) {
                        let focusOnClass = null;
                        if (!_.isEmpty(focusConfig.targetElement))
                            focusOnClass = $("[data-app='" + focusConfig.targetElement + "']").find("." + focusConfig.focusOnCssClass);
                        else
                            focusOnClass = $("." + focusConfig.focusOnCssClass);

                        focusOnClass.attr("tabindex", 0);
                        focusOnClass.focus();
                    } else {
                        const $applet = this.getTargetElement(focusConfig.targetElementType, focusConfig.targetElement);
                        if (this.setDxComponentFocus($applet)) return of(false);
                        const visibleAndTabbable = $applet.find("[tabindex]:visible").first();
                        if (visibleAndTabbable.length > 0) {
                            this.domFocusService.setFocusToElement(visibleAndTabbable);
                        } else {
                            // If there's no immediate access to a tabbable element the following tries to programmatically
                            // enforce the focus on the first header. 
                            const $h1 = $applet.find("h1").first();
                            // Since forceProgrammaticFocusOnElement returns true when the jQuery selector is not empty the following
                            // sets the focus on the first element going from H1 to H3. 
                            if (this.domFocusService.forceProgrammaticFocusOnElement($h1))
                                return of(true);

                            const $h2 = $applet.find("h2").first();
                            if (this.domFocusService.forceProgrammaticFocusOnElement($h2))
                                return of(true);

                            const $h3 = $applet.find("h3").first();
                            if (this.domFocusService.forceProgrammaticFocusOnElement($h3))
                                return of(true);
                        }
                    }
                    return of(true);
                })
            );
    }

    private getTargetElement(targetElementType, targetElement) {
        if ("application".EqualsIgnoreCase(targetElementType)) {
            return this.domComponentRetrievalService.getAppSelector({}, targetElement);
        }
        return $(document.createElement("div"));
    }

    private isSetFocusForMainApplication(targetElementType) {
        return "MainApplication".EqualsIgnoreCase(targetElementType);
    }

    private getCanvasConfig(focusCurrentStep: boolean): any {
        return { appName: this.currentStepName, layoutSize: this.currentBreakPoint };
    }

    private getMainApplicationForSetFocus(triggeringAppName: string, focusCurrentStep: boolean): string {
        const canvasConfig = this.getCanvasConfig(focusCurrentStep);
        const mainApp = this.applicationInformation.getMainApplicationForSetFocus(canvasConfig);
        return _.isEmpty(mainApp) ? triggeringAppName : mainApp;
    }

    private setDxComponentFocus($applet) {
        const $target = $applet.find("[ng-reflect-model]").first();
        if ($target) {
            $target.focus();
            return true;
        }
        return false;
    }
}
