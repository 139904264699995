/* eslint-disable @typescript-eslint/camelcase */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { EventsService } from '../services/events.service';
import { ThemeService } from '../services/theme.service';
import { PopupComponent } from './popup/popup.component';

@Component({
    selector: 'ic-theme-custom-alert',
    template: `<ic-popup *ngIf="enabled" [config]="popup" [context]="context"></ic-popup>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeCustomAlertComponent implements OnInit, OnDestroy {

    enabled: boolean;
    popup: any;
    context: any;

    private onEventSubscription: Subscription;

    @ViewChild(PopupComponent)
    private popupComponent: PopupComponent;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private eventsService: EventsService,
        private themeService: ThemeService) {
        this.enabled = false;
        this.context = {};
        this.onEventSubscription = this.eventsService
            .events$
            .pipe(
                filter(event => event?.id == "ThemeCustomAlert.Event"),
                tap((event) => this.handleEvent(event.state))
            ).subscribe();
    }

    private handleEvent(event: any) {
        if (!this.enabled || _.isNil(event)) return;
        if (event.Hide) this.popupComponent.dxPopup.hide();
        if (event.Show) this.popupComponent.dxPopup.show();
        this.changeDetectorRef.detectChanges();
    }

    ngOnInit(): void {
        const alertInfo = this.themeService.getCustomAlertInfo();
        if (_.isNil(alertInfo)) return;
        this.enabled = true;
        this.popup = {
            "visible": false,
            "popup": {
                "width": "auto",
                "minWidth": alertInfo.width,
                "height": "auto",
                "minHeight": alertInfo.height,
                "contentTemplate": "canvas",
                "closeAriaLabel": "",
                "screenName": alertInfo.appName,
                "showTitle": true,
                "showCloseButton": true,
                "resizeEnabled": true,
                "closeOnOutsideClick": false
            },
            "canvas": {
                "id": alertInfo.appName,
                "name": alertInfo.appName,
                "parentName": IX_Theme.themeName,
                "enable": true,
                "layout": {
                    "natural": [{
                        "cmd": "colStart",
                        "tag": "div",
                        "class": "IXColumn theme-custom-alert-applet " + alertInfo.class
                    }, {
                        "id": alertInfo.appName,
                        "cmd": "applet"
                    }, {
                        "cmd": "colEnd"
                    }]
                },
                "applets": [],
                "config": {
                    "layout": "natural"
                }
            },
            "canvasConfig": {}
        };
    }

    ngOnDestroy(): void {
        this.onEventSubscription?.unsubscribe();
    }
}
