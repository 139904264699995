import { AppsEntity } from '../../state/apps.models';
import { ApplicationInformation } from '../application-information.service';
import { DomComponentRetrievalService } from '../dom-component-retrieval.service';
import { EcdService } from '../clientservices/ecd.service';
import { DefaultApiMapper } from './default-api.mapper';
import { TranslatableEffectCommand } from '../../commands/translatable-effect.command';
import { DynamicReplacementService } from '../dynamic-replacement.service';
import { ValidationEngineService } from '../validation-engine.service';
import { ModalService } from '../modal.service';
import { UtilService } from '../util.service';
import { ApiClientService } from '../api-client.service';
import { HelpersService } from '../helpers.service';
import { CacheManagerService } from '../cachemanager.service';
import { PersonalizationService } from '../personalization.service';
import { AppEvent } from '../../state/app-events.enum';

export class ApiFieldMapper extends DefaultApiMapper {

    fieldMap: FieldMap;
    applicationOnError: string;
    appComponent: any;
    dxList: any;
    grid: any;

    constructor(appsState: Record<string, AppsEntity>,
        utilService: UtilService,
        ecdService: EcdService,
        apiClientService: ApiClientService,
        cacheManagerService: CacheManagerService,
        private commandConfig: CommandConfig,
        private commandHelpers: TranslatableEffectCommand,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private dynamicReplacementService: DynamicReplacementService,
        private validationEngineService: ValidationEngineService,
        private modalService: ModalService,
        private helpersService: HelpersService,
        protected personalizationService: PersonalizationService) {
        super(appsState, utilService, ecdService, cacheManagerService, apiClientService, personalizationService);

        this.fieldMap = this.getFieldMap(this.commandConfig);
        this.applicationOnError = this.commandConfig.parameters[5];
        if (this.commandConfig.parameters[1].trim() === "")
            this.commandConfig.parameters[1] = this.commandHelpers.getTranslation("RETRY-OR-CONTACT-SUPPORT");
    }

    private setInstances(listAppName: string): void {
        const instance = this.domComponentRetrievalService.getDxComponentInstance(this.appComponent.applet, listAppName);
        if ('dxList'.EqualsIgnoreCase(instance?.NAME)) {
            this.dxList = instance;
        } else if (instance) {
            this.grid = instance;
        }
    }

    execute(): Promise<any> {

        const appName = this.commandConfig.appName;
        const path = this.commandConfig.options.path;
        let fieldMapApps = {};
        let options: any = {};

        let listAppForCommand = this.commandConfig.appName;
        if (_.isPlainObject(this.fieldMap)) {
            options = this.getContainerApp(this.commandConfig);
            if (!this.applicationInformation.isListApp(appName)) {
                listAppForCommand = this.fieldMap.sourceApp;
                if (!this.applicationInformation.isListApp(this.fieldMap.sourceApp)) {
                    listAppForCommand = this.fieldMap.targetApp;
                }
            }
        } else {
            options = { appId: appName, containerId: appName };
            fieldMapApps[appName] = true;
        }

        if (!this.appComponent) this.appComponent = this.domComponentRetrievalService.getAppComponent(listAppForCommand);
        if (this.appComponent) {
            this.setInstances(listAppForCommand);
        }
        const resolvePromise = this.setMupData(listAppForCommand);
        if (!_.isNil(resolvePromise)) return resolvePromise;

        if (this.appComponent && this.appComponent.$parent && _.isNumber(this.appComponent.$parent.masterDetailRowIndex)) {
            options.containerId = options.containerId + "[" + this.appComponent.$parent.masterDetailRowIndex + "]";
        }

        this.hideErrorMessageApp();
        this.grid?.beginCustomLoading();
        fieldMapApps = this.getAppsInFieldMap(this.fieldMap, options.appId, options.containerId);

        const ecdPromise = this.ecdRequest(options.appId, options.containerId, this.commandConfig.action, path.Path, fieldMapApps)
        return ecdPromise.then((res) => {
                const onSuccessMessage = this.commandConfig.parameters[0] || "";
                return this.dynamicReplacementService
                    .getDynamicValue(onSuccessMessage, this.appComponent)
                    .then((dynamicMessage) => {
                        if (_.isEmpty(onSuccessMessage.trim())) return res;
                        return this.helpersService.showMessage(this.appComponent, dynamicMessage).then(() => res);
                    });
            })
            .catch((errorResponse) => {
                const errorMessage = this.commandHelpers.onFailApiCall(errorResponse);
                this.displayServerSideFieldValidationErrors(errorResponse);
                let errorPromise;
                if (this.helpersService.shouldUseErrorListComponent()
                    && this.applicationOnError
                    && _.includes(['VALIDATION_ERROR', 'USER_ERROR'], errorResponse?.Status)) {
                    errorPromise = this.showErrorMessageApp(errorMessage, errorResponse);
                } else if (this.commandHelpers.isTupOrMup(this.commandConfig.action)
                    && this.applicationOnError
                    && errorMessage.split('|').length > 3) {
                    errorPromise = this.displayCustomAlert(errorMessage);
                } else if (this.commandConfig.parameters.length > 3 && this.commandConfig.parameters[4]) {
                    errorPromise = this.executeButton(errorMessage);
                } else if (this.commandConfig.parameters[1] && this.commandConfig.parameters[1][0] === '|') {
                    errorPromise = this.handleCustomErrorMessage(errorMessage);
                } else {
                    errorPromise = this.displayErrorMessage(errorMessage, errorResponse);
                }
                return errorPromise;
            })
            .finally(() => {
                this.setState();
                // this section is required in order to avoid more server calls  
                if (this.commandHelpers.isMupCommand(this.commandConfig.action)) {
                    this.setComponentData(listAppForCommand, null);
                    this.grid?.deselectAll();
                }
                this.grid?.endCustomLoading();
                // end doc
            });
    }

    private publishEvent(eventName: string, eventData: any): void {
        const event = {
            id: eventName,
            state: eventData
        };
        this.utilService.publishEvent(event);
    }

    private hideErrorMessageApp(): void {
        if (this.applicationOnError) {
            this.publishEvent('HideErrorMessageApp.Event', { applicationOnError: this.applicationOnError });
        }
    }

    private showErrorMessageApp(errorMessage: string, errorResponse: any): any {
        if (this.applicationOnError) {
            errorResponse.applicationOnError = this.applicationOnError;
            this.publishEvent('ShowErrorMessageApp.Event', errorResponse);
        }
        return Promise.reject(errorMessage);
    }

    private displayCustomAlert(errorMessage: string) {
        const popupProps = {
            appName: this.applicationOnError,
            showTitle: false,
            class: 'popup-' + this.applicationOnError.normalize()
        };
        const deserialized = this.dynamicReplacementService.deserializeErrorOrSuccessMessage(errorMessage);
        const eventArgs: any = {};
        _.forEach(deserialized, (value, key) => eventArgs[key] = value);
        eventArgs.Summary = deserialized.Message;
        const eventName = 'SetCustomErrorDetails.Event';
        this.updateWithObject(eventName, eventArgs, true);
        // TODO: create component similar to theme custom alert
        // IX_ShowCustomAlert(popupProps, '', false, this.appComponent, () => this.publishEvent(eventName, eventArgs));
        return Promise.reject(errorMessage);
    }

    private executeButton(errorMessage: string) {
        let buttonExist = false;
        const buttonName = this.commandConfig.parameters[4];
        for (const currentButton of this.appComponent.buttons) {
            buttonExist = currentButton.fieldName == buttonName;
            if (buttonExist) break;
        }
        if (buttonExist) {
            const eventState = { appName: this.appComponent.applet.name, buttonName };
            this.publishEvent(AppEvent.ExecuteButtonClick, eventState);
        }
        return Promise.reject(errorMessage);
    }

    private displayServerSideFieldValidationErrors(response: any): void {
        if (!this.commandHelpers.isTupCommand(this.commandConfig.action)) return;
        const fieldErrors = [];
        _.forEach(response.SingletonData, (value, fieldName) => {
            if (!_.isString(value)) return;
            const errorMessage = value.split('|');
            if (errorMessage.length < 4) return;
            fieldErrors.push({ fieldName, errorMessage: errorMessage[3] });
        });
        this.validationEngineService.displayFieldValidationErrors(this.commandConfig.appName, fieldErrors);
    }

    private displayErrorMessage(errorMessage: string, errorResponse: Promise<never>): Promise<never> {
        if (this.commandConfig.parameters[1].trim() !== "") {
            return this.dynamicReplacementService
                .getDynamicValue(this.commandConfig.parameters[1], this.appComponent)
                .then((dynamicMessage) => {
                    const translatedMessage = this.commandHelpers.getTranslation(dynamicMessage, dynamicMessage);
                    this.helpersService.showMessage(this.appComponent, translatedMessage);
                    return Promise.reject(errorResponse);
                });
        }
        return Promise.reject(errorMessage);
    }

    private handleCustomErrorMessage(errorMessaged: string) {
        const aTierMessage = errorMessaged;
        const pTierConfig = this.commandConfig.parameters[1];
        const errorMessage = this.commandHelpers.parseATierMessage(aTierMessage, pTierConfig)
            || this.commandHelpers.getTranslation('RETRY-OR-CONTACT-SUPPORT');
        if (_.isEmpty(errorMessage.trim())) {
            return Promise.reject(errorMessaged);
        }
        const aTierErrorToMatch = this.commandConfig.parameters[7];
        const shouldContinue = aTierErrorToMatch && _.includes(errorMessage, aTierErrorToMatch);
        if (shouldContinue) {
            return this.dynamicReplacementService
                .getDynamicValue(errorMessage, this.appComponent)
                .then((dynamicMessage) => {
                    let customCssClass = 'IX_Alert_Continue_On_Error';
                    if (this.commandConfig.appName) {
                        customCssClass += ' IX_Alert_Continue_On_Error_' + this.domComponentRetrievalService.getAppClassName(this.commandConfig.appName);
                    }
                    return this.modalService.generic({
                        actions: {
                            onPrimary: {
                                text: this.commandHelpers.getTranslation('OK'),
                                resolve: true
                            },
                        },
                        customCssClass: customCssClass,
                        //realButtons: true,
                        text: dynamicMessage || '',
                    });
                });
        }
        return this.dynamicReplacementService
            .getDynamicValue(errorMessage, this.appComponent)
            .then((dynamicMessage) => {
                dynamicMessage = dynamicMessage || '';
                const oldOverrideFlag = this.appComponent.customAlertForceOverrideText;
                this.appComponent.customAlertForceOverrideText = true;
                this.helpersService.showMessage(this.appComponent, dynamicMessage, () => this.appComponent.customAlertForceOverrideText = oldOverrideFlag);
                return Promise.reject(errorMessaged);
            });
    }

    private setMupData(listAppForCommand: string): any {

        if (!this.commandHelpers.isMupCommand(this.commandConfig.action)) return null;

        let tmpMupData;
        if (this.grid) {
            let selectedKeys = null;
            if (this.grid.option("selection").mode == "multiple") {
                selectedKeys = this.grid.getSelectedRowsData();
            } else {
                const gridDS = this.grid.getDataSource();
                if (gridDS && gridDS.group() && gridDS.group().length > 0)
                    selectedKeys = this.grid.getAllItems();
                else
                    selectedKeys = this.grid.getDataSource().items();
            }
            if (!Array.isArray(selectedKeys) || !selectedKeys.length) {
                return Promise.reject(new Error("No items selected to perform server request."));
            }
            tmpMupData = this.commandHelpers.convertSelectedRowsToDSFormat(selectedKeys);
        } else if (this.dxList) {
            const listData = this.dxList.option('items');
            if (!Array.isArray(listData) || listData.length < 0) {
                return Promise.reject(new Error("No items in list to perform server request."));
            }
            tmpMupData = this.commandHelpers.convertSelectedRowsToDSFormat(listData);
        } else if (this.applicationInformation.isComponent(listAppForCommand)) {
            if (!this.commandConfig.parameters[6] || (this.commandConfig.parameters[6]
                && !this.commandHelpers.isTruthy(this.commandConfig.parameters[6]))) {
                if (this.applicationInformation.getAppInfo(listAppForCommand).componentType == "NewsFeedV4"
                    && this.commandConfig.options.e
                    && _.isPlainObject(this.commandConfig.options.e)) {
                    // if (!this.commandConfig.options.e.length) {
                    //     reject(new Error("No items selected to perform server request."));
                    //     return false;
                    // }
                    // Support for irregular and non-data-grid component MUPs
                    tmpMupData = this.commandHelpers.convertSelectedRowsToDSFormat(this.commandConfig.options.e);
                }
            } else if (this.commandHelpers.isTruthy(this.commandConfig.parameters[6])) {
                // TODO - get data count directly
                // const mupAppScope = this.utilService.getApplicationScope(listAppForCommand);
                // if (mupAppScope && mupAppScope.componentProperties.dataSource._items.length <= 0) {
                //     return Promise.resolve(true);
                // }
            }
        }

        if (tmpMupData) {
            this.setComponentData(listAppForCommand, tmpMupData);
        }

        return null;
    }
}
