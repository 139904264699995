import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as fromAppActions from '../state/apps.actions';
import { AppsPartialState } from '../state/apps.reducer';
import { ConfigService } from './config.service';

@Injectable()
export class RouteGuards implements CanActivate, CanActivateChild, Resolve<WorkflowConfiguration> {

    constructor(private configSvc: ConfigService, private router: Router, private store: Store<AppsPartialState>) { }

    //#region route guards

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.setIsPlatformMobile(route);
        return this.checkCanActivate(route);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkCanActivate(childRoute);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkflowConfiguration> {
        this.setIsPlatformMobile(route);
        return this.configSvc.load(route.data.workflow);
    }

    //#endregion

    //#region route helpers

    private checkCanActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const screenName = route.data.step;
        return this.configSvc
            .load(route.data.workflow)
            .pipe(switchMap(config => of((IX_UserAuthenticated || !config.constants.requiresAuthentication) && !this.shouldPerformInterstitialPage(screenName, config.interstitialPages))));
    }

    private shouldPerformInterstitialPage(screenName: string, interstitialPages: InterstitialPages): boolean {
        if (interstitialPages.Required) {
            const interstitialPage = interstitialPages.Pages[0];
            if (screenName == interstitialPage) return false;
            this.router.navigateByUrl(interstitialPage);
        }
        return interstitialPages.Required;
    }

    private setIsPlatformMobile(route: ActivatedRouteSnapshot) {
      if (route.queryParams?.platform?.toLowerCase() === 'mobile') {
          this.store.dispatch(fromAppActions.setIsPlatformMobile());
      }
    }

    //#endregion
}
