import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { AppsEntity } from '../state/apps.models';
import { AppEvent } from '../state/app-events.enum';
import { Observable, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from "./command-list-collection.service";
import { DeviceService } from '../services/device-information.service';
import { ApplicationInformation } from '../services/application-information.service';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { EventsService } from '../services/events.service';

type Tabs = {
    startingTabAppNames: string[],
    startingTabAppName: string,
    firstTabIndex: string,
    secondTabIndex: string,
};

@Injectable()
export class TabToActivateConditionallyCommand extends BaseEffectCommand {

    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private eventsService: EventsService,
        private deviceService: DeviceService,
        private applicationInformation: ApplicationInformation,
        private dynamicReplacementService: DynamicReplacementService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.tabToActivateConditionallyCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    return this.tabToActivateConditionally(commandConfig)
                        .pipe(
                            switchMap((event) => {
                                if (_.isBoolean(event))
                                    return this.getNextActions(commandConfig);
                                const tabEventAction = CommandActions.publishEvent({ event });
                                return this.appendNextActions([tabEventAction], commandConfig);
                            })
                        );
                })
            )
    );

    private tabToActivateConditionally(commandConfig: CommandConfig): Observable<boolean | AppsEntity> {

        const timeOutValue = 100;
        const data: Tabs = {
            startingTabAppNames: commandConfig.parameters[0]?.split(',') || [],
            startingTabAppName: null,
            firstTabIndex: null,
            secondTabIndex: null,
        };

        if (commandConfig.parameters[3]) {
            commandConfig.options.path = commandConfig.parameters[0];
            commandConfig.parameters = [commandConfig.parameters[3], commandConfig.parameters[0]];
            return of(this.getActivateTabEventEntity(commandConfig));
        }

        return of(true)
            .pipe(
                delay(timeOutValue),
                switchMap(async () => {
                    data.firstTabIndex = await this.getTabIndexFromToken(commandConfig.parameters[1], commandConfig);
                    data.secondTabIndex = await this.getTabIndexFromToken(commandConfig.parameters[2], commandConfig);
                }),
                switchMap(() => this.openFirstTab(data, timeOutValue)),
                switchMap(() => this.openSecondTab(data, timeOutValue))
            );
    }

    private getTabIndexFromToken(token: string, config: CommandConfig): Promise<string> {
        if (token?.indexOf('{Field:') == -1) {
            return of(token).toPromise();
        }
        return this.dynamicReplacementService.getDynamicValue(token, config);
    }

    private openFirstTab(data: Tabs, timeOutValue: number): Observable<boolean> {
        return of(true)
            .pipe(
                delay(timeOutValue),
                switchMap(() => {
                    data.startingTabAppName = _.first(data.startingTabAppNames);
                    this.triggerTabSelectedIndex(data.startingTabAppName, data.firstTabIndex);
                    return of(true);
                })
            );
    }

    private openSecondTab(data: Tabs, timeOutValue: number): Observable<boolean> {
        if (!data.secondTabIndex)
            return of(true);

        const appName = data.startingTabAppName || data.startingTabAppNames[0];
        const appInfo = this.applicationInformation.getAppInfo(appName);
        if (_.isNil(appInfo) || _.isNil(appInfo.tabApplications[data.firstTabIndex]))
            return of(true);

        const firstAppName = appInfo.tabApplications[data.firstTabIndex];
        return of(timeOutValue)
            .pipe(
                delay(2 * timeOutValue),
                switchMap(() => {
                    this.triggerTabSelectedIndex(firstAppName, data.secondTabIndex);
                    return of(true);
                })
            );
    }

    private triggerTabSelectedIndex(appName: string, tabIndex: string) {
        const event = {
            id: AppEvent.TabToActivate,
            state: { appName, tabIndex }
        };
        this.eventsService.publishEvent(event);
    }
}
