import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { switchMap } from 'rxjs/operators';
import { CommandListCollectionService } from "./command-list-collection.service";

import { BaseEffectCommand } from './base-effect.command';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class PrintToPDFCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected cacheManagerService: CacheManagerService,
        protected commandListCollectionService: CommandListCollectionService,
        ) { 
        super();
    }

    effectCommandName$ = createEffect(
        () => this.actions$.pipe(
            ofType(CommandActions.printToPDFCommand),
            switchMap((action) => {
                const commandConfig = this.getCommandConfig(action);
                window.print();
                return this.getNextActions(commandConfig);
            })
        )
    );

}
