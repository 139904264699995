import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import { AppEvent } from '../state/app-events.enum';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { BaseEffectCommand } from './base-effect.command';
import { CacheManagerService } from '../services/cachemanager.service';
import { SessionTimeout } from '../services/session-timeout.service';
import { ApiClientService } from '../services/api-client.service';
import { CommandListCollectionService } from './command-list-collection.service';
import { EventsService } from '../services/events.service';
import { AppsFacade } from '../state/apps.facade';

@Injectable()
export class GenericEffectsCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private apiClientService: ApiClientService,
        private sessionTimeout: SessionTimeout,
        private eventsService: EventsService,
        private appsFacade: AppsFacade) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$
                .pipe(
                    ofType(CommandActions.delayAndContinueWithNextCommand),
                    switchMap((action) => {
                        const { timeout, commandConfig, prevValue } = action;
                        const delayTimeout = timeout > 0 ? timeout : 50;
                        return of(timeout)
                            .pipe(
                                delay(delayTimeout),
                                switchMap(() => this.getNextActions(commandConfig, prevValue))
                            )
                    })
                )
    );

    stop$ = createEffect(
        () =>
            this.actions$
                .pipe(
                    ofType(CommandActions.stop),
                    tap(() => IX_DEBUG_SETTINGS?.cmdLst?.verbose && console.log("Command execution stopped"))
                ),
        { dispatch: false }
    );

    onCommandError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppsActions.onCommandError),
                tap((action) => {
                    if (action.route?.commandListName) {
                        let error = (action.error as any) instanceof Error ? action.error as any : null;
                        try {
                            if (!error) {
                                const message = _.isString(action.error)
                                    ? action.error
                                    : JSON.stringify(action.error);
                                error = new Error(message);
                            }
                        } catch {
                            error = new Error('[unable to stringify action.error]');
                        }
                        const key = action.route.commandListName + action.route.executionId;
                        this.cacheManagerService.rejectPromiseWith(key, error);
                    }
                    console.log('Error while executing command list with name', action.route.commandListName, { 'Action': action });
                })
            ),
        { dispatch: false }
    );

    redirectUserForLogout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppsActions.redirectUserForLogout),
                tap((action) => {
                    this.sessionTimeout.handleOnLogout();
                    this.redirectUserForLogout(action.extParam);
                })
            ),
        { dispatch: false }
    );

    destroyModalPopup$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.destroyModalPopup),
                tap((action) => {
                    const destroyPopupEvent = {
                        id: AppEvent.PopupDestroy,
                        state: { popupId: action.popupId }
                    };
                    this.eventsService.publishEvent(destroyPopupEvent);
                })
            ),
        { dispatch: false }
    );

    private redirectUserForLogout(extParam?: string): void {

        $(".IX_enhanceTimeoutWindow").remove();
        if (_.isNil(iXing.IX_urlForLogout))
            iXing.IX_urlForLogout = "/Membership/ExtPages/SignOut.aspx";

        let urlRedirect;
        //to not throw a timeout error message once the user logs out from regular menu
        if (extParam && extParam == "&IX_TMD=Y") {
            urlRedirect = iXing.IX_urlForLogout;
        } else {
            urlRedirect = iXing.IX_urlForLogout.replace("&IX_TMO=true", "");
        }

        // Replace leading & as can flag as unsafe request
        if (extParam?.startsWith("&")) {
            extParam = extParam.replace(/&/, '?');
        }

        if (!_.isNil(extParam))
            urlRedirect = urlRedirect + extParam;

        if (_.isFunction(iXing.IX_SignoutScript)) {
            iXing.IX_SignoutScript();
        }
        else if (iXing?.IX_urlForSignout && iXing?.IX_appForSignOut) {
            // This is for detecting if the clicked on menu item is the sign out button
            // and the application is configured to perform dynamic sign out. If so then
            // we need to perform the LST call and perform dynamic sign out actions.
            //console.log("redirectUserForLogout - urlRedirect", urlRedirect);
            this.apiClientService.performDynamicSignOutActions(urlRedirect);
        } else {
            window.location.assign(urlRedirect);
        }
    }
}
