// tslint:disable: forin
// tslint:disable: no-bitwise
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CircularJSON } from 'circular-json';
import { tap } from 'rxjs/operators';
import { EventsService } from './events.service';
import { AppsConstantsFacade } from './apps-constants.facade';
import { getCurrentLocale } from '../translate/locale.functions';
import UtilityFunctions from '../utility.functions';
import { CacheManagerService, CachePolicy } from './cachemanager.service';

@Injectable()
export class ApiClientService {

    constructor(private httpClient: HttpClient,
        private eventsService: EventsService,
        private appsConstantsFacade: AppsConstantsFacade,
        private cacheManagerService: CacheManagerService,
    ) {
        UtilityFunctions.bindPTierAPI('makeServerReplacementRequest', this.makeServerReplacementRequest, this);
    }

    _forceOkResponse = false;

    _serviceMaps = null;
    _useLocalDataSource = null;

    _ecdCachePolicy = () => ({
        canBeCached: (keyId: string) => {
            const parameter = keyId.toLowerCase();
            return parameter.endsWith('_lst');
        },
    });

    _cacheAlwaysPolicy = () => ({
        canBeCached: (key: string) => true,
    });

    setServiceMaps(serviceMaps): void {
        this._serviceMaps = serviceMaps;
    }

    forceOkResponses(): void {
        this._forceOkResponse = true;
    }

    removeOkResponses(): void {
        this._forceOkResponse = false;
    }

    setRequestData(appObj, dataIn): void {
        for (const field in appObj) {
            // Do not send nulls
            const tmpVal = appObj[field];
            if (tmpVal === null) continue;
            dataIn[field] = tmpVal;
        }
    }

    private hasValidValue(value): boolean {
        return value !== void 0 && value !== null && value !== '';
    }

    private flatApplicationProperties(data) {
        const aux = {};
        for (const p in data) {
            if (p === 'Id' && !this.hasValidValue(data[p])) continue;
            if (this.hasValidValue(data[p])) {
                aux[p] = data[p];
            }
        }
        return aux;
    }

    setECDContext(ecdContext, _contextApps) {
        _contextApps = _contextApps || {};
        let dictDetails;
        for (const app in _contextApps) {
            dictDetails = this.flatApplicationProperties(_contextApps[app]);
            if (!$.isEmptyObject(dictDetails)) {
                ecdContext[app] = dictDetails;
            }
        }
    }

    setEcdRequestContext(ecdRequest, ecdContext) {
        let keys: string[];
        for (const tmp in ecdContext) {
            keys = Object.keys(ecdContext[tmp]);
            for (let i = 0; i < keys.length; i++) {
                if (typeof keys[i] === 'string' && (keys[i].indexOf('StorageKey') >= 0 || keys[i][0] === '_')) {
                    delete ecdContext[tmp][keys[i]];
                }
            }
        }
        ecdRequest.Context = {};
        _.extend(ecdRequest.Context, ecdContext);
    }

    createECDHeader(appId: string, parentAppId: string, operation: string, commandPath?: string, fetchDynamicData = false): ECDHeader {
        return {
            ServerCallType: operation,
            ApplicationVersion: 'v4',
            ApplicationName: appId,
            ContainerApplication: parentAppId,
            CommandPath: commandPath,
            Data: undefined,
            FetchDynamicData: fetchDynamicData ? true : false,
        };
    }

    private defer() {
        let resolve, reject;
        const promise = new Promise((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });
        return { resolve, reject, promise };
    }

    messageToEventObj (mainMessage: string, status: string) {
        const eventObj = { 
                severity: '', 
                code: 0, 
                message: '',
                status: status ? status : '',
                pipeDelimitedMessage: false
            }
        if (mainMessage) {
            // A-tier error message response is in format |severity|code|message 
            // ex: "|U_CANNOT_BE_NULL|1300|The value entered cannot be null"
            const responseMsg = mainMessage.split('|');
            if (responseMsg.length > 3) {
                eventObj.severity = responseMsg[1];
                eventObj.code = parseInt(responseMsg[2], 10);
                eventObj.message = responseMsg[3];
                eventObj.pipeDelimitedMessage = true;
            } else if (mainMessage.indexOf(':') >= 0) {
                eventObj.severity = mainMessage.split(':')[0];
                eventObj.message = mainMessage.split(':')[1];
            } else {
                eventObj.severity = mainMessage;
            }
        }
        return eventObj;
    }

    sendEvent (data: any, isError: boolean) {
        try {
            if (!data) {
                return;
            }

            let themeProp, watchMessages;
            let isOptIn = false;
            let isLegacy = false;
            let eventData = this.messageToEventObj(data.MainMessage, data.Status);
            if (isError) {
                isLegacy = eventData.pipeDelimitedMessage;
                if (!isLegacy && eventData.severity.length > 0) {
                    themeProp = UtilityFunctions.getThemeProperty("SendEventOnErrorMessage");
                    watchMessages = !!themeProp ? themeProp.Value1 : '';
                    isOptIn = data.MainMessage && _.indexOf(watchMessages.replace(/[\s]*/mig, '').split(','), eventData.severity) >= 0;
                }
            } else {
                themeProp = UtilityFunctions.getThemeProperty("SendEventOnStatus");
                watchMessages = !!themeProp ? themeProp.Value1 : '';
                isOptIn = data.Status && _.indexOf(watchMessages.replace(/[\s]*/mig, '').split(','), data.Status) >= 0;
            }

            if (isLegacy || isOptIn) {
                const eventName = isError ? "ApplicationEvent.ErrorHandling.Event" : "ApplicationEvent.StatusHandling.Event";
                this.eventsService.publishEvent({ id: eventName, state: eventData});
            }
        } catch (ex) {
            IX_Log("component", "Exception in _makeRequest: sendEvent", ex);
        }
    }

    makeRequest(config: any, request?: any, rejectOnFail?: boolean, bypassCache?: boolean): Promise<any> {

        let promise = null;
        const makeRequestInner = (configInner, requestInner, rejectOnFailInner) => {
            const d = this.defer();
            const ajaxRequest = {
                contentType: 'application/json',
                dataType: 'json',
                ...configInner,
            };

            if (!_.isNil(requestInner) && _.isObject(requestInner)) {
                try {
                    ajaxRequest.data = JSON.stringify(requestInner);
                } catch (ex) {
                    if (ex.message.contains('Converting circular structure to JSON'))
                        ajaxRequest.data = CircularJSON.stringify(requestInner);
                }
            }

            const processLogging = (error) => {
                if (_.isNil(error)) return;

                let jsProperties;

                if (_.isNil(error.responseJSON)) {
                    if (!_.isNil(error.JSProperties)) jsProperties = error.JSProperties;
                } else {
                    if (!_.isNil(error.responseJSON.JSProperties)) jsProperties = error.responseJSON.JSProperties;
                }

                if (jsProperties && jsProperties._dg && jsProperties._dg === 'Y') {
                    let message;
                    if (!_.isNil(error.responseJSON) && !_.isNil(error.responseJSON.MainMessage)) {
                        message = error.responseJSON.MainMessage;
                    } else {
                        message = error.MainMessage;
                    }

                    if (_.isNil(message) || message === '') return;

                    // TODO - do we want to refactor this away from jquery now?
                    let elem = $('#IXDG');
                    let txt = '';
                    if (elem.length < 1) {
                        elem = $('<div id="IXDG" style="display:none"></div>').appendTo('body');
                    } else {
                        txt = atob(elem.text());
                    }
                    elem.text(btoa(txt + '\r\n' + message));
                }
            };

            if (this._forceOkResponse) {
                (Promise as any).delay(100)
                    .then(() => {
                        d.resolve({
                            SingletonData: [],
                            ListData: []
                        });
                    });
            } else {
                $.ajax(ajaxRequest)
                    .done((data) => {
                        processLogging(data);

                        this.sendEvent(data, false);

                        if (!_.isNil(data) && !_.isNil(data.LoggedOut)) {
                            if (data.LoggedOut === 'true' && window.IX_UserAuthenticated)
                                this.redirectUserForLogout();
                        }

                        // IX_PerfEnd('icApiClientService.' + identifier, 'makeRequest');
                        d.resolve(data);
                    })
                    .fail((request, error, errorThrown) => {
                        if (request) {
                            if (configInner.timeout && request.statusText === 'timeout') {
                                // IX_PerfEnd('icApiClientService.' + identifier, 'makeRequest');
                                d.reject(request);
                                return;
                            }

                            if (request.status === 401 || (request.statusText && request.statusText.toLowerCase() === 'unauthorized')) {
                                if (request.responseJSON && !request.responseJSON.LoggedOut) {
                                    // 401 but not server not reporting logged out?
                                    IX_Log('apiCli', 'Server responded with 401, but LoggedOut was not set', request, ajaxRequest);
                                }

                                if (window.IX_UserAuthenticated) {
                                    this.redirectUserForLogout();
                                    window.IX_UserAuthenticated = false;
                                    // we let time pass in case the redirect takes time, so we don't give the user the wrong impression in the browser dialog
                                    // we still complete the promise in case there's no redirect.  Technically there's no need, this is just protective
                                    setTimeout(() => {
                                        // IX_PerfEnd('icApiClientService.' + identifier, 'makeRequest');
                                        d.resolve(request);
                                    }, 10000);
                                    return;
                                } else {
                                    const loginUrl = '/ui/' + UtilityFunctions.toContextAppName(window.IX_Theme.details.UnauthenticatedHomePage ?? 'HomePage');
                                    const url = new URL(window.location.href);
                                    const isLoginPage = url.pathname.toLowerCase().startsWith(loginUrl);

                                    if (!isLoginPage) {
                                        this.redirectUserForLogout();
                                    } else {
                                        // http 401s for requests when on the login page can resolve without error, will still be visible in network/console
                                        d.resolve({
                                            SingletonData: [],
                                            ListData: [],
                                        });
                                        return;
                                    }

                                }
                            }

                            // A-tier error message response is in format |severity|code|message
                            if (request.responseJSON && request.responseJSON.MainMessage) {
                                processLogging(request);

                                // Used by "{Error:} Dynamic Replacement Value."
                                window.lastError = request.responseJSON;
                            }

                            this.sendEvent(request.responseJSON, true);
                        }

                        if (rejectOnFailInner) {
                            // IX_PerfEnd('icApiClientService.' + identifier, 'makeRequest');
                            errorThrown = errorThrown instanceof Error ? errorThrown : new Error(errorThrown);
                            d.reject(errorThrown);
                        } else {
                            // IX_PerfEnd('icApiClientService.' + identifier, 'makeRequest');
                            d.resolve(request);
                        }
                    });
            }
            return d.promise;
        };

        //AA: Cache this
        if (!bypassCache && this.cacheManagerService.forceCache() && request) {
            let key = this.getEcdRequestCacheId(request);
            if (request.Requesters) {
                key += _.reduce(request.Requesters, (k, req) => k + this.getEcdRequestCacheId(req), '');
            }
            if (this.cacheManagerService.has(key)) {
                promise = Promise.resolve(this.cacheManagerService.get(key));
            } else if (this.cacheManagerService.wasRequested(key)) {
                promise = this.cacheManagerService.getPromise(key);
            } else {
                promise = this.cacheManagerService.setPromise(key);
                makeRequestInner(config, request, rejectOnFail)
                    .then((data) => {
                        this.cacheManagerService.resolvePromiseWith(key, data, this._cacheAlwaysPolicy());
                    })
                    .catch((error) => {
                        this.cacheManagerService.rejectPromiseWith(key, error);
                    });
            }
        } else {
            promise = makeRequestInner(config, request, rejectOnFail);
        }

        return promise;
    }

    makeEcdgRequest(request): Promise<any> {
        const config = {
            url: '/iXingPages/ecdg.ashx?requesttype=dataset&v=2',
            type: 'POST',
        };
        return this.makeRequest(config, request, true);
    }

    transferUserAgentMetricsInformation(params: string): Promise<any> {
        const config = {
            url: "/Membership/ExtPages/ilg.ashx?IX_BROWSER_INFO=Y&browserInfo=" + params,
            type: "POST",
            cache: false
        };
        return this.makeRequest(config, undefined, false);
    }

    generateExternalLinkRequest(url: string): Promise<any> {
        url += (url.indexOf('?') > 0 ? '&' : '?') + 'IX_GenerateExternalURL=true';
        const request = {
            url: url,
            type: "GET",
            cache: false
        };
        return this.makeRequest(request, null, false);
    }

    private getEcdUrl(ecdRequest: EcdRequest) {
        const appName = ecdRequest.ApplicationName.replace(/\./g, '_');
        return '/iXingPages/ecd.ashx?requesttype=dataset&v=2&app=' + appName;
    }

    private getAppInfo(appName) {
        const server = this._serviceMaps == null ? '' : this._serviceMaps.appService[appName];
        const serverParameters = this._serviceMaps == null ? '' : (this._serviceMaps.serviceApp[server] || {})[appName] || '';
        return { server: server, serverParameters: serverParameters };
    }

    private logEcdRequest(ecdRequest: EcdRequest) {
        const debug = IX_DEBUG_SETTINGS?.apiCli?.debug;
        const verbose = IX_DEBUG_SETTINGS?.apiCli?.verbose;
        const log = IX_DEBUG_SETTINGS?.apiCli?.log;
        const appName = ecdRequest.ApplicationName;
        const info = this.getAppInfo(appName);

        if (debug) {
            console.log(info.server, ecdRequest.ServerCallType, info.serverParameters, appName, verbose ? ecdRequest : '');

            if (log) {
                //copy to clipboard in devtools with copy(IX_DEBUG_SETTINGS.apiCli.ecdLog.output())
                if (!IX_DEBUG_SETTINGS?.apiCli?.ecdLog) {
                    IX_DEBUG_SETTINGS.apiCli.ecdLog = {
                        calls: [],
                        output: () => {
                            return JSON.stringify(window['IX_DEBUG_SETTINGS'].apiCli.ecdLog.calls, null, 4);
                        },
                    };
                }
                IX_DEBUG_SETTINGS.apiCli.ecdLog.calls.push({ server: info.server, verb: ecdRequest.ServerCallType, data: ecdRequest.Data });
            }
        }
    }

    private getEcdRequestDataCacheId(data) {
        const cacheId = [];
        if (data) {
            for (const name in data) {
                if (data[name]) {
                    if (typeof data[name] === 'object') {
                        cacheId.push(name.IXhashCode() ^ JSON.stringify(data[name]).IXhashCode());
                    } else {
                        cacheId.push(name.IXhashCode() ^ data[name].toString().IXhashCode());
                    }
                } else {
                    cacheId.push(name.IXhashCode() ^ ''.toString().IXhashCode());
                }
            }
        }
        return cacheId.join('_');
    }

    private getEcdRequestCacheId(ecdRequest: EcdRequest) {
        const appName = ecdRequest.ApplicationName;
        const info = this.getAppInfo(appName);
        const dataId = this.getEcdRequestDataCacheId(ecdRequest.Data);
        const server = info.server ?? "UnkwonServer";
        const ecdRequestCacheId = ecdRequest.ApplicationName + '-' + server + '_' + info.serverParameters + '_' + dataId + '_' + ecdRequest.ServerCallType;
        return ecdRequestCacheId;
    }

    makeEcdRequest(ecdRequest: EcdRequest, options?: IcLoadOptions): Promise<any> {
        options = options || {};

        const config = {
            url: this.getEcdUrl(ecdRequest),
            type: 'POST',
            timeout: options.timeout,
        };

        this.logEcdRequest(ecdRequest);

        const deprecatedUseCache = options.bypassCache != null && !options.bypassCache;

        if (options.useCache || deprecatedUseCache) {
            const ecdRequestCacheId = this.getEcdRequestCacheId(ecdRequest);
            return this.makeRequestWithCaching(ecdRequestCacheId, config, ecdRequest);
        }

        return this.makeRequest(config, ecdRequest, true);
    }

    private makeRequestWithCaching(key, config, request, cachePolicy?: CachePolicy): Promise<unknown> {
        let promise;

        if (this.cacheManagerService.has(key)) {
            promise = Promise.resolve(this.cacheManagerService.get(key));
        } else if (this.cacheManagerService.wasRequested(key)) {
            promise = this.cacheManagerService.getPromise(key);
        } else {
            promise = this.cacheManagerService.setPromise(key);

            this.makeRequest(config, request, true)
                .then((data) => {
                    cachePolicy = cachePolicy ? cachePolicy : this._ecdCachePolicy();
                    this.cacheManagerService.resolvePromiseWith(key, data, cachePolicy);
                })
                .catch((error) => {
                    this.cacheManagerService.rejectPromiseWith(key, error);
                });
        }

        return promise;
    }

    makeOdcRequest(ecdRequest: EcdRequest, params) {
        const baseUrl = '/iXingPages/odc.ashx?requesttype=dataset&v=2&';
        const url = !_.isNil(params) ? baseUrl + $.param(params) : baseUrl;
        const config = {
            url: url,
            type: 'POST',
        };
        return this.makeRequest(config, ecdRequest, true);
    }

    makeServerReplacementRequest(request, rejectOnFail?: boolean): Promise<any> {
        const config = {
            url: '/Membership/ExtPages/ilg.ashx?IX_RPVAL=ng',
            type: 'POST',
        };
        const bypassCache = true;
        return this.makeRequest(config, request, rejectOnFail, bypassCache)
            .then((data) => data?.DynamicData?.ReplacedValue || null);
    }

    private getMenuRequestCacheId(workflow: string) {
        const locale = getCurrentLocale();
        return 'mainMenu-' + escape(workflow) + '-' + escape(locale);
    }

    makeMenuRequest(request?, workflow?) {
        const config = {
            url: '/Membership/ExtPages/ilg.ashx?IX_MN=Y',
            type: 'GET',
            cache: true,
        };
        const menuRequestCacheId = this.getMenuRequestCacheId(workflow);
        const cachePolicy = { canBeCached: () => true };

        return this.makeRequestWithCaching(menuRequestCacheId, config, request, cachePolicy);
    }

    makeCanvasRequest(request): Promise<any> {
        const config = {
            url: '/iXingPages/Canvas.ashx',
            type: 'POST',
        };
        return this.makeRequest(config, request, false);
    }

    makeEvictCacheRequest(): Promise<any> {
        const config = {
            url: '/Membership/ExtPages/ilg.ashx?IX_EVCTCHE=true',
            type: 'GET',
            cache: false,
        };
        return this.makeRequest(config, void 0, false);
    }

    extendSessionRequest(actionFromUser: any, actionFromUserValue1: any, actionFromUserValue2: any): Promise<any> {
        const config = {
            url: "/iXingPages/Ecd.ashx?IX_EXTAUTH=Y",
            type: "POST",
            data: {
                "ActionFromUser": actionFromUser,
                "ActionFromUserValue1": actionFromUserValue1,
                "ActionFromUserValue2": actionFromUserValue2
            },
            dataType: 'xml'
        };
        return this.makeRequest(config, null, false);
    }

    signOut(): Promise<any> {
        const commandConfig = {
            url: '/Membership/ExtPages/SignOut.aspx',
            type: "GET",
            cache: false
        };
        return this.makeRequest(commandConfig, null, true);
    }

    performDynamicSignOutActions(url: string): void {
        if (_.isArray(iXing?.IX_dynamicSignoutData?.ListData)
            && iXing.IX_dynamicSignoutData.ListData.length > 0) {
            const logOutUrl = '/iXingPages/Ecd.ashx?IX_SGO=Y&IX_SGOR=N';
            this.httpClient.post(logOutUrl, null)
                .pipe(
                    tap(() => this.doDynamicSignOut(url, iXing.IX_dynamicSignoutData))
                );
        } else if (iXing?.IX_urlForSignout && iXing?.IX_appForSignOut) {
            this.makeEcdRequest({
                "ServerCallType": "LST",
                "ApplicationVersion": "v4",
                "ApplicationName": iXing.IX_appForSignOut,
                "ContainerApplication": $("[data-workflow]").attr("data-workflow")
            })
                .then((data) => this.doDynamicSignOut(url, data))
                .catch(() => this.doDynamicSignOut(url));
        }
        else {
            this.doDynamicSignOut(url);
        }
    }

    private doDynamicSignOut(url: string, data?: any): void {

        let signOutDelay = 0;
        try {
            if (data && data.Status === "SUCCESS" && data.ListData && data.ListData.length > 1) {
                signOutDelay = 2100;
                let terminateSessionUrlIndex = -1;
                data.ListData[0].forEach((value, index) => {
                    if (value === "TerminateSessionUrl") {
                        terminateSessionUrlIndex = index;
                    }
                });
                for (let i = 1; i < data.ListData.length; i++) {
                    const terminateSessionUrl = data.ListData[i][terminateSessionUrlIndex];
                    const id = "terminateSessionFrame_" + i;
                    const frame: any = document.getElementById(id);
                    if (frame) {
                        frame.src = terminateSessionUrl;
                    } else {
                        $('<iframe>', {
                            id: id,
                            src: terminateSessionUrl,
                            style: { width: 0, height: 0 }
                        }).appendTo('body');
                    }
                }
            }
        } catch (e) {
            console.error("Error signing out: " + e);
        }

        setTimeout(() => window.location.assign(url), signOutDelay);
    }

    private redirectUserForLogout() {
        this.appsConstantsFacade.redirectUserForLogout();
    }
}
