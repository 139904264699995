import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { from, Observable, of } from "rxjs";
import { zipAll, switchMap } from "rxjs/operators";
import { getWorkflowLocaleFile, getThemeLocaleFile } from './locale.functions';

export class CustomTranslationLoader implements TranslateLoader {

    constructor(private http: HttpClient) { }

    getTranslation(lang: string): Observable<any> {
        const workflowLocaleFile = getWorkflowLocaleFile();
        const themeLocaleFile = getThemeLocaleFile();
        const workflowLocales$ = this.http.get(workflowLocaleFile);
        const themeLocales$ = this.http.get(themeLocaleFile);
        return from([workflowLocales$, themeLocales$])
            .pipe(
                zipAll(),
                switchMap(([workflowTranslations, themeTranslations]) => {
                    themeTranslations = this.flatTranslations(themeTranslations);
                    workflowTranslations = this.flatTranslations(workflowTranslations);
                    const combineTranslations = _.merge({}, workflowTranslations, themeTranslations);
                    return of(combineTranslations);
                }));
    }

    private flatTranslations(translations: any): any {
        const translationsFlatten = {};
        for (const key in translations) {
            const translation = translations[key];
            if (_.isString(translation))
                translationsFlatten[key] = translation;
            else {
                for (const subKey in translation) {
                    const newKey = key + "." + subKey
                    translationsFlatten[newKey] = translation[subKey];
                }
            }
        }
        return translationsFlatten;
    }
}
