/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { AppsConstantsFacade } from './apps-constants.facade';
import { WorkflowConfigurationService } from './workflow-configuration.service';

class ApplicationTree {

    private root: any;
    private cache: any;

    constructor(tree: any) {
        this.root = tree;
        this.cache = {};
    }

    getAllSteps(): any {
        const result = {};
        const item = this.root;
        if (item.nodes.length > 0) {
            for (let i = 0; i < item.nodes.length; i++) {
                result[item.nodes[i].name] = 1;
            }
        }
        return result;
    }

    search(name: string): any {
        return this.breadthFirstSearch(this.root, this.cache, name);
    }

    getStepApplet(step: string, applet: string): Applet {
        const node = this.search(step);
        const item = this.breadthFirstSearch(node, this.cache, applet);
        return this.createApplet(item);
    }

    private flattenTree(nodes) {
        if (!Array.isArray(nodes)) return [];

        return nodes
            .flatMap((node) => {
                if (node) {
                    const childNodes = node.nodes?.length ? [...this.flattenTree(node.nodes)] : [];
                    return [node, ...childNodes]
                }
            })
            .flat()
    };

    getStepApplets(appNames: string[]): Applet[] {

        const nodes = appNames.map(name => this.search(name)).filter(n => !!n);

        if (!nodes.length) return [];

        return this.flattenTree(nodes).map((n) => { return { ...n } as Applet });
    }

    getStepAllApplets(appNames: string[]): Applet[] {
        const stepApplets = this.getStepApplets(appNames);
        const childApplets = stepApplets.flatMap((applet: Applet) => {
            return this.getStepApplets([applet.name]);
        })

        const allApplets = stepApplets.concat(childApplets);
        // Return unique by name
        return [...new Map(allApplets.map(item => [item['name'], item])).values()];
    }

    private breadthFirstSearch(start: any, cache: any, name: string): any {
        const queue = [start];
        let result = null;
        const cacheKey = start.name + '-' + name;
        while (queue.length > 0) {
            const item = queue.pop();
            if (item == null) continue;
            if (item.name === name) {
                result = item;
                cache[cacheKey] = item;
                break;
            }
            if (cache[cacheKey]) {
                result = cache[cacheKey];
                break;
            }
            for (let i = 0; i < item.nodes.length; i++) {
                queue.unshift(item.nodes[i]);
            }
        }
        return result;
    }

    private createApplet(item: any): Applet {
        return _.isNil(item)
            ? null
            : {
                name: item.name,
                containerName: item.containerName,
            };
    }
}

@Injectable()
export class WorkflowApplicationTree {

    applicationTree: ApplicationTree;

    constructor(private appsConstantsFacade: AppsConstantsFacade,
        private workflowConfigurationService: WorkflowConfigurationService) {
        this.appsConstantsFacade.configuration$
            .pipe(
                filter(action => action?.contains("reload-service-configuration")),
                tap(() => this.reloadConfiguration())
            )
            .subscribe();
    }

    getStepApplets(appName: string, otherApps: string[], includeDeeplyLinkedApps?: boolean): Applet[] {

        if (includeDeeplyLinkedApps) {
            return this.applicationTree.getStepAllApplets([appName, ...otherApps]);
        }

        return this.applicationTree.getStepApplets([appName, ...otherApps]);
    }

    getStepApplet(step: string, applet: string): Applet {
        return this.applicationTree.getStepApplet(step, applet);
    }

    getStepAllApplets(appName: string): Applet[] {
        return this.applicationTree.getStepAllApplets([appName]);
    }

    reloadConfiguration(): void {
        const appTree = this.workflowConfigurationService.getWorkflowTree();
        this.applicationTree = new ApplicationTree(appTree);
    }
}
