import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { switchMap } from 'rxjs/operators';
import { AppEvent } from '../state/app-events.enum';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from './command-list-collection.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { ApplicationInformation } from '../services/application-information.service';
import { ApplicationRefFacade } from '../services/application-ref.facade';
import { ListApplication } from '../components/list-application';

@Injectable()
export class ExportFromListAppCommand extends BaseEffectCommand {

    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private applicationRefFacade: ApplicationRefFacade,) {
        super();
    }

    effectCommandName$ = createEffect(
        () => this.actions$.pipe(
            ofType(CommandActions.exportFromListAppCommand),
            switchMap((action) => {
                const commandConfig = this.getCommandConfig(action);
                const appName = _.first(commandConfig.parameters);
                const exportType = '';
                const visibleColumns = this.getAllowedExportColumnNames(commandConfig);
                const eventState = {
                    appName,
                    exportType,
                    filteringVisibleColumns: visibleColumns.join(','),
                    hasListApps: !_.isEmpty(commandConfig.parameters[2])
                };
                const event = this.createAppEntity(AppEvent.DataGridExport, eventState);
                const publishEventAction = CommandActions.publishEvent({ event });
                return this.appendNextActions([publishEventAction], commandConfig);
            })
        )
    );

    private getAllowedExportColumnNames(commandConfig: CommandConfig): string[] {
        const columnNamesSet = new Set<string>();
        const mandatoryFieldNames = commandConfig.parameters[1]?.split(',') || [];
        const listAppsToScanForVisibleFields = commandConfig.parameters[2]?.split(',') || [];
        mandatoryFieldNames.forEach(fieldName => columnNamesSet.add(fieldName.toLowerCase()));
        listAppsToScanForVisibleFields.forEach(appName => {
            const isValid = this.applicationInformation.isListApp(appName);
            if (!isValid) return;
            const listComponent = this.applicationRefFacade.getAppComponent(appName) as ListApplication;
            if (_.isNil(listComponent)) return;
            const listElement = listComponent.appletElement.nativeElement;
            // Skip any listed app that is not visible
            if (!listElement.offsetParent) return;
            // Skip any listed app that is hidden in a not-selected dxTabPanel tab
            if (listElement.offsetParent.classList.contains('dx-multiview-item-hidden')) return;
            const dxDataGrid = this.applicationRefFacade.getListDxDataGrid(appName);
            if (_.isNil(dxDataGrid)) return;
            const visibleColumns = dxDataGrid.getVisibleColumns()
            visibleColumns.forEach(column => {
                // Exclude command columns
                if (!column.dataField) return;
                columnNamesSet.add(column.dataField.toLowerCase());
            });
        });
        return Array.from(columnNamesSet);
    }
}
