import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import Highcharts from '../../libraries/highcharts.extensions';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { AppsFacade } from '../../state/apps.facade';
import { FieldFormatService } from '../../services/field-format.service';
import { UtilService } from '../../services/util.service';
import { UtilListService } from '../../services/util-list.service';

type DonutChartV4Config = {
  _dataDisplayBehaviorOnEventOnly?: boolean;
  backgroundColor?: string,
  chartColors: string,
  chartAnimation?: string,
  chartFontColor?: string,
  chartHeight?: string,
  chartShadow?: string,
  chartSubtitle?: string,
  chartTitle?: string,
  chartTitleAlign?: string,
  chartTitleColor?: string,
  chartWidth?: string,
  displayDonutTable?: 'true' | 'false' | boolean,
  donutCenterXAxis?: string,
  donutCenterYAxis?: string,
  enableCustomLoading?: 'true' | 'false',
  fontFamily?: string,
  legendHeight?: string,
  legendHorAlign?: 'right' | 'left' | '',
  legendVerAlign?: 'top' | 'bottom' | 'middle' | '',
  legendWidth?: string,
  loadingTop?: string,
  loadingLeft?: string,
  loadingWidth?: string,
  loadingHeight?: string,
  loadingSpanCssDisplay?: string,
  loadingMarginLeft?: string,
  loadingMarginRight?: string,
  loadingText?: string,
  loadingBackgroundImage?: string,
  mapClickedLegendItemToAppName?: string,
  mapClickedLegendItemToFieldName?: string,
  noDataText?: string,
  numOfTopDataToShow?: string,
  noDataFontSize?: string,
  noDataFontFamily?: string,
  noDataFontColor?: string,
  noDataTextOffset?: string,
  pieInnerSize?: string,
  pieSectorBorderWidth?: string,
  seriesArgumentField?: string,
  seriesValueField?: string,
  percentValueField?: string,
  showToolTip?: string,
  sortLegendByName?: string,
  sortLegendByValue?: string;
  useComponentClickForChartLegend?: 'true',
  xForChartTitle?: string,
  xForLegend?: string,
  yForChartTitle?: string,
  yForLegend?: string,
};

type DonutChartV4ComponentProperties = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onItemClick: (_: {}, selectedObj?: { dataForClick: [] }) => Promise<any> | any,
};

@Component({
  selector: 'ic-donutchartv4',
  templateUrl: './donutchartv4.component.html',
  styleUrls: ['./donutchartv4.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutchartV4Component implements OnInit, OnDestroy, AfterViewInit {

  @Input() config: DonutChartV4Config;
  @Input() context: any;
  @Input() applet: Applet
  @Input() data: any;
  @Input() componentProperties: DonutChartV4ComponentProperties;
  @Input() componentConfig: StaticComponentConfig;
  @Input() checkSize: any;

  @Output() updateStaticComponent = new EventEmitter<StaticComponentStateChange>();
  @Output() staticComponentItemClick = new EventEmitter<StaticComponentItemClick>();

  chartData = [];
  legendData = [];
  chartTableData = [];
  totalDataValue = 0;
  totalDataValueStr = '';
  chartColorsThemeAssigned = false;
  chartColorsPaletteAssigned = false;
  chartColors = [];
  totalExceptOthers = 0;
  totalPercentExceptOthers = 0;
  dataBackup = [];

  chart: any;
  colors: any;
  hcConfig: any;
  groupBy: any;
  aggreBy: any;
  aggreByObj: any;
  chartColorsThemeProp: any;
  chartColorsPaletteProp: any;
  colorForOthers: any;
  keys: any[];
  totalAllocations: any;
  noDataText: string;
  aggreByObjLegend: any;

  translateSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private translate: TranslateFacadeService,
    private fieldFormatService: FieldFormatService,
    private appsFacade: AppsFacade,
    private utilService: UtilService,
    private utilListService: UtilListService) { }

  ngOnInit(): void {
    this.controller();
    this.link();
  }

  ngOnDestroy() {
    this.translateSubscription?.unsubscribe();

    for (const pu in this.applet.config.popups) {
      if (this.context._popups[pu])
        delete this.context._popups[pu];
    }
  }

  ngOnChanges(changes) {
    for (const propName in changes) {
      if (Object.prototype.hasOwnProperty.call(changes, propName)) {
        if (propName === 'data') {
          if (!changes[propName].firstChange) {
            this.bindData();
          }
        }
      }
    }
  }

  ngAfterViewInit() {
    this.translateSubscription = this.translate.onLangChange.subscribe(() => this.updateListComponentTranslations());
  }

  bindData() {

    let chartColorsOne;
    let chartColorsTwo;
    let _setItemColor;

    if (this.chartColorsThemeAssigned == true && window['IX_Theme']?.properties[this.chartColorsThemeProp].Value2) {
      chartColorsOne = window['IX_Theme']?.properties[this.chartColorsThemeProp].Value2.split(',');
    };
    if (this.chartColorsPaletteAssigned == true && window['IX_Theme']?.chartAppearance[this.chartColorsPaletteProp].Value1) {
      chartColorsTwo = window['IX_Theme']?.chartAppearance[this.chartColorsPaletteProp].Value1.split('|');
    }; //Apply the colors in the theme properties if it is defined
    if (this.chartColorsThemeAssigned) {

      _setItemColor = (item, index) => {
        //If theme defines the asset class for which the color has to be applied
        if (this.chartColorsThemeAssigned == true && chartColorsOne) {
          item.color = chartColorsOne[index];
          item.borderColor = chartColorsOne[index];
          item.dataLabels = {
            enabled: false
          };
          this.colorForOthers = chartColorsOne[parseInt(this.config.numOfTopDataToShow)];
        }
        //Use palette color as the fallback if the theme does not defined the color for the specific asset class
        if (this.chartColorsThemeAssigned == false && this.chartColorsPaletteAssigned == true && chartColorsTwo) {
          item.color = chartColorsTwo[index];
          item.borderColor = chartColorsTwo[index];
          item.dataLabels = {
            enabled: false
          };
          this.colorForOthers = chartColorsTwo[parseInt(this.config.numOfTopDataToShow)];
        }
        //Use the colors provided in the component parameters if theme and palette colors cannot be applied
        if (this.chartColorsThemeAssigned == false && this.chartColorsPaletteAssigned == false) {
          item.color = this.config.chartColors[index];
          item.borderColor = this.config.chartColors[index];
          item.dataLabels = {
            enabled: false
          };
          this.colorForOthers = this.config.chartColors[parseInt(this.config.numOfTopDataToShow)];
        }
      }
    }
    //Use Palette defined colors as the fallback if the theme does not defined the colors
    if (!this.chartColorsThemeAssigned && this.chartColorsPaletteAssigned) {
      _setItemColor = (item, index) => {
        item.color = chartColorsTwo[index];
        item.borderColor = chartColorsTwo[index];
        item.dataLabels = {
          enabled: false
        };
        this.colorForOthers = chartColorsTwo[parseInt(this.config.numOfTopDataToShow)];
      }
    }
    //Use the component defined colors if neither theme nor palette defines the colors
    if (!this.chartColorsThemeAssigned && !this.chartColorsPaletteAssigned) {
      _setItemColor = (item, index) => {
        item.color = this.config.chartColors[index];
        item.borderColor = this.config.chartColors[index];
        item.dataLabels = {
          enabled: false
        };
        this.colorForOthers = this.config.chartColors[parseInt(this.config.numOfTopDataToShow)];

        if (this.config.chartColors[item.name.toLowerCase()]) {
          item.color = this.config.chartColors[item.name.toLowerCase()];
        }
      }
    }

    const tmp = this.data;

    setTimeout(() => {
      this.hcConfig.series[0].data.forEach((e, i) => {
        this.dataBackup[i] = _.clone(e);
        if (this.dataBackup[i].name === 'Others') {
          this.dataBackup[i].color = this.colorForOthers
        }
      });
    }, 1000);

    let noDataText = this.config.noDataText;
    let noDataFontSize = this.config.noDataFontSize;
    let noDataFontFamily = this.config.noDataFontFamily;
    let noDataFontColor = this.config.noDataFontColor;
    let noDataTextOffset = this.config.noDataTextOffset;

    noDataText = !_.isEmpty(noDataText) ? noDataText : "No Data Available";
    noDataFontFamily = !_.isEmpty(noDataFontFamily) ? noDataFontFamily : "";
    noDataFontSize = !_.isEmpty(noDataFontSize) ? noDataFontSize : "17px";
    noDataFontColor = !_.isEmpty(noDataFontColor) ? noDataFontColor : "#999";
    noDataTextOffset = !_.isEmpty(noDataTextOffset) ? noDataTextOffset : "-70";

    const element = this.elementRef.nativeElement;

    if ($.isArray(tmp) && tmp.length > 1) {
      $(element).find($('tspan:contains("' + noDataText + '")')).closest('text').remove();
      this.updateChartSeriesBB8(this.chart, tmp, this.applet.config.formats, this.groupBy, this.aggreBy, this.aggreByObj, this.aggreByObjLegend, _setItemColor);
    } else {
      this.chart.hideLoading();
      //to remove the undefined property in dataPoint before setting the Data empty
      _.forEach(this.chart.series[0].data, (itm, ind) => {
        delete itm['undefined'];
      });
      this.chart.series[0].setData([], true);
      this.chartTableData = [];
      this.noDataText = this.chart.renderer.text(noDataText, (parseInt(this.config.chartWidth) / 2 + parseInt(noDataTextOffset)), parseInt(this.config.chartHeight) / 2)
        .css({
          color: noDataFontColor,
          fontSize: noDataFontSize,
          fontFamily: noDataFontFamily
        }).addClass("donutChartv4NoData").add();
    }
  }

  controller() {

    // For Highcharts callbacks
    const thisformatSvc = this.fieldFormatService;
    const thiscomponentProperties = this.componentProperties;

    this.componentProperties.onItemClick = (s, selectedObj) => {
      const item = {};
      let i = 0;
      if (_.isPlainObject(selectedObj)) {
        _.forEach(this.chartData[0], (value) => {
          item[value] = selectedObj.dataForClick[i];
          i++;
        });
      }
      return this.staticComponentItemClick.emit({ componentType: 'donutchartv4', fnName: this.applet.name + '__CLE_OnComponentClick', data: item });
    };

    //Calculation of margin depending on the positioning of the legend selected by generating user
    const marginTop = 0.2 * parseFloat(this.config.chartHeight);
    const marginRight = (this.config.legendHorAlign == 'right' && this.config.legendVerAlign == 'top') || (this.config.legendHorAlign == 'right' && this.config.legendVerAlign == 'middle') ? 0.45 * parseFloat(this.config.chartWidth) : 20;
    const marginBottom = this.config.legendVerAlign == 'bottom' ? 0.35 * parseFloat(this.config.chartHeight) : 50;
    const marginLeft = (this.config.legendHorAlign == 'left' && this.config.legendVerAlign == 'top') || (this.config.legendHorAlign == 'left' && this.config.legendVerAlign == 'middle') ? 0.45 * parseFloat(this.config.chartWidth) : 20;

    const chartConfig = {
      chart: {
        backgroundColor: this.config.backgroundColor,
        type: 'pie',
        width: this.config.chartWidth,
        height: parseFloat(this.config.chartHeight),
        margin: [marginTop, marginRight, marginBottom, marginLeft],
        style: {
          fontFamily: this.config.fontFamily,
        },
      },
      lang: {},
      loading: {},
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        verticalAlign: this.config.legendVerAlign,
        align: this.config.legendHorAlign,
        borderRadius: 0,
        borderWidth: 0,
        useHTML: true,
        labelFormatter: function () {
          const itemName = this.config.truncLegndItemAt != null ? (this.name.length > this.config.truncLegndItemAt ? this.name.slice(0, this.config.truncLegndItemAt) + '...' : this.name) : this.name;
          let labelStr = '<div class="donutLegendName">' + itemName + '</div><div class="donutLegendValue">';
          if (this.config.showValueAsPercent == 'true') {
            labelStr += thisformatSvc.format('Percentage', this.y);
          } else {
            labelStr += this.config.showCurrencySymbol == 'true' ? thisformatSvc.format('CurrencyLimited2', Math.abs(parseInt(this.y))) : this.y.toFixed(0);
          }
          labelStr += '</div>';
          return labelStr;
        },
        itemStyle: {
          color: this.config.chartFontColor,
          fontWeight: 'normal',
        },
        itemMarginBottom: 5,
        maxHeight: this.config.legendHeight,
        width: this.config.legendWidth,
        x: this.config.legendHorAlign == 'right' ? -0.1 * parseFloat(this.config.chartWidth) + parseInt(this.config.xForLegend != undefined ? this.config.xForLegend : '0') : 0 + parseInt(this.config.xForLegend != undefined ? this.config.xForLegend : '0'),
        y:
          (this.config.legendHorAlign == 'right' && this.config.legendVerAlign == 'top') || (this.config.legendHorAlign == 'left' && this.config.legendVerAlign == 'top')
            ? 0.2 * parseFloat(this.config.chartHeight) + parseFloat(this.config.yForLegend != undefined ? this.config.yForLegend : '0')
            : 0 + parseFloat(this.config.yForLegend != undefined ? this.config.yForLegend : '0'),
      },
      title: {
        y: parseFloat(this.config.yForChartTitle),
        x: parseFloat(this.config.xForChartTitle),
        style: {
          color: this.config.chartTitleColor,
          fontWeight: 'bold',
          font: '18px',
        },
        align: this.config.chartTitleAlign,
        text: this.config.chartTitle != undefined || this.config.chartTitle != '' ? this.config.chartTitle : '',
      },
      subtitle: {
        text: this.config.chartSubtitle != undefined || this.config.chartSubtitle != '' ? this.config.chartSubtitle : '',
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      plotOptions: {
        pie: {
          size: '100%',
          shadow: this.config.chartShadow == 'false' ? false : true,
          borderWidth: this.config.pieSectorBorderWidth != null ? parseInt(this.config.pieSectorBorderWidth) : 1,
          events: {
            legendItemClick: () => false
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
          },
          animation: this.config.chartAnimation,
          point: {
            events: {
              click: function () {
                if (this.config.useComponentClickForChartLegend == 'true') return;

                if (thiscomponentProperties.onItemClick && this.name != 'Others') {
                  thiscomponentProperties.onItemClick(this, this);
                }
              },
            },
          },
        },
      },
      tooltip: {
        enabled: this.config.showToolTip == 'true',
        formatter: function () {
          const formats = this.applet.config.formats;
          const textSeparator = ': ';
          const useTooltipTextSeparator = _.isNil(this.config.useTooltipTextSeparator) || this.config.useTooltipTextSeparator === 'true';

          let formattedValue = '';

          if (this.config.showValueAsPercent == 'false') {
            if (this.config.showCurrencySymbol == 'true') {
              formattedValue = thisformatSvc.format('CurrencyLimited', this.y);
            } else {
              formattedValue = thisformatSvc.format('Money', this.y);
            }
          } else {
            formattedValue = thisformatSvc.format(formats[this.config.percentValueField], this.percentage / 100);
          }

          return '<b>' + this.point.name + '</b>' + (useTooltipTextSeparator ? textSeparator : ' ') + formattedValue;
        },
      },
      series: [
        {
          data: [],
          innerSize: this.config.pieInnerSize + '%',
          showInLegend: true,
          center: this.config.donutCenterXAxis != null && this.config.donutCenterXAxis != '' && this.config.donutCenterYAxis != null && this.config.donutCenterYAxis != '' ? [this.config.donutCenterXAxis + '%', this.config.donutCenterYAxis + '%'] : ['50%', '50%'],
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    this.colors = this.config.chartColors.split(',');
    this.hcConfig = _.extend({}, chartConfig);

    this.groupBy = [this.config.seriesArgumentField];
    this.aggreBy = [this.config.seriesValueField, this.config.percentValueField];
    this.aggreByObj = [
      {
        field: this.config.seriesValueField,
      },
      {
        field: this.config.percentValueField,
      },
    ];

    if (this.config.enableCustomLoading === 'true') {
      this.hcConfig.lang.loading = this.config.loadingText;
      this.hcConfig.loading.labelStyle = {
        top: this.config.loadingTop,
        left: this.config.loadingLeft,
        width: this.config.loadingWidth,
        height: this.config.loadingHeight,
        display: this.config.loadingSpanCssDisplay,
        'margin-left': this.config.loadingMarginLeft,
        'margin-right': this.config.loadingMarginRight,
      };

      if (!_.isEmpty(this.config.loadingHeight) && !_.isEmpty(this.config.loadingWidth) && !_.isEmpty(this.config.loadingBackgroundImage)) {
        this.hcConfig.loading.labelStyle['background-size'] = this.config.loadingWidth + ' ' + this.config.loadingHeight;
      }

      if (!_.isEmpty(this.config.loadingBackgroundImage)) {
        this.hcConfig.loading.labelStyle['background-image'] = 'url(' + this.config.loadingBackgroundImage + ')';
      }
    }
  }

  link() {

    const element = this.elementRef.nativeElement;

    this.utilService.updateAppWrapperHtmlAttributes(this.applet, element);

    //function to bind the loading announce texts to the datasource loading state change events for the list app
    window['IX_BindAnnounceTextToLoadngState'] && IX_BindAnnounceTextToLoadngState(this);



    if (this.config.displayDonutTable === true || this.config.displayDonutTable === 'true') {
      this.hcConfig.legend.enabled = false;
      this.hcConfig.chart.height = 345;
      this.hcConfig.chart.margin = [95, 20, 50, 20];
    }

    this.chart = new Highcharts.chart($(element).find('.DonutChartV4')[0], this.hcConfig);


    //To check and define an indicator for the presence/absence of colors in theme or palette

    if (typeof window['IX_Theme']?.properties != 'undefined' && window['IX_Theme']?.properties != null && window['IX_Theme']?.properties.length > 0) {
      for (let c = 0; c < window['IX_Theme']?.properties.length; c++) {

        if (window['IX_Theme']?.properties[c].PropertyName.toLowerCase() == 'customdatapointcolors' && window['IX_Theme']?.properties[c].Value1.toLowerCase() == 'donutchartv4') {
          this.chartColorsThemeAssigned = true;
          this.chartColorsThemeProp = c;
          continue;
        }
      }
    }
    if (typeof window['IX_Theme']?.chartAppearance != 'undefined' && window['IX_Theme']?.chartAppearance != null && window['IX_Theme']?.chartAppearance.length > 0) {
      for (let c = 0; c < window['IX_Theme']?.chartAppearance.length; c++) {
        if (window['IX_Theme']?.chartAppearance[c].Category.toLowerCase() == 'palette' && window['IX_Theme']?.chartAppearance[c].Property.toLowerCase() == 'colors') {
          this.chartColorsPaletteAssigned = true;
          this.chartColorsPaletteProp = c;
          continue;
        }
      }
    }


    if (!this.config._dataDisplayBehaviorOnEventOnly) {

      this.chart.showLoading();

      //     if (this.componentProperties.dataSource.isLoaded()) {
      //         this.componentProperties.dataSource.reload();
      //     } else {
      //         this.componentProperties.dataSource.load();
      //     }
    }
  }

  updateListComponentTranslations() {
    this.utilListService.updateListComponentTranslations(this);
  }

  legendItemClickTable = (selection) => {
    if (this.config.useComponentClickForChartLegend == 'true') {
      this.componentProperties.onItemClick(this).then(() => {
        if (_.isEmpty(this.config.mapClickedLegendItemToAppName)
          || _.isEmpty(this.config.mapClickedLegendItemToFieldName)) return;
        const appName = this.config.mapClickedLegendItemToAppName;
        const fieldName = this.config.mapClickedLegendItemToFieldName;
        const appState = { [fieldName]: selection.name };
        this.appsFacade.updateAppState(appName, appState);
      });
    }

    // data the chart will show
    const data = this.chart.series[0].points;
    // there is an undefined property, removing it to avoid browser errors
    for (let i = 0, l = data.length; i < l; i++) {
      delete data[i]['undefined'];
    };
    // toggle active state
    selection.active = !selection.active;

    const activeChartData = [];

    for (let i = 0; i < this.chartTableData.length; i++) {
      if (this.chartTableData[i].active) {
        activeChartData.push(this.chartData[i])
      }
    }

    // recommended method of highcharts to update chart data
    this.chart.series[0].setData(activeChartData);
  }

  createChartSeriesBB8(groupGraph, aggreBy, customizeItemFn) {
    let data = [];
    this.totalDataValue = 0;


    if (!$.isArray(aggreBy) && !aggreBy.length)
      return [];

    for (const group in groupGraph) {
      const item = {
        name: group,
        y: groupGraph[group].group.aggregations[aggreBy[0].field].calculatedVal,
        p: groupGraph[group].group.aggregations[aggreBy[1].field].calculatedVal,
        dataForClick: groupGraph[group].group.items[0]
      };
      this.totalDataValue += parseFloat(item.y);
      if (aggreBy) {
        for (let f = 1; f < aggreBy.length; f++) {
          item[aggreBy[f].target] = groupGraph[group].group.aggregations[aggreBy[f].field].calculatedVal;
        }
      }

      data.push(item);
    }

    const yValCompare = (a, b) => {
      if (a.y < b.y)
        return 1;
      if (a.y > b.y)
        return -1;
      return 0;
    }

    if (_.isString(this.config.sortLegendByName) && this.config.sortLegendByValue.length > 0) {
      const sortArray = this.config.sortLegendByName.toLowerCase().split('|');
      const sortMap = {};

      for (let i = 0; i < sortArray.length; i++) {
        sortMap[sortArray[i]] = i;
      }

      const sortByName = (a, b) => {
        let left = sortMap[a.name.toLowerCase()];
        let right = sortMap[b.name.toLowerCase()];

        if (left == null)
          left = 0;
        if (right == null)
          right = 0;

        if (left > right)
          return 1;

        if (left < right)
          return -1;

        return 0;
      }

      data.sort(sortByName);
    }

    if (typeof this.config.sortLegendByValue == 'undefined' || this.config.sortLegendByValue !== 'false') {
      data.sort(yValCompare);
    };
    if (customizeItemFn) {
      for (let i = 0; i < data.length; i++) {
        customizeItemFn(data[i], i);
      }
    }
    if (this.config.numOfTopDataToShow && parseInt(this.config.numOfTopDataToShow) < data.length) {
      data = data.splice(0, parseInt(this.config.numOfTopDataToShow));
      _.forEach(data, (value, index) => {
        this.totalExceptOthers += value.y;
        this.totalPercentExceptOthers += value.p;
      });
      data.push({
        'name': 'Others',
        'y': this.totalDataValue - this.totalExceptOthers,
        'p': 1 - this.totalPercentExceptOthers,
        'color': this.colorForOthers
      });
    };
    return data;
  };

  updateChartSeriesBB8(chart, rawData, formats, groupBy, aggreBy, aggreByObj, aggreByObjLegend, _setItemColor) {
    if (rawData.length == 0) {
      chart.hideLoading();
      return;
    }

    chart.showLoading();

    // remove chart series data
    if (chart.series[0].data.length) {

      //to remove the undefined property in dataPoint before setting the Data empty
      _.forEach(chart.series[0].data, (itm, ind) => {
        delete itm['undefined'];
      });
      chart.series[0].setData([]);
    };

    // data input is now object
    //this.utilService.convertRawDataArrayFromDSFormat(this, rawData);

    //1.- Set title

    //2.- Group Data
    const groupGraph = this.utilService.groupArray(rawData, groupBy, aggreBy);

    //3.- Get chart series data

    this.chartData = this.createChartSeriesBB8(groupGraph, aggreByObj, _setItemColor);

    setTimeout(() => {
      this.keys = [{
        name: 'color'
      }, {
        name: 'name'
      }, {
        name: 'amount'
      }, {
        name: 'percentage'
      }];
      this.totalAllocations = this.chartData.reduce((t, c) => t + c.y, 0);

    }, 1000);

    //4.-Add data series to the chart one by one and redraw chart only after adding the final series
    chart.series[0].setData(this.chartData);

    //To create data for legend table

    this.chartData.forEach((e, i) => {
      this.chartTableData.push({
        'color': this.hcConfig.series[0].data[i].color,
        'name': e.name,
        'amount': this.fieldFormatService.format(formats[this.config.seriesValueField], Math.round(e.y * 100) / 100),
        'percentage': this.fieldFormatService.format(formats[this.config.percentValueField], e.p),
        'active': true
      });
    });
    chart.hideLoading();
  };


}
