import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  ComponentFactoryResolver,
  RendererFactory2,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { AppEvent } from '../../state/app-events.enum';
import { appletComponentMap } from '../../components/applet-component-map';
import { ApplicationInformation } from '../../services/application-information.service';
import { AppsConstantsFacade } from '../../services/apps-constants.facade';
import { AppsFacade } from '../../state/apps.facade';
import { EventsService } from '../../services/events.service';
import { filter, first, map, tap } from 'rxjs/operators';
import { InputApplication } from '../input-application';
import { RefreshTemplateAppEventState } from '../../commands/refresh-template-app.command';
import { StoreService } from '../../services/store-service';
import { Subscription } from 'rxjs';
import * as StateActions from '../../state/apps.tokens';

export type AppTemplateConfig = {
  appName?: string;
  reportParams?: unknown;
  templateApp?: string;
  templateAppInfo?: AppInfo;

  //TODO: Discover the need for templateApplets array... or remove it.
  // This looks like support for a getTemplates binding that existed in
  // angularJS but was never called.
  // templateApplets: CanvasApplet[];

  templateAppName?: string;
  templateAppType?: string;
  applet?: any;
};

export type TemplateAppState = AppState & {
  TemplateApp,
  TemplateAppType,
};

interface TemplateApplet extends Applet {
  data: AppState,
};

@Component({
  selector: 'ic-app-template',
  templateUrl: './app-template.component.html',
  styleUrls: ['./app-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Angular Migration note:
 *
 * This component in angularJS is coupled to these
 * components:
 *  - icSliderHorizontal
 *  - icSetupChecklist
 *  - icReportScheduler
 *  - icRepeater
 *  - icSortableGroups
 * As these components are ported, note that the
 * former implementation of icAppTemplate was coupled
 * to angularJS isolateScope. Here in Angular, the
 * Refresh Template command works differently, as
 * does template rendering.
 *
 * There *will* doubtless be integration bugs to resolve,
 * as more portals using more features are converted to
 * Angular.
 */
export class AppTemplateComponent implements AfterViewInit, OnDestroy {
  @Input() config: AppTemplateConfig;
  @Input() applet: TemplateApplet;

  //Generated from Field.Sequence
  @Input() index: number | string;

  @Input() cssClass: string;

  @Input() indexField: string;

  //One array element per field, index will correspond to Sequence
  @Input() rawData: AppState[];

  @Input() context: Record<string, unknown>;

  //TODO: Analyze: are there scenarios in which we really need this entire object? (RMaurer)
  @Input() parentApplet: Applet;

  @ViewChild('viewContainer', { read: ViewContainerRef })
  viewContainer: ViewContainerRef;

  public reportImage: string;
  public templateAppType: string;

  private outputFileName: string;
  private onRefresh: Subscription;

  // Do not access directly; use getter
  private _renderer: Renderer2;
  private get renderer(): Renderer2 {
    if (!this._renderer) {
      this._renderer = this.rendererFactory.createRenderer(null, null);
    }
    return this._renderer;
  }

  constructor(
    private applicationInformation: ApplicationInformation,
    private appsConstantsFacade: AppsConstantsFacade,
    private appsFacade: AppsFacade,
    private eventsService: EventsService,
    private rendererFactory: RendererFactory2,
    private resolver: ComponentFactoryResolver,
    private storeService: StoreService,
  ) {}

  ngOnDestroy(): void {
    this.onRefresh?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.onRefresh = this.eventsService.events$
      .pipe(
        filter(
          (event) => {
            return event?.id === AppEvent.RefreshAppTemplate &&
            event.appName === this.config.templateAppInfo.canvasApplet.name
          }
        ),
        tap((event) =>
          this.refreshAppTemplate(event.state as RefreshTemplateAppEventState, event.appName)
        )
      )
      .subscribe();

    if (
      !this.config.templateAppInfo ||
      (this.config.reportParams &&
        this.config.templateAppInfo.appName !== this.config.templateApp)
    ) {
      const appName = this.config.appName || this.parentApplet.name;

      let templateAppType: string
      let templateApp: string;

      this.storeService.getCurrentAppState(appName).pipe(
        first(),
        map((appState: TemplateAppState) => {
          if (appState) {
            templateApp = appState.TemplateApp;
            templateAppType = appState.TemplateAppType;
          } else {
            templateApp = this.config.templateApp;
            templateAppType = this.config.templateAppType;
          }

          let parentAppContainerName = this.parentApplet.containerName;
          let parentAppEcdRequest = this.parentApplet.config.ecdRequest;

          if (this.context) {
            //for edge cases where if the app is a modal popup app
            //it does not generate the correct containerName correctly when doing the product release
            //and gets the theme name as container name
            if (
              parentAppContainerName.indexOf(".") == -1 &&
              this.context._popups &&
              this.context._popups["popupCLpc" + this.parentApplet.name.replaceAll(".", "_")]
            ) {
              parentAppContainerName = this.context._popups["popupCLpc" + this.parentApplet.name.replaceAll(".", "_")].canvas.parentName;
              if (parentAppContainerName) {
                try {
                  parentAppEcdRequest = this.applicationInformation.getAppInfo(parentAppContainerName).canvasApplet.config.ecdRequest;
                } catch {
                  // If we can't get this, and it's necessary, we'll throw further down.
                  parentAppEcdRequest = null;
                }
              }
            };
          }

          if (templateAppType) {
            this.templateAppType = templateAppType.toLowerCase();

            if (this.templateAppType === 'report' && parentAppContainerName) {
              this.outputFileName = appState.outputFileName as string;

              if (parentAppEcdRequest) {
                this.appsFacade.loadListData(
                  parentAppEcdRequest,
                  null
                );
              } else {
                throw new Error('Unable to load list data');
              }
              // If template app type is of report, add v4 to app name to differentiate from v1 reports
              templateApp = 'v4' + templateApp;
            }
          }

          this.config.templateAppInfo = this.applicationInformation.getAppInfo(
            templateApp
          );
          this.updateReportImageUrl();
          this.render();
        })
      ).subscribe();
    }
    this.render();
  }

  private render(): void {
    const rid = `CP_${this.config.templateAppInfo.canvasApplet.name}_${this.index}`;
    this.applet = {
      containerName: this.parentApplet.name,
      ...this.config.templateAppInfo.canvasApplet,
      rid,
      // For updating AppState, we need a unique name for each
      name: rid,
      data: undefined,
      config: {
        ...this.parentApplet.config,
      },
    };
    const componentType =
      appletComponentMap[this.config.templateAppInfo.canvasApplet.template];

    const factory = this.resolver.resolveComponentFactory<InputApplication>(
      componentType
    );
    this.viewContainer.clear();
    const appletRef = this.viewContainer.createComponent(factory);

    appletRef.instance.applet = this.applet;

    if (this.rawData[this.index]) {
      const data: AppState = this.indexField ? this.rawData[this.rawData[this.index][this.indexField]] : this.rawData[this.index];
      (appletRef.instance.applet as TemplateApplet).data = data;
    }

    appletRef.instance.context = this.context;
    appletRef.instance.cssClass = this.applet.class || '';

    const appletNode = appletRef.location.nativeElement;

    this.renderer.setAttribute(appletNode, 'id', this.applet.rid + 'wrapper');
    this.renderer.setAttribute(
      appletNode,
      'data-applet',
      this.config.templateAppInfo.canvasApplet.name
    );
    appletRef.changeDetectorRef.detectChanges();

    this.appsFacade.updateAppState(this.applet.name, this.rawData[this.index]);

    //TODO: Validate via end-to-end test that dynamic replacement and conditional formatting
    // are handled by the rendered implementation of BaseApplication, when a portal implementing these
    // features is available for Angular conversion.
  }

  private refreshAppTemplate(eventState: RefreshTemplateAppEventState, refreshToApp: string): void {
      const appState = this.rawData[this.index];
      let templateApp: string;

      if (eventState.isReportTemplate) {
        templateApp = 'v4' + appState[eventState.fieldName];
        this.index = this.rawData.findIndex(
          (item) => item.Id === eventState.reportDefinitionId
        );
        this.outputFileName = eventState.outputFileName;
      }

      if (this.index !== -1 && appState.ReportDialogPart) {
        refreshToApp = 'v4' + appState.ReportDialogPart;
        templateApp = 'v4' + appState.ReportDialogPart;

        const newState: AppState = {
          ...appState,
          [eventState.fieldName]: appState.ReportDialogPart,
        };
        this.appsConstantsFacade.dispatchStateAction(
          StateActions.UPDATE_APP_STATE,
          refreshToApp,
          newState
        );
      }

      if (templateApp && this.config.templateAppInfo?.appName != templateApp) {
        // Doubtful that we still need this timeout. End-to-end testing needed.
        //TODO: Verify functionality and remove timeout.
        // setTimeout(() => {
        this.config.templateAppInfo = this.applicationInformation.getAppInfo(
          templateApp
        );
        this.render();
        // });
      }
      this.updateReportImageUrl();
  }

  private updateReportImageUrl(): void {
    if (this.templateAppType === 'report' && this.outputFileName) {
      const reportImageName = 'Report.' + this.outputFileName + '.png';
      const themeName = IX_Theme.themeName;
      this.reportImage =
        '/App_Themes/Default/images/ThemeImageOverrides/' +
        themeName +
        '/ApplicationImages/Tiles/' +
        reportImageName;
    }
  }
}
