import numeral from 'numeral';
import Sugar from 'sugar/date';

type Validators = {
    required: any,
    range: any,
    numeric: any,
    stringLength: any,
    pattern: any,
    email: any,
    custom: any,
    mustSelectFromList: any,
    compare: any,
    dateRange: any
}

export abstract class RuleValidators {
    
    private validators: Validators;

    //#region Work around for DevExpress.utils.common not found on 17.2
    
    isDate(object) {
        return "date" === this.type(object)
    }

    isDefined(object) {
        return null !== object && void 0 !== object
    }

    isString(object) {
        return "string" === typeof object
    }

    type(object) {
        const types = {
            "[object Array]": "array",
            "[object Date]": "date",
            "[object Object]": "object",
            "[object String]": "string",
            "[object Null]": "null"
        };
        const typeOfObject = Object.prototype.toString.call(object);
        return "object" === typeof object ? types[typeOfObject] || "object" : typeof object
    }
    
    //#endregion

    get(type) {
        return this.validators[type];
    }

    getAll() {
        return this.validators;
    }

    required(value, rule) {
        if (!this.isDefined(value)) {
            return false;
        }
        if (false === value) {
            return false;
        }
        value = String(value);
        if (rule.trim || !this.isDefined(rule.trim)) {
            value = $.trim(value)
        }
        return "" !== value;
    }

    numeric(value, rule) {
        let isValid = $.isNumeric(value);
        if (!isValid && this.isString(value)) {
            isValid = $.isNumeric(numeral(value)._value);
        }
        return isValid;
    }

    setRuleValidators() {
        this.validators = {
            required: {
                validate: (value, rule) => {
                    return new Promise((resolve, reject) => {
                        const isValid = this.required(value, rule);
                        resolve(isValid);
                    });
                }
            },
            numeric: {
                validate: (value, rule) => {
                    return new Promise((resolve, reject) => {
                        const isValid = this.numeric(value, rule);
                        resolve(isValid);
                    });
                }
            },
            range: {
                validate: (value, rule) => {
                    return new Promise((resolve, reject) => {
                        const validNumber = this.numeric(value, rule),
                            validValue = this.isDefined(value),
                            number = validNumber ? numeral(value)._value : validValue && value.valueOf(),
                            minFunc = rule.minFunc,
                            maxFunc = rule.maxFunc;
                        let min = rule.min,
                            max = rule.max;

                        if (this.isDefined(minFunc)) {
                            min = minFunc();
                        }
                        if (this.isDefined(maxFunc)) {
                            max = maxFunc();
                        }

                        if (!(validNumber || this.isDate(value)) && !validValue) {
                            resolve(false);
                            return;
                        }
                        if (this.isDefined(min)) {
                            if (this.isDefined(max)) {
                                resolve(number >= min && number <= max);
                                return;
                            }
                            resolve(number >= min);
                            return;
                        } else {
                            if (this.isDefined(max)) {
                                resolve(number <= max);
                                return;
                            } else {
                                // if no min or max, range check should succeed, 
                                // or validation will be broken!
                                resolve(true);
                                return;
                            }
                        }
                    });
                }
            },
            stringLength: {
                validate: (value, rule) => {
                    value = this.isDefined(value) ? String(value) : "";
                    if (rule.trim || _.isNil(rule.trim)) {
                        value = $.trim(value)
                    }
                    return this.validators.range.validate(value.length, $.extend({}, rule))
                }
            },
            pattern: {
                validate: (value, rule) => {
                    return new Promise((resolve, reject) => {
                        let pattern = rule.pattern;
                        if (this.isString(pattern)) {
                            pattern = new RegExp(pattern)
                        }
                        const isValid = pattern.test(value);
                        resolve(isValid);
                    });
                }
            },
            email: {
                validate: (value, rule) => {
                    return value === "" || this.validators.pattern.validate(value, $.extend({}, rule, {
                        // eslint-disable-next-line no-useless-escape
                        pattern: /^[\d\w\._\-]+@([\d\w\._\-]+\.)+[\w]+$/i
                    }));
                }
            },
            custom: {
                validate: (value, rule) => {
                    return new Promise((resolve, reject) => {
                        const isValid = rule.validationCallback({
                            value: value,
                            rule: rule
                        });
                        resolve(isValid);
                    });
                }
            },
            mustSelectFromList: {
                validate: (value, rule) => {
                    return new Promise((resolve, reject) => {
                        const isValid = rule.validationCallback({
                            value: value,
                            rule: rule
                        });
                        resolve(isValid);
                    });
                }
            },
            compare: {
                validate: (value, rule) => {
                    return new Promise((resolve, reject) => {
                        if (!rule.comparisonTarget) {
                            throw new Error("Missing compare rule settings")
                        }
                        $.extend(rule, {
                            reevaluate: true
                        });
                        const otherValue = rule.comparisonTarget(),
                            type = rule.comparisonType || "==";
                        let isValid;
                        switch (type) {
                            case "==":
                                isValid = value == otherValue;
                                break;
                            case "!=":
                                isValid = value != otherValue;
                                break;
                            case "===":
                                isValid = value === otherValue;
                                break;
                            case "!==":
                                isValid = value !== otherValue;
                                break;
                            case ">":
                                isValid = value > otherValue;
                                break;
                            case ">=":
                                isValid = value >= otherValue;
                                break;
                            case "<":
                                isValid = value < otherValue;
                                break;
                            case "<=":
                                isValid = value <= otherValue;
                                break;
                        }
                        resolve(isValid);
                    });
                }
            },
            dateRange: {
                validate: (value, rule) => {
                    const validDate = new Sugar.Date(value).isValid().raw;
                    const validValue = this.isDefined(value);
                    if (!validDate || !validValue) {
                        return Promise.resolve(false);
                    }
                    const promises = [
                        this.isDefined(rule.minFunc) ? rule.minFunc() : rule.min,
                        this.isDefined(rule.maxFunc) ? rule.maxFunc() : rule.max,
                    ];
                    return Promise.all(promises)
                        .then(([min, max]) => {
                            const sugarDate = new Sugar.Date(value);
                            if (this.isDefined(min)) {
                                const sugarMinDate = new Sugar.Date(min);
                                if (this.isDefined(max)) {
                                    const sugarMaxDate = new Sugar.Date(max);
                                    return (sugarDate.raw >= sugarMinDate.raw && sugarDate.raw <= sugarMaxDate.raw);
                                }
                                return (sugarDate.raw >= sugarMinDate.raw);
                            } else {
                                if (this.isDefined(max)) {
                                    const sugarMaxDate = new Sugar.Date(max);
                                    return (sugarDate.raw <= sugarMaxDate.raw);
                                } else {
                                    // Fall through to true... perhaps an interpolated field value is empty.
                                    return true;
                                }
                            }
                        });
                }
            }
        };
    }
}