import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { from, Observable, of } from 'rxjs';
import { switchMap } from "rxjs/operators";
import { CommandListCollectionService } from "./command-list-collection.service";
import { EcdService } from '../services/clientservices/ecd.service';

import { BaseEffectCommand } from './base-effect.command';
import { ApiClientService } from '../services/api-client.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class LogoutCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected apiClientService: ApiClientService,
        protected cacheManagerService: CacheManagerService,
        private ecdService: EcdService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.logoutCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    return this.logout(commandConfig)
                        .pipe(
                            switchMap(() => this.getNextActions(commandConfig))
                        );
                })
            )
    );

    private logout(commandConfig: CommandConfig): Observable<any> {
        const operation = "logout";
        const appName = commandConfig.appName;
        const actionPath = commandConfig.options.path;
        const ecdRequest = this.apiClientService.createECDHeader(appName, appName, operation, actionPath.Path);
        const promise = this.ecdService.request(ecdRequest);
        return from(promise)
            .pipe(
                switchMap((data: any) => {
                    const shouldSkipRedirect = _.first(commandConfig.parameters) || 'no';
                    const skipRedirect = this.isTruthy(shouldSkipRedirect);
                    if (!skipRedirect && !_.isNil(data?.JSProperties?.cpURL)) {
                        this.setLocationHref(data.JSProperties.cpURL);
                    }
                    return of(data);
                })
            );
    }
}
