import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class ToggleSideNavigationCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.toggleSideNavigationCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    const toggleSideNavigation = this.toggleSideNavigation(commandConfig);
                    return from(toggleSideNavigation)
                        .pipe(
                            switchMap((id) => {
                                const event = { id, state: {} };
                                const publishEventAction = CommandActions.publishEvent({ event });
                                return this.appendNextActions([publishEventAction], commandConfig);
                            })
                        );
                })
            )
    );

    toggleSideNavigation(commandConfig: CommandConfig): Observable<any> {

        let panelLocation = commandConfig.parameters[0];
        let state = commandConfig.parameters[1];

        let delayInMillisecondsToFocusClose = parseInt(commandConfig.parameters[3]);
        if (_.isNaN(delayInMillisecondsToFocusClose))
            delayInMillisecondsToFocusClose = 0;

        if (_.isNil(panelLocation) || _.isNil(state))
            return throwError("Panel location or State were not provided!");

        panelLocation = panelLocation.trim().toLowerCase();
        let matches = panelLocation.match(/left|right/i);
        if (_.isNil(matches) || matches.length == 0)
            return throwError("Value for panel location '" + panelLocation + "' not supported. Supported values are: left/right.");

        state = state.trim().toLowerCase();
        matches = state.match(/open|close/i);
        if (_.isNil(matches) || matches.length == 0)
            return throwError("Value for state '" + state + "' not supported. Supported values are: open/close.");

        const panelSelector = panelLocation == 'left' ? 'IXLeftPanel' : 'IXRightPanel';
        const target = $('.' + panelSelector);
        if (target.length == 0)
            return throwError("No side panel attached");

        const addSuffix = (state == 'open') ? 'open' : 'closed';
        const removeSuffix = (state == 'open') ? 'closed' : 'open';

        target.removeClass(panelSelector + '-' + removeSuffix);
        target.addClass(panelSelector + '-' + addSuffix);

        const eventName = (state == "close") ? "SideNavigationClose" : "SideNavigationOpen";
        if (state == "close") {
            target.attr("aria-hidden", "true");
            target.find("[tabindex]").each(function () {
                const current = $(this);
                current.attr("data-removedTabIndex", current.attr("tabindex"));
                current.removeAttr("tabindex");
            });
            const focusCssSelectorOnClose = commandConfig.parameters[2];
            setTimeout(() => {
                const closeElement = $(focusCssSelectorOnClose);
                if (closeElement.length == 0)
                    return;
                closeElement.focus();
                closeElement.attr("tabindex", "0");
                closeElement.focus();
            }, delayInMillisecondsToFocusClose);
        }
        else {
            target.removeAttr("aria-hidden");
            target.find("[data-removedTabIndex]").each(function () {
                const current = $(this);
                current.attr("tabindex", current.attr("data-removedTabIndex"));
                current.removeAttr("data-removedTabIndex");
            });
            setTimeout(() => {
                const currentStep = $("body").attr("data-step").toiXingName();
                let currentStepItem = $(".ic-data-menuitem-targetapp-" + currentStep + ":visible");
                if (currentStepItem.length == 0) {
                    currentStepItem = $(".ic-dxmenu-parentof-" + currentStep);
                    if (currentStepItem.length == 0)
                        return;
                }
                const dxMenuItem = currentStepItem.data("dxMenuItem");
                dxMenuItem?._component?.option("focusedElement", currentStepItem.parent());
                dxMenuItem?._component?.focus();
            }, 0);
        }

        return of(eventName);
    }

}
