/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxDropDownEditor = require('devextreme/ui/drop_down_editor/ui.drop_down_editor');

var TEXTEDITOR_CLEAR_BUTTON_CLASS = "dx-clear-button-area";
var TEXTEDITOR_CLEAR_ICON_CLASS = "dx-icon-clear";
var TEXTEDITOR_ICON_CLASS = "dx-icon";

var eventsEngine = DevExpress.events,
  eventUtils = DevExpress.events.utils,
  clickEvent = DevExpress.events.click;
var _dxDropDownEditor = DevExpress.ui.dxDropDownEditor.redefine({
  _createClearButton: function (text) {
    var $clearButton = $(
      "<span tabindex='0' aria-label='Clear current dropdown selection' role='button'>"
    )
      .addClass(TEXTEDITOR_CLEAR_BUTTON_CLASS)
      .append(
        $("<span>")
          .addClass(TEXTEDITOR_ICON_CLASS)
          .addClass(TEXTEDITOR_CLEAR_ICON_CLASS)
      );
    eventsEngine.on(
      $clearButton,
      eventUtils.addNamespace("dxpointerdown", this.NAME),
      function (e) {
        if ("mouse" === e.pointerType) {
          e.preventDefault();
        }
      }
    );
    eventsEngine.on(
      $clearButton,
      eventUtils.addNamespace(clickEvent.name, this.NAME),
      this._clearValueHandler.bind(this)
    );
    iXing.Helpers.handleEnterKeyForAccessibility($clearButton);
    return $clearButton;
  }
});

DevExpress.registerComponent("dxDropDownEditor", _dxDropDownEditor);
