import { Injectable } from "@angular/core";
import { filter, tap } from 'rxjs/operators';
import { EventsService } from './events.service';

@Injectable()
export class WorkflowConfigurationService {

    private config: WorkflowConfiguration;

    constructor(private eventsService: EventsService) {
        this.eventsService
            .configEvent$
            .pipe(
                filter(event => event?.action?.contains("end-load-configuration")),
                tap(event => this.setConfiguration(event.configuration))
            ).subscribe();
    }

    private deleteMenuVariables() {
        delete window['icMenuSecondaryItems'];
        delete window['icMenuDefaultItems'];
    }

    private setConfiguration(config: WorkflowConfiguration): void {
        const prevName = _.get(this.config?.tree, ['name']);
        const currentName = _.get(config?.tree, ['name']);
        if (prevName != currentName) {
            this.deleteMenuVariables(); // TODO: test if this is the right place
            this.config = _.merge({}, config) as WorkflowConfiguration;
        }
    }

    getWorkflowTree(): any {
        return this.config?.tree || {};
    }

    getFieldFormats(): any {
        return this.config?.formats || {};
    }

    getAppInfo(): Record<string, AppInfo> {
        return this.config?.appInfo || {};
    }

    getCommandList(): any {
        return this.config?.commandList || {};
    }

    getConstants(): any {
        return this.config?.constants || {};
    }

    getConfiguration(): any {
        return this.config?.canvas || {};
    }

    getEventMaps(): any {
        return this.config?.eventMaps || {};
    }
}
