/* eslint-disable @typescript-eslint/camelcase */
// tslint:disable: no-bitwise
export default class UtilityFunctions {
  static setCustomSelector() {
    const isElementVisible = (element) => {
      const $element = $(element);
      return $element.is(':visible') && 'hidden' !== $element.css('visibility') && 'hidden' !== $element.parents().css('visibility');
    };
    const isElementFocusable = (element, tabIndex) => {
      const nodeName = element.nodeName.toLowerCase(),
        isTabIndexNotNaN = !isNaN(tabIndex),
        isVisible = isElementVisible(element),
        isDisabled = element.disabled,
        isDefaultFocus = /^(input|select|textarea|button|object|iframe)$/.test(nodeName),
        isHyperlink = 'a' === nodeName;
      let isFocusable = true;
      if (isDefaultFocus) {
        isFocusable = !isDisabled;
      } else {
        if (isHyperlink) {
          isFocusable = element.href || isTabIndexNotNaN;
        } else {
          isFocusable = isTabIndexNotNaN;
        }
      }
      return isVisible ? isFocusable : false;
    };
    $.extend($.expr[':'], {
      'ix-tabbable': (element) => {
        const tabIndex = element.attr('tabindex');
        return (isNaN(tabIndex) || tabIndex >= 0) && isElementFocusable(element, tabIndex);
      },
    });
  }

  static centerElement($element) {
    const $window = $(window);
    const windowProps = { height: $window.height(), width: $window.width() };
    const elementProps = { height: $element.height(), width: $element.width() };
    let maxWidth = $element.css('maxWidth');
    let maxWidthValue = parseFloat(maxWidth);

    const isPercent = _.endsWith(maxWidth, '%');
    if (isPercent) {
      maxWidthValue = Math.round((maxWidthValue * windowProps.width) / 100);
    }

    if (windowProps.width <= elementProps.width || (maxWidth != 'none' && windowProps.width <= maxWidthValue)) {
      maxWidth = windowProps.width * 0.95; // Should be same as dx popup width
    } else {
      maxWidth = maxWidth == 'none' ? elementProps.width : maxWidthValue;
    }

    $element.css({ position: 'fixed', maxWidth: maxWidth });
    $element.position({ of: window, my: 'center', at: 'center' });
  }

  static getValue(propValue) {
    if (typeof propValue === 'object' && propValue !== null) return propValue.value === void 0 ? null : propValue.value;

    return propValue;
  }

  static getFirstRowFromDxDataGrid(records: any[]): any {
    const row = records.length > 0 ? { ...records[0] } : null;
    if (!_.isNil(row?.key)) {
      if (!_.isNil(row.items))
        records = row.items;
      if (!_.isNil(row.collapsedItems))
        records = row.collapsedItems;
      return this.getFirstRowFromDxDataGrid(records);
    }
    return row;
  }

  private static getDxComponentType(el) {
    return _.isFunction(el.data) && _.isArray(el.data('dxComponents')) && el.data('dxComponents').length > 0 ? el.data('dxComponents')[0] : null;
  }

  static getDxComponentClass(el) {
    return this.getDxComponentType(el) !== null ? 'dx-' + this.getDxComponentType(el).substr(2).toLowerCase() : null;
  }

  static isPromise(obj) {
    return ((typeof obj === 'object' && obj !== null) || $.isPlainObject(obj)) && !$.isEmptyObject(obj);
  }

  static getFieldRowTypeFromHTMLClass(classStr) {
    classStr = classStr || '';
    const res = classStr.split(' ').filter(this.fieldClassArrayFilter);
    if (Array.isArray(res) && !!res.length) return res[0];
    return;
  }

  private static fieldClassArrayFilter(it, index, array) {
    return it.startsWith('CL_');
  }

  static getThemeProperty(name: string): ThemeProperty {
    return _.find(IX_Theme?.properties || [], (item) => name?.EqualsIgnoreCase(item.PropertyName));
  }

  static warnUserOnExternalLinks(options) {
    const _options = options;
    function _shouldWarnUser(url) {
      const thisSiteUrl = new RegExp(window.location.origin);
      const protocol = 'http';
      url = url || '';
      if (!_.isNil(_options.exclude) && !_.isEmpty(_options.exclude)) {
        for (let i = 0; i < _options.exclude.length; i++) {
          const expressionToExclude = new RegExp(_options.exclude[i]);
          if (expressionToExclude.test(url)) return false;
        }
      }
      return url.substr(0, protocol.length).toLowerCase() === protocol && !thisSiteUrl.test(url);
    }
    function _register() {
      $(document).on('click', 'a', function (e) {
        const href = $(this).attr('href');
        if (_shouldWarnUser(href) && !confirm(_options.message)) {
          e.preventDefault();
        }
      });
    }
    function _unregister() {
      $(document).off('click', 'a');
    }
    return {
      register: _register,
      unregister: _unregister,
    };
  }

  static dxGridScrollToHeaderOnPagerClick(opts) {
    const _c = opts,
      gCEName = 'contentReady',
      jQevName = 'dxclick.undefinedPages';
    function _scrollToGridHeader() {
      _c.model = _c.component.initialOption();
      if (_c.model.doNotScrollDuringPaging === true) return;
      const dataGridHeader = _c.element.find('.dx-datagrid-headers')[0];
      const dataGridRows = _c.element.find('.dx-datagrid-rowsview')[0];
      !dataGridHeader ? dataGridRows.scrollIntoView({ block: 'center' }) : dataGridHeader.scrollIntoView();
    }
    function _processPageClick(e) {
      const pageNumber = $(e.target).text(),
        pageIndex = '>' === pageNumber ? _c.component.option('pageCount') + 1 : Number(pageNumber);
      if (!isNaN(pageIndex) && typeof pageIndex === 'number') {
        _scrollToGridHeader();
      }
    }
    function _processNavBtnClick(e) {
      if (!$(e.target).hasClass('dx-button-disable')) {
        _scrollToGridHeader();
      }
    }
    function _bindToEvent() {
      const pagerChooser = _c.element.find('div.dx-pager');
      pagerChooser.find('div.dx-pages').on(jQevName, '.dx-page', function (e) {
        _processPageClick(e);
      });

      pagerChooser.on('ic-paginationbuttonclick', 'div.dx-navigate-button', function (e) {
        _processNavBtnClick(e);
      });
      pagerChooser.on('ic-paginationbuttonclick', 'div.dx-page.dx-selection', function (e) {
        setTimeout(function () {
          _processPageClick(e);
        }, 0);
      });
    }
    function _onContentReady() {
      _bindToEvent();
    }
    const _onCRD = _.debounce(_onContentReady);
    function _register() {
      _c.component.on(gCEName, _onCRD);
    }
    function _unregister() {
      _c.component.off(gCEName, _onCRD);
    }
    return {
      register: _register,
      unregister: _unregister,
    };
  }

  static setHelperFunctionsList(props, $scope, _setContextForListApp) {
    $scope.$datagridHeaderFixed = null;
    $scope.freezeHeadersOnScroll = props.freezeHeadersOnScroll;

    //function _onCellClick(info) {
    const existingOnCellClick = $scope.gridProperties.onCellClick;
    $scope.gridProperties.onCellClick = function (info) {
      const $element = $(info.cellElement);
      if (existingOnCellClick && _.isFunction(existingOnCellClick)) {
        existingOnCellClick(info);
      }

      if (info.rowType && info.rowType != 'data') return;

      //to prevent the 'rowClick' when clicking on the 'checkbox' when the multiRow selection mode is enabled
      const selectionMode = _.get(info.model, 'gridProperties.selection.mode', '');
      if (selectionMode == 'multiple' && $element.find('.dx-checkbox').length !== 0) {
        return;
      }

      //to prevent the rowClick when the 'expand row - ellipsis' is clicked in the adaptive mode for the dataGrid
      if ($element && $element.hasClass('dx-command-adaptive')) {
        return;
      }

      const data = { ...info.data, _rowIndex: info.rowIndex };
      _setContextForListApp({ data });
      if (props.onEventFunctionName &&  _.isFunction(props.onEventFunctionName))
        props.onEventFunctionName(); // update if debounce
    };

    const existingOnCellPrepared = $scope.gridProperties.onCellPrepared;
    $scope.gridProperties.onCellPrepared = function (e) {
      if (e.rowType === 'header' && e.columnIndex === 0) {
        const cols: any = _.maxBy(e.component.getController('columns').getVisibleColumns(), 'groupIndex');
        if (cols) $scope.maxGroupIndex = cols.groupIndex;
      }

      if (_.isFunction($scope.onCellPreparedForCustomTotalSummaries)) {
        $scope.onCellPreparedForCustomTotalSummaries(e);
      }

      if (existingOnCellPrepared && _.isFunction(existingOnCellPrepared)) {
        existingOnCellPrepared(e);
      }

      if (e.rowType !== 'group') return;

      if (e.row.groupIndex < $scope.maxGroupIndex) {
        _.forEach(e.summaryItems, function (item) {
          if (item.summaryType === 'custom'
            && (item.name === 'distinct' || item.stopAggregationForceBlank)
            && !item.aggregateAllGroupLevels
            && item.showInColumn === e.column.dataField) {
            e.cellElement.text('');
          }
        });
      }

      if (existingOnCellPrepared && _.isFunction(existingOnCellPrepared)) {
        existingOnCellPrepared(e);
      }
    };
  }

  static setAriaLabelToColHeaderButtons(e) {
    //all buttons needs to have aria-labels for accessibility compliance
    //hence grabbing the aria-label from the column header container div and assigning to the child div with role button
    const columnHeaderEle = e.element.find('[role = columnheader]');
    _.forEach(columnHeaderEle, function (el, ind) {
      $(el).find('[role = button]').attr('aria-label', $(el).data('arialabel'));
    });
  }

  static lightenColorByPercentage(color, percentage) {
    const n = parseInt(color.slice(1), 16),
      a = Math.round(2.55 * percentage),
      // Bitshift 16 bits to the left
      r = (n >> 16) + a,
      // Bitshift 8 bits to the left based on blue
      b = ((n >> 8) & 0x00ff) + a,
      //
      g = (n & 0x0000ff) + a;
    // Calculate
    return (
      '#' +
      (0x1000000 + (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000 + (b < 255 ? (b < 1 ? 0 : b) : 255) * 0x100 + (g < 255 ? (g < 1 ? 0 : g) : 255))
        .toString(16)
        .slice(1)
    );
  }

  static buttonRetainFocus(appSelector: string): void {

    // TODO reference device.service functions or move this elsehwere
    function IX_isMacOS() {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      return isMac;
    }

    function IX_isIOS() {
      const ua = navigator.userAgent;
      const isiOS = /iPad/i.test(ua) || /iPhone/i.test(ua) || /iPhone/i.test(ua) ||
        (/Mac/i.test(ua) && "ontouchend" in document); // added to detect ipad on ios 13+ 
      // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
      return isiOS;
    }

    if (!IX_isIOS() && !IX_isMacOS()) {
      return;
    }

    // Ensures buttons inside dxTabPanels retain focus when clicked
    // which prevents a bug in IOS that returns focus to another tab

    // Select only applets inside dxTabPanel instance
    const appletSelector = '.dx-tabpanel-container ' + appSelector;

    const target = $(appletSelector);
    if (!target?.length) {
      return;
    }

    // prevent multiple bindings to same target
    $(appletSelector).off('mousedown', UtilityFunctions.retainFocus).on('mousedown', UtilityFunctions.retainFocus);
  }

  static retainFocus = function (e: any): void {
    // Keep this function outside buttonRetainFocus to reuse the single instance and prevent multiple bindings.
    const target = $(e.target).closest('.iXingBtn, .dx-button');
    if (!target?.length) {
      return;
    }

    $(target).focus();

    // Prevent the mousedown browser default behaviour, but allow events to fire and bubble
    e.preventDefault();
  };

  static bindPTierAPI(fnName: string, fn: Function, scope: any): void {
    window.IC = window.IC || {};
    window.IC.functions = window.IC.functions || {};
    window.IC.API = window.IC.API || {};
    window.IC.Custom = window.IC.Custom || {};
    window.IC.API[fnName] = fn.bind(scope);
  }

  static bindPTierComponentFunction(appName: string, fieldName: string, fnName: string, fn: Function, scope: any): void {
    window.IC = window.IC || {};
    window.IC.API = window.iXing.API || {};
    window.IC.API.Components = window.IC.API.Components || {};
    window.IC.API.Components[appName] = window.IC.API.Components[appName] || {};
    window.IC.API.Components[appName] = { ...window.IC.API.Components[appName], [fieldName]: { [fnName]: fn.bind(scope) } };
  }

  static bindTestingFunctionAPI(fnName: string, fn: Function, scope: any): void {
    window.IC = window.IC || {};
    window.IC.Testing = window.IC.Testing || {};
    window.IC.Testing[fnName] = fn.bind(scope);
  }

  static bindGlobalLibrariesAPI(libName: any, lib: any): void {
    window.IC = window.IC || {};
    window.IC.Testing = window.IC.Testing || {};
    window.IC.Testing[libName] = lib;
  }

  static generateUUID() {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.abs((d + Math.random() * 16) % 16 | 0);
      d = (d / 16) | 0;
      return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return uuid;
  }

  static handleMonthFieldMasks(format) {
    // Devexpress localization date format does not accept Mon/Month as Month value
    if (format !== null && format.toLocaleLowerCase().includes("mon")) {
      if (format.toLocaleLowerCase().includes("month")) {
        format = format.replace(/Month/gi, "MMMM");
      } else {
        format = format.replace(/Mon/gi, "MMM");
      }
    }
    return format;
  }

  static getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  static toContextAppName(appName: string) {
    return appName.replace(/[\._\s]/g, '').toLowerCase();
  }

}
