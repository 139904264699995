export enum AppEvent {
    OnLogin = 'OnLogin.Event',
    EnhancedAlert = 'EnhancedAlert.Event',
    EnhancedTimeout = 'EnhancedTimeout.Event',
    ThemeCustomAlert = 'ThemeCustomAlert.Event',
    SignOut = 'ApplicationEvent.SignOut.Event',
    SessionTimeout = 'ApplicationEvent.SessionTimeout.Event',
    SessionKeepAlive = 'ApplicationEvent.SessionKeepAlive.Event',
    SetLocale = 'SetLocale.Event',
    RefreshAppTemplate = 'ApplicationEvent.RefreshAppTemplate.Event',
    RefreshValidationGroupButtons = 'v4.validationGroup',
    ResetFileUploadMulti = 'ApplicationEvent.ResetMultiFileUpload.Event',
    ResetAudioRecordState = 'ApplicationEvent.ResetAudioRecordState.Event',
    ResetDirtyFieldsToPristine = 'dataService.Reset.Dirty.Fields.To.Pristine',
    DocumentUpload = 'ApplicationEvent.DocumentUpload.Event',
    DocumentDownload = 'ApplicationEvent.DocumentDownload.Event',
    Base64Download =  "ApplicationEvent.Base64Download.Event",
    DynamicEventGoogleAnalytics = 'ApplicationEvent.DynamicEventGoogleAnalytics.Event',
    UpdateCommonLoadingPanel = 'ApplicationEvent.UpdateCommonLoadingPanel.Event',
    SideNavigationOpen = 'SideNavigationOpen',
    SideNavigationClose = 'SideNavigationClose',
    DataGridExport = 'DataGridExport.Event',
    HideDataGridOverlays = 'ApplicationEvent.HideDataGridOverlays.Event',
    TabToActivate = 'ApplicationEvent.TabToActivate.Event',
    ExecuteButtonClick = 'ApplicationEvent.ExecuteButtonClick.Event',
    ToggleDisabledButton = 'ApplicationEvent.ToggleDisabledButton.Event',
    MenuBadge = 'UpdateMenuBadges.Event',
    IFrameData = 'ApplicationEvent.IFrameData.Event',
    ExternalUrl = 'ApplicationEvent.ExternalUrl.Event',
    StaticLinks = 'ApplicationEvent.StaticLinks.Event',
    HorizontalScrollButtonReadingOrderChanged = 'ListApp.HorizontalScrollButtonReadingOrderChanged.Event',
    ResetHorizontalScrollHandles = 'ListApp.ResetHorizontalScrollHandles.Event',
    LoadHorizontalScrollHandles = 'ListApp.LoadHorizontalScrollHandles.Event',
    PillListPillClicked = 'PillClicked.Event',
    PillListPillCloseClicked = 'PillXClicked.Event',
    PopupDestroy = 'Application.Destroy.Popup.Event',
}
