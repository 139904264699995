/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppsEntity } from '../../state/apps.models';
import { BaseFieldMapper, FieldMapper } from './base-field-mapper';
import { BaseCommand } from '../../commands/base-command';
import { ThemeService } from '../theme.service';
import { CacheManagerService } from '../cachemanager.service';
import { PersonalizationService } from '../personalization.service';

export class DefaultFieldMapper extends BaseFieldMapper implements FieldMapper {

    private setValesFromEventCmdName = "Event - Set Values From Event";
    private urlParamMap: Set<string>;
    private themeName: string;
    private workflowName: string;

    constructor(appsStore: Record<string, AppsEntity>,
        private commandHelpers: BaseCommand,
        private themeService: ThemeService,
        protected cacheManagerService: CacheManagerService,
        protected personalizationService: PersonalizationService) {
        super(appsStore);
        this.urlParamMap = new Set<string>();
        const variables = appsStore['_variables'];
        this.workflowName = variables.state.workflow.toString();
        this.themeName = variables.state.themeName.toString();
    }

    execute(commandConfig: CommandConfig, mapIndex: number, forceSetValue?: boolean): FieldMap {
        let fieldMap = _.cloneDeep(this.getFieldMap(commandConfig, mapIndex));
        if (commandConfig.action === this.setValesFromEventCmdName && !this.isInitialized(fieldMap.sourceApp)) {
            return fieldMap;
        }
        this.reset();
        this.initAppState(fieldMap.targetApp);
        const actionPath = commandConfig.options.path;
        const isParamsEnabled = this.shouldSetUrlQueryString();
        forceSetValue = _.isBoolean(forceSetValue) ? forceSetValue : false;
        if (isParamsEnabled) fieldMap.urlParams = [];
        fieldMap = this.baseFieldMapper(fieldMap, actionPath, forceSetValue, isParamsEnabled);
        this.setState();
        return fieldMap;
    }

    private shouldSetUrlQueryString(): boolean {
        const disableProperty = this.themeService.getThemeProperty('DisableRedirectWithQueryStrings');
        return _.isNil(disableProperty) || !this.commandHelpers.isTruthy(disableProperty);
    }

    private reset() {
        this.resetState();
        this.urlParamMap.clear();
    }

    private doIndividualFieldMap(fieldMap: FieldMap, fieldIndex: number, forceSetValue: boolean, isParamsEnabled: boolean): any {

        const targetFieldName = fieldMap.targetFields[fieldIndex];
        const { source, target } = this.getFieldMapInfo(fieldMap, fieldIndex);

        // It is ok to initialize target app. Source should not be initialized here.
        this.initAppState(target.appName);

        if (!this.isInitialized(source.appName)) return;

        const valTemp: any = this.getAppFieldValue(source.appName, source.fieldName);
        if (_.isUndefined(valTemp)) return;

        if (_.isObject(valTemp)) {
            for (const field in valTemp) {
                if (field.toLowerCase() !== targetFieldName.toLowerCase()) continue;
                this.updateFieldValue(targetFieldName, source, target, valTemp[field], forceSetValue);
            }
        } else {
            this.updateFieldValue(targetFieldName, source, target, valTemp, forceSetValue);
        }

        if (!isParamsEnabled || _.isNil(this.targetFieldMap[targetFieldName]) || this.urlParamMap.has(targetFieldName)) return;

        let urlParam;
        if (this.isFieldAppReferenced(targetFieldName)) {
            const info = this.getAppReferenced(targetFieldName);
            urlParam = info.appName.toPopUpName() + "." + info.fieldName + "=" + encodeURIComponent(this.targetFieldMap[targetFieldName]);
        } else {
            urlParam = fieldMap.targetApp.toPopUpName() + "." + targetFieldName + "=" + encodeURIComponent(this.targetFieldMap[targetFieldName]);
        }
        fieldMap.urlParams.push(urlParam);
        this.urlParamMap.add(targetFieldName);
    }

    private baseFieldMapper(fieldMap: FieldMap, actionPath: any, forceSetValue: boolean, isParamsEnabled: boolean): FieldMap {

        if (fieldMap.sourceFields.length !== fieldMap.targetFields.length) return fieldMap;

        // Individual field mapping happens for all types of applications, including components
        for (let fieldIndex = 0; fieldIndex < fieldMap.sourceFields.length; fieldIndex++) {
            this.doIndividualFieldMap(fieldMap, fieldIndex, forceSetValue, isParamsEnabled);
        }

        // Save to personalization
        const appState = this.getAppState(fieldMap.targetApp);
        this.personalizationService.setPersonalizationFromContext(fieldMap.targetApp, appState);

        this.clearMapFields(fieldMap.targetApp, fieldMap.targetFieldsToClear, actionPath, true);
        this.runMutuallyExclusiveSettings(fieldMap.targetApp, actionPath, forceSetValue);

        return fieldMap;
    }

    private isObject(obj: any): any {
        return _.isObject(obj);
    }

    private hasValidValue(value: any): boolean {
        return !_.isNil(value) && value !== "";
    }

    private flatApplicationProperties(data: any): any {
        const flatten = {};
        for (const p in data) {
            if (p === "Id" && !this.hasValidValue(data[p])) continue;
            if (this.hasValidValue(data[p]))
                flatten[p] = data[p];
        }
        return flatten;
    }

    private clearMapFields(targetAppName: string, targetFieldsToClear: any[], actionPath: any, forceSetValue: boolean): void {
        if (!_.isArray(targetFieldsToClear) || targetFieldsToClear.length <= 0) return;
        const targetApp = this.getAppState(targetAppName);
        const appFlatObj = this.flatApplicationProperties(targetApp);
        for (let i = 0; i < targetFieldsToClear.length; i++) {
            if (!_.isNil(this.targetFieldMap[targetFieldsToClear[i].FieldName])) continue;
            if (targetFieldsToClear[i].RuleSetCode === "") {
                this.clearField(targetAppName, targetFieldsToClear[i].FieldName, forceSetValue);
            } else if (this.isObject(actionPath) && this.isObject(actionPath.fieldToRuleSet)) {
                for (const field in actionPath.fieldToRuleSet) {
                    const ruleSet = actionPath.fieldToRuleSet[field];
                    for (let j = 0; j < actionPath.ruleSets[ruleSet.rule].length; j++) {
                        const rule = actionPath.ruleSets[ruleSet.rule][j];
                        const result = this.callWindowFunction(rule.functionName, appFlatObj);
                        if (result) {
                            this.clearField(targetAppName, targetFieldsToClear[j].FieldName, forceSetValue);
                            break;
                        }
                    }
                }
            } else {
                const functionName = targetFieldsToClear[i].RuleSetCode;
                const result = this.callWindowFunction(functionName, appFlatObj);
                if (result) {
                    this.clearField(targetAppName, targetFieldsToClear[i].FieldName, forceSetValue);
                }
            }
        }
    }

    private runMutuallyExclusiveSettings(targetAppName: string, actionPath: any, forceSetValue: boolean): void {
        if (_.isEmpty(actionPath)) return;
        const rules = actionPath.MutuallyExclusiveSettings;
        if (_.isEmpty(rules)) return;
        const appFlatObj = this.getAppState(targetAppName);
        for (const field in rules.fieldToRuleSet) {
            const conditionFnName = rules.fieldToRuleSet[field].rule;
            const result = this.callWindowFunction(conditionFnName, appFlatObj);
            if (result) {
                this.clearField(targetAppName, field, forceSetValue);
            }
        }
    }

    private callWindowFunction(fnName: string, context: any): boolean | any {
        return this.commandHelpers.callWindowFunction(this.workflowName, this.themeName, fnName, context);
    }
}
