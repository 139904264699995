/* eslint-disable @typescript-eslint/camelcase */
import { Injectable } from "@angular/core";

@Injectable()
export class GdprService {

    private config = {
        trackingIds: {
            GA_AccountId: null,
            ICA_AccountId: null,
            ICA_SiteId: null,
            FB_TrackId: null,
            LI_PartnerId: null,
            PM_PixelId: null,
            TW_TagCode: null,
            ICA_ContainerId: null,
            GoogleTagManager_ContainerId: null,
            TL_TagManagerInfo: null,
            SiteCatalyst_AccountId: null,
            SiteCatalyst_Env: null,

        },
        trackingCustomFunctions: null,
        consentApp: "",
        _injectionState: null,
        anonymousFallbackGA: false,
        _gaPopstateListener: null,
        _targetElement: null,
        _tagManagerPopstateListener: null
    }

    init() {
        switch (IX_GetCookieValue("IXAnalyticsConsent")) {
            case 'deny':
                if (this.config.anonymousFallbackGA) {
                    this.injectAnonymizedTracking();
                }
                return;
            case 'allow':
                this.injectTracking();
                this.injectTagManagement();
                break;
            default: {
                if (this.config.anonymousFallbackGA) {
                    this.injectAnonymizedTracking();
                }
                let intervalAttempts = 0;
                // Attempt to locate consent banner app every second after document ready for up to 1 minute.
                const consentAppCheck = setInterval(() => {
                    // Either the app was not added to this workflow, the app name was entered incorrectly,
                    // the site took too long to load, or there is an SDK issue
                    if (intervalAttempts > 60) {
                        clearInterval(consentAppCheck);
                        // Clear interval before logging, in case something goes wrong
                        IX_Log("gdpr", "Consent banner application '" + this.config.consentApp + "' not loaded after 1 minute.");
                        return;
                    }

                    if (this.getAppTarget().length) {
                        this.getAppTarget().css("display", "");
                        clearInterval(consentAppCheck);
                        return;
                    }

                    intervalAttempts++;
                }, 1000);
            }
        }
    }

    getAppTarget() {
        return $(document.querySelector("[data-app='" + this.config.consentApp + "']") ||
            document.querySelector("#CP_" + this.config.consentApp.replace(/\./g, '')));
    }

    injectAnonymizedTracking() {
        // Currently only support google analytics anonymization
        if (this.config.trackingIds.GA_AccountId)
            this.googleAnalyticsInjector(this.config.trackingIds.GA_AccountId, true);
    }

    injectTracking() {
        if (this.config.trackingIds.ICA_AccountId && this.config.trackingIds.ICA_SiteId)
            this.icAnalyticsInjector(this.config.trackingIds.ICA_AccountId, this.config.trackingIds.ICA_SiteId);

        if (this.config.trackingIds.GA_AccountId)
            this.googleAnalyticsInjector(this.config.trackingIds.GA_AccountId, false);

        if (this.config.trackingIds.FB_TrackId)
            this.facebookInjector(this.config.trackingIds.FB_TrackId);

        if (this.config.trackingIds.LI_PartnerId)
            this.linkedInInjector(this.config.trackingIds.LI_PartnerId);

        if (this.config.trackingIds.PM_PixelId)
            this.programmaticInjector(this.config.trackingIds.PM_PixelId);

        if (this.config.trackingIds.TW_TagCode)
            this.twitterInjector(this.config.trackingIds.TW_TagCode);

        return;
    }

    injectTagManagement() {
        if (this.config.trackingIds.ICA_AccountId && this.config.trackingIds.ICA_ContainerId) {
            this.icTagManagerInjector(this.config.trackingIds.ICA_AccountId, this.config.trackingIds.ICA_ContainerId);
        }

        if (this.config.trackingIds.GoogleTagManager_ContainerId) {
            this.googleTagManagerInjector(this.config.trackingIds.GoogleTagManager_ContainerId);
        }

        if (this.config.trackingIds.TL_TagManagerInfo) {
            this.tealiumTagManagerInjector(this.config.trackingIds.TL_TagManagerInfo);
        }

        if (this.config.trackingIds.SiteCatalyst_AccountId && this.config.trackingIds.SiteCatalyst_Env) {
            this.siteCatalystInjector(this.config.trackingIds.SiteCatalyst_AccountId,
                this.config.trackingIds.SiteCatalyst_Env);
        }
    }

    hideConsent() {
        if (this.getAppTarget().length)
            this.getAppTarget().css("display", "none");
    }

    reload(accepted) {
        const state = accepted ? 'allow' : 'deny';
        IX_SetCookieValue("IXAnalyticsConsent", state);
        this.hideConsent();
        this.init();
    }

    getIcAnalyticsTrackKey(arg1, arg2, arg3) {
        return Array.prototype.slice.call(arg1, arg2, arg3).join('-');
    }

    icAnalyticsInjector(accountId, siteId) {
        const trackId = this.getIcAnalyticsTrackKey('ICA', accountId, siteId);
        if (this.config._injectionState[trackId])
            return;

        const _paq = window._paq = window._paq || [];
        (function () {
            const u = "https://" + accountId + "/";
            _paq.push(['setTrackerUrl', u + 'matomo.php']);
            _paq.push(['setSiteId', siteId]);
            const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.type = 'text/javascript'; g.async = true; g.src = 'https://cdn.matomo.cloud/' + accountId + '/matomo.js'; s.parentNode.insertBefore(g, s);
        })();

        IX_Log('analytics', 'injected ic analytics');
        this.config._injectionState[trackId] = true;
    }

    trackPageViews(previousUrl, currentUrl, title) {
        if (!_.isEmpty(this.config.trackingIds)) {
            _.each(this.config.trackingIds, function (trackingId, analytics) {
                if (!_.isNull(trackingId)) {
                    //tracking page view for IC analytics
                    if (analytics.startsWith("ICA_")) {
                        const accountId = this.config.trackingIds.ICA_AccountId,
                            siteId = this.config.trackingIds.ICA_SiteId;
                        const trackId = this.getIcAnalyticsTrackKey('ICA', accountId, siteId);

                        if (this.config._injectionState[trackId]) {
                            IX_Log("analytics", 'tracking page change from to with title', previousUrl, currentUrl, title);

                            // Track page view
                            const _paq = window._paq = window._paq || [];
                            if (previousUrl != undefined) {
                                _paq.push(['setReferrerUrl', previousUrl]);
                            }
                            _paq.push(['setCustomUrl', currentUrl]);
                            _paq.push(['setDocumentTitle', title]);
                            _paq.push(['deleteCustomVariables', 'page']);
                            _paq.push(['setGenerationTimeMs', 0]);
                            _paq.push(['trackPageView']);
                        }
                    };
                }
            });
        };
    }

    icTagManagerInjector(accountId, containerId) {
        const trackId = this.getIcAnalyticsTrackKey('ICTM', accountId, containerId);
        if (this.config._injectionState[trackId])
            return;

        const _mtm = window._mtm = window._mtm || [];
        _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
        const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript'; g.async = true; g.src = 'https://cdn.matomo.cloud/' + accountId + '/container_' + containerId + '.js'; s.parentNode.insertBefore(g, s);

        IX_Log('analytics', 'injected ic tag manager');
        this.config._injectionState[trackId] = true;
    }

    googleAnalyticsInjector(trackId, anonymize) {
        if (this.config._injectionState[trackId])
            return;

        anonymize = anonymize || false;
        //window['ga-disable-'+trackId]=(function(a){a=document.cookie.match('(^|[^;]+)\\s*IXAnalyticsConsent\\s*=\\s*([^;]+)');return a?!(a.pop()!='deny'):true;})();
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * (new Date() as any); a = s.createElement(o),
                m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
        })(window, document, 'script', 'https://www.googletagmanager.com/gtag/js?id=' + trackId, 'ga');
        window.dataLayer = window.dataLayer || [];
        const gtag = function (arg1, arg2) { window.dataLayer.push(arg1, arg2); }
        gtag('js', new Date());

        this.postInjectGA(gtag, trackId, anonymize);

        this.config._injectionState[trackId] = true;
    }

    postInjectGA(gtag, trackId, anonymize) {
        gtag('config', trackId, { 'anonymize_ip': anonymize });

        // If post-injection has run before, remove the old event listener
        // (when switching from consent state unset/deny to allow)
        if (this.config._gaPopstateListener) {
            window.removeEventListener('popstate', this.config._gaPopstateListener);
            delete this.config._gaPopstateListener;
        }

        this.config._gaPopstateListener = window.addEventListener('popstate', function (e) {
            // TODO
            // gtag('config', trackId, {
            //     'page_path': "/" + e.target.location.href.replace(/.*\?s=/gi,''),
            //     'anonymize_ip': anonymize
            // });
        });
    }

    googleTagManagerInjector(trackId) {
        if (this.config._injectionState[trackId])
            return;
        window.dataLayer = window.dataLayer || [];
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            }); const f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j['async'] = true; j['src'] =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', trackId);
        this.tagManagerOptionalInjector();
        this.config._injectionState[trackId] = true;
    }

    tealiumTagManagerInjector(trackId) {
        if (this.config._injectionState[trackId])
            return;

        //to check if all of the three values needed for tealium tag management injection is provided
        //1. client name 2. profile name 3. environment
        const tealiumIqParams = trackId.split("|");
        if (tealiumIqParams.length != 3) {
            console.log(Error("Not all the necessary parameter for tealium integration is provided"));
            return;
        }

        window.utag_data = window.utag_data || {};
        //need this for our applications since they are SPA
        window.utag_data.site_type = "single_page";

        const tealiumIqParamsString = tealiumIqParams[0] + '/' + tealiumIqParams[1] + '/' + tealiumIqParams[2] + '/';
        (function (a, b, c, d) {
            a = 'https://tags.tiqcdn.com/utag/' + tealiumIqParamsString + 'utag.js';
            b = document; c = 'script'; d = b.createElement(c); d.src = a; d.type = 'text/java' + c; d.async = true;
            a = b.getElementsByTagName(c)[0]; a.parentNode.insertBefore(d, a);
        }
        )();

        //to insert the data layer info for tealium
        //before the utag.js file is laoded
        const utagScriptElmForTealium = _.find($("script"), function (a) {
            if (a.attributes["src"]) {
                return a.attributes["src"].value == "https://tags.tiqcdn.com/utag/" + tealiumIqParamsString + "utag.js"
            };
        });

        //tealium requires both synchronous and sync files to be loaded for optimal performance
        //injecting sync file before the utag.js

        const utagSyncTagForTealium = document.createElement("script");
        utagSyncTagForTealium.src = 'https://tags.tiqcdn.com/utag/' + tealiumIqParamsString + 'utag.sync.js';
        utagSyncTagForTealium.type = 'text/javascript';

        utagScriptElmForTealium.parentNode.insertBefore(utagSyncTagForTealium, utagScriptElmForTealium);

        this.tagManagerOptionalInjector();

        this.config._injectionState[trackId] = true;
    }

    tagManagerOptionalInjector() {
        const that = this;
        // We can arrive here more than once; we only want this
        // event listener set *once* or we'll get multiple
        // dataLayer.push() calls.
        const alreadySetFlagAttr = 'ic-global-listener';

        if ($(document).attr(alreadySetFlagAttr) === 'true') {
            return;
        }

        const eventPush = _.find(window.IX_Theme.properties, function (property) {
            return property.PropertyName === 'GoogleTagManagerEventPush' ||
                property.PropertyName === 'TagManagersEventPush';
        });

        if (!eventPush || !eventPush.Value1) {
            return;
        }

        // *ThemeProperty.Value1* contains the name of the attribute which will
        // decorate a click-target that gets a dataLayer.push() call.
        const clickTargetAttr = eventPush.Value1;
        const config = this.config;

        // *ThemeProperty.Value2* contains an optional list of key-value pairs
        // specifying additional attributes that should be on the element or its
        // ancestors... or (if a value also is given) attributes that should be
        // *added to* the object in the dataLayer.push() call.
        const pushObj = {};
        if (eventPush.Value2) {
            const keyValuePairs = eventPush.Value2.split('|');
            keyValuePairs.forEach(function (kvp) {
                const pair = kvp.split(':');
                const key = pair[0];
                const value = pair[1] !== '' ? pair[1] : undefined;
                pushObj[key] = value;
            });
        }

        document.addEventListener('click', globalListener);
        document.addEventListener('keydown', globalListener);
        $(document).attr(alreadySetFlagAttr, 'true');

        const pushToGoogleTagManager = function (eventData) {
            window.dataLayer.push(eventData);
        };

        function globalListener(event) {
            if (event.type === 'keydown' && event.key !== 'Enter' && event.keyCode !== 13) {
                return;
            }

            const target = $(event.target).closest('[' + clickTargetAttr + ']');
            if (!target || !target.length) {
                return;
            }

            const eventData = {};
            eventData[clickTargetAttr] = target.attr(clickTargetAttr);

            Object.keys(pushObj).forEach(function (key) {
                if (typeof pushObj[key] === 'undefined') {
                    eventData[key] = target.closest('[' + key + ']').attr(key);
                } else {
                    eventData[key] = pushObj[key];
                }
            });

            if (config.trackingIds.GoogleTagManager_ContainerId) {
                pushToGoogleTagManager(eventData);
            }

            if (config.trackingIds.SiteCatalyst_AccountId) {
                that.pushToSiteCatalyst(eventData);
            }

            if (config.trackingIds.TL_TagManagerInfo) {
                that.pushToTealiumIqTagManager(eventData, "Event");
            }
        }
    }

    parseEventDataAndStoreToDataLayer(eventData, dataKey) {
        // Parse eventData and store in dataLayer
        Object.keys(eventData).forEach((key) => {
            const newKey = key.replace(/data-(\w+)-?(\w?)(\w+)/, (_, $1, $2, $3) => {
                // Converts 'data-foo-bar' into 'fooBar'
                return $1 + $2.toUpperCase() + $3;
            });

            window[dataKey][newKey] = eventData[key];
        });

        //add code to trigger custom event tracking call if present
        // if(custom function name present on theme details) {
        //     get that function name from theme details(window[dataKey]);
        // }
    }

    pushToTealiumIqTagManager(data, trackingCondition) {
        const dataKey = "utag_data";
        const config = this.config;
        this.parseEventDataAndStoreToDataLayer(data, dataKey);

        if (!_.isEmpty(config.trackingCustomFunctions)) {
            _.each(config.trackingCustomFunctions, function (functionName, evt) {
                if (evt == trackingCondition) {
                    //since usually the custom functions may be properties of other global objects
                    //need to execute those after parsing
                    if (functionName.indexOf(".") > -1) {
                        const parts = functionName.split(".");

                        if (parts.length <= 1)
                            throw new Error("Tealium function name is invalid, function names must contain a dot");

                        const method = parts[1];
                        if (window.TMS) {
                            window.TMS[method](data);
                        };
                    }

                }
            });
        }
        else {
            if (trackingCondition == "Event") {
                window.utag.link(window.utag_data);
            }
            else {
                window.utag.view(window.utag_data);
            }
        }
    }

    pushToSiteCatalyst(eventData) {
        const dataKey = this.config.trackingIds.SiteCatalyst_AccountId + "Data";
        // Clear out previous data
        window[dataKey] = {};

        this.parseEventDataAndStoreToDataLayer(eventData, dataKey);

        if (window[dataKey].pageInter) {
            window.Bootstrapper.ensEvent.trigger("InvestCloudUserInteraction");
        } else {
            window.Bootstrapper.ensEvent.trigger("apacPageView");
        }
    }

    facebookInjector(trackId) {
        if (this.config._injectionState[trackId])
            return;
        (function (f, b, e, v, n, t, s) {
            if (f['fbq']) return; n = f['fbq'] = window.fbq = function (arg1, arg2) {
                n.callMethod ? n.callMethod.apply(n, arguments) :
                    n.queue.push(arguments);
            }; if (!f['_fbq']) f['_fbq'] = n; n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = [];
            t = b.createElement(e); t.async = !0; t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        window.fbq('init', trackId); window.fbq('track', 'PageView');
        this.config._injectionState[trackId] = true;
    }

    programmaticInjector(trackId) {
        if (this.config._injectionState[trackId])
            return;
        const programmatic = document.createElement("img");
        programmatic.width = 1; programmatic.height = 1;
        programmatic.alt = "";
        programmatic.setAttribute("aria-hidden", "true");
        programmatic.setAttribute("src", "https://one.progmxs.com/seg?add=" + trackId + "&t=2");
        document.body.appendChild(programmatic);
        this.config._injectionState[trackId] = true;
    }

    linkedInInjector(trackId) {
        if (this.config._injectionState[trackId])
            return;
        const _linkedin_partner_id = trackId;
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);

        (function () {
            const s = document.getElementsByTagName("script")[0]; const b = document.createElement("script");
            b.type = "text/javascript"; b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
        })();
        this.config._injectionState[trackId] = true;
    }

    twitterInjector(trackId): any {
        if (this.config._injectionState[trackId])
            return;
        (function (e, t, n, s, u, a) {
            e['twq'] || (s = e['twq'] = window.twq = function (arg1, arg2) {
                s.exe ? s.exe.apply(s, arguments) : s.queue.push(arg1, arg2);
            }, s.version = '1.1', s.queue = [], u = t.createElement(n), u.async = !0, u.src = '//static.ads-twitter.com/uwt.js',
                a = t.getElementsByTagName(n)[0], a.parentNode.insertBefore(u, a));
        })(window, document, 'script');
        // Insert Twitter Pixel ID and Standard Event data below
        window.twq('init', trackId);
        window.twq('track', 'PageView');
        this.config._injectionState[trackId] = true;
    }

    siteCatalystInjector(accId, env) {
        if (this.config._injectionState[env])
            return;

        const s = "script";

        const f = document.getElementsByTagName(s)[0];
        const j = document.createElement(s);
        j['src'] = "https://nexus.ensighten.com/" + accId + "/" + env + "/Bootstrap.js";

        f.parentNode.insertBefore(j, f);

        this.tagManagerOptionalInjector();

        this.config._injectionState[env] = true;
    }
}
