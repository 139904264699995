import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as CommandActions from '../state/command.actions';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from './command-list-collection.service';
import { ApplicationInformation } from '../services/application-information.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class ClearLocalDataSourceCommand extends BaseEffectCommand {

    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation) {
        super();
    }

    effectCommandName$ = createEffect(
        () => this.actions$.pipe(
            ofType(CommandActions.clearLocalDataSourceCommand),
            switchMap((action) => {
                const commandConfig = this.getCommandConfig(action);
                const appName = _.first(commandConfig.parameters);
                const appInfo = this.applicationInformation.getAppInfo(appName);
                if (appInfo?.hasServer) {
                    const keyServerParameters = appInfo.server + '_' + appInfo.serverParameters;
                    const keyAppServerParameters = (appName + '-' + keyServerParameters).toLowerCase();
                    const removeCachePolicy = {
                        canBeDeleted: (keyId) => {
                            const key = keyId.toLowerCase();
                            return key.startsWith(keyServerParameters) || key.startsWith(keyAppServerParameters);
                        }
                    };
                    this.cacheManagerService.delete(appName, removeCachePolicy);
                }
                return this.getNextActions(commandConfig);
            })
        )
    );

}
