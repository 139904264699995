import { Injectable } from "@angular/core";
import { InputApplication } from "../components/input-application";
import { ListApplication } from "../components/list-application";
import { ApplicationRefFacade } from "./application-ref.facade";
import UtilityFunctions from "../utility.functions";
import Sugar from 'sugar';
import numeral from 'numeral';

@Injectable()
export class DomComponentRetrievalService {

    constructor(private applicationRefFacade: ApplicationRefFacade) {
        UtilityFunctions.bindTestingFunctionAPI('getAppComponent', this.getAppComponent, this);
        UtilityFunctions.bindGlobalLibrariesAPI('sugar', Sugar);
        UtilityFunctions.bindGlobalLibrariesAPI('numeral', numeral);
     }

    getAppComponentSelector(appName: string): string {
        return "[data-applet='" + appName + "']"
    }

    getAppComponent(appName: string): InputApplication | ListApplication {
        return this.applicationRefFacade.getAppComponent(appName);
    }

    getAppClassName(appName: string): string {
        return _.isNil(appName) || appName.isBlank() ? null : (appName.replace(/\./g, '_') + "Ctrl");
    }

    getAppSelector(applet: any, targetApp: string): any {
        const appName = applet?.name === targetApp ? applet.name : targetApp;
        return this.getAppComponentSelector(appName);
    }

    getDxComponentInstance(applet: any, targetApp: string): any {
        //'[dx-data-grid],[dx-tree-list],[dx-list]'; //  TODO: filter based on these types
        const gridName = applet?.name === targetApp ? applet.name : targetApp;
        return this.getListDxDataGrid(gridName);
    }

    getListDxDataGrid(appName: string): any {
        return this.applicationRefFacade.getListDxDataGrid(appName);
    }

}
