/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxButton = require('devextreme/ui/button');

var _dxButton = DevExpress.ui.dxButton.redefine({
    _shouldAlwaysKeepTabindex: function () {
        var IC_TABSTOP_WHEN_DISABLED_CLASS = "ic-keep-tabstop-when-disabled";
        var _this$option = this.option();
        var keepTabstopWhenDisabled = _this$option.keepTabstopWhenDisabled;
        return keepTabstopWhenDisabled || this.$element().hasClass(IC_TABSTOP_WHEN_DISABLED_CLASS);
    },
    _isFocusable: function () {
        var _this$option = this.option();
        var focusStateEnabled = _this$option.focusStateEnabled;
        var disabled = _this$option.disabled;
        if (this._shouldAlwaysKeepTabindex()) return focusStateEnabled;
        return focusStateEnabled && !disabled
    }
});

DevExpress.registerComponent("dxButton", _dxButton);
