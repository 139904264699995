import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppsEntity } from '../state/apps.models';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommandListCollectionService } from "./command-list-collection.service";
import { BaseEffectCommand } from './base-effect.command';
import { EcdGroupService } from '../services/clientservices/ecdgroup.service';
import { ApplicationInformation } from '../services/application-information.service';
import { DefaultFieldMapper } from '../services/field-mappers/default-field.mapper';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { ApiClientService } from '../services/api-client.service';
import { UtilService } from '../services/util.service';
import { AppsConstants } from '../state/apps.constants';
import { CacheManagerService } from '../services/cachemanager.service';
import { DefaultFieldMapperFactory } from '../services/field-mappers/field-mapper.factory';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';

@Injectable()
export class SetValuesCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private ecdGroupService: EcdGroupService,
        private applicationInformation: ApplicationInformation,
        private dynamicReplacementService: DynamicReplacementService,
        private apiClientService: ApiClientService,
        private utilService: UtilService,
        private defaultFieldMapperFactory: DefaultFieldMapperFactory,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommandActions.setValuesCommand),
                switchMap((action) => {
                    const commandConfig = this.getCommandConfig(action);
                    const mapIndex = this.getFieldMapIndex(commandConfig);
                    const fieldMap = this.getFieldMap(commandConfig, mapIndex);
                    if (this.dynamicReplacementService.hasReplacementValue(fieldMap.targetApp)) {
                        const options = {
                            rid: commandConfig.rid,
                            appName: commandConfig.appName,
                        };
                        const promise = this.dynamicReplacementService.getDynamicValue(fieldMap.targetApp, options);
                        return this.waitAndContinueNextActions(promise, commandConfig);
                    }
                    let setValuesAction;
                    if (fieldMap.directive === AppsConstants.triggerDirective.none) {
                        setValuesAction = CommandActions.clientSetValuesCommand({ commandConfig });
                    } else {
                        setValuesAction = CommandActions.serverSetValuesCommand({ commandConfig });
                    }
                    return of(setValuesAction);
                })
            )
    );

    serverSetValues$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.serverSetValuesCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.serverSetValuesCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);
                const fieldMapper = this.createDefaultFieldMapper(appsState);
                const mapIndex = this.getFieldMapIndex(commandConfig);
                const fieldMap = fieldMapper.getFieldMap(commandConfig, mapIndex);
                const forceSetValue = false;
                // Step 1: perform field mapping
                fieldMapper.execute(commandConfig, null, forceSetValue);
                // Step 2: perform server request based on trigger directives
                const promise = this.setValuesServerRequest(appsStore, fieldMapper, commandConfig, mapIndex);
                return from(promise).pipe(
                    switchMap(() => {
                        const commandState = Object.values(fieldMapper.State);
                        const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                        return this.appendNextActions(thisCommandActions, commandConfig, fieldMap);
                    })
                );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    setValues$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.clientSetValuesCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.clientSetValuesCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);
                this.setNewStateWithDefaultValues(commandConfig.appName, appsState);
                const forceSetValue = false;
                const mapIndex = this.getFieldMapIndex(commandConfig);
                const fieldMapper = this.createDefaultFieldMapper(appsState);
                const fieldMap = fieldMapper.execute(commandConfig, mapIndex, forceSetValue);
                const commandState = Object.values(fieldMapper.State);
                const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                return this.appendNextActions(thisCommandActions, commandConfig, fieldMap);
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    private createDefaultFieldMapper(appsState: Record<string, AppsEntity>): DefaultFieldMapper {
        return this.defaultFieldMapperFactory.create(appsState, this);
    }

    private getFieldMapIndex(commandConfig: CommandConfig) {
        if (_.isNil(commandConfig.prevCmd) || !_.isNumber(commandConfig.fieldMap)) return commandConfig.options.i;
        return "Redirect-conditionalRedirect".EqualsIgnoreCase(commandConfig.prevCmd) ? commandConfig.fieldMap : commandConfig.options.i;
    }

    private setValuesServerRequest(appsStore, fieldMapper: DefaultFieldMapper,
        commandConfig: CommandConfig, mapIndex: number | undefined): Promise<FieldMap> {
        const appMap = {};
        const fieldMap = fieldMapper.execute(commandConfig, mapIndex);
        const requests = this.getApplicationsRequest(appsStore, fieldMap, commandConfig, mapIndex, appMap);
        return this.ecdGroupService.request(requests)
            .then((data) => {
                fieldMapper.updateWithGroupResponse(data, appMap);
                return fieldMap;
            })
            .catch((error) => {
                IX_Log('fieldMapper', 'Get Trigger Directive Calls fail', error);
                throw new Error('Get Trigger Directive Calls fail');
            });
    }

    private getApplicationsRequest(appsStore, fieldMap: FieldMap, commandConfig: CommandConfig,
        mapIndex: number | undefined, appMap: Record<string, any>): any {
        const command = AppsConstants.triggerDirective[fieldMap.directive];
        let path = commandConfig.options.path;
        const apps = this.utilService.getTriggerDirectiveApplications(fieldMap);
        if (this.applicationInformation.isInputApp(fieldMap.targetApp)
            && !this.applicationInformation.isHolderApp(fieldMap.targetApp)) {
            apps.push(fieldMap.targetApp);
        }
        path = path.Path + "Derived:true;";
        if (_.isNumber(mapIndex) && mapIndex >= 0) {
            path = path + "MapIndex:" + mapIndex + ";";
        }
        const requests = { Requesters: [] };
        apps.forEach((appName) => {
            // TODO: get state from command
            const appContextMap = this.getContextAppsForRequest(appsStore, appName, commandConfig.appName);
            const request = this.apiClientService.createECDHeader(appName, commandConfig.appName, command, path);
            const key = this.utilService.getCacheKeyForECDG(request);

            appMap[request.ApplicationName] = key;
            this.cacheManagerService.delete(key);
            this.cacheManagerService.setPromise(key);

            request.Data = {};
            const ecdContext = {};
            this.apiClientService.setRequestData(appContextMap[appName], request.Data);
            this.apiClientService.setECDContext(ecdContext, appContextMap);
            this.apiClientService.setEcdRequestContext(request, ecdContext);
            request.Data = JSON.stringify(request.Data);
            request.Context = JSON.stringify(request.Context);

            requests.Requesters.push(request);
        });
        return requests;
    }

    private getContextAppsForRequest(appsStore, appId, parentAppId, additionalApps?) {
        const ctxApps = {};

        ctxApps[appId] = this.getAppState(appId, appsStore);

        if (appId !== parentAppId) {
            const parentAppIdWithoutRowIndex = this.normalizeAppNameRemovingMasterDetailRowIndex(parentAppId);
            ctxApps[parentAppIdWithoutRowIndex] = this.getAppState(parentAppId, appsStore);
        }
        if (Array.isArray(additionalApps)) {
            for (let i = 0; i < additionalApps.length; i++) {
                if (ctxApps[additionalApps[i]] != null) continue;

                ctxApps[additionalApps[i]] = this.getAppState(additionalApps[i], appsStore);
            }
        }
        if (_.isPlainObject(additionalApps)) {
            for (const app in additionalApps) {
                if (ctxApps[app] != null) continue;
                ctxApps[app] = this.getAppState(app, appsStore);
            }
        }

        // const componentData = _getComponentData(appId);
        // if (componentData) {
        //     _.extend(ctxApps[appId], componentData);
        // }

        return ctxApps;
    }

    private normalizeAppNameRemovingMasterDetailRowIndex(appName) {
        return appName.split("[")[0];
    }

    private customizer(objValue, srcValue) {
        if (typeof objValue === 'number') {
            return objValue;
        }

        return (_.isNil(objValue) || _.isEmpty(objValue)) ? srcValue : objValue;
    }

    private setNewStateWithDefaultValues(appName: string, appsState: Record<string, AppsEntity>): void {
        if (_.isEmpty(appsState[appName]?.state)) return;
        const appComponent = this.domComponentRetrievalService.getAppComponent(appName);
        if (!appComponent) return;
        // TODO : We should not be relying on model as a source of truth, do we need to persist default values in the store?
        const modelState = appComponent.getStateFromModel();
        if (_.isEmpty(modelState)) return;
        // If we have a value, even if null, do not override from model
        Object.keys(appsState[appName].state).forEach(key => delete modelState[key]);
        const newModelState = _.omitBy(modelState, x => !x);
        if (_.isEmpty(newModelState)) return;
        _.extendWith(appsState[appName].state, newModelState, this.customizer);
    }
}
