import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ApplicationInformation } from '../../services/application-information.service';
import { AppTemplateConfig } from '../app-template/app-template.component';
import { ListDataEntity } from '../../state/apps.models';

// Don't change these strings; they come in from a p-tier component configuration.
export enum RepeatCriteria {
  Numerical = 'numerical',
  RowData = 'rowData',
}

type RepeaterV4Config = {
  /**
   * The conditions and the apps to be used as template when those conditions are met
   */
  conditionalTemplateApps: string;

  /**
   * Enable carousel-style pagination
   */
  enableCarousel: boolean;

  /**
   * Enable carousel looping
   */
  loopCarousel: boolean;

  /**
   * "The condition to repeat the template app under.
   * (possible values: numerical, rowData)"
   */
  repeatCriteria: RepeatCriteria;

  /**
   * "How many times to repeat the template app
   * (Only used when criteria is 'numerical')"
   */
  repetitionCount: string;

  /**
   * The name of the application to use as a display template for this component
   */
  templateAppName: string;

  /**
   * Unused, consider removing from generation.
   *
   * In the angularjs version of this component,
   * true prevented the default load/reload of
   * the datasource.
   *
   * In Angular, that may no longer be a valid
   * concern of this component. Perhaps this needs
   * to be lifted and handled at the list-app level.
   */
  _dataDisplayBehaviorOnEventOnly: boolean;
};

type ConditionalTemplate = {
  applicationName?: string;
  condition?: string;
};

@Component({
  selector: 'ic-repeaterv4',
  templateUrl: './repeaterv4.component.html',
  styleUrls: ['./repeaterv4.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Repeaterv4Component implements OnInit {
  @Input() config: RepeaterV4Config;
  @Input() context: Record<string, unknown>;
  @Input() applet: Applet;
  @Input() data: ListDataEntity;
  @Input() model: Record<string, { value: string }>;

  // Unused
  @Input() checkSize: Function;
  // Unused
  @Input() componentConfig: StaticComponentConfig;
  // Unused
  @Input() componentProperties: unknown;

  @Output() updateStaticComponent: EventEmitter<StaticComponentStateChange> = new EventEmitter();
  @Output() staticComponentItemClick: EventEmitter<StaticComponentItemClick> = new EventEmitter();

  public rawData: AppState[];
  public RepeatCriteria = RepeatCriteria;
  public repetitionCount: number;
  public appTemplateConfig: AppTemplateConfig;
  public currentPage: number;

  private templateAppInfo: AppInfo;

  //TODO: Discover the need for templateApplets array... or remove it.
  // This looks like support for a getTemplates binding that existed in
  // angularJS but was never called.
  // templateApplets: CanvasApplet[];
  // private templateApplets: CanvasApplet[];

  private conditionalTemplates: ConditionalTemplate[];
  private itemsPerPage: number;
  private repeatItemWidth: number;

  constructor(
    private element: ElementRef,
    private applicationInformation: ApplicationInformation,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.data.currentValue as ListDataEntity).data.length) {
      this.onDataUpdated((changes.data.currentValue as ListDataEntity).data);
    }
  }

  ngOnInit(): void {
    if (this.config.enableCarousel) {
      this.initPager();
    }

    if (this.config.repeatCriteria === RepeatCriteria.Numerical) {
      this.repetitionCount = parseInt(this.config.repetitionCount);
    }

    // this.templateApplets = [];
  }

  public getConfigWithConditionalTemplate(
    item: AppState
  ): AppTemplateConfig {
    const config = this.getAppTemplateConfig();
    if (this.conditionalTemplates.length > 0) {
      config.templateAppName = this.checkCondition.call(item);
      config.templateAppInfo = this.applicationInformation.getAppInfo(
        config.templateAppName
      );
    }
    return config;
  }

  // Used for tracking the state of an item. When data changes the hash code will too,
  // allowing us to effectively only apply the new state on 'dirty' items.
  //TODO: Evaluate if this still has value outside of angularjs
  public getItemHash(_index: number, item: AppState): number {
    return JSON.stringify(item).IXhashCode();
  }

  public pageNext(): void {
    if (this.currentPage < this.rawData.length - 2) {
      this.currentPage += 1;
      $(this.element)
        .find('.rpt-carousel')
        .css({ transform: 'translateX(-' + this.calculateOffset() + 'px)' });
    } else if (this.config.loopCarousel) {
      this.pageTo(0); // jump to first page
    }
  }

  public pageTo(index: number): void {
    if (index >= 0 && index <= this.rawData.length - 2) {
      this.currentPage = index;
      $(this.element)
        .find('.rpt-carousel')
        .css({ transform: 'translateX(-' + this.calculateOffset() + 'px)' });
    }
  }

  public pagePrev(): void {
    if (this.currentPage >= 1) {
      this.currentPage -= 1;
      $(this.element)
        .find('.rpt-carousel')
        .css({ transform: 'translateX(-' + this.calculateOffset() + 'px)' });
    } else if (this.config.loopCarousel) {
      this.pageTo(this.rawData.length - 2); // jump to last page
    }
  }

  private getAppTemplateConfig(): AppTemplateConfig {
    return {
      appName: this.applet.name,
      templateApp: this.config.templateAppName,
      templateAppInfo: this.templateAppInfo,
      // templateApplets: this.templateApplets,
      templateAppName: this.config.templateAppName,
    };
  }

  private onDataUpdated(newData: AppState[]): void {

    this.rawData = newData;

    if (this.config.conditionalTemplateApps) {
      //to parse the conditions and template apps json
      const conditionInfoArr = this.config.conditionalTemplateApps
        .replaceAll('\\"', '"')
        .replace(/\s/g, '')
        .split(/\[|\]|\{|\}|","ApplicationName":"|"Condition":"|"}|,/g)
        .filter((ele) => ele.length);
      this.conditionalTemplates = [];
      let template: ConditionalTemplate;
      conditionInfoArr.forEach((item: string, index: number) => {
        const isCondition = index % 2 == 0;
        if (isCondition) {
          template = {
            condition: item.replaceAll('this', 'row'),
          };
        } else {
          template.applicationName = item;
          this.conditionalTemplates.push(template);
        }
      });
    } else {
      this.templateAppInfo = this.applicationInformation.getAppInfo(
        this.config.templateAppName
      );
    }

    this.mapDefaults();

    if (this.config.enableCarousel) {
      this.initPager();
    }

    if (this.checkSize) {
      this.checkSize({
        adjustWidth: true,
        adjustHeight: true,
      });
    }

    this.appTemplateConfig = this.getAppTemplateConfig();
  }

  private calculateCarouselBounds(): void {
    this.repeatItemWidth = $(this.element)
      .find('.ic-repeater-item')
      .first()
      .outerWidth(true);
    this.itemsPerPage =
      $(this.element).find('.rpt-carousel-viewport').width() /
      this.repeatItemWidth;
  }

  private calculateOffset(): number {
    this.calculateCarouselBounds();
    let targetOffset = this.currentPage * this.repeatItemWidth;
    const maxOffset =
      $(this.element).find('.rpt-carousel')[0].scrollWidth -
      this.itemsPerPage * this.repeatItemWidth;
    targetOffset = Math.min(targetOffset, maxOffset);

    return targetOffset;
  }

  // Fill missing data based on default values
  private mapDefaults(): void {
    this.rawData.forEach((row, ri) => {
      Object.keys(row).forEach(key => {
        if (row[key] == undefined && this.model) {
          let modelVal = this.model[key].value;
          // If defaults are pipe-separated, split and map individually
          if (modelVal.includes('|')) modelVal = modelVal.split('|')[ri];

          row[key] = modelVal;
        }
      });
    });
  }

  private initPager(): void {
    this.calculateCarouselBounds();
    this.currentPage = 0;
  }

  private checkCondition(): string {
    let template = '';
    this.conditionalTemplates.forEach(function (cond) {
      if (!template) {
        const result = eval(cond.condition);
        if (result) {
          template = cond.applicationName;
        }
      }
    });
    return template;
  }
}
