import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppsEntity } from '../state/apps.models';
import { AppEvent } from '../state/app-events.enum';
import * as AppsActions from '../state/apps.actions';
import * as AppsSelectors from '../state/apps.selectors';
import * as StateActions from '../state/apps.tokens';
import { EventsService } from './events.service';

@Injectable()
export class AppsConstantsFacade {

    get events$(): BehaviorSubject<AppsEntity> {
        return this.eventsService.events$;
    }

    get replayableEvents$(): Observable<AppsEntity> {
        return this.eventsService.replayableEvents$;
    }

    get configuration$(): BehaviorSubject<string> {
        return this.eventsService.configuration$;
    }

    step$ = this.store.pipe(select(AppsSelectors.getCurrentStep));
    workflow$ = this.store.pipe(select(AppsSelectors.getCurrentWorkflow));
    breakPoint$ = this.store.pipe(select(AppsSelectors.getCurrentBreakPoint));
    contextName$ = this.store.pipe(select(AppsSelectors.getCurrentContextName));

    constructor(private store: Store, private eventsService: EventsService) {
        window.IX_publishOnStaticLinksEvent = (e: any) => {
            if (_.isNil(e?.target)) return;
            const eventName = AppEvent.StaticLinks;
            const eventData = {
                type: "externalWebview",
                destination: e.target?.href || ''
            };
            this.publishAppEvent(eventName, eventData);
        }
    }

    publishEvent(event: AppsEntity): void {
        this.eventsService.publishEvent(event);
    }

    publishAppEvent(appName: string, appState: AppState): void {
        const event = {
            id: appName,
            state: appState
        };
        this.updateAppState(event.id, event.state);
        this.publishEvent(event);
    }

    updateAppState(appName: string, appState: AppState): void {
        this.dispatchStateAction(StateActions.UPDATE_STATE, appName, { appState });
    }

    reloadConfiguration(action: string): void {
        this.eventsService.reloadConfiguration(action);
    }

    setBreakPoint(breakPoint: string): void {
        this.store.dispatch(AppsActions.setBreakPoint({ breakPoint }));
    }

    redirectUserForLogout(extParam?: string): void {
        this.store.dispatch(AppsActions.redirectUserForLogout({ extParam }));
    }

    updateListKeyboardFocus(callback: () => void): void {
        this.store.dispatch(AppsActions.updateListKeyboardFocus({ callback }));
    }

    pillListPillClicked(id: string|number): void {
      this.store.dispatch(AppsActions.pillListPillClicked({ id }));
    }

    pillListPillCloseClicked(id: string|number): void {
      this.store.dispatch(AppsActions.pillListPillCloseClicked({ id }));
    }

    resetReplayableEvents(): void {
        this.eventsService.clearReplayableEventsBuffer();
    }

    dispatchStateAction(actionType: string, appName: string, payload: any) {
        const action = {
            type: `[${appName}] ${actionType}`,
            appName,
            ...payload
        };
        this.store.dispatch(action);
    }
}
