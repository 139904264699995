import { DxComponent } from "devextreme-angular";
import { dxElement } from "devextreme/core/element";
import dxOverlay from "devextreme/ui/overlay";
import { AsyncRule, CompareRule, CustomRule, EmailRule, NumericRule, PatternRule, RangeRule, RequiredRule, StringLengthRule } from "devextreme/ui/validation_rules";
import { dxValidatorOptions } from "devextreme/ui/validator";
import { BaseApplication } from "../../components/base-application";
import { ThemeService } from "../theme.service";
import { ValidationEngineService } from "../validation-engine.service";
import { DevExtremeAdapter } from "../validation-engine/devextreme-adapter";

const VALIDATOR_CLASS = "ic-validator";
const VALIDATION_TARGET = "dx-validation-target";

type dxValidatorRule = RequiredRule | NumericRule | RangeRule | StringLengthRule | CustomRule | CompareRule | PatternRule | EmailRule | AsyncRule;

export type IcValidationRule = dxValidatorRule & {
    enabled?: boolean,
    fieldName?: string,
    getEnableState?: () => boolean,
};

export type IcComponent = {
    NAME: string,
    _$element: any & { outerWidth: () => number } & { _$warningMessage?: dxOverlay & { _$element: dxElement } },
    _$warningMessage?: DxComponent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _options?: Record<string, any>,
    instance: () => { on: Function },
    focus: () => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    option: (option?: string | Record<string, any>, value?: any) => any,
    reset: () => void,
    element: () => HTMLElement,
    $element: () => any,
    multiRowSelectValidationGroupName?: string,
};

export type IcComponentEditor = {
    component: IcComponent,
    element: HTMLElement,
};

export type IcValidatorConfig = {
    adapter: DevExtremeAdapter,
    validate: Function,
    resetAndGetFieldMap: (maintainValidValues: boolean) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validateFVAOrApplyResult: (isValid: boolean, fvaEnabled: boolean, fvaFunction: Function, applyResultFunc: Function) => void,
    validationRules: dxValidatorRule[],
};

export class IcValidator {

    //TODO: This class is trying to **extend** dxValidator, judging from concrete method calls on this instance.
    // However, that doesn't quite work; investigation needed. Meanwhile, declaring (but not defining) "option".
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    public option: { (): any; (optionName: string): any; (optionName: string, optionValue: any): void; (options: any): void; };

    public adapter: DevExtremeAdapter = null;
    public validationRules: IcValidationRule[];
    private groupWasInit = false;
    private validationGroup = void (0);
    private config: IcValidatorConfig;
    protected themeService: ThemeService;

    constructor(private validationEngineService: ValidationEngineService,
        private editor: IcComponentEditor,
        private settings: dxValidatorOptions) {

        this.config = {
            adapter: this.adapter,
            resetAndGetFieldMap: this.resetAndGetFieldMap.bind(this),
            validate: this.validate.bind(this),
            validationRules: this.settings.validationRules,
            validateFVAOrApplyResult: this.validateFVAOrApplyResult.bind(this),
        };

        this.validationRules = this.settings.validationRules;

        this.init();
    }

    applyValidationResult(result, adapter) {
        result.validator = this.config;
        adapter.applyValidationResults && adapter.applyValidationResults(result);
    }

    validate(s: BaseApplication) {
        let result,
            promise;
        const adapter = this.adapter,
            name = this.editor.component.NAME,
            bypass = adapter.bypass && adapter.bypass(),
            value = adapter.getValue(s),
            currentError = adapter.getCurrentValidationError && adapter.getCurrentValidationError(this.settings),
            isOptionalField = _.findIndex(this.settings.validationRules, { type: 'custom' });
        if (bypass || ((typeof (value) === "undefined" || value === null || value === "") && isOptionalField != -1)) {
            result = {
                isValid: true,
                validationRules: [],
                brokenRules: []
            }
            promise = Promise.resolve(result);
        } else {
            if (currentError && currentError.editorSpecific) {
                currentError.validator = this;
                result = {
                    isValid: false,
                    validationRules: [],
                    brokenRules: [currentError]
                };
                promise = Promise.resolve(result);
            } else {
                // adapter.editor.option('validationStatus', 'pending');
                this.validationEngineService.runValidationRulesConditions(s, this.settings.validationRules);
                promise = this.validationEngineService.validate(value, this.settings.validationRules, name, s)
            }
        }

        promise.then((result) => {
            const fvaEnabled = this.validationEngineService.serverSideFVAEnabled();
            this.validateFVAOrApplyResult(result.isValid, fvaEnabled, () => {
                const targetApp = s.applet ? s.applet.name : (s.$parent.applet as Applet).name;
                this.validationEngineService.runServerSideFVA(targetApp, adapter, name, result);
            }, () => {
                this.applyValidationResult(result, adapter);
            });
        });

        return promise;
    }

    validateFVAOrApplyResult(isValid: boolean, fvaEnabled: boolean, fvaFunction: Function, applyResultFunc: Function) {
        const shouldDoFva = isValid && fvaEnabled;
        if (shouldDoFva) {
            fvaFunction();
        } else {
            applyResultFunc();
        }
    }

    render() {
        $(this.editor.element).addClass(VALIDATOR_CLASS);
    }

    findGroup(): string {
        if (!this.groupWasInit)
            this.validationGroup = this.settings ? this.settings.validationGroup : this.settings;
        return this.validationGroup;
    }

    resetAndGetFieldMap(maintainValidValues: boolean) {
        const currentError = this.adapter.getCurrentValidationError(this.settings);
        const shouldReset = currentError || !maintainValidValues;
        const appFieldValueMap = shouldReset ? this.adapter.resetAndGetFieldMap() : this.adapter.getFieldValueMap();
        const result = {
            isValid: true,
            brokenRules: [],
            validationRules: []
        };
        this.adapter.applyValidationResults(result);
        return appFieldValueMap;
    }

    private init() {
        if (!_.isNil(this.settings) && !_.isNil(this.settings.validationGroup))
            this.settings.validationGroup = this.settings.validationGroup.toLowerCase();
        this.initGroupRegistration();
        this.initAdapter();
        this.render();
    }

    private initGroupRegistration() {
        const group = this.findGroup();
        if (!this.groupWasInit) {
            this.editor.component.instance().on("disposing", () => {
                this.validationEngineService.removeRegisteredValidator(this.validationGroup, this);
            });
        }
        if (!this.groupWasInit || this.validationGroup !== group) {
            this.validationEngineService.removeRegisteredValidator(this.validationGroup, this);
            this.groupWasInit = true;
            this.validationGroup = group;
            this.validationEngineService.registerValidatorInGroup(group, this);
        }
    }

    private initAdapter() {
        this.adapter = new DevExtremeAdapter(this.editor.component, this.config);
        this.config.adapter = this.adapter;
    }

}
