/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import numeral from 'numeral';
import { AppsEntity } from '../state/apps.models';
import { TreeListTotalRowComponent } from '../components';
import { ApiClientService } from './api-client.service';
import { DataService } from './data.service';
import { EcdService } from './clientservices/ecd.service';
import { EventsService } from './events.service';
import { ModalService } from './modal.service';
import { EditableRowMupService } from './editable-row-mup.service';
import { WorkflowApplicationTree } from './workflow-application-tree.service';
import { AppsConstantsFacade } from './apps-constants.facade';
import { DynamicReplacementService } from './dynamic-replacement.service';
import { ValidationEngineService } from './validation-engine.service';
import { UtilService } from './util.service';
import { DataSourceService } from './datasource.service';
import { HelpersService } from './helpers.service';
import UtilityFunctions from '../utility.functions';

const DX_INSERT_INDEX_FIELDNAME = '__DX_INSERT_INDEX__';

@Injectable()
export class EditableTreeListService {

    private currentStepName: string;

    private get events$(): BehaviorSubject<AppsEntity> {
        return this.eventsService.events$;
    }

    constructor(
        private injector: Injector,
        private appsConstantsFacade: AppsConstantsFacade,
        private dataService: DataService,
        private editableRowMupService: EditableRowMupService,
        private apiClientService: ApiClientService,
        private workflowApplicationTree: WorkflowApplicationTree,
        private ecdService: EcdService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private eventsService: EventsService,
        private dynamicReplacementService: DynamicReplacementService,
        private validationEngine: ValidationEngineService,
        private modalService: ModalService,
        private utilService: UtilService,
        private dataSourceService: DataSourceService,
        private helpersService: HelpersService) {
        this.appsConstantsFacade.step$.subscribe(stepName => this.currentStepName = stepName);
    }

    onCellPrepared($scope, cellInfo) {

        if (cellInfo.rowType !== 'data') {
            return;
        }

        /**
         * JQuery element containing this cell.
         */
        const cellElement = cellInfo.cellElement;

        /**
         * TreeList Column configuration for this cell.
         */
        const column = cellInfo.column;

        /**
         * TreeList widget instance.
         */
        const component = cellInfo.component;

        /**
         * The data of the TreeList Row to which the cell belongs.
         */
        const data = cellInfo.data;

        /**
         * The row's key.
         */
        const key = cellInfo.key;

        /**
         * Properties of the TreeList Row to which the cell belongs.
         */
        const row = cellInfo.row;

        const isTopLevelRow = this.isTopLevelRow(data, $scope);

        // Because this cell might have just been in editor-mode, and is now brand new to the DOM...
        if (column.validationRules && column.validationRules.length) {
            this.validateCell(cellElement);
        }

        const hasChildrenSumValidator = _.some(column.validationRules, (vr) => {
            return vr.ixMeta === this.getChildrenSumValidatorId();
        });

        const shouldAddValidator =
            isTopLevelRow &&
            !hasChildrenSumValidator &&
            !_.isNil($scope.sumValues[column.dataField]) &&
            column.validationRules; // For non-editable TreeList, this is falsey

        if (shouldAddValidator) {
            const childrenValidator = this.getChildrenSumValidator(column, $scope);
            column.validationRules.push(childrenValidator);
        }

        if (column.command == 'edit') {
            const deleteLink = cellElement.find('.dx-link-delete');
            deleteLink.off('dxclick').on('dxclick', (_clickEvent) => {
                const rowIndex = component.getRowIndexByKey(key);
                component.deleteRow(rowIndex);
                this.updateDeletedRows($scope, key);

                if (isTopLevelRow) {
                    this.removeParentRowFromAggregate($scope, data);
                } else {
                    this.removeChildRowFromAggregate($scope, data, row, component);
                }

                _clickEvent.preventDefault();
            });

            const undeleteLink = cellElement.find('.dx-link-undelete');
            undeleteLink.on('dxclick', (_clickEvent) => {
                this.updateDeletedRows($scope, key);

                if (isTopLevelRow) {
                    this.addParentRowToAggregate($scope, data);
                } else {
                    this.addChildRowToAggregate($scope, data, row, component);
                }
            });

            if (!isTopLevelRow) {
                // Remove add-row button from child rows
                cellElement.find('.dx-link-add').off('dxclick').remove();
            }
        }
    }

    onValueChangedForCell($gridScope, cellInfo, dataField, value, previousValue) {
        this.editableRowMupService.setToUpdated(cellInfo.row);
        const gridElement = cellInfo.component.element();

        const columnIsAggregated = !!$gridScope.sumValues[dataField];
        if (columnIsAggregated) {
            this.updateParent($gridScope, dataField, value, previousValue, cellInfo.row, cellInfo.component);
        }

        this.updateValidState($gridScope, gridElement);
    }

    /**
     * DevExtreme callback handler
     * @param {Object} newRowInfo
     */
    onInitNewRow(newRowInfo) {

        this.setNewlyAddedRow(newRowInfo);

        const $scope = newRowInfo.model;

        if ($scope.newRowInitFields) {
            _.forEach($scope.newRowInitFields, (i) => {
                newRowInfo.data[i] = $scope.model[i].value;
            });
        }

        // Must do after initializing fields, or else relationship identifier on new parent will be stomped
        this.setRelationshipFields($scope, [newRowInfo]);

        this.updateValidState($scope, newRowInfo.element);
    }

    /**
     * DevExtreme callback handler
     * @param {Object} row
     */
    onRowPrepared(row) {

        const $scope = row.model;

        // When grid is first instantiated we don't want to do anything.
        if (this.isIncompleteRow(row)) {
            return;
        }

        if (this.isNewlyAddedRow(row) && !this.isTopLevelRow(row.data, $scope)) {
            row.rowElement.addClass('etl-row-newchild');
        }

        if (this.isInitialized(row)) {
            return;
        }

        // We set initialized and end if its not a newly added row
        if (!this.isNewlyAddedRow(row)) {
            this.setInitialized(row);
            return;
        }

        this.setInitialized(row);

        // Initialize with any DefaultValue settings
        Object.keys($scope.columnMap).forEach((fieldName) => {
            const col = $scope.columnMap[fieldName];
            if (!_.isNil(col.defaultValue)) {
                const value = col.dataType === 'number' && col.defaultValue ?
                    Number(col.defaultValue) :
                    col.defaultValue;
                row.data[fieldName] = value;
            }
        });

        if (this.isTopLevelRow(row.data, $scope)) {
            this.editableRowMupService.initialize(row);
            this.setNewTopLevelKey(row);
            this.addParentRowToAggregate($scope, row.data);
        } else {
            const previousValue = 0;
            const newValue = 0;
            const parent = this.getRowById(row.data[$scope.gridProperties.parentIdExpr], row.component);
            let newRow = null;

            if (this.isNewlyAddedRow(parent)) {

                // This is the case not supported by dev extreme. Inserting a child into a row that is also a newly added row.
                newRow = {
                    data: row.data,
                    level: parent.level + 1,
                    node: {
                        children: [],
                        data: row.data,
                        level: parent.level + 1,
                        parent: parent,
                        visible: true,
                    },
                    rowType: 'data',
                };

                this.editableRowMupService.initialize(newRow);

                if (!Array.isArray(parent.children)) {
                    parent.children = [];
                }

                const newChild = {
                    children: [],
                    data: row.data,
                    hasChildren: false,
                    key: row.data.__KEY__,
                    level: parent.level + 1,
                    node: newRow.node,
                    parent: parent,
                    visible: true,
                };

                parent.node.children.push(newChild);
            }

            parent.data.ic.childrenData.push({
                key: row.key,
                data: row.data
            });

            row.data.ic.removeFromParent = () => {
                _.remove(parent.data.ic.childrenData, (item) => {
                    return item['key'] === row.key;
                });
            };

            const summaryColumnNames = this.getSummaryColumnNames($scope);
            _.forEach(summaryColumnNames, (colName) => {
                this.updateParent($scope, colName, newValue, previousValue, newRow || row, row.component);
            });
        }
    }

    onToolbarPreparing($scope, event) {
        const that = this;
        const revertButton = _.find(event.toolbarOptions.items, (button) => {
            return button.name === 'revertButton';
        });

        if (revertButton) {
            const baseRevert = revertButton.options.onClick;
            revertButton.options.onClick = function (clickEvent) {
                baseRevert.apply(this, clickEvent);
                $scope.sumValues = {};
                $scope.deletedRows = [];
                $scope.columnsWithInvalidTotals = [];
                _.forEach($scope.gridInstance.option('dataSource').items(), function (row) {
                    that.editableRowMupService.setToNotChanged(row);
                });

                that.updateSumValues($scope);
                that.updateValidState($scope, event.element);
            };
        }
    }

    updateSumValues($scope, diff?) {
        $scope.sumValues = $scope.sumValues || {};

        _.forEach($scope.gridProperties.summary.totalItems, (ti) => {
            if (ti.column) {
                if (!$scope.sumValues[ti.column]) {
                    $scope.sumValues[ti.column] = {
                        netDiff: 0,
                        dsTotal: 0
                    };
                }

                const columnSum = $scope.sumValues[ti.column];

                const dataSource = $scope.gridInstance.option('dataSource');
                if (!columnSum.dsTotal && dataSource && dataSource.items().length > 0) {
                    columnSum.dsTotal = _.reduce(dataSource.items(), (sum, row) => {
                        if (!this.isTopLevelRow(row.data, $scope)) {
                            return sum;
                        }

                        const rowValue = row.data[ti.column];

                        return numeral(sum).add(rowValue).value();
                    }, 0);

                    columnSum.netTotal = columnSum.dsTotal;
                    columnSum.netDiff = 0;
                }

                columnSum.formattedValue = () => {
                    return ti.customizeText({
                        value: columnSum.netTotal
                    });
                };

                if (diff && diff.column === ti.column) {
                    this.updateSumDiffValue($scope, diff);
                }
            }
        });
    }

    initializeTotalRow($scope, element) {
        this.updateSumValues($scope);

        const factory = this.componentFactoryResolver.resolveComponentFactory(TreeListTotalRowComponent);
        const componentRef = factory.create(this.injector);
        componentRef.instance.gridProperties = $scope.gridProperties;
        componentRef.instance.sumValues = $scope.sumValues;
        componentRef.hostView.detectChanges();

        componentRef.hostView.detectChanges();
        const compiledElem = componentRef.location.nativeElement;

        _.forEach($scope.gridProperties.columns, (col) => {
            if (!$scope.sumValues[col.dataField]) {
                return;
            }

            const uniqueId = _.uniqueId('etl-footer-summary-value');
            col.footerUniqueId = uniqueId;

            col.footerValidationTooltip = {
                onInitialized: (e) => {
                    $scope.$watch(() => {
                        return {
                            sumVal: $scope.sumValues,
                            items: $scope.gridInstance.getVisibleRows().length
                        };
                    }, () => {
                        const invalidCols = $scope.columnsWithInvalidTotals;
                        const hasData = _.some($scope.gridInstance.getVisibleRows());

                        col.showFooterValidationError = !!(hasData && invalidCols && invalidCols.indexOf(col.dataField) > -1);
                        e.component.toggle(col.showFooterValidationError);
                    }, true);
                },
                position: 'top',
                target: '#' + uniqueId,
                closeOnOutsideClick: false,
                container: compiledElem,
                onShown: (e) => {
                    // TODO: Why does dev extreme show it with 0 height??
                    e.component.content().attr('style', null);
                },
                onHiding: (e) => {
                    if (col.showFooterValidationError) {
                        e.cancel = true;
                    }
                }
            };
        });

        element.append(compiledElem);
    }

    getRowActionButtonConfig($scope): any {
        console.error('"getRowActionButtonConfig" not implemented yet!');
        return {};
        const ttTemplate = [
            '<div class="etl-row-tooltip-content">',
            '   <div class="etl-row-button-container"><button ng-show="canHaveChildren" ng-click="handleAdd()" class="etl-add-row">{{addText}}</button></div>',
            '   <div class="etl-row-button-container"><button ng-hide="markedDeleted" ng-click="handleDelete()" class="etl-delete-row">{{deleteText}}</button></div>',
            '   <div class="etl-row-button-container"><button ng-show="markedDeleted" ng-click="handleUndelete()" class="etl-undelete-row">{{undeleteText}}</button></div>',
            '</div>'
        ].join('');

        return {
            hint: this.utilService.translateOrDefault(
                'etl_row_actions_button_hint',
                'Row Actions'
            ),
            icon: 'more',
            visible: (e) => {
                const parentDeleted = _.indexOf($scope.deletedRows, e.row.data.ParentId) > -1;
                return !parentDeleted && !e.row.isEditing;
            },
            onClick: (e) => {
                let toolTipElem = $(e.event.target)
                    .parent()
                    .find('.etl-row-action-tooltip');
                if (!toolTipElem[0]) {
                    toolTipElem = $(
                        '<div class="etl-row-action-tooltip"></div>'
                    );
                    toolTipElem.insertAfter(e.event.target);

                    const compiledElem = this.getRowMenuContentTemplate(
                        $scope,
                        e,
                        ttTemplate
                    );

                    toolTipElem.dxTooltip({
                        target: e.event.target,
                        visible: true,
                        contentTemplate: (contentElement) => {
                            contentElement.append(compiledElem);
                        }
                    });
                }
                toolTipElem.dxTooltip('show');

                e.event.preventDefault();
            }
        };
    }

    getRowMenuContentTemplate($scope, e, ttTemplate): void {
        console.error('"getRowMenuContentTemplate" not implemented yet!');
        return;
        const $ttScope = $scope.$new(true);
        $ttScope.addText = this.utilService.translateOrDefault(
            'etl-row-action-add',
            'Add Sub Asset Class'
        );
        $ttScope.deleteText = this.utilService.translateOrDefault(
            'etl-row-action-delete',
            'Remove'
        );
        $ttScope.undeleteText = this.utilService.translateOrDefault(
            'etl-row-action-undelete',
            'Undo Remove'
        );
        $ttScope.markedDeleted = _.indexOf($scope.deletedRows, e.row.key) > -1;
        $ttScope.canHaveChildren = this.isTopLevelRow(e.row.data, $scope);
        $ttScope.handleAdd = () => {
            const rowEvent = e;
            const parentId = this.getRowId(rowEvent.row);
            rowEvent.component.addRow(parentId);
        };
        $ttScope.handleDelete = () => {
            const rowEvent = e;
            if (this.isTopLevelRow(rowEvent.row.data, $scope)) {
                this.deleteRowWithWarning($scope, rowEvent)
                    .then((affirmative) => {
                        if (affirmative) {
                            $ttScope.markedDeleted = true;
                        }
                    });
            } else {
                this.deleteRow($scope, rowEvent);

                if (rowEvent.row.data.ic && _.isFunction(rowEvent.row.data.ic.removeFromParent)) {
                    rowEvent.row.data.ic.removeFromParent();
                }
            }

        };
        $ttScope.handleUndelete = () => {
            const rowEvent = e;
            this.undeleteRow($scope, rowEvent);
            $ttScope.markedDeleted = false;
        };

        return $scope.compile(ttTemplate)($ttScope);
    }

    deleteRowWithWarning($gridScope, rowEvent) {
        return this.modalService.confirm({
            text: this.utilService.translateOrDefault(
                'etl-multilevel-delete-warning',
                'Are you sure you want to also delete all of the children for this row?'
            ),
            customCssClass: 'etl-multilevel-delete-warning-modal'
        }).then((affirmative) => {
            if (affirmative) {
                this.deleteRow($gridScope, rowEvent);
                return true;
            }
            return false;
        });
    }

    deleteRow($gridScope, rowEvent) {
        const rowIndex = rowEvent.row.rowIndex;
        const component = rowEvent.component;
        const key = rowEvent.row.key;
        const data = rowEvent.row.data;
        const row = rowEvent.row;
        const childRows = this.getChildRows(row, component);
        component.deleteRow(rowIndex);

        this.editableRowMupService.setToDeleted(row);

        this.updateDeletedRows($gridScope, key);

        if (this.isTopLevelRow(data, $gridScope)) {
            this.deleteChildRows($gridScope, component, childRows);
            component.repaint();
            this.removeParentRowFromAggregate($gridScope, data);
        } else {
            this.removeChildRowFromAggregate($gridScope, data, row, component);
        }

        this.updateValidState($gridScope, rowEvent.element);
    }

    undeleteRow($gridScope, rowEvent) {
        const rowIndex = rowEvent.row.rowIndex;
        const key = rowEvent.row.key;
        const data = rowEvent.row.data;
        const component = rowEvent.component;
        const row = rowEvent.row;
        component.undeleteRow(rowIndex);

        this.editableRowMupService.setToUndeleted(row);

        this.updateDeletedRows($gridScope, key);

        if (this.isTopLevelRow(data, $gridScope)) {
            this.undeleteChildRows($gridScope, component, row.node.children);
            component.repaint();
            this.addParentRowToAggregate($gridScope, data);
        } else {
            this.addChildRowToAggregate($gridScope, data, row, component);
        }
    }

    getChildRows(parentRow, component) {
        let childRows = parentRow.node.children;

        // DxTreeList doesn't let a newly-added row keep anything in its children array
        // Reason: internal code depends on dxDataSource, which remains unaware of newly
        // added rows.
        if (this.isNewlyAddedRow(parentRow)) {
            childRows = component.getVisibleRows().filter((child) => {
                return child.data.ParentId === parentRow.key;
            });
        }

        return childRows;
    }

    deleteChildRows($gridScope, component, children) {
        _.forEach(children, (child) => {
            const rowIndex = component.getRowIndexByKey(child.key);
            component.deleteRow(rowIndex);

            this.editableRowMupService.setToDeleted(child);

            this.updateDeletedRows($gridScope, child.key);
        });
    }

    undeleteChildRows($gridScope, component, children) {
        _.forEach(children, (child) => {
            const rowIndex = component.getRowIndexByKey(child.key);
            component.undeleteRow(rowIndex);

            this.editableRowMupService.setToUndeleted(child);

            this.updateDeletedRows($gridScope, child.key);
        });
    }

    updateParent($scope, dataField, value, previousValue, changedRow, component) {

        const isChangedRowTopLevel = this.isTopLevelRow(changedRow.data, $scope);

        const parent = isChangedRowTopLevel ? changedRow : this.getRowById(changedRow.data[$scope.gridProperties.parentIdExpr], component);
        const parentValue = isChangedRowTopLevel ? value : component.cellValue(component.getRowIndexByKey(parent.key), dataField);

        const existingChildrenData = _.chain(parent.node.children)
            .filter((child) => {
                return !_.includes($scope.deletedRows, child.key);
            })
            .map((child) => {
                return {
                    key: child.key,
                    data: child.data
                };
            })
            .value();

        const otherChildrenData = _.map(parent.data.ic.childrenData, (child) => {
            return {
                key: child.key,
                data: child.data
            };
        });

        const childrenToTotal = _.unionBy(existingChildrenData, otherChildrenData, (child) => {
            return child.key;
        });

        _.forEach(childrenToTotal, (child) => {
            if (changedRow.key === child.key) {
                //DevExtreme won't update data for us, so assign it now.
                child.data[dataField] = Number(value);
            }
        });

        const childrenTotal = _.chain(childrenToTotal)
            .map((child) => {
                if (!isChangedRowTopLevel) {
                    const isChangedNewRow = changedRow.data.__KEY__ === child.key;
                    const isChangedExistingRow = changedRow.key === child.key;

                    if (isChangedExistingRow || isChangedNewRow) {
                        return value;
                    }
                }

                let childValue = component.cellValue(component.getRowIndexByKey(child.key), dataField); // Child objects don't updated values.

                // Collapsed child rows can't have their .cellValue read
                if (_.isNil(childValue)) {
                    childValue = child.data[dataField];
                }

                return childValue;
            })
            .reduce((sum, value) => {
                return numeral(sum).add(value).value();
            }, 0)
            .value();

        const parentRowIndex = component.getRowIndexByKey(parent.key);
        const hasChildren = _.some(childrenToTotal);
        let parentChildrenMatch = this.isFloatEqual(parentValue, childrenTotal);
        if (!$scope.gridProperties || !$scope.gridProperties.expectChildrenWhenMatchingParent) {
            parentChildrenMatch = hasChildren ? parentChildrenMatch : true;
        }
        const sumData = $scope.sumValues[dataField];

        if (sumData) {
            sumData.erroredParentKeys = sumData.erroredParentKeys || [];

            if (parentChildrenMatch) {
                sumData.erroredParentKeys = _.filter(sumData.erroredParentKeys, (key) => {
                    return key !== parent.key;
                });
            } else {
                sumData.erroredParentKeys = sumData.erroredParentKeys || [];
                const hasErrorKey = 0 <= _.findIndex(sumData.erroredParentKeys, (key) => {
                    return key === parent.key;
                });

                if (!hasErrorKey) {
                    sumData.erroredParentKeys.push(parent.key);
                }
            }
        }

        if (this.isInitialized(changedRow)) {
            component.closeEditCell();

            // Force aggregate validation to run on parent
            component.editCell(parentRowIndex, dataField);
            const parentCellElement = this.getParentCellElement(component, parent.key, dataField);
            this.validateCell(parentCellElement);
            component.closeEditCell();
        }

        if (isChangedRowTopLevel) {
            const diff = {
                column: dataField,
                value: value - previousValue
            };

            this.updateSumValues($scope, diff);
        }

        setTimeout(() => {
            this.updateSaveButtonEnabledState($scope, component.element());
        }, 0);
    }

    getParentCellElement(component, parentKey, dataField) {
        return component.getCellElement(component.getRowIndexByKey(parentKey), dataField);
    }

    getDuplicates($scope) {
        const nonBlankValues = _.chain($scope.gridInstance.getVisibleRows())
            .map((row) => {
                return row.data[$scope.uniqueColumnFieldValidations];
            })
            .filter()
            .value();


        return nonBlankValues.filter((val, i, arr) => {
            return arr.indexOf(val) !== i;
        });
    }

    validateUniqueValues($scope, value?) {
        const duplicates = this.getDuplicates($scope);
        return !_.some(duplicates, (dup) => {
            return !value || (dup === value);
        });
    }

    generateValidator($scope, e) {
        const validationGroupName = this.getValidationGroupName($scope);

        const dxValidator = {
            validationGroup: validationGroupName,
            validationRules: [{
                enabled: true,
                type: 'custom',
                validationCallback: () => {
                    return $scope.valid && $scope.validUniqueRows;
                },
            }],
        };

        $scope.valid = true;
        $scope.validUniqueRows = true;

        if ($scope.uniqueColumnFieldValidations) {
            console.log('"uniqueColumnFieldValidations" not implemented yet!');
            // $scope.$watch(() => {
            //     return _.chain($scope.gridInstance.getVisibleRows())
            //         .map((item) => item.data[$scope.uniqueColumnFieldValidations])
            //         .filter()
            //         .value();
            // }, (a, b) => {

            //     if (_.isEqual(a, b)) {
            //         return;
            //     }

            //     $scope.validUniqueRows = this.validateUniqueValues($scope);
            //     $scope.validator.validate($scope);

            //     if (e.element) {
            //         this.updateSaveButtonEnabledState($scope, e.element);
            //     }
            // }, true);
        }

        return this.validationEngine.createNewValidator(e, dxValidator);
    }

    getValidationGroupName($scope) {
        for (let i = 0; i < $scope.gridProperties.columns.length; i++) {
            if ($scope.gridProperties.columns[i].validationGroupName) {
                return $scope.gridProperties.columns[i].validationGroupName;
            }
        }

        // In case p-tier assigns no validation group for any rows,
        // we still need one for the sum validation
        return 'EditableTreeListDefaultValidationGroup';
    }

    updateValidState($scope, gridElement) {
        setTimeout(() => {
            const rowErrors = gridElement.find('.dx-treelist-invalid').length;
            const summaryErrors = gridElement.siblings().find('.' + this.getSummaryRowErrorClassname()).length;
            $scope.valid = !rowErrors && !summaryErrors;
            $scope.validator.validate($scope);
            this.updateSaveButtonEnabledState($scope, gridElement);
        }, 250);
    }

    updateSaveButtonEnabledState($scope, gridElement) {
        const validGroup = this.getValidationGroupName($scope);
        this.validationEngine.validateGroup($scope, validGroup)
            .then((result) => {
                if (!result.isValid) {
                    gridElement.find('.dx-treelist-save-button').dxButton('instance').option('disabled', true);
                } else {

                    gridElement.find('.dx-treelist-save-button').dxButton('instance').option('disabled', false);
                }
            });
    }

    getSummaryRowErrorClassname() {
        return 'etl-sum-error';
    }

    updateSumDiffValue($scope, diff) {
        const columnSum = $scope.sumValues[diff.column];
        columnSum.netDiff = numeral(columnSum.netDiff).add(diff.value).value();
        columnSum.netTotal = numeral(columnSum.dsTotal).add(columnSum.netDiff).value();
        const totalValidation = $scope.totalValidation[diff.column];
        const validValue = parseFloat(totalValidation.value);
        const isEqual = this.isFloatEqual(validValue, columnSum.netTotal);

        if (!_.isArray($scope.columnsWithInvalidTotals)) {
            $scope.columnsWithInvalidTotals = [];
        }

        if (totalValidation && !_.isNil(validValue) && !isEqual) {

            if ($scope.columnsWithInvalidTotals.indexOf(diff.column) === -1) {
                $scope.columnsWithInvalidTotals.push(diff.column);
            }
        } else {

            if ($scope.columnsWithInvalidTotals.indexOf(diff.column) > -1) {
                $scope.columnsWithInvalidTotals.splice(diff.column, 1);
            }
        }
    }

    isFloatEqual(float1, float2) {
        let value = Number.EPSILON;
        if (value === undefined) {
            value = Math.pow(2, -52);
        }

        return Math.abs(float1 - float2) < value;
    }

    addParentRowToAggregate($scope, rowData) {
        const summaryColumnNames = this.getSummaryColumnNames($scope);

        _.forEach(summaryColumnNames, (colName) => {
            const value = _.isNil(rowData[colName]) ? 0 : rowData[colName];
            const diff = {
                column: colName,
                value: value
            };

            this.updateSumDiffValue($scope, diff);
        });
    }

    removeParentRowFromAggregate($scope, rowData) {
        const summaryColumnNames = this.getSummaryColumnNames($scope);
        _.forEach(summaryColumnNames, (colName) => {
            const diff = {
                column: colName,
                value: numeral(rowData[colName]).multiply(-1).value(),
            };

            this.updateSumDiffValue($scope, diff);
        });
    }

    addChildRowToAggregate($scope, rowData, row, component) {
        const summaryColumnNames = this.getSummaryColumnNames($scope);

        _.forEach(summaryColumnNames, (colName) => {
            const previousValue = 0;
            const newValue = rowData[colName];

            this.updateParent($scope, colName, newValue, previousValue, row, component);
        });
    }

    removeChildRowFromAggregate($scope, rowData, row, component) {
        const summaryColumnNames = this.getSummaryColumnNames($scope);

        _.forEach(summaryColumnNames, (colName) => {
            const previousValue = rowData[colName];
            const newValue = 0;

            this.updateParent($scope, colName, newValue, previousValue, row, component);
        });
    }

    updateDeletedRows($scope, rowKey) {
        $scope.deletedRows = !_.isNil($scope.deletedRows) ? $scope.deletedRows : [];

        const index = $scope.deletedRows.indexOf(rowKey);

        if (index > -1) {
            $scope.deletedRows.splice(index, 1);
        } else {
            $scope.deletedRows.push(rowKey);
        }
    }

    validateCell(cellElement) {
        try {
            const validator = cellElement.dxValidator('instance');
            validator.validate();
        } catch (e) {
            // Although we have validationRules, we may not be able to get a validator, instead getting
            // DevExtreme Error: E0009 - Component 'dxValidator' has not been initialized for an element
        }
    }

    getChildrenSumValidator(column, $scope) {
        const parentChildSumValidator = {
            sequence: column.validationRules.length + 1,
            type: 'custom',
            message: this.utilService.translateOrDefault('etl_levels_sum_mismatch', 'Sum of child values does not match'),
            enabled: true,
            ixMeta: this.getChildrenSumValidatorId(),
            validationCallback: (options) => {

                if (this.isTopLevelRow(options.data, $scope)) {
                    const sumData = $scope.sumValues[column.dataField];
                    const parentHasError = sumData && _.some(sumData.erroredParentKeys, (key) => {

                        // Existing Rows match Key to Key expr
                        if (key === options.data[$scope.gridProperties.keyExpr]) {
                            return true;
                        }

                        const newlyAddedRowKey = this.getNewTopLevelKey(options);

                        if (!newlyAddedRowKey) {
                            return false;
                        }

                        return newlyAddedRowKey === key[DX_INSERT_INDEX_FIELDNAME];
                    });
                    return !parentHasError;
                }

                return true;
            }
        };

        return parentChildSumValidator;
    }

    getSummaryColumnNames($scope) {
        const columnsOnly = _.filter($scope.gridProperties.summary.totalItems, (item) => {
            return item.column;
        });

        const totalColumnNames = _.map(columnsOnly, (totalItem) => {
            return totalItem.column;
        });

        return totalColumnNames;
    }

    isTopLevelRow(rowData, $scope) {
        return !(rowData && rowData[$scope.gridProperties.parentIdExpr]);
    }

    getChildrenSumValidatorId() {
        return 'editableTreeList_parentChildSum';
    }

    //#region events

    private addEventSubscription($scope, eventSubscription: Subscription): void {
        $scope.addEventSubscription(eventSubscription);
    }

    private publishEvent(eventState: AppsEntity): void {
        this.eventsService.publishEvent(eventState);
    }

    notifyAddRow(appName: string): void {
        const eventState = {
            id: 'editableTreeList.add-row',
            appName
        }
        this.publishEvent(eventState);
    }

    subscribeToAddRow($scope, callback): void {
        const dispose = this.events$
            .pipe(
                filter(event => event?.id == "editableTreeList.add-row"),
                tap((event) => callback(event.state))
            ).subscribe();
        this.addEventSubscription($scope, dispose);
    }

    notifySaveEditData(appName: string): void {
        const eventState = {
            id: 'editableTreeList.save-edit-data',
            appName
        };
        this.publishEvent(eventState);
    }

    subscribeToSaveEditData($scope, callback): void {
        const dispose = this.events$
            .pipe(
                filter(event => event?.id == "editableTreeList.save-edit-data"),
                tap((event) => callback(event.state))
            ).subscribe();
        this.addEventSubscription($scope, dispose);
    }

    notifyCancelEditData(appName: string): void {
        const eventState = {
            id: 'editableTreeList.cancel-edit-data',
            appName
        };
        this.publishEvent(eventState);
    }

    subscribeToCancelEditData($scope, callback): void {
        const dispose = this.events$
            .pipe(
                filter(event => event?.id == "editableTreeList.cancel-edit-data"),
                tap((event) => callback(event.state))
            ).subscribe();
        this.addEventSubscription($scope, dispose);
    }

    //#endregion

    /**
     * We cannot rely exclusively upon Component.getNodeByKey, because it depends upon
     * DataSource, which isn't updated when rows are added while in batch-edit mode.
     * (It won't find newly-added rows.)
     */
    getRowById(id, component) {
        return component.getVisibleRows().find((visibleRow) => {
            if (
                visibleRow.key &&
                !_.isNil(visibleRow.key[DX_INSERT_INDEX_FIELDNAME]) &&
                !_.isNil(id[DX_INSERT_INDEX_FIELDNAME]) &&
                visibleRow.key[DX_INSERT_INDEX_FIELDNAME] === id[DX_INSERT_INDEX_FIELDNAME]
            ) {
                // Don't depend on object equality when comparing; it is sometimes a copy
                return true;
            }

            return visibleRow.key === id;
        });
    }

    /**
     * Existing rows have a data.Id property.
     * Newly added rows have a key object.
     */
    getRowId(row) {
        return row.data.Id || row.key;
    }

    createRowForDatasource(newValues, listKeyField, listKeyValue) {
        const newRow = {
            data: newValues,
        };

        newRow.data[listKeyField] = listKeyValue;

        return newRow;
    }

    setRelationshipFields($scope, rows) {

        // We cannot rely on DevExtreme to send us new rows in any particular order.
        // Therefore, looping through to find and change a parent can fail
        // (new child comes over before the new parent).  Workaround: repeat the loop
        // every time another record comes over.

        const keyExpr = $scope.gridProperties.keyExpr;
        const parentIdExpr = $scope.gridProperties.parentIdExpr;

        rows.filter((row) => {
            // Is newly added row
            return !row.key;
        })
            .sort((row) => {
                return !row.data[parentIdExpr] ? -1 : 1;
            })
            .forEach((row) => {

                const parentId = row.data[parentIdExpr];
                const isParent = !parentId;

                if (!isParent) {

                    const parentRow = rows.find((item) => {
                        return item.data[keyExpr] === parentId ||
                            (!!this.getNewTopLevelKey(item) && (this.getNewTopLevelKey(item) === row.data[parentIdExpr][DX_INSERT_INDEX_FIELDNAME]));
                    });

                    if (parentRow) {
                        this.setRelationshipForChildRow($scope, row.data, parentRow.data[$scope.newChildBackReferenceField]);
                    }
                } else {
                    this.setRelationshipForParentRow($scope, row.data);
                }
            });
    }

    setRelationshipForParentRow($scope, data) {

        //TODO: Re-implement with appropriately named screen props and fields:

        data[$scope.relationshipFieldName] = $scope.relationshipFieldValueIfParent;

        //TODO: newChildBackReferenceField should be SurrogateParentKeyField
        //TODO: relationshipChildValueFieldToFilter is being used because it happens to be right;
        //TODO: SurrogateKeyField should be added via screen property instead
        data[$scope.newChildBackReferenceField] = data[$scope.relationshipChildValueFieldToFilter];
    }

    setRelationshipForChildRow($scope, data, parentReference) {

        // Name of datafield which will be updated based on comparison against fieldToFilter
        const relationshipField = $scope.relationshipFieldName;

        // Name of datafield identifying row type (to be filtered)
        const fieldToFilter = $scope.relationshipChildValueFieldToFilter;

        // Array of values which can be assigned to relationshipField, based on value of fieldToFilter
        const relationshipFieldValues = $scope.relationshipFieldValuesIfChild;

        // Array of comparators and operators, corresponding to array of values
        const filters = $scope.relationshipChildValueFilters;

        // Name of datafield where reference to parent will be stored
        const parentReferenceField = $scope.newChildBackReferenceField;

        // Name of a datafield on the childRow to copy identifier to
        const duplicateIdField = $scope.relationshipChildDuplicateIdField;

        // Assign duplicate Id from Identifier because A-tier and BA said so to be able to track hierarchy before they have real Id's
        data[duplicateIdField] = data[fieldToFilter];

        const comparators = filters.map((filter) => {
            return filter.comparator;
        });

        const operators = filters.map((filter) => {
            return filter.operator;
        });

        for (let i = 0; i < relationshipFieldValues.length; i++) {
            if (
                (
                    operators[i] === '===' &&
                    data[fieldToFilter] === comparators[i]
                ) ||
                (
                    operators[i] === '!==' &&
                    data[fieldToFilter] !== comparators[i]
                )
            ) {
                data[relationshipField] = relationshipFieldValues[i];
            }
        }

        data[parentReferenceField] = parentReference;
    }

    /**
     * For dropdown to be used in editable cell
     * The treelist may need to filter its data
     * @param {object} ecdRequest
     * @param {string} keyExpr fieldname of key relating dropdown to treelist
     * @param {*} childIdField Optional fieldname identifying the row (in an implied hierarchy)
     * @param {*} parentIdField Optional fieldname identifying the row's parent (in an implied hierarchy)
     */
    createLookupDataSource(ecdRequest, keyExpr, childIdField, parentIdField) {
        const store = this.dataSourceService.createLookupDataSource(ecdRequest, keyExpr, childIdField, parentIdField);

        return {
            store: store,
        };
    }

    // TODO: This does not seem to be called from anywhere, and is not in RBC.
    createDataSource($scope, ecdRequestOriginal, map, doNotLoad, onLoaded, keyExpr) {
        const that = this;
        const store = new DevExpress.data.CustomStore({
            load: function (loadOptions) {

                const d = $.Deferred(), requestData = {};
                const ecdRequest = { ...ecdRequestOriginal };

                loadOptions.filter = that.dataSourceService.getAppFilter(ecdRequestOriginal.ApplicationName);

                if (Array.isArray(loadOptions.filter)) {
                    const arrayLength = loadOptions.filter.length;
                    let filterElement, fValue;
                    for (let i = 0; i < arrayLength; i++) {
                        filterElement = loadOptions.filter[i];
                        if (Array.isArray(filterElement)) {
                            fValue = UtilityFunctions.getValue(filterElement[filterElement.length - 1]);
                            if (fValue != void (0) && fValue != null) {
                                requestData[filterElement[0]] = fValue;
                            }
                        }
                    }
                }
                const runtimeInfo = that.getRuntimeInfo(ecdRequest);
                doNotLoad = doNotLoad || that.dataService.isDisplayBehaviorOnEventOnlyEnabled(runtimeInfo.name);
                if (_.isNil(loadOptions.forceLoad) || !loadOptions.forceLoad) {
                    if (!_.isNil(doNotLoad) && doNotLoad) {
                        loadOptions.forceLoad = true;
                        that.dataService.disableDisplayBehaviorOnEventOnly(runtimeInfo.containerName, runtimeInfo.name);
                        if (_.isEmpty(requestData)) {
                            d.resolve([], { totalCount: 0 });
                            return d.promise();
                        }
                    }
                }

                const ecdContext = {};
                ecdRequest.Data = requestData;
                that.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);
                delete ecdRequest.DynamicColumnItems;

                that.ecdService.request(ecdRequest)
                    .then(function (data) {
                        const listData = convertDataSetListToObjectList(data.ListData);
                        if (!!onLoaded) {
                            onLoaded(listData);
                        }
                        return completeLoadRequest(d, listData);
                        // that.$dataSourceSvc.createDynamicColumns(ecdRequestOriginal.ApplicationName, listData, ecdRequestOriginal.DynamicColumnItems)
                        //     .then(function (listDataWithDynamicColumns) {
                        //         that.$dataSourceSvc.createCustomSortProperties(listDataWithDynamicColumns, map || {});

                        //         return completeLoadRequest(d, listDataWithDynamicColumns);
                        //     });
                    })
                    .catch(function (jqXHR, type, statusMessage) {
                        if (!!onLoaded) {
                            onLoaded([]);
                        }
                        // Used by "{Error:} Dynamic Replacement Value. "
                        window.lastError = jqXHR.responseJSON;
                        IX_Log('component', jqXHR.responseJSON);
                        d.resolve([], { totalCount: 0 });
                    });

                return d.promise();
            },
            insert: function (values, noDebounce) {
                return callAdapter(adapter.insert, undefined, values, $scope, noDebounce);
            },
            update: function (key, values, noDebounce) {
                return callAdapter(adapter.update, key, values, $scope, noDebounce);
            },
            remove: function (key, noDebounce) {
                return callAdapter(adapter.remove, key, undefined, $scope, noDebounce);
            },
            key: keyExpr
        });
        if (_.isEmpty(store.key()))
            console.warn(ecdRequestOriginal.ApplicationName, "is not setting key property for datasource. Potential performance issue.");
        const callAdapter = function (adapterFunction, key, values, $scope, noDebounce) {
            const keepChangesOnSaveError = !!$scope.gridProperties.keepChangesOnSaveError;
            const dfd = $.Deferred();
            adapterFunction(key, values, noDebounce)
                .then(function () {
                    dfd.resolve();
                }, function (e) {
                    if (keepChangesOnSaveError) {
                        // put button state back, so it's clear
                        // that the form has not been saved
                        const element = $scope.gridInstance._$element;
                        setTimeout(function () {
                            that.updateSaveButtonEnabledState($scope, element);
                        }, 0);
                        mupMessageOnError()
                            .then(function (e) {
                                dfd.reject(e);
                            })
                            .catch(function (e) {
                                dfd.reject();
                            });
                    } else {
                        dfd.resolve();
                    }
                });

            return dfd.promise();
        };

        const adapter = {
            insert: function (key, values, noDebounce) {
                const row = that.createRowForDatasource(
                    values,
                    $scope.listKeyField,
                    $scope.model[$scope.listKeyField].value
                );

                performDateReplacements(row);

                that.editableRowMupService.setToCreated(row);
                datasource.items().push(row);
                that.setRelationshipFields($scope, datasource.items());
                return dataChanged(noDebounce);
            },
            update: function (key, values, noDebounce) {
                const row = datasource.items().find(function (r) { return r.key === key; });
                insertNewValues(row, values);

                performDateReplacements(row);

                that.editableRowMupService.setToUpdated(row);
                return dataChanged(noDebounce);
            },
            remove: function (key, values, noDebounce) {
                const row = datasource.items().find(function (r) { return r.key === key; });
                that.editableRowMupService.setToDeleted(row);
                return dataChanged(noDebounce);
            },
        };

        const performDateReplacements = function (row) {
            for (const col in row.data) {
                if (row.data[col]) {
                    if (
                        typeof row.data[col] === "string" &&
                        _.startsWith(row.data[col], "{Date:") &&
                        _.endsWith(row.data[col], "}")
                    ) {
                        row.data[col] = that.dynamicReplacementService.getDateReplacementUnformatted(row.data[col]);
                    }
                }
            }
        };

        const dataChanged = function (noDebounce) {
            // debounced wait
            flatten();
            sortByLevel(datasource.items());

            return bufferedSave(noDebounce);
        };

        const flatten = function () {
            datasource.items().forEach(function (firstLevel) {
                if (firstLevel.children && firstLevel.children.length) {
                    firstLevel.children.forEach(function (secondLevel) {
                        const atFirstLevel = datasource.items().find(function (item) {
                            return item === secondLevel;
                        });

                        if (!atFirstLevel) {
                            datasource.items().push(secondLevel);
                        }
                    });
                }
            });
        };

        const insertNewValues = function (row, values) {
            Object.getOwnPropertyNames(values).forEach(function (prop) {
                row.data[prop] = values[prop];
            });
        };

        const bufferedSave = function (noDebounce) {
            const dfd = $.Deferred();
            if (!$scope.deferredList) {
                $scope.deferredList = [];
            }
            $scope.deferredList.push(dfd);

            const saveItems = _.chain(datasource.items())
                .filter(function (item) {

                    // TODO: Figure out why we get an extra bogus blank row.  This is to remove it. Could be implemented differently
                    return !!item.data[$scope.relationshipChildValueFieldToFilter];
                })
                .map(function (item) {
                    return item.data;
                })
                .value();

            if (!noDebounce) {
                debouncedMakeEcdRequest(saveItems, $scope.deferredList);
            } else {
                // Unit tests become unstable with debounce...
                makeEcdRequest(saveItems, $scope.deferredList);
            }
            return dfd.promise();
        };

        const sortByLevel = function (items) {

            const field = $scope.relationshipFieldName;
            const value = $scope.relationshipFieldValueIfParent;

            items.sort(function (a, b) {
                const aIsFirstLevel = a.data[field] === value;
                const bIsFirstLevel = b.data[field] === value;

                if (aIsFirstLevel && bIsFirstLevel) {
                    return 0;
                } else if (aIsFirstLevel) {
                    return -1;
                } else if (bIsFirstLevel) {
                    return 1;
                } else {
                    return 0;
                }
            });
        };

        const convertDataSetListToObjectList = function (listData) {
            const dataObjectArr = [];
            let singleObject, i, j;
            for (i = 1; i < listData.length; i++) {
                singleObject = listData[i];
                dataObjectArr[i - 1] = {};
                for (j = 0; j < singleObject.length; j++) {
                    dataObjectArr[i - 1][listData[0][j]] = singleObject[j];
                }
            }
            return dataObjectArr;
        };

        const completeLoadRequest = function (deferred, listData) {
            const totalCountObj = { totalCount: listData.length };
            deferred.resolve(listData, totalCountObj);
        };

        const mupMessageOnError = function () {
            if ($scope.mupMessageOnError) {
                const saveFailureMessage = that.utilService.translateOrDefault(
                    $scope.mupMessageOnError,
                    $scope.mupMessageOnError
                );
                return that.dynamicReplacementService.getDynamicValue(saveFailureMessage, $scope);
            } else {
                return Promise.reject(false);
            }
        };

        function makeEcdRequest(dsItems, deferredPromises) {
            const localDeferred = deferredPromises.slice(0, deferredPromises.length)
            deferredPromises.length = 0;
            _deleteNamespace(dsItems);

            const ecdRequest = { ...ecdRequestOriginal };
            ecdRequest.ServerCallType = 'MUP-C';
            ecdRequest.Context = {};

            const mupData = _transformRequestToMupM(dsItems);
            ecdRequest.Data = {
                $ComponentDataToMUP: mupData,
                $ComponentKeys: mupData[0]
            };

            that.ecdService.request(ecdRequest)
                .then(function (response) {
                    if (!$scope.mupMessageOnSuccess) {
                        localDeferred.forEach(function (v) { v.resolve(true); });
                        localDeferred.length = 0;
                        return;
                    }

                    const saveSuccessMessage = that.utilService.translateOrDefault(
                        $scope.mupMessageOnSuccess,
                        $scope.mupMessageOnSuccess
                    );

                    return that.dynamicReplacementService.getDynamicValue(saveSuccessMessage, $scope)
                        .then(function (dynamicMessage) {
                            return that.helpersService.showMessage($scope, dynamicMessage)
                                .then(function () {
                                    localDeferred.forEach(function (v) { v.resolve(true); });
                                    localDeferred.length = 0;
                                });
                        });
                }, function (jqXHR, type, statusMessage) {
                    //Used by "{Error:} Dynamic Replacement Value. "
                    window.lastError = jqXHR.responseJSON;
                    IX_Log('component', jqXHR.responseJSON);

                    mupMessageOnError()
                        .then(function (dynamicMessage) {
                            return this.helpersService.showMessage($scope, dynamicMessage)
                                .then(function (result) {
                                    localDeferred.forEach(function (v) { v.reject(result); });
                                    localDeferred.length = 0;
                                })
                                .catch(function () {
                                    localDeferred.forEach(function (v) { v.reject(); });
                                    localDeferred.length = 0;
                                });
                        })
                        .catch(function () {
                            localDeferred.forEach(function (v) { v.reject(false); });
                            localDeferred.length = 0;
                        });
                });

        };

        const debouncedMakeEcdRequest = _.debounce(makeEcdRequest, 50);

        function _deleteNamespace(items) {
            items.forEach(function (data) {

                if (_.isObject(data[$scope.gridProperties.parentIdExpr])) {
                    data[$scope.gridProperties.parentIdExpr] = null;
                }

                delete data.ic;
            });
        }

        function _transformRequestToMupM(data) {
            const cols = _.reduce(data, function (cols, r) {
                return _.union(cols, Object.keys(r));
            }, []);
            const mupRows = _.map(data, function (r) {
                const newRow = _.map(cols, function (c) {
                    return r[c];
                });

                return newRow;
            });
            let mupData = [];
            mupData.push(cols);
            mupData = mupData.concat(mupRows);
            return mupData;
        }

        const datasource = new DevExpress.data.DataSource({
            store: store,
            filter: null,
            onChanged: function () {
                that.initializeMUPVerb(datasource.items());
            },
        });
        return datasource;
    }

    initializeMUPVerb(datasourceItems) {
        // Recursively initialize nested items
        datasourceItems.forEach((item) => {
            this.editableRowMupService.initialize(item);
            if (item.children && item.children.length) {
                this.initializeMUPVerb(item.children);
            }
        });
    }

    getRuntimeInfo(ecdRequest) {
        const currentStepName = this.currentStepName;
        let runtimeInfo = this.workflowApplicationTree.getStepApplet(currentStepName, ecdRequest.ApplicationName);
        if (!runtimeInfo) {
            runtimeInfo = {
                name: ecdRequest.ApplicationName,
                containerName: ecdRequest.ContainerApplication
            };
        }
        return runtimeInfo;
    }

    setNewTopLevelKey(row) {
        if (!row.data.ic) {
            row.data.ic = {};
        }

        row.data.ic[DX_INSERT_INDEX_FIELDNAME] = row.key[DX_INSERT_INDEX_FIELDNAME];

        if (!row.data.ic.childrenData) {
            row.data.ic.childrenData = [];
        }
    }

    getNewTopLevelKey(row) {
        return row.data.ic && row.data.ic[DX_INSERT_INDEX_FIELDNAME];
    }

    setInitialized(row) {
        if (!row.data.ic) {
            row.data.ic = {
                initialized: true
            };

        } else {
            row.data.ic.initialized = true;
        }

        if (!row.data.ic.childrenData) {
            row.data.ic.childrenData = [];
        }
    }

    isInitialized(row) {
        return row.data.ic && row.data.ic.initialized;
    }

    setNewlyAddedRow(row) {
        if (!row.data.ic) {
            row.data.ic = {
                newlyAdded: true,
            };
        } else {
            row.data.ic.newlyAdded = true;
        }

        if (!row.data.ic.childrenData) {
            row.data.ic.childrenData = [];
        }
    }

    isNewlyAddedRow(row) {
        return row.data.ic && row.data.ic.newlyAdded;
    }

    isIncompleteRow(row) {
        // Will return true, for example, when the treelist is first being drawn
        return !row.data;
    }
}
