const LocalPrefix = '/scripts/jig/';
const LocalExtension = '.json';

function getLocaleUniqueId(): string {
    return '-' + window.uniqueTranslationId + '-locale-';
}

export function getLocaleKey(): string {
    const wfName = window.workflowName.toPopUpName();
    const currentLocale = getCurrentLocale();
    const themeName = window.IX_Theme.themeName.replace(/\s/g, '');
    window.$translateLocaleKey = [wfName, themeName, currentLocale, window.uniqueTranslationId].join("-");
    return window.$translateLocaleKey;
}

export function getCurrentLocale(): string {
    const locale = IX_GetCookieValue('IXCulture') || document.documentElement.lang;
    return escape(locale);
}

export function getWorkflowLocaleFile() {
    const currentLocale = getCurrentLocale();
    const wfName = window.workflowName.toPopUpName();
    const uniqueLocaleId = getLocaleUniqueId();
    return LocalPrefix + wfName + uniqueLocaleId + currentLocale + LocalExtension;
}

export function getThemeLocaleFile() {
    const currentLocale = getCurrentLocale();
    const themeName = window.IX_Theme.themeName.replace(/\s/g, '');
    const uniqueLocaleId = getLocaleUniqueId();
    // TODO: make improvements to NOT make request of theme locale file 
    // when it was not required to be generated (no theme override labels or properties to be translated).
    return LocalPrefix + themeName + uniqueLocaleId + currentLocale + LocalExtension;
}
