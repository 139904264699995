// tslint:disable: forin
// tslint:disable: no-bitwise
import { Injectable } from '@angular/core';
import UtilityFunctions from '../../utility.functions';
import { ApiClientService } from '../api-client.service';
import { EcdUtilService } from './ecdutil.service';

@Injectable()
export class EcdGroupService {

  constructor(private ecdUtilService: EcdUtilService,
    private apiClientService: ApiClientService) { }

  request(params) {
    const useLocalDataSource = UtilityFunctions.getThemeProperty('UseLocalDataSource');

    if (useLocalDataSource && window.location.href.indexOf('IX_DEBUG_DISABLE_LOCAL_DATASOURCE') === -1) {
      if (Array.isArray(params.Requesters)) {
        const clientRequests = this.createOnlyClientServiceRequest(params.Requesters);
        // to determine if it is worth reducing the payload
        // for client service requests
        if (clientRequests.clientServicePromises.length === params.Requesters.length) {
          return this.processClientOnlyRequests(clientRequests);
        }
      }
    }

    return this.apiClientService.makeEcdgRequest(params);
  }

  private createOnlyClientServiceRequest(requests) {
    const clientServicePromises = [];
    const requestsMap = {};
    for (let r = 0; r < requests.length; r++) {
      const request = requests[r];
      const clientService = this.ecdUtilService.getOverrideService(request);
      if (clientService && clientService.canHandleRequest(request)) {
        requestsMap[r] = request.ApplicationName;
        clientServicePromises.push(request);
      }
    }
    return {
      clientServicePromises: clientServicePromises,
      requestsMap: requestsMap,
    };
  }

  private processClientOnlyRequests(clientRequests) {
    const response = {
      Responses: {},
    };

    return (Promise as any).mapSeries(clientRequests.clientServicePromises, (request) => {
      //.reduce((promise, func) => promise.then((result) => func().then(Array.prototype.concat.bind(result))), Promise.resolve([]))
      //.then((request) => {
        const clientService = this.ecdUtilService.getOverrideService(request);
        return clientService.request(request);
      })
      .then((results) => {
        for (let i = 0; i < results.length; i++) {
          const appName = clientRequests.requestsMap[i];
          response.Responses[appName] = results[i];
        }
        return Promise.resolve(response);
      });
  }
}
