import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

interface Personalization {
    initialized: boolean;
    data: any;
    updates: number;
    app: string;
}

@Injectable()
export class PersonalizationService {

    personalization: Personalization;

    contextAppKey: string;
    contextAppName: string;
    personalizationGroups: Map<string, any>;
    personalizeContextApp: boolean;
    personalizeContextAppFields: string[];
    debouncedAutoSavePersonalization: any;

    personalizationSavePromise: Promise<boolean>;
    personalizationSaveRunning: boolean;
    personalizationSaveNeeded: boolean;

    constructor(private apiClientService: ApiClientService) {
        this.personalizeContextAppFields = [];
        this.personalizationSaveRunning = false;
        this.personalizationSaveNeeded = false;
        this.personalizationGroups = new Map<string, any>();
    }

    initPersonalization(app: string, contextAppName: string, personalizeContextApp: boolean, personalizeContextAppFields: string[]) {

        if (_.isNil(contextAppName) || this.personalization?.initialized) return Promise.resolve(null);

        this.debouncedAutoSavePersonalization = _.debounce(this.autoSavePersonalization.bind(this), 1000, { leading: true, trailing: true });

        return this.loadPersonalizationData(app)
            .then((data) => {
                data = data || {};

                data = this.stateMigration(data);

                this.personalization = {
                    data,
                    initialized: true,
                    updates: 0,
                    app
                };
                IX_Log("personalization", "initialized personalization", this.personalization);
                this.contextAppName = contextAppName || this.contextAppName;
                if (this.contextAppName) {
                    this.contextAppKey = "context." + this.contextAppName;
                }
                this.personalizeContextApp = personalizeContextApp && !!contextAppName || this.personalizeContextApp;
                if (this.personalizeContextApp && _.isArray(personalizeContextAppFields)) {
                    this.personalizeContextAppFields = personalizeContextAppFields;
                }
                return this.personalization;
            }).catch(() => {
                this.personalization = {
                    data: {},
                    initialized: true,
                    updates: 0,
                    app
                };
                return this.personalization;
            });
    }

    loadMigrationStrategies() {
        return {
            0:  function(data) {
                return data;
            },
            1: function (data) {
                let grids = _.get(data, 'grids', {});
                const targetGrids = [
                    'ICRBCInvTypeFixIncListApp',
                    'ISRBCInvTypeFixIncListApp',
                    'PCRBCInvTypeFixIncV4ListApp',
                    'PSRBCInvTypeFixIncV4ListApp'
                ];
                _.each(grids, function (value, key) {
                    if (targetGrids.indexOf(key) >= 0) {
                        let column = _.find(_.get(value, 'GV.columns', [{}]), {dataField: 'CombinedNameSuperScript'});
                        if (!_.isNil(column)) {
                            column.visible = false;
                            IX_Log('personalization','hide extra col (CombinedNameSuperScript) for ' + key);
                        }
                    }
                });
                return data;
            },
        };
    }

    stateMigration(data) {
        data = _.isNil(data) ? {} : data;
        const migrationStrategies = this.loadMigrationStrategies();
        const currentVersion = _.get(data, 'version', 0);
        const latestVersion = _.keys(migrationStrategies).length - 1;
        for (let upgradeTo = currentVersion + 1; upgradeTo <= latestVersion; upgradeTo++) {
            IX_Log('personalization','migrating state from ' + currentVersion + ' to version ' + upgradeTo);
            data = migrationStrategies[upgradeTo](data);
            data.version = upgradeTo;
        }
        return data;
    }

    setPersonalization(key, value) {
        _.set(this.personalization.data, key, value);
        ++this.personalization.updates;
        IX_Log("personalization", "Updated personalization", key, this.personalization);
        if (this.debouncedAutoSavePersonalization) this.debouncedAutoSavePersonalization();
    }

    resetPersonalization(key) {
        _.unset(this.personalization.data, key);
        ++this.personalization.updates;
        IX_Log("personalization", "Reset personalization", key, this.personalization);
        if (this.debouncedAutoSavePersonalization) this.debouncedAutoSavePersonalization();
    }

    getPersonalization(key) {
        const value = _.get(this.personalization.data, key);
        IX_Log('personalization', 'Getting personalization', key, value);
        return value;
    }

    getAppPersonalizationState(appName: string): AppState {
        const newState = {};
        if (this.personalizeContextApp
            && this.personalizeContextAppFields
            && appName?.EqualsIgnoreCase(this.contextAppName)) {
            const appState = this.getPersonalization(this.contextAppKey);
            if (appState) this.personalizeContextAppFields.forEach((field) => newState[field] = appState[field]);
        }
        return newState;
    }

    setPersonalizationFromContext(appName: string, appState: AppState) {
        if (!this.personalizeContextApp || _.isNil(appName)
            || !appName?.EqualsIgnoreCase(this.contextAppName)) return;
        const newState = {};
        this.personalizeContextAppFields.forEach((field) => {
            if (_.isNil(appState[field])) return;
            newState[field] = appState[field];
        });
        this.setPersonalization(this.contextAppKey, newState);
    }

    updatePersonalizationGroupItem(groupName: string, itemName: string, value: any) {
        let groups = this.getPersonalization('personalizationGroups');
        if (_.isUndefined(groups)) {
            groups = {};
            this.personalization.data.personalizationGroups = groups;
        }
        const group = this.getPersonalizationGroupBag(groupName, groups);
        if (!group[itemName.toLowerCase()]) {
            IX_Log('personalization', 'Personalization group "' + groupName + '" item name "' + itemName + '" not found, creating.');
        }
        group[itemName.toLowerCase()] = value;
        IX_Log('personalization', 'Personalization group "' + groupName + '" item name "' + itemName + '" set to value "' + value + '".');
        this.setPersonalization(this.contextAppKey, groups);
    }

    getPersonalizationGroupItem(groupName: string, itemName: string) {
        const groups = this.getPersonalization('personalizationGroups');
        if (!groups[groupName.toLowerCase()]) {
            IX_Log('personalization', 'Personalization group "' + groupName + '" not found. Unable to retrieve value. Define group with screen property #List.AddToPersonalizationGroup');
            return;
        }
        const group = this.getPersonalizationGroupBag(groupName, groups);
        if (!group[itemName.toLowerCase()]) {
            IX_Log('personalization', 'Personalization group "' + groupName + '" item name "' + itemName + '" not found.');
            return;
        }
        const value = group[itemName.toLowerCase()];
        IX_Log('personalization', 'Personalization group "' + groupName + '" item name "' + itemName + '" value "' + value + '" retrieved.');
        return value;
    }

    getPersonalizationGroupBag(groupName: string, group: any) {
        if (!group[groupName.toLowerCase()])
            group[groupName.toLowerCase()] = {};
        return group[groupName.toLowerCase()];
    }

    getPersonalizationPromise() {
        return this.personalizationSavePromise;
    }

    testSetUpdates(count: number): void {
        this.personalization.updates = count;
    }

    private autoSavePersonalization(): Promise<boolean> {
        if (this.personalizationSaveRunning) {
            this.personalizationSaveNeeded = true;
            return this.personalizationSavePromise;
        }
        this.personalizationSaveNeeded = false;
        this.personalizationSaveRunning = true;
        const savePromise = this.internalSavePersonalization()
            .then(() => {
                if (this.personalizationSaveNeeded) {
                    this.personalizationSaveNeeded = false;
                    this.personalizationSaveRunning = false;
                    this.autoSavePersonalization();
                } else {
                    this.personalizationSaveRunning = false;
                }
                return true;
            })
            .catch(() => {
                if (this.personalizationSaveNeeded) {
                    this.personalizationSaveNeeded = false;
                    this.personalizationSaveRunning = false;
                    this.autoSavePersonalization();
                    return false;
                }
                this.personalizationSaveRunning = false;
                return false;
            });
        this.personalizationSavePromise = savePromise;
        return this.personalizationSavePromise;
    }

    private internalSavePersonalization(): Promise<boolean> {
        if (!this.personalization.updates) return Promise.resolve(false);
        const updates = this.personalization.updates;
        return this.savePersonalizationData(this.personalization.app, this.personalization.data)
            .then((data) => {
                IX_Log('personalization', 'saved personalization', data);
                // Only reset updates if there are no subsequent changes while save was in progress
                let message = 'personalization was modified while save';
                if (updates == this.personalization.updates) {
                    message = 'resetting updates';
                    this.personalization.updates = 0;
                }
                IX_Log('personalization', message);
                return true;
            })
            .catch(() => {
                IX_Log('personalization', 'Update failed');
                return false;
            });
    }

    private loadPersonalizationData(application) {
        const request = {
            ...this.apiClientService.createECDHeader(application, application, 'load personalization data'),
            Data: {
                Global: true
            }
        };
        return this.apiClientService.makeEcdRequest(request)
            .then((dataIn) => {
                if (dataIn.Status !== 'SUCCESS' || _.isNil(dataIn.MainMessage) || !dataIn.MainMessage.isJSON())
                    return null;
                return JSON.parse(dataIn.MainMessage);
            })
            .catch(() => null);
    }

    private resetPersonalizationData(application) {
        const ecdRequest = {
            ...this.apiClientService.createECDHeader(application, application, 'reset personalization data', '', false),
            Data: {
                Global: true
            }
        };
        return this.apiClientService.makeEcdRequest(ecdRequest);
    }

    private savePersonalizationData(application, data) {
        const ecdRequest = {
            ...this.apiClientService.createECDHeader(application, application, 'save personalization data', '', false),
            Data: {
                State: JSON.stringify(data),
                Global: true
            }
        };
        return this.apiClientService.makeEcdRequest(ecdRequest);
    }

}
