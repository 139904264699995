<div class="dx-datagrid-total-footer" style="padding-right: 0px;">
    <div class="dx-datagrid-content dx-datagrid-scroll-container">
        <table class="dx-datagrid-table dx-datagrid-table-fixed" role="presentation">
            <colgroup>
                <ng-container *ngFor="let col of gridProperties.columns">
                    <col *ngIf="col.visible || col.buttons" [ngStyle]="{ 'width': col.width }">
                    <col style="width: 100px">
                </ng-container>
            </colgroup>
            <tbody>
                <tr class="dx-row" role="row">
                    <ng-container *ngFor="let col of gridProperties.columns">
                        <td *ngIf="col.visible" role="gridcell" [ngStyle]="{ 'text-align': col.alignment}">
                            <div attr.id="container{{col.footerUniqueId}}" *ngIf="sumValues[col.dataField] !== undefined" class="dx-datagrid-summary-item dx-datagrid-text-content" [ngStyle]="{ 'text-align': col.alignment }">
                                <div class="etl-sum-error-tooltip" dx-tooltip="col.footerValidationTooltip">Total target allocation must add up to 100%</div>
                                <span attr.id="{{col.footerUniqueId}}" [ngClass]="{ 'etl-sum-error': col.showFooterValidationError }">
                                    <span [innerHTML]="sumValues[col.dataField].formattedValue()"></span>
                                    <i class="etl-sum-error-icon fa fa-exclamation-triangle" *ngIf="col.showFooterValidationError" aria-hidden="true"></i>
                                </span>
                            </div>
                        </td>
                        <td *ngIf="gridProperties.columns.slice(-1)[0].buttons" role="gridcell" [ngStyle]="{ 'width': gridProperties.columns.slice(-1)[0].width }"></td>
                    </ng-container>    
                </tr>
            </tbody>
        </table>
    </div>
</div>