// tslint:disable: forin
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiClientService } from '../api-client.service';
import { ApplicationInformation } from '../application-information.service';
import { QryAggregateProfitAndLossLiteServiceRBC } from './qryaggregateprofitandlosslite-rbc.service';
import { DomComponentRetrievalService } from '../dom-component-retrieval.service';
import { TranslateFacadeService } from '../translate-facade.service';
import { HelpersService } from '../helpers.service';
import { InputApplication } from '../../components/input-application';
import { AdvancedFilterExpressionParser } from "./advanced-filter-expression-parser";
import { GenericCalculator } from './genericcalculator';
import { RowSet } from './rowset';
import { DataSet } from './dataset';
import { ListApplication } from '../../components/list-application';
import { getClientServicesWorker } from './getClientServicesWorker';

@Injectable()
export class QryAggregateProfitAndLossLiteService {
  _qryProfitAndLossWideServiceName = 'IM.QryProfitAndLossWideService';
  _accountMapServiceName = 'Nq.AccountToAccountGroupSecureService';
  _objectPreferenceServiceName = 'Dynamic.rbc010.ObjectPreferenceService';
  _priceServiceName = 'Dynamic.rbc010.ModelFPricingService';
  _supportedGroupByFields = {
    GrpByAccount: [
      'AccountId',
      'AccountShortName',
      'AccountFriendlyName',
      'AltAccountFriendlyName',
      'AccountType',
      'Classification1',
      'Classification2',
      'Classification3',
      'Classification4',
      'AccountNumber',
      'AccountMandate',
      'TaxClassDesc',
      'AccountOpenDate',
      'AccountStatus',
      'AccountName',
      'AltSystemID1',
      'AltSystemID2',
      'AltSystemID3',
      'AltSystemID4',
      'AltSystemID5',
    ],
    GrpByAccountGroupSecure: ['AccountGroupSecureId', 'AccountGroupSecureShortName', 'AccountGroupSecureName', 'AccountGroupSecureType'],
    GrpBySecurity: ['Ticker', 'Isin', 'Sedol', 'Cusip', 'EnterpriseSymbol', 'PrimarySecuritySymbol', 'SecurityDescription', 'AssetClassLevel1', 'AssetClassLevel2', 'AssetClassLevel3', 'SecuritySectorLevel1', 'SecuritySectorLevel2', 'SecuritySectorLevel3', 'LocalCCYDescription'],
    GrpByLocalCCY: ['LocalCCY', 'LocalCCYDescription'],
    GrpByBookCCY: ['BookCCY', 'BookCCYDescription'],
    GrpByAssetClassLevel1: ['AssetClassLevel1'],
    GrpByAssetClassLevel2: ['AssetClassLevel2'],
    GrpBySecuritySectorLevel1: ['SecuritySectorLevel1'],
    GrpBySecuritySectorLevel2: ['SecuritySectorLevel2'],
    GrpByAltSecId2: ['AltSecId2'],
    GrpByAsOf: ['BeAsOf'],
    GrpByInvestmentMaturityDate: ['InvestmentMaturityDate'],
    GrpByCoupon: ['Coupon'],
    GrpBySecurityIdentifiers: ['Ticker', 'Isin', 'Sedol', 'Cusip', 'EnterpriseSymbol', 'PrimarySecuritySymbol', 'SecurityDescription'],
  };
  _mutuallyExclusiveGroupByFields = {
    // A:B - If grouped by A, do not group by any of B
    // TODO merge this into _updateGroupByFields ?
    GrpBySecurity: ['GrpBySecurityIdentifiers'],
    GrpBySecurityIdentifiers: ['GrpByAltSecId2'], // Is this MODEL_F only?
  };
  _customSortFields = {
    SortByBookUGLDesc: {
      field: 'BookUGL',
      orderFn: this.custSortByBookUGLDesc,
      index: 0,
    },
    SortByBookUGLAsc: {
      field: 'BookUGL',
      orderFn: this.custSortByBookUGLAsc,
      index: 1,
    },
    SortByField: 'SortDirection',
  };
  _groupSecureColumns = ['AccountGroupSecureName', 'AccountGroupSecureShortName', 'AccountGroupSecureType', 'AccountGroupSecureDescription'];
  _accountColumns = ['AccountShortName', 'AccountFriendlyName', 'SortOrder'];
  _superScriptColumns = ['SuperScript'];
  _factsetURLColumns = ['ConstructURL', 'FactsetURL'];
  _selectFieldsOnlyIfGrouped = ['GrpByInvestmentMaturityDate'];
  _ignoreFilterFields = {
    // Values that would pass to the server that do not filter data
    AccessRules: 1,
    BeAsOf: 1,
    BeAsOfIntraday: 1,
    EnableCashValue: 1,
    OverrideBookWithReportingValues: 1,
    ReportingCCYConversion: 1,
    ReportingToCCY: 1,
    SortDirection: 1,
    UseTranslations: 1,
    retFld: 1,
    EnableSnapshotIndexHint: 1,
    ExcludeField: 1,
    ExcludeValues: 1,
    ConstructURL: 1,
  };
  _serverFilterFields = {};
  _clientFilterFields = {
    AccountId: 1,
    PnLRecordContext: 1,
    AssetClassLevel1: 1,
    AssetClassLevel2: 1,
    AccountStatus: 1,
    Ticker: 1,
    SecuritySectorLevel1: 1,
    SecuritySectorLevel2: 1
  };
  _ignoredRepeaterFilterFields = [];
  _groupSecureFilterFields = {
    AccountGroupSecureType: 1,
    AccountGroupSecureId: 1,
  };
  _loadDefer = null;
  _qryProfitAndLossLiteData = null;
  _accountToAccountGroupSecureData = null;
  _objectPreferenceData = null;
  _priceData = null;
  _priceDataPromise = null;
  _priceFieldMap = null;
  _currencyPriceDataMap = null;
  _dynamicReplacements = null;
  _tenantSettings = {};
  _accountLookups = null;
  _deferredQueue = [];
  _factSetURLMaps = {};
  _workers = [];

  constructor(private apiClientService: ApiClientService,
    private applicationInformation: ApplicationInformation,
    private qryAggregateProfitAndLossLiteServiceRBC: QryAggregateProfitAndLossLiteServiceRBC,
    private translate: TranslateFacadeService,
    private router: Router,
    private helpersService: HelpersService,
    private domComponentRetrievalService: DomComponentRetrievalService,) {
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    ).subscribe(() => {
      this.clearPriceData();
      this.clearWorkers();
      performance.clearMarks();
      performance.clearMeasures();
    });
  }

  private clearWorkers() {
    this._workers.forEach(worker => worker.terminate());
    this._workers = [];
  }

  private returnNullIfEmpty(columnName, rowSet) {
    const val = rowSet.get(columnName);
    if (typeof val !== 'string') return val;
    return val.trim() === '' ? null : val;
  }

  getProfitAndLossLiteData() {
    return this._qryProfitAndLossLiteData.slice();
  }

  private getPriceData() {
    return this._priceData != null ? this._priceData.slice() : [];
  }

  _getAccountToAccountGroupSecureData() {
    const data = this._accountToAccountGroupSecureData.slice();
    if (data.length) {
      data[0] = data[0].map((item) => {
        return item === 'GroupId' ? 'AccountGroupSecureId' : item;
      });
    }
    return data;
  }

  clearPriceData(): void {
    this._priceData = null;
    this._priceDataPromise = null;
    this._currencyPriceDataMap = null;
  }

  clearCache(): void {
    this._loadDefer = null;
    this._qryProfitAndLossLiteData = null;
    this._accountToAccountGroupSecureData = null;
    this.clearPriceData();
  }

  prefetchData(): void {
    this.loadData(true);
  }

  loadPriceData(warmup?: boolean): Promise<any> {
    if (warmup && this._priceData != null) return Promise.resolve(this._priceData);

    if (this._priceDataPromise && !this._priceDataPromise.isFulfilled()) {
      return this._priceDataPromise;
    }

    if (!this._priceFieldMap) {
      return Promise.resolve([]);
    }

    if (this._priceData != null) {
      return Promise.resolve(this._priceData);
    }

    if (!Array.isArray(this._priceFieldMap.Currencies) || this._priceFieldMap.Currencies.length === 0) {
      return Promise.resolve([]);
    }

    const priceDataRequest = { ...this.apiClientService.createECDHeader(this._priceServiceName, this._priceServiceName, 'LST'), Data: {} };

    priceDataRequest.Data['Identifiers'] = JSON.stringify(this._priceFieldMap.Currencies);
    priceDataRequest.Data['BeAsOf'] = this._priceFieldMap.BeAsOf;

    IX_PerfStart('ClientServices', 'loadPriceData');

    this._priceDataPromise = this.apiClientService
      .makeEcdRequest(priceDataRequest)
      .then((response) => {
        this._priceData = response.ListData;

        this.createCurrencyPriceDataMap(this._priceData);

        // if (_priceData.length === 0) {
        // console.log('Unable to retrieve latest price data');
        // }

        IX_PerfEnd('ClientServices', 'loadPriceData');

        return this._priceData;
      })
      .catch(() => {
        this._priceData = null;
        return [];
      });
    return this._priceDataPromise;
  }

  private custSortByBookUGLDesc(result, columnMap) {
    const bookUGLIndex = columnMap['BookUGL'];

    const sortBy = (a, b) => {
      const valueA = a[bookUGLIndex];
      const valueB = b[bookUGLIndex];
      let res;
      if (valueB == null && valueA != null) {
        res = -1;
      } else if (valueB != null && valueA == null) {
        res = 1;
      } else if (valueA == null && valueB == null) {
        res = 0;
      } else {
        res = valueB - valueA;
      }
      return res;
    }
    result.sort(sortBy);
  }

  private custSortByBookUGLAsc(result, columnMap) {
    const bookUGLIndex = columnMap['BookUGL'];

    const sortBy = (a, b) => {
      const valueA = a[bookUGLIndex];
      const valueB = b[bookUGLIndex];
      let res;
      if (valueA == null && valueB != null) {
        res = -1;
      } else if (valueA != null && valueB == null) {
        res = 1;
      } else if (valueA == null && valueB == null) {
        res = 0;
      } else {
        res = valueA - valueB;
      }
      return res;
    }
    result.sort(sortBy);
  }

  private createResponse() {
    return {
      JSProperties: {
        ApiVersion: 'v4Client',
      },
      Status: 'SUCCESS',
    };
  }

  private createColumn(listData, columnMap, field) {
    if (listData.length === 0) return;
    if (_.isNil(columnMap[field])) {
      listData[0].push(field);
      columnMap[field] = columnMap._lastIndex;
      columnMap._lastIndex++;
    }
  }

  private convertDataSetListToObjectList(listData) {
    const dataObjectArr = [];
    let singleObject;

    // No data after filtering
    if (listData.length === 1) {
      dataObjectArr[0] = {};
      for (let j = 0; j < listData[0].length; j++) {
        dataObjectArr[0][listData[0][j]] = null;
      }
      return dataObjectArr;
    }
    for (let i = 1; i < listData.length; i++) {
      singleObject = listData[i];
      dataObjectArr[i - 1] = {};
      for (let j = 0; j < singleObject.length; j++) {
        dataObjectArr[i - 1][listData[0][j]] = singleObject[j];
      }
    }
    return dataObjectArr;
  }

  private mapAccountGroupSecureFields(targetGroupObj, rowSet) {
    for (let i = 0; i < this._groupSecureColumns.length; i++) {
      const field = this._groupSecureColumns[i];
      targetGroupObj[field] = rowSet.get(field);
    }
  }

  private createAccountAndAccountGroupSecureLookups(filterBy) {
    let accountId;
    let accountGroupSecureId;
    const accountGroupArray = this._getAccountToAccountGroupSecureData();
    const lookups = {
      AccountGroupSecureLookup: {},
      AccountLookup: {},
    };
    const columnMap = new DataSet(accountGroupArray).ColumnMap();
    for (let i = 1; i < accountGroupArray.length; i++) {
      const rowSet = new RowSet(accountGroupArray[i], columnMap);
      if (rowSet.isFilteredRow(filterBy)) {
        accountId = rowSet.get('AccountId');
        accountGroupSecureId = rowSet.get('AccountGroupSecureId');
        if (!lookups.AccountGroupSecureLookup[accountGroupSecureId]) {
          lookups.AccountGroupSecureLookup[accountGroupSecureId] = {
            AccountGroupSecureId: accountGroupSecureId,
          };
          this.mapAccountGroupSecureFields(lookups.AccountGroupSecureLookup[accountGroupSecureId], rowSet);
        }
        if (!lookups.AccountLookup[accountId]) {
          lookups.AccountLookup[accountId] = {};
        }
        lookups.AccountLookup[accountId][accountGroupSecureId] = 1;
      }
    }
    return lookups;
  }

  private getIgnoredRepeaterFields(appInfo) {
    if (appInfo.componentType == "RepeaterV4") {
      const scope = this.domComponentRetrievalService.getAppComponent(appInfo.appName) as ListApplication;
      return scope.ignoredRepeaterFilterFields && scope.ignoredRepeaterFilterFields.split(',');
    }
  }

  private getFiltersAndGroupByFields(appInfo, options) {
    let hasSortBy = false;
    const result = {
      groupBy: [],
      filterBy: {},
      sortBy: [],
      groupFilterBy: {},
      advancedFilter: null,
      hasUnsupportedFields: false,
    };
    const _excludeGroupByGroups = [];
    if (_.isNil(appInfo.serverParametersRaw)) {
      result.hasUnsupportedFields = true;
      return result;
    }

    this._ignoredRepeaterFilterFields = this.getIgnoredRepeaterFields(appInfo);

    // Exclude GrpBy if a higher order group by overrides it
    for (const field in appInfo.serverParametersRaw) {
      if (field.startsWith('GrpBy')) {
        if (Array.isArray(this._supportedGroupByFields[field])) {
          if (this._mutuallyExclusiveGroupByFields[field] && Array.isArray(this._mutuallyExclusiveGroupByFields[field])) {
            // eslint-disable-next-line prefer-spread
            _excludeGroupByGroups.push.apply(_excludeGroupByGroups, this._mutuallyExclusiveGroupByFields[field]);
          }
        }
      }
    }

    for (const field in appInfo.serverParametersRaw) {
      if (field in this._customSortFields) {
        let sortIndex = 2;
        const sort = {};
        if (typeof this._customSortFields[field] === "string") {
          let order = this._customSortFields[field];
          order = appInfo.serverParametersRaw[order] || 'DESC';
          sort['field'] = appInfo.serverParametersRaw[field];
          sort['order'] = order.toLowerCase();
        } else {
          const item = this._customSortFields[field];
          sort['field'] = item.field;
          sort['order'] = item.orderFn;
          sortIndex = item.index;
        }
        result.sortBy[sortIndex] = sort;
        hasSortBy = true;
        continue;
      }
      if (field in this._ignoreFilterFields) {
        continue;
      }
      if (field in this._serverFilterFields) {
        if (this._serverFilterFields[field] === appInfo.serverParametersRaw[field]) {
          result.hasUnsupportedFields = true;
          break;
        }
        continue;
      }
      if (field.startsWith('GrpBy')) {
        if (Array.isArray(this._supportedGroupByFields[field])) {
          if (_excludeGroupByGroups.indexOf(field) === -1) {
            for (let i = 0; i < this._supportedGroupByFields[field].length; i++) {
              const groupField = this._supportedGroupByFields[field][i];
              result.groupBy.push({
                field: groupField,
                order: +appInfo.serverParametersRaw[field] * 100 + i,
              });
            }
          }
        } else {
          result.hasUnsupportedFields = true;
          break;
        }
      } else {
        if (field in this._groupSecureFilterFields) {
          result.groupFilterBy[field] = this.translateFilter(field, appInfo.serverParametersRaw[field]);
        } else if (field in this._clientFilterFields) {
          result.filterBy[field] = this.translateFilter(field, appInfo.serverParametersRaw[field]);
        } else {
          IX_Log('clientSvc', appInfo.name, 'Not client filter', field);
        }
      }
    }
    if (!result.hasUnsupportedFields) {
      if (typeof options.Data === "string") {
        options.Data = JSON.parse(options.Data);
      }
      for (const field in options.Data) {
        if (field in this._groupSecureFilterFields) {
          result.groupFilterBy[field] = options.Data[field];
        } else if (field in this._clientFilterFields) {
          if (this._ignoredRepeaterFilterFields && this._ignoredRepeaterFilterFields.includes(field))
            continue;
          else {
            result.filterBy[field] = options.Data[field];
          }
        } else {
          IX_Log('clientSvc', appInfo.name, 'Not client filter', field);
        }
      }
      result.groupBy.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
      result.groupBy = result.groupBy.map((it) => {
        return it.field;
      });
    }
    if (!hasSortBy) {
      result.sortBy = [];
    }

    if (!_.isNil(appInfo.serverParametersRaw.ExcludeField) && !_.isNil(appInfo.serverParametersRaw.ExcludeValues)) {
      // Turn exclusion fields into advancedFilterExpression to perform client side filtering
      // ExcludeField: "AssetClassLevel2"
      // ExcludeValues: "Cash"
      const field = appInfo.serverParametersRaw.ExcludeField;
      const values = appInfo.serverParametersRaw.ExcludeValues.split(',');

      const expr = [];

      // account for existing expression already. AND exclusions with it
      if (!_.isNil(appInfo.advancedFilterExpression)) {
        expr.push(appInfo.advancedFilterExpression, ' AND ');
      }

      for (let i = 0; i < values.length; i++) {
        if (i !== 0) {
          expr.push(' AND ');
        }
        expr.push(field, '!=', "'", values[i], "'");
      }

      appInfo.advancedFilterExpression = expr.join('');
    }

    if (!_.isNil(appInfo.advancedFilterExpression)) {
      result.advancedFilter = new AdvancedFilterExpressionParser().parse(appInfo.advancedFilterExpression);
    }
    return result;
  }

  private translateFilter(field: string, value: any): string {
    const key = 'CLIENTSERVICES_' + field + '_' + value;
    const translation = this.translate.instant(key)?.toString();
    return translation === key ? value : translation;
  }

  // we need to filter profit data if we filter group secure by type
  private filterDataForGroupSecureType(listData, columnMap, groupFilterBy) {
    let groups, currentGroup, columnValue;
    const filteredData = [];
    if (!_.isEmpty(groupFilterBy)) {
      filteredData.push(_.first(listData));
      for (let i = 1; i < listData.length; i++) {
        const rowSet = new RowSet(listData[i], columnMap);
        columnValue = rowSet.get('AccountId');
        groups = this._accountLookups.AccountLookup[columnValue];
        if (!_.isNil(groups)) {
          for (const group in groups) {
            currentGroup = this._accountLookups.AccountGroupSecureLookup[group];
            const newRow = rowSet.clone();
            for (const field in currentGroup) {
              newRow.setColumn(field).withValue(currentGroup[field]);
            }
            filteredData.push(newRow.unwrap());
          }
        }
      }
      listData = filteredData;
    }
    return listData;
  }

  private populateAccountGroupSecureFieldValues(listData, fieldsInfo, columnMap) {
    this._accountLookups = this.createAccountAndAccountGroupSecureLookups(fieldsInfo.groupFilterBy);
    listData = this.filterDataForGroupSecureType(listData, columnMap, fieldsInfo.groupFilterBy);
    return listData;
  }

  createCurrencyPriceDataMap(priceData) {
    if (this._currencyPriceDataMap != null) return this._currencyPriceDataMap;
    let altSecId2, ReportingToCCY, reportingFxRateTemp, keyMap;
    const columnMap = new DataSet(priceData).ColumnMap()
    const priceMap = {};
    const idsMap = {};
    for (let i = 1; i < priceData.length; i++) {
      const rowSet = new RowSet(priceData[i], columnMap);

      rowSet.set('Identifiers', null);

      altSecId2 = rowSet.get('Identifier');
      ReportingToCCY = rowSet.get('ReportingToCCY');
      reportingFxRateTemp = rowSet.get('ReportingFXRate');
      keyMap = altSecId2;

      if (_.isNil(priceMap[keyMap])) {
        priceMap[keyMap] = [];
      }

      const currencyPriceItem = {
        ReportingToCCY: ReportingToCCY,
        ReportingFxRateTemp: reportingFxRateTemp,
        Identifier: altSecId2,
        LocalIntraDayPrice: rowSet.get('LocalIntraDayPrice'),
        LocalIntraDayFXRate: rowSet.get('LocalIntraDayFXRate'),
        DividendPerShare: this.returnNullIfEmpty('DividendPerShare', rowSet),
        AnnualDividend: this.returnNullIfEmpty('AnnualDividend', rowSet),
        AnnualYield: this.returnNullIfEmpty('AnnualYield', rowSet),
        OpenInterest: this.returnNullIfEmpty('OpenInterest', rowSet),
        Change: this.returnNullIfEmpty('Change', rowSet),
        ChangePercent: this.returnNullIfEmpty('ChangePercent', rowSet),
        PreviousClose: this.returnNullIfEmpty('PreviousClose', rowSet),
        PayableDate: this.returnNullIfEmpty('PayableDate', rowSet)?.substring(0, 10),
        ExDividendDate: this.returnNullIfEmpty('ExDividendDate', rowSet)?.substring(0, 10),
        ExpirationDate: this.returnNullIfEmpty('ExpirationDate', rowSet)?.substring(0, 10),
        LoadType: rowSet.get('LoadType'),
      };

      const idKey = keyMap + '-' + currencyPriceItem['LocalIntraDayPrice'];

      if (!_.isNil(idsMap[idKey])) continue;

      idsMap[idKey] = 1;
      priceMap[keyMap].push(currencyPriceItem);
    }
    this._currencyPriceDataMap = priceMap;
    return priceMap;
  }

  private calculateValuesAsync(appInfo, listData, fieldsInfo, priceData) {
    return new Promise((resolve, reject) => {

      const worker = this.createWorker();

      worker.addListeners('calculationResult', (name, result, metrics) => {
        if (appInfo.DataModel === 'MODEL_F') {
          IX_PerfEnd(appInfo.appName, 'calculateModelFValues', metrics);
        } else {
          IX_PerfEnd(appInfo.appName, 'calculateModelWideValues', metrics);
        }
        worker.terminate();
        resolve(result);
      });

      const lang = IX_GetCookieValue('IXCulture');
      const step = $('body').attr('data-step') || '';
      const appInfoSubset = {
        appName: appInfo.appName,
        serverParametersRaw: appInfo.serverParametersRaw,
        DataModel: appInfo.DataModel
      }

      if (appInfo.DataModel === 'MODEL_F') {
        IX_PerfStart(appInfo.appName, 'calculateModelFValues');
        worker.sendQuery('calculateModelFValues', appInfoSubset, listData, fieldsInfo, lang, this._tenantSettings, step, priceData, this._supportedGroupByFields, this._currencyPriceDataMap || {}, this._factSetURLMaps, this.qryAggregateProfitAndLossLiteServiceRBC.getSuperScriptsMap());
      } else {
        IX_PerfStart(appInfo.appName, 'calculateModelWideValues');
        worker.sendQuery('calculateModelWideValues', appInfoSubset, listData, fieldsInfo, this._tenantSettings, step);
      }
    });
  }

  private performDataTransformations(appInfo, listData, fieldsInfo) {
    let promise = null;
    const isModelF = appInfo.DataModel === 'MODEL_F';

    if (listData.length === 0) {
      promise = Promise.resolve(listData);
    } else if (isModelF) {
      promise = this.loadPriceData();
    } else {
      promise = Promise.resolve([]);
    }

    return promise
      .then(() => this.calculateValuesAsync(appInfo, listData, fieldsInfo, this.getPriceData()))
      .then(data => {
        //AA: TODO switch to return dataSet directly instead of additional mapping
        const length = data.length;
        const dataSet = new DataSet(data);

        if (Array.isArray(fieldsInfo.advancedFilter) && fieldsInfo.advancedFilter.length > 0) {
          const calculator = new GenericCalculator({});
          calculator.applyAdvancedFilter(dataSet, fieldsInfo.advancedFilter);
        }

        if (length > 0) {
          this.removeFieldsIfNotGroupedBy(dataSet, fieldsInfo);
        }

        return this.setDefaultIdColumnAndReturnRawData(dataSet);
      });
  }

  private setDefaultIdColumnAndReturnRawData(dataSet: DataSet): any[] {
    const data = dataSet.data();
    const idColumn = "Id";
    if (dataSet.hasColumn(idColumn)) return data;
    for (let i = 0; i < data.length; i++) {
      data[i].push(i === 0 ? idColumn : i);
    }
    return data;
  }

  private removeFieldsIfNotGroupedBy(dataSet, fieldsInfo) {
    // Remove fields only returned if also grouped by
    for (let i = 0; i < this._selectFieldsOnlyIfGrouped.length; i++) {
      const grpselect = this._supportedGroupByFields[this._selectFieldsOnlyIfGrouped[i]];
      for (let j = 0; j < grpselect.length; j++) {
        const field = grpselect[j];
        if (fieldsInfo.groupBy.indexOf(field) < 0) {
          dataSet.clearColumns(field);
        }
      }
    }
  }

  private returnOnlyRetFields(listData, retFld) {
    if (!retFld) return listData;

    const retFlds = retFld.split(',');
    const colIndexes = [];
    listData[0].forEach((c, index) => {
      if (retFlds.indexOf(c) > -1) {
        colIndexes.push(index);
      }
    });
    const returnData = [];
    listData.forEach((it) => {
      returnData.push(
        it.filter((a, index) => colIndexes.indexOf(index) > -1)
      );
    });

    return returnData;
  }

  createQryProfitAndLossLiteColumnsWithArray(columnNames, headerRow, columnMap) {
    let columnIndex, columnName;
    for (let i = 0; i < columnNames.length; i++) {
      columnName = columnNames[i];
      columnIndex = columnMap[columnName];
      if (_.isNil(columnIndex)) {
        columnMap[columnName] = headerRow.length;
        headerRow.push(columnName);
      }
    }
  }

  private createCurrencyPriceFieldMap(columnMap) {
    const currencyIndexMap = {};
    const currencies = [];
    const beAsOfs = [];
    for (let i = 1; i < this._qryProfitAndLossLiteData.length; i++) {
      const rowSet = new RowSet(this._qryProfitAndLossLiteData[i], columnMap);
      const localCCY = rowSet.get('LocalCCY');
      const altSecId2 = rowSet.get('AltSecId2');
      const beAsOf = rowSet.get('BeAsOf');
      if (beAsOf != null && beAsOf !== '') {
        beAsOfs.push(beAsOf);
      }
      if (localCCY == null || localCCY === '') {
        continue;
      }
      if (_.isNil(currencyIndexMap[localCCY])) {
        currencyIndexMap[localCCY] = currencies.length;
        currencies.push({
          Currency: localCCY,
          Identifiers: [],
        });
      }
      if (altSecId2 != null && altSecId2 !== '') {
        const currencyObj = currencies[currencyIndexMap[localCCY]];
        currencyObj.Identifiers.push(altSecId2);
      }
    }
    this._priceFieldMap = {
      Currencies: currencies,
      BeAsOf: _.first(beAsOfs),
    };
  }

  private performLST(defer, options) {
    options.fieldsInfo = this.getFiltersAndGroupByFields(options.appInfo, options);

    const fieldsInfo = options.fieldsInfo;
    let listData = this.getProfitAndLossLiteData();
    const columnMap = new DataSet(listData).ColumnMap()
    listData = this.populateAccountGroupSecureFieldValues(listData, fieldsInfo, columnMap);
    this.performDataTransformations(options.appInfo, listData, fieldsInfo)
      .then((listArrayResult) => {
        const data = listArrayResult.length <= 1 ? [] : listArrayResult;
        const result = { ...this.createResponse(), ListData: data };
        defer.resolve(result);
      });
  }

  private performKey(defer, options) {
    options.fieldsInfo = this.getFiltersAndGroupByFields(options.appInfo, options);

    const fieldsInfo = options.fieldsInfo;
    let listData = this.getProfitAndLossLiteData();
    const columnMap = new DataSet(listData).ColumnMap()
    listData = this.populateAccountGroupSecureFieldValues(listData, fieldsInfo, columnMap);
    fieldsInfo.groupBy = fieldsInfo.groupBy.length > 0 ? fieldsInfo.groupBy : ['AccountGroupSecureId'];
    this.performDataTransformations(options.appInfo, listData, fieldsInfo).then((listArrayResult) => {
      if (options.appInfo.serverParametersRaw.retFld) {
        listArrayResult = this.returnOnlyRetFields(listArrayResult, options.appInfo.serverParametersRaw.retFld);
      }
      const listObjectsResult = _.first(this.convertDataSetListToObjectList(listArrayResult)) || {};
      // Filter out fields that are not part requested fields for current app
      const scope = this.domComponentRetrievalService.getAppComponent(options.appInfo.appName) as InputApplication;
      const result = { ...this.createResponse(), SingletonData: null };
      if (scope && scope.model) {
        result.SingletonData = _.pick(listObjectsResult, _.keys(scope.model));
      } else {
        result.SingletonData = listObjectsResult;
      }

      defer.resolve(result);
    });
  }

  private queueRequest(serverCallType, defer, options) {
    this._deferredQueue.push({
      serverCallType: serverCallType,
      promise: defer,
      options: options,
    });
  }

  private resolveQueue() {
    while (this._deferredQueue.length) {
      const item = this._deferredQueue.shift();
      switch (item.serverCallType) {
        case 'lst':
          this.performLST(item.promise, item.options);
          break;
        case 'key':
          this.performKey(item.promise, item.options);
          break;
        default:
          item.promise.reject('Something went wrong');
          break;
      }
    }
  }

  private getEcdgRequest() {
    const request = {
      Requesters: [],
    };
    const lang = IX_GetCookieValue('IXCulture') || 'en-US';
    const flatDataRequest = { ...this.apiClientService.createECDHeader(this._qryProfitAndLossWideServiceName, this._qryProfitAndLossWideServiceName, 'LST'), Data: { UseTranslations: lang } };
    request.Requesters.push(flatDataRequest);

    const accountMapRequest = this.apiClientService.createECDHeader(this._accountMapServiceName, this._accountMapServiceName, 'LST');
    request.Requesters.push(accountMapRequest);

    const accountSortRequest = this.apiClientService.createECDHeader(this._objectPreferenceServiceName, this._objectPreferenceServiceName, 'LST');
    request.Requesters.push(accountSortRequest);

    return request;
  }

  private addQryProfitAndLossLiteAdditionalColumns() {
    const dataSet = new DataSet(this._qryProfitAndLossLiteData);
    const columnMap = dataSet.ColumnMap();
    const headerRow = dataSet.headerRow();
    if (Array.isArray(headerRow)) {
      const cols = this._accountColumns.concat(this._groupSecureColumns, this._superScriptColumns, this._factsetURLColumns);
      this.createQryProfitAndLossLiteColumnsWithArray(cols, headerRow, columnMap);
      this.createCurrencyPriceFieldMap(columnMap);
    } else {
      IX_Log('clientSvc', 'Error adding additional columns');
    }
  }

  processServerResponse(serverData) {
    let tmpPNL;
    for (const appName in serverData.Responses) {
      const response = serverData.Responses[appName];
      if (appName === this._qryProfitAndLossWideServiceName) {
        tmpPNL = response.ListData || [];
      } else if (appName === this._accountMapServiceName) {
        this._accountToAccountGroupSecureData = response.ListData || [];
      } else if (appName === this._objectPreferenceServiceName) {
        this._objectPreferenceData = response.ListData || [];
      }
    }

    // Only assign once all three datasets loaded into memory
    // pnl data being available starts queue processing in _handleCommand()
    this._qryProfitAndLossLiteData = tmpPNL;

    if (!this._qryProfitAndLossLiteData.length) {
      console.log('No PNL data returned');
    }

    this.addQryProfitAndLossLiteAdditionalColumns();

    this._qryProfitAndLossLiteData = this.qryAggregateProfitAndLossLiteServiceRBC.initPNLData(this._qryProfitAndLossLiteData, this._objectPreferenceData);

    IX_PerfEnd('ClientServices', 'loadWideData');
  }

  private getTenantSettings() {
    IX_PerfStart('ClientServices', 'getTenantSettings');
    // Using an "always there" app to populate tenant settings
    const applicationName = 'RBCLeftPanel.Input.App';
    const serverSideFieldMasks = [];
    const dynamicReplacements = [
      '{Globals:TenantSetting:LatestEODBeAsOfDateL2}',
      '{Globals:TenantSetting:RBC.SessionKeepAliveUrl}',
      '{Globals:TenantSetting:RBC.SnapQuoteEquityTypes|[InheritTenancy=true]}',
      '{Globals:TenantSetting:RBC.SnapQuoteETFTypes|[InheritTenancy=true]}',
      '{Globals:TenantSetting:RBC.SnapQuoteMutualFundTypes|[InheritTenancy=true]}',
      '{Globals:TenantSetting:RBC.SnapQuoteIgnoreTypes|[InheritTenancy=true]}',
      '{Globals:TenantSetting:RBC.SnapQuoteEquityUrl|[InheritTenancy=true]}',
      '{Globals:TenantSetting:RBC.SnapQuoteETFUrl|[InheritTenancy=true]}',
      '{Globals:TenantSetting:RBC.SnapQuoteMutualFundUrl|[InheritTenancy=true]}',
      '{Globals:TenantSetting:QryAggregateProfitAndLossLite.OverrideReportingWithLocal|[InheritTenancy=true]}',
      '{Globals:TenantSetting:QryAggregateProfitAndLossLite.ExcludeIntraDayCostUGL|[InheritTenancy=true]}',
      '{Globals:TenantSetting:QryAggregateProfitAndLossLite.CustomIntradayPricingAssetClassLevel1|[InheritTenancy=true]}',
      '{Globals:TenantSetting:QryAggregateProfitAndLossLite.CustomFactSetChangeMV|[InheritTenancy=true]}',
      '{Globals:TenantSetting:QryAggregateProfitAndLossLite.IntradayPricingAssetClassLevel2Exclude|[InheritTenancy=true]}'
    ];
    const requestData = {
      applicationName,
      dynamicReplacements,
      serverSideFieldMasks
    };
    return this.apiClientService.makeServerReplacementRequest(requestData);
  }

  private setTenantSettings(res) {
    // AA: Force this value in there so we can query a single tenant settings object
    // TODO: Refactor this to unify tenant settings management
    // Mia: Do we need to even force this value anymore? - Oct-11-2021
    if (res['{Globals:TenantSetting:CASHVALUE_INCLUDEACCRUEDINTEREST}']) // Dont accidentally override value (128328)
      res['{Globals:TenantSetting:CASHVALUE_INCLUDEACCRUEDINTEREST}'] = this.helpersService.shouldIncludeAccruedInterestCashvalue() ? 'Y' : 'N';

    this._dynamicReplacements = res;
    this._tenantSettings = res;
    this.qryAggregateProfitAndLossLiteServiceRBC.setTenantSettings(res);
    this._factSetURLMaps = this.qryAggregateProfitAndLossLiteServiceRBC.mapFactSetTypeToUrls();

    IX_PerfEnd('ClientServices', 'getTenantSettings');
  }

  private defer() {
    let resolve, reject;
    const promise = new Promise((_resolve, _reject) => {
      resolve = _resolve;
      reject = _reject;
    });
    return {
      resolve: resolve,
      reject: reject,
      promise: promise
    };
  }

  loadData(warmup = false) {
    if (warmup && this._qryProfitAndLossLiteData != null && this._accountToAccountGroupSecureData != null)
      return Promise.resolve(this._qryProfitAndLossLiteData);

    const ecdgRequest = this.getEcdgRequest();
    const ecdgDefer = this.defer();
    this._loadDefer = ecdgDefer.promise;

    IX_PerfStart('ClientServices', 'loadWideData');
    const promiseData = this.apiClientService
      .makeEcdgRequest(ecdgRequest)
      .then(serverData => this.processServerResponse(serverData))
      .catch(args => {
        IX_Log('clientSvc', 'Error retrieving qry aggregate data', args);
        this._qryProfitAndLossLiteData = [];
        this._accountToAccountGroupSecureData = [];
        this._loadDefer = null;
        IX_PerfEnd('ClientServices', 'loadWideData');
      });

    const promiseTenant = this.getTenantSettings()
      .then(this.setTenantSettings.bind(this))
      .catch(() => {
        console.log('get tenant settings failed');
        IX_PerfEnd('ClientServices', 'getTenantSettings');
      });

    return Promise.all([promiseData, promiseTenant])
      .then(() => {
        ecdgDefer.resolve();
        this.resolveQueue();
      })
      .catch(() => {
        ecdgDefer.resolve();
        this.resolveQueue();
      });
  }

  private handleCommand(serverCallType, options) {
    const defer = this.defer();
    if (this._loadDefer && !this._loadDefer.isFulfilled()) {
      this.queueRequest(serverCallType, defer, options);
    } else if (!this._loadDefer && !this._qryProfitAndLossLiteData) {
      this.queueRequest(serverCallType, defer, options);
      this.loadData();
    } else {
      this.queueRequest(serverCallType, defer, options);
      this.resolveQueue();
    }
    return defer.promise;
  }

  private getServerCallType(serverCallType) {
    if (serverCallType && serverCallType.indexOf('-') !== -1) {
      serverCallType = serverCallType.split('-');
      serverCallType = serverCallType[0];
    }
    serverCallType = String(serverCallType).toLocaleLowerCase();
    return serverCallType;
  }

  private getDataModel(appInfo) {
    return appInfo?.serverParametersRaw?.AccessRules;
  }

  private executeLocalCommand(serverCallType, options): Promise<any> {

    options.appInfo = this.applicationInformation.getAppInfo(options.ApplicationName);
    options.appInfo.DataModel = this.getDataModel(options.appInfo);
    options.fieldsInfo = this.getFiltersAndGroupByFields(options.appInfo, options); // Also do this later when we have translation ready

    let promise = null;
    switch (serverCallType) {
      case 'lst':
      case 'key':
        promise = this.handleCommand(serverCallType, options);
        break;
      default:
        console.error('client services non key or lst command', options);
      //     delete options.appInfo;
      //     delete options.fieldsInfo;
      //     promise = this.makeEcdRequest(options);
      //     break;
    }
    return promise;
  }

  canHandleRequest(options): boolean {
    const appInfo = this.applicationInformation.getAppInfo(options.ApplicationName),
      fieldsInfo = this.getFiltersAndGroupByFields(appInfo, options);
    return !fieldsInfo.hasUnsupportedFields;
  }

  request(options): Promise<any> {
    const serverCallType = this.getServerCallType(options.ServerCallType);
    return this.executeLocalCommand(serverCallType, options);
  }

  createWorker(): any {
    const worker = new QueryableWorker();
    this._workers.push(worker);
    return worker;
  }
}

function QueryableWorker() {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const instance = this;

  //Helper method needed using import.meta.url in Worker because Jest cannot handle this ESM yet.
  let worker = getClientServicesWorker();
  let listeners = {};

  this.defaultListener = function (message) { console.log(message); };

  this.postMessage = function (message) {
    worker.postMessage(message);
  };

  this.terminate = function () {
    worker?.terminate();
    worker = null;
    listeners = {};
  };

  this.addListeners = function (name, listener) {
    listeners[name] = listener;
  };

  this.removeListeners = function (name) {
    delete listeners[name];
  };

  /*
    This functions takes at least one argument, the method name we want to query.
    Then we can pass in the arguments that the method needs.
    */
  this.sendQuery = function () {
    if (arguments.length < 1) {
      throw new TypeError('QueryableWorker.sendQuery takes at least one argument');
      return;
    }
    worker.postMessage({
      // eslint-disable-next-line prefer-rest-params
      query: arguments[0],
      // eslint-disable-next-line prefer-rest-params
      queryArgs: Array.prototype.slice.call(arguments, 1),
    });
  };

  worker.onmessage = (event) => {
    if (!(event.data instanceof Object)) {
      this.defaultListener.call(instance, event.data);
      return;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (event.data.hasOwnProperty('queryListener') && event.data.hasOwnProperty('queryArgs')) {
      listeners[event.data.queryListener].apply(instance, event.data.queryArgs);
      // eslint-disable-next-line no-prototype-builtins
    } else if (event.data.hasOwnProperty('logText') && event.data.hasOwnProperty('logData')) {
      window.IX_DEBUG_SETTINGS.clientSvc.logTable(event.data.logText, event.data.logData);
      // eslint-disable-next-line no-prototype-builtins
    } else if (event.data.hasOwnProperty('iXingLogArgs')) {
      window.IX_Log.apply(instance, event.data.iXingLogArgs);
    } else {
      this.defaultListener.call(instance, event.data);
    }
  };

  worker.onerror = function (error) {
    console.error('worker error: ', error);
  }

  if (window.IX_DEBUG_SETTINGS.clientSvc.debug || window.IX_DEBUG_SETTINGS.clientSvc.verbose) {
    worker.postMessage({
      debugMode: true,
    });
  }
}
