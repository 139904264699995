import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from "./command-list-collection.service";
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { ThemeService } from '../services/theme.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class RefreshListCommand extends BaseEffectCommand {

    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private themeService: ThemeService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.refreshListCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (action: ReturnType<typeof CommandActions.refreshListCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }) => {
                const commandConfig = this.getCommandConfig(action);
                const refreshList = this.refreshList(commandConfig);
                return from(refreshList)
                    .pipe(
                        switchMap(() => this.getNextActions(commandConfig))
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route }),
        })
    );

    private evictAppCache(appName: string): void {
        const useLocalDataSource = this.themeService.getThemeProperty('UseLocalDataSource');
        if (!useLocalDataSource || _.isEmpty(appName)) return;
        const deletePolicy = {
            canBeDeleted: (key: string): boolean => key.contains(appName)
        };
        this.cacheManagerService.delete(appName, deletePolicy);
    }

    private warnAndReturn(message: string): Observable<boolean> {
        console.warn(message);
        return of(false);
    }

    private doRefreshList(commandConfig: CommandConfig): Observable<boolean> {

        const appName = _.first(commandConfig.parameters);

        // Evict cache for Refresh List if the cache is being used
        this.evictAppCache(appName);

        let message = `List app ${appName} can not be found.`;
        const applet = { name: appName };
        const component = this.domComponentRetrievalService.getDxComponentInstance(applet, appName);
        if (_.isNil(component))
            return this.warnAndReturn(message);

        component.deselectAll();
        const filterPromise = component.refresh();

        const clearClientSideFilter = this.isTruthy(commandConfig.parameters[1]);
        if (clearClientSideFilter && component.getDataSource())
            component.clearFilter();

        const executeAsync = this.shouldWaitForApplyFilterData(commandConfig.parameters);
        if (!executeAsync)
            return of(true);

        return from(filterPromise)
            .pipe(
                switchMap(() => {
                    component.getController('keyboardNavigation')?.setupFocusedView();
                    return of(true);
                })
            );
    }

    refreshList(commandConfig: CommandConfig): Observable<boolean> {

        const appName = _.first(commandConfig.parameters);

        let message = `Command not supported for unknown apps ${appName}.`;
        if (!this.applicationInformation.isV4App(appName))
            return this.warnAndReturn(message);

        message = `Command not supported for non list app ${appName}.`;
        if (this.applicationInformation.isInputApp(appName) || this.applicationInformation.isPivotedList(appName))
            return this.warnAndReturn(message);

        if (this.applicationInformation.isListApp(appName))
            return this.doRefreshList(commandConfig);

        return this.warnAndReturn(message);
    }
}
