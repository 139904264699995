import { Injectable } from "@angular/core";

@Injectable()
export class FocusService {
    cell = null;
    targetElement = null;

    clear() {
        this.cell = null;
        this.targetElement = null;
    }

    /**
     * Call with DOM element that is focus target, so that
     * focus can be restored after a focus-losing operation.
     * 
     * Every call to .storeElement() must be followed with a call
     * to .restore().
     */
    storeElement(element) {
        this.clear();

        this.targetElement = element;
    };

    /**
     * Call with grid cell that is focus target, so that
     * focus can be restored after a focus-losing operation.
     * 
     * Every call to .storeGridCell() must be followed with a call
     * to .restore().
     */
    storeGridCell(gridInstance, rowIndex, columnIndex) {
        this.clear();

        this.cell = {
            gridInstance: gridInstance,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
        };
    };

    /**
     * Call to restore focus to the prior target.
     */
    restore() {
        if (this.cell) {
            this.cell.gridInstance.focus(this.cell.gridInstance.getCellElement(this.cell.rowIndex, this.cell.columnIndex));
        } else if (this.targetElement) {
            $(this.targetElement).focus();
        }

        this.clear();
    };
}
