import _ from 'lodash';

export class ArrayHelpers {
    
    MultiColumnSort(array, columnMap, columns, orderBy, nullsLast = false) {
        return array.sort((rowA, rowB) => {
            return this.multiColumnSortRecursive(rowA, rowB, columnMap, columns, orderBy, 0, nullsLast);
        });
    };

    private multiColumnSortRecursive(rowA, rowB, columnMap, columns, orderBy, index, nullsLast) {

        const ascDirection = orderBy[index] === 'DESC' ? false : true;
        const column = columns[index];
        const columnIndex = columnMap[column];
        const valueA = rowA[columnIndex];
        const valueB = rowB[columnIndex];

        if (nullsLast) {
            if (valueA === null && valueB !== null) {
                return ascDirection ? 1 : -1;
            }
            if (valueB === null && valueA !== null) {
                return ascDirection ? -1 : 1;
            }
        } else {
            if (valueA === null && valueB !== null) {
                return ascDirection ? -1 : 1;
            }
            if (valueB === null && valueA !== null) {
                return ascDirection ? 1 : -1;
            }
        }

        if (valueA < valueB) {
            return ascDirection ? -1 : 1;
        }

        // tslint:disable-next-line: triple-equals
        if (valueA == valueB) {
            return columns.length - 1 > index ? this.multiColumnSortRecursive(rowA, rowB, columnMap, columns, orderBy, index + 1, nullsLast) : 0;
        }

        return ascDirection ? 1 : -1;
    }

}