import { Component, OnInit, ElementRef, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'ic-view-load-announcer',
  template: "<div role='alert' aria-live='assertive' aria-atomic='true' class='ic-view-load-announcer'>{{ announcementText }}</div>",
  styleUrls: ['./view-load-announcer.component.css'],
})
export class ViewLoadAnnouncerComponent implements OnInit, OnChanges {
  @Input() context: any;
  @Input() pageTitle: any;
  @Input() step: any;

  announcementText: string;
  styledElement: any;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void { }

  ngOnChanges(changes) {

    if (!this.styledElement) {
      this.init();
    }

    for (const propName in changes) {
      if (propName === 'pageTitle') {
        const pageTitle = changes['pageTitle'].currentValue;

        let announceText = null;

        // Retrieves the custom announcing text from the #Step.AnnounceText ScreenProperty
        if (this.step != null && this.context._steps[this.step] != null) announceText = this.context._steps[this.step].announceText;

        // In case of no custom announcing text the screen reader will read the screen page title.
        if (announceText == null) announceText = "Page '" + pageTitle + "' loaded.";

        this.announce(announceText);
      }
    }
  }

  init() {
    const cssObj = { color: 'white', 'font-weight': 'bold', position: 'fixed', 'z-index': -1, top: 0 };
    this.styledElement = $(this.elementRef.nativeElement).css(cssObj);
  }

  announce(message) {
    this.styledElement.attr({ tabindex: '-1' });
    this.show(message);
  }

  show(message) {
    return setTimeout(() => {
      this.announcementText = message;
      this.hide();
    }, 1500);
  }

  hide() {
    return setTimeout(() => {
      this.announcementText = '';
      this.styledElement.removeAttr('tabindex');
    }, 5000);
  }
}
