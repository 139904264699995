/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  EventEmitter,
  Output,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { DataService } from '../../services/data.service';
import { UtilService } from '../../services/util.service';
import { IcValidator } from '../../services/validation-engine/ic-validator';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { ValidationGroupService } from '../../services/validation-group.service';

interface AutocompleteOptions {
  dataAttributes?: string;
  dataSource?: DataSource;
  fieldMap?: { key: string; value: string };
  icDisplayInitialValueFromModel?: boolean;
  itemTemplate?: string;
  onClosed?: Function;
  onFocusIn?: Function;
  onFocusOut?: Function;
  onInitialized?: Function;
  onItemClick?: Function;
  onOpened?: Function;
  onPaste?: Function;
  onValueChanged?: Function;
  resolveFieldMapOnDefaultValue?: boolean;
  valueExpr?: string;
};
@Component({
  selector: 'ic-autocomplete',
  template: `<dx-autocomplete
    [(value)]="dxValue"
    [class]="cssClass"
    [placeholder]="placeholder"
    [dataSource]="dxOptions.dataSource"
    [valueExpr]="dxOptions.valueExpr"
    [itemTemplate]="dxOptions.itemTemplate"
    (onInitialized)="dxOptions.onInitialized($event)"
    (onFocusIn)="dxOptions.onFocusIn($event)"
    (onFocusOut)="dxOptions.onFocusOut($event)"
    (onOpened)="dxOptions.onOpened($event)"
    (onClosed)="dxOptions.onClosed($event)"
    (onItemClick)="dxOptions.onItemClick($event)"
    (onPaste)="dxOptions.onPaste($event)"
  ></dx-autocomplete>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  @Input() fieldName: any;
  @Input() model: any;
  @Input() config: any;
  @Input() applet: Applet;
  @Input() context: any;
  @Input() conditionalFormats: any;
  @Input() parentModel: any;
  @Input() cssClass: string;
  @Input() checkSize: any;

  @Output() updateState = new EventEmitter<Record<string, any>>();

  placeholder: any;
  translateSubscription: Subscription;
  dxOptions: AutocompleteOptions;
  dxValue: any;
  isValidationGroupValid: () => void;
  enforceSelection: boolean;
  validatorOptions: {
    validationGroup?: string;
  };
  validator: any;
  originalOnItemClick: any;

  private resetDirtyFieldsToPristine: Subscription;

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private translate: TranslateFacadeService,
    private dataService: DataService,
    private utilService: UtilService,
    private validationEngine: ValidationEngineService,
    private validationGroupService: ValidationGroupService,
    ) { }

  ngOnDestroy() {
    this.resetDirtyFieldsToPristine?.unsubscribe();
    this.translateSubscription?.unsubscribe();
  }

  ngOnChanges(changes) {
    // Setup default values, e.g. require formats to be set
    if (this.dxOptions == null) {
      this.controller();
    }

    for (const propName in changes) {
      if (propName === 'model') {
        let newVal = null;
        let oldVal = null;
        if (changes[propName].currentValue.value) {
          newVal = changes[propName].currentValue.value;
        }
        if (
          changes[propName].previousValue &&
          changes[propName].previousValue.value
        ) {
          oldVal = changes[propName].previousValue.value;
        }
        const _getValueFn = this._getGetValueFake;
        let _actualGetValueFn;
        if (this._shouldResetToDefaultValue(newVal, oldVal, this)) {
          _actualGetValueFn = this._getDefaultValue;
        } else if (
          this._shouldGetDisplayValue() ||
          this._shouldGetInitialDisplayValue()
        ) {
          _actualGetValueFn = this._getDisplayValue.bind(this);
        }

        if (_actualGetValueFn) {
          _actualGetValueFn().then(this._setDefaultValue.bind(this));
        } else {
          _getValueFn().then(this._setDefaultValue.bind(this));
        }
      }
    }
  }

  ngAfterViewInit() {
    const $element = $(this.elementRef.nativeElement);
    const options = {
      element: $element,
      target: $element.find('input'),
      appId: this.applet.rid,
      placeholder: this.placeholder,
    };

    this.updateTranslations(options);

    this.translateSubscription = this.translate.onLangChange.subscribe(() =>
      this.updateTranslations(options)
    );
  }

  controller() {
    this.dxValue = '';
    this.model.text = '';
    this.model.value = '';
    this.fieldName = this.utilService.getFieldNameFromConfig(
      this.config.autocompleteModel
    );
    this.placeholder = this.config.autocompleteModel.placeholder;
    this.enforceSelection = false;
    this.validatorOptions = {};
    this.validator = null;
    const ixIsValidationGroupValid = _.debounce(() => {
      if (!this.validatorOptions?.validationGroup) {
        return;
      }
      this.validationGroupService.publishRefreshButtons(
        this.applet.name,
        this.validatorOptions.validationGroup
      );
    }, 150);

    this.isValidationGroupValid = () => ixIsValidationGroupValid();

    let listItemsContainer = null;
    const that = this;
    const config = {
      visible: false,
      inputAttr: {
        required: '',
        id: '',
      },
      onInitialized: (e) => {
        if (this.dxOptions.dataAttributes) {
          this.utilService.decorateElementWithDataAttributes(e.element, this.dxOptions);
        }

        if (!_.isEmpty(that.validatorOptions))
          that.validator = new IcValidator(
            that.validationEngine,
            e,
            that.validatorOptions
          );
        // this.validator = validationEngine.createNewValidator(e,this.validatorOptions );
        that._removeListboxAriaActivedescendant();

        const dirtyCallback = () => {
          e.component.option('isValid', true);
          that.dxValue = '';
        };
        that.resetDirtyFieldsToPristine = that.dataService.subscribeToResetDirtyFieldsToPristine(
          that.applet.name,
          dirtyCallback
        );

        e.component.option('icSelectedFromList', false);
        e.component.option('icRunValidationOnPaste', that.config.autocompleteModel.icRunValidationOnPaste);
      },
      onOpened: (e) => {
        let width = 0;
        const $element = $(e.element);
        e.component._$list
          .find('.dx-list-item-content')
          .first()
          .find('span')
          .each(function () {
            width += $(this).width();
          });
        width = width * 1.5;
        e.component._popup.option('minWidth', width + 'px');
        e.component._popup.repaint();
        // Rearranges the DOM for readability on tablet by Talkback.
        listItemsContainer = $(
          '#' + $element.find('.dx-texteditor-input').attr('aria-owns')
        )
          .parent()
          .parent();

        if (!listItemsContainer[0].classList.contains('iXingbody')) {
          listItemsContainer.insertAfter($element);
        }

        if (
          e.component._list != null &&
          this.config.autocompleteModel.listHeight != null
        ) {
          e.component._list.option(
            'height',
            this.config.autocompleteModel.listHeight
          );
        }
      },
      onClosed: (e) => {
        if (
          listItemsContainer &&
          !listItemsContainer[0].classList.contains('iXingbody')
        ) {
          listItemsContainer.remove();
        }
      },
      onFocusIn: (e) => this._onFocusIn(e),
      onFocusOut: (e) => {
        const itemData = e.component.option('selectedItem');
        const fieldMap = this.dxOptions.fieldMap;
        if (
          this.enforceSelection &&
          (!fieldMap ||
            (fieldMap.key !== fieldMap.value && !this._isObject(itemData)))
        ) {
          this.dxValue = '';
          this.model.text = '';
          this.model.value = '';
        } else if (
          !this.enforceSelection &&
          this.config.autocompleteModel.allowFreeTextEntry &&
          !this._isObject(itemData)
        ) {
          this.dxValue = itemData;
          this.model.text = itemData;
          this.model.value = itemData;
          this._updateAppState();
          this._runValidationAndUpdateComponent(e);
        }
      },
      onValueChanged: this._onValueChanged.bind(this),
      onItemClick: (e) => {
        if (e.itemElement) e.itemElement = $(e.itemElement);
        const isSelectedFromList = !!e.itemElement.length;
        e.component.option('icSelectedFromList', isSelectedFromList);

        // Bind missing properties from dxOptions to component
        // Required for event handler
        e.component.option(this.dxOptions);
        this._onSAYTValueChanged(e, e.itemData);
      },
      onPaste: (e) => {
        e.component.option('icSelectedFromList', false);
        if (e.component.option('icRunValidationOnPaste')) {
          this._runValidationAndUpdateComponent(e);
        }
      },
    };

    delete this.config.autocompleteModel.bindingOptions;
    config.inputAttr.id = this.config.IdForInternalInput;
    if (this.config.autocompleteModel.dxValidator) {
      _.extend(
        this.validatorOptions,
        this.config.autocompleteModel.dxValidator
      );
      delete this.config.autocompleteModel.dxValidator;
      config.inputAttr.required = 'required';
    }

    if (this.config.autocompleteModel.isMulticolumn) {
      const columnHeaders = _.extend(
        [],
        this.config.autocompleteModel.displayColumnHeaders
      );
      const columns = _.extend(
        [],
        this.config.autocompleteModel.displayColumns
      );

      if (this.config.autocompleteModel.dataSource) {
        this.config.autocompleteModel.dataSource._postProcessFunc = (data) => {
          if (Array.isArray(data) && data.length) {
            const header = {
              isHeader: false,
              disabled: false,
            };

            for (let i = 0; i < columns.length; i++) {
              header[columns[i]] = columnHeaders[i];
            }

            header.isHeader = true;
            header.disabled = true;
            data.unshift(header);
          }
          return data;
        };
      }

      this.config.itemTemplate = (itemData, _itemIndex, itemElement) => {
        _.map(columns, (column) => {
          let text = itemData[column];
          if (text == null) {
            text = '';
          }
          const $cell = $('<span>').text(text);

          if (itemData.isHeader) {
            $cell.addClass(
              'ic-autocomplete-list-cell ic-autocomplete-list-header-cell'
            );
          } else {
            $cell.addClass('ic-autocomplete-list-cell');
          }

          $(itemElement).append($cell);
        });
      };

      delete this.config.autocompleteModel.displayColumnHeaders;
      delete this.config.autocompleteModel.displayColumns;
      delete this.config.autocompleteModel.isMulticolumn;
      delete this.config.autocompleteModel.itemTemplate;

      if (this.config.autocompleteModel.enforceSelection) {
        this.enforceSelection = true;
        delete this.config.autocompleteModel.enforceSelection;
      }

      if (this.config.autocompleteModel.onItemClick) {
        this.originalOnItemClick = this.config.autocompleteModel.onItemClick;
        delete this.config.autocompleteModel.onItemClick;
      }
    }

    /* Deprecated by DevExtreme 17.2
      See: https://investcloud-dev.slack.com/archives/CET1VS0DC/p1544592642009600
    */
    delete this.config.displayExpr;

    _.extend(config, this.config);
    _.extend(config, this.config.autocompleteModel);

    this.dxOptions = config;
  }

  _log() {
    // eslint-disable-next-line prefer-rest-params
    const args = Array.prototype.slice.call(arguments, 0);
    args.unshift('icAutocomplete');
    // eslint-disable-next-line
    IX_Log.apply(null, args);
  }

  _setModelValueAndGetHasChanged(itemData) {
    let newVal = null;
    let displayValue;
    let changed = false;
    if (this._isObject(this.config.fieldMap)) {
      newVal = itemData[this.config.fieldMap.key];
      displayValue = itemData[this.config.fieldMap.value];
    } else if (_.isString(this.config.fieldMap)) {
      newVal = itemData[this.config.fieldMap];
      displayValue = newVal;
    }
    changed = this.model.value !== newVal;
    this.model.value = newVal === '' ? null : newVal;
    this.model.text = displayValue;
    this.dxValue = displayValue;
    return changed;
  }

  _setModelValue(itemData) {
    const newVal = this._getNewValue(itemData, this.config.fieldMap);
    const displayValue = this._getNewDisplayValue(
      itemData,
      this.config.fieldMap
    );

    this.model.value = newVal === '' ? null : newVal;
    this.model.text = displayValue;
    this.dxValue = displayValue;
  }

  _isObject(obj: any): boolean {
    return _.isObject(obj);
  }

  _getNewValue(itemData, fieldMap) {
    let newVal = null;
    if (this._isObject(fieldMap)) {
      newVal = itemData[fieldMap.key];
    } else if (_.isString(fieldMap)) {
      newVal = itemData[fieldMap];
    }
    return newVal;
  }

  _getNewDisplayValue(itemData, fieldMap) {
    let newDisplayVal = null;
    if (this._isObject(fieldMap)) {
      newDisplayVal = itemData[fieldMap.value];
    } else if (_.isString(fieldMap)) {
      newDisplayVal = itemData[fieldMap];
    }
    return newDisplayVal;
  }

  _removeUnnecesaryAriaText(e) {
    $(e.element)
      .find('.dx-scrollview-pull-down .dx-scrollview-pull-down-text')
      .find('div')
      .attr('aria-hidden', 'true');
  }

  _onFocusIn(e) {
    this._removeUnnecesaryAriaText(e);
  }

  _removeListboxAriaActivedescendant() {
    // this is for ada compliance, removing unnecessary aria-activedescendant
    // if there's a combobox on page with aria-activedescendant and a listbox with same attribute
    // then remove that attribute from listbox
    setTimeout(() => {
      if (
        $('input[role="combobox"]').length > 0 &&
        $('div[role="listbox"]').length > 0
      ) {
        const _inputWithRoleCombobox = $('input[role="combobox"]');
        const _elWithRoleListbox = $('div[role="listbox"]');

        if (_inputWithRoleCombobox[0].hasAttribute('aria-activedescendant')) {
          _elWithRoleListbox.removeAttr('aria-activedescendant');
        }
      }
    }, 0);
  }

  _getValue() {
    return this._isObject(this.config.fieldMap)
      ? this.model.value
      : this.model.text;
  }

  _updateAppState() {
    const valToUpdate = this._getValue();

    this.updateState.emit({ field: this.fieldName, value: valToUpdate });

    if (this.config.autocompleteModel.mapValueColumnName) {
      this.updateState.emit({
        field: this.config.autocompleteModel.mapValueColumnName,
        value: this.model.value,
      });
    }
    if (this.config.autocompleteModel.mapTextColumnName) {
      this.updateState.emit({
        field: this.config.autocompleteModel.mapTextColumnName,
        value: this.model.value,
      });
    }
  }

  _setModelValueToNull() {
    this.model.value = null;
    this.model.text = null;
    this.dxValue = null;
  }

  _setModelValueToNullAndGetHasChanged() {
    const changed = this.model.value !== null;
    this._setModelValueToNull();
    return changed;
  }

  _updateAppStateToNull() {
    this.updateState.emit({ field: this.fieldName, value: null });

    if (this.config.autocompleteModel.mapValueColumnName) {
      this.updateState.emit({
        field: this.config.autocompleteModel.mapValueColumnName,
        value: null,
      });
    }

    if (this.config.autocompleteModel.mapTextColumnName) {
      this.updateState.emit({
        field: this.config.autocompleteModel.mapTextColumnName,
        value: null,
      });
    }
  }

  _onSAYTValueCleared(e) {
    if (this.config.autocompleteModel.alwaysUpdateOnSelection) {
      this._setModelValueToNull();
      this._updateAppStateToNull();
      this._updateAppStateMappingFieldsToNull();
      this._runValidationAndUpdateComponent(e);
    } else if (this._setModelValueToNullAndGetHasChanged()) {
      this._updateAppStateToNull();
      this._updateAppStateMappingFieldsToNull();
      this._runValidationAndUpdateComponent(e);
    }
  }

  _onValueChanged(e) {
    if (_.isNil(e.value)) {
      this._onSAYTValueCleared(e);
    }
  }

  _runValidationAndUpdateComponent(e) {
    setTimeout(() => {
      if (this.validator != null) this.validator.validate(this);
      this.isValidationGroupValid();
    }, 0);
    if (_.isFunction(this.originalOnItemClick)) {
      setTimeout(() => {
        this.originalOnItemClick(e);
      }, 0);
    }

    this._removeListboxAriaActivedescendant();
  }

  _updateAppStateMappingFieldsToNull() {
    if (!this.config.autocompleteModel.mappingFields) {
      return;
    }
    const mappings = this.config.autocompleteModel.mappingFields.split(',');
    _.map(mappings, (mapping) => {
      mapping = mapping.trim();
      const fields = mapping.split('=');
      const target = fields[0];
      this.updateState.emit({ field: target, value: null });
    });
  }

  _updateAppStateMappingFields(itemData) {
    if (!itemData || !this.config.autocompleteModel.mappingFields) {
      return;
    }
    const mappings = this.config.autocompleteModel.mappingFields.split(',');
    _.map(mappings, (mapping) => {
      mapping = mapping.trim();
      const fields = mapping.split('=');
      let source, target;
      if (fields.length === 1 && !!fields[0]) {
        source = fields[0].trim();
        target = fields[0].trim();
      }
      if (fields.length === 2) {
        target = fields[0].trim();
        source = fields[1].trim();
      }
      if (!source || !target) {
        return;
      }
      const value = itemData[source];
      this.updateState.emit({ field: target, value: value });
    });
  }

  _onSAYTValueChanged(e, itemData) {
    if (this.config.autocompleteModel.alwaysUpdateOnSelection) {
      this._setModelValue(itemData);
      this._updateAppState();
      this._updateAppStateMappingFields(itemData);
      this._runValidationAndUpdateComponent(e);
    } else {
      if (this._setModelValueAndGetHasChanged(itemData)) {
        this._updateAppState();
        this._updateAppStateMappingFields(itemData);
        this._runValidationAndUpdateComponent(e);
      }
    }
  }

  updateTranslations(options) {
    this.utilService.updateComponentTranslationProperties(
      options,
      this.config.translationId
    );
    this.utilService.createADALabelAttributes(options);
    if (this.config.translationId + '.placeholder' !== options.placeholder) {
      this.placeholder = options.placeholder;
    }
    this.detectChanges();
  }

  private detectChanges() {
    this.cdr.detectChanges();
  }

  _getDisplayValue() {
    const that = this;
    const ds = that.dxOptions.dataSource,
      fieldMap = that.dxOptions.fieldMap;
    let promise = Promise.resolve({
      value: that.model.value,
      displayValue: that.model.text,
    });
    if (fieldMap.key !== fieldMap.value) {
      ds.searchValue(that.model.value.toString());
      ds.searchOperation('contains');
      ds.searchExpr(fieldMap.key);
      const defer = $.Deferred();
      promise = defer.promise();

      ds.load().then((result) => {
        let value = result[result.length - 1][fieldMap.key];
        let displayValue = result[result.length - 1][fieldMap.value];

        if (that.dxOptions.icDisplayInitialValueFromModel) {
          const selection = _.find(result, (member) => {
            return member[fieldMap.key] === that.model.value;
          });
          if (selection) {
            value = that.model.value;
            displayValue = selection[fieldMap.value];
          }
        }

        defer.resolve({
          value: value,
          displayValue: displayValue,
        });
      });
    }
    return promise;
  }

  _setDefaultValue(model) {
    if (!this._isObject(model)) return;
    this.model.value = model.value;
    this.model.text = model.displayValue;
    this.dxValue = model.displayValue;
    this.detectChanges();
  }

  _getDefaultValue() {
    return Promise.resolve({
      value: null,
      displayValue: '',
    });
  }

  _shouldGetDisplayValue() {
    return (
      this.dxOptions.resolveFieldMapOnDefaultValue &&
      this.model.value !== null &&
      this.model.value === this.model.text &&
      this.dxValue !== this.model.text
    );
  }

  _shouldGetInitialDisplayValue() {
    return (
      this.dxOptions.icDisplayInitialValueFromModel &&
      !this.dxValue &&
      !_.isNil(this.model.value) &&
      this.model.value === this.model.text
    );
  }

  _shouldResetToDefaultValue(newVal, ignore?, _ignore?) {
    return _.isUndefined(newVal) || newVal === null || newVal === '';
  }

  _getGetValueFake() {
    return Promise.resolve(false);
  }
}
