/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.CollectionWidget = require('devextreme/ui/collection/ui.collection_widget.edit');

var collectionWidget = DevExpress.ui.CollectionWidget.redefine({
    _refreshActiveDescendant: function (e) {
        /* Do not call the base class. */
        /* Per Deque recommendation all aria-activedescendant attributes are being suppressed. 
           See:
           https://investcloud.visualstudio.com/Portfolio/_workitems/edit/65233
           https://investcloud.visualstudio.com/Portfolio/_workitems/edit/65239

           Should there be any future need for aria-activedescendant attributes please add 
           component level custom code.
         */
    }
});

DevExpress.registerComponent("CollectionWidget", collectionWidget);
