import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, delay, retry, switchMap, take, tap, zipAll } from 'rxjs/operators';
import Sugar from 'sugar/date';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from './command-list-collection.service';
import { BaseEffectCommand } from './base-effect.command';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { CacheManagerService } from '../services/cachemanager.service';

export type SetClassValueType = string | number | boolean;
type SetClassFieldType = 'string' | 'number' | 'date';
export type SetClassConfig = {
  appName: string;
  appState: Record<string, string | SetClassValueType>;
  fieldName: string;
  fieldType: SetClassFieldType;
  operator: string;
  operand: string;
  targetAppName: string;
  classesToAddWhenTrue: string[];
  classesToRemoveWhenTrue: string[];
  classesToAddWhenFalse: string[];
  classesToRemoveWhenFalse: string[];
};

@Injectable()
export class SetClassCommand extends BaseEffectCommand {
  constructor(
    protected actions$: Actions,
    protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
    protected commandListCollectionService: CommandListCollectionService,
    protected cacheManagerService: CacheManagerService,
    private domComponentRetrievalService: DomComponentRetrievalService
  ) {
    super();
  }

  effectCommandName$ = createEffect(() =>
    this.dataPersistence.fetch(CommandActions.setClassCommand, {
      id: (action, state) => this.getEffectFetchId(action),
      run: (action: ReturnType<typeof CommandActions.setClassCommand>,
        { [AppsFeature.APPS_FEATURE_KEY]: appsStore }) => {
        const commandConfig = this.getCommandConfig(action);
        const appState = this.getAppState(commandConfig.appName, appsStore);

        this.setClass(commandConfig, appState).pipe(take(1)).subscribe();
        return this.getNextActions(commandConfig);
      },
      onError: (route, error) => AppsActions.onCommandError({ error, route }),
    })
  );

  private setClass(commandConfig: CommandConfig, appState: AppState): Observable<any> {
    return this.setElementClass({
      appName: commandConfig.appName,
      appState,
      fieldName: commandConfig.parameters[0],
      fieldType: commandConfig.parameters[1].toLowerCase(),
      operator: commandConfig.parameters[2],
      operand: commandConfig.parameters[3],
      targetAppName: commandConfig.parameters[4],
      classesToAddWhenTrue: this.getClasses(commandConfig.parameters[5]),
      classesToRemoveWhenTrue: this.getClasses(commandConfig.parameters[6]),
      classesToAddWhenFalse: this.getClasses(commandConfig.parameters[7]),
      classesToRemoveWhenFalse: this.getClasses(commandConfig.parameters[8]),
    });
  }

  private setElementClass(config: SetClassConfig): Observable<any> {
    let sourceAppName: string;
    let sourceFieldName: string;
    if (config.fieldName.includes('.')) {
      sourceAppName = config.fieldName.substring(0, config.fieldName.lastIndexOf('.'));
      sourceFieldName = config.fieldName.substring(config.fieldName.lastIndexOf('.') + 1, config.fieldName.length);
    } else {
      sourceAppName = config.appName;
      sourceFieldName = config.fieldName;
    }

    const sourceClass = this.domComponentRetrievalService.getAppClassName(sourceAppName);
    const targetClass = this.domComponentRetrievalService.getAppClassName(config.targetAppName);

    const sourceExists = this.checkElement('.' + sourceClass);
    const targetExists = this.checkElement('.' + targetClass);

    return from([sourceExists, targetExists])
      .pipe(
        zipAll(),
        tap((elements) => {
          const target = _.last(elements);
          const targetElement = target.nodeName === 'DIV' ? target : target.getElementsByTagName('div')[0]; // Apply style to first div we get to, ic-repeater has custom node wrapper as first child
          const operandVal = this.parseType(this.parseOperand(config.operand), config.fieldType);
          const fieldVal = config.appState[sourceFieldName];
          const sourceVal = this.parseType(fieldVal, config.fieldType);

          let result: boolean;
          switch (config.operator) {
            case '<':
              result = sourceVal < operandVal;
              break;
            case '>':
              result = sourceVal > operandVal;
              break;
            case '<=':
              result = sourceVal <= operandVal;
              break;
            case '>=':
              result = sourceVal >= operandVal;
              break;
            case '!=':
              // Intentionally using loose equality
              // tslint:disable-next-line: triple-equals
              result = sourceVal != operandVal;
              break;
            case '!==':
              result = sourceVal !== operandVal;
              break;
            case '==':
              // Intentionally using loose equality
              // tslint:disable-next-line: triple-equals
              result = sourceVal == operandVal;
              break;
            case '===':
              result = sourceVal === operandVal;
              break;
          }

          const classesToAdd = result ? config.classesToAddWhenTrue : config.classesToAddWhenFalse;
          const classesToRemove = result ? config.classesToRemoveWhenTrue : config.classesToRemoveWhenFalse;

          classesToAdd.forEach((className) => className && targetElement.classList.add(className));
          classesToRemove.forEach((className) => className && targetElement.classList.remove(className));
        }),
        catchError((e) => {
          if (IX_DEBUG_SETTINGS.cmdLst.debug)
            console.log(`Set class command: could not found '${sourceClass}' '${targetClass}'`, e);
          return of(false);
        })
      );
  }

  private getClasses(parameter: string): string[] {
    return parameter?.replace(/,/gi, ' ').split(' ') || [];
  }

  private checkElement(selector: string): Observable<any> {
    return of(selector)
      .pipe(
        delay(100),
        switchMap((selector) => {
          const element = document.querySelector(selector);
          if (_.isNil(element))
            return throwError(`Missing element '${selector}'`);
          return of(element);
        }),
        retry(10)
      );
  }

  private parseOperand(input: string): string {
    switch (input) {
      case 'null':
        return null;
      case 'undefined':
        return undefined;
      default:
        return input;
    }
  }

  private parseType(value: SetClassValueType, valType: SetClassFieldType): SetClassValueType {
    if (value == null) {
      return value;
    }
    switch (valType) {
      case 'date':
        // return Unix timestamp in milliseconds
        return new Sugar.Date(value as any).format('{x}').raw;
      case 'number':
        return Number(value);
    }
    return value;
  }
}
