/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, ElementRef, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { DxTextBoxComponent, DxTextAreaComponent } from 'devextreme-angular';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { Subscription } from 'rxjs';
import { AccessibilityService } from '../../services/accessibility.service';
import { DataService } from '../../services/data.service';
import { FieldFormatService } from '../../services/field-format.service';
import { IcComponentEditor, IcValidator } from '../../services/validation-engine/ic-validator';
import { UtilService } from '../../services/util.service';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { ValidationGroupService } from '../../services/validation-group.service';

type TextBoxConfig = {
  format?: {},
  textBoxModel: {
    bindingOptions?: {},
    dxValidator?: {},
    mode?: 'password',
    name?: string,
    onValueChanged?: () => void,
    placeholder?: string,
    revealPassword?: boolean;
  },
  translationId?: string,
};

@Component({ template: '' })
export abstract class TextboxShared implements OnChanges {

  @Input() fieldName: any;
  @Input() model: any;
  @Input() config: TextBoxConfig;
  @Input() applet: Applet
  @Input() context: any;
  @Input() conditionalFormats: any;
  @Input() parentModel: any;
  @Input() cssClass: string;
  @Input() checkSize: any;

  @Output() updateState = new EventEmitter<AppStateChange>();

  translateSubscription: Subscription;
  dxComponent: DxTextBoxComponent | DxTextAreaComponent;
  dxValue = '';
  dxOptions: any;
  dxDisabled: any;
  validatorOptions: {
    validationGroup?: string,
  } = {};
  validator: any;
  placeholder: any;
  isValidationGroupValid: () => void;
  originalOnValueChanged: any;
  format = '';
  isTextArea: boolean;
  previousValueB4Pristine: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onInitialized: ($event: IcComponentEditor) => void;

  private resetDirtyFieldsToPristine: Subscription;

  constructor(
    private elementRef: ElementRef,
    private translate: TranslateFacadeService,
    private dataService: DataService,
    private fieldFormatService: FieldFormatService,
    private utilService: UtilService,
    private validationEngine: ValidationEngineService,
    private validationGroupService: ValidationGroupService,
    private $accessibilitySvc: AccessibilityService) { }

  _log(...args: any[]): void {
    if (IX_DEBUG_SETTINGS.icTextBox) {
      IX_Log.apply(null, args);
    }
  }

  ngOnChanges(changes): void {
    this.init();

    for (const propName in changes) {
      if (propName !== 'model') continue;
      if (_.isNil(this.model)) return;
      if (!_.isNil(this.model.text)) {
        if (this.model.text === this.dxValue) {
          return;
        }
      }
      const v = typeof this.model.value === 'undefined' ? '' : this.model.value;
      if (this.dxValue !== v) {
        this.dxValue = v;
      }
    }
  }

  baseAfterViewInit(): void {
    this.dxComponent.instance.option(this.dxOptions);

    const $e = $(this.elementRef.nativeElement);
    const options = {
      element: $e,
      target: $e.find('input'),
      appId: this.applet.rid,
      placeholder: this.config.textBoxModel.placeholder,
    };

    this.updateTranslations(options);

    this.translateSubscription = this.translate.onLangChange.subscribe(() => this.updateTranslations(options));

    /*
          To prevent chrome's autofill text to overlap with dx placeholder text,
          we trigger a css animation from ixTextBox.css and then listen to the event using js
          when this animation triggers we know chrome autfill is kicking in and so add a dx class to the
          placeholder html.
          */
    $e.on('animationstart', (event) => {
      if (event.originalEvent.animationName === 'onAutoFillStart') {
        $e.find('input').next('.dx-placeholder').addClass('dx-state-invisible');
      }
    });
  }

  baseOnDestroy(): void {
    this.resetDirtyFieldsToPristine?.unsubscribe();
    this.translateSubscription?.unsubscribe();
  }

  init(): void {
    // Setup default values, e.g. require formats to be set
    if (this.dxOptions == null) {
      this.controller();
    }
  }

  isPristine(): boolean {
    return this.dataService.isAppFieldPristine(this.applet.name, this.fieldName, this.model.value);
  }

  _setValue(e): boolean {
    if (e.value) {
      this.format = this.utilService.getConditionalFormatRulesFieldMask(this.format, this.conditionalFormats, this.parentModel, this.fieldName, null);

      if (this.format && !_.isEmpty(this.format)) {
        const formattedVal = this.fieldFormatService.format(this.format, e.value);
        if (formattedVal !== '$NaN' && (formattedVal !== this.model.text || formattedVal !== e.value)) {
          this.model.value = this.fieldFormatService.unformat(this.format, formattedVal);
          this.model.text = formattedVal;
        } else if (formattedVal === '$NaN' || formattedVal === 'NaN') {
          this.model.value = '';
          this.model.text = '';
        }
        this.dxValue = this.model.text;
        return true;
      }
    }
    this.model.value = e.value;
    this.model.text = e.value;
    return true;
  }

  controller(): void {

    const ixIsValidationGroupValid = _.debounce(
      () => {
        if (!this.validatorOptions?.validationGroup) {
          return;
        }
        this.validationGroupService.publishRefreshButtons(this.applet.name, this.validatorOptions.validationGroup);
      }, 150);

    this.fieldName = this.utilService.getFieldNameFromConfig(this.config.textBoxModel);
    this.placeholder = this.config.textBoxModel.placeholder;
    this.isValidationGroupValid = () => ixIsValidationGroupValid();

    this.onInitialized = $event => {
      this.resetDirtyFieldsToPristine = this.dataService.subscribeToResetDirtyFieldsToPristine(this.applet.name, () => {
        $event.component.option('isValid', true);
      });
      if (!$.isEmptyObject(this.validatorOptions)) {
        this.validator = new IcValidator(this.validationEngine, $event, this.validatorOptions);
      }
    };

    this.dxOptions = {
      visible: false,
      inputAttr: {
        name: this.config.textBoxModel.name ? this.config.textBoxModel.name : '',
        autocomplete: this.config.textBoxModel.name ? this.config.textBoxModel.name : 'off',
      },
      onValueChanged: (e) => {
        // IE11 specific check for executing filter command when textbox is cleared by keyboard
        // IE11 onValueChanged gets executed on each keypress and hence when Enter is pressed,
        // the field is considered Pristine and therefore commands filter command is not executed.
        if (e.previousValue && e.previousValue.length > 0 && !e.value && e.value.length === 0) {
          this.previousValueB4Pristine = e.previousValue;
        }

        if (this._setValue(e)) {
          if (!this.isPristine()) {
            this.validator?.validate(this);
            this.isValidationGroupValid();
          }

          if (!this.isPristine() || this.previousValueB4Pristine) {
            if (!_.isNil(this.originalOnValueChanged))
              setTimeout(() => this.originalOnValueChanged(e), 0);
          }

          this.updateState.emit({ field: this.fieldName, value: this.model.value });
        }
      },
      onContentReady: (e) => {
        if (this.config.textBoxModel.mode === 'password' && this.config.textBoxModel.revealPassword.toString() === 'true') {

          let inputBoxClone;
          //to create the reveal password icon
          const revealPassIcon = $("<span class='ic-reveal-password-icon fa fa-fw fa-eye' style='float: right; margin-top: -25px; position: relative;'></span>");
          e.element.find('.dx-texteditor-container').append(revealPassIcon);

          const inputBox = e.element.find("[type='password']");
          //click handler for revealing/hiding the password
          $(revealPassIcon).on('mousedown touchstart', () => {
            $(this).toggleClass('fa-eye fa-eye-slash');

            //to clone the input box without the handlers
            //because changing the attribute values in the original textbox hinders with dx handlers for valuechanged events
            inputBoxClone = inputBox.clone(false);

            //changing the clone type to text to reveal the password and adding it to DOM
            inputBoxClone.attr('type', 'text').insertAfter(inputBox);

            //hiding the original inputbox
            inputBox.toggle();
          });
          $(revealPassIcon).on('mouseup touchend mouseout', () => {
            //to remove the inputbox clone from the DOM
            if (inputBoxClone) {
              $(this).toggleClass('fa-eye fa-eye-slash');
              inputBoxClone.remove();
              inputBoxClone = undefined;
              //to display the original input box
              inputBox.toggle();
            }
          });
        }
      },
    };

    delete this.config.textBoxModel.bindingOptions;

    if (typeof this.config.textBoxModel.dxValidator !== 'undefined') {
      _.extend(this.validatorOptions, this.config.textBoxModel.dxValidator);
      //delete this.config.textBoxModel.dxValidator;
    }

    if (typeof this.config.textBoxModel.onValueChanged !== 'undefined') {
      this.originalOnValueChanged = this.config.textBoxModel.onValueChanged;
      delete this.config.textBoxModel.onValueChanged;
    }

    if (this.config.format != null) {
      _.extend(this.format, this.config.format);
    }
    _.extend(this.dxOptions, this.config.textBoxModel);

    if (this.model && this.model.value && this.model.value.length > 0 && this.model.text.length === 0) {
      this.model.text = this.model.value;
    }
  }

  updateTranslations(options): void {
    const translationId = this.config.translationId;
    this.utilService.updateComponentTranslationProperties(options, translationId);
    const currentPlaceholder = this.dxComponent.instance.option('placeholder');
    const currentAriaLabel = this.dxComponent.instance.option('ariaLabel');
    const updatedOptions = {
      placeholder: this.utilService.translateOrDefault(translationId + '.placeholder', currentPlaceholder),
      ariaLabel: this.utilService.translateOrDefault(translationId + '.ariaLabel', currentAriaLabel),
    };
    this.dxComponent.instance.option(updatedOptions);
    if (translationId + '.placeholder' !== options.placeholder) {
      this.placeholder = options.placeholder;
    }
    this.addAccessibilityToTextInput();
  }

  addAccessibilityToTextInput(): void {
    const applyAriaToSelector = "[role='textbox']";

    if (!this.$accessibilitySvc.addAriaLabelFromOption(this.dxComponent.instance, 'ariaLabel', applyAriaToSelector))
      return;

    const element = this.dxComponent.instance.$element();
    const $input = $(element).find(applyAriaToSelector);
    if (!this.$accessibilitySvc.addAriaLabelledBy($input, $(this.elementRef.nativeElement).parent()))
      return;

    if (!this.$accessibilitySvc.addAriaLabelFromOption(this.dxComponent.instance, 'placeholder', applyAriaToSelector))
      return;

    this.$accessibilitySvc.warnIfInvalidAriaLabel(this.dxComponent.instance, applyAriaToSelector);
  }

}
