<dx-popup
  [showTitle]="true"
  [showCloseButton]="false"
  [focusStateEnabled]="true"
  [title]="title"
  width="400"
  height="200"
  (onContentReady)="onContentReady"
  (onHiding)="onHiding"
>
    <div>
        {{ caller }} is attempting to call you.
    </div>
    <br/>
    <dx-button (click)="acceptCall" text="Accept"></dx-button>
    <dx-button (click)="declineCall" text="Decline"></dx-button>
</dx-popup>