/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');

var _dxDropDownBox = DevExpress.ui.dxDropDownBox.redefine({
    // InvestCloud change to close column chooser on scroll
    // This fixes an issue on mac where the column chooser remains open
    _canShowVirtualKeyboard: function() {
        return false
    },
});

DevExpress.registerComponent("dxDropDownBox", _dxDropDownBox);
