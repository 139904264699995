import { AppsEntity } from '../../state/apps.models';
import { BaseFieldMapper, FieldMapper } from './base-field-mapper';
import { EcdService } from '../clientservices/ecd.service';
import { UtilService } from '../util.service';
import { ApiClientService } from '../api-client.service';
import { CacheManagerService } from '../cachemanager.service';
import { PersonalizationService } from '../personalization.service';

export class DefaultApiMapper extends BaseFieldMapper implements FieldMapper {

    constructor(appsState: Record<string, AppsEntity>,
        protected utilService: UtilService,
        protected ecdService: EcdService,
        protected cacheManagerService: CacheManagerService,
        private apiClientService: ApiClientService,
        protected personalizationService: PersonalizationService) {
        super(appsState);
    }

    execute(): void {
        // not implemented
    }

    ecdRequest(appId: string, parentAppId: string, operation: string, commandPath: any, additionalApps: any): Promise<any> {

        const dataIn = {};
        const ecdContext = {};
        const parentAppName = this.removingMasterDetailRowIndexSuffix(parentAppId);
        const ctxApps = this.getRequestContextApps(appId, parentAppId, additionalApps);
        const ecdRequest = this.apiClientService.createECDHeader(appId, parentAppName, operation, commandPath, false);

        this.apiClientService.setRequestData(ctxApps[appId], dataIn);
        this.apiClientService.setECDContext(ecdContext, ctxApps);
        ecdRequest.Data = dataIn;
        this.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);
        const d = this.defer();

        this.ecdService.request(ecdRequest)
            .then((response) => {
                window.lastError = response;
                this.updateWithResponse(ecdRequest.ApplicationName, response, true);
                const result = response.SingletonData ? response.SingletonData : response;
                d.resolve(result);
            })
            .catch((error) => {
                d.reject(error);
            })
        return d.promise;
    }

    setComponentData(appName: string, tmpMupData: any): void {
        super.setComponentData(appName, tmpMupData);
    }

    private removingMasterDetailRowIndexSuffix(appName: string): string {
        return appName.split("[")[0];
    }

    private getRequestContextApps(appId: string, parentAppId: string, additionalApps: any): Record<string, AppState> {
        const ctxApps = {};
        ctxApps[appId] = this.getAppState(appId);
        if (appId !== parentAppId) {
            const parentAppName = this.removingMasterDetailRowIndexSuffix(parentAppId);
            ctxApps[parentAppName] = this.getAppState(parentAppName);
        }
        if (Array.isArray(additionalApps)) {
            additionalApps.forEach(appName => {
                ctxApps[appName] = this.getAppState(appName);
            });
        }
        if (_.isObject(additionalApps)) {
            for (const app in additionalApps) {
                ctxApps[app] = this.getAppState(app);
            }
        }
        const componentData = this.getComponentData(appId);
        if (componentData) {
            _.extend(ctxApps[appId], componentData);
        }
        return ctxApps;
    }

    private defer() {
        let resolve, reject;
        const promise = new Promise((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });
        return {
            resolve: resolve,
            reject: reject,
            promise: promise
        };
    }
}
