import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'delete-button',
  template: `<div class="dx-fileuploader-button-container dx-fileuploader-delete-button">
    <div
      class="dx-fileuploader-button dx-fileuploader-cancel-button dx-fileuploader-delete-button dx-button dx-button-normal dx-widget dx-button-has-icon"
      role="button"
      aria-label="Close"
      tabindex="0"
      (click)="onDeleteFile($event)">
      <div class="dx-button-content">
        <i class="dx-icon dx-icon-remove"></i>
      </div>
    </div>
  </div>`
})
export class FileDeleteComponent {

  @Input() fileId: any;
  @Output() updateState = new EventEmitter<any>();

  onDeleteFile(e) {
    this.updateState.emit(this.fileId);
    $(e.currentTarget).closest('.dx-fileuploader-file-container').remove();
  }
}
