import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  OnChanges,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { TextAnnouncer } from '../../commands/text-announcer.service';
import { ThemeService } from '../../services/theme.service';
import { UtilService } from '../../services/util.service';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { ValidationGroupService } from '../../services/validation-group.service';
import { IcComponentEditor, IcValidator } from '../../services/validation-engine/ic-validator';
import UtilityFunctions from '../../utility.functions';
import { DeviceService } from '../../services/device-information.service';

@Component({
  selector: 'ic-radio-group',
  templateUrl: './radiogroup.component.html',
  styleUrls: ['./radiogroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent implements OnInit, OnDestroy, OnChanges {

  @Input() fieldName: any;
  @Input() model: any;
  @Input() config: any;
  @Input() applet: Applet
  @Input() context: any;
  @Input() conditionalFormats: any;
  @Input() parentModel: any;
  @Input() cssClass: string;
  @Input() checkSize: any;

  @Output() updateState = new EventEmitter<AppStateChange>();

  isInitialized = false;
  items = [];
  isRequired = true;
  isDisabled = false;
  disableValidation = false;
  validator = null;
  forceValueChangeOnItemInit = true;
  iconFile;
  selectedValue;
  validatorOptions;
  lastFocusedElement;
  translateSubscription: Subscription;
  parentisDisabled: false;

  parentonItemClick = () => {
    console.log('radio click');
  };
  parentonKeyDown = () => {
    console.log('radio key down');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private originalOnValueChanged: (e: IcComponentEditor) => void;

  constructor(private elementRef: ElementRef<HTMLDivElement>,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateFacadeService,
    private textAnnouncer: TextAnnouncer,
    private themeService: ThemeService,
    private validationEngine: ValidationEngineService,
    private utilService: UtilService,
    private validationGroupService: ValidationGroupService,
    private deviceService: DeviceService) { }

  init(): void {
    if (!this.isInitialized) {
      this.controller();
    }
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.translateSubscription?.unsubscribe();
    if (!_.isEmpty(this.validatorOptions)) {
      this.validationEngine.removeRegisteredValidator(
        this.validatorOptions.validationGroup,
        this.validator
      );
    }
    this.config.dataSource?.off('changed', () => this._onDSChanged());
  }

  ngOnChanges(changes): void {
    this.init();
    for (const propName in changes) {
      if (!Object.prototype.hasOwnProperty.call(changes, propName)) continue;
      switch (propName) {
        case 'model':
          if (this.selectedValue !== this.model.value) {
            this.selectedValue = this.model.value;
            this.items.forEach((item) => {
              item.selected = item.value === this.selectedValue;
            });
            this.isValidationGroupValid();
          }
          break;
        case 'config':
          if (this.config.isAlwaysDisabled) {
            this.isDisabled = true;
            return;
          }
          if (_.isBoolean(this.config.isDisabled)) {
            this.isDisabled = this.config.isDisabled;
          }
          break;
      }
    }
  }

  getRadioGroupCssClasses(): Record<string, boolean> {
    const css = {
      'ic-radiogroup': true,
      'dx-radiogroup': true,
      'dx-widget': true,
      'dx-radiogroup-vertical': this.config.layout === 'vertical',
      'dx-radiogroup-horizontal': this.config.layout === 'horizontal',
      'answer-reviewed': this.config.answerReviewed,
    };

    if (this.cssClass) {
      css[this.cssClass] = true;
    }

    return css;
  }

  showWrongSelectionSupplement(item): boolean {
    const show = item.selected && !item.isCorrectAns;
    if (this.config.showReviewButton === 'true') {
      return show && this.config.answerReviewed;
    }
    return show;
  }

  private getInstanceDecorator(element: HTMLDivElement = null): IcComponentEditor {
    const option = (name: string, value: boolean): string | boolean | void => {
      const getting = typeof value === 'undefined';
      switch (name) {
        case 'value':
          return this.selectedValue;
        case 'disabled': {
          if (getting) {
            return this.config.isAlwaysDisabled || this.isDisabled;
          }

          if (this.config.isAlwaysDisabled) {
            return;
          }

          if (this.isDisabled && value === false) {
            const text = 'radio group ' + this.config.ixLabel + ' available.';
            this.textAnnouncer.announce({ text });
          }
          this.isDisabled = value;
        }
      }
    };

    return {
      component: {
        NAME: 'icRadioGroup',
        _$element: element ? $(element) : null,
        _options: this.config,
        instance: () => ({ on: _.noop }),
        focus: () => this.lastFocusedElement?.focus(),
        option,
        reset: () => this.config.componentApi.clear(),
        $element: ()=>  $(element).children(),
        element: ()=>  $(element).children().get(0)
      },
      element,
    };
  }

  selectItem(item): void {
    const hasChanges = this.model.value !== item.value;

    this.model.value = item.value;
    this.model.text = item.text;
    this.selectedValue = item.value;

    if (!this.disableValidation) {
      this.validator?.validate(this);
      this.isValidationGroupValid();
    }

    if (hasChanges || this.forceValueChangeOnItemInit) {
      setTimeout(() => {
        const instance = this.getInstanceDecorator(this.elementRef.nativeElement);
        this.config.onValueChanged(instance);
      }, 0);
    }
  }

  isChecked(): boolean {
    return this.config.componentApi.getCheckedItem() != null;
  }

  onItemClick(item): void {
    if (this.isDisabled) return;
    if (item.enabled === false) return;
    if (item.selected) return;

    this.items.forEach((current) => {
      if (current !== item) {
        current.selected = false;
        current.hasBeenSelected = false;
      }
    });

    this.selectItem(item);
    item.selected = true;
    item.hasBeenSelected = true;
    this.lastFocusedElement = document.activeElement;
    if (this.config.disableItemsOnSelection === 'true')
      this.config.componentApi.disableAll();
  }

  onKeyDown($event): void {
    switch ($event.keyCode) {
      case 39:
        $($event.currentTarget).next().focus();
        break;
      case 37:
        $($event.currentTarget).prev().focus();
        break;
    }
  }

  buildRadioGroupFromItemArray(rawItems) {
    this.items = [];
    let result = null;
    this.selectedValue = null;

    rawItems.forEach((item, index) => {
      let enabled = true;
      if (this.config.disableExpr && item[this.config.disableExpr] != null) {
        const disableExpr = item[this.config.disableExpr]
          .toString()
          .toLowerCase();
        enabled = disableExpr !== 'true' && disableExpr !== 'disabled';
      }

      const radioGroupItem = {
        elementId: UtilityFunctions.generateUUID(),
        text: item[this.config.displayExpr],
        value: item[this.config.valueExpr],
        icon: item[this.config.iconExpr],
        cssClass: {
          'dx-item': true,
          'dx-radiobutton': true,
        },
        setSize: rawItems.length,
        setPosition: index + 1,
        enabled: enabled,
        selected: false,
        isCorrectAns: item.isCorrectAns === 'T',
        hasBeenSelected: false,
        tooltip: null,
      };

      if (item[this.config.classExpr]) {
        radioGroupItem.cssClass[item[this.config.classExpr]] = true;
      }

      if (this.config.disabledText) {
        radioGroupItem.tooltip = {
          onInitialized: (e) => {
            // dev extreme won't show the tooltip on a targeted element that has disabled markings. manually register to hover
            setTimeout(() => {
              $('#' + radioGroupItem.elementId).hover(
                () => {
                  if (!radioGroupItem.enabled || this.isDisabled) {
                    e.component.show($('#' + radioGroupItem.elementId));
                  }
                },
                () => e.component.hide()
              );
            }, 50);
          },
        };
      }

      if (this.model.value === radioGroupItem.value) {
        radioGroupItem.selected = true;
        this.selectedValue = this.model.value;
        result = radioGroupItem;
      }

      this.items.push(radioGroupItem);
    });

    return {
      selectedItem: result,
    };
  }

  _onDSChanged(): void {
    if (this.config.dataSource == null) return;
    const items = this.config.dataSource.items();
    const info = this.buildRadioGroupFromItemArray(items);
    if (info.selectedItem != null) {
      this.forceValueChangeOnItemInit = false;
      this.selectItem(info.selectedItem);
    }
    this.detectChanges();
  }

  controller(): void {

    this.isInitialized = true;
    this.isDisabled = this.config.isAlwaysDisabled ? true : this.config.isDisabled;
    this.fieldName = this.utilService.getFieldNameFromConfig(this.config);
    this.iconFile = this.themeService.getDefaultRadioButtonInfoIcon();

    const config = {
      visible: false,
      elementAttr: {},
      itemTemplate: null,
      isDisabled: this.isDisabled,
      componentApi: {
        disableValidation: (value) => this.disableValidation = value,
        validate: () => {
          this.validator?.validate(this);
          this.isValidationGroupValid();
        },
        disableAll: () => this.items.forEach((item) => item.enabled = false),
        enableAll: () => this.items.forEach((item) => item.enabled = true),
        getCheckedItem: () => _.find(this.items, (item) => {
          if (item.selected === true) return item;
        }),
        clear: () => this.items.forEach((item) => {
          item.selected = false;
          this.model.value = null;
          this.model.text = '';
        }),
        required: (value, ...args) => {
          if (args.length === 0) return this.isRequired;
          if (!_.isBoolean(value)) throw new Error("Invalid value for method 'required'.");
          this.isRequired = value;
        },
      },
      onInitialized: (e) => { },
      onContentReady: (e) => {
        // The following allows for swipe navigation on Talkback and VoiceOver. 
        if (this.deviceService.IX_isAndroid() || this.deviceService.IX_isIOS()) {
          e.element.find('[role=radio]').attr('tabindex', '0');
        }
      },
      onValueChanged: _.debounce((e) => {
        this.forceValueChangeOnItemInit = false;
        this.validator?.validate(this);
        this.isValidationGroupValid();

        this.updateState.emit({ field: this.fieldName, value: this.model.value });

        if (this.originalOnValueChanged) {
          setTimeout(() => this.originalOnValueChanged(e), 0);
        }
      }, 100),
    };

    if (this.config.dxValidator) {
      this.validatorOptions = this.config.dxValidator;
      delete this.config.dxValidator;
      config.elementAttr['aria-required'] = true;
    }

    if (this.config.onValueChanged) {
      this.originalOnValueChanged = this.config.onValueChanged;
      delete this.config.onValueChanged;
    }

    // Cannot have an item template when the data is coming from the database as it is not possible to determine the help text field
    if (this.config.valueExpr && this.config.valueExpr !== '') {
      delete config.itemTemplate;
    }

    this.config = _.extend({}, config, this.config);

    if (!_.isEmpty(this.validatorOptions)) {
      this.validator = new IcValidator(
        this.validationEngine,
        this.getInstanceDecorator(this.elementRef.nativeElement),
        this.validatorOptions
      );
    }
    this.isValidationGroupValid();

    this.config.dataSource?.on('changed', () => this._onDSChanged());
    this.config.dataSource?.load();

    if (this.config.items) {
      this.buildRadioGroupFromItemArray(this.config.items);
    }

    const _updateTranslations = (options) => {
      this.utilService.updateComponentTranslationProperties(options, this.config.translationId);
      this.utilService.createADALabelAttributes(options);
      this.detectChanges();
    };

    const $e = $(this.elementRef.nativeElement);
    const $ps = this.utilService.getAppContext(this);
    const _options = {
      element: $e,
      target: $e,
      appId: $ps.applet.rid,
    };

    _updateTranslations(_options);

    this.translateSubscription = this.translate.onLangChange.subscribe(() => _updateTranslations(_options));
  }

  private isValidationGroupValid(): void {
    if (!this.validatorOptions?.validationGroup) {
      return;
    }
    this.validationGroupService.publishRefreshButtons(this.applet.name, this.validatorOptions.validationGroup);
  }

  private detectChanges(): void {
    this.changeDetectorRef.detectChanges();
  }
}
