import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { AppEvent } from '../state/app-events.enum';
import { CanvasLayoutRenderService } from '../services/canvas-layout-render.service';
import { DeviceService } from '../services/device-information.service';
import { ThemeService } from '../services/theme.service';
import { AppsConstantsFacade } from '../services/apps-constants.facade';
import { ComponentService } from '../services/component.service';

@Component({
    selector: 'ic-canvas-natural',
    host: { '[class]': 'cssClass' },
    template: `<br />`
})
export class CanvasNaturalComponent implements OnInit, OnDestroy {

    @Input() canvas;
    @Input() context;
    @Input() config;

    breakPoint: string;
    cssClass: string;
    windowResize$: Subscription;
    appletLookup: Record<string, any>;
    windowWidth: number;

    constructor(private elementRef: ElementRef,
        private canvasLayoutRenderService: CanvasLayoutRenderService,
        private deviceService: DeviceService,
        private themeService: ThemeService,
        private appsConstantsFacade: AppsConstantsFacade,
        private componentService: ComponentService) {
    }

    ngOnDestroy(): void {
        this.canvasLayoutRenderService.cleanApplicationRef(this.canvas.id);
        this.windowResize$?.unsubscribe();
    }

    ngOnInit(): void {
        this.windowWidth = this.themeService.getCanvasResponsiveWith();
        this.canvas.layoutCommands = [];
        this.appletLookup = _.keyBy(this.canvas.applets, 'id');
        this.canvas.config.onResize = this.onResponsiveResize.bind(this);
        if (this.canvas.config.layout == 'responsive') this.breakPoint = this.onResponsiveResize(this.canvas);
        this.reRenderCanvas();
        this.windowResize$ = fromEvent(window, 'resize')
            .pipe(
                throttleTime(500, undefined, { trailing: true }),
                tap(() => {
                    const width = this.themeService.getCanvasResponsiveWith();
                    if (this.windowWidth !== width && this.canvasReRenderOnResponsiveResize()) {
                        this.componentService.cleanApplicationPopupRef();
                        this.reRenderCanvas();
                    }
                    this.windowWidth = width;
                })
            ).subscribe();
    }

    private publishOnLoginEvent() {
        if (IX_GetCookieValue('IXAuthComplete')) {
            IX_DeleteCookie('IXAuthComplete');
            const eventEntity = { 'Parameter1': 'Yes' };
            this.appsConstantsFacade.publishAppEvent(AppEvent.OnLogin, eventEntity);
        }
    }

    private reRenderCanvas() {
        this.cssClass = 'natural-canvas clearfix canvas-' + this.canvas.id.replace(/\./g, '').toLowerCase();
        const containerNode = this.elementRef.nativeElement;
        this.canvasLayoutRenderService.removeChild(containerNode, containerNode.firstChild);
        this.canvasLayoutRenderService.cleanApplicationRef(this.canvas.id);
        this.renderCanvas();
        this.publishOnLoginEvent();
    }

    private canvasReRenderOnResponsiveResize() {
        const layout = this.canvas.config.layout;
        if ('responsive' == layout) {
            const breakPoint = this.onResponsiveResize(this.canvas);
            if (breakPoint != this.breakPoint) {
                this.breakPoint = breakPoint;
                return true;
            }
        }
        return false;
    }

    private getLayoutCommands(): LayoutCommand[] {
        let commands: LayoutCommand[];
        const layout = this.canvas.config.layout;
        if ('responsive' == layout) {
            this.appsConstantsFacade.setBreakPoint(this.breakPoint);
            this.canvas.config.layoutSize = this.breakPoint;
            commands = this.canvas.layout[layout][this.breakPoint];
        } else if ('natural' == layout) {
            commands = Array.from(this.canvas.layout[layout]);
            commands.unshift({ cmd: "colStart" } as LayoutCommand);
            commands.push({ cmd: "colEnd" } as LayoutCommand);
        } else {
            commands = this.canvas.layout[layout]["All"];
        }
        return commands;
    }

    private renderCanvas(): void {
        this.canvas.layoutCommands = this.getLayoutCommands();
        const canvasNode = this.canvasLayoutRenderService.render(this.canvas.id, this.canvas.layoutCommands, this.appletLookup, this.context);
        this.canvasLayoutRenderService.appendChild(this.elementRef.nativeElement, canvasNode);
    }

    private onResponsiveResize(canvas: any): string {
        const breakPoints = this.themeService.getResponsiveBreakpoints();
        let width = this.themeService.getCanvasResponsiveWith();
        if (this.deviceService.isAndroidTablet()) width = this.deviceService.getAndroidTabletScreenWidth();
        // Do special handling for ios to use screen width/height to account for orientation change
        if (this.deviceService.isIOS()) width = this.deviceService.getIOSScreenWidth();
        canvas = canvas || this.canvas;
        // Ignore onResize event if canvas, $scope.canvas and $scope.shellCanvas are all undefined.
        if (!canvas) return;
        const responsiveConfig = canvas.layout.responsive || {};
        let breakPoint = 'ExtraSmall';
        if (width >= breakPoints['ExtraLarge'] && !_.isNil(responsiveConfig['ExtraLarge'])) {
            breakPoint = 'ExtraLarge';
        } else if (width >= breakPoints['Large'] && !_.isNil(responsiveConfig['Large'])) {
            breakPoint = 'Large';
        } else if (width >= breakPoints['Medium'] && !_.isNil(responsiveConfig['Medium'])) {
            breakPoint = 'Medium';
        } else if (width >= breakPoints['Small'] && !_.isNil(responsiveConfig['Small'])) {
            breakPoint = 'Small';
        }
        // Adjustment for some tablets with low DPI getting large or higher breakpoint which is undesirable.
        if (width >= breakPoints['Large']
            && this.deviceService.isLandscapeTablet()
            && !_.isNil(responsiveConfig['Medium'])) {
            breakPoint = 'Medium';
        }
        if (window['IX_DBG_TraceResponsiveness']) {
            console.log('onResponsiveResize w = ' + width + ', size = ' + breakPoint);
        }
        return breakPoint;
    }
}
