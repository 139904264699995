/* eslint-disable */

var True = true;
var False = false;

if (typeof System == 'undefined')
    window.System = {};

if (typeof System.DateTime == 'undefined')
    System.DateTime = {};

if (typeof System.DateTime.MinValue == 'undefined')
    System.DateTime.MinValue = new Date(-8640000000000000);

var string = {
    Compare: function () {
        if (arguments.length < 2) return 0;
        if (arguments.length == 2 || (arguments.length == 3 && arguments[2] === false))
            return arguments[0] === arguments[1] ? 0 : 1;
        if (arguments.length == 3 && arguments[2] === true) {
            var a = typeof arguments[0] == 'undefined' ? void (0) : arguments[0].toString().toLowerCase();
            var b = typeof arguments[1] == 'undefined' ? void (0) : arguments[1].toString().toLowerCase();
            return a === b ? 0 : 1;
        }
        return 1;
    },
    IsNullOrEmpty: function (s) {
        if (s == void (0)) return true;
        var t = s.toString();
        return t == void (0) ? true : t === "";
    },
    IsNullOrWhiteSpace: function (s) {
        if (s == void (0)) return true;
        var t = s.toString().trim();
        return t == void (0) ? true : t === "";
    },
    IsJSON: function (obj) {
        if (_.isString(obj)) {
            try {
                var isJSON = JSON.parse(obj);
                // this is for null, as "null" is a valid JSON and type of null is object
                // but "null" is itself falsey
                // if isJSON is null then if( isJSON && ... ) will be false
                if (isJSON && typeof isJSON === "object") {
                    // everything goes fine, return true
                    return true;
                }
                return false;
            } catch (e) {
                return false;
            }
        }
        // "obj" is not string, so first,
        // JSON.stringify() it, then parse
        var jsonString = JSON.stringify(obj);
        try {
            var checkJSON = JSON.parse(jsonString);
            // this is for null, as "null" is a valid JSON and type of null is object
            // but "null" is itself falsey
            // if isJSON is null then if( isJSON && ... ) will be false
            if (checkJSON && typeof checkJSON === "object") {
                // everything goes fine, return true
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    },
};

String.prototype.normalize = function () {
    if (this == void (0)) return this;
    return this.replace(/\s/g, '').replace(/\./g, '').replace(/_/g, '').toLowerCase();
}

String.prototype.Equals = function () {
    if (arguments.length < 1) return false;
    var other = arguments[0].toString();
    return other === this.toString();
}

String.prototype.EqualsIgnoreCase = function () {
    if (arguments.length < 1) return false;
    var other = arguments[0];
    if (typeof other == 'undefined') return false;
    return this.toString().toUpperCase() === other.toString().toUpperCase();
}

String.prototype.ToString = function () {
    return this.toString();
}

String.prototype.ToLower = function () {
    return this.toLowerCase();
}

String.prototype.EndsWith = function () {
    return this.toString().endsWith(arguments[0]);
}

String.prototype.EndsWith = function () {
    return this.toString().endsWith(arguments[0]);
}

String.prototype.isBlank = function () {
    return /^\s*$/.test(this);
}

String.prototype.isJSON = function () {
    return string.IsJSON(this);
}

if (!String.prototype.contains) {
    String.prototype.contains = String.prototype.includes;
}

String.prototype.toiXingName = function () {
    if (this == void (0)) return this;
    return this.replace(/\./g, '').replace(/\_/g, '').toLowerCase();
};

String.prototype.toPopUpName = function () {
    if (this == void (0)) return this;
    return this.replace(/\./g, '_');
};

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

String.prototype.StartsWith = String.prototype.startsWith;

if (!String.prototype.regexIndexOf) {
    String.prototype.regexIndexOf = function (regex, startPos) {
        var indexOf = this.substring(startPos || 0).search(regex);
        return (indexOf >= 0) ? (indexOf + (startPos || 0)) : indexOf;
    };
}

function IX_ConditionalFormatExecuteRules(target, conditionalFormats, applicationObject, callback, filterBy, rowIndex, runAllRules) {
    if (!conditionalFormats) return;
    var ruleSet, result, i, field;
    for (field in conditionalFormats.fieldToRuleSet) {
        if (filterBy == void (0) || filterBy === field) {
            ruleSet = conditionalFormats.fieldToRuleSet[field];
            for (i = 0; i < conditionalFormats.ruleSets[ruleSet.rule].length; i++) {
                var rule = conditionalFormats.ruleSets[ruleSet.rule][i];
                var ruleFunction = rule.functionName == void (0) ? rule.condition : window[rule.functionName];
                result = ruleFunction.call(applicationObject);
                if (result) {
                    callback(target, field, rule, ruleSet.row, rowIndex);
                    if (!runAllRules) break;
                } else {
                    // Remove previously applied classes
                    // Individual styles must be overwritten by each rule
                    IX_UnApplyConditionalFormatCssClass(target, field, rule);
                }
            }
        }
    }
}

function IX_ConditionalRedirectExecuteRules(conditions, applicationObject) {
    return new Promise(function (resolve, reject) {
        for (var field in conditions.fieldToRuleSet) {
            for (var i = 0; i < conditions.ruleSets[field].length; i++) {
                var rule = conditions.ruleSets[field][i];
                var ruleFunction = rule.functionName == void (0) ? rule.condition : window[rule.functionName];
                var result = ruleFunction.call(applicationObject);
                if (result) {
                    resolve(rule.fieldMapIndex);
                    break;
                }
            }
        }
        resolve(-1);
    });
}

function IX_Update_Disabled_State_For_Validation_Group_Buttons(s, engine, rules, validationGroup, callback, trackDirtyState, isValid) {
    // this is an issue related to app for a pop up not using canvas to render content
    var appName = s.applet.name;
    var appSelector = s.applet.rid != void (0) ? ("#" + s.applet.rid) : ("." + iXing.Helpers.getAppClassName(appName));

    var isValid = _.isBoolean(isValid) ? isValid : true;

    for (var r in rules.ruleSets) {
        var rule = rules.ruleSets[r][0]; //TODO: add support for more rules
        rule.runCondition(s, engine, validationGroup)
            .then(function (result) {
                callback(rule.actionConfig, validationGroup, !result || !isValid, appSelector, appName, trackDirtyState);
            });
    }
}

if (typeof module != 'undefined') {
    module.exports = {
        True: True,
        False: False,
        string: string,
        IX_ConditionalFormatExecuteRules: IX_ConditionalFormatExecuteRules,
        IX_ConditionalRedirectExecuteRules: IX_ConditionalRedirectExecuteRules,
        IX_Update_Disabled_State_For_Validation_Group_Buttons: IX_Update_Disabled_State_For_Validation_Group_Buttons
    };
}