/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxMenu = require('devextreme/ui/menu');

var DX_MENU_CLASS = "dx-menu",
  DX_MENU_ITEM_CLASS = DX_MENU_CLASS + "-item",
  DX_MENU_ITEM_EXPANDED_CLASS = DX_MENU_ITEM_CLASS + "-expanded",
  PREVITEM_OPERATION = "prevItem";

var _dxMenu = DevExpress.ui.dxMenu.redefine({
  _refreshActiveDescendant: function () {
    this.setAria("activedescendant", "");
    //setting the activedescendant id value randomly without the existing refId is not ADA compliant
    //also, not setting the the activedescendant is not an option as that hinders the screen readers from reading the menu items
    if ($("#" + this.getFocusedItemId()).length > 0) {
      this.setAria("activedescendant", this.getFocusedItemId());
    }
  },
  _moveMainMenuFocus: function (direction) {
    var $items = this._getAvailableItems(),
      itemCount = $items.length,
      $currentItem = $items.filter("." + DX_MENU_ITEM_EXPANDED_CLASS).eq(0),
      itemIndex = $items.index($currentItem);
    this._hideSubmenu(this._visibleSubmenu);
    itemIndex += direction === PREVITEM_OPERATION ? -1 : 1;
    if (itemIndex >= itemCount || itemIndex < 0) {
      itemIndex = 0; // give parent node the focus
    }
    var isSubMenuItem = this._getSubmenuByElement($items.eq(itemIndex)) === 0;
    itemIndex = isSubMenuItem ? 0 : itemIndex;
    if ($currentItem.length > 0) {
      var $newItem = $items.eq(itemIndex);
      $($newItem).removeAttr('aria-expanded');
      this.option("focusedElement", DevExpress.utils.dom.getPublicElement($newItem));
    }
  },
  _showSubmenu: function ($itemElement) {
    var submenu = this._getSubmenuByElement($itemElement);
    if (this._visibleSubmenu !== submenu) {
      this._hideVisibleSubmenu()
    }
    if (submenu) {
      var overlayContent = submenu._overlay.content();
      if (overlayContent && overlayContent.length > 0 && overlayContent[0].hasAttribute('aria-activedescendant')) {
        overlayContent.removeAttr('aria-activedescendant');
      }
      submenu.show();
      $($itemElement).attr('aria-expanded', 'true');
      // if none focused default to first itemIndex
      var focusedElement = submenu.option("focusedElement");
      focusedElement = focusedElement ? focusedElement : $($itemElement).find('div.dx-item.dx-menu-item.dx-menu-item-has-text')[0];
      this.option("focusedElement", focusedElement);
    }
    this._visibleSubmenu = submenu;
    this._hoveredRootItem = $itemElement
  }
});

DevExpress.registerComponent("dxMenu", _dxMenu);
