import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { BaseEffectCommand } from './base-effect.command';
import { CacheManagerService } from '../services/cachemanager.service';
import { CommandListCollectionService } from "./command-list-collection.service";

@Injectable()
export class CustomBrowserCodeCommand extends BaseEffectCommand {

    constructor(protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.customBrowserCodeCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.eventPublishCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const functionName = commandConfig.parameters[0];
                const result = this.callCustomBrowserCode(appsStore.workflow, appsStore.themeName, functionName);
                // CBC require an explicit "false" return from CBC to end command list, 
                // otherwise continue with next action
                if (result === false) {
                    this.completeCommandList(commandConfig.commandListName, commandConfig.executionId);
                    return EMPTY;
                }
                return this.getNextActions(commandConfig, result);
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route })
        })
    );

    private callCustomBrowserCode(workflowName: string, themeName: string, fnName: string): boolean | any {
        try {
            const customBrowserFunction = this.getWindowFunction(workflowName, themeName, fnName);
            if (_.isFunction(customBrowserFunction)) {
                return customBrowserFunction({}, undefined);
            }
            console.error('Custom browser code "' + fnName + '" not found');
            return false;
        } catch (e) {
            if (IX_DEBUG_SETTINGS.cmdLst.debug)
                console.error('Custom browser code "' + fnName + '" error:', e);
        }
    }
}
