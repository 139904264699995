export const SET_FILTERS = "@ic/apps/set-filters";
export const RESET_STATE = "@ic/apps/reset-state";
export const UPDATE_STATE = "@ic/apps/update-state";
export const UPDATE_APP_STATE = '@ic/apps/update-app-state';
export const LOAD_SUCCESS = "@ic/apps/load-success";
export const LOAD_LIST_DATA_SUCCESS = "@ic/apps/load-list-data-success";
export const LOAD_LIST_DATA_ERROR = "@ic/apps/load-list-data-error";
export const LOAD_LIST_DATA_ONEVENTONLY_BYPASS = "@ic/apps/load-list-data-oneventonly-bypass";
export const REPLACE_LIST_DATA = "@ic/apps/update-list-data";
export const MUP_LIST_DATA_SUCCESS = "@ic/apps/mup-list-data-success";
