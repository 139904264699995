/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { AppEvent } from '../state/app-events.enum';
import { ValidationGroupEvent } from '../state/app-events.types';
import { EventsService } from './events.service';
import { ValidationEngineService } from './validation-engine.service';

type ValidationGroup = {
    hasButtons: boolean,
    hasComponents: boolean,
    hasFields: boolean,
    validationGroup: string,
};

type ValidationRule = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    runCondition: ($scope: any, groupValidation) => Promise<boolean>,
    actionConfig: string[],
};

type ValidationRules = {
    ruleSets: {
        ValidateForm: ValidationRule[],
    },
};

@Injectable()
export class ValidationGroupService {

    private validationGroupMap: Record<string, ValidationGroup>;

    constructor(private eventsService: EventsService,
        private validationEngineService: ValidationEngineService,
        private router: Router) {
        this.validationGroupMap = {};
        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationEnd),
                tap(() => this.validationGroupMap = {})
            ).subscribe();
    }

    isValidationGroupValid(groupName: string): boolean {
        if (!_.isString(groupName) || _.isEmpty(this.validationGroupMap)) return false;
        groupName = groupName.toLowerCase();
        const groupFlags = this.validationGroupMap[groupName];
        return !_.isNil(groupFlags) && groupFlags.hasButtons
            && (groupFlags.hasFields || groupFlags.hasComponents);
    }

    getValidationGroupMap(applets: Array<Record<string, any>>): Record<string, ValidationGroup> {
        if (!_.isArray(applets) || !_.isEmpty(this.validationGroupMap)) return this.validationGroupMap;
        applets.forEach((applet) => {
            if (!_.isArray(applet.validationGroups)) return;
            applet.validationGroups.forEach((group: ValidationGroup) => {
                const groupName = group.validationGroup.toLowerCase();
                if (_.isNil(this.validationGroupMap[groupName])) {
                    this.validationGroupMap[groupName] = group;
                } else {
                    this.validationGroupMap[groupName].hasFields = this.validationGroupMap[groupName].hasFields || group.hasFields;
                    this.validationGroupMap[groupName].hasButtons = this.validationGroupMap[groupName].hasButtons || group.hasButtons;
                    this.validationGroupMap[groupName].hasComponents = this.validationGroupMap[groupName].hasComponents || group.hasComponents;
                }
                // Do not delete this as it modifies the original object, 
                // and cause issues on router navigation
                //delete group.validationGroup;
            });
        });
        return this.validationGroupMap;
    }

    publishRefreshButtons(appName: string, validationGroup: string, isValid?: boolean): void {
        const eventState = { appName, validationGroup, isValid };
        this.publishEvent(AppEvent.RefreshValidationGroupButtons, eventState);
    }

    updateButtonDisabledState($scope: any, event: ValidationGroupEvent): void {
        const isValid = _.isBoolean(event.state.isValid) ? event.state.isValid : true;
        const validationGroup = event.state.validationGroup;
        const validationEvent = {
            id: event.id,
            state: { ...event.state }
        };
        validationEvent.state.buttonAppName = $scope.applet.name;
        this.getAndRunValidationGroupRules($scope, validationGroup)
            .then((result) => {
                const disabled = !result || !isValid;
                this.toggleDisabledButtons(validationEvent, validationGroup, $scope.validationGroupButtons, disabled);
            });
    }

    private getAndRunValidationGroupRules($scope: any, validationGroup: string): Promise<boolean> {
        const groupInfo = this.validationEngineService.getGroupConfig(validationGroup)
        const results = [];
        if (groupInfo) {
            const validators = groupInfo.getValidators();
            for (let i = 0; i < validators.length; i++) {
                const validator = validators[i];
                const editor = validator.adapter.editor;
                const value = editor.option('value');
                this.validationEngineService.runValidationRulesConditions($scope, validator.validationRules);
                const isOptionalField = _.findIndex(validator.validationRules, { type: 'custom' });
                if (!validator.adapter.bypass() && (isOptionalField == -1 || value))
                    results.push(this.validationEngineService.validate(value, validator.validationRules, editor.NAME + i.toString(), $scope));
            }
        }
        return Promise.all(results)
            .then((values) => {
                const isValid = values.some((el) => {
                    let aux = el.isValid;
                    if (!aux && el.brokenRules[0].warning) {
                        aux = true;
                    }
                    return aux == false;
                });
                IX_Log('validationGroup', 'isValid: ' + !isValid, _.map(values, (val) => _.pick(val, ['name', 'value', 'isValid'])));
                return !isValid;
            });
    }

    private toggleDisabledButtons(event: ValidationGroupEvent, validationGroup: string, buttonsGroupClass: string[], disabled: boolean): void {
        const buttonAppName = event.state.buttonAppName;
        const appName = event.state.appName;
        buttonsGroupClass.forEach((groupClass) => {
            const [groupName, className] = groupClass.split('|');
            if (groupName !== validationGroup) return;
            const eventState = { appName, disabled, className, buttonAppName };
            this.publishEvent(AppEvent.ToggleDisabledButton, eventState);
        });
    }

    private publishEvent(eventName: string, eventState: any) {
        const event = {
            id: eventName,
            state: eventState
        };
        this.eventsService.publishEvent(event);
    }
}
