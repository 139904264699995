/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxDateBox = require('devextreme/ui/date_box');

var _isEightDigitAllNumeric = function (dateString) {
    return typeof dateString === 'string' &&
        dateString.length === 8 &&
        !dateString.match(/[^\d]/);
};

var _dxDateBox = DevExpress.ui.dxDateBox.redefine({
    _getParsedDate: function (text) {
        var displayFormat = this._strategy.getDisplayFormat(this.option('displayFormat'));
        // Globalize would make an incorrect assumption that '06302019' means 2019-06-02
        if (_isEightDigitAllNumeric(text)) {
            return undefined;
        }
        var parsedText = this._strategy.getParsedText(text, displayFormat);
        return typeof parsedText !== 'undefined' && parsedText !== null ? parsedText : undefined;
    }
});
DevExpress.registerComponent('dxDateBox', _dxDateBox);

var _dxDateBox2 = DevExpress.ui.dxDateBox.inherit({
    _renderDropDownButton: function () {
        this.callBase();
        this._$dropDownButton.attr('tabindex', '0');
        iXing.Helpers.handleEnterKeyForAccessibility(this._$dropDownButton);
    }
});
DevExpress.registerComponent('dxDateBox', _dxDateBox2);
