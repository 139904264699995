import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import { from, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { BaseEffectCommand } from './base-effect.command';
import { CacheManagerService } from '../services/cachemanager.service';
import { UtilService } from '../services/util.service';
import { ListApplication } from '../components/list-application';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';

@Injectable()
export class EnableDisableGroupBlockingCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        protected utilService: UtilService,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.enableDisableGroupBlockingCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.enableDisableGroupBlockingCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const ruleConfig = this.getConditionalRules(commandConfig);
                if (_.isNil(ruleConfig?.functionName))
                    return throwError("Unable to determine grouping set rules.");
                const appState = this.getAppState(commandConfig.appName, appsStore);
                const promise = this.utilService.updateConditionalFormatRulesContext(commandConfig.appName, appState);
                return from(promise)
                    .pipe(
                        switchMap((contextObj) => {
                            const appComponent = this.domComponentRetrievalService.getAppComponent(commandConfig.appName) as ListApplication;
                            const result = this.callWindowFunction(appsStore.workflow, appsStore.themeName, ruleConfig.functionName, contextObj);
                            if (result && appComponent) {
                                const enableDisable = commandConfig.parameters[1];
                                appComponent.allowGroupBlocking = false;
                                appComponent.groupBlockingLevel = null;
                                if ("Enable".EqualsIgnoreCase(enableDisable)) {
                                    appComponent.allowGroupBlocking = true;
                                    appComponent.groupBlockingLevel = parseInt(commandConfig.parameters[2]);
                                }
                            }
                            return this.getNextActions(commandConfig);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route })
        })
    );
}
