/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import Highcharts from '../libraries/highcharts.extensions';
import { FieldFormatService } from "./field-format.service";
import { ApplicationInformation } from "./application-information.service";
import { UtilService } from './util.service';

@Injectable()
export class ChartService {

    constructor(private fieldFormatService: FieldFormatService,
        private applicationInformationService: ApplicationInformation,
        private utilService: UtilService) {
      
    }

    _getBarChartTemplate(elementId) {
        var assetBarIdPrefix = 'ic_allocation_' + elementId + '_';
        var barChartTemplate =
        '<div class="barContainer" tabindex="0" aria-live="assertive" aria-relevant="all"' +
        ' aria-label="Bar Chart. This chart shows the asset allocations in colors corresponding to what is shown in the legend below. Bar segments for equities with securities are color-filled and those without securities are hollow bars"' +
        ' style="background:{{config.backgroundColor}}">' +
        '  <div class="assetBar"' +
        '   id="{{\'' + assetBarIdPrefix + '\' + $index}}"' +
        '   ng-repeat-start="barPoint in newSeriesData.data" ng-if="barPoint.t != 0"' +
        '   ng-style="{&#39;border&#39;:&#39;1px solid&#39; + barPoint.borderColor,&#39;width&#39;: &#39;calc(&#39;+barPoint.t * 99+&#39;% - 3px)&#39;,&#39;background-color&#39;: barPoint.name.toLowerCase() == &#39;cash&#39; ? barPoint.borderColor : (barPoint.level2Sec == true ? barPoint.borderColor :&#39;{{config.backgroundColor}}&#39;)}">' +
        '   <div ng-repeat="secuPoints in barPoint.securitiesArray|limitTo:barPoint.securitiesArray.length-1" ng-if="barPoint.level2Sec == true && barPoint.securitiesArray.length > 1"' +
        '    class="assetDivider" ng-style="{&apos;margin-left&apos;: 100/barPoint.securitiesArray.length + &apos;%&apos;}"></div>' +
        '  </div>' +
        '  <div ng-repeat-end' +
        '   ng-init="tooltip = config.barChart.tooltipFormatter(barPoint)"' +
        '   dx-tooltip="{ ' + 
        '   target: \'#' + assetBarIdPrefix + '{{$index}}\',' +
        '   showEvent: \'mouseenter\',' +
        '   hideEvent: \'mouseleave\', ' +
        '   position: \'top\',' +
        '   animation: { show: { duration: 0}, hide: { duration: 0} },' +
        '   elementAttr: { class: \'ic-allocation-barchart-tooltip\'}' +
        '  }" ><b>{{ tooltip.name }}</b>: {{ tooltip.value }}' +
        '  </div>' +
        '</div>';
        return barChartTemplate;
    }

    _CreateChartSeriesData($s, groupGraph, aggreBy, customizeItemFn) {
        var data = [], i = 0;

        if (!$.isArray(aggreBy) && !aggreBy.length)
            return [];

        for (var group in groupGraph) {
            var item = {
                name: group,
                y: groupGraph[group].group.aggregations[aggreBy[0].field].calculatedVal,
                level2Sec: groupGraph[group].group.level2Sec,
                securitiesArray: groupGraph[group].group.securitiesArray
            };

            if (aggreBy) {
                for (var f = 1; f < aggreBy.length; f++) {
                    item[aggreBy[f].target] = groupGraph[group].group.aggregations[aggreBy[f].field].calculatedVal;
                }
            }

            data.push(item);
        }

        //For custom sorting as defined in the theme properties
        if ($s.themeSortIndicator && $s.themeSortIndicator.themeSort == true) {
            if (window.IX_Theme.properties[$s.themeSortIndicator.position].PropertyName != null && window.IX_Theme.properties[$s.themeSortIndicator.position].PropertyName != "" && window.IX_Theme.properties[$s.themeSortIndicator.position].PropertyName != undefined) {
                var customSortOrderArray = window.IX_Theme.properties[$s.themeSortIndicator.position].Value2.split("|");

                data.sort(function (a, b) {
                    var indexA = $.inArray(a.name, customSortOrderArray);
                    var indexB = $.inArray(b.name, customSortOrderArray);
                    return (indexA < indexB) ? -1 : (indexA > indexB) ? 1 : 0;
                });
            }
        }
        else {
            data.sort(function (a, b) {
                if (a.y < b.y) return 1;
                if (a.y > b.y) return -1;
                return 0;
            });
        }

        if (customizeItemFn) {
            for (var i = 0; i < data.length; i++) {
                customizeItemFn(data[i], i);
            }
        }

        return data;
    }

    _CreateChartLegendData($s, data, formats, aggreBy, setTotal) {
        var i = 0, tmp, formats = this.applicationInformationService.getFormats($s.applet.name);
        $s.totalSecurities = 0;
        if (!$.isArray(aggreBy) && !aggreBy.length)
            return [];

        for (; i < data.length; i++) {
            tmp = {
                name: data[i].name,
                borderColor: data[i].borderColor,
                level2Sec: data[i].level2Sec,
                securitiesArray: data[i].securitiesArray
            };
            if (data[i].securitiesArray && data[i].securitiesArray.length > 0) {
                $s.totalSecurities += data[i].securitiesArray.length;
            }
            tmp[aggreBy[0].target] = data[i][aggreBy[0].field];
            tmp[aggreBy[0].target + 'Str'] = this.fieldFormatService.format(formats[aggreBy[0].format], data[i][aggreBy[0].field]);

            if (aggreBy) {
                for (var f = 1; f < aggreBy.length; f++) {
                    tmp[aggreBy[f].target] = data[i][aggreBy[f].field];
                    tmp[aggreBy[f].target + 'Str'] = this.fieldFormatService.format(formats[aggreBy[f].format], data[i][aggreBy[f].field]);
                }
            }

            $s.legendData.push(tmp);

            if (setTotal)
                $s.totalDataValue += data[i][aggreBy[0].field];
        }
    }

    _UpdateChartSeriesData($s, chart, rawData, formats, groupBy, aggreBy, aggreByObj, aggreByObjLegend, _setItemColor, secLevelField, securityNameField, securitySymbolField, idField) {

        if (rawData.length == 0) {
            chart.hideLoading();
            return;
        }

        formats = this.applicationInformationService.getFormats($s.applet.name);

        chart.showLoading();

        // remove chart series data
        if (chart.series.length)
            chart.series[0].remove(true);

        this.utilService.convertRawDataArrayFromDSFormat($s, rawData);

        //1.- Group Data 
        var groupGraph = this.utilService.groupArray($s.rawData, groupBy, aggreBy, secLevelField, $s, securityNameField, securitySymbolField, idField);

        //3.- Get chart series data
        $s.totalDataValue = 0;
        $s.legendData.clear();

        $s.chartData = this._CreateChartSeriesData($s, groupGraph, aggreByObj, _setItemColor);

        if (formats && $.isArray(aggreByObjLegend))
            this._CreateChartLegendData($s, $s.chartData, formats, aggreByObjLegend, true);

        var newSeriesData = new Object({ name: $s.config.subTitleStaticTitle, data: $s.chartData });

        //3.- tell highchart to redraw chart
        chart.addSeries(newSeriesData, true);

        if (formats && $.isArray(aggreByObjLegend)){
            $s.totalDataValueStr = this.fieldFormatService.format(formats[$s.config.seriesValueField], $s.totalDataValue);
        }

        if ($s.config.showDonutCenTxt == 'true') {
            // Place text inside donut chart
            var textX = chart.plotLeft + (chart.plotWidth  * 0.5);
            var textY = chart.plotTop  + (chart.plotHeight * 0.5);
    
            var span = '<span class="donutChartCenterText" style="position:absolute; text-align:center;">';
            span += '<span class="totalValue">' + $s.totalDataValueStr + '</span>';
            span += '<span class="centerText">' + $s.config.donutCenterText + '</span>';
            span += '</span>';
    
            $(".AssetAllocationDonutChartContainer").children().append(span);
            const spanSelector = $('.donutChartCenterText');
            spanSelector.css('left', textX + (spanSelector.width() * -0.5));
            spanSelector.css('top', textY + (spanSelector.height() * -0.5));
        }
        
        $s.$applyAsync(function () {
            chart.hideLoading();
        });

    }

    _UpdateBarChartSeriesData($s, element, rawData, formats, groupBy, aggreBy, aggreByObj, aggreByObjLegend, _setItemColor, secLevelField, securityNameField, securitySymbolField, idField) {

        if (rawData.length == 0) {
            return;
        }

        formats = this.applicationInformationService.getFormats($s.applet.name);

        this.utilService.convertRawDataArrayFromDSFormat($s, rawData);

        //1.- Group Data 
        var groupGraph = this.utilService.groupArray($s.rawData, groupBy, aggreBy, secLevelField, $s, securityNameField, securitySymbolField, idField);

        //3.- Get chart series data
        $s.totalDataValue = 0;
        $s.legendData.clear();

        $s.chartData = this._CreateChartSeriesData($s, groupGraph, aggreByObj, _setItemColor);


        if (formats && $.isArray(aggreByObjLegend))
            this._CreateChartLegendData($s, $s.chartData, formats, aggreByObjLegend, true);

        $s.newSeriesData = new Object({ name: $s.config.subTitleStaticTitle, data: $s.chartData });

        // Draw the chart
        $(element).find('.AssetAllocationDonutChartContainer')[0].innerHTML = "";
        var barChartTemplate = this._getBarChartTemplate(element[0].id);
        $(element).find('.AssetAllocationDonutChartContainer')[0].insertAdjacentHTML('afterbegin', barChartTemplate);

        if (formats && $.isArray(aggreByObjLegend))
            $s.totalDataValueStr = this.fieldFormatService.format(formats[$s.config.seriesValueField], $s.totalDataValue);


        if (formats && $.isArray(aggreByObjLegend))
            $s.totalDataValueStr = this.fieldFormatService.format(formats[$s.config.seriesValueField], $s.totalDataValue);
    }

    _UpdToggleChartSeries($s, element, rawData, formats, groupBy, aggreBy, aggreByObj, aggreByObjLegend, _setItemColor, secLevelField, securityNameField, securitySymbolField, idField) {

        if (rawData.length == 0) {
            return;
        }

        formats = this.applicationInformationService.getFormats($s.applet.name);

        this.utilService.convertRawDataArrayFromDSFormat($s, rawData);

        //1.- Group Data 
        var groupGraph = this.utilService.groupArray($s.rawData, groupBy, aggreBy, secLevelField, $s, securityNameField, securitySymbolField, idField);

        //2.- Get chart series data
        $s.totalDataValue = 0;
        $s.legendData.clear();

        $s.chartData = this._CreateChartSeriesData($s, groupGraph, aggreByObj, _setItemColor);

        if (formats && $.isArray(aggreByObjLegend))
            this._CreateChartLegendData($s, $s.chartData, formats, aggreByObjLegend, true);

        $s.newSeriesData = new Object({ name: $s.config.subTitleStaticTitle, data: $s.chartData });
        var level2Indicator = false;
        _.forEach($s.chartData, function (value, index) {
            if (typeof value.level2Sec != "undefined" && value.level2Sec) {
                level2Indicator = true;
                return;
            }
        });
        if (level2Indicator) {
            // Draw the chart
            var barChartTemplate = this._getBarChartTemplate(element[0].id);
            $(element).find('.AssetAllocationDonutChartContainer')[0].innerHTML = "";
            $(element).find('.AssetAllocationDonutChartContainer')[0].insertAdjacentHTML('afterbegin', barChartTemplate);

            _.forEach($s.chartData, function (value, index) {
                var ind = 0;
                if (value.securitiesArray.length > 1 && value.level2Sec == true) {
                    var marginLeft = 100 / value.securitiesArray.length + '%';
                    $('.assetDivider').eq(ind).css('margin-left', marginLeft);
                    ind++;
                }
            })

            if (formats && $.isArray(aggreByObjLegend)) {
                $s.totalDataValueStr = this.fieldFormatService.format(formats[$s.config.seriesValueField], $s.totalDataValue);
            }
            $s.compileBar(element);

        } else {

            var chart = new Highcharts.chart($(element).find('.AssetAllocationDonutChartContainer')[0], $s.hcConfig);
            // remove chart series data
            if (chart.series.length)
                chart.series[0].remove(true);

            chart.addSeries($s.newSeriesData, true);

            if (formats && $.isArray(aggreByObjLegend))
                $s.totalDataValueStr = this.fieldFormatService.format(formats[$s.config.seriesValueField], $s.totalDataValue);
        }
    }
}
