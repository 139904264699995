import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, ElementRef } from '@angular/core';
import { TranslateFacadeService } from '../../services/translate-facade.service';

@Component({
  selector: 'ic-modal-email-viewer',
  templateUrl: './modal-email-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEmailViewerComponent implements OnInit, OnDestroy {

  @Input() options: ModalEmailViewerOptions;
  @Input() done: Function;
  @Input() onHiding: Function;

  title: string;
  isWriteMode: boolean;
  width: number | string;
  height: number | string;
  formToAddress: string;
  formSubject: string;
  formBody: string;
  froalaOptions = {
    pluginsEnabled: null,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'undo', 'redo', 'html'],
    pastePlain: true,
    height: 550,
    heightMin: 100,
    key: 'lJCJWECHICc2JOZWJ==',
  };

  constructor(private elementRef: ElementRef,
    private translateFacade: TranslateFacadeService) { }

  ngOnInit(): void {
    this.title = this.options.title || this.translateFacade.getTranslation('Thread Subject');
    this.isWriteMode = this.options.isWriteMode || false;
    this.width = this.options.width || 800;
    this.height = this.options.height || 600;
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.off(['blur', 'focus']);
    this.elementRef.nativeElement.froalaEditor('destroy');
  }

  handleSendClicked(e) {
    const ecdSendEmailRequest = {
      ServerCallType: 'SendNewEmail',
      ApplicationName: 'BB2CRMNewEmail.Input.App',
      ContainerApplication: 'BB2CRMNewEmail.Input.App',
      ApplicationVersion: 'v1.0.0',
    };

    const dataViewConvo = {
      ToAddress: this.formToAddress,
      Subject: this.formSubject,
      Body: this.formBody,
    };
    const returnAsRawList = true;
    const ecdContext = {};
    const deferred = $.Deferred();
    IX_GetEcdDataAndResolvePromise(ecdSendEmailRequest, dataViewConvo, deferred, returnAsRawList, ecdContext);
    deferred.resolve(true);
    this.done();
  }

  onShowing(e) {
    const editor = e.element.find('textarea').froalaEditor(this.froalaOptions);

    const save = function () {
      const html = editor.froalaEditor('html.get');

      if (html === this.formBody) {
        return;
      }

      this.formBody = html;
    };

    editor.on('froalaEditor.blur', function () {
      save();
    });
  }

  onContentReady(e) {
    e.component.focus();
  }
}
