import { Component, OnChanges, OnDestroy, AfterViewInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from '../../services/util.service';
import { AppsFacade } from '../../state/apps.facade';
import { DomComponentRetrievalService } from '../../services/dom-component-retrieval.service';
import { AppEvent } from '../../state/app-events.enum';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { DeviceService } from './../../services/device-information.service';
import { AlertService } from '../../services/alert.service';

@Component({
    selector: 'ic-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnChanges, OnDestroy, AfterViewInit {

    @Input() fieldName: any;
    @Input() model: any;
    @Input() config: any;
    @Input() applet: Applet
    @Input() context: any;
    @Input() conditionalFormats: any;
    @Input() parentModel: any;
    @Input() cssClass: string;
    @Input() checkSize: any;

    @Output() updateState = new EventEmitter<AppStateChange>();

    isInitialized = false;
    fileName = "";
    $elementCache = {};
    _fieldMap = {};
    KEYS = {
        FILE_UPLOAD: 'FILE_UPLOAD',
        SEC_FILE_UPLOAD: 'SEC_FILE_UPLOAD',
        PROGRESS_BAR: 'PROGRESS_BAR',
        UPLOADED_FILES: 'UPLOADED_FILES'
    };
    _mimeTypeClasses = "PDFFileBg WordFileBg ExcelFileBg ZipFileBg HTMLFileBg PPTFileBg UnknownFileBg";

    translateSubscription: Subscription;
    hasDocumentBeenLoadedOne = false;
    fileUploadId: string;
    maxWidth: number;
    setBackgroundImageBasedOnFileExt: any;
    _acceptImageFileTypes: RegExp;

    public ids: {
        fileupload: string,
        secFileupload: string,
        progressBar: string,
        uploadedFile: string
    };

    constructor(private elementRef: ElementRef,
        private translateFacade: TranslateFacadeService,
        private appsFacade: AppsFacade,
        private utilService: UtilService,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private deviceService: DeviceService,
        private alertService: AlertService) { }

    private getAppState(): AppState {
        const appComponent = this.domComponentRetrievalService.getAppComponent(this.config.appName)
        return appComponent?.getStateFromModel() || {};
    }

    ngOnDestroy(): void {
        this.translateSubscription?.unsubscribe();

        const $e = $(this.elementRef.nativeElement);

        $e.find('*:not(input)').unbind('paste');
        if (this.ids) {
            let tmp = $e.find('#' + this.ids?.fileupload);
            if (!_.isNil(tmp.data().blueimpFileupload)) {
                tmp.fileupload('destroy');
            }
            tmp = $e.find('#' + this.ids.secFileupload);
            if (!_.isNil(tmp.data().blueimpFileupload)) {
                tmp.fileupload('destroy');
            }
        }
    }

    ngOnChanges(changes): void {

        if (!this.isInitialized) {
            this.init();
        }

        for (const propName in changes) {
            if (propName === 'model') {
                const newVal = changes[propName].currentValue;
                const oldVal = changes[propName].previousValue;

                if (!!newVal.value && !this.fileName) {
                    this.fileName = newVal.value;
                };
                const element = this.elementRef.nativeElement;
                const $e = $(element);
                if (!_.isNil(this.fileName) && this.fileName.length > 0 && !newVal.value) {
                    this._clearFileView($e.find(".fileDeleteButton"));
                }
            }
        }

        this.updateTranslations();
    }

    ngAfterViewInit(): void {
        this.translateSubscription = this.translateFacade.onLangChange.subscribe(() => {
            this.updateTranslations();
        });
    }

    init(): void {
        this.isInitialized = true;
        this._acceptImageFileTypes = this.config.isSnapComponent ? /(\.|\/)?(gif|jpe?g|png)$/ : /(\.|\/)?(gif|jpe?g|png|tiff|bmp)$/;
        this.fileUploadId = this.applet.rid + '_' + this.config.sequence;
        this.ids = {
            fileupload: 'fileupload' + this.fileUploadId,
            secFileupload: 'fileupload_sec' + this.fileUploadId,
            progressBar: 'progressBar' + this.fileUploadId,
            uploadedFile: 'uploadedFile' + this.fileUploadId,
        };

        this._createFieldMap();

        setTimeout(() => this._setUp(), 0);

        const element = this.elementRef.nativeElement;
        const $e = $(element);
        $e.find('*:not(input)').bind('paste', (e) => e.stopPropagation());

        if (this.checkSize) {
            this.checkSize({
                adjustWidth: true,
                adjustHeight: true
            });
        }
    }


    _setUp(): void {
        this.updateTranslations();
        this._updateConfigurationForMobile();
        if (this.config.useThumbnailOnly) {
            this._setUpSecondaryFileUpload();
        } else {
            this._setUpFileUpload();
        }
    }

    updateTranslations() {
        const element = this.elementRef.nativeElement;
        const $e = $(element);

        const _options = {
            element: $e,
            target: $e.find('input'),
            appId: this.applet.rid
        };

        this._createElementCache();
        this.utilService.updateComponentTranslationProperties(_options, this.config.translationId);
        this.utilService.createADALabelAttributes(_options);
        let $ele = this._getFileInputLabel();
        this._setImageCss($ele, "uploadImg");
        $ele = this._getSecFileInputLabel();
        this._setImageCss($ele, "thumbnailImg");
    }

    _createElementCache() {
        this.$elementCache[this.KEYS.FILE_UPLOAD] = $('#' + this.ids.fileupload);
        this.$elementCache[this.KEYS.SEC_FILE_UPLOAD] = $('#' + this.ids.secFileupload);
        this.$elementCache[this.KEYS.PROGRESS_BAR] = $('#' + this.ids.progressBar);
        this.$elementCache[this.KEYS.UPLOADED_FILES] = $('#' + this.ids.uploadedFile);
    }

    _updateCacheElement(elementKey: string, forceReload?: boolean) {
        const element = this.elementRef.nativeElement;
        let $element = this.$elementCache[elementKey];
        if (!$element.length || forceReload) {
            $element = this.$elementCache[elementKey] = $(element).find($element.selector);
        }
        return $element;
    }


    _getFileInput(forceReload?: boolean) {
        return this._updateCacheElement(this.KEYS.FILE_UPLOAD, forceReload);
    }
    _getSecFileInput(forceReload?: boolean) {
        return this._updateCacheElement(this.KEYS.SEC_FILE_UPLOAD, forceReload);
    }

    _getInputLabel(getFileFn) {
        let $element = getFileFn.apply(this).parent("label");
        if (!$element.length)
            $element = getFileFn.call(this, true).parent("label");
        return $element;
    }
    _getFileInputLabel() {
        return this._getInputLabel(this._getFileInput);
    }
    _getSecFileInputLabel() {
        return this._getInputLabel(this._getSecFileInput);
    }
    _getProgressBar() {
        return this._updateCacheElement(this.KEYS.PROGRESS_BAR);
    }
    _getUploadedDiv() {
        return this._updateCacheElement(this.KEYS.UPLOADED_FILES);
    }
    _setValue(field, value) {
        this.updateState.emit({ field: field, value: value });
    }
    _getValue(field) {
        let val: any = "";
        const state = this.getAppState();
        if (state[field]) {
            val = state[field];
        }
        return val;
    }
    _deleteServerFile(postbackURL, fileId) {
        let qryChar = "?"
        if (postbackURL.indexOf(qryChar) !== -1) {
            qryChar = "&";
        }
        const deleteUrl = postbackURL + qryChar + "IX_DEL=" + fileId;
        return $.ajax(deleteUrl, { type: "POST" });
    }
    _clearFileView($delBtn) {

        const $fileUploadLabel = this._getFileInputLabel();
        const thisecFileUploadLabel = this._getSecFileInputLabel();

        $delBtn.removeClass("waitIndicator").addClass("fileDeleteButton");

        if (this.config.useThumbnail) {
            if (!this.config.useThumbnailOnly) {
                thisecFileUploadLabel.hide();
                $fileUploadLabel.show().width(this.maxWidth);
            }
            this.hasDocumentBeenLoadedOne = false;
        }

        this._clearPreviousValuesForPrimaryFile();

        thisecFileUploadLabel.removeClass("noBgImage").find(".attachedImagePrev").remove();
        $fileUploadLabel.removeClass(this._mimeTypeClasses).find(".attachedImagePrev").remove();
        this._getUploadedDiv().fadeOut();
    }

    deleteFile(e) {
        const $delBtn = $(e.target);
        const deleteWithoutConfirmation = false
        const fileId = this._getValue(this.config.fields.id);

        if (_.isNil(fileId) || !fileId) {
            this._alert("DELETE-FILE-NO-FILE");
            return;
        }

        if (!deleteWithoutConfirmation) {
            const conf = confirm(this.getMessageText("DELETE-FILE-CONFIRM"));
            if (!conf) return;
        }

        const promise = this._deleteServerFile(this.config.handlerUrl, fileId);
        $delBtn.removeClass("fileDeleteButton").addClass("waitIndicator");
        promise.done(() => {
            this._clearFileView($delBtn);
        })
            .fail(() => {
                $delBtn.removeClass("waitIndicator").addClass("fileDeleteButton");
                this._alert("DELETE-FILE-ERROR");
            })
    }

    _setImageCss($ele, property) {
        let img = this.utilService.translateOrDefault(this.config.translationId + "." + property, this.config[property]);
        img = this.utilService.setASPNETThemePath(img);
        const styleObj = {
            "background-repeat": "no-repeat",
            "background-align": "center",
            "backgroundImage": "url(" + img + " )"
        };
        $ele.css(styleObj);
    }

    _updateConfigurationForMobile() {
        if (this.deviceService.IX_isMobile()) {
            const selectors = "#" + this.ids.fileupload + ",#" + this.ids.secFileupload;
            $(selectors).attr("accept", "image/*;capture=camera")
        }
    }

    _alert(messageId) {
        this.alertService.alert2(this.getMessageText(messageId), () => { });
    }

    _wasUploadSuccessful(file) {
        const inError = typeof file.errorMessage !== "undefined"
            && file.errorMessage != null
            && file.errorMessage !== ""
            && typeof file.name !== "undefined"
            && file.name != null;
        return inError;
    }

    _fileUploadDone(e, data) {
        const file = data.result.List[0];
        const inError = this._wasUploadSuccessful(file);
        if (inError) {
            this._alert("ERROR-UPLOADING-FILE");
        }
        this._updateFileUploadView();
        return !inError;
    }

    validatePrimaryFile(e, data) {
        const file = data.originalFiles[0] || {};
        const app = this.getAppState();

        if (!this.isValidFileExtension(file.name, this.config.allowedExtensions)) {
            return this._alert("FILE_UPLOAD_EXTENSION_NOT_ALLOWED");
        }

        // If MaxFileSize field is present in the app, validate it
        if (!_.isNil(app["MaxFileSize"]) && !_.isNil(file.size) && file.size > app["MaxFileSize"])
            return this._alert("ERROR-UPLOADING-FILE-MAY-BE-TOO-LARGE");

        if (!this.config.isSnapComponent && (!this.config.useThumbnail || this.deviceService.IX_isMobile()))
            return true;

        if (this.config.isSnapComponent) {
            if (!_.isNil(file.type) && file.type.length && !this._acceptImageFileTypes.test(file.type)) {
                return this._alert("IMAGE-FILE-TYPE-NOT-SUPPORTED");
            }
        } else {
            if (file.type?.length && this._acceptImageFileTypes.test(file.type)) {
                return confirm(this.getMessageText("IMAGE-AS-MAIN-DOC-WARNING"));
            }
        }
        return true;
    }

    isValidFileExtension(fileName, allowedExtensions) {
        return allowedExtensions.length === 0 ||
            _.reduce(allowedExtensions, (result, ext) => {
                // the end result is true if at least one of the items evaluates to true
                return result || fileName.toLowerCase().endsWith(ext.toLowerCase());
            }, false);
    }

    _clearPreviousValuesForPrimaryFile() {
        this._setValue(this.config.fields.id, null);
        this.fileName = "";
        if (this.config.isSnapComponent) {
            this._setValue(this.config.fields.FileName, null);
            this._setValue(this.config.fields.TimeStamp, null);
        } else {
            this._setValue(this.config.fields.originalFileName, null);
            this._setValue(this.config.fields.uploadedBy, null);
            this._setValue(this.config.fields.uploadTime, null);
        }
    }

    _setValuesForPrimaryFile(file) {
        this._setValue(this.config.fields.id, file.fileId);
        this.fileName = file.name;
        if (this.config.isSnapComponent) {
            this._setValue(this.config.fields.FileName, file.name);
            this._setValue(this.config.fields.TimeStamp, file.uploadedTime);
        } else {
            this._setValue(this.config.fields.originalFileName, file.name);
            this._setFriendlyNameIfNull();
            this._setValue(this.config.fields.uploadedBy, file.uploadedBy);
            this._setValue(this.config.fields.uploadTime, file.uploadedTime);
        }
    }

    _clearPreviousValuesForSecondaryFile() {
        this._setValue(this.config.fields.secOriginalFileName, null);
    }

    _fileUploadFail() {
        this._alert("ERROR-UPLOADING-FILE-MAY-BE-TOO-LARGE");
        this._updateFileUploadView();
    }

    _updateFileUploadView() {
        this._getProgressBar().fadeOut({
            complete: () => {
                this._getUploadedDiv().fadeIn();
            }
        });
    }

    _setBackgroundImageBasedOnFileExt(fileExt, fileId, sendingElement) {
        //remove added class before adding a new class
        if (sendingElement[0].classList.length > 1) {
            sendingElement[0].className = "fileinput-button";
        }

        switch (fileExt.toUpperCase()) {
            case "PDF":
                sendingElement.addClass("PDFFileBg");
                break;
            case "DOC":
            case "DOCX":
                sendingElement.addClass("WordFileBg");
                break;
            case "XLS":
            case "XLSX":
                sendingElement.addClass("ExcelFileBg");
                break;
            case "ZIP":
                sendingElement.addClass("ZipFileBg");
                break;
            case "HTM":
            case "HTML":
                sendingElement.addClass("HTMLFileBg");
                break;
            case "PPT":
            case "PPTX":
                sendingElement.addClass("PPTFileBg");
                break;
            case "TXT":
                sendingElement.addClass("fu-ico-txt");
                break;
            case "CSV":
                sendingElement.addClass("fu-ico-csv");
                break;
            default:
                if (this._acceptImageFileTypes.test(fileExt)) {
                    this._secFileUploadDone(fileId);
                } else {
                    sendingElement.addClass("UnknownFileBg");
                }
                break;
        }
    }

    _determineFileExt(fileName, fileId, sendingElement) {
        let fileExt = fileName.split(".");
        fileExt = fileExt[fileExt.length - 1];
        this._setBackgroundImageBasedOnFileExt(fileExt, fileId, sendingElement);
    }

    _setFriendlyNameIfNull() {
        const originalFileNameValue = this._getValue(this.config.fields.originalFileName)
        if (!_.isNil(originalFileNameValue)) {
            const fileExtPeriod = originalFileNameValue.lastIndexOf('.');
            if (fileExtPeriod > - 1) {
                const currentValue = this._getValue(this.config.fields.friendlyName);
                const originalFileNameValueSliced = originalFileNameValue.slice(0, fileExtPeriod)
                if (currentValue == null || currentValue === "")
                    this._setValue(this.config.fields.friendlyName, originalFileNameValueSliced);
            }
        }
    }

    _fileUploadAdd(e, data, validationMethod) {

        if (_.isFunction(validationMethod) && !validationMethod.call(this, e, data))
            return;

        if (data.originalFiles.length > 1) {
            if (data.files[0].name === data.originalFiles[0].name)
                this._alert("ONE_FILE_UPLOADED"); // only alert the first time
            return;
        }

        this._setUpProgressBar();

        data.submit();
    }

    _setUpProgressBar() {
        $('#' + this.ids.uploadedFile).hide();
        this._getProgressBar().find('span')
            .css("width", "0%")
            .text(this.getMessageText("UPLOADING") + "...");
        this._getProgressBar().addClass("stripes")
            .fadeIn();
    }

    _fileUploadProgressAll(e, data) {
        const percentProgress = data.loaded / data.total * 100;
        this._getProgressBar().find('span').css('width', Math.round(percentProgress) + '%');
    }

    _createFieldMap() {
        for (let field in this.config.fields) {
            if (Object.prototype.hasOwnProperty.call(this.config.fields, field)) {
                field = field.charAt(0).toUpperCase() + field.slice(1);
                this._fieldMap[field] = true;
            }
        }
    }

    _fileUploadSetFormData() {
        const dataToSend = []
        const app = this.getAppState();
        for (const field in app) {
            if (app[field] && this._fieldMap[field] && app[field] !== null && app[field] !== "") {
                dataToSend.push({ name: field, value: app[field] });
            }
        }
        return dataToSend;
    }

    _validateSecondaryFile(e, data) {
        const file = data.originalFiles[0] || {};
        if (!_.isNil(file.type) && file.type.length && !this._acceptImageFileTypes.test(file.type)) {
            this._alert("This file type is not accepted as a thumbnail.  Accepted types are JPG, GIF, PNG, TIFF, BMP.");
            return false;
        }
        return true;
    }

    _secFileUploadDone(_id) {
        const _fileInputLabel = this._getSecFileInputLabel();
        _fileInputLabel.find(".attachedImagePrev").remove();
        const divcssObj = { "width": _fileInputLabel.width() + "px", "position": "absolute", "top": "0px", "text-align": "center", "left": "0px", "height": _fileInputLabel.height() + "px" };
        const $attachDiv = $("<div class='attachedImagePrev'></div>").css(divcssObj);
        const imgcssObj = { "max-height": "100%", "max-width": "100%", "vertical-align": "middle" };
        const $attachImg = $("<span class='vAlignhelperSpan'></span><img src='/PersistedResource/Sec/" + _id + "'>").css(imgcssObj);
        $attachDiv.prepend($attachImg);
        _fileInputLabel.addClass("noBgImage").prepend($attachDiv);
    }

    _setUpSecondaryFileUpload() {
        if (!this.config.useThumbnail)
            return;

        const _fileInputLabel = this._getFileInputLabel();
        const _secFileInput = this._getSecFileInput();
        const _secFileInputLabel = this._getSecFileInputLabel();

        const _options = {
            dataType: 'json',
            done: (e, data) => {
                const success = this._fileUploadDone(e, data);
                const file = data.result.List[0];
                if (success) {
                    if (this.config.useThumbnailOnly) {
                        this._setValuesForPrimaryFile(file);
                    }
                    this._setValue(this.config.fields.secOriginalFileName, file.name);
                    this._secFileUploadDone(file.fileId);
                }
            },
            fail: this._fileUploadFail.bind(this),
            add: (e, data) => {
                if (this.config.useThumbnailOnly)
                    this._clearPreviousValuesForPrimaryFile();
                this._clearPreviousValuesForSecondaryFile();
                this._fileUploadAdd(e, data, this._validateSecondaryFile);
            },
            progressall: this._fileUploadProgressAll.bind(this),
            formData: this._fileUploadSetFormData.bind(this),
            dropZone: _secFileInputLabel,
            fileInput: _secFileInput,
            fileuploaddragover: () => {
                _secFileInputLabel.css("border-color", "red");
            },
            always: (e, data) => this.publishDocumentUploadEvent(data.files)
        };

        _secFileInput.fileupload(_options);

        _secFileInputLabel.bind('dragenter dragover', (e) => {
            $(this).addClass("IXUpload_hoverInput");
        })
            .bind('dragleave dragend drop', (e) => {
                $(this).removeClass("IXUpload_hoverInput");
            });

        // this was being reinitialized every time someone uploaded a document, then uploaded a different document causeing layout issues.
        if ((_.isNil(this.hasDocumentBeenLoadedOne) || !this.hasDocumentBeenLoadedOne) && !this.config.useThumbnailOnly) {
            this.maxWidth = _fileInputLabel.width();
            _fileInputLabel.width(this.maxWidth * .3)
            let secondaryWidth = this.maxWidth - _fileInputLabel.width();
            if (secondaryWidth <= 0) {
                window['IX_Log'] && IX_Log("component", "Generated css is forcing width (e.g: width: '" + this.maxWidth + " !important')on file upload element", _fileInputLabel);
                secondaryWidth = Math.floor(this.maxWidth / 2) - 3;
            }
            _secFileInputLabel.width(secondaryWidth).fadeIn();
        }
        if (this.config.useThumbnailOnly) {
            _fileInputLabel.hide();
            _secFileInputLabel.width("100%").fadeIn();
        }
        this.hasDocumentBeenLoadedOne = true;
    }
    _setUpFileUpload() {
        const _fileInput = this._getFileInput();
        const _fileInputLabel = this._getFileInputLabel();

        const _options = {
            dataType: 'json',
            done: (e, data) => {
                const file = data.result.List[0]
                const success = this._fileUploadDone(e, data);
                if (success) {
                    this._setValuesForPrimaryFile(file);
                    this._determineFileExt(file.name, file.fileId, _fileInputLabel);
                    this._setUpSecondaryFileUpload();
                }
            },
            fail: this._fileUploadFail.bind(this),
            add: (e, data) => {
                this._clearPreviousValuesForPrimaryFile();
                this._clearPreviousValuesForSecondaryFile();
                this._fileUploadAdd(e, data, this.validatePrimaryFile);
            },
            progressall: this._fileUploadProgressAll.bind(this),
            formData: this._fileUploadSetFormData.bind(this),
            dropZone: _fileInputLabel,
            fileInput: _fileInput,
            always: (e, data) => this.publishDocumentUploadEvent(data.files)
        };


        _fileInput.fileupload(_options);
    }

    private publishDocumentUploadEvent(files) {
        if (files.length <= 0) return;
        const eventState = {
            type: "uploadDocument",
            from: files[0].name,
            fileType: files[0].type
        };
        this.appsFacade.publishAppEvent(AppEvent.DocumentUpload, eventState);
    }

    getMessageText(translateId: string): string {
        return this.translateFacade.getTranslation(translateId);
    }

}
