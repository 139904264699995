import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { BaseEffectCommand } from './base-effect.command';
import { CommandListCollectionService } from "./command-list-collection.service";
import { PersonalizationService } from '../services/personalization.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class MapPersonalizationGroupItemToFieldCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private personalizationService: PersonalizationService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.mapPersonalizationGroupItemToFieldCommand,
            {
                id: (action, state) => this.getEffectFetchId(action),
                run: (
                    action: ReturnType<typeof CommandActions.mapPersonalizationGroupItemToFieldCommand>,
                    { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
                ) => {
                    const commandConfig = this.getCommandConfig(action);
                    const [groupName, itemName, valueField] = commandConfig.parameters;
                    const fieldValue = this.personalizationService.getPersonalizationGroupItem(groupName, itemName);
                    const appState = this.getAppState(commandConfig.appName, appsStore);
                    appState[valueField] = fieldValue;
                    const appEntity = this.createAppEntity(commandConfig.appName, appState);
                    const thisCommandActions = this.getUpdateCommandStateActions([appEntity], commandConfig);
                    return this.appendNextActions(thisCommandActions, commandConfig);
                },
                onError: (route, error) => AppsActions.onCommandError({ error, route })
            })
    );
}
