import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import { AppsEntity } from '../state/apps.models';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslatableEffectCommand } from './translatable-effect.command';
import { RouterService } from '../services/router.service';
import { AlertService } from '../services/alert.service';
import { ApiClientService } from '../services/api-client.service';
import { TranslateFacadeService } from '../services/translate-facade.service';
import { ApplicationInformation } from '../services/application-information.service';
import { InputApplication } from '../components/input-application';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { AppsConstants } from '../state/apps.constants';
import { CacheManagerService } from '../services/cachemanager.service';
import { AppEvent } from '../state/app-events.enum';
import { HelpersService } from '../services/helpers.service';

@Injectable()
export class OpenWindowCommand extends TranslatableEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected translateFacade: TranslateFacadeService,
        protected cacheManagerService: CacheManagerService,
        private router: RouterService,
        private alertService: AlertService,
        private apiClientService: ApiClientService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService,
        protected helpersService: HelpersService,) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.openWindowCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.openWindowCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const fieldMap = _.isNil(commandConfig.fieldMap) ? this.getFieldMap(commandConfig) : commandConfig.fieldMap;
                return this.openPopup(fieldMap, commandConfig, appsStore)
                    .pipe(
                        switchMap((result) => {
                            if (_.isBoolean(result))
                                return this.getNextActions(commandConfig);
                            const commandState = [result];
                            const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                            const publishEventAction = CommandActions.publishEvent({ event: result });
                            // TODO: investigate to confirm if the following actions are executed in sequence?
                            thisCommandActions.push(publishEventAction);
                            return this.appendNextActions(thisCommandActions, commandConfig);
                        })
                    )
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    private getParameter(commandConfig: CommandConfig, index: number): any {
        return commandConfig.parameters[index];
    }

    private openPopup(fieldMap: FieldMap, commandConfig: CommandConfig, appsStore: any): Observable<any> {

        const isNativeApp = iXing.IX_PlatformMobile;
        const configParams = {
            application: this.getParameter(commandConfig, 0),
            windowSize: this.getParameter(commandConfig, 1),
            treatAsRedirect: this.getParameter(commandConfig, 2),
            exclusiveForDoc: this.getParameter(commandConfig, 3),
            newTab: this.isTruthy(this.getParameter(commandConfig, 4) || "false"),
            fieldStoreDoc: this.getParameter(commandConfig, 5),
            generateExternalLink: this.isTruthy(this.getParameter(commandConfig, 6) || "false") && isNativeApp,
        };

        const baseUrl = this.router.absUrl();
        let url;
        if (_.isString(fieldMap.targetApp)
            && (!this.applicationInformation.isAppWithinWorkflow(fieldMap.targetApp)
                || this.applicationInformation.isBinaryRelay(fieldMap.targetApp))) {
            url = this.getNewUrlOutsideWorkFlow(fieldMap, baseUrl);
        } else {
            url = this.getNewUrlFromFieldMap(fieldMap, baseUrl);
        }

        if (_.isNil(url) || _.isEmpty(url)) return of(false);

        const suppressDocViewer = this.getSuppressDocViewerIfNativeApp();
        if (!suppressDocViewer && !configParams.generateExternalLink) {
            const s = this.domComponentRetrievalService.getAppComponent(commandConfig.appName) as InputApplication;
            let newAppWindow;
            if ((configParams.newTab) || (s.config && this.isTruthy(s.config.openInNewTab))) { // Attempt to open in new tab
                newAppWindow = window.open(url, '_blank');
            } else {
                let width = "1100";
                let height = "648";
                if (configParams.windowSize && _.isString(configParams.windowSize)) {
                    const tmp = configParams.windowSize.split(",");
                    if (tmp && tmp.length == 2) {
                        height = tmp[0];
                        width = tmp[1];
                    }
                }
                newAppWindow = window.open(url, '', 'width=' + width + ',height=' + height + ',scrollbars=1,resizable=1');
            }
            if (!newAppWindow && !IX_isWebView()) {
                const message = this.getTranslation('POPUP-BLOCKED');
                this.alertService.enhanceAlert(message);
            }
        } else if (configParams.generateExternalLink) {
            return from(this.apiClientService.generateExternalLinkRequest(url))
                .pipe(
                    switchMap((formData) => {
                        const externalURL = _.get(formData, 'ExternalUrl') || "";
                        const externalLinkEvent = this.createPublishOnExternalLinkEvent(externalURL);
                        return of(externalLinkEvent);
                    })
                );
        }

        // Add this parameter to define the field names used to store documentInfo
        let documentName = '';
        if (configParams.fieldStoreDoc) {
            const state = this.getAppState(fieldMap.targetApp, appsStore);
            const params = configParams.fieldStoreDoc.split(',');
            if (state && params[0] && state[params[0].trim()]) {
                documentName = state[params[0].trim()] as string;
                const fileNameWithExtension = /[^\\]*\.(\w+)$/;
                if (!documentName.match(fileNameWithExtension) && params[1] && state[params[1].trim()]) {
                    const documentType = state[params[1].trim()] as string;
                    documentName = documentName + documentType.substring(documentType.lastIndexOf("."), documentType.length);
                }
            }
        }
        const downloadEvent = this.helpersService.publishOnDocumentDownloadEvent(url, documentName);
        return of(downloadEvent);
    }

    private createPublishOnExternalLinkEvent(url: string): AppsEntity {
        const eventName = AppEvent.ExternalUrl;
        const baseUrl = window.location.origin; // Return the protocol, hostname and port number of a URL
        const isUrl = this.isUrl(url); // valid url
        const eventData = {
            type: "externalWebview",
            destination: (isUrl ? url : (baseUrl + url))
        };
        return this.createAppEntity(eventName, eventData);
    }

    private getSuppressDocViewerIfNativeApp() {
        const themeProperty = this.getThemeProperty('SuppressDocViewerIfNativeApp');
        let suppressDocViewer = false;
        if (themeProperty && themeProperty.Value1 && iXing.IX_PlatformMobile) {
            suppressDocViewer = this.isTruthy(themeProperty.Value1);
        }
        return suppressDocViewer;
    }

    private getNewUrlOutsideWorkFlow(fieldMap: FieldMap, baseUrl: string | void): string {
        baseUrl = (baseUrl == null) ? this.router.absUrl() : baseUrl;
        const isTargetAppUrl = this.isUrl(fieldMap.targetApp);
        if (isTargetAppUrl)
            return fieldMap.targetApp;
        const isV4App = this.applicationInformation.isV4App(fieldMap.targetApp);
        const params = this.getParamsFromFieldMap(fieldMap, !isV4App);
        let extension = ".aspx";
        let appName = fieldMap.targetApp.replace(/\./g, '_');
        let suffix = "";
        let folder = "";
        const newWF = !_.isEmpty(fieldMap.targetWF);
        if (isV4App && !newWF) { // TODO: how to identify a v4 angular vs angular js workflow
            extension = this.applicationInformation.isBinaryRelay(fieldMap.targetApp) ? ".ashx" : ".aspx;";
            folder = this.applicationInformation.getFolder(fieldMap.targetApp);
            folder = (!_.isNil(folder) && folder != "") ? ("/" + folder) : "";
        } else if (newWF) {
            suffix = fieldMap.targetWF.normalize();
            appName = fieldMap.targetWF.replace(/\./g, '_');
            const urlInfo = this.getIcUrl(baseUrl as string);
            params.push('s=' + fieldMap.targetApp.normalize());
            suffix = urlInfo.prefix + '/w/' + suffix;
        }
        return folder + "/Apps/" + appName + extension + suffix + "?" + params.join("&");
    }

    private getNewUrlFromFieldMap(baseUrl, map) {
        const params = this.getParamsFromFieldMap(map, false);
        params.push("s=" + map.targetApp.normalize());
        baseUrl = this.getQueryUrl(baseUrl);
        const urlInfo = this.getIcUrl(baseUrl);
        baseUrl = '/' + urlInfo.folder + '/' + urlInfo.page + urlInfo.prefix + '/w/' + urlInfo.wf + '?' + params.join("&");
        return baseUrl;
    }

    private getQueryUrl(url) {
        let _baseUrl = window.location.protocol + '://' + window.location.host;
        const port = parseInt(window.location.port);
        if (port != 443 && port != 80) {
            _baseUrl = _baseUrl + ':' + port;
        }
        return url.replace(_baseUrl, '');
    }

    private getParamsFromFieldMap(fieldMap: FieldMap, isV1: boolean): string[] {
        const params = [];
        fieldMap.urlParams?.forEach(urlParam => params.push(urlParam));
        if (fieldMap.directive && fieldMap.directive != AppsConstants.triggerDirective.none)
            params.push("IX_OB=" + (isV1 ? AppsConstants.triggerDirective[fieldMap.directive] : fieldMap.directive));

        else
            params.push("IX_OB=" + (isV1 ? "NONE" : AppsConstants.triggerDirective.none));
        return params;
    }
}
