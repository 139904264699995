/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
const DevExpress = require('devextreme/bundles/modules/core');
const ui = require('devextreme/bundles/modules/ui');
ui.dxList = require('devextreme/ui/list');

const NOT_EXISTING_INDEX = -1;

const indexExists = function(index) {
    return index !== NOT_EXISTING_INDEX;
};

const _dxList = DevExpress.ui.dxList.redefine({
    _supportedKeys: function _supportedKeys() {
        var _this = this;

        var that = this;
        var parent = this.callBase();

        var deleteFocusedItem = function deleteFocusedItem(e) {
            if (that.option("allowItemDeleting")) {
                e.preventDefault();
                that.deleteItem(that.option("focusedElement"));
            }
        };

        var moveFocusedItem = function moveFocusedItem(e, moveUp) {
            var editStrategy = _this._editStrategy;

            var focusedElement = _this.option("focusedElement");

            var focusedItemIndex = editStrategy.getNormalizedIndex(focusedElement);

            var isLastIndexFocused = focusedItemIndex === _this._getLastItemIndex();

            if (isLastIndexFocused && _this._isDataSourceLoading()) {
                return;
            }

            if (that.option('icActivateMoveMode') && that.option("itemDragging.allowReordering")) {
                var nextItemIndex = focusedItemIndex + (moveUp ? -1 : 1);
                var $nextItem = editStrategy.getItemElement(nextItemIndex);

                _this.reorderItem(focusedElement, $nextItem);

                _this.scrollToItem(focusedElement);

                e.preventDefault();
            } else {
                var editProvider = _this._editProvider;
                var isInternalMoving = editProvider.handleKeyboardEvents(focusedItemIndex, moveUp);

                if (!isInternalMoving) {
                    moveUp ? parent.upArrow(e) : parent.downArrow(e);
                }
            }
        };

        var enter = function enter(e) {
            if (!this._editProvider.handleEnterPressing()) {
                parent.enter.apply(this, arguments);
            }
        };

        var space = function space(e) {
            const icActivateMoveMode = that.option('icActivateMoveMode');
            const $focusedElement = $(that.option('focusedElement'));

            if (!this._editProvider.handleEnterPressing()) {
                parent.space.apply(this, arguments);
            }
            if ($focusedElement && $focusedElement.length === 1) {
                that.option('icActivateMoveMode', !icActivateMoveMode);
                if (icActivateMoveMode) {
                    $focusedElement.attr('aria-pressed', false);
                    $focusedElement.removeClass('dx-sortable-source');
                } else {
                    $focusedElement.attr('aria-pressed', true);
                    $focusedElement.addClass('dx-sortable-source');
                }
            }
        };

        return _.extend({}, parent, {
            del: deleteFocusedItem,
            upArrow: function upArrow(e) {
                return moveFocusedItem(e, true);
            },
            downArrow: function downArrow(e) {
                return moveFocusedItem(e);
            },
            enter: enter,
            space: space
        });
    },
    //IC Override note: from \DevExtreme\js\ui\collection\ui.collection_widget.edit.js
    reorderItem: function(itemElement, toItemElement) {

        const deferred = new DevExpress.ic.core.deferredUtils.Deferred();

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        const strategy = this._editStrategy;

        const $movingItem = strategy.getItemElement(itemElement);
        const $destinationItem = strategy.getItemElement(toItemElement);
        const movingIndex = strategy.getNormalizedIndex(itemElement);
        const destinationIndex = strategy.getNormalizedIndex(toItemElement);

        const changingOption = this._dataSource ? 'dataSource' : 'items';

        const canMoveItems = indexExists(movingIndex) && indexExists(destinationIndex) && movingIndex !== destinationIndex;
        if(canMoveItems) {
            deferred.resolveWith(this);
        } else {
            deferred.rejectWith(this);
        }

        return deferred.promise().done(function() {
            $destinationItem[strategy.itemPlacementFunc(movingIndex, destinationIndex)]($movingItem);

            strategy.moveItemAtIndexToIndex(movingIndex, destinationIndex);
            this._updateIndicesAfterIndex(movingIndex);

            //IC change start
            //IC change description: Due to this code only running in keyboard-action reordering,
            // and mutating array, but not calling .onReorder, callback needed to update index field values.
            const reorderCallback = that.option('itemDragging.icOnReorderByKeyboard');
            const icActivateMoveMode = that.option('icActivateMoveMode')
            if (typeof reorderCallback === 'function') {
                const $focusedElement = $(that.option('focusedElement'));
                reorderCallback({
                    focusedElementItemData: $focusedElement.data().dxListItemData,
                    focusKeyboardHandlerCallback: $target => {
                        if (icActivateMoveMode) $target.addClass('dx-sortable-source');
                        that.option.call(that, 'focusedElement', $target);
                        that._setFocusedItem.call(that, $target);
                        that.focus();
                    },
                });
            }
            //IC change end

            that.option('selectedItems', that._getItemsByKeys(that._selection.getSelectedItemKeys(), that._selection.getSelectedItems()));

            if(changingOption === 'items') {
                that._simulateOptionChange(changingOption);
            }
            that._itemEventHandler($movingItem, 'onItemReordered', {
                fromIndex: strategy.getIndex(movingIndex),
                toIndex: strategy.getIndex(destinationIndex)
            }, {
                excludeValidators: ['disabled', 'readOnly']
            });
        });
    },
});

DevExpress.registerComponent("dxList", _dxList);
