/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxTabPanel = require('devextreme/ui/tab_panel');

function strategy(element) {
  return element && element.get(0);
};

function getPublicElement(element) {
  return strategy(element);
}

var TabPanel = DevExpress.ui.dxTabPanel.redefine({
    _refreshActiveDescendant: function () {
        if (!this._tabs) {
            return
        }
        var tabs = this._tabs,
            tabItems = tabs.itemElements(),
            $activeTab = $(tabItems[tabs.option("selectedIndex")]),
            id = this.getFocusedItemId();
        this.setAria("controls", void 0, $(tabItems));
        // Check to see if element with this ID exists to prevent
        //  a invalid aria attribute value
        if ($('#' + id).length > 0) {
            this.setAria("controls", id, $activeTab);
        }
    },

    _optionChanged: function(args) {
        const name = args.name;
        const value = args.value;
        const fullName = args.fullName;

        switch(name) {
            case 'dataSource':
                this.callBase(args);
                break;
            case 'items':
                this._setTabsOption(name, this.option(name));
                this._updateLayout();
                if(!this.option('repaintChangesOnly')) {
                    this._tabs.repaint();
                }
                this.callBase(args);
                break;
            case 'width':
                this.callBase(args);
                this._tabs.repaint();
                break;
            case 'selectedIndex':
            case 'selectedItem': {
                this._setTabsOption(fullName, value);

                this.callBase(args);

                //IC override starts here
                // DevExtreme slide-animates the panel from the right or left. We turn animation off,
                // and that effectively gives a zero duration. However, when something event-driven (like WalkMe)
                // interrupts and causes the animation to hang, there is a visible flash of the selected tab's
                // content, alongside the already-selected tab. We need to hide that explicitly.
                // The prior approach of hiding with overflow:hidden breaks position:sticky.
                this._$itemContainer.children('.dx-item-selected').css('transform', 'none');
                this._$itemContainer.children(':not(.dx-item-selected)').classList?.add('dx-multiview-item-hidden');
                //IC override ends here

                if(this.option('focusStateEnabled') === true) {
                    const selectedIndex = this.option('selectedIndex');
                    const selectedTabContent = this._itemElements().eq(selectedIndex);
                    this.option('focusedElement', getPublicElement(selectedTabContent));
                }
                break;
            }
            case 'itemHoldTimeout':
            case 'focusStateEnabled':
            case 'hoverStateEnabled':
                this._setTabsOption(fullName, value);
                this.callBase(args);
                break;
            case 'scrollingEnabled':
            case 'scrollByContent':
            case 'showNavButtons':
                this._setTabsOption(fullName, value);
                break;
            case 'focusedElement': {
                const id = value ? $(value).index() : value;
                const newItem = value ? this._tabs._itemElements().eq(id) : value;
                this._setTabsOption('focusedElement', getPublicElement(newItem));
                this.callBase(args);
                break;
            }
            case 'itemTitleTemplate':
                this._setTabsOption('itemTemplate', this._getTemplateByOption('itemTitleTemplate'));
                break;
            case 'onTitleClick':
                this._createTitleClickAction();
                this._setTabsOption('onItemClick', this._titleClickAction.bind(this));
                break;
            case 'onTitleHold':
                this._createTitleHoldAction();
                this._setTabsOption('onItemHold', this._titleHoldAction.bind(this));
                break;
            case 'onTitleRendered':
                this._createTitleRenderedAction();
                this._setTabsOption('onItemRendered', this._titleRenderedAction.bind(this));
                break;
            case 'loop':
                this._setTabsOption('loopItemFocus', value);
                break;
            case 'badgeExpr':
                this._invalidate();
                break;
            default:
                this.callBase(args);
        }
    },
});

DevExpress.registerComponent('dxTabPanel', TabPanel);
