import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import { from, throwError, EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { BaseEffectCommand } from './base-effect.command';
import { UtilService } from '../services/util.service';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class StopExecutionIfCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        private utilService: UtilService,
        protected cacheManagerService: CacheManagerService,
        protected commandListCollectionService: CommandListCollectionService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.stopExecutionIfCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.stopExecutionIfCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const { workflow, themeName } = appsStore;
                const ruleConfig = this.getConditionalRules(commandConfig);
                if (_.isNil(ruleConfig?.functionName))
                    return throwError("Unable to determine stop execution if rules.");
                const appState = this.getAppState(commandConfig.appName, appsStore);
                const promise = this.utilService.updateConditionalFormatRulesContext(commandConfig.appName, appState);
                return from(promise)
                    .pipe(
                        switchMap((contextObj) => {
                            const shouldStop = this.callWindowFunction(workflow, themeName, ruleConfig.functionName, contextObj);
                            if (shouldStop) {
                                this.completeCommandList(commandConfig.commandListName, commandConfig.executionId);
                                return EMPTY;
                            }

                            return this.getNextActions(commandConfig);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route })
        })
    );

}
