import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { BaseEffectCommand } from './base-effect.command';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class RunDynamicReplacementCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        protected dynamicReplacementService: DynamicReplacementService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.runDynamicReplacementCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.runDynamicReplacementCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const targetAppName = commandConfig.parameters[1];
                const appState = this.getAppState(commandConfig.appName, appsStore);
                const replacementPromise = this.runDynamicReplacement(appState, commandConfig);
                return from(replacementPromise)
                    .pipe(
                        switchMap(replacementValue => {
                            const targetAppField = commandConfig.parameters[2];
                            appState[targetAppField] = replacementValue;
                            const commandState = {
                                id: targetAppName,
                                state: appState
                            };
                            const thisCommandActions = this.getUpdateCommandStateActions([commandState], commandConfig);
                            return this.appendNextActions(thisCommandActions, commandConfig, null);
                        })
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    runDynamicReplacement(appState: AppState, commandConfig: CommandConfig): Promise<string> {
        const [dynamicReplacementExpression, appName] = commandConfig.parameters;
        const options = {
            appName,
            data: appState,
            force: false
        };
        return this.dynamicReplacementService.getDynamicValue(dynamicReplacementExpression, options);
    }

}
