/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
var aria = require('./dx.aria');

ui.dxSelectBox = require('devextreme/ui/select_box');

var icdxDropDown = DevExpress.ui.dxSelectBox.inherit({
  setAria: function () {
    aria.setAria.apply(this, arguments);

    // Give text input a labelledby of the table header if present
    var textbox = this._$element.find('input.dx-texteditor-input');
    if (textbox.length) {
      textbox.attr("role", "combobox");
      var describedBy = textbox.closest('td.dx-editor-cell').attr('aria-describedby');
      if (describedBy) {
        textbox.attr("aria-labelledby", describedBy);
      }
    }
    
  },
  filterAria: function (args) {
    switch (args.name) {
      case 'owns':
      case 'activedescendant':
        // Do not add aria-owns or aria-activedescendant. 
        args.ignore = true;
        break;
    }
  },
  _initOptions: function (options) {
    if (options.placeholder !== '') {
      options.placeholder = options.placeholder || this._options.placeholder;
    }
    this.option(options);
  },
  _listContentReadyHandler: function () {
    this.callBase();
    this._scrollToSelectedItem();
    this._focusSelectedElement();
  },
  _listConfig: function () {
    // Config for dropdown list to change dxList.useNativeScrolling
    // https://js.devexpress.com/Documentation/18_2/ApiReference/UI_Widgets/dxList/Configuration/#useNativeScrolling
    var result = this.callBase();

    var themeProp = IX_GetThemeProperty('FixMacOSDropdownOverscroll');
    if (
      !themeProp ||
      !_.isString(themeProp.Value1) ||
      themeProp.Value1.toLowerCase() !== 'true'
    ) {
      return result;
    }

    // We need to fix this component's behavior in Safari desktop browser, which bubbles the scroll
    // event to the dropdown's parent when the popup's downward scrolling bottoms out. This has the
    // unwanted effect of closing the dropdown.
    var overscrolls =
      'CSS' in window && !CSS.supports('overscroll-behavior', 'contain');

    // We won't need to fix *mobile*
    var touchScreen = 'ontouchstart' in document.documentElement;

    if (overscrolls && !touchScreen) {
      // Leaving dxList.useNativeScrolling at default usually creates the best UX, but not in the
      // above-described case. Here, we opt for DevExtreme's synthesized scrolling behavior so that
      // the dropdown stays open and the control's container does not scroll.
      result.useNativeScrolling = false;
    }

    return result;
  }
});

DevExpress.registerComponent('dxSelectBox', icdxDropDown);
