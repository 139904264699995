import { Injectable } from '@angular/core';

@Injectable()
export class ListGroupingService {
  
  constructor() {}

    groupingExpanders = {};
    mtInputApp = {
        firstWithData: '',
        AccountId: 0,
        listApps: []
    };
    mtListApp = {
        listAppName: '',
        recordCount: -1,
        expanded: true,
        getScope: undefined
    };

    // assumptions: 
    // inputApp is parent of several listApps
    // multiple inputApps are calling this _groupingExpanderSynchronizer
    // InitList below is called only once per listApp init

    InitList(inputAppName, listAppName, callBack) {
        // set items/listApps for this inputApp
        let syncItem = -1;
        let isNewInputApp = false;
        if (!_.has(this.groupingExpanders, inputAppName)) {
            this.groupingExpanders[inputAppName] = _.cloneDeep(this.mtInputApp); // add inputApp
            isNewInputApp = true;
        } else {
            syncItem = _.findIndex(this.groupingExpanders[inputAppName].listApps, {
                'listAppName': listAppName
            }); // find listApp
        }
        if (syncItem === 0 || isNewInputApp) { // first item in listApps
            this.groupingExpanders[inputAppName].firstWithData = ''; // reset firstWithData
        }
        if (syncItem < 0) {
            const app = _.cloneDeep(this.mtListApp);
            app.listAppName = listAppName;
            app.getScope = callBack;
            this.groupingExpanders[inputAppName].listApps.push(app); // add to listApps
        } else {
            this.groupingExpanders[inputAppName].listApps[syncItem].recordCount = -1; // reset it
        }
    };

    
    OnLoaded($scope) {
        return (data) => {
            this.UpdateList(
                $scope.applet.containerName,
                $scope.applet.name,
                data.length,
                $scope.model.AccountId.value
            );
        }
    };

    private UpdateList(inputAppName, listAppName, recordCount, AccountId) {
        // update entries for this inputApp
        const syncItem = _.findIndex(this.groupingExpanders[inputAppName].listApps, {
            'listAppName': listAppName
        });
        // reset when new account
        if (this.groupingExpanders[inputAppName].AccountId !== AccountId) {
            const origId = this.groupingExpanders[inputAppName].AccountId;
            this.groupingExpanders[inputAppName].AccountId = AccountId;
            this.groupingExpanders[inputAppName].firstWithData = '';
            if (origId > 0) {
                _.each(this.groupingExpanders[inputAppName].listApps, (item, index) => {
                    item.recordCount = -1;
                });
            }
        }
        const listApp = this.groupingExpanders[inputAppName].listApps[syncItem];
        if (listApp.recordCount >= 0) {
            return false; // bail if repeat
        }
        this.groupingExpanders[inputAppName].listApps[syncItem].recordCount = recordCount;

        // find firstWithData
        if (this.groupingExpanders[inputAppName].firstWithData === '') {
            let firstWithData = '';
            let olderSybsNoData = 0;
            _.each(this.groupingExpanders[inputAppName].listApps, (item, index: number) => {
                if (item.recordCount < 0) { // dont evaluate if preceding ones haven't recd data
                    olderSybsNoData++;
                }
                if (
                    olderSybsNoData === 0 && item.recordCount > 0 && firstWithData.length === 0
                ) { // other ones haven't gotten data so we are it
                    firstWithData = item.listAppName;
                }
                if (syncItem === index) { // only loop until our position
                    return false;
                }
            });
            if (firstWithData.length > 0) {
                // if (firstWithData !== groupingExpanders[inputAppName].firstWithData) {
                this.groupingExpanders[inputAppName].firstWithData = firstWithData;
                this.FirstWithDataFound(inputAppName, '');
            }
        } else { // it was already found
            this.FirstWithDataFound(inputAppName, listAppName);
        }
    };

    // broadcast to subscribers
    private FirstWithDataFound(inputAppName, listAppName) {
        const firstWithData = this.groupingExpanders[inputAppName].firstWithData;
        _.each(this.groupingExpanders[inputAppName].listApps, (item, index) => {
            this.ProcessFirstWithData(item.getScope(), firstWithData, item.listAppName);
        });
    };


    private ProcessFirstWithData($scope, firstWithData, listAppName) {
        const dataGrid = $scope.gridInstance;
        let key = dataGrid.getKeyByRowIndex(0);

        if (!!key) {
            this.ExpandCollapse(dataGrid, firstWithData, listAppName, key, $scope.applet.containerName);
        } else {
            setTimeout(() => {
                key = dataGrid.getKeyByRowIndex(0);
                this.ExpandCollapse(dataGrid, firstWithData, listAppName, key, $scope.applet.containerName);
            }, 0);
        }
    };

    private ExpandCollapse(dataGrid, firstWithData, listAppName, key, inputAppName) {
        if (!!key) {
            const syncItem = _.find(this.groupingExpanders[inputAppName].listApps, {
                'listAppName': listAppName
            }); // find listApp

            syncItem.expanded = dataGrid.isRowExpanded(key);
            if (firstWithData === listAppName) {
                if (!dataGrid.isRowExpanded(key)) {
                    dataGrid.expandAll();
                    syncItem.expanded = true;
                }
            } else {
                if (dataGrid.isRowExpanded(key)) {
                    dataGrid.collapseAll();
                    syncItem.expanded = false;
                }
            }
        }
    }

    private GetFirstWithData(inputAppName) {
        return this.groupingExpanders[inputAppName].firstWithData;
    };

    private GetGroupingExpanders() {
        return this.groupingExpanders;
    }

}
