/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxFileUploader = require('devextreme/ui/file_uploader');

var _dxFileUploader = DevExpress.ui.dxFileUploader.inherit({
    _createFile: function (value) {
        var file = this.callBase(value);
        file.isValidFilenameCharacters = true;
        file.isValid = function () {
            return this.isValidFileExtension && this.isValidMaxSize && this.isValidMinSize && this.isValidFilenameCharacters;
        };
        return file;
    },
    _renderFile: function (file) {
        this.callBase(file);
        if (!file.isValidFilenameCharacters) {
            file.$statusMessage.append(this._createValidationElement('icInvalidFilenameCharacterMessage'));
        }
    },
    _validateFile: function (file) {
        this.callBase(file);
        file.isValidFilenameCharacters = this._icValidateFilenameCharacters(file);
    },
    _icValidateFilenameCharacters: function (file) {
        if (this.option('icAllowInvalidFilenameCharacters')) {
            return true;
        }
        return !/[\\\/:\*\?"<>\|]/.test(file.value.name);
    },
});

DevExpress.registerComponent('dxFileUploader', _dxFileUploader);
