import { Component, Input } from '@angular/core';

@Component({
    selector: 'ic-canvas',
    template: `
    <ng-container [ngSwitch]="canvas.config.layout">
        <ic-canvas-natural *ngSwitchCase="'natural'" [canvas]="canvas" [context]="context" [config]="configValues"></ic-canvas-natural>
        <ic-canvas-natural *ngSwitchCase="'responsive'" [canvas]="canvas" [context]="context" [config]="configValues"></ic-canvas-natural>
        <ng-container *ngSwitchDefault><br /></ng-container>
        <!-- <ng-container *ngSwitchDefault>
            <ic-canvas-interactive *ngIf="configValues.format != 'landscape' && configValues.format != 'portrait'" [canvas]="canvas" [context]="context" [config]="configValues" [on-change]="onChange()"></ic-canvas-interactive>
            <ic-canvas-print *ngIf="configValues.format == 'landscape' || configValues.format == 'portrait'" [canvas]="canvas" [context]="context" [config]="configValues" [on-change]="onChange()"></ic-canvas-print>
        </ng-container> -->
    </ng-container>
    `
})
export class CanvasComponent {

    @Input() canvas;
    @Input() context;
    @Input("config") configValues;
    @Input("on-change") onChange;

}
