<dx-popup
  [showTitle]="true"
  [showCloseButton]="true"
  [focusStateEnabled]="true"
  [title]="title"
  [visible]="true"
  [width]="width"
  [height]="height"
  (onContentReady)="onContentReady"
  (onHiding)="onHiding"
  (onShowing)="onShowing"
>
  <div style="height: 90%">
    <dx-scroll-view direction="both">
      <div *ngIf="!options.isWriteMode">
        <label>To:</label><input type="text" [(ngModel)]="formToAddress" /> 
        <label>Subject:</label><input type="text" [(ngModel)]="formSubject" />
        <dx-button (click)="handleSendClicked" text="Send" icon="fa fa-paper-plane" style="display: inline-block"></dx-button>
        <textarea froala="froalaOptions" [(ngModel)]="formBody"></textarea>
      </div>
      <div *ngFor="let msg of options.emails">
        <div><span style="font-weight: bold">Date: </span>{{ msg.DateCreated }}</div>
        <div><span style="font-weight: bold">From: </span> "{{ msg.SenderName }}" &lt;{{ msg.FromAddress }}&gt;</div>
        <div><span style="font-weight: bold">To: </span>{{ msg.ToAddress }}</div>
        <div><span style="font-weight: bold">CC: </span>{{ msg.ToCCAddress }}</div>
        <p [innerHTML]="msg.Body"></p>
        <hr />
      </div>
    </dx-scroll-view>
  </div>
  <dx-button (click)="done" text="Done"></dx-button>
</dx-popup>
