<table [ngClass]="cssClass">
    <tbody>
        <tr>
            <td valign="top">
                <div class="fileupload-table">
                    <label class="fileinputsecondary-button" role="button" style="width:198px;height:210px;display:none">
                        <input [id]="ids?.secFileupload" type="file" name="files[]" [attr.data-url]="config.handlerSecUrl">
                    </label>
                    <label class="fileinput-button" role="button" style="width:230px;height:150px;">
                        <input [id]="ids?.fileupload" type="file" name="files[]" [attr.data-url]="config.handlerUrl" style="display:none">
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td valign="top" style="padding-right:0px;">
                <div [id]="ids?.progressBar" class="progress-bar blue" style="width: 100%;">
                    <span style="width: 0%;text-align:center;display:inline-block;line-height:18px"></span>
                </div>
                <div [id]="ids?.uploadedFile" class="uploadedFile" [hidden]="!fileName.length">
                    <table width="100%" cellpadding="0px" cellspacing="0px">
                        <tbody>
                            <tr>
                                <td>
                                    <input [(ngModel)]="fileName" class="dxeEditAreaSys" readonly="readonly" autocomplete="off" type="text" spellcheck="false" tabindex="0" role="textbox" />
                                </td>
                                <td>
                                    <div class="fileDeleteButton" (click)="deleteFile($event)"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>
    </tbody>
</table>