import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { NxModule } from '@nrwl/angular';
import { ReactiveComponentModule } from '@ngrx/component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppComponent } from './components/app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';
import * as fromApps from './state/apps.reducer';
import { ProjectEffects } from './project-effects.import';
import { ApplicationRefFacade } from './services/application-ref.facade';
import { EventsService } from './services/events.service';
import { AppsConstantsFacade } from './services/apps-constants.facade';
import { AppsFacade } from './state/apps.facade';
import { StoreService } from './services/store-service';
import { PersonalizationService } from './services/personalization.service';
import { DeviceService } from './services/device-information.service';
import { FieldFormatService } from './services/field-format.service';
import { ChartService } from './services/chart.service';
import { HighchartsService } from './services/highcharts.service';
import { AccessibilityService } from './services/accessibility.service';
import { DynamicReplacementService } from './services/dynamic-replacement.service';
import { FocusService } from './services/focus.service';
import { ValidationEngineService } from './services/validation-engine.service';
import { ValidationGroupService } from './services/validation-group.service';
import { EditableRowMupService } from './services/editable-row-mup.service';
import { EditableTreeListService } from './services/editable-tree-list.service';
import { FieldAdapter } from './services/field-adapter.service';
import { SpecialEffectsService } from './services/special-effects.service';
import { DomFocusService } from './services/dom-focus.service';
import { DomComponentRetrievalService } from './services/dom-component-retrieval.service';
import { WorkflowApplicationTree } from './services/workflow-application-tree.service';
import { ApplicationInformation } from './services/application-information.service';
import { GdprService } from './services/gdpr.service';
import { ConfigService } from './services/config.service';
import { CanvasLayoutRenderService } from './services/canvas-layout-render.service';
import { RouteGuards } from './services/route-guards.service';
import { WorkflowStepResolver } from './services/workflow-step-resolver';
import { TranslateFacadeService } from './services/translate-facade.service';
import { WorkflowConfigurationService } from './services/workflow-configuration.service';
import { RouterService } from './services/router.service';
import { ComponentService } from './services/component.service';
import { AlertService } from './services/alert.service';
import { ApiClientService } from './services/api-client.service';
import { EcdUtilService } from './services/clientservices/ecdutil.service';
import { EcdService } from './services/clientservices/ecd.service';
import { EcdGroupService } from './services/clientservices/ecdgroup.service';
import { QryAggregateProfitAndLossLiteService } from './services/clientservices/qryaggregateprofitandlosslite.service';
import { QryAggregateProfitAndLossLiteServiceRBC } from './services/clientservices/qryaggregateprofitandlosslite-rbc.service';
import { CustomTranslationLoader } from './translate/custom-translation.loader';
import { CustomMissingTranslationHandler } from './translate/custom-missing-translation.handler';
import { ThemeService } from './services/theme.service';
import { ComplianceService } from './services/compliance.service';
import { DragDropHelperService } from './services/dragdrophelper.service';
import { ListGroupingService } from './services/list-grouping.service';
import { ConditionalFormattingService } from './services/conditional-formatting.service';
import { TextAnnouncer } from './commands/text-announcer.service';
import { CsvGenerator } from './services/csv.generator';
import { CommandParser } from './commands/command-parser.service';
import { CommandListEngine } from './commands/command-list-engine.service';
import { CommandListFactory } from './commands/command-list-factory.service';
import { CommandListCollectionService } from './commands/command-list-collection.service';
import { AppShellComponent } from './components/app-shell.component';
import { NotFoundComponent } from './components/not-found.component';
import { WorkflowComponent } from './components/workflow.component';
import { WorkflowStepComponent } from './components/workflow-step.component';
import { ModalService } from './services/modal.service';
import { UtilService } from './services/util.service';
import { UtilListService } from './services/util-list.service';
import { DataService } from './services/data.service';
import { DataSourceService } from './services/datasource.service';
import { HelpersService } from './services/helpers.service';
import { SessionTimeout } from './services/session-timeout.service';
import { CacheManagerService } from './services/cachemanager.service';
import { MetaTagsService } from './services/metatags.service';
import { DefaultFieldMapperFactory, ApiFieldMapperFactory } from './services/field-mappers/field-mapper.factory';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    AppShellComponent,
    WorkflowComponent,
    WorkflowStepComponent
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    NxModule.forRoot(),
    ReactiveComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslationLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: false,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50, autoPause: true }) : [],
    StoreModule.forFeature(fromApps.APPS_FEATURE_KEY, fromApps.dynamicReducer),
    EffectsModule.forFeature(ProjectEffects)
  ],
  exports: [TranslateModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/ui/' },
    ApplicationRefFacade,
    CanvasLayoutRenderService,
    RouterService,
    ConfigService,
    ComponentService,
    RouteGuards,
    WorkflowStepResolver,
    TranslateFacadeService,
    WorkflowConfigurationService,
    PersonalizationService,
    EventsService,
    AlertService,
    AppsConstantsFacade,
    StoreService,
    AppsFacade,
    DeviceService,
    FieldFormatService,
    ChartService,
    HighchartsService,
    DomFocusService,
    DomComponentRetrievalService,
    WorkflowApplicationTree,
    ApplicationInformation,
    AccessibilityService,
    FieldAdapter,
    CsvGenerator,
    QryAggregateProfitAndLossLiteService,
    QryAggregateProfitAndLossLiteServiceRBC,
    ApiClientService,
    EcdUtilService,
    EcdService,
    EcdGroupService,
    ThemeService,
    FocusService,
    SpecialEffectsService,
    ComplianceService,
    DragDropHelperService,
    ListGroupingService,
    ConditionalFormattingService,
    EditableRowMupService,
    EditableTreeListService,
    TextAnnouncer,
    CommandParser,
    CommandListEngine,
    CommandListFactory,
    CommandListCollectionService,
    DynamicReplacementService,
    ValidationEngineService,
    ValidationGroupService,
    GdprService,
    ModalService,
    UtilService,
    UtilListService,
    DataService,
    DataSourceService,
    HelpersService,
    SessionTimeout,
    CacheManagerService,
    MetaTagsService,
    ApiFieldMapperFactory,
    DefaultFieldMapperFactory,
  ],
  entryComponents: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
