/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxTabs = require('devextreme/ui/tabs');

var FOCUS_UP = "up",
    FOCUS_DOWN = "down",
    FOCUS_LEFT = "left",
    FOCUS_RIGHT = "right",
    FOCUS_LAST = "last",
    FOCUS_FIRST = "first";

var Tabs = DevExpress.ui.dxTabs.redefine({
    _supportedKeys: function () {
        var enter = function (e) {
            var $itemElement = $(this.option("focusedElement"));
            if (!$itemElement.length) {
                return
            }
            this._itemClickHandler($.extend({}, e, {
                target: $itemElement,
                currentTarget: $itemElement
            }))
        },
            space = function (e) {
                e.preventDefault();
                enter.call(this, e)
            },
            move = function (location, e) {
                e.preventDefault();
                e.stopPropagation();
                this._moveFocus(location, e)
            };
        return _.extend(this.callBase(), {
            space: space,
            enter: enter,
            leftArrow: move.bind(this, FOCUS_LEFT),
            rightArrow: move.bind(this, FOCUS_RIGHT),
            upArrow: function () { return; },
            downArrow: function () { return; },
            pageUp: move.bind(this, FOCUS_UP),
            pageDown: move.bind(this, FOCUS_DOWN),
            home: move.bind(this, FOCUS_FIRST),
            end: move.bind(this, FOCUS_LAST)
        })
    },
});

DevExpress.registerComponent('dxTabs', Tabs);
