import { createAction, props } from '@ngrx/store';
import { AppsEntity } from './apps.models';

export const publishEvent = createAction(
  '@ic/events/publish',
  props<{ event: AppsEntity }>()
);

export const showModalPopup = createAction(
  '@ic/events/show-modal-popup',
  props<{ commandConfig: CommandConfig, fieldMap: FieldMap }>()
);

export const destroyModalPopup = createAction(
  '@ic/events/destroy-modal-popup',
  props<{ popupId: string }>()
);

export const delayAndContinueWithNextCommand = createAction(
  '@ic/command/delay-and-continue-with-next-command',
  props<{ timeout: number, commandConfig: CommandConfig, prevValue?: any }>()
);

export const eventPublishCommand = createAction(
  '@ic/command/event-publish',
  props<{ commandConfig: CommandConfig }>()
);

export const eventSetValuesCommand = createAction(
  '@ic/command/event-set-values',
  props<{ commandConfig: CommandConfig }>()
);

export const setValuesCommand = createAction(
  '@ic/command/set-values',
  props<{ commandConfig: CommandConfig }>()
);

export const clientSetValuesCommand = createAction(
  '@ic/command/client-set-values',
  props<{ commandConfig: CommandConfig }>()
);

export const serverSetValuesCommand = createAction(
  '@ic/command/server-set-values',
  props<{ commandConfig: CommandConfig }>()
);

export const applyFilterMapperCommand = createAction(
  '@ic/command/apply-filter',
  props<{ commandConfig: CommandConfig }>()
);

export const applyClientSideFilterCommand = createAction(
  '@ic/command/apply-client-side-filter',
  props<{ commandConfig: CommandConfig }>()
);

export const customBrowserCodeCommand = createAction(
  '@ic/command/custom-browser-code',
  props<{ commandConfig: CommandConfig }>()
);

export const stopExecutionIfCommand = createAction(
  '@ic/command/stop-execution-if',
  props<{ commandConfig: CommandConfig }>()
);

export const triggerSpecialEffectCommand = createAction(
  '@ic/command/trigger-special-effect',
  props<{ commandConfig: CommandConfig }>()
);

export const clearStepEventsCommand = createAction(
  '@ic/command/clear-step-events',
  props<{ commandConfig: CommandConfig }>()
);

export const redirectToExternalURLCommand = createAction(
  '@ic/command/redirect-to-external-url',
  props<{ commandConfig: CommandConfig }>()
);

export const datagridPaginationButtonsCommand = createAction(
  '@ic/command/datagrid-pagination-buttons',
  props<{ commandConfig: CommandConfig }>()
);

export const clearAllFileMultiUploadFieldsCommand = createAction(
  '@ic/command/clear-all-file-multi-upload-field',
  props<{ commandConfig: CommandConfig }>()
);

export const logoutCommand = createAction(
  '@ic/command/logout',
  props<{ commandConfig: CommandConfig }>()
);

export const redirectMapperCommand = createAction(
  '@ic/command/redirect',
  props<{ commandConfig: CommandConfig }>()
);

export const apiMapperCommand = createAction(
  '@ic/command/api-command',
  props<{ commandConfig: CommandConfig }>()
);

export const triggerWebHookCommand = createAction(
  '@ic/command/trigger-webhook',
  props<{ commandConfig: CommandConfig }>()
);

export const refreshListCommand = createAction(
  '@ic/command/refresh-list',
  props<{ commandConfig: CommandConfig }>()
);

export const refreshComponentCommand = createAction(
  '@ic/command/refresh-component',
  props<{ commandConfig: CommandConfig }>()
);

export const runDynamicReplacementCommand = createAction(
  '@ic/command/run-dynamic-replacement',
  props<{ commandConfig: CommandConfig }>()
);

export const announceTextCommand = createAction(
  '@ic/command/announce-text',
  props<{ commandConfig: CommandConfig }>()
);

export const clearLocalDataSourceCommand = createAction(
  '@ic/command/clear-local-data-source',
  props<{ commandConfig: CommandConfig }>()
);

export const setCookieConsentStateCommand = createAction(
  '@ic/command/set-cookie-consent-state',
  props<{ commandConfig: CommandConfig }>()
);

export const toggleSideNavigationCommand = createAction(
  '@ic/command/toggle-side-navigation',
  props<{ commandConfig: CommandConfig }>()
);

export const pushToGoogleTagDataLayerCommand = createAction(
  '@ic/command/push-to-google-tag-data-layer',
  props<{ commandConfig: CommandConfig }>()
);

export const clearGoogleTagDataLayerCommand = createAction(
  '@ic/command/clear-google-tag-data-layer',
  props<{ commandConfig: CommandConfig }>()
);

export const saveListEditingChangesCommand = createAction(
  '@ic/command/save-list-editing-changes',
  props<{ commandConfig: CommandConfig }>()
);

export const discardListEditingChangesCommand = createAction(
  '@ic/command/discard-list-editing-changes',
  props<{ commandConfig: CommandConfig }>()
);

export const activateTabCommand = createAction(
  '@ic/command/activate-tab',
  props<{ commandConfig: CommandConfig }>()
);

export const tabToActivateConditionallyCommand = createAction(
  '@ic/command/tab-to-activate-conditionally',
  props<{ commandConfig: CommandConfig }>()
);

export const conditionalRedirectResolverCommand = createAction(
  '@ic/command/conditional-redirect-resolver',
  props<{ commandConfig: CommandConfig }>()
);

export const transferUserAgentMetricsInformationCommand = createAction(
  '@ic/command/transfer-user-agent-metrics-information',
  props<{ commandConfig: CommandConfig }>()
);

export const loginUserCommand = createAction(
  '@ic/command/login-user',
  props<{ commandConfig: CommandConfig }>()
);

export const setFocusCommand = createAction(
  '@ic/command/set-focus',
  props<{ commandConfig: CommandConfig }>()
);

export const setClassCommand = createAction(
  '@ic/command/set-class',
  props<{ commandConfig: CommandConfig }>()
);

export const closeModalPopupCommand = createAction(
  '@ic/command/close-modal-popup',
  props<{ commandConfig: CommandConfig }>()
);

export const openModalPopupCommand = createAction(
  '@ic/command/open-modal-popup',
  props<{ commandConfig: CommandConfig }>()
);

export const openWindowCommand = createAction(
  '@ic/command/open-window',
  props<{ commandConfig: CommandConfig }>()
);

export const enableDisableCommand = createAction(
  '@ic/command/enable-disable',
  props<{ commandConfig: CommandConfig }>()
);

export const enableDisableGroupBlockingCommand = createAction(
  '@ic/command/enable-disable-group-blocking',
  props<{ commandConfig: CommandConfig }>()
);

export const clearFilterCommand = createAction(
  '@ic/command/clear-filter',
  props<{ commandConfig: CommandConfig }>()
);

export const clearFieldValueCommand = createAction(
  '@ic/command/clear-field-value',
  props<{ commandConfig: CommandConfig }>()
);

export const setFirstDropdownItemCommand = createAction(
  '@ic/command/set-first-dropdown-item',
  props<{ commandConfig: CommandConfig }>()
);

export const printToPDFCommand = createAction(
  '@ic/command/print-to-pdf',
  props<{ commandConfig: CommandConfig }>()
);

export const clearClientServicesCacheCommand = createAction(
  '@ic/command/clear-client-services-cache',
  props<{ commandConfig: CommandConfig }>()
);

export const clearFormValuesCommand = createAction(
  '@ic/command/clear-form-values',
  props<{ commandConfig: CommandConfig }>()
);

export const retrievePersistedReportInZipMapperCommand = createAction(
  '@ic/command/retrieve-persisted-report-in-zip-mapper',
  props<{ commandConfig: CommandConfig }>()
);

export const exportFromListAppCommand = createAction(
  '@ic/command/export-from-list-app',
  props<{ commandConfig: CommandConfig }>()
);

export const savePersonalizeMenuCommand = createAction(
  '@ic/command/save-personalize-menu',
  props<{ commandConfig: CommandConfig }>()
);

export const setListSelectedValuesCommand = createAction(
  '@ic/command/set-list-selected-values',
  props<{ commandConfig: CommandConfig }>()
);

export const validateFormCommand = createAction(
  '@ic/command/validate-form',
  props<{ commandConfig: CommandConfig }>()
);

export const resetValidationGroupCommand = createAction(
  '@ic/command/reset-validation-group',
  props<{ commandConfig: CommandConfig }>()
);

export const selectItemsInListCommand = createAction(
  '@ic/command/select-items-in-list',
  props<{ commandConfig: CommandConfig }>()
);

export const setValuesForColumnInListCommand = createAction(
  '@ic/command/set-values-for-column-in-list',
  props<{ commandConfig: CommandConfig }>()
);

export const resetFieldsPristineCommand = createAction(
  '@ic/command/reset-all-fields-to-pristine',
  props<{ commandConfig: CommandConfig }>()
);

export const updatePersonalizationGroupItemCommand = createAction(
  '@ic/command/update-personalization-group-item',
  props<{ commandConfig: CommandConfig }>()
);

export const mapPersonalizationGroupItemToFieldCommand = createAction(
  '@ic/command/map-personalization-group-item-to-field',
  props<{ commandConfig: CommandConfig }>()
);

export const openCloseGroupsCommand = createAction(
  '@ic/command/open-close-groups',
  props<{ commandConfig: CommandConfig }>()
);

export const refreshTemplateAppCommand = createAction(
  '@ic/command/refresh-template-app',
  props<{ commandConfig: CommandConfig }>()
);

export const stop = createAction('@ic/command/noop');
