import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommandListCollectionService } from './command-list-collection.service';
import { DefaultFieldMapper } from '../services/field-mappers/default-field.mapper';
import { BaseEffectCommand } from './base-effect.command';
import { ThemeService } from '../services/theme.service';
import { CacheManagerService } from '../services/cachemanager.service';
import { PersonalizationService } from '../services/personalization.service';
import { DeviceService } from '../services/device-information.service';

@Injectable()
export class TriggerWebHookCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private themeService: ThemeService,
        private personalizationService: PersonalizationService,
        private deviceService: DeviceService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.triggerWebHookCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.triggerWebHookCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);
                const fieldMapper = new DefaultFieldMapper(appsState, this, this.themeService, this.cacheManagerService, this.personalizationService);
                const forceSetValue = false;
                // Step 1: perform field mapping
                const fieldMap = fieldMapper.execute(commandConfig, null, forceSetValue);
                const commandState = Object.values(fieldMapper.State);
                // Step 2: update store with new state
                const thisCommandActions = this.getUpdateCommandStateActions(commandState, commandConfig);
                // Step 3: trigger webhook
                const eventState = fieldMapper.State[fieldMap.targetApp];
                return this.triggerWebHook(eventState?.state, commandConfig, fieldMap)
                    .pipe(
                        switchMap(() => this.appendNextActions(thisCommandActions, commandConfig, commandState))
                    );
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    private triggerWebHook(eventData: Record<string, any>, commandConfig: CommandConfig, fieldMap: FieldMap): Observable<any> {
        const [config, eventType, isIframeData] = commandConfig.parameters;
        let actualEventType = eventType;

        const useBooleansThemeProp = this.themeService.getThemeProperty('UseBooleanPropsInWebhooks');
        if (useBooleansThemeProp?.Value1?.toLowerCase() === 'true') {
            const booleanPrefix = 'BOOL';
            Object.keys(eventData).forEach(function (key) {
                if (key.startsWith(booleanPrefix)) {
                    eventData[key.substr(booleanPrefix.length)] = eventData[key] && eventData[key].toString().toLowerCase() === 'true';
                    delete eventData[key];
                }
            });
        }


        if (this.isTruthy(isIframeData) && !_.isEmpty(eventData)) {
            const eventKey = _.first(fieldMap.targetFields);
            eventData = JSON.parse(eventData[eventKey]);
            if (eventData && eventData['ic.webhook']) {
                actualEventType = eventData['ic.webhook'];
                actualEventType = _.upperFirst(eventData['ic.webhook']);
                delete eventData['ic.webhook'];
            }
        }
        const result = this.deviceService.triggerWebHook(actualEventType, eventData, isIframeData);
        return result.success
            ? of(result)
            : throwError(result.message);
    }

}
