import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';
import { zipAll, switchMap, take } from 'rxjs/operators';
import * as fromAppSelectors from '../state/apps.selectors';
import importThemeModule from '../import-theme.module';
import { getLocaleKey } from '../translate/locale.functions';
import { EventsService } from './events.service';
import { SessionTimeout } from './session-timeout.service';
import { ThemeService } from './theme.service';
import { TranslateFacadeService } from './translate-facade.service';
import { QryAggregateProfitAndLossLiteService } from './clientservices/qryaggregateprofitandlosslite.service';
import { select, Store } from '@ngrx/store';
import { AppsPartialState } from '../state/apps.reducer';

@Injectable()
export class ConfigService {

    theme: ThemeConfiguration;
    themeName: string;
    shouldLoadTheme = true;
    uniqueTranslationId: string;
    config: WorkflowConfiguration;

    constructor(@Inject(DOCUMENT) private document,
        private store: Store<AppsPartialState>,
        private http: HttpClient,
        private translate: TranslateFacadeService,
        private eventsService: EventsService,
        private sessionTimeout: SessionTimeout,
        private themeService: ThemeService,
        private qryAggregateProfitAndLossLiteService: QryAggregateProfitAndLossLiteService) {
        this.theme = window.IX_Theme;
        this.uniqueTranslationId = window.uniqueTranslationId;
        this.themeName = this.theme.themeName.replace(/\s/g, '');
        this.config = {} as WorkflowConfiguration;
    }

    loadCurrent(): Observable<WorkflowConfiguration> {
        return of(this.config);
    }

    load(workflowName: string): Observable<WorkflowConfiguration> {

        const currentWorkflowName = this.getCurrentWorkflowName();
        if (currentWorkflowName == workflowName) return of(this.config);

        if (!_.isNil(window.$translateLocaleKey)) {
            this.translate.resetLang(window.$translateLocaleKey);
        }

        this.sessionTimeout.init();

        const mapFiles = {};
        const themeFile = {};

        return this.store.pipe(
            select(fromAppSelectors.getIsPlatformMobile),
            switchMap(isMobile => this.http.get<ThemeWorkflowConfiguration>( `/Membership/ExtPages/ilg.ashx?IC_UI_CONFIG=${workflowName}${isMobile ? '&platform=mobile' : ''}`)),
            take(1),
            switchMap((themeWorkflowConfig) => {
                this.eventsService.publishStartLoadConfiguration();
                this.config.constants = themeWorkflowConfig.Constants;
                this.config.eventMaps = themeWorkflowConfig.EventMaps;
                this.config.interstitialPages = themeWorkflowConfig.InterstitialPages;
                const configFiles = [].concat(themeWorkflowConfig.ConfigFiles);
                const allRequests = configFiles.map((jsonFile, i) => {
                    const paths: string[] = jsonFile.replace('/scripts/jig/', '').split('_IX');
                    const parts = _.first(paths).split('-');
                    const prefix = _.first(parts);
                    const fileType = _.last(parts);
                    if (this.themeName == prefix) themeFile[fileType] = i;
                    else mapFiles[fileType] = i;
                    return this.http.get(jsonFile);
                });
                const workflowLocalesObservable = this.loadWorkflowLocales(workflowName);
                allRequests.push(workflowLocalesObservable);
                if (this.shouldLoadTheme) allRequests.push(importThemeModule(this.themeName));

                this.injectStyleBundle(themeWorkflowConfig.StyleFiles);
                this.loadClientServiceData();

                return allRequests;
            }),
            zipAll(),
            switchMap((responses: Record<string, any>[]) => {

                this.handleThemeResponse(responses);
                this.handleConfigurationResponse(responses, mapFiles, themeFile);

                this.eventsService.publishEndLoadConfiguration(this.config);
                this.eventsService.reloadConfiguration("reload-service-configuration" + currentWorkflowName);

                return of(this.config);
            })
        );
    }

    private handleThemeResponse(responses: Record<string, any>[]): void {
        const themeResponse = _.last(responses);
        const themeModuleName = this.themeName + 'Module';
        if (this.shouldLoadTheme && !_.isNil(themeResponse[themeModuleName])) {
            console.log(this.themeName + ' theme module imported dynamically');
            this.shouldLoadTheme = false;
        }
    }

    private handleConfigurationResponse(responses: Record<string, any>[],
        mapFiles: Record<string, any>, themeFile: Record<string, any>): void {
        this.config.appInfo = this.mergeConfiguration(responses, mapFiles, themeFile, 'ApplicationMapper');
        this.config.formats = this.mergeConfiguration(responses, mapFiles, themeFile, 'LocaleFieldFormats');
        this.config.commandList = this.mergeConfiguration(responses, mapFiles, themeFile, 'CommandListService');
        this.config.tree = this.mergeTreeConfiguration(responses, mapFiles, themeFile, 'WorkFlowApplicationTree');
        this.config.overrideTemplates = this.mergeConfiguration(responses, mapFiles, themeFile, 'TemplateCacheDecorator');
        this.config.canvas = this.applyThemeConfigurationWorkflowCommands(responses[mapFiles['WorkFlow']], responses[themeFile['WorkFlow']]);
    }

    private getInjectCSSClassMap(): Record<string, string> {
        const responsiveClassesMap = {};
        this.theme.properties.forEach(property => {
            if (_.isNil(property)
                || _.isNil(property.PropertyName)
                || _.isNil(property.Value1)
                || _.isNil(property.Value2)) return;
            const propertyName = property.PropertyName.toLowerCase().trim();
            if (propertyName !== "injectcssclass") return;
            const responsiveClassName = "ix" + property.Value1.toLowerCase().trim()
            responsiveClassesMap[responsiveClassName] = property.Value2.trim();
        });
        return responsiveClassesMap;
    }

    private applyThemeLayoutCommands(themeAppMap, themeCommands, themeConfiguration, workflowCommands, responsiveClassesMap) {
        const resultCommands = [];
        workflowCommands.forEach(function (command) {
            if (!_.isNil(command.class)) {
                const auxClass = command.class.toLowerCase().trim();
                if (!_.isNil(responsiveClassesMap[auxClass])) {
                    command.class = command.class + " " + responsiveClassesMap[auxClass];
                }
            }
            if (command.cmd !== "placeholder") {
                resultCommands.push(command);
            } else if (_.get(themeAppMap, command.id) && _.get(themeConfiguration, themeAppMap[command.id])) {
                const themeAppName = _.get(themeConfiguration, themeAppMap[command.id]);
                if (_.get(themeCommands, themeAppName)) {
                    themeCommands[themeAppName].forEach(themeCommand => resultCommands.push(themeCommand));
                }
            }
        });
        return resultCommands;
    }

    private applyThemeConfigurationWorkflowCommands(workflowCommands, themeConfiguration) {
        const canvas = _.merge({}, workflowCommands, themeConfiguration);
        delete canvas.naturalLayoutCommands;
        const themeAppMap = {
            HeaderApplication: '_headerApp',
            LeftPanelApplication: '_leftPanel',
            RightPanelApplication: '_rightPanel',
            FooterApplication: '_footerApp'
        };
        const responsiveClassesMap = this.getInjectCSSClassMap();
        const themeCommands = themeConfiguration.naturalLayoutCommands || {};
        for (const step in canvas._steps) {
            const info = canvas._steps[step];
            const layout = info.config.layout;
            if (layout === 'natural') {
                info.layout[layout] = this.applyThemeLayoutCommands(themeAppMap, themeCommands, themeConfiguration, info.layout[layout], {});
            } else if (layout === 'responsive') {
                const config = info.layout[layout];
                for (const breakpoint in config) {
                    config[breakpoint] = this.applyThemeLayoutCommands(themeAppMap, themeCommands, themeConfiguration, config[breakpoint], responsiveClassesMap);
                }
            }
        }
        return canvas;
    }

    private mergeConfiguration(results, workflowFileMap, themeFieldMap, fileType) {
        const themeConfiguration = results[themeFieldMap[fileType]] || {};
        return _.merge({}, results[workflowFileMap[fileType]], themeConfiguration);
    }

    private mergeTreeConfiguration(results, workflowFileMap, themeFieldMap, fileType) {
        const workflowConfig = results[workflowFileMap[fileType]];
        const themeConfig = results[themeFieldMap[fileType]] || {};
        const nodes = themeConfig.nodes || [];
        const workflowMap = {};
        workflowConfig.nodes = workflowConfig.nodes || [];
        workflowConfig.nodes.forEach((node, index) => {
            workflowMap[node.name] = index;
        });
        nodes.forEach((node) => {
            if (_.isNil(workflowMap[node.name])) {
                workflowConfig.nodes.push(node);
            } else {
                _.merge(workflowConfig.nodes[workflowMap[node.name]], node);
            }
        });
        return workflowConfig;
    }

    private injectStyleBundle(styleFiles: string[]) {
        const idLink = "ic-ui-workflow-style";
        let link: HTMLLinkElement = this.document.getElementById(idLink);
        if (_.isNil(link)) {
            link = this.document.createElement('link');
            link.setAttribute('id', idLink);
            link.setAttribute('rel', 'stylesheet');
            this.document.head.prepend(link);
        }
        const styleFile = _.first(styleFiles);
        link.setAttribute('href', styleFile);
    }

    private getCurrentWorkflowName(): string {
        if (!this.config.tree) return null;
        return this.config.tree.name;
    }

    private loadWorkflowLocales(workflowName: string): Observable<any> {
        window.workflowName = workflowName;
        const localeKey = getLocaleKey();
        this.translate.resetLang(localeKey);
        return this.translate.useLang(localeKey);
    }

    private loadClientServiceData() {
        if (!window.IX_UserAuthenticated) return;
        if (!this.themeService.getThemeProperty('UseLocalDataSource')) return;
        this.qryAggregateProfitAndLossLiteService.clearCache();
        this.qryAggregateProfitAndLossLiteService.prefetchData();
    }
}
