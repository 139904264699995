/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxList = require('devextreme/ui/list');

var _dxList = DevExpress.ui.dxList.redefine({
    _renderItem: function (index, itemData, $container, $itemToReplace) {
        $container = $container || this._itemContainer();
        var $itemFrame = this._renderItemFrame(index, itemData, $container, $itemToReplace);
        this._setElementData($itemFrame, itemData, index);
        $itemFrame.attr(this.option('_itemAttributes'));

        this._attachItemClickEvent(itemData, $itemFrame);

        // We don't want this nested div, because nothing should get between table-row and table-cell
        var $itemContent = this._getItemContent($itemFrame);
        $itemContent.remove();

        var renderContentPromise = this._renderItemContent({
            index: index,
            itemData: itemData,
            container: $itemFrame,
            contentClass: this._itemContentClass(),
            defaultTemplateName: this.option('itemTemplate'),
        });

        var that = this;

        Promise.resolve(renderContentPromise).done(function ($itemContent) {
            that._postprocessRenderItem({
                itemElement: $itemFrame,
                itemContent: $itemContent,
                itemData: itemData,
                itemIndex: index,
            });
            that._executeItemRenderAction(index, itemData, $itemFrame);
        });
        return $itemFrame;
    },
});

DevExpress.registerComponent('dxList', _dxList);
