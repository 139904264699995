/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ChangeDetectionStrategy, ViewChild,  ElementRef, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { DxTextBoxComponent } from 'devextreme-angular';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { TextboxShared } from './text-box.shared';
import { DataService } from '../../services/data.service';
import { FieldFormatService } from '../../services/field-format.service';
import { AccessibilityService } from '../../services/accessibility.service';
import { ValidationEngineService } from '../../services/validation-engine.service';
import { UtilService } from '../../services/util.service';
import { ValidationGroupService } from '../../services/validation-group.service';

@Component({
  selector: 'ic-text-box',
  template: `<dx-text-box [(value)]="dxValue" [class]="cssClass" (onInitialized)="onInitialized($event)"></dx-text-box>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    ':host { display: contents; }',
  ]
})
export class TextboxComponent extends TextboxShared implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DxTextBoxComponent)
  textbox: DxTextBoxComponent;

  constructor(
    elementRef: ElementRef,
    translate: TranslateFacadeService,
    dataService: DataService,
    fieldFormatService: FieldFormatService,
    utilService: UtilService,
    validationEngine: ValidationEngineService,
    validationGroupService: ValidationGroupService,
    accessibilityService: AccessibilityService) {
    super(elementRef, translate, dataService, fieldFormatService, utilService, validationEngine, validationGroupService, accessibilityService);
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.baseOnDestroy()
  }

  ngAfterViewInit() {
    this.dxComponent = this.textbox;
    this.baseAfterViewInit();
  }
}
