/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
var _translator = require('devextreme/animation/translator');
ui.dxSortable = require('devextreme/ui/sortable');

var _dxSortable = DevExpress.ui.dxSortable.redefine({
    _move: function (position, $element) {
        var $item = $element || this._$dragElement;
        (0, _translator.move)($item, position);
        var dxList = this.option('component');
        var scrollSensitivity = this.option('scrollSensitivity');
        var canScrollWindow = dxList.option('icScrollWindowOnDrag');
        var itemBounds = $item.get(0).getBoundingClientRect();
        var startTop = this._startPosition ? this._startPosition.top : position.top;

        var isGoingDown = position.top > startTop;

        // scrolling back up after scrolling down
        if ((itemBounds.top < 0) || ((this._scrollOffset - startTop) >  position.top)) {
            isGoingDown = false;
        }      

        var viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        var top = isGoingDown ? itemBounds.top + scrollSensitivity : itemBounds.top - scrollSensitivity;
        var bottom = isGoingDown ? itemBounds.bottom + scrollSensitivity : itemBounds.bottom - scrollSensitivity;
        var isFullyVisible = top >= 0 && bottom > 0 && bottom <= viewportHeight;
        if (canScrollWindow && !isFullyVisible) {
            var scrollValue = isGoingDown
                ? (window.scrollY + scrollSensitivity + itemBounds.height)
                : (window.scrollY - scrollSensitivity - itemBounds.height);

            this._scrollOffset = scrollValue;
            $(window).scrollTop(scrollValue);
        }
    },
});

DevExpress.registerComponent("dxSortable", _dxSortable);
