import { Injectable } from "@angular/core";
import { RuleValidators } from "./validation-engine/rule-validators";
import { DataService } from "./data.service";
import { ValidationGroupConfig } from "./validation-engine/validation-group-config";
import { IcValidationRule, IcValidator } from "./validation-engine/ic-validator";
import { UtilService } from "./util.service";
import { ThemeService } from "./theme.service";

@Injectable()
export class ValidationEngineService extends RuleValidators {

    private trackDirtyState: boolean;
    private groups: ValidationGroupConfig[] = [];

    constructor(private dataService: DataService,
        private utilService: UtilService,
        private themeService: ThemeService) {
        super();
        this.init();
        const isDisabled = this.themeService.isTrackDirtyStateDisabled();
        this.trackDirtyState = isDisabled ? false : true;
        // TODO: implement warning!
        // let dirtyFormWarningText = this.getThemeDetail("DirtyFormWarningText");
        // dirtyFormWarningText = _.isNil(dirtyFormWarningText) ? "DIRTY-FORM-WARNING-TEXT" : dirtyFormWarningText;
        // this.helpersService.setUpLocationChangeWatch(this /* TODO was rootScope */, $scope, dirtyFormWarningText);
    }

    runValidationRulesConditions($scope, rules: IcValidationRule[]) {
        const context = _.isFunction($scope.getStateFromModel) ? $scope.getStateFromModel() : {};
        rules.forEach((rule) => {
            if (!_.isFunction(rule.getEnableState)) return;
            rule.enabled = rule.getEnableState.call(context);
        });
    }

    validate(value: string | number | boolean, validationRules: any[], name, $s): Promise<any>{            
        const result = {
            name,
            value,
            isValid: true,
            brokenRules: [],
            validationRules
        };
        const promises = [];
        const $ps = this.utilService.getAppContext($s);
        validationRules.forEach((rule) => {
            if (!rule.enabled) return;
            rule.value = value;
            const ruleValidator = this.get(rule.type);
            let shouldRunValidator = true;
            if (this.trackDirtyState) {
                rule.fieldIsDirty = this.dataService.isAppFieldDirty($ps.applet.name, $s.fieldName, value);
                shouldRunValidator = rule.fieldIsDirty || rule.type === 'required';
            }
            let validationPromise = Promise.resolve(true);
            if (shouldRunValidator)
                validationPromise = ruleValidator.validate(value, rule);
            promises.push(validationPromise);
        });

        if (promises.length == 0)
            return Promise.resolve(result);

        return Promise.all(promises)
            .then((values) => {
                result.isValid = values.reduce((b, c, i) => {
                    const n = validationRules[i].enabled ? i : i + 1;
                    validationRules[n].isValid = c;
                    if (!c || validationRules[n].warning) {
                        if (validationRules[n].warning) {
                            validationRules[n].isValid = !c;
                            c = true;
                        }
                        result.brokenRules.push(validationRules[n])
                    }
                    return c && b;
                }, result.isValid);
                return result;
            });
    }

    validateGroup(s, group: string) {
        const groupConfig = this.getGroupConfig(group);
        if (!groupConfig) {
            throw new Error("Validation group not found: " + group);
        }
        return groupConfig.validate(s);
    }

    getGroupConfig(group: string): ValidationGroupConfig {
        const result = $.grep(this.groups, (config) => {
            const configName = config.name ? config.name.toLowerCase() : config.name;
            const groupName = group ? group.toLowerCase() : group;
            return configName === groupName;
        });
        return (result.length) ? result[0] : void (0);
    }

    addGroup(group?): ValidationGroupConfig {
        let config = this.getGroupConfig(group);
        if (!config) {
            config = new ValidationGroupConfig(group);
            this.groups.push(config)
        }
        return config
    }

    registerValidatorInGroup(group: string, validator: IcValidator) {
        const groupConfig = this.addGroup(group);
        if (groupConfig) {
            return groupConfig.addValidator(validator);
        }
    }

    resetGroup(group) {
        const groupConfig = this.getGroupConfig(group);
        if (!groupConfig) {
            return; //throw new Error("Unknown validation group");
        }
        return groupConfig.resetAndGetFieldMap(false);
    }

    shouldRemoveGroup(group, validatorsInGroup) {
        const isDefaultGroup = void 0 === group;
        const isValidationGroupInstance = group && "dxValidationGroup" === group.NAME;
        return !isDefaultGroup && !isValidationGroupInstance && !validatorsInGroup.length
    }

    removeGroup(group) {
        const config = this.getGroupConfig(group),
            index = $.inArray(config, this.groups);
        if (index > -1) {
            this.groups.splice(index, 1)
        }
        return config
    }

    removeRegisteredValidator(group, validator) {
        const config = this.getGroupConfig(group),
            validatorsInGroup = config && config.getValidators();
        const index = $.inArray(validator, validatorsInGroup);
        if (index > -1) {
            validatorsInGroup.splice(index, 1);
            const removed = config.removeValidator(validator, index);
            if (removed && this.shouldRemoveGroup(group, validatorsInGroup)) {
                this.removeGroup(group)
            }
        }
    }

    init() {
        this.groups = [];
        this.addGroup();
        this.setRuleValidators();
    }

    createNewValidator(editor, settings) {
        return new IcValidator(this, editor, settings); // jshint ignore:line
    }

    serverSideFVAEnabled(): boolean {
        return _.get(this.themeService.getThemeProperty('Validation.EnableServerSideFVA'), "Value1", "").toLowerCase() === "true";
    }

    // TODO: more testing is required.
    displayFieldValidationErrors(appName: string, fieldErrors: any[]) {
        const adapterMap = new Map<string, any>();
        this.groups.forEach((group) =>
            group.getValidators()
                .forEach(validator => {
                    const options = validator.adapter.editor.option();
                    if (!appName.EqualsIgnoreCase(options?.appName)) return;
                    adapterMap.set(options.fieldName, validator.adapter);
                })
        );
        fieldErrors.forEach((error) => {
            if (!adapterMap.has(error.fieldName)) return;
            const errorRule = {
                isValid: false,
                brokenRules: [{
                    isValid: false,
                    message: this.utilService.getTranslation(error.errorMessage)
                }],
                validationRules: []
            };
            adapterMap.get(error.fieldName).applyValidationResults(errorRule);
        });
    }

    runServerSideFVA(appName, adapter, name, result) {
        const fieldMapApps = {};
        const options = { appId: appName, containerId: appName };
        fieldMapApps[appName] = true;

        // TODO make FVA request
        // this.$stateSvc.ecdRequest(options.appId, options.containerId, "FVA", "", void (0), fieldMapApps)
        // .then((data) => {
        //     adapter.applyValidationResults(result);
        // }).catch(function (errorData) {
        //     //to account for the case where the A-Tier service does not support FVA
        //     if (errorData.MainMessage && errorData.MainMessage.split("|")[1] === "U_UNSUPPORTED_FUNCTION") {
        //         return;
        //     }
        //     _.forEach(errorData.SingletonData, (value) => {
        //         const rule = {  
        //             brokenRules: [{
        //                 isValid: false,
        //                 message: this.translateFacadeService.getTranslation(value),
        //             }],
        //             isValid: false,
        //             name: name,
        //             validationRules: []
        //         }
        //         adapter.applyValidationResults(rule);
        //     });
        // });
    }
}
