import { Injectable } from "@angular/core";
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { CommandListCollectionService } from "./command-list-collection.service";
import { ApplicationInformation } from '../services/application-information.service';
import { BaseEffectCommand } from "./base-effect.command";
import { CacheManagerService } from "../services/cachemanager.service";

@Injectable()
export class ClearFilterCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private applicationInformation: ApplicationInformation) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.clearFilterCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.clearFilterCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appName = commandConfig.appName;
                const updateState = this.getClearFilter(commandConfig)
                const appEntity = this.updateAppEntityWith(appName, updateState, appsStore);
                const thisCommandActions = this.getUpdateCommandStateActions([appEntity], commandConfig);
                if (this.applicationInformation.isListApp(appName)
                    && !this.applicationInformation.isPivotedList(appName)) {
                    const setAppFilterAction = this.getSetAppFilterAction(appName, { filters: [] });
                    thisCommandActions.push(setAppFilterAction);
                }
                return this.appendNextActions(thisCommandActions, commandConfig);
            },
            onError: (route, error) => AppsActions.onCommandError({ error: error, route: route })
        })
    );

    private getClearFilter(commandConfig: CommandConfig): AppState {
        const filters = this.getFilterFields(commandConfig);
        if (!_.isArray(filters?.Path)) return {};
        const appState = {};
        for (let i = 0; i < filters.Path.length; i++) {
            const fieldName = filters.Path[i];
            if (!_.isString(fieldName) || "#" == _.first(fieldName)) continue;
            appState[fieldName] = null;
        }
        return appState;
    }
}
