/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { CommandListFactory } from './command-list-factory.service';
import { CommandListCollectionService } from './command-list-collection.service';
import UtilityFunctions from '../utility.functions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type GetAppStateFunction = (appName: string) => AppState;

@Injectable()
export class CommandListEngine {

    constructor(private commandListFactory: CommandListFactory,
        private commandListCollectionService: CommandListCollectionService) { }

    startExecution(applet: any, commandListName: string, e: any, executionId: string): any {
        const commandConfig = {
            commandListName,
            rid: applet.rid,
            appName: applet.name,
            currentIndex: -1,
            action: null,
            command: null,
            module: null,
            parameters: null,
            options: {
                e,
                i: null,
                path: null
            },
            executionId
        };
        return this.commandListCollectionService.getNextCommand(commandConfig);
    }

    startCommandListIfContextHasChanged(applet: any, commandListName: string, executionId: string, getAppState: GetAppStateFunction): any {
        const cmdLst = this.commandListCollectionService.getCommandList(commandListName, applet.name);
        if (_.isNil(cmdLst)) return this.commandListCollectionService.resolveCommandListExecution(commandListName, executionId);
        const eventFieldMaps = cmdLst.commandList
            .filter(this.eventCommandsFilterFunction)
            .map(this.eventFieldMapperMapFunction);
        const hasChanged = this.hasContextChanged(getAppState, eventFieldMaps);
        if (hasChanged)
            return this.startExecution(applet, commandListName, null, executionId);
        return this.commandListCollectionService.resolveCommandListExecution(commandListName, executionId);
    }

    getCommandWithTargetApp(appName: string, commandListName: string): any {
        const commandList = this.commandListCollectionService.getCommandList(commandListName, appName);
        if (_.isNil(commandList)) return null;
        const commands = this.commandListFactory.createCommandList(commandList.commandList, commandList.commandsConfig);
        return _.find(commands, cmd => _.find(cmd.parameters, p => !_.isEmpty(p) && p.targetApp === appName));
    }

    eventCommandsFilterFunction(commandConfig: CommandConfig): boolean {
        return commandConfig.action === "Event - Set Values From Event";
    }

    eventFieldMapperMapFunction(commandConfig: CommandConfig): FieldMap {
        const parameter = commandConfig.parameters[1];
        const fieldMap = _.isString(parameter) ? JSON.parse(parameter) : parameter;
        if (!_.isArray(fieldMap.targetFieldsToClear))
            fieldMap.targetFieldsToClear = [];
        return fieldMap;
    }

    private hasContextChanged(getAppState: GetAppStateFunction, fieldMaps: any[]) {
        let res = false;
        for (let m = 0; m < fieldMaps.length; m++) {
            const info = fieldMaps[m];
            const sourceAppState = getAppState(info.sourceApp);
            const targetAppState = getAppState(info.targetApp);
            if (_.isEmpty(sourceAppState) || _.isEmpty(targetAppState)) return true;
            // TODO: check state based on application type so the state is uniformed
            // if (!_.isNil(state[sourceApp]._listData) && !_.isNil(state[targetApp]._listData)) {
            //     if (state[sourceApp]._listData.length != state[targetApp]._listData.length) return true;
            //     for (let k = 0; k < state[sourceApp]._listData.length; k++) {
            //         const sourceRecord = state[sourceApp]._listData[k];
            //         const targetRecord = state[targetApp]._listData[k];
            //         res = this.areFieldsInMapIdentical(sourceRecord, targetRecord, info.sourceFields, info.targetFields);
            //         if (res) return true;
            //     }
            // } else {
            res = this.areFieldsInMapIdentical(sourceAppState, targetAppState, info.sourceFields, info.targetFields);
            if (res) break;
            // }
        }
        return res;
    }

    private areFieldsInMapIdentical(sourceApp: AppState, targetApp: AppState, sourceFields: string[], targetFields: string[]): boolean {
        const _getValue = UtilityFunctions.getValue;
        for (let i = 0; i < targetFields.length; i++) {
            const sourceFieldName = sourceFields[i];
            const targetFieldName = targetFields[i];
            const sourceFieldValue = sourceApp[sourceFieldName];
            const targetFieldValue = targetApp[targetFieldName];
            if (!_.isNil(sourceFieldValue) && _.isNil(targetFieldValue)) return true;
            if (!_.isNil(sourceFieldValue) && _getValue(sourceFieldValue) !== _getValue(targetFieldValue)) return true;
        }
        return false;
    }

}
