import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect } from '@ngrx/effects';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as CommandActions from '../state/command.actions';
import { AppsEntity } from '../state/apps.models';
import { CommandListCollectionService } from "./command-list-collection.service";
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { ValidationEngineService } from '../services/validation-engine.service';
import { IcComponent } from '../services/validation-engine/ic-validator';
import { BaseEffectCommand } from './base-effect.command';
import { CacheManagerService } from '../services/cachemanager.service';

@Injectable()
export class EnableDisableCommand extends BaseEffectCommand {

    constructor(
        protected actions$: Actions,
        protected dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        protected commandListCollectionService: CommandListCollectionService,
        protected cacheManagerService: CacheManagerService,
        private validationEngine: ValidationEngineService,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        super();
    }

    effectCommandName$ = createEffect(() =>
        this.dataPersistence.fetch(CommandActions.enableDisableCommand, {
            id: (action, state) => this.getEffectFetchId(action),
            run: (
                action: ReturnType<typeof CommandActions.enableDisableCommand>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const commandConfig = this.getCommandConfig(action);
                const appsState = this.getAppsState(appsStore);
                const commandState = this.enableDisable(appsStore.workflow, appsStore.themeName, appsState, commandConfig);
                const commandAppsState = Object.values(commandState);
                const thisCommandActions = this.getUpdateCommandStateActions(commandAppsState, commandConfig);
                return this.appendNextActions(thisCommandActions, commandConfig);
            },
            onError: (route, error) => AppsActions.onCommandError({ error, route })
        })
    );

    enableDisable(workflow: string, themeName: string, appsState: Record<string, AppsEntity>,
        commandConfig: CommandConfig): Record<string, AppsEntity> {
        const clearDisableFieldValue = this.isTruthy(commandConfig.parameters[1]);
        const clearWithDefaultValue = this.isTruthy(commandConfig.parameters[2]);
        const ruleSets = this.getConditionalRules(commandConfig);
        const appsMap = new Set<string>();
        ruleSets.forEach(ruleSet => {
            const appEntity = this._getAppState(appsState, ruleSet.applicationName);
            if (_.isNil(appEntity)) return;
            const result = this.callWindowFunction(workflow, themeName, ruleSet.functionName, appEntity.state);
            if (result) {
                this.enableFields(ruleSet.enableFields);
                this.disableFields(appEntity, appsMap, ruleSet.disableFields, clearDisableFieldValue, clearWithDefaultValue);
            }
        });
        return this.getCommandState(appsState, appsMap);
    }

    private disableFields(appEntity: AppsEntity, appsMap: Set<string>, disableFields: any[], clearDisableFieldValue: boolean, clearWithDefaultValue: boolean) {
        const appComponent = this.domComponentRetrievalService.getAppComponent(appEntity.id);
        for (let i = 0; i < disableFields.length; i++) {
            const field = disableFields[i];
            this.setEditorDisableState(field.validationGroup, field.fieldName, true);
            if (clearDisableFieldValue) {
                let fieldValue = appEntity.state[field.fieldName] || null;
                fieldValue = clearWithDefaultValue ? this.getModelDefaultValueOrDefault(appComponent, field.fieldName, fieldValue) : "";
                this.updateAppState(appEntity, appsMap, field.fieldName, fieldValue);
            }
        }
    }

    private enableFields(enableFields: any[]) {
        for (let i = 0; i < enableFields.length; i++) {
            const field = enableFields[i];
            this.setEditorDisableState(field.validationGroup, field.fieldName, false);
        }
    }

    private setEditorDisableState(validationGroup, fieldName: string, disabled: boolean) {
        if (_.isNil(validationGroup)) {
            IX_Log("fieldMapper", "There was an attempt to use an Enable/Disable instruction to disable a field that has no ValidationGroup.")
            return;
        }
        const editor = this.getDxComponent(validationGroup, fieldName);
        if (_.isNil(editor)) return;
        editor.option("disabled", disabled);
    }

    private getDxComponent(validationGroup: string, fieldName: string): IcComponent {
        const groupConfig = this.validationEngine.getGroupConfig(validationGroup);
        if (_.isNil(groupConfig)) return null;
        const validators = groupConfig.getValidators();
        for (let i = 0; i < validators.length; i++) {
            const validator = validators[i];
            for (let j = 0; j < validator.validationRules.length; j++) {
                const validationRule = validator.validationRules[j];
                if (validationRule.fieldName == fieldName)
                    return validator.adapter.editor;
            }
        }
        return null;
    }

    private _getAppState(appsState: Record<string, AppsEntity>, appName: string): AppsEntity {
        return appsState[appName] || null;
    }

    private updateAppState(appEntity: AppsEntity, appsMap: Set<string>, fieldName: string, fieldValue: any): void {
        if (_.isNil(fieldValue)) return;
        appEntity.state[fieldName] = fieldValue;
        appsMap.add(appEntity.id);
    }

    protected getCommandState(appsState: Record<string, AppsEntity>, appsMap: Set<string>): Record<string, AppsEntity> {
        const state = {};
        appsMap.forEach(appName => state[appName] = { ...appsState[appName] });
        return state;
    }

}
