/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import numeral from 'numeral';
import { AppsEntity } from '../state/apps.models';
import { AppEvent } from '../state/app-events.enum';
import { AppsFacade } from '../state/apps.facade';
import { DataService } from '../services/data.service';
import { WorkflowConfigurationService } from '../services/workflow-configuration.service';
import { ThemeService } from '../services/theme.service';
import { ApplicationInformation } from '../services/application-information.service';
import { ApiClientService } from '../services/api-client.service';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { GdprService } from '../services/gdpr.service';
import { UtilService } from '../services/util.service';
import { ValidationGroupService } from '../services/validation-group.service';
import { AppStateBuilder } from '../state/app-state-builder';
import { HelpersService } from '../services/helpers.service';
import { SessionTimeout } from '../services/session-timeout.service';
import { MetaTagsService } from '../services/metatags.service';
import { TranslateFacadeService } from '../services/translate-facade.service';

@Component({
    selector: 'ic-workflow-step',
    template: `
        <ic-view-load-announcer [context]="context" [pageTitle]="pageTitle" [step]="step"></ic-view-load-announcer>
        <ic-canvas [context]="context" [canvas]="canvas" [config]="canvasConfig"></ic-canvas>
    `
})
export class WorkflowStepComponent implements OnInit, OnDestroy {

    public static globalMenu: any;

    step: string;
    pageTitle: string;
    currentLocale: string;
    canvas: Record<string, any>;
    context: Record<string, any>;
    canvasConfig: Record<string, any>;
    translateSubscription: Subscription;
    setLocaleSubscription: Subscription;
    sessionExtensionBackgroundSubscription: Subscription;

    throttledServerRequest: (...args) => void;

    constructor(private route: ActivatedRoute,
        private appsFacade: AppsFacade,
        private translate: TranslateFacadeService,
        private applicationInformation: ApplicationInformation,
        private dataService: DataService,
        private validationGroupService: ValidationGroupService,
        private workflowConfigSvc: WorkflowConfigurationService,
        private themeService: ThemeService,
        private apiClientService: ApiClientService,
        private utilService: UtilService,
        private dynamicReplacementService: DynamicReplacementService,
        private gdprSvc: GdprService,
        private sessionTimeout: SessionTimeout,
        private helpersService: HelpersService,
        private metaTagsService: MetaTagsService) {

        this.step = this.route.snapshot.data.step.toiXingName();
        this.appsFacade.setStep(this.route.snapshot.data.step);
        this.appsFacade.setWorkflow(this.route.snapshot.data.workflow);

        this.context = {};
        this.context._popups = {};
        this.context._dataSources = {};
        this.context._currentPopup = {};
        this.context._events = {};

        this.setLocaleSubscription = this.appsFacade.events$.pipe(
            filter((event) => AppEvent.SetLocale.EqualsIgnoreCase(event?.id)),
            tap((event) => this.helpersService.setLocales(event.state.LanguageCode))
        ).subscribe();

        this.canvasConfig = {
            float: true,
            hideTitle: true,
            headerMode: 'hover',
            showMove: false,
            isDisabled: true,
            format: 'digital',
            horizontalMargin: 0,
            verticalMargin: 0,
            layout: 'column',
            hideChrome: true,
            cellHeight: this.themeService.getCanvasCellHeight()
        };
    }

    ngOnDestroy(): void {
        this.translateSubscription?.unsubscribe();
        this.setLocaleSubscription?.unsubscribe();
        this.sessionExtensionBackgroundSubscription?.unsubscribe();
    }

    ngOnInit(): void {

        const canvasConfig = this.workflowConfigSvc.getConfiguration();
        const constants = this.workflowConfigSvc.getConstants();

        _.merge(this.context, canvasConfig);

        this.context._addStepToTitle = false; // TODO: get from workflow metadata. Hint: AngularConfigurationCacheGenerator
        this.context._stepCount = this.getStepCount();
        this.canvas = _.clone(this.context._steps[this.step]);
        this.context.currentStepNumber = _.parseInt(this.canvas.sequence);

        this.setupWorkflow(constants.workflow);
        this.applyThemeConfiguration(constants.contextName);
        this.setSessionExtensionEventHandler();

        this.translateSubscription = this.translate.onLangChange.subscribe(() => this.translateViewTitle());
    }

    private getStepCount(): number {
        return _(this.context._steps)
            .keys()
            .map(key => this.context._steps[key])
            .reduce((sum, item) => sum + (item.subSteps || 1), 0);
    }

    private setupWorkflow(workflow: string): void {

        this.apiClientService.setServiceMaps(this.applicationInformation.getServiceMaps());

        const themeApplications = this.themeService.getThemeApplications();
        this.canvas.applets = this.applicationInformation.getStepApplets(this.canvas.name, themeApplications);
        this.validationGroupService.getValidationGroupMap(this.canvas.applets);

        // TODO: pass this  down to the  ic-menu-default in a better way
        const wfInfo = this.applicationInformation.getAppInfo(workflow);
        const home = _.first(wfInfo.appsInCanvas).toiXingName();
        this.context._menu = Object.freeze({ home }); // TODO: Similar set up in app shell component?
        WorkflowStepComponent.globalMenu = this.context._menu;

        this.utilService.checkWorkflowWizard(this);// TODO: implement event bindings "$scope.$on('');"

        this.translateViewTitle();
        this.setBodyAttributes(workflow);
        this.setupWorkflowTrackers();
    }

    private setBodyAttributes(workflow: string): void {
        document.body.setAttribute('data-workflow', workflow);
        document.body.setAttribute('data-step', this.route.snapshot.data.step);
    }

    private translateViewTitle(): void {
        const translateId = this.canvas.id + '.dynamicObjs.screenDisplayLabel';
        const currentViewTitle = this.utilService.translateOrDefault(translateId, this.canvas.viewTitle);
        // In case the title has been set already, replace only the step in the title
        // document.title = document.title.replace(this.canvas.viewTitle, currentViewTitle); ??
        document.title = currentViewTitle;
    }

    private setupWorkflowTrackers(): void {
        this.dynamicReplacementService.getDynamicValue(document.title, {})
            .then(pageTitle => {
                if (this.context._addStepToTitle) {
                    pageTitle = this.canvas.step.viewTitle + ' Step ' + this.context.currentStepNumber + ' of ' + this.context.stepCount;
                }
                const isRecordNoThrottleEnabled = this.themeService.isRecordUserNavEventWithNoThrottleWaitEnabled();
                const message = ('Go to app: ' + this.canvas.name + ', ' + pageTitle).substring(0, 100);
                this.initiateServerSideCall('IXLastActivityTime', new Date(), message, isRecordNoThrottleEnabled);
                const previousUrl = null; // TODO: determine what is previousUrl?
                this.gdprSvc.trackPageViews(previousUrl, location.href, pageTitle);
                this.metaTagsService.setMetaTagsForViewport();
                this.metaTagsService.setTags({
                    'title': pageTitle,
                    'og:title': pageTitle
                });
                this.pageTitle = pageTitle;
            });
    }

    private initiateServerSideCall(actionFromUser, actionFromUserValue1, actionFromUserValue2, recordUserNavEventWithNoThrottleWait) {
        if (actionFromUser === "ExtendSession") iXing.pendingSessionExtensionRequests = true;
        const serverCaller = this.createThrottledServerCall(actionFromUser, actionFromUserValue1, actionFromUserValue2, recordUserNavEventWithNoThrottleWait);
        serverCaller();
    }

    private createThrottledServerCall(actionFromUser, actionFromUserValue1, actionFromUserValue2, recordUserNavEventWithNoThrottleWait) {
        if (recordUserNavEventWithNoThrottleWait) {
            this.makeServerSideCall(actionFromUser, actionFromUserValue1, actionFromUserValue2);
            return _.noop;
        }
        const throttleWait = 60000;
        const options = { leading: true, trailing: false };
        const optionsReset = { leading: false, trailing: true };
        const serverCall = _.throttle(() => this.makeServerSideCall(actionFromUser, actionFromUserValue1, actionFromUserValue2), throttleWait, options);
        const resetSession = _.throttle(() => this.sessionTimeout.init(), throttleWait, optionsReset);
        return () => {
            serverCall();
            resetSession();
        };
    }

    private createThrottledServerRequest(throttleWait: number): any {
        const options = { leading: true, trailing: false };
        const optionsReset = { leading: false, trailing: true };
        const serverCall = _.throttle(this.makeServerSideCall.bind(this), throttleWait, options);
        const resetSession = _.throttle(() => this.sessionTimeout.init(), throttleWait, optionsReset);
        return (actionFromUser, actionFromUserValue1, actionFromUserValue2, recordUserNavEventWithNoThrottleWait) => {
            serverCall(actionFromUser, actionFromUserValue1, actionFromUserValue2, recordUserNavEventWithNoThrottleWait);
            resetSession();
        };
    }

    private makeServerSideCall(actionFromUser, actionFromUserValue1, actionFromUserValue2) {
        actionFromUser = _.isNil(actionFromUser) ? "ExtendSession" : actionFromUser;

        // Updating the "IXLastActivityTime" cookie with the current timestamp of user action
        if (actionFromUser == "IXLastActivityTime") {
            this.sessionTimeout.LastActivityTime = new Date();
            IX_SetCookieValue("IXLastActivityTime", this.sessionTimeout.LastActivityTime);
        }

        this.apiClientService.extendSessionRequest(actionFromUser, actionFromUserValue1, actionFromUserValue2)
            .then(() => delete iXing.pendingSessionExtensionRequests);

        if (_.isFunction(iXing.IX_ExtendSessionTimeoutScript))
            iXing.IX_ExtendSessionTimeoutScript();
    }

    private applyThemeConfiguration(contextName: string): void {
        this.themeService.getAddThisCode();
        this.themeService.setBodyCssClass();
        this.themeService.setAddThisWidget();
        this.themeService.setWarnUserOnExternalLinks();
        this.themeService.setCanvasAppletCommandOverrides(this.canvas);
        const eventMaps = this.workflowConfigSvc.getEventMaps();
        const defaultState = this.generateContextDefaultValues(contextName, eventMaps);
        this.appsFacade.updateCommandState(Object.values(defaultState));
        try {
            const workflowPageChangeScript = this.themeService.getWorkflowPageChangeScript();
            const fn = new Function('$scope', workflowPageChangeScript);
            fn(this);
        } catch (e) {
            console.error('P-Tier workflowPageChangeScript error: ', e.message);
        }
        this.setPrintNavigationEventCode();
    }

    private setPrintNavigationEventCode(): void {
        window.onbeforeprint = () => {
            const eventMessage = ('Browser print: ' + document.title).substring(0, 100);
            const isRecordNoThrottleEnabled = this.themeService.isRecordUserNavEventWithNoThrottleWaitEnabled();
            this.initiateServerSideCall(eventMessage, "Browser print", document.title, isRecordNoThrottleEnabled);
        };
    }

    private generateContextDefaultValues(applicationContextName, eventApps): Record<string, AppsEntity> {
        const appStateBuilder = new AppStateBuilder({}, this.dataService);
        const forceSetValue = true;
        IX_Theme.properties.forEach((property) => {
            if (!"context.defaultvalue".EqualsIgnoreCase(property.PropertyName)
                || _.isNil(property.Value1)) {
                return;
            }
            const options = property.Value1.split('.');
            let fieldName = options[0];
            if (options.length > 1) { // Event support
                let appName = options[0];
                fieldName = options[1];
                if (eventApps[appName] && eventApps[appName].indexOf(fieldName) > -1) {
                    appStateBuilder.initializeAppFieldState(appName + '.Event', fieldName, property.Value2, forceSetValue);
                }
                else if (this.applicationInformation.getAppInfo(options[0].replace(/_/g, '.'))) {
                    appName = options[0].replace(/_/g, '.');
                    appStateBuilder.initializeAppFieldState(appName, fieldName, property.Value2, forceSetValue);
                }
            }
            else if (applicationContextName) { //If workflow has context
                appStateBuilder.initializeAppFieldState(applicationContextName, fieldName, property.Value2, forceSetValue);
            }
        });
        return appStateBuilder.State;
    }

    private setSessionExtensionEventHandler(): void {
        const propMatch = _.find(IX_Theme.properties, (prop) => "SessionExtension.ThrottleWaitMilliseconds".EqualsIgnoreCase(prop.PropertyName));
        const extensionWait = numeral(propMatch?.Value1).value() || 60000;
        this.throttledServerRequest = this.createThrottledServerRequest(extensionWait);

        // Allow session to stay alive without user input
        const keepAliveBackgroundMatch = _.find(IX_Theme.properties, (prop) => "SessionExtension.KeepAliveInBackground".EqualsIgnoreCase(prop.PropertyName));
        if (keepAliveBackgroundMatch?.Value1) {
            this.sessionExtensionBackgroundSubscription = interval(IX_TimeoutStart / 2)
                .pipe(
                    tap(() => this.triggerExtentSessionEvent())
                )
                .subscribe();
        }
    }

    //#region event handlers

    @HostListener('document:mousemove', ['$event'])
    documentExtentSessionEventHandlerMouseMove(e): void {
        this.triggerExtentSessionEvent();
    }

    @HostListener('document:wheel', ['$event'])
    documentExtentSessionEventHandlerWheel(e): void {
        this.triggerExtentSessionEvent();
    }

    @HostListener('document:scroll', ['$event'])
    documentExtentSessionEventHandlerScroll(e): void {
        this.triggerExtentSessionEvent();
    }

    @HostListener('document:keyup', ['$event'])
    documentExtentSessionEventHandlerkeyup(e): void {
        this.triggerExtentSessionEvent();
    }

    @HostListener('document:click', ['$event'])
    documentExtentSessionEventHandlerClick(e): void {
        this.triggerExtentSessionEvent();
    }

    @HostListener('window:resize', ['$event'])
    documentExtentSessionEventHandlerResize(e): void {
        this.triggerExtentSessionEvent();
    }

    private triggerExtentSessionEvent(): void {
        if (this.sessionTimeout.TimerVisible
            || !this.throttledServerRequest) return;
        this.throttledServerRequest("ExtendSession");
    }

    //#endregion
}