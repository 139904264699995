/* eslint-disable */
var DevExpress = require('devextreme/bundles/modules/core');
var ui = require('devextreme/bundles/modules/ui');
ui.dxLoadPanel = require('devextreme/ui/load_panel');

const LOADPANEL_MESSAGE_CLASS = "dx-loadpanel-message";

const LoadPanel = DevExpress.ui.dxLoadPanel.redefine({
    _renderMessage: function () {
        if (!this._$loadPanelContentWrapper) {
            return
        }
        const message = this.option("message");
        if (!message) {
            return
        }
        // NOTE: This is the custom IC code, able to detect HTML vs plain .text()
        const isHtml = !!message.match(/<(.*)>.*?|<(.*) \/>/);
        const $message = $("<div>").addClass(LOADPANEL_MESSAGE_CLASS)
        if (isHtml) {
            $message.html(message); // HTML injection is needed here do not escape
        } else {
            $message.text(message);
        }
        // end IC customization
        this._$loadPanelContentWrapper.append($message)
    },
});

DevExpress.registerComponent('dxLoadPanel', LoadPanel);
